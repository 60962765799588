import { graphql } from '@qasa/graphql'

export const FINALIZE_UPLOAD = graphql(`
  mutation FinalizeUploadMutation($etag: String!, $id: ID!) {
    finalizeUpload(etag: $etag, id: $id) {
      errors {
        codes
        field
      }
      upload {
        fileType
        id
        title
        type
        url
        metadata {
          order
          primary
          rotation
        }
      }
    }
  }
`)
