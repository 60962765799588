import { useTranslation } from 'react-i18next'

import { FormCheckboxGroup } from '../../create-tenant-listing/fields/form-checkbox-group'
import { ProfileSearchPreferenceForm } from '../forms/profile-search-preference-form'
import { ProfileRequiredAmenitiesEnum, ProfileSectionEnum } from '../profile.types'

export function RequiredAmenitiesSection() {
  const { t } = useTranslation('create_tenant_listing')

  const amenitiesOptions = Object.values(ProfileRequiredAmenitiesEnum).map((value) => ({
    value,
    label: t(`fields.requirements.checkbox_options.${value}`),
  }))

  return (
    <ProfileSearchPreferenceForm dataKey="requiredAmenities">
      {({ control }) => (
        <FormCheckboxGroup
          control={control}
          label={t(`profile:sections_title.${ProfileSectionEnum.REQUIRED_AMENITIES}`)}
          isLabelVisuallyHidden
          name="requiredAmenities"
          options={amenitiesOptions}
        />
      )}
    </ProfileSearchPreferenceForm>
  )
}
