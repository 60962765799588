import type { LinkProps } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
import type { OwnProps } from '@qasa/qds-ui'
import { Button } from '@qasa/qds-ui'
import { forwardRef } from 'react'

export type LinkButtonProps = OwnProps<typeof Button> & LinkProps

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>((props, ref) => {
  const { children, isDisabled, to, ...rest } = props

  if (isDisabled) {
    return (
      <Button
        as="a"
        isDisabled={isDisabled}
        // https://www.scottohara.me/blog/2021/05/28/disabled-links.html
        role="link"
        aria-disabled="true"
        {...rest}
        ref={ref}
      >
        {children}
      </Button>
    )
  }

  return (
    <Button as={RouterLink} ref={ref} to={to} {...rest}>
      {children}
    </Button>
  )
})
