import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useToastContext } from '@qasa/app'
import { EditDialog } from '@qasa/app/src/modules/edit-dialog'

import { useProfileContext } from '../profile-context'
import type { ProfileDataFormValuesKeys } from '../profile.types'
import { getProfileName } from '../profile.utils'
import { useEditProfile } from '../hooks/use-edit-profile'

type ProfileDataFormProps<TDataKey extends ProfileDataFormValuesKeys> = {
  children?: ReactNode
  dataKey: TDataKey
}
export function ProfileDataForm<TDataKey extends ProfileDataFormValuesKeys>({
  children,
  dataKey,
}: ProfileDataFormProps<TDataKey>) {
  const { profile, isEditDrawerOpen, activeEditSection, closeEditProfileSection } = useProfileContext()
  const { t } = useTranslation('edit_profile')
  const { addToast } = useToastContext()
  const handleError = () => addToast({ message: t('commons:unexpected_error') })

  const allDefaultValues = {
    intro: {
      firstName: profile.firstName || '',
      showAge: profile.showAge,
    },
    aboutMe: {
      bioTitle: profile.bio.title,
      bio: profile.bio.intro,
      bioPets: profile.bio.pets,
    },
  }
  const currentSectionDefaultValues = allDefaultValues[dataKey]
  const { formMethods, onSubmit } = useEditProfile({
    onCompleted: closeEditProfileSection,
    onError: handleError,
    userUid: profile.uid,
    defaultValues: currentSectionDefaultValues,
  })

  return (
    <EditDialog
      isOpen={isEditDrawerOpen}
      onOpenChange={closeEditProfileSection}
      title={t(`profile:sections_title.${activeEditSection}`, {
        profileName: getProfileName({ user: profile }),
      })}
      formMethods={formMethods}
      formId="profile-data-form-search-preference-form"
      onSubmit={onSubmit}
    >
      {children}
    </EditDialog>
  )
}
