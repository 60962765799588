import styled from '@emotion/styled'

import type { VisuallyHiddenProps } from './visually-hidden.types'

/**
 * Visually hides content while preserving it for assistive technology.
 */
export const VisuallyHiddenWrapper = styled.div({
  borderWidth: 0,
  clip: 'rect(0px, 0px, 0px, 0px)',
  height: 1,
  width: 1,
  margin: -1,
  padding: 0,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  wordWrap: 'normal',
  position: 'absolute',
})

export function VisuallyHidden({ children, ...rest }: VisuallyHiddenProps) {
  return (
    <VisuallyHiddenWrapper aria-hidden="true" {...rest}>
      {children}
    </VisuallyHiddenWrapper>
  )
}
