import { useTranslation } from 'react-i18next'
import { useRef, useState } from 'react'
import { useToastContext, reportError } from '@qasa/app'
import { ampli } from '@qasa/ampli/p2'

import { P1 } from '../../helpers/p1-api'

import { FieldEnum } from './edit-listing.types'
import { useListingContext } from './listing-context'

export const useSuggestedDescription = ({
  homeId,
  description: currentDescription,
}: {
  homeId: string
  description: string | null
}) => {
  const [suggestedDescription, setSuggestedDescription] = useState('')
  const previousDescription = useRef<string | null>(null)
  const [isLoadingSuggestedDescription, setIsLoadingSuggestedDescription] = useState(false)
  const { addToast } = useToastContext()
  const { t } = useTranslation('commons')
  const { dispatch } = useListingContext()

  const fetchSuggestedDescription = async () => {
    previousDescription.current = currentDescription
    const loadStartTime = performance.now()
    setIsLoadingSuggestedDescription(true)
    try {
      const response = await P1.getSuggestedDescription({ homeId })

      if (!response.ok) {
        throw new Error(response.status.toString())
      }

      dispatch({ action: { type: FieldEnum.Description, payload: '' } })

      const reader = response.body?.getReader()
      const decoder = new TextDecoder()
      let accumulatedResponseText = ''
      let result = await reader?.read()
      while (!result?.done) {
        const text = decoder.decode(result?.value)
        accumulatedResponseText += text
        setSuggestedDescription((old) => old + text)
        result = await reader?.read()
      }

      dispatch({ action: { type: FieldEnum.Description, payload: accumulatedResponseText } })
    } catch (error) {
      reportError('suggested description fetch error', { error })
      addToast({ message: t('unexpected_error') })
    } finally {
      const loadEndTime = performance.now()
      const loadTimeSecondsWithTwoDecimalPlaces = parseFloat(
        ((loadEndTime - loadStartTime) / 1000).toFixed(2),
      )
      ampli.suggestedDescriptionRequested({
        homeId,
        loadTime: loadTimeSecondsWithTwoDecimalPlaces,
      })
      setIsLoadingSuggestedDescription(false)
      setSuggestedDescription('')
    }
  }
  const undoSuggestedDescription = () => {
    if (previousDescription.current) {
      dispatch({ action: { type: FieldEnum.Description, payload: previousDescription.current } })
      previousDescription.current = ''
    }
  }
  const hasPreviousDescription = Boolean(previousDescription.current)
  return {
    fetchSuggestedDescription,
    suggestedDescription,
    isLoadingSuggestedDescription,
    undoSuggestedDescription,
    hasPreviousDescription,
  }
}
