import type { BigSearchVacationCardFragment } from '@qasa/graphql'

import { useAppTranslation } from '../../../../contexts/i18next'
import { formatSpan, formatNumber } from '../../../../utils/number'
import { getSortedUploads, prependSrcWithThumbor } from '../../../../utils/image'
import { HOME_PLACEHOLDER_IMAGE_SRC } from '../../../../assets/home-placeholder'

type UseBigSearchCardParams = {
  data: BigSearchVacationCardFragment
  shouldDisplayPriceRange: boolean
}
export function useBigSearchVacationCard({ data, shouldDisplayPriceRange }: UseBigSearchCardParams) {
  const { t } = useAppTranslation('common_home')

  const {
    location,
    uploads,
    averagePricePerNight,
    minimumPricePerNight,
    maximumPricePerNight,
    title,
    tenantCount,
    bedCount,
    bedroomCount,
    favoriteMarkedByUser: isFavoriteMarked,
    currency,
  } = data

  const formattedImages = uploads.length
    ? getSortedUploads({ uploads }).map(({ url }) =>
        prependSrcWithThumbor({ src: url, height: 350, width: 350 }),
      )
    : [HOME_PLACEHOLDER_IMAGE_SRC]
  const formattedTenantCount = t('vacation_home_info.tenant_count', { count: tenantCount ?? undefined })

  const formattedBedroomCount = t('vacation_home_info.bedroom_count', {
    count: bedroomCount ?? undefined,
  })

  const formattedBedCount = t('vacation_home_info.bed_count', { count: bedCount ?? undefined })

  const formattedPrice = shouldDisplayPriceRange
    ? formatSpan({
        min: minimumPricePerNight,
        max: maximumPricePerNight,
        fallbackNumber: averagePricePerNight,
        currency,
      })
    : formatNumber({ amount: averagePricePerNight ?? 0, currency })

  const price = averagePricePerNight
    ? t('vacation_home_info.price_per_night', { price: formattedPrice })
    : null

  return {
    images: formattedImages,
    hero: price,
    heading: `${title}, ${location.locality}`,
    bulletData: [formattedTenantCount, formattedBedroomCount, formattedBedCount] as [string, string, string],
    isFavoriteMarked,
  }
}
