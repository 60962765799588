import { useTranslation } from 'react-i18next'
import { Stack, RadioGroup } from '@qasa/qds-ui'
import { TraitTypeEnum } from '@qasa/graphql'

import { FieldEnum } from '../edit-listing.types'
import { useListingContext } from '../listing-context'
import { SectionWrapper } from '../section-wrapper'
import { getIsProbablyFinlandMarket, getIsProfessionalWithSeveralListings } from '../listing.utils'

export function SharedFurnishedSection() {
  const { t } = useTranslation('listing')

  const {
    store: {
      values: { rentalType, traits, shared: isShared, isProfessional, numberOfHomes, market, countryCode },
    },
    dispatch,
  } = useListingContext()
  const isPrivateIndividualOrHasSingleListing = !getIsProfessionalWithSeveralListings({
    isProfessional,
    numberOfHomes,
  })
  const isSharedValue = typeof isShared === 'boolean' ? isShared.toString() : null
  const isFurnishedValue = traits === null ? null : traits?.includes(TraitTypeEnum.furniture).toString()

  const isFinlandMarket = getIsProbablyFinlandMarket({ countryCode, marketName: market?.name })
  const isLongtermRental = rentalType === 'long_term'

  return (
    <SectionWrapper title={t('section_headings.shared_furnished')}>
      {isPrivateIndividualOrHasSingleListing && (
        <RadioGroup
          name="shared"
          value={isSharedValue ?? ''}
          onValueChange={(value) => {
            const isShared = value === 'true'
            dispatch({
              action: {
                type: FieldEnum.Shared,
                payload: isShared,
              },
            })
          }}
        >
          <RadioGroup.Label>{t('shared.label')}</RadioGroup.Label>
          <Stack gap="2x">
            <RadioGroup.Card value="false" label={t('shared.private')} />
            <RadioGroup.Card value="true" label={t('shared.shared')} />
          </Stack>
        </RadioGroup>
      )}
      {isLongtermRental && !isFinlandMarket && (
        <RadioGroup
          name="furnished"
          value={isFurnishedValue ?? ''}
          onValueChange={(value) => {
            const isFurnished = value === 'true'
            dispatch({
              action: {
                type: FieldEnum.Traits,
                payload: isFurnished
                  ? [...(traits || []), TraitTypeEnum.furniture]
                  : traits?.filter((trait) => trait !== TraitTypeEnum.furniture) || [],
              },
            })
          }}
        >
          <RadioGroup.Label>{t('furnished.label')}</RadioGroup.Label>
          <Stack gap="2x">
            <RadioGroup.Card value="true" label={t('furnished.furnished')} />
            <RadioGroup.Card value="false" label={t('furnished.unfurnished')} />
          </Stack>
        </RadioGroup>
      )}
    </SectionWrapper>
  )
}
