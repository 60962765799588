import { Paragraph, useStableId } from '@qasa/qds-ui'
import { forwardRef } from 'react'
import styled from '@emotion/styled'

import { VisuallyHidden } from '../../visually-hidden'

import { CheckboxIndicator } from './checkbox-indicator'
import type { UseCheckboxParams } from './use-checkbox'
import { useCheckbox } from './use-checkbox'

type CardProps = {
  isChecked: boolean
}

const Card = styled.label<CardProps>(({ theme, isChecked }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: 56,
  gap: theme.spacing['1x'],
  padding: theme.spacing['4x'],
  // Larger padding on the right to make room for the indicator
  paddingRight: 48,
  background: theme.colors.bg.default,
  border: '1px solid',
  borderColor: theme.colors.border[isChecked ? 'defaultSelected' : 'default'],
  borderRadius: theme.radii.md,
  cursor: 'pointer',
  transitionProperty: 'border-color, box-shadow, background-color, transform, outline',
  transitionDuration: '120ms',
  transitionTimingFunction: 'ease-in-out',
  ...(isChecked
    ? {
        boxShadow: `inset 0 0 0 1px ${theme.colors.border.defaultSelected}`,
        backgroundColor: theme.colors.bg.inset,
      }
    : {
        '&:hover': {
          borderColor: theme.colors.border.defaultHover,
          backgroundColor: theme.colors.bg.inset,
        },
      }),
  // Not supported in a lot of browsers, but hey... progressive enhancement!
  '&:has(input:focus-visible)': {
    outlineStyle: 'solid',
    outlineColor: theme.colors.border.defaultSelected,
    outlineWidth: '2px',
    outlineOffset: '2px',
  },

  WebkitTouchCallout: 'none',
  WebkitTapHighlightColor: 'transparent',

  ':active': {
    transform: 'scale(0.98)',
  },
}))

const IndicatorWrapper = styled.div({
  position: 'absolute',
  top: 18,
  right: 16,
})

const InlineLabel = styled.span(({ theme }) => ({
  ...theme.typography.label.md,
  color: theme.colors.text.default,
}))

export type CheckboxCardProps = UseCheckboxParams & { label: string; helperText?: string }
export const CheckboxCard = forwardRef<HTMLInputElement, CheckboxCardProps>(
  ({ label, helperText, ...restProps }, ref) => {
    const { getInputProps, isChecked } = useCheckbox({ ...restProps })

    const id = useStableId()
    const labelId = `${id}-label`
    const helperTextId = helperText ? `${id}-helper` : undefined

    return (
      <Card isChecked={isChecked}>
        <VisuallyHidden>
          <input ref={ref} {...getInputProps()} aria-labelledby={labelId} aria-describedby={helperTextId} />
        </VisuallyHidden>
        <IndicatorWrapper>
          <CheckboxIndicator size="small" isChecked={isChecked} />
        </IndicatorWrapper>
        <InlineLabel id={labelId}>{label}</InlineLabel>
        {helperText && (
          <Paragraph id={helperTextId} size="sm" color="subtle">
            {helperText}
          </Paragraph>
        )}
      </Card>
    )
  },
)
