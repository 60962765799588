import { usePersistentStorage } from '@qasa/app'

import { getAdminToken, setAdminToken } from '../helpers/p1-auth'

export function useAdminState() {
  const [isAdminLoggedInAsUser, setAdminLoggedInAsUser] = usePersistentStorage<boolean | null>(
    'adminLoggedInAsUser',
  )
  return {
    isAdminLoggedInAsUser: isAdminLoggedInAsUser || Boolean(getAdminToken()),
    setAdminLoggedInAsUser: (token?: string) => {
      setAdminLoggedInAsUser(Boolean(token))
      setAdminToken(token)
    },
  }
}
