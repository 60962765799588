import { useTranslation } from 'react-i18next'
import { useLocation, useParams, Link as RouterLink } from 'react-router-dom'
import { ArrowLeftIcon, IconButton, Stack } from '@qasa/qds-ui'

import { Wizard } from '../../ui-shared/wizard'
import { getPath } from '../../routing/get-path'
import { LinkButton } from '../../ui-shared/_core/link-button'

import { useListingContext } from './listing-context'

type SectionNavigationProps = {
  previousSection?: string
  nextSection?: string
  isCurrentSectionComplete?: boolean
  nextButtonLabelOverride?: string
  isLoadingNext?: boolean
  isCreateListing?: boolean
}
function NextLink({
  previousSection,
  nextSection,
  isCurrentSectionComplete = true,
  nextButtonLabelOverride,
  isLoadingNext,
  isCreateListing = false,
}: SectionNavigationProps) {
  const { t } = useTranslation()
  const { isPublishingDisabled, isPendingShortcut } = useListingContext()
  const { homeId }: { homeId: string } = useParams()
  const location = useLocation()

  if (nextSection) {
    return (
      <LinkButton
        variant="primary"
        to={{ ...location, pathname: nextSection }}
        isDisabled={!isCurrentSectionComplete || isLoadingNext}
        isFullWidth={!previousSection}
      >
        {nextButtonLabelOverride ?? t('commons:next')}
      </LinkButton>
    )
  }
  if (isCreateListing) {
    return null
  }
  if (isPendingShortcut) {
    return (
      <LinkButton
        variant="primary"
        to={getPath('listingDashboard', { homeId })}
        isDisabled={!isCurrentSectionComplete}
      >
        {t('commons:save')}
      </LinkButton>
    )
  }
  return (
    <LinkButton
      variant="primary"
      to={getPath('home', { homeId })}
      isDisabled={isPublishingDisabled || !isCurrentSectionComplete}
    >
      {t('listing:preview_ad')}
    </LinkButton>
  )
}

export function SectionNavigation({ previousSection, ...rest }: SectionNavigationProps) {
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <Wizard.Footer>
      <Stack
        // `row-reverse` makes it so the right-most button will have the lowest tabindex
        direction="row-reverse"
        alignItems="center"
        justifyContent="space-between"
      >
        <NextLink {...rest} previousSection={previousSection} />
        {previousSection && (
          <IconButton
            as={RouterLink}
            to={{ ...location, pathname: previousSection }}
            variant="tertiary"
            icon={ArrowLeftIcon}
            label={t('commons:back')}
          />
        )}
      </Stack>
    </Wizard.Footer>
  )
}
