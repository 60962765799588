import { Heading, Paragraph, Stack, createLucideIcon } from '@qasa/qds-ui'
import { ShieldCheck } from 'lucide-react'

const ShieldCheckIcon = createLucideIcon(ShieldCheck)

type UspBoxProps = {
  title: string
  description: string
}
export function UspBox({ title, description }: UspBoxProps) {
  return (
    <Stack gap={'2x'}>
      <Stack gap={'2x'} direction="row" alignItems="center">
        <ShieldCheckIcon color="positive" />
        <Heading size="xs" as="h4">
          {title}
        </Heading>
      </Stack>
      <Paragraph>{description}</Paragraph>
    </Stack>
  )
}
