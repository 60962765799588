import type { ReactNode } from 'react'
import { Paragraph, Stack, styled } from '@qasa/ui'

import { SearchPreferenceListItemWrapper } from './search-preference-list-item-wrapper'

const ContentWrapper = styled(Stack)({ minWidth: 0 })
const PreferenceText = Paragraph

type SearchPreferenceListItemProps = {
  value?: ReactNode
  children?: ReactNode
  matchingIndicator?: ReactNode
  isFullWidth?: boolean
}
export function SearchPreferenceListItem({
  value,
  children,
  matchingIndicator,
  isFullWidth,
}: SearchPreferenceListItemProps) {
  if (!value) {
    return null
  }
  return (
    <SearchPreferenceListItemWrapper
      direction={'row'}
      gap={'4x'}
      alignItems={'center'}
      isFullWidth={isFullWidth}
    >
      {children}
      <ContentWrapper>
        <PreferenceText numberOfLines={1}>{value}</PreferenceText>
        {matchingIndicator}
      </ContentWrapper>
    </SearchPreferenceListItemWrapper>
  )
}
