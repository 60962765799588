import type { Consumer } from '@rails/actioncable'

import { reportWarning } from '../../../vendor/sentry'
import { getIsOnline } from '../../../utils/network'

import { runCableConnectionManager } from './connection-manager'

export const initializeConsumer = (consumer: Consumer) => {
  /**
   * Tapping into ActionCable to catch silent errors that are not returned by the library
   * Example: Trying to connect to a url that does not exist or is not a websocket server
   */
  const connectionPrototype = Object.getPrototypeOf(consumer.connection)
  connectionPrototype.events.error = (error: unknown) => {
    getIsOnline().then((isOnline) => {
      reportWarning('ActionCable: unexpected error', { error, clientIsOnline: isOnline })
    })
  }

  runCableConnectionManager(consumer)
  return consumer
}
