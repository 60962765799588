import type { TraitTypeEnum, MarketNameEnum } from '@qasa/graphql'

import { VACATION_TRAITS } from './vacation-traits'
import type { AmenityGroupTitleEnum } from './types'
import { getLongTermTraits } from './get-long-term-traits'

type GetTraitsProps = {
  isVacationHome: boolean
  marketName?: MarketNameEnum
  excludedGroups?: AmenityGroupTitleEnum[]
  additionalTraits?: TraitTypeEnum[]
}

export const getTraits = ({ isVacationHome, excludedGroups, marketName }: GetTraitsProps) => {
  const allTraits = isVacationHome ? VACATION_TRAITS : getLongTermTraits({ marketName })
  if (excludedGroups) {
    return allTraits.filter(({ title }) => !excludedGroups.includes(title))
  }
  return allTraits
}
