import { Spacer, Heading, Paragraph } from '@qasa/qds-ui'
import i18next from 'i18next'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { useWizardRouterContext } from '../wizard-router/wizard-router'

const Wrapper = 'div'
type WizardStepWrapperProps = {
  children: ReactNode
}

export function WizardStepWrapper({ children }: WizardStepWrapperProps) {
  const { t } = useTranslation('create_tenant_listing')
  const { currentStep } = useWizardRouterContext()

  const currentStepName = currentStep.name

  const title = t(`steps.${currentStepName}.title`)

  const hasDescription = i18next.exists(`create_tenant_listing:steps.${currentStepName}.description`)
  const description = t(`steps.${currentStepName}.description`)

  return (
    <Wrapper>
      <div>
        <Heading as="h1">{title}</Heading>

        {hasDescription && (
          <>
            <Spacer size="2x" />
            <Paragraph>{description}</Paragraph>
          </>
        )}
        <Spacer size="8x" />
      </div>
      {children}
    </Wrapper>
  )
}
