import { graphql } from '@qasa/graphql'

export const CONTRACTS_COUNTS = graphql(`
  query ContractsCountsQuery {
    contractsCounts {
      vacation {
        canceled
        draft
        extended
        ended
        finalDraft
        pending
        pendingAprilaApplication
        pendingAprilaRentalAgreement
        pendingTenantCreditCheck
        signed
        rejected
        timedout
      }
      longTerm {
        canceled
        draft
        awaitingInsuranceApproval
        extended
        ended
        finalDraft
        pending
        pendingAprilaApplication
        pendingAprilaRentalAgreement
        pendingTenantCreditCheck
        signed
        rejected
        timedout
      }
    }
  }
`)
