import { useTranslation } from 'react-i18next'

import { DurationInput } from '../../../ui-shared/_core/controls/duration-input'
import { FieldEnum } from '../edit-listing.types'
import { useListingContext } from '../listing-context'
import { SectionWrapper } from '../section-wrapper'
import { SectionOfferingUSPBox } from '../section-offering-usp-box'
import { BooleanInput } from '../../../ui-shared/_core/controls/boolean-input'
import { CheckboxCard } from '../../../ui-shared/_core/controls/checkbox'
import { getIsProbablyFinlandMarket } from '../listing.utils'

export function DurationSection() {
  const { t } = useTranslation('listing')
  const {
    store: {
      values: {
        countryCode,
        market,
        isProfessional,
        duration: { startOptimal, endOptimal, endUfn: isEndUfn, possibilityOfExtension: isPossibleToExtend },
        ownHome, //eslint-disable-line @typescript-eslint/naming-convention
      },
    },
    getErrorMessage,
    dispatch,
  } = useListingContext()

  const isFinlandMarket = getIsProbablyFinlandMarket({ countryCode, marketName: market?.name })
  const errorMessage = getErrorMessage({ field: FieldEnum.Duration })
  return (
    <SectionWrapper title={t('section_headings.duration')}>
      <DurationInput
        name={'moveIn'}
        label="move_in"
        nonDateLabel="asap"
        value={startOptimal || null}
        onChange={(value) => {
          dispatch({
            action: {
              type: FieldEnum.Duration,
              payload: value
                ? { startOptimal: value, startAsap: false }
                : { startOptimal: null, startAsap: true },
            },
          })
        }}
      />
      <DurationInput
        name={'moveOut'}
        label="move_out"
        nonDateLabel="until_further_notice"
        value={endOptimal || null}
        onChange={(value) => {
          dispatch({
            action: {
              type: FieldEnum.Duration,
              payload: value
                ? { endOptimal: value, endUfn: false }
                : { endOptimal: null, endUfn: true, possibilityOfExtension: false },
            },
          })
        }}
        errorMessage={errorMessage}
      />
      {!isEndUfn && (
        <CheckboxCard
          label={t('duration.possible_to_extend')}
          value={'duration.possible_to_extend'}
          isChecked={Boolean(isPossibleToExtend)}
          onChange={(isChecked) => {
            dispatch({
              action: {
                type: FieldEnum.Duration,
                payload: { possibilityOfExtension: isChecked },
              },
            })
          }}
        />
      )}
      {!isProfessional && !isFinlandMarket && (
        <BooleanInput
          positiveLabel={t('own_home.positive')}
          negativeLabel={t('own_home.negative')}
          label={t('own_home.label')}
          name={t('own_home.label')}
          value={ownHome}
          onChange={(value) => {
            dispatch({ action: { type: FieldEnum.OwnHome, payload: value } })
          }}
        />
      )}
      <SectionOfferingUSPBox
        heading={t('offering_usp_box.personal_support.heading')}
        body={t('offering_usp_box.personal_support.body')}
      />
    </SectionWrapper>
  )
}
