import { useTranslation } from 'react-i18next'
import { ArrowLeftIcon, Button, Heading, IconButton, Stack, Textarea, useBreakpointValue } from '@qasa/qds-ui'
import { useFormContext } from 'react-hook-form'
import type { UpdateUserInput } from '@qasa/graphql'

import type { HousingSituationStepProps } from '../housing-situation'
import { DialogAndWizardActionsFooter } from '../../../ui-shared/dialog-and-wizard-actions-footer'

const MAX_LENGTH = 160

export function PreviousHousing({ goBack }: HousingSituationStepProps) {
  const { t } = useTranslation('edit_profile')
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<UpdateUserInput>()
  const minRows = useBreakpointValue({ base: 3, md: 2 })
  const fieldLength = watch('bioLived')?.length || 0

  return (
    <Stack gap="8x">
      <Heading size="lg">{t('form.bio.fields.bioLived.title')}</Heading>
      <Textarea
        {...register('bioLived', {
          maxLength: { value: MAX_LENGTH, message: t('errors.bioLived.too_long') },
        })}
        label=""
        placeholder={t('form.bio.fields.bioLived.placeholder')}
        maxLength={MAX_LENGTH}
        minRows={minRows}
        errorMessage={errors.bioLived?.message}
        isInvalid={Boolean(errors.bioLived?.message)}
        helperText={fieldLength + ' / ' + MAX_LENGTH}
      />
      <DialogAndWizardActionsFooter>
        <IconButton
          type="button"
          icon={ArrowLeftIcon}
          variant="tertiary"
          label={t('commons:back')}
          onClick={() => goBack()}
        />
        <Button type="submit">{t('commons:next')}</Button>
      </DialogAndWizardActionsFooter>
    </Stack>
  )
}
