import { HomeTypeEnum } from '@qasa/graphql'
import type { TenantPreferencesHomeFragment } from '@qasa/graphql'
import type { TFunction } from 'i18next'

import type { SearchPreferenceMatchIndicatorProps } from '../../components/search-preference-match-indicator'
import type { ParsedProfileSearchPreference } from '../../utils/parse-profile-search-preference'

type GetHomeTypeAndSharingMatchDataParams = {
  t: TFunction
  homeType: ParsedProfileSearchPreference['homeType']
  shared: ParsedProfileSearchPreference['shared']
  matchingHome: Omit<TenantPreferencesHomeFragment, '__typename'>
}
export const getHomeTypeAndSharingMatchData = ({
  t,
  homeType,
  shared,
  matchingHome,
}: GetHomeTypeAndSharingMatchDataParams): SearchPreferenceMatchIndicatorProps => {
  const homeTypeMapping: Record<HomeTypeEnum, HomeTypeEnum> = {
    apartment: HomeTypeEnum.apartment,
    corridor: HomeTypeEnum.other, // Mapping 'corridor' to 'other'
    cottage: HomeTypeEnum.house, // Mapping 'cottage' to 'house'
    duplex: HomeTypeEnum.duplex,
    house: HomeTypeEnum.house,
    loft: HomeTypeEnum.apartment, // Mapping 'cottage' to 'apartment'
    other: HomeTypeEnum.other,
    own: HomeTypeEnum.own,
    room: HomeTypeEnum.room,
    terrace_house: HomeTypeEnum.terrace_house,
  }

  const mappedMatchingHomeType = homeTypeMapping[matchingHome.type]
  const isMatchingHomeType = homeType.length === 0 || homeType.includes(mappedMatchingHomeType)
  const isMatchingShared = (!matchingHome.shared && shared === 'onlyPrivate') || shared === 'all'

  const getDifferenceString = () => {
    if (!isMatchingHomeType && !isMatchingShared) {
      return t('home_type_and_shared.both_not_matching')
    } else if (!isMatchingShared) {
      return t('home_type_and_shared.shared_not_matching')
    } else if (!isMatchingHomeType) {
      return t('home_type_and_shared.home_type_not_matching')
    } else {
      return ''
    }
  }
  return {
    matchingLevel: isMatchingHomeType && isMatchingShared ? 'match' : 'no_match',
    differenceString: getDifferenceString(),
  }
}
