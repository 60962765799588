import { Stack, styled } from '@qasa/ui'
import type { BigSearchLongtermCardFragment } from '@qasa/graphql'
import { graphql } from '@qasa/graphql'
import type { PropsWithChildren } from 'react'
import { Fragment } from 'react'

import { Duration } from '../../../../components/duration'
import { FavoriteHomeButton } from '../../../favorite-home-button'
import { Carousel } from '../../../carousel'
import { BigDataCardBase } from '../../base/big-data-card-base'
import { HomeTag } from '../../../../components/home-tag'
import { SlashSeparatedData } from '../../../../components/slash-separated-data'
import { UnstyledButton } from '../../../../components/unstyled-button'
import { CURRENT_PLATFORM } from '../../../../env'

import { useBigSearchLongtermCard } from './big-search-longterm-card.logic'

const HomeTagWrapper = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing['4x'],
  left: theme.spacing['4x'],
}))

type BigSearchLongtermCardProps = {
  data: BigSearchLongtermCardFragment
  onPress?: () => void
}

graphql(`
  fragment BigSearchLongtermCard on Home {
    id
    rent
    minRent
    currency
    maxRent
    tenantBaseFee
    location {
      id
      locality
      route
      streetNumber
    }
    displayStreetNumber
    uploads {
      id
      url
      metadata {
        primary
        order
      }
    }
    user {
      uid
      proAgent
    }
    instantSignSetting {
      id
      enabled
    }
    type
    shared
    roomCount
    squareMeters
    minSquareMeters
    maxSquareMeters
    minRoomCount
    maxRoomCount
    numberOfHomes
    firsthand
    seniorHome
    studentHome
    corporateHome
    ...FavoriteHomeButton
    ...UseHomeBaseInfo
    duration {
      ...DurationComponent
    }
  }
`)
// On native wrapping the BigSearchLongtermCard with a Link disables interactions with the underlying FlatList in the Carousel
// This is an ugly solution to the problem where we wrap each item in the Carousel and the text with its own Pressable
// -@magnuslh 04-2024
function ButtonOrFragment({ children, onPress }: PropsWithChildren<{ onPress?: () => void }>) {
  return onPress ? (
    <UnstyledButton {...(CURRENT_PLATFORM === 'native' && { hitSlop: 16 })} onPress={onPress}>
      {children}
    </UnstyledButton>
  ) : (
    <Fragment>{children}</Fragment>
  )
}

export function BigSearchLongtermCard({ data, onPress }: BigSearchLongtermCardProps) {
  const { images, heading, hero, homeTagVariant, bulletData, duration } = useBigSearchLongtermCard({
    data,
  })

  return (
    <BigDataCardBase>
      <Carousel hasBorderRadius>
        {images.map((image, index) => (
          <ButtonOrFragment key={index} onPress={onPress}>
            <Carousel.Image src={image} loading={'lazy'} />
          </ButtonOrFragment>
        ))}
      </Carousel>
      {homeTagVariant && (
        <HomeTagWrapper>
          <HomeTag variant={homeTagVariant} />
        </HomeTagWrapper>
      )}
      <BigDataCardBase.FavoriteWrapper>
        <FavoriteHomeButton home={data} />
      </BigDataCardBase.FavoriteWrapper>
      <ButtonOrFragment onPress={onPress}>
        <BigDataCardBase.TextWrapper>
          <BigDataCardBase.Heading>{heading}</BigDataCardBase.Heading>
          <SlashSeparatedData gap="1x" data={bulletData} />
          <Duration duration={duration} />
          <BigDataCardBase.HeroData>{hero}</BigDataCardBase.HeroData>
        </BigDataCardBase.TextWrapper>
      </ButtonOrFragment>
    </BigDataCardBase>
  )
}
