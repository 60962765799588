import { graphql } from '@qasa/graphql'

export const EVALUATE_FOR_SHORTCUT = graphql(`
  mutation EvaluateHomeForShortcut($homeId: ID!) {
    evaluateHomeForShortcut(homeId: $homeId) {
      home {
        status
        id
      }
    }
  }
`)
