import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Textarea } from '@qasa/qds-ui'
import type { InputMaybe } from '@qasa/graphql'

import {
  containsEmailUrlOrPhoneNumberValidator,
  containsEmailOrPhoneNumberValidator,
} from '../../../helpers/validation'
import type { ProfileDataFormValues } from '../profile.types'

const BIO_MAX_LENGTH = 240

type BioFieldProps = {
  isFieldRequired?: boolean
  minLength?: number
  isProfessional: boolean
}

export function BioField({ isFieldRequired = false, minLength, isProfessional }: BioFieldProps) {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<ProfileDataFormValues['aboutMe']>()
  const { t } = useTranslation('edit_profile')

  const validator = isProfessional
    ? containsEmailOrPhoneNumberValidator
    : containsEmailUrlOrPhoneNumberValidator

  const validateErrorMessage = isProfessional
    ? 'disallow_content_with_email_or_phone'
    : 'disallow_content_with_email_url_or_phone'

  const fieldLength = watch('bio')?.length || 0

  return (
    <Textarea
      {...register('bio', {
        required: { value: isFieldRequired, message: t('errors.bio.required') },
        minLength: { value: minLength || 0, message: t('errors.bio.too_short', { amount: minLength || 0 }) },
        maxLength: { value: BIO_MAX_LENGTH, message: t('errors.bio.too_long') },
        validate: (value: InputMaybe<string> | undefined) =>
          validator()(value).valid || t(`validation_utils:error_messages.${validateErrorMessage}`),
      })}
      label={t('form.bio.fields.bio.title')}
      placeholder={t('form.bio.fields.bio.placeholder')}
      maxLength={BIO_MAX_LENGTH}
      errorMessage={errors.bio?.message}
      isInvalid={Boolean(errors.bio)}
      helperText={fieldLength + ' / ' + BIO_MAX_LENGTH}
    />
  )
}
