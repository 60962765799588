import {
  QASA_EXTERNAL_LINKS,
  QASA_FINLAND_EXTERNAL_LINKS,
  BLOCKET_EXTERNAL_LINKS,
  QASA_FRANCE_EXTERNAL_LINKS,
} from '@qasa/app/src/configs/external-links'
import type { ExternalLinks } from '@qasa/app/src/configs/external-links'

import { currentBrand } from '.'
import type { BrandType } from './index.types'

const externalLinks: Record<BrandType, ExternalLinks> = {
  qasa: QASA_EXTERNAL_LINKS,
  qasa_finland: QASA_FINLAND_EXTERNAL_LINKS,
  blocket: BLOCKET_EXTERNAL_LINKS,
  qasa_france: QASA_FRANCE_EXTERNAL_LINKS,
  dotcom: QASA_EXTERNAL_LINKS,
}
export const EXTERNAL_LINKS = externalLinks[currentBrand]
