import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { TenantAdStatusEnum } from '@qasa/graphql'

import { useProfileContext } from '../profile-context'
import { useUnpublishTenantAd } from '../hooks/use-unpublish-tenant-ad'
import { getIsSearchPreferencesFilled } from '../profile.utils'
import { useAuthContext } from '../../../context/auth-context'
import { LinkButton } from '../../../ui-shared/_core/link-button'
import { getPath } from '../../../routing/get-path'

import { ProfileCard } from './profile-card'
import { ProfileCardUspList } from './profile-card-usp-list'
import { PrivateCardActions } from './private-actions'

const UnpublishProfileButton = styled.button({
  textDecoration: 'underline',
})

export function ProfileCardPrivate() {
  const { t } = useTranslation('profile_card')
  const { tenantAdStatus, profile } = useProfileContext()
  const { unpublishTenantAd, isLoadingUnpublishTenantAd } = useUnpublishTenantAd()
  const { authBody } = useAuthContext()

  const hasPhoneNumberAndFamilyName = Boolean(authBody?.private?.phoneNumber && authBody.private.familyName)
  const isSearchPreferencesFilled = getIsSearchPreferencesFilled({ profile })
  const isProfilePublished = tenantAdStatus === TenantAdStatusEnum.published

  if (!isSearchPreferencesFilled || !hasPhoneNumberAndFamilyName) {
    return (
      <ProfileCard>
        <ProfileCard.Header>{t('private.missing_info.label')}</ProfileCard.Header>
        <ProfileCard.Description>{t(`private.missing_info.description`)}</ProfileCard.Description>
        <ProfileCardUspList translationArray={t('USPs.unpublished', { returnObjects: true })} />
        <LinkButton variant="primary" to={getPath('createTenantListing')}>
          {t('private.unpublished.cta_text')}
        </LinkButton>
      </ProfileCard>
    )
  }

  return (
    <ProfileCard>
      <div>
        <ProfileCard.Header>{t('private.title')}</ProfileCard.Header>
        <ProfileCard.Status />
        <ProfileCard.ExpireStatus />
      </div>
      <ProfileCard.Description>{t(`private.${tenantAdStatus}.description`)}</ProfileCard.Description>
      <ProfileCardUspList translationArray={t(`USPs.${tenantAdStatus}`, { returnObjects: true })} />
      <PrivateCardActions />
      {isProfilePublished && (
        <UnpublishProfileButton disabled={isLoadingUnpublishTenantAd} onClick={unpublishTenantAd}>
          {t('private.published.unpublish')}
        </UnpublishProfileButton>
      )}
    </ProfileCard>
  )
}
