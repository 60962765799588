import { Spacer, Stack, useBreakpointValue } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import { TenantAdStatusEnum } from '@qasa/graphql'

import { PageWidthContainer } from '../../ui-shared/_layout/width-containers'
import { isMobile } from '../../ui-styles/media-queries'
import { BackLink } from '../../ui-shared/_core/back-button'
import { useAuthContext } from '../../context/auth-context'

import { useProfileContext } from './profile-context'
import { ProfileCardFooter } from './profile-card/profile-card-footer'
import { ContactTenantDialog } from './contact-tenant/contact-tenant-dialog'
import { ProfileBody } from './profile-body'
import { ProfileCardPrivate } from './profile-card/profile-card-private'
import { ProfileCardPublic } from './profile-card/profile-card-public'
import { ProfileEdit } from './profile-edit'
import { ProfileTopSection } from './profile-top-section'

export const PROFILE_HOME_ID_PARAM_NAME = 'homeId'

const ColumnContent = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing['16x'],
  [theme.mediaQueries.mdUp]: {
    flexDirection: 'row',
  },
}))
const Column = styled.div({
  flex: 1,
})
const DesktopOnlyColumn = styled(Column)({
  minWidth: 320,
  maxWidth: 320,
  [isMobile]: {
    display: 'none',
  },
})
const CardWrapper = styled.div(({ theme }) => ({
  position: 'sticky',
  top: 108,
  padding: 24,
  boxShadow: theme.shadows.lg,
  borderRadius: theme.radii.lg,
}))

export function Profile() {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { authBody } = useAuthContext()
  const { state } = useLocation<{ previousPath?: string } | undefined>()
  const previousPath = state?.previousPath
  const { profile, isOwnProfile } = useProfileContext()

  const isOwnProfileAndTenant = profile.tenant && isOwnProfile
  const isPublishedTenant = profile.tenantAd?.status === TenantAdStatusEnum.published

  const hasProfileCard = isOwnProfileAndTenant || (isPublishedTenant && !authBody?.tenant)

  return (
    <>
      <PageWidthContainer maxWidth={hasProfileCard ? 'wider' : undefined}>
        {previousPath ? (
          <>
            <Spacer size="4x" />
            <BackLink to={previousPath} />
            <Spacer size="4x" />
          </>
        ) : (
          <Spacer size="16x" />
        )}
        <ProfileTopSection isCentered={isMobile} />
        <Spacer size="12x" />
        <ColumnContent>
          <Column>
            <ProfileBody />
          </Column>
          {hasProfileCard && (
            <DesktopOnlyColumn>
              <CardWrapper>{isOwnProfile ? <ProfileCardPrivate /> : <ProfileCardPublic />}</CardWrapper>
            </DesktopOnlyColumn>
          )}
        </ColumnContent>
        {isOwnProfile && <ProfileEdit />}
      </PageWidthContainer>
      <ContactTenantDialog tenant={profile} />
      {isMobile && hasProfileCard && <ProfileCardFooter />}
    </>
  )
}
