import { compile } from 'path-to-regexp'
import queryString from 'query-string'

type Props = {
  path: string
  params?: Record<string, string | undefined>
  queryParams?: Record<string, string | boolean | number | null | undefined>
}

/**
 * Based on a path template (e.g. /home/:id), a set of params (e.g. { id: '123' }) and a set of query params (e.g. { showGallery: 'true' })
 * Generate a valid url (e.g. /home/123?showGallery=true)
 * Prefer Link, useLinkTo and getPath over this function
 * This is a custom implementation of react-router's generatePath using path-to-regexp which react-router uses internally
 * Created to avoid creating a dependency on react-router in native
 * -- magnuslh 06-2023
 */
export const generatePath = ({ path, params, queryParams }: Props) => {
  const pathFunction = compile(path)
  if (queryParams) {
    return `${pathFunction(params)}?${queryString.stringify(queryParams)}`
  }
  return pathFunction(params)
}
