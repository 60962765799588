import {
  ArrowLeftIcon,
  Button,
  Heading,
  HintBox,
  IconButton,
  Label,
  Paragraph,
  Stack,
  TextField,
} from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { HousingSituationAgreementTypeEnum, type UpdateUserInput } from '@qasa/graphql'
import { useQuery } from '@apollo/client'

import { HOMEQ_LANDLORDS } from '../landlord-combobox.gql'
import type { HousingSituationStepProps } from '../housing-situation'
import { LandlordCombobox } from '../landlord-combobox'
import { ProfileBadge } from '../../../ui-shared/profile-badges'
import { UserDataVisibilityHintbox } from '../../../ui-shared/user-data-visibility-hintbox'
import { DialogAndWizardActionsFooter } from '../../../ui-shared/dialog-and-wizard-actions-footer'
import { swedishPhoneFullValidator } from '../../../helpers/validation'

import { ReferenceNotSupported } from './reference-not-supported'

const HOUSING_TYPES_THAT_SUPPORT_REFERENCES = [
  HousingSituationAgreementTypeEnum.student_housing,
  HousingSituationAgreementTypeEnum.co_living,
  HousingSituationAgreementTypeEnum.first_hand_renting,
]
export const isReferenceSupportedForHousingType = (agreementType: HousingSituationAgreementTypeEnum) => {
  return HOUSING_TYPES_THAT_SUPPORT_REFERENCES.includes(agreementType)
}

export function ReferenceStep({ goBack, goNext, requiredValues }: HousingSituationStepProps) {
  const { t } = useTranslation('edit_profile')
  const {
    register,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useFormContext<UpdateUserInput>()
  const { data, loading: isLoading, error } = useQuery(HOMEQ_LANDLORDS)

  const { housingSituation } = getValues()
  const { agreementType, landlordCity } = housingSituation || {}

  if (!agreementType || !isReferenceSupportedForHousingType(agreementType)) {
    return <ReferenceNotSupported agreementType={agreementType} goNext={goNext} goBack={goBack} />
  }
  const formattedAgreementType = t(
    `profile_housing_block:current_agreement_type.options.${agreementType ? agreementType : 'no_agreement_type'}`,
  )
  const formattedHousing = landlordCity
    ? t('profile_housing_block:current_agreement_type.type_with_location', {
        agreementType: formattedAgreementType,
        location: landlordCity,
      })
    : formattedAgreementType

  const landlordName = watch('housingSituation.landlordName')
  const isLandlordNameRequired = requiredValues.landlordName
  const isLandlordPhoneRequired = requiredValues.landlordPhoneNumber
  const isMissingRequiredFields = (!landlordName && isLandlordNameRequired) || !isValid

  return (
    <Stack gap="6x">
      <Heading size="lg">{t('form.housing_situation.housing_reference.title')}</Heading>
      <Label>{formattedHousing}</Label>
      <Stack gap="4x">
        <LandlordCombobox
          landlordList={data?.homeqLandlordCompanies || []}
          isLoading={isLoading}
          error={error}
        />
        <TextField
          {...register('housingSituation.landlordPhoneNumber', {
            required: isLandlordPhoneRequired,
            validate: (value) => !value || swedishPhoneFullValidator(value).valid,
          })}
          label={t('form.housing_situation.housing_reference.landlord_phone.label')}
          placeholder={t('form.housing_situation.housing_reference.landlord_phone.placeholder')}
          isInvalid={Boolean(errors.housingSituation?.landlordPhoneNumber)}
          errorMessage={t('form.housing_situation.housing_reference.landlord_phone.error')}
        />
      </Stack>
      <Stack gap="3x">
        <HintBox>
          <Stack direction="row" gap="2x" alignItems="center">
            <ProfileBadge confirmed />
            <Paragraph size="sm">
              {t('form.housing_situation.housing_reference.profile_badge_description')}
            </Paragraph>
          </Stack>
        </HintBox>
        <UserDataVisibilityHintbox mode="private" />
      </Stack>
      <DialogAndWizardActionsFooter>
        <IconButton
          type="button"
          icon={ArrowLeftIcon}
          variant="tertiary"
          label={t('commons:back')}
          onClick={() => goBack()}
        />
        <Button type="submit" isDisabled={isMissingRequiredFields}>
          {t('commons:next')}
        </Button>
      </DialogAndWizardActionsFooter>
    </Stack>
  )
}
