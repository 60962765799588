import { graphql } from '@qasa/graphql'

export const SCHIBSTED_LOGIN = graphql(`
  mutation SchibstedLogin($input: SchibstedLoginInput!) {
    schibstedLogin(input: $input) {
      payload {
        accessToken
        user {
          ...BaseUser
          ...UserPrivateFields
          affiliateCode {
            code
            discountFor
            discountType
            discountValue
            expirationDate
            affiliateCodeType
          }
          ...UserBio
          ...UserProfileCompleteness
          smoker
          creditReport {
            id
            fetchStatus
          }
          applicationStats {
            uid
            count
            inContactCount
            locales
            priceMax
            priceMin
            roomMax
            roomMin
          }
        }
      }

      errors {
        field
        codes
      }
    }
  }
`)
