import { Paragraph } from '@qasa/ui'
import type { Theme } from '@qasa/qds-ui'

const SLASH = '/'

type Sizes = keyof Theme['typography']['body']
type Color = keyof Theme['colors']['text']

type Props = { size?: Sizes; color?: Color }

export function SlashSeparator({ size, color }: Props) {
  return (
    <Paragraph size={size} color={color}>
      {SLASH}
    </Paragraph>
  )
}
