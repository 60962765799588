import { Select, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { HomeRentalTypeEnum } from '@qasa/graphql'

import { BooleanInput } from '../../../ui-shared/_core/controls/boolean-input'
import { FieldEnum } from '../edit-listing.types'
import { useListingContext } from '../listing-context'
import { SectionWrapper } from '../section-wrapper'
import { getTraits } from '../trait-declarations/get-traits'
import type { AmenityGroupType } from '../trait-declarations/types'
import { AmenityGroupTitleEnum } from '../trait-declarations/types'

import { createNumberOptions } from './use-get-areas-section-fields'

export function RulesAndAccessibilitySection() {
  const { t } = useTranslation('listing')
  const {
    store: {
      values: { traits: selectedTraits, rentalType, tenantCount, market },
    },
    dispatch,
    getErrorMessage,
  } = useListingContext()
  const traits = getTraits({
    isVacationHome: rentalType === HomeRentalTypeEnum.vacation,
    marketName: market?.name,
  }).filter(({ title }) => [AmenityGroupTitleEnum.RULES, AmenityGroupTitleEnum.ACCESSIBILITY].includes(title))

  const tenantCountOptions = createNumberOptions(30).map(({ value, label }) => (
    <Select.Option key={value} value={value}>
      {label}
    </Select.Option>
  ))

  const handleTenantCountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      action: {
        type: FieldEnum.TenantCount,
        payload: parseInt(e.target.value, 10) || null,
      },
    })
  }

  return (
    <SectionWrapper
      title={t('section_headings.rules_and_accessibility')}
      description={t('rules_and_accessibility.description')}
    >
      <Stack gap="8x">
        <Select
          value={tenantCount ?? 0}
          label={t('size.tenant_count_label')}
          placeholder={t('size.tenant_count_placeholder')}
          isInvalid={Boolean(getErrorMessage({ field: FieldEnum.TenantCount }))}
          errorMessage={getErrorMessage({ field: FieldEnum.TenantCount }) ?? undefined}
          onChange={handleTenantCountChange}
        >
          {tenantCountOptions}
        </Select>
        {traits.map((amenityGroup: AmenityGroupType) => {
          return (
            <Stack gap="6x" key={amenityGroup.title}>
              {amenityGroup.traits.map(({ trait }) => {
                return (
                  <BooleanInput
                    key={trait}
                    label={t(`home_amenities:${trait}`)}
                    name={t(`home_amenities:${trait}`)}
                    value={selectedTraits?.includes(trait) ?? false}
                    positiveLabel={t('commons:yes')}
                    negativeLabel={t('commons:no')}
                    onChange={(value) => {
                      dispatch({
                        action: {
                          type: FieldEnum.Traits,
                          payload: value
                            ? [...(selectedTraits || []), trait]
                            : selectedTraits?.filter((selectedTrait) => trait !== selectedTrait) || [],
                        },
                      })
                    }}
                  />
                )
              })}
            </Stack>
          )
        })}
      </Stack>
    </SectionWrapper>
  )
}
