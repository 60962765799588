import type { HomeViewQuery } from '@qasa/graphql'

type IsBlockListingProps = {
  property?: UNSAFE_DeepNonNullableObject<HomeViewQuery>['home']['property'] | null
  numberOfHomes: UNSAFE_DeepNonNullableObject<HomeViewQuery>['home']['numberOfHomes']
}
/* TODO: Remove this function and file, this is only used in p2/home which is now only for vacation homes, which cannot be blocklistings */
export function getIsBlockListing({ property, numberOfHomes }: IsBlockListingProps) {
  return property
    ? Boolean(property.numberOfHomes && property.numberOfHomes > 1)
    : Boolean(numberOfHomes && numberOfHomes > 1)
}
