import { Link, useLocation } from 'react-router-dom'
import { stringify } from 'query-string'
import { HomeRentalTypeEnum } from '@qasa/graphql'
import { useQuery } from '@apollo/client'
import { ampliExperiment } from '@qasa/ampli/app'

import { BigSearchVacationCard, BigSearchLongtermCard } from '../../../modules/big-data-card'
import { useEmbeddedFindHome } from '../hooks/use-embedded-find-home.web'
import { getPath } from '../../../navigation/get-path'
import type { FindHomeNode } from '../../../types/find-home'
import { useRegionContext } from '../../../contexts/region'
import { DotcomHomeLink } from '../dotcom-home-link'

import { BLOCK_LISTING_DATA_FOR_HOME } from './home-block-listing-data.gql'

type HomeItemProps = {
  home: FindHomeNode
  onHover?: (hovering: boolean) => void
  checkInDate?: string | null
  checkOutDate?: string | null
  shouldOpenInNewTab?: boolean
  hasFilteredOnPricePerNight?: boolean
  isQasaShortcutHome?: boolean
  onClick?: () => void
}

// This component is from p2 ui-shared and should perhaps be made into a full component in app and exported out of the package.
export function HomeItem({
  home,
  onHover,
  checkInDate,
  checkOutDate,
  shouldOpenInNewTab = false,
  hasFilteredOnPricePerNight = false,
  onClick,
}: HomeItemProps) {
  const isFindHomeEmbedded = useEmbeddedFindHome()

  const { pathname, search } = useLocation()
  const { region } = useRegionContext()
  const searchQueryString =
    stringify({ checkInDate, checkOutDate }, { skipNull: true, skipEmptyString: true }) || undefined

  const { shouldGetDeferredBlockListingData, id } = home

  const { data: blockListingData } = useQuery(BLOCK_LISTING_DATA_FOR_HOME, {
    variables: { id },
    skip: !shouldGetDeferredBlockListingData,
  })

  const homeData: FindHomeNode = {
    ...home,
    ...blockListingData?.home,
  }

  const isVacationHome = home.rentalType === HomeRentalTypeEnum.vacation
  const HomeCard = isVacationHome ? (
    <BigSearchVacationCard data={homeData} shouldDisplayPriceRange={hasFilteredOnPricePerNight} />
  ) : (
    <BigSearchLongtermCard data={homeData} />
  )

  if (
    region === 'international' &&
    ampliExperiment.getVariant('dotcom-bypass-country-selector')?.value !== 'on'
  ) {
    return (
      <DotcomHomeLink
        homeId={home.id}
        homeLocale={home.location.countryCode}
        onMouseEnter={() => {
          if (onHover) onHover(true)
        }}
        onMouseLeave={() => {
          if (onHover) onHover(false)
        }}
        target={shouldOpenInNewTab || isFindHomeEmbedded ? '_blank' : '_self'}
        onClick={onClick}
      >
        {HomeCard}
      </DotcomHomeLink>
    )
  }

  return (
    <Link
      to={{
        pathname: getPath('Home', { id: home.id }),
        state: {
          fromLocation: pathname + search,
        },
        search: searchQueryString,
      }}
      onMouseEnter={() => {
        if (onHover) onHover(true)
      }}
      onMouseLeave={() => {
        if (onHover) onHover(false)
      }}
      target={shouldOpenInNewTab || isFindHomeEmbedded ? '_blank' : '_self'}
      onClick={onClick}
    >
      {HomeCard}
    </Link>
  )
}
