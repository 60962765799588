import { LoadingDots, Stack, styled } from '@qasa/ui'

import { useAppTranslation } from '../../../../contexts/i18next'
import { useSearchHistoryContext } from '../search-history-context'
import { Error } from '../../../../components/error'
import { SearchHistoryHeading } from '../search-history.parts'
import type { SearchHistoryProps } from '../search-history'

import { SavedSearchesListItem } from './saved-searches-list-item'
import { SavedSearchesListWrapper } from './saved-searches-list-wrapper'

const Wrapper = styled(Stack)({
  alignItems: 'flex-start',
})
const ListWrapper = styled(SavedSearchesListWrapper)({
  width: '100%',
})
export function SavedSearches({ onListItemPress }: SearchHistoryProps) {
  const { t } = useAppTranslation('saved_searches')

  const { savedSearches, isLoading, error } = useSearchHistoryContext()

  if (isLoading) {
    return <LoadingDots />
  }

  if (error) {
    return <Error error={error} />
  }

  if (!savedSearches || savedSearches.length === 0) {
    return null
  }

  return (
    <Wrapper gap="4x">
      <SearchHistoryHeading>{t('list_title')}</SearchHistoryHeading>
      <ListWrapper>
        {savedSearches.map((savedSearch) => (
          <SavedSearchesListItem
            onListItemPress={onListItemPress}
            key={savedSearch.id}
            savedSearch={savedSearch}
          />
        ))}
      </ListWrapper>
    </Wrapper>
  )
}
