import { Heading, Paragraph, Stack, useBreakpointValue } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

export function TitleAndDescriptionSection() {
  const { t } = useTranslation('listing')
  const isLgOrAbove = useBreakpointValue({ base: false, lg: true })

  return (
    <Stack gap="3x">
      <Heading size={isLgOrAbove ? 'lg' : 'md'}>{t('find_tenant_intro.title')}</Heading>
      <Paragraph size="lg">{t('find_tenant_intro.description')}</Paragraph>
    </Stack>
  )
}
