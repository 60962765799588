import type { ReactNode } from 'react'
import { useContext, useEffect, useState, createContext } from 'react'

import { setHotjarConsent } from '../../vendor/hotjar'
import type { UnsubscribeFunction } from '../../vendor/schibsted-sourcepoint-client'
import {
  initSchibstedCookieConsent,
  Categories,
  convertSourcepointValueToBoolean,
  getIsSourcepointClientActive,
  subscribeToSourcepointCategoryConsent,
} from '../../vendor/schibsted-sourcepoint-client'
import { toggleAmplitudeActive } from '../../vendor/ampli'
import { useToggles } from '../../configs/use-toggles'
import { setGoogleConsentValues } from '../../vendor/google-analytics'

import { ConsentCookie } from './consent-cookie-helper'

type ServiceConsentValues = {
  analytics: boolean
  marketing: boolean
  advertising?: boolean
}
type UserConsentContextType = {
  getServiceConsentValues: () => ServiceConsentValues
  setServiceConsentValues: (consentValues: SetCookieConsentParams) => void
}
const INITIAL_CONSENT_VALUES = {
  analytics: false,
  marketing: false,
  advertising: false,
}

const UserConsentContext = createContext<UserConsentContextType>({
  getServiceConsentValues: () => INITIAL_CONSENT_VALUES,
  setServiceConsentValues: () => ({}),
})

function useCurrentServiceConsentValues() {
  const [consentValues, setConsentValues] = useState<ServiceConsentValues>(INITIAL_CONSENT_VALUES)
  const toggles = useToggles()
  const [isSourcePointInitialized, setIsSourcePointInitialized] = useState(getIsSourcepointClientActive())

  useEffect(() => {
    if (toggles.usesSchibstedSourcepointClient && !isSourcePointInitialized) {
      initSchibstedCookieConsent().then(() => setIsSourcePointInitialized(true))
    }
  }, [isSourcePointInitialized, toggles.usesSchibstedSourcepointClient])

  useEffect(() => {
    let unsubscribeAnalyticsFunction: UnsubscribeFunction | null = null
    let unsubscribeMarketingFunction: UnsubscribeFunction | null = null
    let unsubscribeAdvertisingFunction: UnsubscribeFunction | null = null
    if (isSourcePointInitialized) {
      //Analytics listener
      unsubscribeAnalyticsFunction = subscribeToSourcepointCategoryConsent({
        category: Categories.analytics,
        callbackFunction: (value) => {
          const hasConsent = convertSourcepointValueToBoolean({ value })
          setConsentValues((prevValues) => ({
            ...prevValues,
            analytics: hasConsent,
          }))
        },
      })
      //Advertising listener
      unsubscribeAdvertisingFunction = subscribeToSourcepointCategoryConsent({
        category: Categories.advertising,
        callbackFunction: (value) => {
          const hasConsent = convertSourcepointValueToBoolean({ value })
          setConsentValues((prevValues) => ({
            ...prevValues,
            advertising: hasConsent,
          }))
        },
      })
      //Marketing listener
      unsubscribeMarketingFunction = subscribeToSourcepointCategoryConsent({
        category: Categories.marketing,
        callbackFunction: (value) => {
          const hasConsent = convertSourcepointValueToBoolean({ value })
          setConsentValues((prevValues) => ({
            ...prevValues,
            marketing: hasConsent,
          }))
        },
      })
    } else {
      const hasConsentedToAnalytics = Boolean(ConsentCookie.get().analytics)
      const hasConsentedToMarketing = Boolean(ConsentCookie.get().marketing)
      setConsentValues((prevValues) => ({
        ...prevValues,
        analytics: hasConsentedToAnalytics,
        marketing: hasConsentedToMarketing,
      }))
    }
    return () => {
      unsubscribeAnalyticsFunction?.()
      unsubscribeAdvertisingFunction?.()
      unsubscribeMarketingFunction?.()
    }
  }, [isSourcePointInitialized])

  return consentValues
}

type ToggleThirdPartyServicesParams = {
  hasConsentedToAnalytics: boolean
  hasConsentedToMarketing: boolean
  isUserAction?: boolean
}
const toggleThirdPartyServices = ({
  hasConsentedToAnalytics,
  hasConsentedToMarketing,
  isUserAction = false,
}: ToggleThirdPartyServicesParams) => {
  /* Analytics */
  toggleAmplitudeActive({ shouldActivate: hasConsentedToAnalytics, shouldTrackInitialPageView: isUserAction })
  setHotjarConsent({ hasConsented: hasConsentedToAnalytics })

  /* Analytics & Marketing */
  setGoogleConsentValues({ hasConsentedToAnalytics, hasConsentedToMarketing })
}

type SetCookieConsentParams = {
  hasConsentedToAnalytics: boolean
  hasConsentedToMarketing: boolean
}
const setCookieConsent = ({ hasConsentedToAnalytics, hasConsentedToMarketing }: SetCookieConsentParams) => {
  ConsentCookie.set({
    analytics: hasConsentedToAnalytics,
    marketing: hasConsentedToMarketing,
    // NOTE: Temporarily maintaining the old fields until we're sure we can remove them
    adrollConsent: hasConsentedToMarketing,
    facebookConsent: hasConsentedToMarketing,
    googleConsent: hasConsentedToAnalytics,
    amplitudeConsent: hasConsentedToAnalytics,
  })
  toggleThirdPartyServices({ hasConsentedToAnalytics, hasConsentedToMarketing, isUserAction: true })
}

export function UserConsentProvider({ children }: { children: ReactNode }) {
  const toggles = useToggles()
  const currentServiceConsentValues = useCurrentServiceConsentValues()

  useEffect(() => {
    toggleThirdPartyServices({
      hasConsentedToAnalytics: Boolean(currentServiceConsentValues.analytics),
      hasConsentedToMarketing: Boolean(currentServiceConsentValues.marketing),
    })
  }, [currentServiceConsentValues.analytics, currentServiceConsentValues.marketing])

  /**
   * TODO: make it more clear that currentServiceConsentValues
   * is only guaranteed to be current when using Schibsted sourcepoint client,
   * since we don't subscribe to changed cookie values.
   **/
  const getServiceConsentValues = () => {
    return toggles.usesSchibstedSourcepointClient
      ? currentServiceConsentValues
      : {
          analytics: Boolean(ConsentCookie.get().analytics),
          marketing: Boolean(ConsentCookie.get().marketing),
        }
  }

  const setServiceConsentValues = toggles.usesSchibstedSourcepointClient ? () => ({}) : setCookieConsent

  return (
    <UserConsentContext.Provider value={{ getServiceConsentValues, setServiceConsentValues }}>
      {children}
    </UserConsentContext.Provider>
  )
}
export const useUserConsentContext = () => useContext<UserConsentContextType>(UserConsentContext)
