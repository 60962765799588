import i18next from 'i18next'
import { isBefore, isSameDay, isToday } from 'date-fns'
import { useMemo } from 'react'
import { formatNumber } from '@qasa/app/src/utils/number'
import { ElectricityFeePaymentOptionEnum, HomeRentalTypeEnum } from '@qasa/graphql'

import {
  combineValidators,
  onlyNumbers,
  noNumbers,
  requiredBoolean,
  min,
  max,
  required,
  maxLength,
  postalCodeValidator,
  minLength,
  containsEmailUrlOrPhoneNumberValidator,
  containsEmailOrPhoneNumberValidator,
  MAX_WATER_COST_PER_TENANT,
  MAX_CLEANING_FEE,
} from '../../helpers/validation'
import { ENV } from '../../helpers/env'

import type { ListingStoreValues } from './edit-listing.types'
import { FieldEnum } from './edit-listing.types'
import { getIsProbablyFinlandMarket } from './listing.utils'

const LOCATION_IQ_KEY = ENV.LOCATIONIQ_KEY
const LOCATION_IQ_REVERSE_GEOCODING_URL = `https://eu1.locationiq.com/v1/reverse.php?key=${LOCATION_IQ_KEY}&zoom=0&format=json`

type ValidationResult = string[] | Promise<string[]>
export const useGetErrors = ({
  values,
}: {
  values: ListingStoreValues
}): Partial<Record<FieldEnum, ValidationResult>> => {
  const {
    isProfessional,
    shared: isShared,
    companyName,
    orgNumber,
    type,
    tenureType,
    firsthand, //eslint-disable-line @typescript-eslint/naming-convention
    seniorHome, //eslint-disable-line @typescript-eslint/naming-convention
    studentHome, //eslint-disable-line @typescript-eslint/naming-convention
    corporateHome, //eslint-disable-line @typescript-eslint/naming-convention
    roomCount,
    minRoomCount,
    maxRoomCount,
    squareMeters,
    minSquareMeters,
    maxSquareMeters,
    numberOfHomes,
    tenantCount,
    toiletCount,
    bedCount,
    bedroomCount,
    route,
    streetNumber,
    postalCode,
    locality,
    apartmentNumber,
    duration,
    ownHome, //eslint-disable-line @typescript-eslint/naming-convention
    position,
    title,
    description,
    houseRules,
    waterFeePerTenant,
    electricityFee,
    rent,
    floor,
    buildingFloors,
    isExternal,
    externalApplicationUrl,
    externalEmail,
    externalInfoPage,
    responsibleForCleaning,
    cleaningFee,
    cancellationPolicyName,
    minNights,
    rentalType,
    countryCode,
    market,
    currency,
  } = values

  const isVacationHome = rentalType === HomeRentalTypeEnum.vacation
  const isFinlandMarket = getIsProbablyFinlandMarket({ countryCode, marketName: market?.name })

  const contractTypeValidator = useMemo(() => {
    const contractTypes = [firsthand, studentHome, seniorHome, corporateHome]
    /* This is different on Qasa Finland mainly since there is no first-hand option there */
    if (isProfessional && numberOfHomes && numberOfHomes > 1 && !isFinlandMarket) {
      const contractTypeError = contractTypes.some(Boolean)
        ? []
        : [i18next.t('validation_utils:error_messages.required')]
      return contractTypeError
    }
    return []
  }, [firsthand, studentHome, seniorHome, corporateHome, isProfessional, numberOfHomes, isFinlandMarket])

  return {
    [FieldEnum.IsProfessional]: useMemo(() => {
      const error = requiredBoolean()(isProfessional).message
      return error ? [error] : []
    }, [isProfessional]),

    [FieldEnum.Shared]: useMemo(() => {
      const error = requiredBoolean()(isShared).message
      return error ? [error] : []
    }, [isShared]),

    [FieldEnum.CompanyName]: useMemo(() => {
      const error = required()(companyName).message
      return error ? [error] : []
    }, [companyName]),

    [FieldEnum.OrganizationNumber]: useMemo(() => {
      const error = required()(orgNumber).message
      return error ? [error] : []
    }, [orgNumber]),

    [FieldEnum.Type]: useMemo(() => {
      const error = required()(type).message
      return error ? [error] : []
    }, [type]),

    [FieldEnum.TenureType]: useMemo(() => {
      const error = required()(tenureType).message
      return error ? [error] : []
    }, [tenureType]),

    [FieldEnum.Firsthand]: contractTypeValidator,
    [FieldEnum.Corporate]: contractTypeValidator,
    [FieldEnum.Student]: contractTypeValidator,
    [FieldEnum.Senior]: contractTypeValidator,

    [FieldEnum.RoomCount]: useMemo(() => {
      const error = required()(roomCount).message
      return error ? [error] : []
    }, [roomCount]),
    [FieldEnum.MinRoomCount]: useMemo(() => {
      const error = required()(minRoomCount).message
      return error ? [error] : []
    }, [minRoomCount]),
    [FieldEnum.MaxRoomCount]: useMemo(() => {
      const error = required()(maxRoomCount).message

      if (error) return [error]

      if (minRoomCount && maxRoomCount && maxRoomCount < minRoomCount) {
        return [i18next.t('listing:validation.invalid_room_count_range')]
      }

      return []
    }, [minRoomCount, maxRoomCount]),

    [FieldEnum.SquareMeters]: useMemo(() => {
      // required number greater than 1
      const validator = combineValidators(min({ min: 1 }), onlyNumbers())
      const error = validator(squareMeters).message

      return error ? [error] : []
    }, [squareMeters]),

    [FieldEnum.MinSquareMeters]: useMemo(() => {
      // required number greater than 1
      const validator = combineValidators(min({ min: 1 }), onlyNumbers())
      const error = validator(minSquareMeters).message

      return error ? [error] : []
    }, [minSquareMeters]),
    [FieldEnum.MaxSquareMeters]: useMemo(() => {
      // required number greater than 1
      const validator = combineValidators(min({ min: 1 }), onlyNumbers())
      const error = validator(maxSquareMeters).message

      if (error) return [error]

      if (minSquareMeters && maxSquareMeters && maxSquareMeters < minSquareMeters) {
        return [i18next.t('listing:validation.invalid_square_meter_range')]
      }

      return []
    }, [maxSquareMeters, minSquareMeters]),
    [FieldEnum.NumberOfHomes]: useMemo(() => {
      const error = required()(numberOfHomes).message
      return error ? [error] : []
    }, [numberOfHomes]),

    [FieldEnum.TenantCount]: useMemo(() => {
      const error = required()(tenantCount).message
      return error ? [error] : []
    }, [tenantCount]),

    [FieldEnum.ToiletCount]: useMemo(() => {
      const error = required()(toiletCount).message
      return error ? [error] : []
    }, [toiletCount]),

    [FieldEnum.BedroomCount]: useMemo(() => {
      const error = required()(bedroomCount).message
      return error ? [error] : []
    }, [bedroomCount]),

    [FieldEnum.BedCount]: useMemo(() => {
      const error = required()(bedCount).message
      return error ? [error] : []
    }, [bedCount]),

    [FieldEnum.Route]: useMemo(() => {
      const validator = combineValidators(required(), noNumbers())
      const error = validator(route).message
      return error ? [error] : []
    }, [route]),
    [FieldEnum.StreetNumber]: useMemo(() => {
      const error = required()(streetNumber).message
      return error ? [error] : []
    }, [streetNumber]),
    [FieldEnum.PostalCode]: useMemo(() => {
      const validator = combineValidators(required(), postalCodeValidator())
      const error = validator(postalCode).message
      return error ? [error] : []
    }, [postalCode]),
    [FieldEnum.Locality]: useMemo(() => {
      const error = required()(locality).message
      return error ? [error] : []
    }, [locality]),
    [FieldEnum.ApartmentNumber]: useMemo(() => {
      const error = maxLength({ max: 15 })(apartmentNumber).message
      return error ? [error] : []
    }, [apartmentNumber]),
    [FieldEnum.Duration]: useMemo(() => {
      const { startOptimal, endOptimal, endUfn, startAsap } = duration // eslint-disable-line @typescript-eslint/naming-convention
      const startDate = startOptimal ? new Date(startOptimal) : null
      const endDate = endOptimal ? new Date(endOptimal) : null
      const hasEndDateBeforeStartDate =
        startDate && endDate && (isBefore(endDate, startDate) || isSameDay(startDate, endDate))
      const isEndingToday = endDate && isToday(endDate)

      if (hasEndDateBeforeStartDate || isEndingToday) {
        return [i18next.t('listing:validation.end_date_is_not_after_start_date')]
      }
      if (!startOptimal && !startAsap) {
        return [i18next.t('listing:validation.required_start_date')]
      }
      if (!endOptimal && !endUfn) {
        return [i18next.t('listing:validation.required_end_date')]
      }
      return []
    }, [duration]),

    [FieldEnum.OwnHome]: useMemo(() => {
      if (isProfessional) {
        return []
      }
      const error = requiredBoolean()(ownHome).message
      return error ? [error] : []
    }, [ownHome, isProfessional]),

    [FieldEnum.Position]: useMemo(() => {
      // required numeric
      const { latitude, longitude } = position
      if (!latitude || !longitude) {
        return [i18next.t('listings:validation.no_position')]
      } else {
        return fetch(`${LOCATION_IQ_REVERSE_GEOCODING_URL}&lat=${latitude}&lon=${longitude}`)
          .then((resp) => resp.json())
          .then((result) => {
            // NOTE: Response from reverse locationIq has country_code in lower case
            const lowerCaseCountryCode = countryCode?.toLowerCase()
            if (result.address?.country_code !== lowerCaseCountryCode) {
              return [
                i18next.t('listing:validation.position_out_of_bounds', {
                  country: i18next.t(`commons:countries.${lowerCaseCountryCode}`),
                }),
              ]
            }
            return []
          })
      }
    }, [countryCode, position]),

    [FieldEnum.Title]: useMemo(() => {
      // max 60  characters
      const validator = combineValidators(required(), minLength({ min: 2 }), maxLength({ max: 60 }))
      const error = validator(title).message
      return error ? [error] : []
    }, [title]),

    [FieldEnum.Description]: useMemo(() => {
      // max 30 000 characters
      // NOTE: this might be temporary for vacation pre-release, we need to decide on the requirements for later and fix it
      if (!isVacationHome && !description) {
        return []
      }

      const contentValidator = isProfessional
        ? containsEmailOrPhoneNumberValidator
        : containsEmailUrlOrPhoneNumberValidator

      const validator = combineValidators(
        required(),
        minLength({ min: 2 }),
        maxLength({ max: 3000 }),
        contentValidator(),
      )

      const error = validator(description).message
      return error ? [error] : []
    }, [description, isVacationHome, isProfessional]),

    [FieldEnum.HouseRules]: useMemo(() => {
      // max 30 000 characters
      if (!houseRules) {
        return []
      }
      const error = maxLength({ max: 3000 })(houseRules).message
      return error ? [error] : []
    }, [houseRules]),

    // TODO: how to handle images validation ?
    // case 'IMAGES': {
    //   // jpeg / png / svg / gif 1kb <> 8mb
    //   return () => []
    // }

    [FieldEnum.ResponsibleForCleaning]: useMemo(() => {
      const error = required()(responsibleForCleaning).message
      return error ? [error] : []
    }, [responsibleForCleaning]),

    [FieldEnum.CleaningFee]: useMemo(() => {
      const validator = combineValidators(
        required(),
        min({ min: 0 }),
        max({ max: MAX_CLEANING_FEE }),
        onlyNumbers(),
      )
      const error = validator(cleaningFee).message
      return error ? [error] : []
    }, [cleaningFee]),

    [FieldEnum.CancellationPolicyName]: useMemo(() => {
      const error = required()(cancellationPolicyName).message
      return error ? [error] : []
    }, [cancellationPolicyName]),

    [FieldEnum.MinNights]: useMemo(() => {
      const error = required()(minNights).message
      return error ? [error] : []
    }, [minNights]),

    [FieldEnum.Rent]: useMemo(() => {
      //required and >= 0
      const validator = combineValidators(required(), min({ min: 1 }))
      const error = validator(rent).message
      return error ? [error] : []
    }, [rent]),

    [FieldEnum.Floor]: useMemo(() => {
      if (floor === null) {
        return []
      }
      // not-required number greater than 0
      const validator = combineValidators(min({ min: 0 }), onlyNumbers())
      const error = validator(floor).message

      return error ? [error] : []
    }, [floor]),

    [FieldEnum.BuildingFloors]: useMemo(() => {
      if (buildingFloors === null) {
        return []
      }
      const validator = combineValidators(min({ min: 0 }), onlyNumbers())
      const error = validator(buildingFloors).message

      if (error) {
        return [error]
      }
      if (floor && floor > buildingFloors) {
        return [i18next.t('listing:validation.invalid_building_floors')]
      }
      return []
    }, [buildingFloors, floor]),
    [FieldEnum.ElectricityFee]: useMemo(() => {
      if (!electricityFee) return []

      if (
        electricityFee.paymentPlan === ElectricityFeePaymentOptionEnum.fixed_fee &&
        electricityFee.monthlyFee
      ) {
        const minErrorMessage = min({ min: 0 })(electricityFee.monthlyFee).message
        if (minErrorMessage) return [minErrorMessage]
      }

      return []
    }, [electricityFee]),
    [FieldEnum.WaterFeePerTenant]: useMemo(() => {
      if (!waterFeePerTenant) return []

      const minErrorMessage = min({ min: 1 })(waterFeePerTenant).message

      if (minErrorMessage) return [minErrorMessage]

      const isMaxValid = max({ max: MAX_WATER_COST_PER_TENANT })(waterFeePerTenant).valid
      const maxErrorMessage = i18next.t('listing:validation.water_fee_max', {
        maxValue: formatNumber({ amount: MAX_WATER_COST_PER_TENANT, currency }),
      })

      if (!isMaxValid) return [maxErrorMessage]

      return []
    }, [waterFeePerTenant, currency]),
    [FieldEnum.ExternalEmail]: useMemo(() => {
      const isRequired =
        isExternal && [externalEmail, externalApplicationUrl, externalInfoPage].every((val) => !val)
      if (isRequired) return ['Required']
      return []
    }, [isExternal, externalEmail, externalInfoPage, externalApplicationUrl]),
    [FieldEnum.ExternalApplicationUrl]: useMemo(() => {
      const isRequired =
        isExternal && [externalEmail, externalApplicationUrl, externalInfoPage].every((val) => !val)
      if (isRequired) return ['Required']
      return []
    }, [isExternal, externalEmail, externalInfoPage, externalApplicationUrl]),
    [FieldEnum.ExternalInfoPage]: useMemo(() => {
      const isRequired =
        isExternal && [externalEmail, externalApplicationUrl, externalInfoPage].every((val) => !val)
      if (isRequired) return ['Required']
      return []
    }, [isExternal, externalEmail, externalInfoPage, externalApplicationUrl]),
    [FieldEnum.CountryCode]: useMemo(() => {
      const error = required()(countryCode).message
      return error ? [error] : []
    }, [countryCode]),
  }
}
