import pick from 'lodash/pick'

/**
 * Returns true if the provided object has no attributes or attributes with falsy values.
 * The attribute "__typename" is ignored
 *
 * @param obj
 */
function isEmpty<T>(obj: (T & { __typename?: string }) | null) {
  return (
    Boolean(obj) &&
    Object.values(obj!)
      .filter((val) => val !== obj!.__typename)
      .every((val) => !val)
  )
}

/**
 * Map for Objects: returns the object with applied function over all attributes
 */

function mapObject<T, TT>(obj: Record<string, T>, fn: (v: T) => TT): Record<string, TT> {
  return Object.keys(obj).reduce((mappedObj, attr) => ({ ...mappedObj, [attr]: fn(obj[attr]) }), {})
}

export { pick, isEmpty, mapObject }
