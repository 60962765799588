import { Paragraph, Spacer, Heading, createLucideIcon } from '@qasa/qds-ui'
import type { ReactNode } from 'react'
import styled from '@emotion/styled'
import { ShieldCheck } from 'lucide-react'

const ShieldCheckIcon = createLucideIcon(ShieldCheck)
const Wrapper = styled.div({})

const Title = styled(Heading)({
  display: 'flex',
  alignItems: 'center',
})

type Props = {
  heading: string
  body: ReactNode
}
export function SectionOfferingUSPBox({ heading, body }: Props) {
  return (
    <Wrapper>
      <Spacer size="4x" />
      <Title size="xs">
        <ShieldCheckIcon size={32} style={{ marginRight: 8 }} />
        {heading}
      </Title>
      <Spacer size="2x" />
      <Paragraph>{body}</Paragraph>
    </Wrapper>
  )
}
