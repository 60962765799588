import { createElement, useState, useEffect, lazy } from 'react'
import { CountryCodeEnum } from '@qasa/graphql'

import { currentBrand } from '../../config'
import type { BrandType } from '../../config/index.types'
import type { AvailableMarketCountryCodes } from '../../config/market-country-codes'

import type { ImportComponentFunction, ImportModuleFunction } from './localise-import.types'

const BRAND_LOCALE: Record<BrandType, CountryCodeEnum | null> = {
  blocket: CountryCodeEnum.SE,
  qasa: CountryCodeEnum.SE,
  qasa_finland: CountryCodeEnum.FI,
  qasa_france: CountryCodeEnum.FR,
  dotcom: null,
}

const asyncImportByLocaleWithFallback = async <T,>(
  importFunction: ImportModuleFunction<T>,
  locale: CountryCodeEnum | null,
) => {
  const extension = locale ? `.${locale.toLowerCase()}` : ''
  try {
    return await importFunction(extension)
  } catch {
    return await importFunction('')
  }
}

const createLazyComponents = <T,>(importFunction: ImportComponentFunction<T>) => {
  return {
    default: lazy(() => asyncImportByLocaleWithFallback(importFunction, null)),
    [CountryCodeEnum.SE]: lazy(() => asyncImportByLocaleWithFallback(importFunction, CountryCodeEnum.SE)),
    [CountryCodeEnum.FI]: lazy(() => asyncImportByLocaleWithFallback(importFunction, CountryCodeEnum.FI)),
    [CountryCodeEnum.FR]: lazy(() => asyncImportByLocaleWithFallback(importFunction, CountryCodeEnum.FR)),
    [CountryCodeEnum.NO]: lazy(() => asyncImportByLocaleWithFallback(importFunction, CountryCodeEnum.NO)),
  }
}

/**
 * Imports a component lazily, localised based on the provided locale or the current brand through file extensions.
 * If a localised component does not exist for the current brand, it will import a default component with no extension. All localised modules must have a default module to fallback to.
 *
 * Place localised component inside a folder, and call this function from an index file in that folder. Avoid placing other files in the folder.
 *
 * Localised components must be default exported.
 *
 * @template T - The type of the expected props of the imported component.
 * @param importFunction - The function that imports the module. It should take an extension as a parameter and call import() with the path to the localised components.
 * @example
 * // index.tsx:
 * const LocalisedComponent = createLocalisedLazyComponent(
 *  (ext) => import('./localised-component' + ext),
 * )
 *
 * export function LocalisedFeature() {
 *  return (
 *    <Suspense fallback={<Loader />}>
 *      <LocalisedComponent />
 *    </Suspense>
 *  )
 * }
 */
export const createLocalisedLazyComponent = <
  T extends Record<string, unknown> & {
    locale?: AvailableMarketCountryCodes
  },
>(
  importFunction: ImportComponentFunction<T>,
) => {
  if (currentBrand !== 'dotcom') {
    return lazy(() => asyncImportByLocaleWithFallback(importFunction, BRAND_LOCALE[currentBrand]))
  }

  const lazyComponents = createLazyComponents<T>(importFunction)

  return function (props: T) {
    return createElement(lazyComponents[props.locale ?? 'default'], props as LegitimateAny)
  }
}

const localiseImportAsync = async <T,>(
  importFunction: ImportModuleFunction<T>,
  locale: CountryCodeEnum | null,
): Promise<T> => {
  if (currentBrand !== 'dotcom') {
    return asyncImportByLocaleWithFallback(importFunction, BRAND_LOCALE[currentBrand])
  }
  return asyncImportByLocaleWithFallback(importFunction, locale)
}

/**
 * Imports a module asynchronously, localised based on the provided locale or the current brand through file extensions.
 * If a localised module does not exist for the current brand, it will import a default module with no extension. All localised modules must have a default module to fallback to.
 *
 * Place localised modules inside a folder, and call this function from an index file in that folder. Avoid placing other files in the folder.
 *
 * For better readability, prefer to localise with lazy components, using createLocalisedLazyComponent.
 *
 * @template T - The type of the imported module.
 * @param importFunction - The function that imports the module. It should take an extension as a parameter and call import() with the path to the localised modules.
 * @example
 * // index.ts
 * type LocalisationTestModule = {
 *  localisationTestFunction: () => string
 *  localisationTestObject: { message: string }
 * }
 *
 * export function useLocalisedFeature({ locale }: { locale: CountryCodeEnum }) {
 *  const module = useImportByLocale<LocalisationTestModule>(
 *    (ext) => import('./localisation-test-module' + ext),
 *    locale
 *  )
 *  return module
 * }
 *
 */
// eslint-disable-next-line import/no-unused-modules
export const useImportByLocale = <T,>(importFunction: ImportModuleFunction<T>, locale?: CountryCodeEnum) => {
  const [localisedImport, setLocalisedImport] = useState<T | null>(null)

  useEffect(() => {
    if (!localisedImport) {
      localiseImportAsync(importFunction, locale || null).then((imported) => {
        setLocalisedImport(imported)
      })
    }
  }, [importFunction, locale, localisedImport])

  return localisedImport
}
