import { detectCollisions } from './detect-collisions'

function buildI18nFile(context: LegitimateAny): LegitimateAny {
  const filesMap: Record<string, string> = {}

  return context.keys().reduce((allTranslations: Record<string, string>, filename: string) => {
    const translationFileContent = context(filename)
    detectCollisions(translationFileContent, filename, filesMap)
    return { ...allTranslations, ...translationFileContent }
  }, {})
}

const requireSource = require.context('../../../src', true, /^((?!__generated__).)*\.i18n\.json$/)

const requireEn = require.context('../../../src/translations/__generated__/en-US', true, /^.*\.json$/)

const requireSv = require.context('../../../src/translations/__generated__/sv-SE', true, /^.*\.json$/)

const requireFi = require.context('../../../src/translations/__generated__/fi-FI', true, /^.*\.json$/)

const requireNb = require.context('../../../src/translations/__generated__/nb-NO', true, /^.*\.json$/)

const requireFr = require.context('../../../src/translations/__generated__/fr-FR', true, /^.*\.json$/)

export const source = buildI18nFile(requireSource)
export const en = buildI18nFile(requireEn)
export const sv = buildI18nFile(requireSv)
export const fr = buildI18nFile(requireFr)
export const fi = buildI18nFile(requireFi)
export const nb = buildI18nFile(requireNb)
