import { IconButton, PenIcon } from '@qasa/ui'

import { useAppTranslation } from '../../contexts/i18next'

import type { EditDialogTriggerProps } from './edit-dialog.types'

export function EditDialogTrigger({ onClick }: EditDialogTriggerProps) {
  const { t } = useAppTranslation('common_buttons')

  return <IconButton size="sm" icon={PenIcon} label={t('edit')} onPress={onClick} variant="tertiary" />
}
