import i18next from 'i18next'
import type { FlatNamespace, KeyPrefix, i18n } from 'i18next'
import { createContext, useContext, useEffect } from 'react'
import type { FallbackNs, UseTranslationOptions } from 'react-i18next'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useTranslation } from 'react-i18next'

import { source, en, sv, fr, fi, nb } from '../../translations/build-language-file'

type I18nextContextType = {
  i18n: i18n
}

// @ts-ignore
const i18nextContext = createContext<I18nextContextType>(null)

const IS_SERVER_SIDE = typeof window === 'undefined'

const i18nInstance = i18next.createInstance({
  resources: { af: source, en, sv, fi, nb, fr },
  lng: undefined,
  fallbackLng: 'af',
  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
  },
  defaultNS: 'common',
  compatibilityJSON: 'v3',
  preload: IS_SERVER_SIDE ? ['dev', 'en', 'sv', 'fr', 'fi', 'nb'] : [],
})
i18nInstance.init()

type Props = {
  currentLanguage: string
  children: React.ReactNode
}

function I18nextProvider({ currentLanguage, children }: Props) {
  if (IS_SERVER_SIDE && i18nInstance.language !== currentLanguage) {
    i18nInstance.changeLanguage(currentLanguage)
  }

  useEffect(() => {
    if (i18nInstance.language === currentLanguage) return
    i18nInstance.changeLanguage(currentLanguage)
  }, [currentLanguage])

  return <i18nextContext.Provider value={{ i18n: i18nInstance }}>{children}</i18nextContext.Provider>
}

const useAppTranslation = <
  TNamespace extends
    | FlatNamespace
    | readonly [(FlatNamespace | undefined)?, ...FlatNamespace[]]
    | undefined = undefined,
  TKeyPrefix extends KeyPrefix<FallbackNs<TNamespace>> = undefined,
>(
  ns?: TNamespace,
  options?: UseTranslationOptions<TKeyPrefix>,
) => {
  const { i18n } = useContext(i18nextContext)

  return useTranslation(ns, { i18n, ...options })
}
export { I18nextProvider, useAppTranslation }
