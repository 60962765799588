import type { ReactElement } from 'react'
import { Children, useRef, useState } from 'react'
import { styled } from '@qasa/ui/web'
import { useBreakpointValue } from '@qasa/qds-ui'

import { CarouselProgressIndicator } from '../carousel-progress-indicator'
import { CarouselImage } from '../carousel-image'
import type { CarouselProps } from '../carousel.shared'
import { useEffectOnMount } from '../../../hooks/use-effect-on-mount'
import { getAdjustedIndicies, getArrayWithLoopCopies } from '../carousel.shared'

import { CarouselNavigationControls } from './carousel-navigation-controls.web'

const CarouselWrapper = styled('div')<Pick<CarouselProps, 'hasBorderRadius'>>(
  ({ theme, hasBorderRadius }) => ({
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    maxHeight: '100%',
    height: '100%',
    userSelect: 'none',
    ...(hasBorderRadius && {
      borderRadius: theme.radii.xl,
    }),
  }),
)
const CarouselSlider = styled('ul')({
  width: '100%',
  maxHeight: '100%',
  height: '100%',
  display: 'flex',
  overflowY: 'hidden',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  scrollSnapStop: 'always',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  // This is needed to fix a bug on iOS Safari
  borderRadius: 'inherit',
})
const CarouselListItem = styled('li')({
  width: '100%',
  height: '100%',
  maxHeight: '100%',
  flexShrink: 0,
  scrollSnapAlign: 'center',
})

const CarouselItem = styled('div')({
  width: '100%',
  height: '100%',
})

function useCarouselBase({ children, shouldLoop = false, initialIndex = 0 }: CarouselProps) {
  const childArray = Children.toArray(children) as ReactElement[]
  const sliderRef = useRef<HTMLUListElement>(null)
  const [isHovered, setIsHovered] = useState(false)
  const isDesktop = useBreakpointValue({ base: false, md: true })
  const [currentIndex, setCurrentIndex] = useState(initialIndex)
  const carouselItems = shouldLoop ? getArrayWithLoopCopies(childArray) : childArray
  const { adjustedCurrentIndex, initialScrollIndex } = getAdjustedIndicies(
    currentIndex,
    initialIndex,
    shouldLoop,
  )

  const isFirstStep = currentIndex === 0
  const isLastStep = currentIndex === childArray.length - 1

  useEffectOnMount(() => scrollTo('initial'))

  const handleCarouselNavigation = (scrollValue: -1 | 1) => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: sliderRef.current.clientWidth * scrollValue,
        behavior: 'smooth',
      })
    }
  }
  const scrollTo = (value: 'start' | 'end' | 'initial') => {
    if (sliderRef.current) {
      const distanceToFirstItem = sliderRef.current.clientWidth * -childArray.length
      const distanceToLastItem = sliderRef.current.clientWidth * childArray.length
      const distanceToInitialItem = sliderRef.current.clientWidth * initialScrollIndex

      const distances = {
        start: distanceToFirstItem,
        end: distanceToLastItem,
        initial: distanceToInitialItem,
      }

      sliderRef.current.scrollBy({
        left: distances[value],
      })
    }
  }
  const handleScrollBehavior = () => {
    const itemWidth = sliderRef.current?.clientWidth ?? 0
    const currentPosition = sliderRef.current?.scrollLeft ?? 0

    if (shouldLoop) {
      const hasReachedStart = currentPosition === 0
      const hasReachedEnd = currentPosition === itemWidth * (carouselItems.length - 1)
      if (hasReachedStart) {
        scrollTo('end')
      } else if (hasReachedEnd) {
        scrollTo('start')
      }
    }
    setCurrentIndex(Math.round(currentPosition / itemWidth))
  }
  const handleHover = ({ isHovered }: { isHovered: boolean }) => setIsHovered(isHovered)

  return {
    childrenCount: childArray.length,
    currentIndex: adjustedCurrentIndex,
    sliderRef,
    isFirstStep,
    isLastStep,
    handleCarouselNavigation,
    handleScrollBehavior,
    handleHover,
    shouldShowNavigationControls: isHovered && isDesktop,
    carouselItems,
  }
}

function CarouselBase({
  children,
  initialIndex,
  shouldLoop = false,
  hasBorderRadius = false,
}: CarouselProps) {
  const {
    sliderRef,
    currentIndex,
    handleHover,
    shouldShowNavigationControls,
    childrenCount,
    isFirstStep,
    isLastStep,
    carouselItems,
    handleCarouselNavigation,
    handleScrollBehavior,
  } = useCarouselBase({
    children,
    initialIndex,
    shouldLoop,
  })

  return (
    <CarouselWrapper
      onMouseEnter={() => handleHover({ isHovered: true })}
      onMouseLeave={() => handleHover({ isHovered: false })}
      hasBorderRadius={hasBorderRadius}
    >
      <CarouselSlider onScroll={handleScrollBehavior} ref={sliderRef}>
        {carouselItems.map((child, index) => {
          return <CarouselListItem key={index}>{child}</CarouselListItem>
        })}
      </CarouselSlider>
      <CarouselProgressIndicator count={childrenCount} currentIndex={currentIndex} />
      {shouldShowNavigationControls && (
        <CarouselNavigationControls
          shouldLoop={shouldLoop}
          hasPrevious={!isFirstStep}
          onClick={handleCarouselNavigation}
          hasNext={!isLastStep}
        />
      )}
    </CarouselWrapper>
  )
}
export const Carousel = Object.assign(CarouselBase, { Image: CarouselImage, Item: CarouselItem })
