import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { OccupationTypeEnum } from '@qasa/graphql'

import { FormSelectField } from '../../../ui-page-modules/create-tenant-listing/fields/form-select-field'
import type { OccupationFormValues } from '../use-occupation-form'

import { ERROR_OCCUPATION_I18N_KEY, OCCUPATION_I18N_KEY } from './title'

export function OccupationTypeField() {
  const { t } = useTranslation('edit_profile')

  const {
    control,
    formState: { errors },
  } = useFormContext<OccupationFormValues>()

  const occupationTypeOptions = Object.values(OccupationTypeEnum)
    .reverse()
    .map((value) => {
      return { value, label: t(`${OCCUPATION_I18N_KEY}.occupation_type.options.${value}`) }
    })

  return (
    <FormSelectField
      control={control}
      name={'occupationType'}
      label={t(`${OCCUPATION_I18N_KEY}.occupation_type.label`)}
      placeholder={t(`${OCCUPATION_I18N_KEY}.occupation_type.placeholder`)}
      rules={{ required: t(`${ERROR_OCCUPATION_I18N_KEY}.required.occupation_type`) }}
      errorMessage={errors['occupationType'] && errors['occupationType'].message}
      options={occupationTypeOptions}
    />
  )
}
