import { useTranslation } from 'react-i18next'
import { Heading, Stack } from '@qasa/qds-ui'
import { HomeItemGrid } from '@qasa/app'

import { HomeItem } from '../../ui-shared/home-item'
import { LinkButton } from '../../ui-shared/_core/link-button'

import { useProfileContext } from './profile-context'

export function ProfilePublishedListings() {
  const { t } = useTranslation('profile_published_listings_block')
  const { profile } = useProfileContext()

  return (
    <Stack gap="6x">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Heading size="sm">{t('title')}</Heading>
        <LinkButton size="xs" variant="tertiary" style={{ marginLeft: 'auto' }} to="/find-home/">
          {t('nav_link')}
        </LinkButton>
      </Stack>
      <HomeItemGrid>
        {profile.publishedHomes.map((home) => {
          return <HomeItem key={home.id} home={home} />
        })}
      </HomeItemGrid>
    </Stack>
  )
}
