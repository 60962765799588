import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Heading, Paragraph, Spacer, Stack } from '@qasa/qds-ui'
import type { HomeDraftsAndPreviouslySignedHomesQueryQuery } from '@qasa/graphql'

import { formatDateToDayMonthYear } from '../../helpers/date'
import homePlaceholderImg from '../../assets/misc/home-placeholder.jpg'
import { SlashSeparator } from '../../ui-shared/_core/slash-separator'
import { Image } from '../../ui-shared/_core/image'
import { getFormattedAddress } from '../../helpers/address'

const ListItem = styled.div({ display: 'flex', alignItems: 'center' })
const HomeItemImage = styled(Image)(({ theme }) => ({
  width: theme.sizes['20x'],
  height: theme.sizes['20x'],
  border: '1px solid',
  borderColor: theme.colors.bg.default,
  borderRadius: theme.radii.sm,
  objectFit: 'cover',
  [theme.mediaQueries.mdUp]: {
    width: theme.sizes[128],
  },
}))
const HomeDetails = styled(Stack)({ flex: 1, minWidth: 0 })

type HomeItemProps = Pick<
  UNSAFE_DeepNonNullableObject<HomeDraftsAndPreviouslySignedHomesQueryQuery>['homeDrafts']['edges'][number]['node'],
  'location' | 'shared' | 'roomCount' | 'squareMeters' | 'type'
> & {
  datePrefix: string
  date: string | null
  imageUrl: string
}

export function HomeItem({
  location,
  imageUrl,
  type,
  shared: isShared,
  roomCount,
  squareMeters,
  date,
  datePrefix,
}: HomeItemProps) {
  const { t } = useTranslation('listing')
  const homeType = (isShared ? `${t('templates.room_in')}${' '}` : '') + t('commons:home_type:' + type)
  const imageSrc = imageUrl ?? homePlaceholderImg
  const { route, streetNumber } = location || {}

  return (
    <ListItem>
      <HomeItemImage src={imageSrc} width={240} height={160} fittingMethod="crop" />
      <Spacer axis="x" size={'4x'} />
      <HomeDetails gap="4x">
        <div>
          <Heading size="sm" as="h3">
            {getFormattedAddress({ route, streetNumber })}
          </Heading>
          <Paragraph size="sm">
            {homeType}
            <SlashSeparator />
            {t('commons:roomWithCount', { count: roomCount ?? 0 })}
            <SlashSeparator />
            {t('commons:square_meters', { number: squareMeters })}
          </Paragraph>
        </div>
        <Paragraph size="sm">
          {datePrefix} {date ? formatDateToDayMonthYear(date) : '-'}
        </Paragraph>
      </HomeDetails>
    </ListItem>
  )
}
