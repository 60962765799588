import { Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import type { ComponentType } from 'react'
import { useQuery } from '@apollo/client'
import type { Market } from '@qasa/graphql'
import { HomeStatusEnum } from '@qasa/graphql'

import { Wizard } from '../ui-shared/wizard'
import { ShortcutIntro } from '../ui-page-modules/shortcut-opt-in/shortcut-intro'
import { ShortcutSuccess } from '../ui-page-modules/shortcut-opt-in/shortcut-success'
import { GET_HOME_STATUS } from '../ui-page-modules/shortcut-opt-in/shortcut-opt-in.gql'
import { PageNetworkStatus } from '../ui-shared/_core/network-statuses'
import { getPath } from '../routing/get-path'

export type ShortcutOptInStepProps = {
  goNext: () => void
  goBack?: () => void
  market?: Pick<Market, 'name'>
}
type ShortcutRoute = {
  path: string
  Component: ComponentType<ShortcutOptInStepProps>
}

export function ShortcutOptIn() {
  const { path: currentPath, url } = useRouteMatch()
  const { push, replace } = useHistory()
  const location = useLocation()
  const { homeId } = useParams<{ homeId: string }>()
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(GET_HOME_STATUS, {
    variables: { homeId },
  })

  const moveToStep = (step?: string) => {
    if (step) {
      replace({
        ...location,
        pathname: step,
      })
    } else {
      push({
        ...location,
        pathname: `${getPath('editListing', { homeId })}/amenities-home`,
      })
    }
  }

  if (isLoading || error) {
    return <PageNetworkStatus {...{ isLoading, error }} />
  }

  const isPendingShortcut = data?.home?.status === HomeStatusEnum.pending_shortcut
  const isPotentialShortcut = data?.home?.status === HomeStatusEnum.proposed_shortcut

  const isPendingOrPotentialShortcut = isPendingShortcut || isPotentialShortcut
  if (!isPendingOrPotentialShortcut) {
    return (
      <Redirect
        to={{
          ...location,
          pathname: `/my-listings/${homeId}`,
        }}
      />
    )
  }

  // NOTE: temporarily moved the routes inside the component to dynamically show the right component for intro
  const shortcutRoutes: ShortcutRoute[] = [
    {
      path: 'intro',
      Component: ShortcutIntro,
    },
    {
      path: 'success',
      Component: ShortcutSuccess,
    },
  ]

  return (
    <Switch>
      <Route exact path={currentPath}>
        <Redirect
          to={{
            ...location,
            pathname: `${url}/${shortcutRoutes[isPendingShortcut ? 1 : 0].path}`,
          }}
        />
      </Route>
      <Wizard shouldFillAvailableSpace={location.pathname.includes('intro')} closeLink="/my-listings/">
        <Switch>
          {shortcutRoutes.map(({ path, Component }, index) => {
            const previousStep = shortcutRoutes[index - 1]?.path
            const nextStep = shortcutRoutes[index + 1]?.path

            return (
              <Route key={path} path={`${currentPath}/${path}`}>
                <Component
                  goNext={() => moveToStep(nextStep)}
                  goBack={() => moveToStep(previousStep)}
                  market={data.home?.market}
                />
              </Route>
            )
          })}
        </Switch>
      </Wizard>
    </Switch>
  )
}
