import { graphql } from '@qasa/graphql'

export const ME = graphql(/* GraphQL */ `
  query MeQuery {
    me {
      ...BaseUser
      ...UserPrivateFields
      ...UserBio
      ...UserProfileCompleteness
      creditReport {
        id
        fetchStatus
      }
      applicationStats {
        uid
        count
        inContactCount
        locales
        priceMax
        priceMin
        roomMax
        roomMin
      }
      affiliateCode {
        code
        discountFor
        discountType
        discountValue
        expirationDate
        affiliateCodeType
      }
      smoker
    }
  }
`)
