import * as PopoverPrimitive from '@radix-ui/react-popover'
import type { PropsWithChildren, ReactNode } from 'react'
import { forwardRef } from 'react'
import { styled } from '@qasa/ui/web'

type PopoverRootProps = Omit<PopoverPrimitive.PopoverProps, 'open'> & { isOpen?: boolean }
function PopoverRoot({ isOpen, ...restProps }: PopoverRootProps) {
  return <PopoverPrimitive.Root open={isOpen} {...restProps} />
}

type PopoverTriggerProps = {
  children: ReactNode
}
function PopoverTrigger({ children }: PopoverTriggerProps) {
  return <PopoverPrimitive.Trigger asChild>{children}</PopoverPrimitive.Trigger>
}
function PopoverAnchor({ children }: PopoverTriggerProps) {
  return <PopoverPrimitive.Anchor asChild>{children}</PopoverPrimitive.Anchor>
}

const StyledContent = styled(PopoverPrimitive.Content)(({ theme }) => ({
  // NOTE: Inherits body pointerEvents none by default, so we override it here.
  // Relevant when used within a Dialog.
  pointerEvents: 'auto',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  background: theme.colors.core.white,
  boxShadow: theme.shadows.lg,
  borderRadius: theme.radii.md,
  maxHeight: '60vh',
  zIndex: theme.zIndices.popover,
}))
type PopoverContentProps = PropsWithChildren<
  Pick<PopoverPrimitive.PopoverContentProps, 'onOpenAutoFocus' | 'align'>
>
const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  ({ children, ...restProps }, forwardedRef) => (
    <PopoverPrimitive.Portal>
      <StyledContent align="start" sideOffset={8} {...restProps} ref={forwardedRef}>
        {children}
      </StyledContent>
    </PopoverPrimitive.Portal>
  ),
)

const PopoverBody = styled('div')({
  overflowY: 'auto',
})

const PopoverFooter = styled('footer')(({ theme }) => ({
  paddingTop: theme.spacing['3x'],
  paddingBottom: theme.spacing['3x'],
  paddingLeft: theme.spacing['4x'],
  paddingRight: theme.spacing['4x'],
  borderTop: '1px solid',
  borderColor: theme.colors.border.default,
}))

export const Popover = Object.assign(PopoverRoot, {
  Trigger: PopoverTrigger,
  Anchor: PopoverAnchor,
  Content: PopoverContent,
  Body: PopoverBody,
  Footer: PopoverFooter,
})
