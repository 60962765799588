import { HintBox, Paragraph, Stack, createLucideIcon } from '@qasa/qds-ui'
import { EyeOff, Eye } from 'lucide-react'
import { useTranslation } from 'react-i18next'

type UserDataVisibilityHintboxProps = {
  mode: 'private' | 'public'
}

const PublicIcon = createLucideIcon(Eye)
const PrivateIcon = createLucideIcon(EyeOff)
const ICONS = {
  public: PublicIcon,
  private: PrivateIcon,
}
export function UserDataVisibilityHintbox({ mode }: UserDataVisibilityHintboxProps) {
  const { t } = useTranslation('edit_profile')

  const labels = {
    public: t('user_data_visibility.public'),
    private: t('user_data_visibility.private'),
  }

  const Icon = ICONS[mode]
  const label = labels[mode]
  return (
    <HintBox>
      <Stack direction="row" gap="2x" alignItems="center">
        <Icon size={16} />
        <Paragraph size="sm">{label}</Paragraph>
      </Stack>
    </HintBox>
  )
}
