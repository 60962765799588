import { createEnumParam, useQueryParams, withDefault } from 'use-query-params'
import { CreditReportFetchStatusEnum, type HomeViewQuery } from '@qasa/graphql'
import type { ApplicationFlowStepProps } from '@qasa/app/src/features/home/modules/home-application/steps/steps.types'

import { useWizard } from '../../../hooks/use-wizard'
import { useAuthContext } from '../../../context/auth-context'

import { CreditVerificationCreating } from './credit-verification/credit-verification-creating'
import { CreditVerificationInitial } from './credit-verification/credit-verification-initial'
import { CreditVerificationVerified } from './credit-verification/credit-verification-verified'
import { CreditVerificationIntro } from './credit-verification/credit-verification-intro'

export enum CreditVerificationSteps {
  CreditIntro = 'creditIntro',
  CreditInitial = 'creditInitial',
  CreditCreating = 'creditCreating',
  CreditVerified = 'creditVerified',
}
export type CreditVerificationStepProps = {
  goNext: () => void
  goBack: () => void
  manuallyUpdateVerificationState: (newState: CreditVerificationSteps) => void
  onSkipCreditFlow: () => void
  isOptional?: boolean
  home?: NonNullable<HomeViewQuery>['home']
  isSettingsVerificationFlow: boolean
}
type CreditVerificationContext = {
  isCreditReportDownloaded?: boolean
}

export function CreditVerificationStep({
  onNext,
  isOptional,
  onBack,
  home,
  isSettingsVerificationFlow = false,
  flowQueryParam = 'step',
}: Pick<ApplicationFlowStepProps, 'onNext' | 'onBack' | 'isOptional' | 'home' | 'flowQueryParam'> & {
  isSettingsVerificationFlow?: boolean
}) {
  const { authBody } = useAuthContext()
  const isCreditReportDownloaded = Boolean(
    authBody?.creditReport?.fetchStatus === CreditReportFetchStatusEnum.downloaded,
  )

  // NOTE: the manual handling of query params here is only used for directly navigating to a specific step
  // which is not yet supported in useWizard
  const [, setQueryParams] = useQueryParams({
    [flowQueryParam]: withDefault(createEnumParam(Object.values(CreditVerificationSteps)), null),
  })

  const manuallyUpdateVerificationState = (newState: CreditVerificationSteps) => {
    setQueryParams({ [flowQueryParam]: newState }, 'replaceIn')
  }

  const creditFlowSteps = [
    {
      id: CreditVerificationSteps.CreditIntro,
      Component: CreditVerificationIntro,
      guard: (context: CreditVerificationContext) =>
        !isSettingsVerificationFlow && !context.isCreditReportDownloaded,
    },
    {
      id: CreditVerificationSteps.CreditInitial,
      Component: CreditVerificationInitial,
      guard: (context: CreditVerificationContext) => !context.isCreditReportDownloaded,
    },
    {
      id: CreditVerificationSteps.CreditCreating,
      Component: CreditVerificationCreating,
      guard: (context: CreditVerificationContext) => !context.isCreditReportDownloaded,
    },
    {
      id: CreditVerificationSteps.CreditVerified,
      Component: CreditVerificationVerified,
    },
  ]
  const { currentStep, goNext, goBack } = useWizard(creditFlowSteps, {
    onCompleted: onNext,
    onCancelled: onBack,
    queryParamName: flowQueryParam,
    initialContext: {
      isCreditReportDownloaded,
    },
  })

  return (
    <currentStep.Component
      goNext={goNext}
      goBack={goBack}
      onSkipCreditFlow={() => onNext({ context: { isCreditReportSkipped: true } })}
      manuallyUpdateVerificationState={manuallyUpdateVerificationState}
      isOptional={isOptional}
      home={home}
      isSettingsVerificationFlow={isSettingsVerificationFlow}
    />
  )
}
