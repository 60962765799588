import type { MutableRefObject } from 'react'
import { useEffect, useRef, useState } from 'react'
import type { MapboxOptions } from 'mapbox-gl'

import type { Region } from '../contexts/region'
import 'maplibre-gl/dist/maplibre-gl.css'
import { getEnv } from '../env'
import { useRegionContext } from '../contexts/region'
import { CENTER_OF_FINLAND, CENTER_OF_SWEDEN } from '../utils/map'

import { useHasUsedMouseAfterScrolling } from './use-has-used-mouse-after-scrolling'

export const LOCATIONIQ_TILES_URL = `https://tiles.locationiq.com/v2/streets/vector.json?key=${getEnv(
  'LOCATIONIQ_KEY',
)}`

type MapConfig = {
  language: string
  mapCenter: { lat: number; lng: number }
}

const CONFIG: Record<Region, MapConfig> = {
  se: {
    language: 'se-SE',
    mapCenter: CENTER_OF_SWEDEN,
  },
  fi: {
    language: 'fi-FI',
    mapCenter: CENTER_OF_FINLAND,
  },
  fr: {
    language: 'fr-FR',
    //TODO: Update this to the center of France
    mapCenter: CENTER_OF_SWEDEN,
  },
  international: {
    language: 'en-EN',
    //TODO: Update this to the center of what, exactly?
    mapCenter: CENTER_OF_SWEDEN,
  },
}

export const useMapCenter = () => {
  const { region } = useRegionContext()
  return CONFIG[region].mapCenter
}

type MapWithLanguage = mapboxgl.Map & {
  setLanguage: (language: string) => void
  autodetectLanguage: () => void
}

type UseLoadMapProps = {
  initialValues: Pick<MapboxOptions, 'zoom' | 'center' | 'minZoom' | 'maxZoom' | 'scrollZoom'> & {
    mapAnchor: MutableRefObject<HTMLDivElement | null>
    isRotateEnabled: boolean
    isPanEnabled: boolean
    hasControlButtons?: boolean
  }
}
export function useMap({
  initialValues: {
    mapAnchor,
    zoom = 15,
    center,
    minZoom = 4,
    maxZoom = 16,
    isRotateEnabled = false,
    isPanEnabled = true,
    scrollZoom = true,
    hasControlButtons = true,
  },
}: UseLoadMapProps) {
  const [map, setMap] = useState<MapWithLanguage>()
  const hasMapBeenInstantiated = useRef(false)
  const { region } = useRegionContext()
  const language = CONFIG[region].language
  const initialMapCenter = CONFIG[region].mapCenter
  const mapCenter = center ? center : initialMapCenter
  const hasUsedMouseAfterScrolling = useHasUsedMouseAfterScrolling()

  useEffect(() => {
    if (map) {
      if (hasUsedMouseAfterScrolling && scrollZoom) {
        map.scrollZoom.enable()
      } else {
        map.scrollZoom.disable()
      }
    }
  }, [map, hasUsedMouseAfterScrolling, scrollZoom])

  useEffect(() => {
    const loadMap = async () => {
      if (!mapAnchor.current || hasMapBeenInstantiated.current) return

      hasMapBeenInstantiated.current = true
      const mapboxgl = (await import('../vendor/locationiq-lang.js')).default
      const glmap = new mapboxgl.Map({
        container: mapAnchor.current,
        center: mapCenter,
        zoom,
        minZoom,
        maxZoom,
        dragRotate: isRotateEnabled,
        dragPan: false, // need to switch this on later to allow normal scrolling of the page on touch devices
        touchPitch: false,
        scrollZoom,
        style: LOCATIONIQ_TILES_URL,
        attributionControl: false,
      }) as MapWithLanguage

      glmap.addControl(
        new mapboxgl.AttributionControl({
          compact: false,
        }),
      )

      glmap.setLanguage(language)
      if (hasControlButtons) {
        glmap.addControl(
          new mapboxgl.NavigationControl({
            showCompass: false,
          }),
        )
      }

      /**
       * Make sure touch device users can scroll
       * the page with one finger without accidentally panning the map.
       */
      glmap.on('touchstart', (event) => {
        glmap.dragPan.disable()
        glmap.scrollZoom.disable()
        const e = event.originalEvent
        if (e.touches.length > 1 && isPanEnabled) {
          glmap.dragPan.enable()
        }
      })
      glmap.on('touchend', (event) => {
        const e = event.originalEvent
        if (e.changedTouches.length > 1) {
          glmap.dragPan.disable()
        }
      })

      /* Switch on dragPan for mouse/trackpad users */
      glmap.on('mousedown', () => {
        if (isPanEnabled && !glmap.dragPan.isEnabled()) {
          glmap.dragPan.enable()
        }
      })

      /* Force resize to prevent known size bug that can occur if container size is changed */
      /* https://github.com/mapbox/mapbox-gl-js/issues/3265 */
      glmap.on('load', () => glmap.resize())

      glmap.on('styleimagemissing', (event) => {
        /**
         * Our map tile provider (LocationIQ) doesn't provide images for all possible icons (ie "coworking_space", "bicycle_parking" etc)
         * and when this happens the map will output a lot of warnings to the console which we don't really care about.
         * To work around this we add a 0x0 image without any content which silences the warning without any actual impact on what's rendered.
         */
        glmap.addImage(event.id, { width: 0, height: 0, data: new Uint8Array() })
      })
      setMap(glmap)
    }
    if (!map) {
      loadMap()
    }
  }, [
    mapAnchor,
    zoom,
    minZoom,
    maxZoom,
    scrollZoom,
    language,
    isRotateEnabled,
    isPanEnabled,
    map,
    mapCenter,
    hasControlButtons,
  ])
  return { map }
}
