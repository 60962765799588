/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { Reference } from '@apollo/client'
import type { FieldPolicy, KeyArgsFunction } from '@apollo/client/cache/inmemory/policies'

type KeySpecifier = string[]
type KeyArgs = KeySpecifier | KeyArgsFunction | false

type OffsetNodes<T = Reference> = { nodes: T[] }
type OffsetLimitVariables = { limit?: number; offset?: number }

type MergeHomePagesArguments = {
  existing?: OffsetNodes
  incoming: OffsetNodes
  offsetLimitArguments: OffsetLimitVariables
}

export const mergeNodes = ({ existing, incoming, offsetLimitArguments }: MergeHomePagesArguments) => {
  if (!offsetLimitArguments) return { ...incoming }

  const mergedNodes = existing?.nodes.slice() ?? []
  const incomingNodes = incoming.nodes.slice()

  const offset = offsetLimitArguments.offset ?? 0

  for (let i = 0; i < incoming.nodes.length; ++i) {
    const incomingNode = incomingNodes[i]
    if (incomingNode) {
      mergedNodes[offset + i] = incomingNode
    }
  }
  return { ...existing, ...incoming, nodes: mergedNodes }
}

export const readNodes = ({ field, variables }: { field?: OffsetNodes; variables: OffsetLimitVariables }) => {
  const { limit, offset } = variables

  if (!limit || !offset) {
    return { ...field, nodes: field?.nodes ?? [] }
  }

  return { ...field, nodes: field?.nodes.slice(offset, offset + limit) ?? [] }
}

export function homeOffsetLimitPagination(keyArgs: KeyArgs = false): FieldPolicy<OffsetNodes> {
  return {
    keyArgs,
    merge: (existing, incoming, { args }) =>
      mergeNodes({ existing, incoming, offsetLimitArguments: args as OffsetLimitVariables }),
    read: (field, { variables }) => readNodes({ field, variables: variables as OffsetLimitVariables }),
  }
}
