import type { CreateHomeParams } from '../ui-page-modules/listing/use-create-home'

export type SignupMode = 'checkout' | 'marketplace'

export const AFTER_LOGIN_ACTIONS: {
  CREATE_HOME: 'CREATE_HOME'
  GO_TO_ROUTE: 'GO_TO_ROUTE'
  DEFAULT: 'DEFAULT'
} = {
  CREATE_HOME: 'CREATE_HOME',
  GO_TO_ROUTE: 'GO_TO_ROUTE',
  DEFAULT: 'DEFAULT',
}

export type AfterLoginAction =
  | ({
      type: typeof AFTER_LOGIN_ACTIONS.CREATE_HOME
    } & CreateHomeParams)
  | { type: typeof AFTER_LOGIN_ACTIONS.GO_TO_ROUTE; route: string; search?: string }
  | { type: typeof AFTER_LOGIN_ACTIONS.DEFAULT }
