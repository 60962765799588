import type { ReactNode } from 'react'

import { useEmbeddedFindHome } from '../hooks/use-embedded-find-home'

export function HideInEmbeddedFindHomeWrapper({ children }: { children: ReactNode }) {
  const isFindHomeEmbedded = useEmbeddedFindHome()

  if (isFindHomeEmbedded) return null

  return <>{children}</>
}
