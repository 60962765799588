/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { Reference } from '@apollo/client'
import type { FieldPolicy, KeyArgsFunction } from '@apollo/client/cache/inmemory/policies'

type KeySpecifier = string[]
type KeyArgs = KeySpecifier | KeyArgsFunction | false

type OffsetNodes = { nodes: (Reference & { score: number | null })[] }

export function findTenantOffsetLimitPagination(keyArgs: KeyArgs = false): FieldPolicy<OffsetNodes> {
  return {
    keyArgs,
    merge(existing = { nodes: [] }, incoming, { args, mergeObjects, readField }) {
      const mergedObjects = mergeObjects(existing, incoming) || incoming
      const newCacheObject = { ...mergedObjects }
      const currentNodes = [...(existing?.nodes || [])]
      const incomingNodes = incoming.nodes
      const offset = args?.offset || 0

      for (let i = 0; i < incoming.nodes.length; ++i) {
        const currentIncoming = incomingNodes[i]
        if (currentIncoming) {
          currentNodes[offset + i] = currentIncoming
        }
      }
      newCacheObject.nodes = currentNodes.map((reference) => {
        const score = readField('score', reference) as number | null
        return { ...reference, score }
      })

      return newCacheObject
    },
    read(existing, { args, cache }) {
      if (!existing) return undefined
      const offset = args?.offset || 0
      const limit = args?.limit || -1
      const nodesInPage = existing.nodes.slice(offset, offset + limit).map((reference) => {
        if (reference.score) {
          //Update the cached tenantAd with the score related to the relevant HomeId
          cache.modify({
            id: cache.identify(reference),
            fields: {
              score() {
                return reference.score
              },
            },
            broadcast: false,
          })
        }
        return reference
      })
      return { ...existing, nodes: nodesInPage }
    },
  }
}
