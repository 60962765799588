import { useAppTranslation } from '../../../../../contexts/i18next'
import { useFindHomeFiltersContext } from '../../../contexts'
import { FilterCheckboxGroup } from '../../components/filter-checkbox-group'

export function FurnishedFilterSection() {
  const { t } = useAppTranslation('filter_fields')
  const { filterValues, updateFilterValues } = useFindHomeFiltersContext()

  const furnishedOptions = [
    { label: t('furnished.furnished'), value: 'furnished' },
    { label: t('furnished.unfurnished'), value: 'unfurnished' },
  ]
  return (
    <FilterCheckboxGroup
      label={t('furnished.title', { ns: 'filter_sections' })}
      isLabelVisuallyHidden={true}
      values={filterValues.furnished}
      onChange={(newValue) => updateFilterValues({ furnished: newValue })}
      options={furnishedOptions}
    />
  )
}
