// We actually want the full path here including the locale prefix
// eslint-disable-next-line no-restricted-imports
import { usePathname } from 'next/navigation'

import { currentBrand } from '.'
import type { BrandType } from './index.types'

export const useBrandDefaultCanonicalUrl = () => {
  const pathname = usePathname()
  /**
   * Since Netlify prerenders for crawlers based on the internal site domain (eg. qasa-production.netlify.app),
   * and we don't use them for our custom domains, we can't use window.href or similar here (yet).
   */
  const brandDefaultCanonicalUrl: Record<BrandType, string> = {
    /* Canonicalize all URLs on blocket to qasa for SEO purposes */
    blocket: `https://qasa.se${pathname}`,
    qasa: `https://qasa.se${pathname}`,
    qasa_finland: `https://qasa.fi${pathname}`,
    qasa_france: `https://qasa.fr${pathname}`,
    dotcom: `https://qasa.com${pathname}`,
  }
  return brandDefaultCanonicalUrl[currentBrand]
}
