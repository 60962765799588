import { gtmCall, pushToDataLayer } from '@qasa/app'
import type { CreateHomeMutationMutation } from '@qasa/graphql'

import { currentBrand } from '../../config'

const CREATE_LISTING_FORM_ID = 'create-listing-form'

type SectionTrackingParams = {
  trackingInfo: {
    sectionPath: string
    sectionNumber: number
  }
}

const clearDataLayer = () => {
  pushToDataLayer({ object: { viewData: undefined } })
}

const trackingContext = currentBrand === 'blocket' ? 'blocketbostad' : currentBrand

export const trackSectionView = ({ trackingInfo: { sectionPath, sectionNumber } }: SectionTrackingParams) => {
  clearDataLayer()
  gtmCall({
    event: 'view',
    eventType: 'View',
    objectType: 'Form',
    viewData: {
      id: `sdrn:${trackingContext}:form:${CREATE_LISTING_FORM_ID}`,
      stepName: sectionPath,
      stepNumber: sectionNumber.toString(),
    },
  })
}

type ListingCreatedTrackingParams = {
  homeId?: UNSAFE_DeepNonNullableObject<CreateHomeMutationMutation>['createHome']['home']['id']
}
export const trackListingCreated = ({ homeId }: ListingCreatedTrackingParams) => {
  if (!homeId) {
    return
  }
  clearDataLayer()
  gtmCall({
    event: 'view',
    eventType: 'View',
    objectType: 'Confirmation',
    viewData: {
      id: `sdrn:${trackingContext}:confirmation:${CREATE_LISTING_FORM_ID}`,
      items: [
        {
          id: `sdrn:${trackingContext}:classified:${homeId}`,
          type: 'ClassifiedAd',
        },
      ],
    },
  })
}
