import { Paragraph, Stack, createIcon } from '@qasa/qds-ui'
import type { ComponentProps } from 'react'
import styled from '@emotion/styled'
import type { ProfileQueryQuery } from '@qasa/graphql'

import { getBadges } from '../ui-page-modules/profile/profile-badges'

export const BadgeIcon = createIcon({
  displayName: 'BadgeIcon',
  d: 'M256 48c-24.1 0-45.1 13.5-55.7 33.5C194.9 91.7 183 96.6 172 93.2c-21.6-6.6-46.1-1.4-63.1 15.7S86.6 150.4 93.2 172c3.4 11-1.5 22.9-11.7 28.2C61.5 210.9 48 231.9 48 256s13.5 45.1 33.5 55.7C91.7 317.1 96.6 329 93.2 340c-6.6 21.6-1.4 46.1 15.7 63.1s41.5 22.3 63.1 15.7c11-3.4 22.9 1.5 28.2 11.7c10.6 20 31.6 33.5 55.7 33.5s45.1-13.5 55.7-33.5c5.4-10.2 17.2-15.1 28.2-11.7c21.6 6.6 46.1 1.4 63.1-15.7s22.3-41.5 15.7-63.1c-3.4-11 1.5-22.9 11.7-28.2c20-10.6 33.5-31.6 33.5-55.7s-13.5-45.1-33.5-55.7c-10.2-5.4-15.1-17.2-11.7-28.2c6.6-21.6 1.4-46.1-15.7-63.1S361.6 86.6 340 93.2c-11 3.4-22.9-1.5-28.3-11.7C301.1 61.5 280.1 48 256 48zm-88.1-4.6C188.2 17 220.1 0 256 0s67.8 17 88.1 43.4c33-4.3 67.6 6.2 93 31.6s35.9 60 31.6 93C495 188.2 512 220.1 512 256s-17 67.8-43.4 88.1c4.3 33-6.2 67.6-31.6 93s-60 35.9-93 31.6C323.8 495 291.9 512 256 512s-67.8-17-88.1-43.4c-33 4.3-67.6-6.2-93-31.6s-35.9-60-31.6-93C17 323.8 0 291.9 0 256s17-67.8 43.4-88.1c-4.3-33 6.2-67.6 31.6-93s60-35.9 93-31.6z',
  viewBox: '0 0 512 512',
})

export const BadgeFilledIcon = createIcon({
  displayName: 'BadgeFilledIcon',
  d: 'M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z',
  viewBox: '0 0 512 512',
})

const Wrapper = Stack

const BadgeContainer = Stack

const BadgeText = styled(Paragraph)({
  paddingLeft: 8,
})

type BaseUser = Pick<
  NonNullable<ProfileQueryQuery['user']>,
  | 'firstName'
  | 'companyName'
  | 'professional'
  | 'uid'
  | 'profilePicture'
  | 'createdAt'
  | 'seenAt'
  | 'landlord'
  | 'certificate'
  | 'showAge'
  | 'age'
  | 'currentIdentification'
  | 'housingSituation'
>

type Landlord = {
  landlord: true
} & BaseUser &
  Pick<
    NonNullable<ProfileQueryQuery['user']>,
    'landlordApplicationResponseRate' | 'landlordApplicationResponseTimeHours'
  >

type Tenant = {
  landlord: false
} & BaseUser

type User = Tenant | Landlord

export function ProfileBadge({
  confirmed,
  confirmedLabel,
  notConfirmedLabel,
  ...rest
}: {
  confirmed?: boolean | null
  confirmedLabel?: string
  notConfirmedLabel?: string
} & ComponentProps<typeof BadgeContainer>) {
  return (
    <BadgeContainer direction="row" alignItems="center" {...rest}>
      {confirmed ? <BadgeFilledIcon size={16} /> : <BadgeIcon size={16} />}
      <BadgeText size="sm" numberOfLines={1}>
        {confirmed ? confirmedLabel : notConfirmedLabel}
      </BadgeText>
    </BadgeContainer>
  )
}

type Props = { profile: User; shouldHideUnconfirmed?: boolean }

export function ProfileBadges({ profile, shouldHideUnconfirmed }: Props) {
  const badges = getBadges(profile)

  return (
    <Wrapper direction="row" gap="4x" wrap="wrap">
      {badges.map((badge) => {
        if ((shouldHideUnconfirmed || badge.shouldHideWhenDisabled) && !badge?.isEnabled) return null
        return (
          <ProfileBadge
            confirmed={badge?.isEnabled}
            confirmedLabel={badge?.enabledTitle}
            notConfirmedLabel={badge?.disabledTitle}
            key={badge?.badgeName}
          />
        )
      })}
    </Wrapper>
  )
}
