import { differenceInCalendarDays, isSameDay } from 'date-fns'
import type { TenantPreferencesHomeFragment } from '@qasa/graphql'
import type { TFunction } from 'i18next'

import { formatDateDistance } from '../../utils/date'
import type { ParsedProfileSearchPreference } from '../../utils/parse-profile-search-preference'
import type { SearchPreferenceMatchIndicatorProps } from '../../components/search-preference-match-indicator'

type GetDurationMatchDataParams = {
  t: TFunction
  moveIn: ParsedProfileSearchPreference['moveIn']
  moveOut: ParsedProfileSearchPreference['moveOut']
  duration: TenantPreferencesHomeFragment['duration']
}
// eslint-disable-next-line complexity
export const getDurationMatchData = ({
  t,
  moveIn,
  moveOut,
  duration,
}: GetDurationMatchDataParams): SearchPreferenceMatchIndicatorProps => {
  const today = new Date()
  const moveInDateOrToday = moveIn ? new Date(moveIn) : today
  const startOptimalDateOrToday = duration.startOptimal ? new Date(duration.startOptimal) : today

  const isMoveInMatching = Boolean(
    (duration.startAsap && moveIn === null) || isSameDay(startOptimalDateOrToday, moveInDateOrToday),
  )
  const isMoveOutMatching = Boolean(
    (duration.endUfn && moveOut === null) ||
      (duration.endOptimal && moveOut && isSameDay(new Date(duration.endOptimal), new Date(moveOut))),
  )
  switch (true) {
    case isMoveInMatching && isMoveOutMatching:
      return {
        matchingLevel: 'match',
      }
    case !isMoveInMatching && !isMoveOutMatching:
      return {
        matchingLevel: 'no_match',
        differenceString: t('duration.both'),
      }
    case !isMoveInMatching: {
      const daysDifference = differenceInCalendarDays(moveInDateOrToday, startOptimalDateOrToday)
      const formattedDateDistance = formatDateDistance({
        date: moveInDateOrToday,
        referenceDate: startOptimalDateOrToday,
      })
      return {
        matchingLevel: 'is_close',
        differenceString: t('duration.move_in', {
          timeDifference: Math.abs(daysDifference) === 1 ? t('duration.one_day') : formattedDateDistance,
          timeSuffix: daysDifference < 0 ? t('duration.earlier') : t('duration.later'),
        }),
      }
    }
    case !isMoveOutMatching: {
      if (duration.endOptimal) {
        if (!moveOut) {
          return {
            matchingLevel: 'is_close',
            differenceString: t('duration.move_out_is_later'),
          }
        }
        const daysDifference = differenceInCalendarDays(new Date(moveOut), new Date(duration.endOptimal))
        const formattedDateDistance = formatDateDistance({
          date: new Date(moveOut),
          referenceDate: new Date(duration.endOptimal),
        })
        return {
          matchingLevel: 'is_close',
          differenceString: t('duration.move_out', {
            timeDifference: Math.abs(daysDifference) === 1 ? t('duration.one_day') : formattedDateDistance,
            timeSuffix: daysDifference < 0 ? t('duration.earlier') : t('duration.later'),
          }),
        }
      } else if (duration.endUfn) {
        return {
          matchingLevel: 'is_close',
          differenceString: t('duration.move_out_is_earlier'),
        }
      } else {
        return {
          matchingLevel: 'no_match',
        }
      }
    }
    default:
      return {
        matchingLevel: 'no_match',
      }
  }
}
