import type { Reference } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useToastContext } from '@qasa/app'
import type { ListingQuery } from '@qasa/graphql'

import { DESTROY_UPLOAD } from '../../data/graphql/mutations/destroy-upload'

export const useDeleteUploadFromHome = ({
  homeId,
}: {
  homeId: UNSAFE_DeepNonNullableObject<ListingQuery>['home']['id']
}) => {
  const { addToast } = useToastContext()
  const { t } = useTranslation('commons')
  const [deleteUpload] = useMutation(DESTROY_UPLOAD, {
    onError: () => {
      addToast({ message: t('unexpected_error') })
    },
  })
  const deleteFunction = ({
    id,
  }: {
    id: UNSAFE_DeepNonNullableObject<ListingQuery>['home']['uploads'][number]['id']
  }) => {
    deleteUpload({
      variables: { id },
      update: (cache, { data }) =>
        cache.modify({
          id: cache.identify({ id: homeId, __typename: 'Home' }),
          fields: {
            uploads(existingUploadRefs: Reference[] = []) {
              const deletedUpload = cache.identify({ id: data?.destroyUpload?.id, __typename: 'Upload' })
              return existingUploadRefs.filter((upload) => upload.__ref !== deletedUpload)
            },
          },
        }),
    })
  }
  return deleteFunction
}
