import { useState } from 'react'
import { useBreakpointValue } from '@qasa/qds-ui'

import type { AreaSearchProps } from '../types'
import { useAppTranslation } from '../../../contexts/i18next'
import { Autocomplete } from '../../../components/autocomplete/web'
// eslint-disable-next-line import/no-restricted-paths,@typescript-eslint/no-restricted-imports
import { AutocompleteFindHome } from '../../../features/find-home/autocomplete-find-home.web'
import { useAreaSuggestions } from '../use-area-suggestions'
import {
  getUnselectedAreaOptions,
  selectedAreaToLabel,
  selectedAreaToSublabel,
} from '../components/area-search.utils'

export function AreaSearchAutocomplete({
  isFindHome,
  value,
  onChange,
}: AreaSearchProps & {
  isFindHome?: boolean
}) {
  const { t } = useAppTranslation('area_search')
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [searchQuery, setSearchQuery] = useState('')
  const { suggestedAreas, isLoadingSuggestions } = useAreaSuggestions({
    searchQuery,
    suggestionsLimit: isMobile ? 12 : 8,
  })

  const maxOptions = isFindHome ? undefined : 3

  const placeholder =
    maxOptions && value.length >= maxOptions
      ? t('input_placeholder.max_selected')
      : value.length === 0
        ? t('input_placeholder.default')
        : t('input_placeholder.add_another_area')

  const nonSelectedAreas = getUnselectedAreaOptions({ areaSuggestions: suggestedAreas, selectedAreas: value })
  const AutocompleteComponent = isFindHome ? AutocompleteFindHome : Autocomplete

  return (
    <AutocompleteComponent
      inputValue={searchQuery}
      isLabelVisuallyHidden
      label={t('label')}
      options={nonSelectedAreas}
      maxOptions={maxOptions}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      optionToLabel={selectedAreaToLabel}
      optionToSublabel={selectedAreaToSublabel}
      onInputValueChange={setSearchQuery}
      isLoading={isLoadingSuggestions}
    />
  )
}
