import { useCallback } from 'react'
import { useQueryParams } from 'use-query-params'
import { HomeSearchOrderByEnum, HomeSearchOrderEnum } from '@qasa/graphql'

import type { FindHomeFilterValues, FindHomeFiltersContextValue } from '../contexts'

import { paramConfig } from './use-query-param-home-filters.shared'

export function useQueryParamHomeFilters(): Omit<FindHomeFiltersContextValue, 'rentalType'> {
  const [filterValues, setFilterValues] = useQueryParams(paramConfig)

  const handleClearFilterValues = (clearedFilterKeys: (keyof FindHomeFilterValues)[]) => {
    const clearedFilters: Partial<typeof filterValues> = {}

    clearedFilterKeys.forEach((key) => (clearedFilters[key] = undefined))
    clearedFilters.page = undefined
    setFilterValues(clearedFilters, 'replaceIn')
  }

  /*
   * We remove some values if they're equal to their default as
   * this cleans up the url a bit
   */
  const sanitizeFilters = useCallback((newFilters: Partial<FindHomeFilterValues>) => {
    if (newFilters.order === HomeSearchOrderEnum.DESCENDING) {
      newFilters.order = undefined
    }
    if (newFilters.orderBy === HomeSearchOrderByEnum.PUBLISHED_AT) {
      newFilters.orderBy = undefined
    }
    return newFilters
  }, [])

  /**
   * Merges into the current query parameters
   */
  const handleUpdateFilters = useCallback(
    (changedValues: Partial<FindHomeFilterValues>, shouldResetPage = true) => {
      const sanitizedFilters = sanitizeFilters(changedValues)

      // Reset page everytime the filters change
      if (shouldResetPage) {
        sanitizedFilters.page = undefined
      }
      setFilterValues(sanitizedFilters, 'replaceIn')
    },
    [sanitizeFilters, setFilterValues],
  )

  return {
    filterValues,
    clearFilterValues: handleClearFilterValues,
    updateFilterValues: handleUpdateFilters,
  }
}
