import { useMutation } from '@apollo/client'
import type { UpdateUploadMutationMutationVariables } from '@qasa/graphql'

import { UPDATE_UPLOAD } from '../../data/graphql/mutations/update-upload'

export const useOptimisticUpdateOfHomeUpload = () => {
  const [updateUpload] = useMutation(UPDATE_UPLOAD)

  const updateFunction = ({ variables }: { variables: UpdateUploadMutationMutationVariables }) => {
    const {
      id,
      input: { metadata },
    } = variables
    updateUpload({
      variables: {
        id,
        input: { metadata },
      },
      /**
       * TODO: The optimistic response below crashes sometimes, probably due to cache issues related to the
       * HOME_DRAFTS_AND_PREVIOUSLY_SIGNED_HOMES_QUERY (the home disappears from the cache and the user is
       * redirected to /404 through the edit-listing component.
       * Find out why and re-add the optimistic handling if possible
       */
      // optimisticResponse: {
      //   updateUpload: {
      //     errors: null,
      //     upload: {
      //       id,
      //       __typename: 'Upload',
      //       metadata: {
      //         __typename: 'UploadMetadata',
      //         order: metadata?.order ?? null,
      //         primary: metadata?.primary ?? null,
      //         rotation: metadata?.rotation ?? null,
      //       },
      //     },
      //     __typename: 'UpdateUploadPayload',
      //   },
      // },
    })
  }
  return updateFunction
}
