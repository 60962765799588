import { graphql } from '@qasa/graphql'

export const SEARCH_AREA = graphql(`
  query SearchAreaQuery($areaParams: [AreaParamsInput!]) {
    searchArea(areaParams: $areaParams) {
      name
      city
      identifier
      geojson
    }
  }
`)
