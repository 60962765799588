import { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { Paragraph } from '@qasa/qds-ui'

const RemainingCharacters = styled(Paragraph)<{ shouldHide: boolean }>(({ shouldHide }) => ({
  transition: '0.3s opacity',
  opacity: shouldHide ? 0 : 1,
}))

type Props = {
  className?: string
  inputValue: string
  maxCharacters: number
  /**
   * Only display the component after the inputValue changed
   */
  initiallyHidden?: boolean
  /**
   * Display the character count when input is empty
   */
  showWhenEmpty?: boolean
}

export function DisplayCharactersLeft({
  inputValue,
  maxCharacters,
  className,
  initiallyHidden = false,
  showWhenEmpty = false,
}: Props) {
  const [isHidden, setIsHidden] = useState(initiallyHidden)
  const inputChanged = useRef(false)

  useEffect(() => {
    if (inputChanged.current) {
      setIsHidden(false)
    }
    inputChanged.current = true
  }, [inputValue])

  const charactersLeft = maxCharacters - inputValue.length
  const isInputValueEmpty = inputValue.length === 0
  const shouldHide = (!showWhenEmpty && isInputValueEmpty) || isHidden

  return (
    <RemainingCharacters size="xs" shouldHide={shouldHide} className={className}>
      {charactersLeft}
    </RemainingCharacters>
  )
}
