import type { CSSObject } from '@emotion/react'

import type { Theme as CommonTheme } from '../../theme'

type NamedStyles = Record<string, CSSObject>
type StyleInterpolation<T> = (theme: CommonTheme) => T

/**
 * Creates a collection of named style rules.
 */
export function createStyleVariants<T extends NamedStyles>(styles: StyleInterpolation<T>) {
  return (theme: CommonTheme) => styles(theme)
}
