import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Button, Paragraph, Stack, Select, Textarea, Spacer, Link } from '@qasa/qds-ui'
import { useToastContext } from '@qasa/app'
import { useState } from 'react'
import { Dialog } from '@qasa/app/src/components/dialog'
import type { ReportUserMutationVariables } from '@qasa/graphql'
import { ReportTypeEnum } from '@qasa/graphql'

import { REPORT_USER } from '../../data/graphql/mutations'
import { BRAND_CONFIG } from '../../config/brand-configurations'
import { EXTERNAL_LINKS } from '../../config/external-links'

type FormData = Pick<ReportUserMutationVariables, 'reason' | 'reportType'>

export function ReportProfileForm({
  userUid,
  onProfileReported,
}: {
  userUid: string
  onProfileReported: () => void
}) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ReportUserMutationVariables>()
  const { addToast } = useToastContext()
  const { t } = useTranslation('report_profile_form')
  const [isAlreadyReported, setIsAlreadyReported] = useState(false)

  const brandName = BRAND_CONFIG.name

  const [reportUser, { loading: isLoading }] = useMutation(REPORT_USER, {
    onCompleted: ({ reportUser }) => {
      if (reportUser?.userReport) {
        onProfileReported()
        addToast({ message: t('success_toast') })
      } else {
        const isUserAlreadyReported = reportUser?.errors?.some(({ codes }) =>
          codes.includes('user_report.reported_user.taken'),
        )
        if (isUserAlreadyReported) {
          setIsAlreadyReported(true)
        } else {
          addToast({ message: t('commons:unexpected_error') })
        }
      }
    },
    onError: () => addToast({ message: t('commons:unexpected_error') }),
  })

  const onSubmit = (data: FormData) => {
    reportUser({
      variables: {
        uid: userUid,
        reason: data.reason,
        reportType: data.reportType,
      },
    })
  }

  if (isAlreadyReported) {
    return (
      <>
        <Dialog.Header hasSidePadding>
          <Dialog.Title>{t('user_already_reported.title')}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Paragraph>
            <Trans
              t={t}
              i18nKey="user_already_reported.description"
              components={[<Link key="0" isExternal href={EXTERNAL_LINKS.helpCenterUrl} />]}
            />
          </Paragraph>
        </Dialog.Body>
        <Dialog.Footer>
          <Stack alignItems="center">
            <Button onClick={onProfileReported}>{t('commons:close')}</Button>
          </Stack>
        </Dialog.Footer>
      </>
    )
  }

  return (
    <>
      <Dialog.Header hasSidePadding>
        <Dialog.Title>{t('title')}</Dialog.Title>
      </Dialog.Header>
      <Dialog.Body>
        <Paragraph> {t('description', { brandName })}</Paragraph>
        <Spacer size="4x" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap="4x" alignItems="center">
            <Select
              {...register('reportType', {
                required: t('report_type_required'),
              })}
              label={t('report_type_label')}
              placeholder={t('select_reason')}
              isInvalid={Boolean(errors?.reportType)}
              errorMessage={errors?.reportType?.message}
            >
              {Object.keys(ReportTypeEnum).map((type) => {
                return (
                  <Select.Option key={type} value={type}>
                    {t(`report_type.${type}`)}
                  </Select.Option>
                )
              })}
            </Select>
            <Textarea
              {...register('reason', { required: t('reason_required') })}
              label={t('reason_label')}
              isInvalid={Boolean(errors?.reason)}
              errorMessage={errors?.reason?.message}
            />
            <Button type="submit" isLoading={isLoading}>
              {t('submit')}
            </Button>
          </Stack>
        </form>
      </Dialog.Body>
    </>
  )
}
