import type { SVGProps } from 'react'

const VARIANT_COLORS = {
  default: '#F94F55',
  white: '#FFFFFF',
}

type ColorVariant = keyof typeof VARIANT_COLORS

type ToriLogoProps = SVGProps<SVGSVGElement> & {
  color?: ColorVariant
}
export function ToriLogo({ color = 'default', ...restProps }: ToriLogoProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 66 32" {...restProps}>
      <path
        fill={VARIANT_COLORS[color]}
        fillRule="evenodd"
        d="M65.66 3.857A3.818 3.818 0 1 1 61.83 0a3.8 3.8 0 0 1 3.83 3.857zm-36.124 6.468c-6.387 0-11.098 4.633-11.098 10.837 0 6.205 4.71 10.838 11.098 10.838 6.408 0 11.14-4.633 11.14-10.838 0-6.204-4.732-10.837-11.14-10.837zm0 5.164c3.12 0 5.474 2.337 5.474 5.673 0 3.337-2.355 5.674-5.474 5.674-3.12 0-5.454-2.337-5.454-5.674 0-3.336 2.334-5.673 5.454-5.673zm24.292-5.164c-2.525 0-4.498.871-5.793 2.805v-2.253h-5.368V31.47h5.538V20.25c0-3.06 2.388-4.441 5.237-4.441 2.382 0 3.272.467 3.272.467v-5.397a7.363 7.363 0 0 0-2.886-.553zm10.804.553h-5.539v20.59h5.539v-20.59zm-47.678 0H9.506V5.33H3.968v5.546H0v4.93h3.968v7.97c0 5.333 2.61 8.223 7.703 8.223a11.613 11.613 0 0 0 6.937-2.253l-2.323-4.153c-1.125.68-2.386 1.242-3.702 1.242-1.719 0-3.077-.935-3.077-3.506v-7.522h7.448v-4.93z"
        clipRule="evenodd"
      />
    </svg>
  )
}
