import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'

import { getAdminToken, getP1AccessToken } from '../../../utils/auth/tokens'

import { consumer } from './consumer'

export const actionCableLink = new ActionCableLink({
  cable: consumer,
  connectionParams: () => {
    const token = getAdminToken() || getP1AccessToken()
    return { accessToken: token }
  },
})
