import { graphql } from '@qasa/graphql'

export const LISTING_AVAILABILITY_SETTINGS = graphql(`
  query ListingAvailabilitySettings($homeId: ID!) {
    homeAvailabilitySettingsQuery(homeId: $homeId) {
      minNightsStay
      noCheckinDays
      rentOnlyWeekly
    }
  }
`)

export const LISTING_AVAILABILITY = graphql(`
  query ListingAvailability($homeId: ID!) {
    homeAvailabilities(homeId: $homeId) {
      date
      price
      status
    }
  }
`)

export const UPSERT_LISTING_AVAILABILITY_SETTINGS = graphql(`
  mutation UpsertListingAvailabilitySettings($input: UpsertHomeAvailabilitySettingsInput!) {
    upsertHomeAvailabilitySettings(input: $input) {
      errors {
        codes
        field
      }
      homeAvailabilitySettings {
        minNightsStay
        noCheckinDays
        rentOnlyWeekly
      }
    }
  }
`)
