import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { useState, useEffect } from 'react'
import { Stack, Heading, Button, TrashIcon, IconButton, ArrowLeftIcon } from '@qasa/qds-ui'
import { useToastContext } from '@qasa/app'
import { Dialog } from '@qasa/app/src/components/dialog'
import { CreditReportFetchStatusEnum } from '@qasa/graphql'

import { VerificationDataCard } from '../../../../ui-shared/verification-data-card'
import { useEffectOnMount } from '../../../../hooks/use-effect-on-mount'
import { GET_CREDIT_REPORT } from '../credit-report.gql'
import { PageNetworkStatus } from '../../../../ui-shared/_core/network-statuses'
import { useAuthContext } from '../../../../context/auth-context'
import type { CreditVerificationStepProps } from '../credit-verification-step'
import { CreditVerificationSteps } from '../credit-verification-step'
import { UserDataVisibilityHintbox } from '../../../../ui-shared/user-data-visibility-hintbox'
import { StatusTag } from '../../../../ui-shared/status-tag'
import { DialogAndWizardActionsFooter } from '../../../../ui-shared/dialog-and-wizard-actions-footer'

import { useFormatCreditReportData } from './use-format-credit-report-data'
import { CreditVerificationDelete } from './credit-verification-delete'

const Wrapper = Stack

export function CreditVerificationVerified({
  goNext,
  goBack,
  manuallyUpdateVerificationState,
  isSettingsVerificationFlow,
}: CreditVerificationStepProps) {
  const { t } = useTranslation('create_application_with_verifications')
  const [pollingTimeout, setPollingTimeout] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isDeletingReport, setIsDeletingReport] = useState(false)
  const { addToast } = useToastContext()

  const { refreshAuthBody } = useAuthContext()

  const handleErrorGettingReportData = () => {
    addToast({ message: t('credit_verification_step.verified.error_report_data') })
    manuallyUpdateVerificationState(CreditVerificationSteps.CreditInitial)
  }
  const [, { data, startPolling, stopPolling }] = useLazyQuery(GET_CREDIT_REPORT, {
    fetchPolicy: 'no-cache',
    onError: () => handleErrorGettingReportData(),
  })
  const infoItems = useFormatCreditReportData(data?.me?.creditReport)

  useEffectOnMount(() => {
    startPolling?.(1000)
    setIsLoading(true)
    const timeout = window.setTimeout(() => {
      stopPolling()
      setIsLoading(false)
      handleErrorGettingReportData()
    }, 15000)
    setPollingTimeout(timeout)
  })

  const resetProcesses = () => {
    stopPolling()
    setIsLoading(false)
    window.clearTimeout(pollingTimeout)
  }

  useEffect(() => {
    if (data?.me?.creditReport?.fetchStatus === CreditReportFetchStatusEnum.downloaded) {
      resetProcesses()
      refreshAuthBody()
    } else if (data?.me?.creditReport?.fetchStatus === CreditReportFetchStatusEnum.errored) {
      resetProcesses()
      handleErrorGettingReportData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleDelete = async () => {
    setIsDeletingReport(true)
  }

  const handleCancelDelete = () => {
    setIsDeletingReport(false)
  }

  if (isLoading) {
    return <PageNetworkStatus isLoading={isLoading} />
  }

  if (isDeletingReport) {
    return (
      <CreditVerificationDelete
        manuallyUpdateVerificationState={manuallyUpdateVerificationState}
        onCancel={handleCancelDelete}
        data={data?.me?.creditReport}
      />
    )
  }

  return (
    <Dialog.Body>
      <Wrapper gap={'8x'}>
        <Heading size="lg">{t(`credit_verification_step.verified.title`)}</Heading>
        <div style={{ alignSelf: 'flex-start' }}>
          <StatusTag tagColor="green">{t('verifications:card.statuses.verified')}</StatusTag>
        </div>
        <VerificationDataCard infoItems={infoItems} />
        <Button
          iconLeft={TrashIcon}
          variant="tertiary"
          size="sm"
          onClick={handleDelete}
          style={{ alignSelf: 'flex-start' }}
        >
          {t('credit_verification_step.verified.remove_credit_report_label')}
        </Button>
        <UserDataVisibilityHintbox mode="private" />
        <DialogAndWizardActionsFooter>
          <IconButton
            type="button"
            icon={ArrowLeftIcon}
            variant="tertiary"
            label={t('commons:back')}
            onClick={() => goBack()}
          />
          <Button onClick={() => goNext()}>
            {isSettingsVerificationFlow ? t('commons:close') : t('commons:next')}
          </Button>
        </DialogAndWizardActionsFooter>
      </Wrapper>
    </Dialog.Body>
  )
}
