import capitalize from 'lodash/capitalize'
import range from 'lodash/range'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Heading, Stack } from '@qasa/qds-ui'

import { FormSelectField } from '../../../ui-page-modules/create-tenant-listing/fields/form-select-field'
import type { OccupationFormValues } from '../use-occupation-form'
import { getFormattedMonths } from '../occupation.utils'

import { ERROR_OCCUPATION_I18N_KEY, OCCUPATION_I18N_KEY } from './title'

const CURRENT_YEAR = new Date().getFullYear()
const CURRENT_MONTH = new Date().getMonth()

const startYearOptions = range(CURRENT_YEAR, 1940, -1).map((year) => ({
  value: year,
  label: year.toString(),
}))

export function StartDateField() {
  const { t } = useTranslation('edit_profile')
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<OccupationFormValues>()
  const { startYear } = watch()
  const isCurrentYear = startYear === CURRENT_YEAR

  const formattedMonths = getFormattedMonths()
  const monthOptions = formattedMonths.map((month, index) => ({
    value: index,
    label: capitalize(month),
    isDisabled: isCurrentYear && index > CURRENT_MONTH,
  }))

  return (
    <Stack gap="2x">
      <Heading size="sm">{t(`${OCCUPATION_I18N_KEY}.date.dates_label`)}</Heading>
      <Stack direction={'row'} gap="3x">
        <FormSelectField
          control={control}
          name={'startMonth'}
          label={t(`${OCCUPATION_I18N_KEY}.date.start_month_label`)}
          placeholder={t(`${OCCUPATION_I18N_KEY}.date.month_placeholder`)}
          rules={{ required: t(`${ERROR_OCCUPATION_I18N_KEY}.required.start_month`) }}
          errorMessage={errors['startMonth'] && errors['startMonth'].message}
          options={monthOptions}
        />
        <FormSelectField
          control={control}
          name={'startYear'}
          label={t(`${OCCUPATION_I18N_KEY}.date.start_year_label`)}
          placeholder={t(`${OCCUPATION_I18N_KEY}.date.year_placeholder`)}
          rules={{ required: t(`${ERROR_OCCUPATION_I18N_KEY}.required.start_year`) }}
          errorMessage={errors['startYear'] && errors['startYear'].message}
          options={startYearOptions}
        />
      </Stack>
    </Stack>
  )
}
