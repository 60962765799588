import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextField } from '@qasa/qds-ui'

import {
  containsEmailUrlOrPhoneNumberValidator,
  containsEmailOrPhoneNumberValidator,
} from '../../../helpers/validation'
import type { ProfileDataFormValues } from '../profile.types'

const BIO_PETS_MAX_LENGTH = 90

type BioPetsFieldProps = {
  isProfessional: boolean
}

export function BioPetsField({ isProfessional }: BioPetsFieldProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<ProfileDataFormValues['aboutMe']>()
  const { t } = useTranslation('edit_profile')

  const validator = isProfessional
    ? containsEmailOrPhoneNumberValidator
    : containsEmailUrlOrPhoneNumberValidator

  const validateErrorMessage = isProfessional
    ? 'disallow_content_with_email_or_phone'
    : 'disallow_content_with_email_url_or_phone'

  const isBioPetsInvalid = Boolean(errors.bioPets)

  return (
    <Controller
      name={'bioPets'}
      control={control}
      rules={{
        maxLength: {
          value: BIO_PETS_MAX_LENGTH,
          message: t('errors.bioPets.too_long'),
        },
        validate: (value: string) =>
          validator()(value).valid || t(`validation_utils:error_messages.${validateErrorMessage}`),
      }}
      render={({ field }) => (
        <TextField
          label={t('form.bio.fields.bioPets.title')}
          isOptional
          placeholder={t('form.bio.fields.bioPets.placeholder')}
          maxLength={BIO_PETS_MAX_LENGTH}
          isInvalid={isBioPetsInvalid}
          errorMessage={errors.bioPets?.message}
          helperText={t('form.bio.fields.bioPets.helper_text')}
          {...field}
        />
      )}
    />
  )
}
