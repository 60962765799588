import Cookies from 'js-cookie'
import { useEffect } from 'react'

import { CookieNames } from '../../helpers/cookie'

import type { ListingStoreValues } from './edit-listing.types'

export function useCreateListingCookie({
  storeValues: {
    isProfessional,
    type,
    firsthand,
    seniorHome,
    studentHome,
    corporateHome,
    position,
    locality,
    postalCode,
    route,
    streetNumber,
    apartmentNumber,
    floor,
    buildingFloors,
    countryCode,
    country,
    tenantCount,
    rentalType,
    roomCount,
    minRoomCount,
    maxRoomCount,
    toiletCount,
    bedCount,
    bedroomCount,
    hasKitchen,
    squareMeters,
    minSquareMeters,
    maxSquareMeters,
    numberOfHomes,
    traits,
    shared,
    companyName,
    orgNumber,
    formattedAddress,
    tenureType,
    duration,
    ownHome,
    rent,
    title,
    houseRules,
    origin,
  },
  shouldSaveFormValues,
}: {
  storeValues: Partial<ListingStoreValues>
  shouldSaveFormValues: boolean
}) {
  useEffect(() => {
    if (shouldSaveFormValues) {
      Cookies.set(
        CookieNames.NEW_LISTING_DRAFT,
        JSON.stringify({
          isProfessional,
          type,
          shared,
          traits,
          firsthand,
          seniorHome,
          studentHome,
          corporateHome,
          position,
          locality,
          postalCode,
          route,
          streetNumber,
          apartmentNumber,
          floor,
          buildingFloors,
          countryCode,
          country,
          tenantCount,
          toiletCount,
          bedCount,
          bedroomCount,
          hasKitchen,
          roomCount,
          minRoomCount,
          maxRoomCount,
          squareMeters,
          minSquareMeters,
          maxSquareMeters,
          numberOfHomes,
          companyName,
          orgNumber,
          formattedAddress,
          tenureType,
          duration,
          ownHome,
          rent,
          rentalType,
          title,
          houseRules,
          origin,
        }),
        {
          expires: 30,
        },
      )
    }
  }, [
    shouldSaveFormValues,
    isProfessional,
    type,
    firsthand,
    seniorHome,
    studentHome,
    corporateHome,
    position,
    locality,
    postalCode,
    route,
    streetNumber,
    apartmentNumber,
    floor,
    buildingFloors,
    countryCode,
    country,
    tenantCount,
    toiletCount,
    bedCount,
    bedroomCount,
    hasKitchen,
    roomCount,
    minRoomCount,
    maxRoomCount,
    squareMeters,
    minSquareMeters,
    maxSquareMeters,
    numberOfHomes,
    traits,
    shared,
    companyName,
    orgNumber,
    formattedAddress,
    tenureType,
    duration,
    ownHome,
    rent,
    rentalType,
    title,
    houseRules,
    origin,
  ])
}
