import { useMutation, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { HomeRentalTypeEnum } from '@qasa/graphql'

import { useAuthContext } from '../../context/auth-context'

import { HOME_DRAFTS_AND_PREVIOUSLY_SIGNED_HOMES_QUERY } from './home-drafts-and-previously-rented-out.gql'
import { CREATE_HOME_FROM_TEMPLATE } from './home-template.gql'

type UseHomeDraftsParams = {
  onError?: () => void
}
export function useHomeDraftsAndPreviouslySignedHomes({ onError }: UseHomeDraftsParams) {
  const history = useHistory()
  const { isAuthenticated } = useAuthContext()
  const { data, loading: isLoading } = useQuery(HOME_DRAFTS_AND_PREVIOUSLY_SIGNED_HOMES_QUERY, {
    variables: { first: 2, rentalTypes: [HomeRentalTypeEnum.long_term] },
    skip: !isAuthenticated,
    onError,
  })

  const homeDrafts = data?.homeDrafts?.edges.map((homeDraft) => homeDraft.node) || []
  const totalDraftCount = data?.homeDrafts.totalCount
  const hasHomeDrafts = Boolean(totalDraftCount)

  const previouslySignedHomes = data?.previouslySignedHomes.edges.map((home) => home.node) ?? []
  const totalPreviouslySignedHomesCount = data?.previouslySignedHomes.totalCount
  const hasPreviouslySignedHomes = Boolean(totalPreviouslySignedHomesCount)

  const [createHomeFromTemplate, { loading: isLoadingCreateHome }] = useMutation(CREATE_HOME_FROM_TEMPLATE, {
    onCompleted: (data) => {
      if (data?.createHomeFromTemplate?.home?.id) {
        history.push(`/edit-listing/${data.createHomeFromTemplate.home.id}/duration`)
      } else {
        onError && onError()
      }
    },
    onError,
  })

  return {
    homeDrafts,
    isLoading,
    totalDraftCount,
    previouslySignedHomes,
    totalPreviouslySignedHomesCount,
    createHomeFromTemplate,
    isLoadingCreateHome,
    hasHomeDrafts,
    hasPreviouslySignedHomes,
  }
}
