import type { TenantPreferencesHomeFragment } from '@qasa/graphql'
import inRange from 'lodash/inRange'
import type { TFunction } from 'i18next'

import type { SearchPreferenceMatchIndicatorProps } from '../../components/search-preference-match-indicator'
import { isNumber } from '../../utils/number'
import type { ParsedProfileSearchPreference } from '../../utils/parse-profile-search-preference'

const HOUSEHOLD_SIZE_CLOSENESS_FACTOR_RANGE = [1, 1.33] as const

type GetHouseHoldSizeMatchDataParams = {
  t: TFunction
  householdSize: ParsedProfileSearchPreference['householdSize']
  matchingHome: Omit<TenantPreferencesHomeFragment, '__typename'>
}
export const getHouseholdSizeMatchData = ({
  t,
  householdSize,
  matchingHome,
}: GetHouseHoldSizeMatchDataParams): SearchPreferenceMatchIndicatorProps => {
  const isMatchingHouseholdSize = Boolean((matchingHome.tenantCount ?? 0) >= (householdSize ?? 0))
  const differenceFactor =
    matchingHome.tenantCount && householdSize && householdSize / matchingHome.tenantCount
  const isHouseholdSizeSlightlyLarger =
    !isMatchingHouseholdSize &&
    isNumber(differenceFactor) &&
    inRange(differenceFactor, ...HOUSEHOLD_SIZE_CLOSENESS_FACTOR_RANGE)
  const getMatchingLevel = () => {
    if (isMatchingHouseholdSize) {
      return 'match'
    } else if (isHouseholdSizeSlightlyLarger) {
      return 'is_close'
    }
    return 'no_match'
  }
  return {
    matchingLevel: getMatchingLevel(),
    differenceString: t('household_size', {
      count: Math.abs((householdSize ?? 0) - (matchingHome.tenantCount ?? 0)),
    }),
  }
}
