import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { CheckIcon, Divider, Heading, Paragraph, Stack } from '@qasa/qds-ui'
import type { ReactNode } from 'react'
import { TraitTypeEnum } from '@qasa/graphql'

import { getTraits } from '../listing/trait-declarations/get-traits'
import { AmenityGroupTitleEnum, type AmenityGroupType } from '../listing/trait-declarations/types'

import { useHomeContext } from './home-context'
import { HomeSection } from './home-section'

const AMENITIES_HIDDEN_IN_HOME = [
  AmenityGroupTitleEnum.RULES,
  AmenityGroupTitleEnum.ACCESSIBILITY,
  AmenityGroupTitleEnum.EXTRA,
  AmenityGroupTitleEnum.EXTRA_VACATION,
  AmenityGroupTitleEnum.POPULAR,
]

export const AmenityGrid = styled.ul(({ theme }) => ({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  display: 'grid',
  columnGap: 8,
  rowGap: 12,
  gridTemplateColumns: 'repeat(2,1fr)',
  [theme.mediaQueries.mdUp]: {
    gridTemplateColumns: 'repeat(3,1fr)',
  },
}))
const Wrapper = Stack
const AmenitiesWrapper = Stack

const AmenityWrapper = styled.li({
  gap: '12px',
  display: 'flex',
  alignItems: 'center',
  minWidth: 0,
})
const AmenityText = styled(Paragraph)<{ isAvailable: boolean }>(({ isAvailable }) => ({
  textDecoration: isAvailable ? 'none' : 'line-through',
  whiteSpace: 'nowrap',
}))

type AmenityProps = {
  title: string
  isAvailable: boolean
  children?: ReactNode
}
export function Amenity({ title, isAvailable, children }: AmenityProps) {
  return (
    <AmenityWrapper>
      {children ? children : <CheckIcon size={20} />}
      <AmenityText numberOfLines={1} size="sm" {...{ isAvailable }}>
        {title}
      </AmenityText>
    </AmenityWrapper>
  )
}

export function Amenities() {
  const { t } = useTranslation('home_amenities')
  const { home, isVacationHome } = useHomeContext()
  const { traits, market } = home

  const amenityGroups = getTraits({
    isVacationHome,
    excludedGroups: AMENITIES_HIDDEN_IN_HOME,
    marketName: market.name,
  })
  const availableAmenities = traits.map((trait) => trait.type)

  const amenitiesToShow = amenityGroups
    .map((group) => ({
      ...group,
      traits: group.traits.filter(({ trait }) => availableAmenities.includes(TraitTypeEnum[trait])),
    }))
    .filter((group) => group.traits.length !== 0)

  if (amenitiesToShow.length === 0) {
    return null
  }
  return (
    <>
      <HomeSection heading={t('heading')}>
        <Wrapper gap="6x">
          {amenitiesToShow.map((group: AmenityGroupType) => (
            <AmenitiesWrapper gap="3x" key={group.title}>
              <Heading size="3xs" as="h5">
                {t(`listing:subsection_headings.${group.title}`)}
              </Heading>
              <AmenityGrid>
                {group.traits.map(({ trait }) => (
                  <Amenity title={t(trait)} isAvailable key={trait} />
                ))}
              </AmenityGrid>
            </AmenitiesWrapper>
          ))}
        </Wrapper>
      </HomeSection>
      <Divider />
    </>
  )
}
