import compact from 'lodash/compact'
import {
  withDefault,
  NumberParam,
  StringParam,
  encodeArray,
  createEnumParam,
  decodeDelimitedArray,
} from 'serialize-query-params'
import { HomeRentalTypeEnum, HomeSearchOrderByEnum, HomeSearchOrderEnum } from '@qasa/graphql'

import type { SelectedArea } from '../../../types/find-home'
import { parseSearchAreaQuery, stringifySearchArea } from '../../../utils/search'

const SortedStringArrayParam = {
  encode: (array?: string[]) => {
    const clonedArray = array ? [...array] : []
    return encodeArray(clonedArray.sort())
  },
  decode: (arrayStr: string | (string | null)[] | null | undefined) => {
    const decodedArray = decodeDelimitedArray(arrayStr, ',')
    return compact(decodedArray)
  },
}
const HomeRentalTypeEnumParam = createEnumParam(Object.values(HomeRentalTypeEnum))
const NullableNumberParam = withDefault(NumberParam, null)
const NullableStringParam = withDefault(StringParam, null)
const SearchAreasParam = {
  encode: (searchAreas: SelectedArea[]) => encodeArray(searchAreas.map(stringifySearchArea).filter(Boolean)),
  decode: (arrayString: string | (string | null)[] | null | undefined) => {
    const parsedValue = decodeDelimitedArray(arrayString, ',')
    if (!parsedValue) {
      return []
    } else if (Array.isArray(parsedValue)) {
      return parsedValue.filter(Boolean).map((str) => parseSearchAreaQuery(str!))
    } else {
      return [parseSearchAreaQuery(parsedValue)]
    }
  },
}
const PageNumberParam = withDefault(NumberParam, 1)
const OrderEnumParam = withDefault(
  createEnumParam(Object.values(HomeSearchOrderEnum)),
  HomeSearchOrderEnum.DESCENDING,
)
const OrderByEnumParam = withDefault(
  createEnumParam(Object.values(HomeSearchOrderByEnum)),
  HomeSearchOrderByEnum.PUBLISHED_AT,
)
const UndefinableStringArrayParam = {
  encode: (array: string[]) => encodeArray(array),
  decode: (arrayStr: string | (string | null)[] | null | undefined) =>
    (decodeDelimitedArray(arrayStr, ',') as string[] | undefined) || undefined,
}
export const paramConfig = {
  minMonthlyCost: NullableNumberParam,
  maxMonthlyCost: NullableNumberParam,
  minSquareMeters: NullableNumberParam,
  maxSquareMeters: NullableNumberParam,
  minRoomCount: NullableNumberParam,
  maxRoomCount: NullableNumberParam,
  earliestMoveIn: NullableStringParam,
  minRentalLength: NullableNumberParam,
  homeTypes: SortedStringArrayParam,
  contractTypes: SortedStringArrayParam,
  safeRental: SortedStringArrayParam,
  sharedHome: SortedStringArrayParam,
  furnished: SortedStringArrayParam,
  wheelchairAccessible: SortedStringArrayParam,
  householdSize: NullableNumberParam,
  searchAreas: SearchAreasParam,
  page: PageNumberParam,
  checkInDate: NullableStringParam,
  checkOutDate: NullableStringParam,
  bedCount: NullableNumberParam,
  bedroomCount: NullableNumberParam,
  toiletCount: NullableNumberParam,
  includedRooms: SortedStringArrayParam,
  minNightlyCost: NullableNumberParam,
  maxNightlyCost: NullableNumberParam,
  popularTraits: SortedStringArrayParam,
  locationPerks: SortedStringArrayParam,
  equipmentTraits: SortedStringArrayParam,
  rentalType: HomeRentalTypeEnumParam,
  rules: SortedStringArrayParam,
  order: OrderEnumParam,
  orderBy: OrderByEnumParam,
  uids: UndefinableStringArrayParam,
}
