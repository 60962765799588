import { setContext } from '@apollo/client/link/context'

import { getAdminToken, getP1AccessToken } from '../../utils/auth/tokens'

/**
 * Set auth headers on each request
 *
 * Docs: https://www.apollographql.com/docs/react/networking/authentication/
 */
export function requestAuthMiddleware(headers: Record<string, string> = {}) {
  // This breaks with nullish coalescing, which probably means that we rely on an empty string to be returned. --magnuslh
  const token = getAdminToken() || getP1AccessToken()
  return headers['Access-Token'] !== token
    ? {
        headers: {
          ...headers,
          'Access-Token': token,
        },
      }
    : { headers }
}
export const authLink = setContext((_, { headers }) => requestAuthMiddleware(headers))
