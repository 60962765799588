import { HomeRentalTypeEnum, HomeTypeEnum } from '@qasa/graphql'
import type { CurrencyEnum, HomeSearchParamsInput, HomeTraitEnum, Home } from '@qasa/graphql'

import { snakeCase } from '../../utils/string'
import { compact } from '../../utils/array'
import { formatSpan, formatNumber } from '../../utils/number'
import type { SelectedAreaWithSearchAreaData } from '../../types/find-home'
import { useAppTranslation } from '../../contexts/i18next'
import { CURRENT_PLATFORM } from '../../env'

import { type FindHomeFilterValues, useFindHomeFiltersContext } from './contexts/find-home-filter-context'

export const PAGE_SIZE = CURRENT_PLATFORM === 'web' ? 60 : 10

export const pageToOffset = (page?: number, pageSize = PAGE_SIZE) => {
  return (page ? page - 1 : 0) * pageSize
}

type MapHomeTypesParams = { homeTypes: string[] }
const mapHomeTypes = ({ homeTypes }: MapHomeTypesParams): HomeTypeEnum[] => {
  const defaultHomeTypes = {
    other: [HomeTypeEnum.other],
    corridor: [HomeTypeEnum.corridor, HomeTypeEnum.room],
    apartment: [HomeTypeEnum.apartment, HomeTypeEnum.loft],
    house: [HomeTypeEnum.house, HomeTypeEnum.terrace_house, HomeTypeEnum.duplex],
    cottage: [HomeTypeEnum.cottage],
  }
  /**
   * NOTE: The below types are not available in the filter UI anymore, any changes need to be synced with
   * home-type-mapping.ts
   * Still defined to support old links with these query params.
   */
  const legacyHomeTypes = {
    duplex: [HomeTypeEnum.house, HomeTypeEnum.terrace_house, HomeTypeEnum.duplex],
    loft: [HomeTypeEnum.apartment, HomeTypeEnum.loft],
    own: [HomeTypeEnum.own],
    room: [HomeTypeEnum.room],
    terrace_house: [HomeTypeEnum.house, HomeTypeEnum.terrace_house, HomeTypeEnum.duplex],
  }

  const allAvailableHomeTypes: Record<HomeTypeEnum, HomeTypeEnum[]> = {
    ...defaultHomeTypes,
    ...legacyHomeTypes,
  }

  const homeTypeSet = new Set(
    homeTypes.flatMap((homeType) => allAvailableHomeTypes[homeType as HomeTypeEnum]),
  )
  return Array.from(homeTypeSet)
}

type FiltersToHomeSearchParamsProps = {
  searchParams: FindHomeFilterValues
  areas: SelectedAreaWithSearchAreaData[]
  rentalType: HomeRentalTypeEnum
}

const getIsFurnished = (furniture: string[]) => {
  const hasFurnished = furniture.includes('furnished')
  const hasUnfurnished = furniture.includes('unfurnished')

  if (hasFurnished && !hasUnfurnished) {
    return true
  }

  if (hasUnfurnished && !hasFurnished) {
    return false
  }

  return undefined
}

const getIsShared = (sharedHome: string[]) => {
  const hasSharedHome = sharedHome.includes('sharedHome')
  const hasPrivateHome = sharedHome.includes('privateHome')

  if (hasSharedHome && !hasPrivateHome) {
    return true
  }

  if (hasPrivateHome && !hasSharedHome) {
    return false
  }

  return undefined
}

// eslint-disable-next-line complexity
export const filtersToHomeSearchParams = ({
  searchParams,
  areas,
  rentalType,
}: FiltersToHomeSearchParamsProps): HomeSearchParamsInput => {
  const isVacationSearch = rentalType === HomeRentalTypeEnum.vacation
  const {
    furnished,
    contractTypes,
    wheelchairAccessible,
    householdSize,
    homeTypes,
    sharedHome,
    minMonthlyCost,
    maxMonthlyCost,
    minRoomCount,
    maxRoomCount,
    minSquareMeters,
    maxSquareMeters,
    earliestMoveIn,
    minRentalLength,
    rules,
    checkInDate,
    checkOutDate,
    minNightlyCost,
    maxNightlyCost,
    bedCount,
    bedroomCount,
    toiletCount,
    includedRooms,
    popularTraits,
    locationPerks,
    equipmentTraits,
    uids,
  } = searchParams

  const hasSetWheelchairAccessible = wheelchairAccessible.includes('wheelchairAccessible')
  const hasSetPetsAllowed = rules.includes('pets')
  const hasSetSmokingAllowed = rules.includes('smoker')

  const getTraits = () => {
    const combinedTraits = [
      ...popularTraits,
      ...locationPerks,
      ...equipmentTraits,
      ...(rules.length !== 0 ? rules : []),
      ...(hasSetWheelchairAccessible ? wheelchairAccessible : []),
    ].map((trait) => snakeCase(trait))
    if (combinedTraits.length === 0) {
      return undefined
    }
    return combinedTraits as HomeTraitEnum[]
  }

  const mappedHomeTypes = mapHomeTypes({ homeTypes })
  const areaIdentifier = compact(areas.map((area) => area.identifier))

  // TODO: This is temporary as we need to update the traits array
  // to support three values (true, false and null). The array only allows
  // for boolean
  const traitsParams = isVacationSearch
    ? {
        traits: getTraits(),
      }
    : {
        wheelchairAccessible: hasSetWheelchairAccessible || undefined,
        smoker: hasSetSmokingAllowed || undefined,
        pets: hasSetPetsAllowed || undefined,
      }

  return {
    ...traitsParams,
    furniture: getIsFurnished(furnished),
    householdSize: householdSize || undefined,
    homeType: mappedHomeTypes.length > 0 ? mappedHomeTypes : undefined,
    shared: getIsShared(sharedHome),
    firsthand: contractTypes.includes('firstHand') || undefined,
    studentHome: contractTypes.includes('studentHome') || undefined,
    seniorHome: contractTypes.includes('seniorHome') || undefined,
    corporateHome: contractTypes.includes('corporateHome') || undefined,
    minMonthlyCost: minMonthlyCost || undefined,
    maxMonthlyCost: maxMonthlyCost || undefined,
    minRoomCount: minRoomCount || undefined,
    maxRoomCount: maxRoomCount || undefined,
    maxSquareMeters: maxSquareMeters || undefined,
    minSquareMeters: minSquareMeters || undefined,
    minRentalLength: minRentalLength || undefined,
    moveInEarliest: earliestMoveIn || undefined,
    areaIdentifier,
    minBedCount: bedCount || undefined,
    minBedroomCount: bedroomCount || undefined,
    minToiletCount: toiletCount || undefined,
    hasKitchen: includedRooms.includes('kitchen') || undefined,
    minPricePerNight: minNightlyCost || undefined,
    maxPricePerNight: maxNightlyCost || undefined,
    checkIn: checkInDate || undefined,
    checkOut: checkOutDate || undefined,
    uids: uids || undefined,
    rentalType: [rentalType],
  }
}

export type HomeLocation = {
  id: string
  latitude: string
  longitude: string
  rent: number
  tenant_base_fee: number
  currency: CurrencyEnum
  average_price_per_night: number
  professional?: boolean
}

type FormatTranslatedTitleType = {
  filterValues: FindHomeFilterValues
  rentalType: HomeRentalTypeEnum
}

export const useFormattedTitle = ({ filterValues, rentalType }: FormatTranslatedTitleType) => {
  const { t } = useAppTranslation('find_home')
  let key = 'title'
  const { searchAreas, homeTypes } = filterValues
  const firstArea = searchAreas[0]?.name
  const firstHomeType = homeTypes[0]

  if (firstArea) {
    if (searchAreas.length > 1) {
      key = key + '_with_multiple_locations'
    } else {
      key = key + '_with_location'
    }
  }

  if (firstHomeType) {
    if (homeTypes.length > 1) {
      key = key + '_with_multiple_object_types'
    } else {
      key = key + '_with_object_type'
    }
  }

  const formattedRentalType =
    !firstArea && !firstHomeType
      ? t(`common_home:rental_type.${rentalType}`).toLowerCase()
      : t(`common_home:rental_type.${rentalType}_plural`)

  return t(`titles.${key}`, {
    objectType: t(`common_home:home_type.${firstHomeType}_plural`),
    rentalType: formattedRentalType,
    location: firstArea,
  })
}

export const useFormattedPricePerNight = ({
  home,
}: {
  home: Pick<Home, 'minimumPricePerNight' | 'maximumPricePerNight' | 'averagePricePerNight' | 'currency'>
}) => {
  const { t } = useAppTranslation('common_home')
  const { minimumPricePerNight, maximumPricePerNight, averagePricePerNight, currency } = home

  const { filterValues } = useFindHomeFiltersContext()
  const { minNightlyCost, maxNightlyCost } = filterValues

  const shouldDisplayPriceRange = Boolean(minNightlyCost || maxNightlyCost)

  const formattedPrice = shouldDisplayPriceRange
    ? formatSpan({
        min: minimumPricePerNight,
        max: maximumPricePerNight,
        fallbackNumber: averagePricePerNight,
        currency,
      })
    : formatNumber({ amount: averagePricePerNight ?? 0, currency })

  return averagePricePerNight ? t('vacation_home_info.price_per_night', { price: formattedPrice }) : null
}
