import { Heading as HeadingElement, Paragraph } from '@qasa/ui'
import type { ReactNode } from 'react'

import { FavoriteWrapper } from './favorite-wrapper'
import { TextWrapper } from './text-wrapper'

function Heading({ children }: { children: ReactNode }) {
  return <HeadingElement size="2xs">{children}</HeadingElement>
}

type HeroDataProps = {
  children: string
}
function HeroData({ children }: HeroDataProps) {
  return <Paragraph boldness="bolder">{children}</Paragraph>
}

export const BigDataCardParts = { Heading, HeroData, FavoriteWrapper, TextWrapper }
