import type { Polygon } from '@turf/helpers'
import type { GeoJSONSource } from 'mapbox-gl'
import { useEffect, useMemo, useRef, useState } from 'react'
import { easeCubicInOut } from 'd3-ease'
import styled from '@emotion/styled'
import { theme } from '@qasa/qds-ui'

import { getPolygonBounds, invertPolygons, useMemoedPolygons } from '../../../../helpers/map-polygons'
import { useMap } from '../../../../helpers/use-map'

const MapWrapper = styled.div(({ theme }) => ({
  height: theme.sizes[384],
  borderRadius: theme.radii.md,
  /* Z-index keeps attribution control from going on top of footer */
  zIndex: 0,
  [theme.mediaQueries.mdUp]: {
    height: theme.sizes[512],
  },
}))

type CreateTenantListingMapProps = {
  polygons: Polygon[]
  isLoading?: boolean
}

export function CreateTenantListingMap({ polygons }: CreateTenantListingMapProps) {
  const mapAnchor = useRef(null!)
  const invertedPolygons = useMemo(() => invertPolygons(polygons), [polygons]) as GeoJSON.Feature
  const memoedPolygons = useMemoedPolygons({ polygons })
  const [hasMapLoaded, setHasMapLoaded] = useState(false)
  const { map } = useMap({ initialValues: { mapAnchor, zoom: 3.5, minZoom: 3.5 } })

  useEffect(() => {
    if (map && polygons.length && hasMapLoaded) {
      const source = map.getSource('polygonSource') as GeoJSONSource

      const bounds = getPolygonBounds({ polygons: memoedPolygons })
      map.fitBounds(bounds, {
        animate: true,
        easing: easeCubicInOut,
        speed: 1.5,
        padding: 20,
      })

      if (!source) {
        map.addSource('polygonSource', {
          type: 'geojson',
          data: invertedPolygons,
        })

        map?.addLayer({
          id: 'polygonLayer',
          type: 'fill',
          source: 'polygonSource',
          layout: {},
          paint: {
            'fill-color': '#000',
            'fill-opacity': 0.12,
          },
        })

        map?.addLayer({
          id: 'outline',
          type: 'line',
          source: 'polygonSource',
          layout: {},
          paint: {
            'line-color': `${theme.colors.core.gray90}`,
            'line-width': 2,
          },
        })
      } else {
        source.setData(invertedPolygons)
      }
    }
  }, [polygons, map, hasMapLoaded, invertedPolygons, memoedPolygons])

  /* This handles toggling of the overlays, undefined is the default, ie they're visible */
  useEffect(() => {
    const hasLayers = Boolean(map?.getLayer('polygonLayer') && map?.getLayer('outline'))

    if (hasLayers) {
      if (polygons.length) {
        map?.setLayoutProperty('polygonLayer', 'visibility', undefined)
        map?.setLayoutProperty('outline', 'visibility', undefined)
      } else {
        map?.setLayoutProperty('polygonLayer', 'visibility', 'none')
        map?.setLayoutProperty('outline', 'visibility', 'none')
      }
    }
  }, [polygons, map])

  useEffect(() => {
    const listener = () => {
      setHasMapLoaded(true)
    }
    map?.on('load', listener)

    return () => {
      map?.off('load', listener)
    }
  }, [map])

  return <MapWrapper ref={mapAnchor} />
}
