import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Textarea, TextField, Stack, Spacer, Button, HintBox, Paragraph } from '@qasa/qds-ui'
import { useParams } from 'react-router-dom'
import { ampliExperiment } from '@qasa/ampli/p2'
import { useLayoutEffect, useRef } from 'react'
import { HomeRentalTypeEnum } from '@qasa/graphql'

import { FieldEnum } from '../edit-listing.types'
import { useListingContext } from '../listing-context'
import { SectionWrapper } from '../section-wrapper'
import { DisplayCharactersLeft } from '../../../ui-shared/_core/display-characters-left'
import { ErrorMessage } from '../../../ui-shared/_core/error-message'
import { useSuggestedDescription } from '../use-suggested-description'

const StyledList = styled.ul(({ theme }) => ({
  marginBlock: theme.spacing['2x'],
  listStyle: 'disc',
  paddingLeft: theme.spacing['4x'],
}))

const TITLE_MAX_LENGTH = 60
const DESCRIPTION_RULES_MAX_LENGTH = 3000
const tenantsCuriosityHints = {
  vacation: [
    'swimming_possibilities',
    'home_equipments',
    'entertainment',
    'places_nearby',
    'home_reachability',
  ],
  longterm: ['home_layout', 'interesting_facts', 'communications', 'reason_for_renting_out'],
}

const RemainingCharacters = styled(DisplayCharactersLeft)({
  textAlign: 'right',
  flexShrink: 0,
})

export function DescriptionSection() {
  const { t } = useTranslation('listing')
  const { homeId }: { homeId: string } = useParams()

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const {
    store: {
      values: { title, description, houseRules, rentalType },
    },
    getErrorMessage,
    dispatch,
  } = useListingContext()

  const {
    fetchSuggestedDescription,
    hasPreviousDescription,
    undoSuggestedDescription,
    suggestedDescription,
    isLoadingSuggestedDescription,
  } = useSuggestedDescription({ homeId, description })

  const hasSuggestedDescriptionFeatureFlag =
    ampliExperiment.getVariant('open-ai-suggested-home-description')?.value === 'on'

  useLayoutEffect(() => {
    if (textAreaRef?.current) {
      textAreaRef.current.style.transition = isLoadingSuggestedDescription ? 'height 300ms ease-in-out' : ''
      if (description || suggestedDescription) {
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
      } else {
        textAreaRef.current.style.height = `0px`
      }
    }
  }, [isLoadingSuggestedDescription, suggestedDescription, description])

  const titleErrorMessage = getErrorMessage({ field: FieldEnum.Title })
  const descriptionErrorMessage = getErrorMessage({ field: FieldEnum.Description }) || undefined
  const houseRulesErrorMessage = getErrorMessage({ field: FieldEnum.HouseRules })
  const isVacationRental = rentalType === HomeRentalTypeEnum.vacation
  const tenantsCuriosityHintsI18nKeys = isVacationRental
    ? tenantsCuriosityHints.vacation
    : tenantsCuriosityHints.longterm
  return (
    <SectionWrapper title={t(`section_headings.description`)} description={t(`description.description`)}>
      {isVacationRental && (
        <Stack gap="2x">
          <TextField
            label={t('title.description')}
            placeholder={t('title.placeholder')}
            maxLength={TITLE_MAX_LENGTH}
            value={title || ''}
            onChange={(event) => dispatch({ action: { type: FieldEnum.Title, payload: event.target.value } })}
            isInvalid={Boolean(titleErrorMessage)}
          />
          <Stack direction="row-reverse" justifyContent="space-between" alignItems="baseline">
            <RemainingCharacters inputValue={title || ''} maxCharacters={TITLE_MAX_LENGTH} showWhenEmpty />
            {titleErrorMessage && <ErrorMessage>{titleErrorMessage}</ErrorMessage>}
          </Stack>
          <HintBox>{t('title.info_box.intro')}</HintBox>
        </Stack>
      )}
      <Stack gap="2x">
        <Textarea
          ref={textAreaRef}
          label={t('description.label')}
          minRows={8}
          placeholder={t('description.placeholder')}
          value={description || suggestedDescription || ''}
          maxLength={DESCRIPTION_RULES_MAX_LENGTH}
          onChange={(event) =>
            dispatch({ action: { type: FieldEnum.Description, payload: event.target.value } })
          }
          isDisabled={isLoadingSuggestedDescription}
          resize="vertical"
          isInvalid={Boolean(descriptionErrorMessage)}
          errorMessage={descriptionErrorMessage}
        />
        <RemainingCharacters
          inputValue={description || suggestedDescription || ''}
          maxCharacters={DESCRIPTION_RULES_MAX_LENGTH}
          showWhenEmpty
        />
        <HintBox>
          <HintBox.Title>{t('description.info_box.tenants_are_curious_about')}</HintBox.Title>
          <StyledList>
            {tenantsCuriosityHintsI18nKeys.map((key) => (
              <li key={key}>{t(`description.info_box.${key}`)}</li>
            ))}
          </StyledList>
          {hasSuggestedDescriptionFeatureFlag && (
            <>
              <Spacer size={'4x'} />
              <Stack gap={'2x'}>
                <Paragraph size={'sm'}>{t('description.suggested_description_introduction')}</Paragraph>
                <Stack gap={'2x'} direction={'row'} justifyContent="flex-start">
                  <Button
                    size={'sm'}
                    variant={'secondary'}
                    onClick={fetchSuggestedDescription}
                    isLoading={isLoadingSuggestedDescription}
                  >
                    {t('description.suggested_description_button_text')}
                  </Button>
                  {hasPreviousDescription && !isLoadingSuggestedDescription && (
                    <Button size={'sm'} variant={'tertiary'} onClick={undoSuggestedDescription}>
                      {t('description.suggested_description_undo')}
                    </Button>
                  )}
                </Stack>
              </Stack>
            </>
          )}
        </HintBox>
      </Stack>

      {isVacationRental && (
        <Stack gap="2x">
          <Textarea
            label={t('house_rules.label')}
            placeholder={t('house_rules.placeholder')}
            value={houseRules || ''}
            maxLength={DESCRIPTION_RULES_MAX_LENGTH}
            onChange={(event) =>
              dispatch({ action: { type: FieldEnum.HouseRules, payload: event.target.value } })
            }
            resize="vertical"
            isInvalid={Boolean(houseRulesErrorMessage)}
          />
          <Stack direction="row-reverse" justifyContent="space-between" alignItems="baseline">
            <RemainingCharacters
              inputValue={houseRules || ''}
              maxCharacters={DESCRIPTION_RULES_MAX_LENGTH}
              showWhenEmpty
            />
            {houseRulesErrorMessage && <ErrorMessage>{houseRulesErrorMessage}</ErrorMessage>}
          </Stack>
          <HintBox>{t('house_rules.info_box.intro')}</HintBox>
        </Stack>
      )}
    </SectionWrapper>
  )
}
