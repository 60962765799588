import { useHistory } from 'react-router'

import type { GetPathType } from './get-path'
import { getPath } from './get-path'

export const useLinkTo = () => {
  const { push } = useHistory()

  const linkTo: GetPathType<void> = (to, params, queryParams) => {
    push(getPath(to, params, queryParams))
  }

  /**
   * linkToUnsafe can be used to link to any path in p2, but without type safety. If possible, prefer linkTo.
   */
  const linkToUnsafe = (to: string) => push(to)

  return { linkTo, linkToUnsafe }
}
