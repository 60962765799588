import { graphql } from '@qasa/graphql'

export const PROFILE_FOR_CREATE_TENANT_LISTING = graphql(`
  query ProfileForCreateTenantListing($uid: ID!) {
    user(uid: $uid) {
      uid
      professional
      housingSituation {
        id
        agreementType
        landlordCity
        landlordName
        landlordPhoneNumber
      }
      movingReason
      firstName
      occupations {
        ...OccupationFragment
      }
      bio {
        title
        intro
        lived
        pets
      }
      age
      showAge
      private {
        id
        familyName
        phoneNumber
      }
      tenantAd {
        id
        searchPreference {
          id
          searchAreas {
            name
            city
            geojson
            identifier
          }
          homeType
          balcony
          bathtub
          bikeRoom
          corporateHome
          countryCode
          currency
          dishWasher
          furniture
          householdSize
          inhomeSauna
          storage
          studentHome
          pets
          recycling
          seniorHome
          shared
          smoker
          washingMachine
          tumbleDryer
          wheelchairAccessible
          parking
          homeType
          minRoomCount
          minSquareMeters
          maxMonthlyCost
          startAsap
          startOptimal
          endUfn
          endOptimal
        }
      }
    }
  }
`)
