import type { BBox } from 'geojson'
import { useMemo, useEffect } from 'react'
import type { ViewState } from 'react-map-gl'
import { useMap } from 'react-map-gl'
import useSupercluster from 'use-supercluster'
import inRange from 'lodash/inRange'
import { useBreakpointValue } from '@qasa/qds-ui'
import type { ClusterFeature, PointFeature } from 'supercluster'
import isEqual from 'lodash/isEqual'

import { useDebouncedValue } from '../../../hooks/use-debounced-value'
import type { HomeLocation } from '../find-home.utils'

import { usePanCardIntoView } from './use-pan-card-into-view'
import { MapMarker } from './map-marker'
import { MapCluster } from './map-cluster'
import type { LocationProperties } from './map.utils.web'
import { calculateClusterRadius, createGeoJSONArray, isCluster, isIndividualPoint } from './map.utils.web'
import type { SelectedLocation } from './use-selected-location'
import type { ClusterType } from './use-selected-cluster'

type MapLocationsProps = {
  locations: HomeLocation[]
  selectedLocation: SelectedLocation
  selectedCluster?: ClusterType
  viewport: ViewState
  updateViewport: (newViewport: ViewState) => void
  setSelectedLocation: (location: SelectedLocation) => void
  onClusterClick: (cluster: ClusterType) => void
  onSyncStateChange: (isOutOfSync: boolean) => void
}

export function MapLocations({
  locations,
  selectedLocation,
  setSelectedLocation,
  selectedCluster,
  updateViewport,
  onClusterClick,
  onSyncStateChange,
}: MapLocationsProps) {
  const { current: map } = useMap()

  /**
   * Memoized since otherwise it changes reference on every render.
   * Without memoization the map re-renders constantly.
   */
  const currentBounds = map?.getBounds()
  const currentZoom = map?.getZoom()
  const viewport = useMemo(
    () => ({
      bounds: currentBounds?.toArray().flat() as BBox | undefined,
      zoom: currentZoom || 1,
    }),
    [currentBounds, currentZoom],
  )
  const debouncedViewport = useDebouncedValue({
    value: viewport,
    delay: 250,
  })
  const { bounds, zoom } = debouncedViewport
  const points = useMemo(() => createGeoJSONArray(locations), [locations])
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { panCardIntoView } = usePanCardIntoView()

  const pointsWithinBounds = points.filter((point) => {
    const coords = point.geometry.coordinates
    if (!bounds) return true

    return inRange(coords[0] ?? 0, bounds[0], bounds[2]) && inRange(coords[1] ?? 0, bounds[1], bounds[3])
  })
  const clusterRadius = calculateClusterRadius({ pointCount: pointsWithinBounds.length })

  const { clusters, supercluster } = useSupercluster<LocationProperties>({
    points: pointsWithinBounds,
    bounds,
    zoom,
    options: { radius: clusterRadius, maxZoom: 20 },
  })

  const handleOnPointClick = (point: PointFeature<LocationProperties>) => {
    if (!isMobile) {
      panCardIntoView({
        position: point.geometry.coordinates,
      })
    }
    setSelectedLocation({
      homeId: point.properties.homeId,
      position: point.geometry.coordinates,
      rent: point.properties.rent,
      tenant_base_fee: point.properties.tenant_base_fee,
      average_price_per_night: point.properties.average_price_per_night,
      currency: point.properties.currency,
    })
  }

  const handleOnClusterClick = (point: ClusterFeature<LocationProperties>) => {
    if (zoom >= 17) {
      const clusterLeaves = supercluster?.getLeaves(point.properties.cluster_id)
      const leaf: SelectedLocation[] =
        clusterLeaves?.map((leaf) => {
          return {
            homeId: leaf.properties.homeId,
            position: point.geometry.coordinates,
            rent: leaf.properties.rent,
            tenant_base_fee: leaf.properties.tenant_base_fee,
            average_price_per_night: leaf.properties.average_price_per_night,
            professional: leaf.properties.professional,
            currency: leaf.properties.currency,
          }
        }) || []
      onClusterClick({ leaves: leaf, clusterId: point.properties.cluster_id })
    }
    const expansionZoom = Math.min(
      supercluster?.getClusterExpansionZoom(point.properties.cluster_id) || zoom,
      20,
    )
    map?.easeTo({
      zoom: expansionZoom,
      center: [point.geometry.coordinates[0] ?? 0, point.geometry.coordinates[1] ?? 0],
    })
  }

  const isOutOfSync = !isEqual(viewport, debouncedViewport)

  useEffect(() => {
    if (!isOutOfSync) {
      onSyncStateChange(false)
      return undefined
    }

    const timeout = setTimeout(() => onSyncStateChange(isOutOfSync), 750)

    return () => clearTimeout(timeout)
  }, [isOutOfSync, onSyncStateChange])
  const markers = useMemo(
    () =>
      clusters.map((point) => {
        if (isCluster(point)) {
          return (
            <MapCluster
              key={point.properties.cluster_id}
              coordinates={point.geometry.coordinates}
              pointCount={point.properties.point_count}
              variant={'normal'}
              onClick={() => {
                handleOnClusterClick(point)
              }}
            />
          )
        }

        if (isIndividualPoint(point) && selectedLocation?.homeId !== point.id) {
          const {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            properties: { rent, tenant_base_fee, average_price_per_night, currency },
            geometry: { coordinates },
          } = point

          return (
            <MapMarker
              variant="default"
              key={point.properties.homeId}
              rent={rent}
              tenant_base_fee={tenant_base_fee}
              coordinates={coordinates}
              average_price_per_night={average_price_per_night}
              currency={currency}
              onClick={() => {
                handleOnPointClick(point)
              }}
            />
          )
        }

        return null
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clusters, selectedLocation, updateViewport, supercluster],
  )

  return (
    <>
      {markers}
      {selectedLocation && !selectedCluster && (
        <MapMarker
          key={selectedLocation.homeId}
          coordinates={[selectedLocation.position[0] ?? 0, selectedLocation.position[1] ?? 0]}
          rent={selectedLocation.rent}
          currency={selectedLocation.currency}
          average_price_per_night={selectedLocation.average_price_per_night}
          tenant_base_fee={selectedLocation.tenant_base_fee}
          variant="active"
          onClick={() => setSelectedLocation(null)}
        />
      )}
      {selectedCluster && selectedLocation && (
        <MapCluster
          key={selectedCluster.clusterId}
          coordinates={[selectedLocation.position[0] ?? 0, selectedLocation.position[1] ?? 0]}
          pointCount={selectedCluster.leaves.length}
          variant={'active'}
          onClick={() => {
            setSelectedLocation(null)
            onClusterClick(undefined)
          }}
        />
      )}
    </>
  )
}
