import { useAppTranslation } from '../../contexts/i18next'

/* TODO: This can be replaced by 18n functionality once we've updated to 21.0.0 */
export const useOrdinalNumber = () => {
  const { t } = useAppTranslation('ordinal')
  const getSuffix = (number: number) => {
    if (number === 1) {
      return 'one'
    } else if (number === 2) {
      return 'two'
    } else if (number === 3) {
      return 'few'
    } else {
      return 'other'
    }
  }
  const getOrdinalNumber = (count: number) => {
    if (count <= 9) {
      return count + t('suffix', { context: getSuffix(count) })
    }
    const lastDigit = count % 10
    return count + t('suffix', { context: getSuffix(lastDigit) })
  }

  return {
    getOrdinalNumber,
  }
}
