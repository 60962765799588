import { Spacer, Heading, Paragraph, Stack, createLucideIcon } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { PawPrint } from 'lucide-react'

import { useProfileContext } from '../profile-context'
import { ProfileSectionHeader } from '../profile-section-header'
import { ProfileSectionEnum } from '../profile.types'

import { EmptyField } from './empty-field'

const AdditionalText = styled(Paragraph)({
  wordBreak: 'break-word',
})

const PawPrintIcon = createLucideIcon(PawPrint)

export function ProfileAboutMeBlock() {
  const { profile, isOwnProfile, openEditProfileSection } = useProfileContext()
  const { t } = useTranslation('profile_page_header')

  const hasNoTitleOrIntro = !profile.bio.title && !profile.bio.intro
  const isProfessional = profile.professional
  const hasPets = Boolean(profile.bio.pets)
  const isProPilot = profile.proPilot
  const isLandlord = Boolean(profile.landlord)

  const getTitle = () => {
    if (isProPilot && isLandlord) return t('about_me_pro')
    if (isProfessional && isLandlord) return t('about_me_professional')
    return t('about_me')
  }

  return (
    <div>
      <ProfileSectionHeader
        title={getTitle()}
        isOwnProfile={isOwnProfile}
        onEditButtonClick={() => openEditProfileSection({ activeEditSection: ProfileSectionEnum.ABOUT_ME })}
      />
      <Spacer size="3x" />
      {hasNoTitleOrIntro ? (
        <EmptyField />
      ) : (
        <Stack gap="1x">
          <Heading size="xs" as="h4">
            {profile.bio.title}
          </Heading>
          {profile.bio.intro && <AdditionalText>{profile.bio.intro}</AdditionalText>}
        </Stack>
      )}
      {!isProfessional && hasPets && (
        <>
          <Spacer size="6x" />
          <Stack direction="row" gap="4x" alignItems="center">
            <PawPrintIcon size={20} />
            <Paragraph>{profile.bio.pets}</Paragraph>
          </Stack>
        </>
      )}
    </div>
  )
}
