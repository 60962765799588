import { graphql } from '@qasa/graphql'

export const OPT_IN_SHORTCUT = graphql(`
  mutation OptInShortcut($homeId: ID!) {
    acceptShortcutProposal(homeId: $homeId) {
      homeId
    }
  }
`)

export const OPT_OUT_SHORTCUT = graphql(`
  mutation OptOutShortcut($homeId: ID!, $declineReason: ShortcutProposalDeclineReasonEnum) {
    declineShortcutProposal(homeId: $homeId, declineReason: $declineReason) {
      homeId
    }
  }
`)

export const GET_HOME_STATUS = graphql(`
  query GetHomeStatus($homeId: ID!) {
    home(id: $homeId) {
      id
      status
      market {
        id
        name
      }
    }
  }
`)
