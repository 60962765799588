import { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { HintBox, Stack } from '@qasa/qds-ui'

import { useMap } from '../../../helpers/use-map'
import { useListingContext } from '../listing-context'
import { FieldEnum } from '../edit-listing.types'
import { ErrorMessage } from '../../../ui-shared/_core/error-message'
import { SectionWrapper } from '../section-wrapper'
import { MapMarker } from '../../../ui-shared/map-marker'

const MapAnchor = styled.div({
  height: '100%',
  canvas: {
    outline: 'none',
  },
})

const MapContainer = styled.div(({ theme }) => ({
  height: 500,
  /* Z-index keeps attribution control from going on top of footer */
  zIndex: 0,
  width: '100%',
  borderRadius: theme.radii.xl,
  overflow: 'hidden',
}))

export function ListingLocationMap() {
  const { t } = useTranslation('listing')
  const mapAnchor = useRef<HTMLDivElement>(null!)
  const { map } = useMap({ initialValues: { mapAnchor, zoom: 3, minZoom: 0, scrollZoom: false } })
  const {
    store: {
      values: {
        position: { latitude, longitude },
      },
    },
    getErrorMessage,
    dispatch,
  } = useListingContext()
  const errorMessage = getErrorMessage({ field: FieldEnum.Position })

  useEffect(() => {
    /* allow updating the position by dragging the map */
    function handleMoveEnd() {
      if (map) {
        dispatch({
          action: {
            type: FieldEnum.Position,
            payload: {
              longitude: map.getCenter().lng,
              latitude: map.getCenter().lat,
            },
          },
        })
      }
    }
    if (map) {
      map.on('moveend', handleMoveEnd)
    }
    return () => {
      map?.off('moveend', handleMoveEnd)
    }
  }, [map, dispatch])

  useEffect(() => {
    if (latitude && longitude) {
      map?.flyTo({ center: { lat: latitude, lng: longitude }, zoom: 18, speed: 1.5, curve: 1 })
    }
  }, [map]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SectionWrapper title={t('section_headings.map')} description={t('map.adjust_marker')}>
      <Stack gap={'2x'}>
        <MapContainer>
          <MapAnchor ref={mapAnchor} />
          <MapMarker variant={'red-with-icon'} map={map} shouldBeCenterOfMap={true} />
        </MapContainer>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <HintBox>{t('map.info_box')}</HintBox>
      </Stack>
    </SectionWrapper>
  )
}
