import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from '@qasa/qds-ui'

import { CREATE_TENANT_LISTING_PATH } from '../../legacy-pages/create-tenant-listing-page'
import { Wizard } from '../../ui-shared/wizard'

import { useCreateTenantListingContext } from './create-tenant-listing-context'
import { createTenantWizardSteps } from './step-declarations'
import { WizardRouter } from './wizard-router/wizard-router'

export function CreateTenantListing() {
  const { t } = useTranslation()
  const { triggerMutation } = useCreateTenantListingContext()
  const [currentStepIndex, setCurrentStepIndex] = useState<number | null>(null)
  const isPastFirstStep = currentStepIndex !== null && currentStepIndex > 0

  const CloseElement = (
    <Link as={RouterLink} to="/profile" onClick={isPastFirstStep ? triggerMutation : undefined}>
      {isPastFirstStep ? t('commons:save_and_close') : t('commons:close')}
    </Link>
  )
  return (
    <Wizard closeElement={CloseElement}>
      <WizardRouter
        onIndexChange={setCurrentStepIndex}
        basePath={CREATE_TENANT_LISTING_PATH}
        steps={createTenantWizardSteps}
      />
    </Wizard>
  )
}
