import { Label, Spacer } from '@qasa/qds-ui'
import type { ReactNode } from 'react'
import { useContext, createContext, forwardRef, Fragment } from 'react'

import { VisuallyHidden } from '../../visually-hidden'

import type { UseCheckboxGroupParams, UseCheckboxGroupReturn } from './use-checkbox-group'
import { useCheckboxGroup } from './use-checkbox-group'

export type CheckboxGroupProps = UseCheckboxGroupParams & {
  children: ReactNode

  /**
   * The label for the checkbox group
   */
  label: string
  /**
   * Visually hides the label, but keeps it readable for screen readers
   */
  isLabelVisuallyHidden?: boolean
}

const CheckboxGroupContext = createContext<UseCheckboxGroupReturn | undefined>(undefined)
export const useCheckboxGroupContext = () => useContext(CheckboxGroupContext)

export const CheckboxGroup = forwardRef<HTMLDivElement, CheckboxGroupProps>(
  ({ children, label, isLabelVisuallyHidden = false, ...restProps }, ref) => {
    const ariaLabelId = ['checkbox-group', label].join('-')
    const LabelWrapper = isLabelVisuallyHidden ? VisuallyHidden : Fragment

    const groupContextValue = useCheckboxGroup({ ...restProps })

    return (
      <CheckboxGroupContext.Provider value={groupContextValue}>
        <div ref={ref} role="group" aria-labelledby={ariaLabelId}>
          <LabelWrapper>
            <Label id={ariaLabelId} role="none">
              {label}
            </Label>
            <Spacer size="3x" />
          </LabelWrapper>
          {children}
        </div>
      </CheckboxGroupContext.Provider>
    )
  },
)
