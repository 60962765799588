import { styled, QasaLogo, createStyleVariants, Stack } from '@qasa/ui'

import type { TagVariant } from '../../utils/home'

export const StyledQasaLogo = styled(QasaLogo)(({ theme }) => ({
  width: theme.sizes['12x'],
  // NOTE: visually align to the text
  position: 'relative',
  top: 2,
}))
const variantStyles = createStyleVariants((theme) => ({
  firsthand: {
    backgroundColor: theme.colors.core.blue20,
  },
  shortcut: {
    backgroundColor: theme.colors.core.softPink,
  },
  instant_sign: {
    backgroundColor: theme.colors.core.softYellow,
  },
}))
export const HomeTagWrapper = styled(Stack)<{ variant: TagVariant }>(({ theme, variant }) => ({
  height: theme.sizes['10x'],
  paddingRight: theme.spacing['4x'],
  paddingLeft: theme.spacing['4x'],
  borderRadius: theme.radii.full,
  ...variantStyles(theme)[variant],
}))
