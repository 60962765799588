import { Paragraph, styled } from '@qasa/ui'
import type { ParagraphProps } from '@qasa/qds-ui'

import { useAppTranslation } from '../../../contexts/i18next'
import { formatNumber } from '../../../utils/number'
import { useFindHomeContext } from '../contexts'

const ResultsParagraph = styled(Paragraph)(({ theme }) => ({
  ...theme.typography.label.md,
}))

export function HomeListResultText(props: ParagraphProps) {
  const { t } = useAppTranslation('list_of_homes')
  const { homesResultCount, isHomesLoading } = useFindHomeContext()
  const formattedHomeCount = formatNumber({ amount: homesResultCount ?? 0 })
  const context = homesResultCount === 1 ? null : 'plural'

  return (
    <ResultsParagraph {...props}>
      {isHomesLoading
        ? t('fetching_homes')
        : t('number_of_results', {
            count: homesResultCount ?? 0,
            formattedHomeCount,
            context,
          })}
    </ResultsParagraph>
  )
}
