import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { GetCreditReportQuery } from '@qasa/graphql'

import { formatNumber } from '../../../../helpers/i18n'
import type { InfoItemType } from '../../../../ui-shared/verification-data-card'

export const useFormatCreditReportData = (
  creditReport?: NonNullable<GetCreditReportQuery['me']>['creditReport'] | null,
) => {
  const { t } = useTranslation('create_application_with_verifications')
  const [infoItems, setInfoItems] = useState<InfoItemType[]>([])

  useEffect(() => {
    const {
      currentAddress,
      moveInfo,
      numberPaymentRemarks,
      totalDebt,
      yearlyIncomeStatements,
      debtSanitization: hasHadDebtSanitization,
      guardianship: isUnderGuardianship,
      seizureAttempt: hasHadSeizureAttempt,
      expiresOn,
    } = creditReport || {}

    const getDataPointAnswer = ({
      i18nKey,
      data,
    }: {
      i18nKey: 'guardianship' | 'debt_sanitation' | 'seizure_attempt'
      data?: boolean | null
    }) => {
      switch (data) {
        case true:
          return t(`credit_verification_step.verified.items.${i18nKey}`)
        case false:
          return t(`credit_verification_step.verified.items.${i18nKey}_none`)
        default:
          return t('credit_verification_step.verified.items.no_info')
      }
    }
    const formattedAddress = currentAddress
      ? `${currentAddress?.street}${currentAddress?.street && ', '}${currentAddress?.postalCode} ${
          currentAddress?.city
        }`
      : t('credit_verification_step.verified.items.no_info')

    const formattedPaymentRemarks =
      numberPaymentRemarks === 0
        ? t('credit_verification_step.verified.items.payment_notices_none')
        : numberPaymentRemarks ?? t('credit_verification_step.verified.items.no_info')

    const formattedDebt =
      totalDebt === 0
        ? t('credit_verification_step.verified.items.debt_none')
        : totalDebt ?? t('credit_verification_step.verified.items.no_info')

    let incomePerYear = yearlyIncomeStatements ?? []
    if (yearlyIncomeStatements?.length && yearlyIncomeStatements?.length > 2) {
      incomePerYear = yearlyIncomeStatements.slice(-2)
    }

    const movesTranslationString =
      moveInfo?.count === 0
        ? 'credit_verification_step.verified.items.move_none'
        : moveInfo?.count
          ? 'credit_verification_step.verified.items.move_description'
          : 'credit_verification_step.verified.items.no_info'

    setInfoItems([
      { title: t('credit_verification_step.verified.items.address_label'), content: formattedAddress },
      {
        title: t('credit_verification_step.verified.items.move_label'),
        content: t(movesTranslationString, {
          count: moveInfo?.count,
          amount: moveInfo?.years,
        }),
      },
      ...(incomePerYear
        ? incomePerYear.map(({ amount, year }) => ({
            title: t('credit_verification_step.verified.items.income_label', { year }),
            content: amount ? formatNumber({ amount }) : t('credit_verification_step.verified.items.no_info'),
          }))
        : []),
      {
        title: t('credit_verification_step.verified.items.debt_label'),
        content: formattedDebt,
      },
      {
        title: t('credit_verification_step.verified.items.payment_notices_label'),
        content: formattedPaymentRemarks,
      },
      {
        title: t('credit_verification_step.verified.items.debt_sanitation_label'),
        content: getDataPointAnswer({
          i18nKey: 'debt_sanitation',
          data: hasHadDebtSanitization,
        }),
      },
      {
        title: t('credit_verification_step.verified.items.guardianship_label'),
        content: getDataPointAnswer({
          i18nKey: 'guardianship',
          data: isUnderGuardianship,
        }),
      },
      {
        title: t('credit_verification_step.verified.items.seizure_attempt_label'),
        content: getDataPointAnswer({
          i18nKey: 'seizure_attempt',
          data: hasHadSeizureAttempt,
        }),
      },
      {
        title: t('credit_verification_step.verified.items.valid_until_label'),
        content: expiresOn ?? t('credit_verification_step.verified.items.no_info'),
      },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditReport])

  return infoItems
}
