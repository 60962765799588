import {
  CreateTenantListing,
  CreateTenantListingProvider,
  usePreloadedFormValues,
} from '../ui-page-modules/create-tenant-listing'
import { Loading } from '../ui-shared/_core/network-statuses'

export const CREATE_TENANT_LISTING_PATH = '/create-tenant-listing'

export function CreateTenantListingPage() {
  const { isLoadingPreloadedFormValues, preloadedFormValues } = usePreloadedFormValues()

  if (isLoadingPreloadedFormValues) {
    return <Loading />
  }

  return (
    <CreateTenantListingProvider initialFormValues={preloadedFormValues}>
      <CreateTenantListing />
    </CreateTenantListingProvider>
  )
}
