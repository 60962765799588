import type { SVGProps } from 'react'

export function QasaXBlocketMobile(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 82 20" {...props}>
      <mask
        id="a"
        width={18}
        height={19}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill="#fff" d="M0 0h18v18H0V0Z" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#F71414"
          fillRule="evenodd"
          d="M15.694 2.306C14.069.648 11.534.017 9 0 6.466.017 3.93.648 2.306 2.306.647 3.932.017 6.466 0 9c.017 2.535.647 5.07 2.306 6.694C3.931 17.353 6.466 17.984 9 18c2.534-.017 5.069-.647 6.694-2.306C17.352 14.07 17.983 11.534 18 9c-.017-2.534-.648-5.069-2.306-6.694Z"
          clipRule="evenodd"
        />
      </g>
      <mask
        id="b"
        width={10}
        height={18}
        x={8}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill="#fff" d="M8.803 0H18v18H8.803V0Z" />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#C20606"
          fillRule="evenodd"
          d="M15.694 15.694C17.352 14.069 17.983 11.534 18 9c-.017-2.534-.648-5.069-2.306-6.694C14.069.648 11.534.017 9 0c-.066 0-.131.001-.197.003v17.994c.066.001.131.003.197.003 2.534-.017 5.069-.648 6.694-2.306Z"
          clipRule="evenodd"
        />
      </g>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M9.172 8.268c-.12 0-.271.022-.369.044v2.233h.39c.716 0 1.095-.444 1.095-1.16 0-.715-.4-1.117-1.116-1.117Zm.119 4.12H6.678V4.69h2.125v1.767c.206-.043.466-.076.694-.076 1.864 0 2.981 1.03 2.981 3.003 0 1.995-1.192 3.003-3.187 3.003Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        d="M29.012 7.235a.553.553 0 0 0-.782-.782l-1.818 1.82-1.82-1.819a.553.553 0 0 0-.782.783l1.82 1.818-1.818 1.82a.553.553 0 0 0 .782.781l1.818-1.82 1.82 1.819a.553.553 0 0 0 .782-.782l-1.82-1.818 1.818-1.82Z"
      />
      <g clipPath="url(#c)">
        <path
          fill="#000"
          d="M69.023 5.886c0 .408-.174 1.181-.284 1.752-.095.49-.224 1.15-1.048 1.15-1.697 0-1.464-2.186-2.844-2.186-.569 0-.906.431-.906.968 0 .403.243.78.616 1.058l1.373 1.045c1.146.8 1.611 1.864 1.611 2.687 0 1.548-1.332 2.703-2.939 2.703h-3.407c-1.286 0-1.934-.395-2.306-1.324-.098-.28-.625-1.644-.625-2.124 0-.724.494-1.284 1.248-1.284 1.451 0 2.085 2.186 3.279 2.186.469 0 .817-.411.817-.836 0-.318-.203-.66-.576-.939L61.84 9.79c-.998-.767-1.566-1.649-1.566-2.589 0-1.723 1.344-3.286 3.429-3.286h3.425c1.101 0 1.896.99 1.896 1.971Zm-11.885 5.18c0 .749.347 1.602.347 2.369 0 .836-.658 1.627-1.627 1.627-1.213 0-1.21-1.166-1.878-1.166-.668 0-.716 1.308-2.828 1.308s-4.445-2.272-4.445-5.338c0-3.066 1.997-5.81 5.096-5.81 1.772 0 2.418 1.093 3.028 1.093.795 0 .805-1.376 2.24-1.376.9 0 1.61.755 1.61 1.65 0 1.428-1.543 3.576-1.543 5.643ZM54.216 9.63c0-1.186-.95-2.147-2.122-2.147s-2.123.961-2.123 2.147c0 1.187.95 2.148 2.123 2.148 1.172 0 2.122-.961 2.122-2.148Zm25.558 1.437c0 .748.347 1.601.347 2.368 0 .836-.657 1.627-1.626 1.627-1.213 0-1.21-1.166-1.878-1.166-.668 0-.716 1.308-2.828 1.308s-4.445-2.272-4.445-5.338c0-3.066 1.997-5.81 5.096-5.81 1.772 0 2.418 1.093 3.028 1.093.794 0 .805-1.376 2.24-1.376.9 0 1.609.755 1.609 1.65 0 1.428-1.543 3.576-1.543 5.643ZM76.853 9.63c0-1.186-.95-2.147-2.122-2.147-1.173 0-2.123.961-2.123 2.147 0 1.187.95 2.148 2.123 2.148 1.172 0 2.122-.961 2.122-2.148ZM46.437 5.446c0 .728-.442 1.841-1.026 4.204-.584 2.362-.922 3.634-.922 4.804 0 1.17.538 1.9.538 2.92s-.82 1.854-1.626 1.854h-1.41c-.914 0-1.603-.992-1.603-1.854 0-1.49 1.587-1.94 1.587-2.882 0-.395-.274-.738-.67-.738-.754 0-.925 1.025-2.628 1.025-2.12 0-4.474-2.065-4.474-5.198 0-3.133 2.502-5.524 5.142-5.524 1.907 0 2.383 1.093 3.154 1.093.822 0 .928-1.377 2.285-1.377.833 0 1.654.68 1.654 1.673Zm-4.46 4.185c0-1.186-.95-2.148-2.122-2.148s-2.122.962-2.122 2.148c0 1.186.95 2.147 2.122 2.147s2.123-.961 2.123-2.147Z"
        />
      </g>
      <defs>
        <clipPath id="c">
          <path fill="#fff" d="M34 3h48v17H34z" />
        </clipPath>
      </defs>
    </svg>
  )
}
