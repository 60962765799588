import { styled, Stack, Heading, Paragraph, Button, createIcon } from '@qasa/ui'
import type { ApolloError } from '@apollo/client'

import { useAppTranslation } from '../../contexts/i18next'

const TriangleExclamationSolidIcon = createIcon({
  viewBox: '0 0 512 512',
  d: 'M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z',
})

export { TriangleExclamationSolidIcon }

const ErrorTitleRow = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing['4x'],
}))

const ErrorCard = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.colors.bg.default,
  maxWidth: theme.sizes['448'],
  borderWidth: 1,
  borderColor: theme.colors.border.default,
  borderRadius: theme.radii.md,
  padding: theme.spacing['8x'],
}))

type ErrorProps = {
  error?: ApolloError
  refetch?: () => void
}

export function Error({ error, refetch }: ErrorProps) {
  const { t } = useAppTranslation('error')

  /*
    TODO: report error to sentry when setup is complete:
    what to send: error.graphQLErrors[0].message & error.graphQLErrors[0].extensions.problems[0].explanation
    these fields give great tips on what went wrong
  */

  /*
    docs: https://www.apollographql.com/docs/react/data/error-handling/
    - If a GraphQL error occurs, your server includes it in the errors array of its response to Apollo Client.
    - Apollo Client then adds those errors to the error.graphQLErrors array returned by your useQuery call
  */

  const graphQlError = error?.graphQLErrors[0]?.message ?? null

  // ApolloServerErrorCode enum is only available from @apollo/server/errors - we don't want to install this on the client
  // here is a list of build in graphql error codes: https://www.apollographql.com/docs/apollo-server/data/errors/#built-in-error-codes

  const getErrorValues = () => {
    switch (true) {
      case Boolean(error?.networkError):
        return {
          errorTitle: t('network_error.title'),
          errorMessage: t('network_error.message'),
        }

      case Boolean(graphQlError && graphQlError.includes('INTERNAL_SERVER_ERROR')):
        return {
          errorTitle: t('server_error.title'),
          errorMessage: t('server_error.message'),
        }

      default:
        return {
          errorTitle: t('default_error.title'),
          errorMessage: t('default_error.message'),
        }
    }
  }

  const { errorTitle, errorMessage } = getErrorValues()

  return (
    <ErrorCard>
      <ErrorTitleRow alignItems="center" gap="3x">
        <TriangleExclamationSolidIcon size={24} color="negative" />
        <Heading>{errorTitle}</Heading>
      </ErrorTitleRow>
      <Stack gap="3x">
        <Paragraph>{errorMessage}</Paragraph>
        {refetch && (
          <Button isFullWidth variant="tertiary" onPress={() => refetch()}>
            <Paragraph boldness="bold" color="onBrandTertiary">
              {t('reload')}
            </Paragraph>
          </Button>
        )}
      </Stack>
    </ErrorCard>
  )
}
