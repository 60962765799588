import { createContext, useContext } from 'react'
import type { ReactNode } from 'react'
import type { HomeViewQuery, ExistingBookingsQuery, ApplicationForHomeQuery } from '@qasa/graphql'
import { HomeRentalTypeEnum, HomeStatusEnum } from '@qasa/graphql'

import { useGetExistingBookingForHome } from '../reservation-summary/reservation-summary.utils'
import { useAuthContext } from '../../context/auth-context'

import { getIsBlockListing } from './home.utils'

type HomeContextValue = {
  home: NonNullable<HomeViewQuery['home']>
  isVacationHome: boolean
  isUserCurrentHandler: boolean
  isUserLandlord: boolean
  isBlockListing: boolean
  isQasaShortcutListing: boolean
  isListingInactive: boolean
  existingBooking?: ExistingBookingsQuery['bookings'][number]
  existingApplication?: ApplicationForHomeQuery['homeApplications']['nodes'][number]
}

const HomeContext = createContext<HomeContextValue>(undefined!)

type Props = {
  children: ReactNode
  home: NonNullable<HomeViewQuery['home']>
  applicationData?: ApplicationForHomeQuery
}

function HomeContextProvider({ children, home, applicationData }: Props) {
  const { authBody } = useAuthContext()
  const userUid = authBody?.uid
  const isUserLandlord = userUid === home.landlord.uid
  const isVacationHome = home.rentalType === HomeRentalTypeEnum.vacation
  const isUserCurrentHandler = userUid === home.user.uid

  const { existingBooking } = useGetExistingBookingForHome({
    homeId: home.id,
    isUserLandlord,
    isVacationHome,
  })

  const homeStatus = home?.status

  const isBlockListing = getIsBlockListing({
    property: home.property,
    numberOfHomes: home.numberOfHomes as number | null,
  })
  const isQasaShortcutListing = Boolean(home?.user.proAgent)
  const isListingInactive =
    !isUserLandlord &&
    Boolean(homeStatus) &&
    !(
      homeStatus === HomeStatusEnum.normal ||
      homeStatus === HomeStatusEnum.in_review ||
      homeStatus === HomeStatusEnum.pending_shortcut
    )

  const value = {
    home,
    isVacationHome,
    isUserCurrentHandler,
    isUserLandlord,
    isBlockListing,
    isQasaShortcutListing,
    isListingInactive,
    existingBooking,
    existingApplication: applicationData?.homeApplications?.nodes[0],
  }
  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>
}

const useHomeContext = () => {
  const homeContext = useContext<HomeContextValue>(HomeContext)

  if (homeContext === undefined) {
    throw new Error('useHomeContext must be used within a provider')
  }
  return homeContext
}

export { HomeContextProvider, useHomeContext }
