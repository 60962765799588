import type { FieldValues } from 'react-hook-form'
import { useController } from 'react-hook-form'
import type { TextFieldProps } from '@qasa/qds-ui'
import { TextField } from '@qasa/qds-ui'

import type { InheritedUseControllerProps } from '../../ui-page-modules/create-tenant-listing/fields/form-fields.types'

type Merge<T, U> = Omit<T, keyof U> & U

type FormInputWithUnitFieldProps<TFormValues extends FieldValues> = Merge<
  TextFieldProps,
  InheritedUseControllerProps<TFormValues>
>

export function FormInputField<TFormValues extends FieldValues>({
  name,
  control,
  rules,
  ...textFieldProps
}: FormInputWithUnitFieldProps<TFormValues>) {
  const { field } = useController({ control, name, rules })

  return <TextField {...textFieldProps} {...field} />
}
