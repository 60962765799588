import styled from '@emotion/styled'
import { Label, createStyleVariants } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import type { getRentRelationToRecommendedRent } from './listing.utils'

type Status = ReturnType<typeof getRentRelationToRecommendedRent>

const colorVariant = createStyleVariants((theme) => {
  const negativeColors = {
    borderColor: theme.colors.core.red60,
    backgroundColor: theme.colors.core.red20,
  }
  return {
    high: negativeColors,
    low: negativeColors,
    average: {
      borderColor: theme.colors.core.green60,
      backgroundColor: theme.colors.core.green20,
    },
  }
})
const ColouredBox = styled.div<{ status: NonNullable<Status> }>(({ status, theme }) => {
  return {
    padding: theme.spacing['2x'],
    paddingInline: theme.spacing['4x'],
    borderRadius: theme.radii.lg,
    border: '1px solid',
    display: 'inline-block',
    ...colorVariant(theme)[status],
  }
})

type RecommendedRentAlignmentIndicatorProps = {
  status: Status
}
export function RecommendedRentAlignmentIndicator({ status }: RecommendedRentAlignmentIndicatorProps) {
  const { t } = useTranslation('recommended_rent_alignment_indicator')
  if (!status) return null

  const string = t([`rent_is_${status}`, 'rent_is_within_recommended_span'])

  return (
    <ColouredBox status={status}>
      <Label as={'span'} size={'sm'}>
        {string}
      </Label>
    </ColouredBox>
  )
}
