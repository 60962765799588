import { Stack, type StackProps } from '@qasa/qds-ui'
import { type ReactNode } from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.footer(({ theme }) => ({
  minHeight: theme.sizes['16x'],
  flexShrink: 0,
  alignItems: 'center',
  paddingInline: theme.spacing['6x'],
  paddingBottom: theme.spacing['6x'],
  [theme.mediaQueries.mdUp]: {
    paddingInline: theme.spacing['12x'],
  },
}))

type ButtonSectionProps = {
  children: ReactNode
  justifyContent?: StackProps['justifyContent']
}

export function ButtonSection({ children, justifyContent = 'center' }: ButtonSectionProps) {
  return (
    <Wrapper>
      <Stack
        gap={{
          base: '4x',
          md: '6x',
        }}
        direction="row-reverse"
        justifyContent={justifyContent}
        wrap="wrap"
      >
        {children}
      </Stack>
    </Wrapper>
  )
}
