import { useParams } from 'react-router-dom'
import { StringParam, useQueryParams } from 'use-query-params'

const SESSION_KEY = 'referredAdOrigin'

type ReferredAdOriginType = 'tori' | 'oikotie'

const isValidOrigin = (origin: string | undefined | null): origin is ReferredAdOriginType => {
  return origin === 'tori' || origin === 'oikotie'
}

export const useReferredAdOrigin = () => {
  const { homeId }: { homeId: string } = useParams()
  const [{ origin }] = useQueryParams({
    origin: StringParam,
  })

  if (isValidOrigin(origin)) {
    sessionStorage.setItem(SESSION_KEY, JSON.stringify({ savedHomeId: homeId, origin }))
    return { adOrigin: origin }
  }
  const savedOrigin = sessionStorage.getItem(SESSION_KEY)

  if (savedOrigin) {
    const { origin, savedHomeId } = JSON.parse(savedOrigin)
    if (isValidOrigin(origin) && homeId === savedHomeId) {
      return { adOrigin: origin }
    } else {
      sessionStorage.removeItem(SESSION_KEY)
    }
  }
  return { adOrigin: null }
}
