import camelCase from 'lodash/camelCase'
import type { TenantPreferencesHomeFragment } from '@qasa/graphql'

import type { ParsedProfileSearchPreference } from '../../utils/parse-profile-search-preference'
import { useAppTranslation } from '../../contexts/i18next'

import { getDurationMatchData } from './get-duration-match-data'
import { getFurnitureMatchData } from './get-furniture-match-data'
import { getHomeTypeAndSharingMatchData } from './get-home-type-and-sharing-match-data'
import { getHouseholdSizeMatchData } from './get-household-size-match-data'
import { getMonthlyCostMatchData } from './get-monthly-cost-match-data'
import { getSizeMatchData } from './get-size-match-data'

type UseSearchPreferenceMatchDataParams = {
  parsedSearchPreference: Pick<
    ParsedProfileSearchPreference,
    | 'maxMonthlyCostCents'
    | 'moveIn'
    | 'moveOut'
    | 'minRoomCount'
    | 'minSquareMeters'
    | 'householdSize'
    | 'furniture'
    | 'homeType'
    | 'shared'
    | 'requiredAmenities'
    | 'preferredAmenities'
  >
  matchingHome?: Omit<TenantPreferencesHomeFragment, '__typename'> | null
}
export const useSearchPreferenceMatchData = ({
  parsedSearchPreference,
  matchingHome,
}: UseSearchPreferenceMatchDataParams) => {
  const { t } = useAppTranslation('search_preference_differences')
  if (!matchingHome) {
    return null
  }
  const {
    maxMonthlyCostCents,
    moveIn,
    moveOut,
    minRoomCount,
    minSquareMeters,
    householdSize,
    furniture,
    homeType,
    shared,
    requiredAmenities,
    preferredAmenities,
  } = parsedSearchPreference

  const activeHomeTraits = matchingHome.traits.map((trait) => trait.type)

  return {
    maxMonthlyCostCents: getMonthlyCostMatchData({ maxMonthlyCostCents, matchingHome }),
    duration: getDurationMatchData({ t, moveIn, moveOut, duration: matchingHome.duration }),
    size: getSizeMatchData({ minRoomCount, minSquareMeters, matchingHome }),
    householdSize: getHouseholdSizeMatchData({ t, householdSize, matchingHome }),
    furniture: getFurnitureMatchData({ furniture, matchingHome }),
    homeTypeAndSharing: getHomeTypeAndSharingMatchData({ t, homeType, shared, matchingHome }),
    /**
     * TODO: this is not really type safe or nice, it would be cool if traits had the same string representation both
     * on the home traits object as well as in filters and profile preferences / saved searches, to be able to compare 1-to-1
     */
    requiredAmenities: requiredAmenities.filter((amenity) =>
      activeHomeTraits.map(camelCase).includes(amenity),
    ),
    preferredAmenities: preferredAmenities.filter((amenity) =>
      activeHomeTraits.map(camelCase).includes(amenity),
    ),
  }
}
