import { IconButton, Label, MinusIcon, Paragraph, PlusIcon, Stack, useStableId } from '@qasa/qds-ui'
import { styled } from '@qasa/ui/web'

import { useAppTranslation } from '../../../contexts/i18next'

const FieldWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing['4x'],
}))
const StepperContainer = styled('div')(({ theme }) => ({
  width: 128,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing['3x'],
}))

const TabularNums = styled('span')({
  fontVariantNumeric: 'tabular-nums',
})

type NumberStepperProps = {
  value: number
  onChange: (newValue: number) => void
  label?: string
  helperText?: string
  min?: number
  max?: number
}
export function NumberStepper({ label, value, helperText, onChange, min = 0, max = 10 }: NumberStepperProps) {
  const { t } = useAppTranslation('number_stepper')
  const id = useStableId()
  const isDecrementDisabled = value <= min
  const isIncrementDisabled = value >= max

  const handleDecrement = () => {
    if (!isDecrementDisabled) {
      onChange(value - 1)
    }
  }
  const handleIncrement = () => {
    if (!isIncrementDisabled) {
      onChange(value + 1)
    }
  }
  return (
    <FieldWrapper>
      {label && (
        <Stack>
          <Label id={id}>{label}</Label>
          {helperText && (
            <Paragraph size="sm" color="subtle">
              {helperText}
            </Paragraph>
          )}
        </Stack>
      )}
      <StepperContainer>
        <IconButton
          icon={MinusIcon}
          label={t('number_stepper.decrement')}
          variant="tertiary"
          size="sm"
          onClick={handleDecrement}
          isDisabled={isDecrementDisabled}
          aria-describedby={id}
        />
        <TabularNums>{value}</TabularNums>
        <IconButton
          icon={PlusIcon}
          label={t('number_stepper.increment')}
          variant="tertiary"
          size="sm"
          onClick={handleIncrement}
          isDisabled={isIncrementDisabled}
          aria-describedby={id}
        />
      </StepperContainer>
    </FieldWrapper>
  )
}
