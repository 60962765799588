import { useCallback, useEffect, useRef } from 'react'
import { Global } from '@emotion/react'

import faMapPinWhite from '../assets/icons/map-pin-light-white.svg'

type MapMarkerProps = {
  map?: mapboxgl.Map
  location?: {
    lat: number
    lng: number
  }
  variant?: 'dot' | 'red-with-icon'
  shouldBeCenterOfMap?: boolean
}

export function MapMarker({ map, location, variant = 'dot', shouldBeCenterOfMap }: MapMarkerProps) {
  const markerRef = useRef<mapboxgl.Marker | null>(null)
  useEffect(() => {
    const createMarker = async () => {
      const mapboxgl = (await import('mapbox-gl')).default
      if (!map || markerRef.current) return

      const element = document.createElement('div')
      element.className = `map-home-marker-${variant}`

      const marker = new mapboxgl.Marker({
        element,
        offset: variant === 'red-with-icon' ? [0, -6] : undefined,
      })
        .setLngLat(location ?? map.getCenter())
        .addTo(map)

      markerRef.current = marker

      // eslint-disable-next-line consistent-return
      return () => {
        marker.remove()
        markerRef.current = null
      }
    }
    createMarker()
  }, [map, location, variant])

  const setMarkerOnMapCenter = useCallback(() => {
    if (map) {
      markerRef.current?.setLngLat(map.getCenter())
    }
  }, [map])
  useEffect(() => {
    if (map) {
      if (shouldBeCenterOfMap) {
        map!.on('move', setMarkerOnMapCenter)
      } else {
        map!.off('move', setMarkerOnMapCenter)
      }
    }
  }, [map, shouldBeCenterOfMap, setMarkerOnMapCenter])

  useEffect(() => {
    if (location) {
      markerRef.current?.setLngLat(location)
    }
  }, [location])

  return (
    <Global
      styles={(theme) => ({
        '.map-home-marker-dot': {
          backgroundColor: theme.colors.core.uiPink,
          height: 24,
          width: 24,
          borderRadius: '50%',
          border: `2px solid white`,
          boxShadow: '0 1px 3px rgba(0, 0, 0, .3)',
        },
        '.map-home-marker-red-with-icon': {
          backgroundColor: theme.colors.core.uiPink,
          height: 32,
          width: 32,
          borderRadius: '50%',
          backgroundImage: `url(${faMapPinWhite})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'auto 20px',
        },
        '.mapboxgl-ctrl-top-right': {
          margin: 10,
        },
      })}
    />
  )
}
