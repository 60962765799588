import type { TenantPreferencesHomeFragment } from '@qasa/graphql'
import { TraitTypeEnum } from '@qasa/graphql'

import type { ParsedProfileSearchPreference } from '../../utils/parse-profile-search-preference'
import type { SearchPreferenceMatchIndicatorProps } from '../../components/search-preference-match-indicator'

type GetFurnitureMatchDataParams = {
  furniture: ParsedProfileSearchPreference['furniture']
  matchingHome: Omit<TenantPreferencesHomeFragment, '__typename'>
}
export const getFurnitureMatchData = ({
  furniture,
  matchingHome,
}: GetFurnitureMatchDataParams): SearchPreferenceMatchIndicatorProps => {
  const activeHomeTraits = matchingHome.traits.map((trait) => trait.type)
  const isHomeFurnished = activeHomeTraits.includes(TraitTypeEnum.furniture)
  const isMatching =
    furniture === 'both' ||
    Boolean(
      (isHomeFurnished && furniture === 'furnished') || (!isHomeFurnished && furniture === 'unfurnished'),
    )
  return {
    matchingLevel: isMatching ? 'match' : 'no_match',
  }
}
