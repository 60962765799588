import { useState } from 'react'

import { ProfileSectionEnum } from '../profile.types'

type UseEditProfileStateParams = { onClose: () => void }
export const useEditProfileState = ({ onClose }: UseEditProfileStateParams) => {
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false)
  const [activeEditSection, setActiveEditSection] = useState(ProfileSectionEnum.INTRO)
  const handleOpenEditSection = ({ activeEditSection }: { activeEditSection: ProfileSectionEnum }) => {
    setActiveEditSection(activeEditSection)
    setIsEditDrawerOpen(true)
  }
  const handleCloseEditSection = () => {
    onClose()
    setIsEditDrawerOpen(false)
  }

  return {
    isEditDrawerOpen,
    activeEditSection,
    openEditProfileSection: handleOpenEditSection,
    closeEditProfileSection: handleCloseEditSection,
  }
}

export type UseEditSectionStateReturn = ReturnType<typeof useEditProfileState>
