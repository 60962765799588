import type { Position } from 'geojson'
import { Marker } from 'react-map-gl'
import { styled } from '@qasa/ui/web'
import { HomeIcon, createStyleVariants } from '@qasa/qds-ui'
import type { VariantProps } from '@qasa/qds-ui'
import { HomeRentalTypeEnum } from '@qasa/graphql'

import { useFindHomeContext } from '../contexts/find-home-context'
import { formatNumber } from '../../../utils/number'
import { getTotalMonthlyCost } from '../../../utils/home'
import type { HomeLocation } from '../find-home.utils'

import { useHoverState, useIsDraggingOnClick } from './map.utils.web'

type StyledMarkerProps = {
  isHovered: boolean
}

const getMarkerVariant = createStyleVariants((theme) => ({
  active: {
    wrapper: {
      background: theme.colors.bg.brandPrimary,
      color: theme.colors.text.onBrandPrimary,
      borderColor: theme.colors.bg.brandPrimary,
      transform: `scale(${1.05})`,
    },
    icon: {
      color: theme.colors.icon.onBrandPrimary,
    },
  },
  default: {
    wrapper: {
      background: theme.colors.bg.default,
      color: theme.colors.text.default,
      borderColor: theme.colors.border.default,
      transform: `scale(${1})`,
    },
    icon: {
      color: theme.colors.icon.default,
    },
  },
}))
type MarkerVariant = VariantProps<typeof getMarkerVariant>

const StyledMarker = styled(Marker)<StyledMarkerProps>(({ isHovered }) => ({
  position: 'relative',
  zIndex: isHovered ? 2 : 0,
}))

const MarkerWrapper = styled('button')<{
  variant: MarkerVariant
  hasPrice: boolean
}>(({ theme, variant, hasPrice }) => ({
  fontSize: 12,
  height: 12 * 2,
  width: 'auto',
  paddingRight: 8,
  paddingLeft: 8,
  ...(!hasPrice && {
    height: 32,
    width: 32,
  }),
  borderRadius: !hasPrice ? '50%' : 999,
  boxShadow: theme.shadows.sm,
  fontWeight: 700,
  userSelect: 'none',
  transitionProperty: 'transform, box-shadow',
  transitionDuration: '0.1s',
  transitionTimingFunction: 'ease-out',
  border: '1px solid',
  ':hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows.sm,
  },
  ...getMarkerVariant(theme)[variant].wrapper,
}))

const StyledHomeIcon = styled(HomeIcon)<{
  variant: MarkerVariant
}>(({ variant, theme }) => ({
  ...getMarkerVariant(theme)[variant].icon,
}))

type MapMarkerProps = Pick<
  HomeLocation,
  'rent' | 'tenant_base_fee' | 'average_price_per_night' | 'currency'
> & {
  coordinates: Position
  onClick?: () => void
  variant: MarkerVariant
}

export function MapMarker({
  coordinates,
  rent,
  currency,
  tenant_base_fee,
  onClick,
  variant,
  average_price_per_night,
}: MapMarkerProps) {
  const { isHovering, hoverProps } = useHoverState()
  const { dragProps, isDragging } = useIsDraggingOnClick()
  const { rentalType } = useFindHomeContext()
  const isVacationHome = rentalType === HomeRentalTypeEnum.vacation
  const [longitude, latitude] = coordinates
  const hasPrice = Boolean(isVacationHome && average_price_per_night) || Boolean(!isVacationHome && rent)
  const VacationMarkerContent = average_price_per_night ? (
    formatNumber({ amount: average_price_per_night, currency })
  ) : (
    <StyledHomeIcon size={16} color="currentColor" variant={variant} />
  )
  const monthlyCost = getTotalMonthlyCost({ rent, tenantBaseFee: tenant_base_fee })

  return (
    <StyledMarker
      anchor="center"
      isHovered={isHovering}
      longitude={longitude}
      latitude={latitude}
      draggable={false}
    >
      <MarkerWrapper
        variant={variant}
        hasPrice={hasPrice}
        onClick={(e) => {
          e.stopPropagation()
          if (!isDragging && onClick) onClick()
        }}
        {...dragProps}
        {...hoverProps}
      >
        {isVacationHome ? VacationMarkerContent : formatNumber({ amount: monthlyCost, currency })}
      </MarkerWrapper>
    </StyledMarker>
  )
}
