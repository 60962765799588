import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { Heading, Stack, TrashIcon, IconButton, ChevronDownIcon, ChevronUpIcon } from '@qasa/qds-ui'
import type { ListingQuery } from '@qasa/graphql'

import { Image } from '../../ui-shared/_core/image'
import { SkeletonPulse } from '../../ui-shared/_core/skeletons'

import { useDeleteUploadFromHome } from './use-delete-upload-from-home'
import { useOptimisticUpdateOfHomeUpload } from './use-optimistic-image-upload'

const ImageListItemWrapper = styled(Stack)({
  display: 'flex',
})
const ImageWrapper = styled.div(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.core.gray20,
  borderRadius: theme.radii.xs,
}))
const StyledImage = styled(Image)<{ $isHidden: boolean }>(({ theme, $isHidden }) => ({
  borderRadius: theme.radii.xs,
  maxHeight: 400,
  maxWidth: '100%',
  margin: '0 auto',
  display: $isHidden ? 'none' : 'block',
}))
const SkeletonImage = styled(SkeletonPulse)(({ theme }) => ({
  borderRadius: theme.radii.xs,
  height: 400,
  backgroundClip: 'border-box',
}))
const ImageControlsWrapper = Stack
const ImageOrderControls = Stack

type ImageListItemProps = {
  imageSrc: UNSAFE_DeepNonNullableObject<ListingQuery>['home']['uploads'][number]['url']
  onIncrementOrder: () => void
  onDecrementOrder: () => void
  onDeleteUpload: () => void
  shouldDisableIncrementButton: boolean
  shouldDisableDecrementButton: boolean
}
function ImageControls({
  onIncrementOrder,
  onDecrementOrder,
  onDeleteUpload,
  shouldDisableIncrementButton,
  shouldDisableDecrementButton,
}: Omit<ImageListItemProps, 'imageSrc'>) {
  const { t } = useTranslation('listing')

  return (
    <ImageControlsWrapper justifyContent={'space-between'}>
      <IconButton icon={TrashIcon} label={t('images.delete')} onClick={onDeleteUpload} />
      <ImageOrderControls alignItems={'center'} gap={'1x'}>
        <IconButton
          icon={ChevronUpIcon}
          label={t('images.decrement')}
          onClick={onDecrementOrder}
          disabled={shouldDisableDecrementButton}
        />
        <IconButton
          icon={ChevronDownIcon}
          label={t('images.increment')}
          onClick={onIncrementOrder}
          disabled={shouldDisableIncrementButton}
        />
      </ImageOrderControls>
    </ImageControlsWrapper>
  )
}

function ImageListItem({ imageSrc, ...props }: ImageListItemProps) {
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  return (
    <ImageListItemWrapper gap="3x" direction="row">
      <ImageWrapper>
        <StyledImage
          src={imageSrc}
          fittingMethod={'full-fit-in'}
          onLoad={() => setIsImageLoaded(true)}
          $isHidden={!isImageLoaded}
        />
      </ImageWrapper>
      {!isImageLoaded && <SkeletonImage />}
      <ImageControls {...props} />
    </ImageListItemWrapper>
  )
}

export function ImagesList({
  uploads,
}: {
  uploads: UNSAFE_DeepNonNullableObject<ListingQuery>['home']['uploads'][number][]
}) {
  const { t } = useTranslation('listing')
  const { homeId }: { homeId: UNSAFE_DeepNonNullableObject<ListingQuery>['home']['id'] } = useParams()

  const updateUpload = useOptimisticUpdateOfHomeUpload()
  const handleDeleteUpload = useDeleteUploadFromHome({ homeId })

  const handleUpdateOrder = ({
    upload,
    index,
    type,
  }: {
    upload: UNSAFE_DeepNonNullableObject<ListingQuery>['home']['uploads'][number]
    index: number
    type: 'increment' | 'decrement'
  }) => {
    const adjacentUpload = uploads[index + (type === 'increment' ? 1 : -1)]
    if (!adjacentUpload) {
      return
    }
    updateUpload({
      variables: {
        id: upload.id,
        input: {
          metadata: {
            primary: type === 'decrement' && index === 1,
            order: adjacentUpload.metadata?.order,
          },
        },
      },
    })
    updateUpload({
      variables: {
        id: adjacentUpload.id,
        input: {
          metadata: {
            primary: type === 'increment' && index === 0,
            order: upload.metadata?.order,
          },
        },
      },
    })
  }
  return (
    <Stack gap={'4x'}>
      {uploads.map((upload, index) => {
        const ListItem = (
          <ImageListItem
            key={upload.id}
            imageSrc={upload.url}
            onIncrementOrder={() => handleUpdateOrder({ upload, index, type: 'increment' })}
            onDecrementOrder={() => {
              handleUpdateOrder({ upload, index, type: 'decrement' })
            }}
            onDeleteUpload={() => handleDeleteUpload({ id: upload.id })}
            shouldDisableIncrementButton={index === uploads.length - 1}
            shouldDisableDecrementButton={index === 0}
          />
        )

        if ([0, 1].includes(index)) {
          return (
            <Stack gap={'3x'} key={upload.id}>
              <Heading size={'3xs'}>
                {index === 0 ? t('images.primary_image') : t('images.other_images')}
              </Heading>
              {ListItem}
            </Stack>
          )
        }

        return ListItem
      })}
    </Stack>
  )
}
