import type { ElementType } from 'react'
import { useEffect, useMemo, useRef } from 'react'
import type { UrlUpdateType } from 'use-query-params'
import { createEnumParam, useQueryParams, withDefault } from 'use-query-params'

type TraverseStepsParams = {
  steps: readonly WizardStep<LegitimateAny>[]
  currentIndex: number
  context: LegitimateAny
  direction: 1 | -1
}

const traverseSteps = ({
  steps,
  currentIndex,
  context,
  direction = 1,
}: TraverseStepsParams): WizardStep | null => {
  const newIndex = currentIndex + direction
  const nextStep = steps[newIndex]

  if (!nextStep) {
    return null
  }

  if (!nextStep?.guard || nextStep.guard(context)) {
    return nextStep
  }

  return traverseSteps({
    steps,
    currentIndex: newIndex,
    context,
    direction,
  })
}

type Context = Record<string, unknown>

export type WizardStep<TContext extends Context = Context> = {
  id: string
  Component: ElementType
  /**
   * Condition to render the step.
   * If the condition is met, the step will be displayed.
   */
  guard?: (context: TContext) => boolean
}

type WizardOptions<TContext extends Context = Context> = {
  queryParamName?: string
  queryParamUpdateType?: UrlUpdateType
  onCompleted: () => void
  onCancelled: () => void
  onStepUpdate?: (stepId: string) => void
} & (undefined extends TContext
  ? {
      initialContext?: undefined
    }
  : {
      initialContext: TContext
    })

export const useWizard = <
  TContext extends Context = LegitimateAny,
  TSteps extends readonly WizardStep<TContext>[] = WizardStep<TContext>[],
>(
  steps: TSteps,
  options: WizardOptions<TContext>,
) => {
  const {
    queryParamName = 'step',
    queryParamUpdateType = 'replaceIn',
    onCompleted,
    onCancelled,
    onStepUpdate,
    initialContext,
  } = options
  const stepIds = steps.map((step) => step.id)
  const [{ [queryParamName]: currentStepQueryParam }, setQueryParams] = useQueryParams({
    [queryParamName]: withDefault(createEnumParam(stepIds), null),
  })
  const context = useRef<WizardOptions<TContext>['initialContext']>(initialContext)

  const currentIndex = useMemo(
    () => steps.findIndex((step) => step.id === currentStepQueryParam) || 0,
    [steps, currentStepQueryParam],
  )
  const currentStep = useMemo(() => steps[currentIndex], [currentIndex, steps])

  const entryIndex = useRef(currentIndex)

  useEffect(() => {
    // We move the "pointer" of the entry if user goes back
    if (currentIndex < entryIndex.current) {
      entryIndex.current = currentIndex
    }
  }, [currentIndex])

  if (
    currentIndex === entryIndex.current &&
    currentStep?.guard &&
    // @ts-expect-error
    !currentStep.guard(context.current)
  ) {
    const newStep = traverseSteps({
      steps,
      currentIndex,
      direction: 1,
      context: context.current,
    })

    if (!newStep) {
      // TODO: should we assume that the QP are set in that method or do we need to do it here?
      onCompleted()
    } else {
      setQueryParams({ [queryParamName]: newStep.id }, queryParamUpdateType)
      onStepUpdate?.(newStep.id)
    }
  }

  const handleBack = () => {
    const newStep = traverseSteps({
      steps,
      currentIndex,
      direction: -1,
      context: context.current,
    })

    if (!newStep) {
      // TODO: should we assume that the QP are set in that method or do we need to do it here?
      onCancelled()
      return
    }

    setQueryParams({ [queryParamName]: newStep.id }, queryParamUpdateType)
    onStepUpdate?.(newStep.id)
  }

  type NextParams = {
    context?: Partial<TContext>
  }

  const handleNext = ({ context: payload }: NextParams = {}) => {
    context.current = { ...context.current, ...payload }

    const newStep = traverseSteps({
      steps,
      currentIndex,
      direction: 1,
      context: context.current,
    })

    if (!newStep) {
      onCompleted()
      return
    }

    setQueryParams({ [queryParamName]: newStep.id }, queryParamUpdateType)
    onStepUpdate?.(newStep.id)
  }

  type GoToStepParams = {
    stepId: string
    context?: Partial<TContext>
  }
  const handleGoToStep = ({ stepId, context: payload }: GoToStepParams) => {
    const step = steps.find((step) => step.id === stepId)
    if (!step) {
      return
    }
    context.current = { ...context.current, ...payload }
    setQueryParams({ [queryParamName]: step.id }, queryParamUpdateType)
    onStepUpdate?.(step.id)
  }

  return {
    currentStep: currentStep as TSteps[number],
    goBack: handleBack,
    goNext: handleNext,
    goToStep: handleGoToStep,
    context: context.current,
  }
}
