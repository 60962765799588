import { useCallback, useState } from 'react'
import type { MutationFunctionOptions, MutationUpdaterFn } from '@apollo/client'
import type {
  UploadTypeEnum,
  OwnerTypeEnum,
  UploadMetadataInput,
  FinalizeUploadMutationMutation,
} from '@qasa/graphql'

import { useUpload } from './use-upload'

/**
 * Hook for common functionality when uploading images.
 * Returns a function that takes care of creating a upload request, uploading the image to S3 and finalizing the upload.
 * Intended to be used with file-upload.tsx
 */
type Options = {
  uploadType: UploadTypeEnum
  ownerType: OwnerTypeEnum
  ownerId: string
  cacheUpdateFunction?: MutationUpdaterFn<FinalizeUploadMutationMutation>
  refetchQueries?: MutationFunctionOptions['refetchQueries']
}

export function useUploadImage(options: Options) {
  const [isLoading, setIsLoading] = useState(false)
  const [uploadId, setUploadId] = useState<string | null>(null)
  const upload = useUpload({
    setIsLoading,
    setUploadId,
    cacheUpdateFunction: options.cacheUpdateFunction,
    refetchQueries: options.refetchQueries,
  })

  const uploadImage = useCallback(
    (file: File, metadata?: UploadMetadataInput) => {
      return upload({ file, options: { ...options, metadata } })
    },
    [options, upload],
  )

  return [uploadImage, { isLoading, uploadId }] as const
}
