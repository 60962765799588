import { useRef } from 'react'
import { Stack, Label } from '@qasa/qds-ui'
import { styled } from '@qasa/ui/web'

import { useAppTranslation } from '../../../contexts/i18next'

import { MapPopupContent } from './map-popup-content.web'
import { POPUP_DESKTOP_HEIGHT, POPUP_DESKTOP_WIDTH, POPUP_MOBILE_HEIGHT } from './map.utils.web'
import type { ClusterType } from './use-selected-cluster'
import { Slider, SliderItem } from './carousel/carousel.web'
import { NavigationControls } from './carousel/navigation-controls.web'
import { useCarousel } from './carousel/use-carousel.web'

const NAVIGATION_HEIGHT = 40

const SliderWrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  height: POPUP_MOBILE_HEIGHT,
  [theme.mediaQueries.mdUp]: {
    width: POPUP_DESKTOP_WIDTH,
    height: POPUP_DESKTOP_HEIGHT,
  },
}))

const NavigationWrapper = styled('div')(({ theme }) => ({
  height: NAVIGATION_HEIGHT,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  borderTop: '1px solid',
  borderColor: theme.colors.border.default,
  backgroundColor: theme.colors.bg.default,
}))

type Props = {
  selectedCluster: ClusterType
}

export function MapHomeCardCarousel({ selectedCluster }: Props) {
  const { t } = useAppTranslation('map')
  const sliderRef = useRef<null | HTMLUListElement>(null)
  const carouselWrapperRef = useRef(null)

  const { registerItem, currentIndex, itemCount, isFirstStep, isLastStep, manualScroll } = useCarousel({
    containerRef: carouselWrapperRef,
    sliderRef,
    observationThreshold: 0.75,
  })

  if (!selectedCluster) return null

  // showing non-professional homes first to highlight them
  selectedCluster.leaves.sort((a, b) => Number(a?.professional) - Number(b?.professional))

  return (
    <>
      <SliderWrapper>
        <Slider ref={sliderRef}>
          {selectedCluster.leaves.map((leaf, index) => {
            if (leaf)
              return (
                <SliderItem key={leaf.homeId} ref={(item: HTMLLIElement) => registerItem({ item, index })}>
                  <MapPopupContent {...{ selectedLocation: leaf }} />
                </SliderItem>
              )
            return null
          })}
        </Slider>
      </SliderWrapper>
      <NavigationWrapper>
        <NavigationControls
          hasPrevious={!isFirstStep}
          onPreviousClick={(event) => {
            event.preventDefault()
            manualScroll(-1)
          }}
          hasNext={!isLastStep}
          onNextClick={(event) => {
            event.preventDefault()
            manualScroll(1)
          }}
        />
        <Label size={'sm'}> {t('showing', { current: currentIndex + 1, itemCount })}</Label>
      </NavigationWrapper>
    </>
  )
}
