import type { TextFieldProps } from '@qasa/qds-ui'
import { TextField } from '@qasa/qds-ui'
import type { FieldValues } from 'react-hook-form'
import { useController } from 'react-hook-form'
import type { ChangeEvent } from 'react'

import type { InheritedUseControllerProps } from './form-fields.types'

type FormInputWithUnitFieldProps = Pick<TextFieldProps, 'placeholder' | 'errorMessage' | 'maxLength'> & {
  label: string
  unit: string
}

export function FormInputWithUnitField<TFormValues extends FieldValues>({
  unit,
  errorMessage,
  placeholder,
  maxLength,
  label,
  ...formProps
}: FormInputWithUnitFieldProps & InheritedUseControllerProps<TFormValues>) {
  const { field } = useController(formProps)
  const { onChange, ...fieldProps } = field

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (ev.target.value === '') {
      onChange(null)
      return
    }
    const value = parseFloat(ev.target.value)
    if (value) {
      onChange(value)
    }
  }
  return (
    <TextField
      label={label}
      placeholder={placeholder}
      maxLength={maxLength}
      suffix={unit}
      onChange={handleChange}
      isInvalid={Boolean(errorMessage)}
      errorMessage={errorMessage ?? undefined}
      {...fieldProps}
    />
  )
}
