import { useEffect, useState } from 'react'
import { createEnumParam, useQueryParam, withDefault } from 'use-query-params'
import type { ProfileQueryQuery } from '@qasa/graphql'

import { OCCUPATION_FORM_STEP_QUERY_PARAM, OccupationForm, OccupationFormSteps } from './occupation-form'
import { OccupationsList } from './occupations-list'

export type OccupationType = NonNullable<NonNullable<ProfileQueryQuery['user']>['occupations']>[number]

type TenantOccupationProps = {
  occupations: OccupationType[]
  onComplete: () => void
  onCancel: () => void
  occupationsListActions?: React.ReactNode
  shouldShowDataVisibilityInfo?: boolean
}

export function TenantOccupation({
  occupations,
  onComplete,
  onCancel,
  shouldShowDataVisibilityInfo = false,
  occupationsListActions,
}: TenantOccupationProps) {
  const [occupationToEdit, setOccupationToEdit] = useState<OccupationType | null>(null)
  const [occupationFormStep, setOccupationFormStep] = useQueryParam(
    OCCUPATION_FORM_STEP_QUERY_PARAM,
    withDefault(createEnumParam(Object.values(OccupationFormSteps)), null),
  )

  useEffect(() => {
    setOccupationFormStep(occupations.length === 0 ? OccupationFormSteps.Type : null, 'replace')
    return () => {
      // NOTE: the cleanup of the query param is necessary since the component could be used within a dialog
      // that might be closed from the parent component
      setOccupationFormStep(null, 'replace')
    }
  }, [occupations.length, setOccupationFormStep])

  const handleComplete = () => {
    onComplete()
    setOccupationFormStep(null, 'replace')
  }
  const hasNoOccupations = occupations.length === 0
  const handleCancel = () => {
    setOccupationFormStep(null, 'replace')
    if (hasNoOccupations) {
      onCancel()
    }
  }

  if (occupationFormStep) {
    return (
      <OccupationForm
        onCancel={handleCancel}
        onComplete={handleComplete}
        occupation={occupationToEdit}
        shouldShowDataVisibilityInfo={shouldShowDataVisibilityInfo}
      />
    )
  }
  const handleOpenForm = (occupation?: OccupationType) => {
    setOccupationToEdit(occupation ?? null)
    setOccupationFormStep(occupation ? OccupationFormSteps.Details : OccupationFormSteps.Type, 'replace')
  }
  return (
    <>
      <OccupationsList occupations={occupations} onOpenForm={handleOpenForm} onDelete={onComplete} />
      {occupationsListActions}
    </>
  )
}
