import type { ReactNode } from 'react'
import { Heading, Paragraph, Stack } from '@qasa/qds-ui'

type SectionWrapperProps = {
  title: string
  description?: string
  children?: ReactNode
  className?: string
}
export function SectionWrapper({ className, children, title, description }: SectionWrapperProps) {
  return (
    <Stack gap="8x" className={className}>
      <Stack gap="3x">
        <Heading as={'h1'}>{title}</Heading>
        {description && <Paragraph>{description}</Paragraph>}
      </Stack>
      {children}
    </Stack>
  )
}
