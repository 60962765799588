import moment from 'moment'
import type { BaseUserFragment, MeQueryQuery, ProfileQueryQuery } from '@qasa/graphql'

const PROFILE_COMPLETENESS_THRESHOLD = 75
const DAYS_THRESHOLD = 4

type ProfileName = Pick<BaseUserFragment, 'firstName' | 'companyName' | 'professional'>
export const getProfileName = ({ user }: { user?: ProfileName }) => {
  /*
  TODO: User should be available when accessing a protected route.
  However, when reaching a protected route directly from the login page in P1,
  the current authentication checks may have finished before related user-info is fetched, causing the page to crash.
  This should be solved on a more fundamental level. Consider more rigorous authentication checks.
  */
  if (!user) {
    return ''
  }

  if (user.professional && user.companyName) {
    return user.companyName
  }
  return user.firstName || ''
}
type GetIsSearchPreferencesFilledParams = {
  profile: NonNullable<ProfileQueryQuery['user']>
}
export const getIsSearchPreferencesFilled = ({ profile }: GetIsSearchPreferencesFilledParams) => {
  if (!profile.tenantAd?.searchPreference) return false

  const { homeType, householdSize, minRoomCount, minSquareMeters, maxMonthlyCost, searchAreas } =
    profile.tenantAd.searchPreference
  const { title } = profile.bio

  const requiredFieldsForPublish = [
    homeType,
    householdSize,
    minRoomCount,
    minSquareMeters,
    maxMonthlyCost,
    searchAreas,
    title,
  ]

  return Object.values(requiredFieldsForPublish).every((value) => {
    if (Array.isArray(value)) return value.length > 0
    return Boolean(value)
  })
}

type ProfileCompleteness = Pick<UNSAFE_DeepNonNullableObject<MeQueryQuery>['me'], 'tenantProfileCompleteness'>
export const getIsProfileCompletenessLow = ({ user }: { user?: ProfileCompleteness }) => {
  /**
   * NOTE: since this is a new (2022-01) property that has been added to the `authBody`, most users won't have it in the
   * `authBody` that is stored in localStorage.
   *  Therefore, without optional chaining this might crash even if the `tenantProfileCompleteness` property is required on the type.
   * TODO: We should find a way to refactor the authBody to handle this uncertainty.
   */
  const currentCompletenessScore = user?.tenantProfileCompleteness?.completenessScore
  const isProfileCompletenessLow =
    currentCompletenessScore && currentCompletenessScore < PROFILE_COMPLETENESS_THRESHOLD
  return Boolean(isProfileCompletenessLow)
}

export const getProfileAge = ({
  profile,
}: {
  profile: Pick<BaseUserFragment, 'age' | 'showAge' | 'professional'>
}) => {
  const hasAgeAndNotProffesional = profile.age && profile?.age > 0 && !profile.professional
  return profile.showAge && hasAgeAndNotProffesional ? profile.age : null
}

type GetIsTenantAdRenewableParams = {
  tenantAdPublishedUntil: QasaDateTime
}
export const getIsTenantAdRenewable = ({ tenantAdPublishedUntil }: GetIsTenantAdRenewableParams) => {
  if (!tenantAdPublishedUntil) return false
  const currentDate = moment()
  const daysLeftBeforeExpiration = moment(tenantAdPublishedUntil).diff(currentDate, 'days')
  return daysLeftBeforeExpiration < DAYS_THRESHOLD
}
