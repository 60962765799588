import { graphql } from '@qasa/graphql'

export const CONTRACT = graphql(`
  query ContractQuery($id: ID!) {
    contract(id: $id) {
      ...BaseContract
      extensionContract {
        id
        status
        signUrl
        rent
        currency
        duration {
          startOptimal
          endOptimal
          endUfn
        }
        signatories {
          id
          role
          signedAt
          order
        }
        motherContract {
          id
        }
      }
      conversation {
        id
      }
      marketDetails {
        ... on FinlandMarketDetails {
          id
          minimumStayMonths
          rentIndexation {
            fixedPercentage
            indexationOption
          }
        }
      }
      inspectionProtocol {
        url
        id
      }
      currency
      qasaGuarantee
      insurance
      termsUrl
      depositFree
      depositFreeCost
      depositPayoutApprovedAt
      depositPayoutDeniedAt
      signUrl
      language
      availablePreviewLanguages
      numberOfTenants
      pendingTerminationEndDate
      apartmentNumber
      tenantFees {
        waterFeePerTenant {
          availability
          cost {
            fixed
            isFree
          }
        }
        monthlyElectricityFee {
          availability
          cost {
            fixed
            isFree
          }
        }
      }
      electricityFee {
        monthlyFee
        paymentPlan
      }
      landlordFees {
        base {
          availability
          cost {
            factor
            total
          }
        }
      }
      ...FinancePayouts
      ...FinanceDepositInvoices
      ...FinanceRentInvoices
      ...FinanceGenericInvoice
      tenantPostSignDocuments {
        url
        id
        filename
        contentType
      }
      landlordPostSignDocuments {
        url
        id
        filename
        contentType
      }
      marketDetails {
        ... on FinlandMarketDetails {
          id
          rentIndexation {
            fixedPercentage
            indexationOption
          }
        }
      }
      invoices {
        amount
        currency
        guaranteed
        periodEndsAt
        periodStartsAt
        type
        dueAt
        paidAt
        id
        sentAt
        sendAt
        creditedAmount
        batch
        url
        invoiceEntries {
          amount
          currency
          costType
          entryType
          id
        }
      }
      home {
        id
        market {
          id
          name
        }
      }
    }
  }
`)
