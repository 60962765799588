import { Dialog } from '@qasa/app/src/components/dialog'
import { HousingSituationAgreementTypeEnum } from '@qasa/graphql'
import { useQuery } from '@apollo/client'

import { useProfileContext } from '../profile-context'
import { HousingSituation } from '../../../feature-modules/housing-situation/housing-situation'
import { NetworkStatus } from '../../../ui-shared/_core/network-statuses'

import { HOUSING_SITUATION_EXTRA_DATA } from './housing-situation-section.gql'

export function HousingSituationSection() {
  const { profile, isEditDrawerOpen, closeEditProfileSection, refetchProfile } = useProfileContext()
  const { data, loading: isLoading, error } = useQuery(HOUSING_SITUATION_EXTRA_DATA)

  if (isLoading || error) {
    return <NetworkStatus {...{ isLoading, error }} />
  }

  const handleOpenChange = () => {
    closeEditProfileSection()
  }

  const agreementType = profile.housingSituation?.agreementType || null
  // TODO: remove this after some time when all users have transitioned to the new owning values
  const isCurrentAgreementTypeOwning = agreementType === HousingSituationAgreementTypeEnum.owning
  const defaultValues = {
    bioLived: profile.bio.lived,
    movingReason: profile.movingReason,
    housingSituation: {
      agreementType: !isCurrentAgreementTypeOwning ? agreementType : null,
      landlordCity: profile.housingSituation?.landlordCity,
      landlordName: data?.me?.housingSituation?.landlordName,
      landlordPhoneNumber: data?.me?.housingSituation?.landlordPhoneNumber,
    },
  }

  return (
    <Dialog isOpen={isEditDrawerOpen} onOpenChange={handleOpenChange} trigger={null}>
      <Dialog.Header hasSidePadding />
      <Dialog.Body>
        <HousingSituation
          onComplete={() => {
            refetchProfile()
            handleOpenChange()
          }}
          onCancel={handleOpenChange}
          defaultValues={defaultValues}
          initialContext={{ shouldHideReferenceIfNotSupportedForHousingType: true }}
        />
      </Dialog.Body>
    </Dialog>
  )
}
