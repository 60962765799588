import type { ClusterFeature, PointFeature } from 'supercluster'
import type { Feature, Point } from 'geojson'
import type { MouseEvent } from 'react'
import { useState, useRef } from 'react'
import type { ViewState } from 'react-map-gl'

import { useMapCenter } from '../../../hooks/use-map.web'
import type { HomeLocation } from '../find-home.utils'

import type { PersistedMapState } from './use-persisted-map-state.web'

export type LocationProperties = Pick<
  HomeLocation,
  'rent' | 'tenant_base_fee' | 'currency' | 'average_price_per_night' | 'professional'
> & {
  cluster: boolean
  homeId: HomeLocation['id']
}

export const POPUP_DESKTOP_HEIGHT = 282
export const POPUP_DESKTOP_WIDTH = 280
export const POPUP_MOBILE_HEIGHT = 106

export const createGeoJSONArray = (locations: HomeLocation[]): Feature<Point, LocationProperties>[] => {
  return locations.map(
    ({
      id,
      rent,
      tenant_base_fee,
      longitude,
      latitude,
      currency,
      average_price_per_night,
      professional: isProfessional,
    }) => ({
      type: 'Feature',
      id,
      properties: {
        homeId: id,
        cluster: false,
        rent,
        tenant_base_fee,
        currency,
        average_price_per_night,
        professional: isProfessional,
      },
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(longitude), parseFloat(latitude)],
      },
    }),
  )
}

export const isCluster = (point: LegitimateAny): point is ClusterFeature<LocationProperties> => {
  return point?.properties?.cluster
}

export const isIndividualPoint = (point: LegitimateAny): point is PointFeature<LocationProperties> => {
  return !point?.properties?.cluster
}

export const useHoverState = () => {
  const [isHovering, setIsHovering] = useState(false)

  const hoverProps = {
    onMouseEnter: () => setIsHovering(true),
    onMouseLeave: () => setIsHovering(false),
  }
  return { isHovering, hoverProps }
}

export const useIsDraggingOnClick = () => {
  const [isDragging, setIsDragging] = useState(false)
  const mouseClickPosition = useRef<{ x: number | null; y: number | null }>({ x: null, y: null })

  const dragProps = {
    onMouseDown: (event: MouseEvent) => {
      const { clientX, clientY } = event
      mouseClickPosition.current = { x: clientX, y: clientY }
      setIsDragging(true)
    },
    onMouseUp: (event: MouseEvent) => {
      const { clientX, clientY } = event
      const { x, y } = mouseClickPosition.current

      if (!x || !y) return null

      const threshold = 12
      if (Math.abs(clientX - x) < threshold || Math.abs(clientY - y) < threshold) {
        setIsDragging(false)
      }
      return null
    },
  }

  return { dragProps, isDragging }
}

type CalculateClusterRadiusParams = {
  pointCount: number
}
export const calculateClusterRadius = ({ pointCount }: CalculateClusterRadiusParams) => {
  switch (true) {
    case pointCount >= 500:
      return 60
    case pointCount >= 100:
      return 50
    case pointCount >= 50:
      return 50
    case pointCount >= 25:
      return 30
    default:
      return 15
  }
}

type GetInitialViewportParams = {
  isMobile: boolean
  persistedMapState: PersistedMapState
}
export const useInitialViewport = ({ isMobile, persistedMapState }: GetInitialViewportParams): ViewState => {
  const mapCenter = useMapCenter()
  const { latitude = mapCenter.lat, longitude = mapCenter.lng, zoom } = persistedMapState || {}
  const initialValues = {
    padding: { left: 0, top: 0, right: 0, bottom: 0 },
    pitch: 0,
    bearing: 0,
  }
  if (latitude && longitude && zoom) {
    return {
      latitude,
      longitude,
      zoom,
      ...initialValues,
    }
  }

  return {
    latitude: mapCenter.lat,
    longitude: mapCenter.lng,
    zoom: isMobile ? 4 : 4.0,
    ...initialValues,
  }
}
