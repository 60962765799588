import { graphql } from '@qasa/graphql'

export const INITIATE_CONTRACT_SIGN = graphql(`
  mutation InitiateContractSignMutation(
    $id: ID!
    $landlordInvitationMethod: ScriveInvitationOptionEnum
    $tenantInvitationMethod: ScriveInvitationOptionEnum
    $sendInitialInvitation: Boolean
  ) {
    initiateContractSigning(
      id: $id
      landlordInvitationMethod: $landlordInvitationMethod
      tenantInvitationMethod: $tenantInvitationMethod
      sendInitialInvitation: $sendInitialInvitation
    ) {
      contract {
        id
        signUrl
      }
      errors {
        field
        codes
      }
    }
  }
`)
