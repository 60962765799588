import type { ExternalLinks } from '.'

export const QASA_FRANCE_EXTERNAL_LINKS: ExternalLinks = {
  helpCenterUrl: 'https://support.qasa.fr/hc/',
  termsAndConditionsUrl: 'https://support.qasa.fr/hc/fr/articles/16570382139409',
  requestSupportUrl: 'https://support.qasa.fr/hc/fr/requests/new',
  insuranceClaim: 'https://support.qasa.fr/hc/',
  legalMentions: 'https://support.qasa.fr/hc/fr/articles/16905215985553',
  ownerJourney: 'https://support.qasa.fr/hc/fr/articles/16809787559825',
  partnerShip: 'https://support.qasa.fr/hc/fr/articles/16677025547921',
  howDoesItWork: 'https://support.qasa.fr/hc/fr/articles/16676678252049',
  services: 'https://support.qasa.fr/hc/fr/articles/16324784946705',
  insuranceGuarantee: 'https://support.qasa.fr/hc/fr/articles/16680069333393',
  insuranceTerms: 'https://support.qasa.fr/hc/fr/articles/19075697483537',
  leaseTakeoverForm: 'https://48u1wpbiz2e.typeform.com/to/WmKm9wG6?utm_source=hp',
  recontactForm: 'https://48u1wpbiz2e.typeform.com/to/dfs4q1i9?utm_source=hp',
  pricing: 'https://support.qasa.fr/hc/en-us/articles/16780149954449',
  infoEmail: 'info@qasa.fr',
}
