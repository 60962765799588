import { useTranslation } from 'react-i18next'

import { ProfilePreferredAmenitiesEnum } from '../../profile/profile.types'
import { Form } from '../components/form'
import { StepNavigation } from '../components/step-navigation'
import { WizardStepWrapper } from '../components/step-wrapper'
import { FormCheckboxGroup } from '../fields/form-checkbox-group'
import { useWizardRouterContext } from '../wizard-router/wizard-router'

export function PreferredAmenitiesStep() {
  const { goNext } = useWizardRouterContext()
  const { t } = useTranslation('create_tenant_listing')

  const preferredAmenitiesOptions = Object.values(ProfilePreferredAmenitiesEnum).map((value) => {
    return { value, label: t(`fields.preferences.checkbox_options.${value}`) }
  })
  return (
    <WizardStepWrapper>
      <Form dataKey="preferences" onSubmit={goNext}>
        {({ control }) => (
          <>
            <FormCheckboxGroup
              control={control}
              options={preferredAmenitiesOptions}
              label={t('fields.preferences.label')}
              name={'preferredAmenities'}
              isLabelVisuallyHidden
            />
            <StepNavigation />
          </>
        )}
      </Form>
    </WizardStepWrapper>
  )
}
