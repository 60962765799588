import { Heading, Paragraph, Stack } from '@qasa/qds-ui'

import { EmptyField } from './view-profile-sections/empty-field'

const ItemTitle = Heading

type ProfileSectionItemProps = {
  title: string
  text?: string | null
}
export function ProfileSectionItem({ title, text }: ProfileSectionItemProps) {
  return (
    <Stack gap="1x">
      <ItemTitle as="h3" size="3xs">
        {title}
      </ItemTitle>
      {text ? <Paragraph>{text}</Paragraph> : <EmptyField />}
    </Stack>
  )
}
