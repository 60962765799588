import { graphql } from '@qasa/graphql'

export const PROFILE = graphql(`
  query ProfileQuery($uid: ID!) {
    user(uid: $uid) {
      uid
      professional
      proPilot
      proAgent
      landlord
      tenant
      createdAt
      seenAt
      occupations {
        ...OccupationFragment
      }
      landlordApplicationResponseRate
      housingSituation {
        id
        agreementType
        landlordCity
        hasReference
      }
      movingReason
      tenantAd {
        id
        status
        publishedUntil
        searchPreference {
          id
          searchAreas {
            city
            name
            geojson
            identifier
          }
          balcony
          bathtub
          bikeRoom
          corporateHome
          countryCode
          currency
          dishWasher
          furniture
          householdSize
          inhomeSauna
          storage
          studentHome
          pets
          recycling
          seniorHome
          shared
          smoker
          washingMachine
          tumbleDryer
          wheelchairAccessible
          parking
          minRoomCount
          minSquareMeters
          homeType
          maxMonthlyCost
          maxMonthlyCostCents
          currency
          startAsap
          startOptimal
          endUfn
          endOptimal
        }
      }
      tenantProfileCompleteness {
        completenessScore
        tenantProfileFields {
          bioLived {
            completed
          }
          bio {
            completed
          }
          firstName {
            completed
          }
          idNumberConfirmed {
            completed
          }
          occupations {
            completed
          }
          matchingProfile {
            completed
          }
          movingReason {
            completed
          }
          profilePicture {
            completed
          }
          bioPets {
            completed
          }
          bioTitle {
            completed
          }
          currentAgreementType {
            completed
          }
        }
      }
      landlordApplicationResponseTimeHours
      certificate {
        id
        status
        expiresAt
      }
      profilePicture {
        id
        url
      }
      firstName
      companyName
      bio {
        title
        intro
        lived
        pets
      }
      currentIdentification {
        identityConfirmed
        identityConfirmedAt
      }
      age
      showAge
      publishedHomes {
        id
        firsthand
        rent
        tenantBaseFee
        title
        currency
        favoriteMarkedByUser
        landlord {
          uid
          companyName
          premium
          professional
          profilePicture {
            url
          }
          proPilot
        }
        location {
          id
          route
          locality
          latitude
          longitude
          sublocality
          countryCode
          streetNumber
        }
        displayStreetNumber
        floor
        buildingFloors
        traits {
          type
        }
        roomCount
        shared
        squareMeters
        type
        duration {
          createdAt
          endOptimal
          endUfn
          id
          startAsap
          startOptimal
          updatedAt
        }
        user {
          uid
          proAgent
          firstName
          profilePicture {
            url
          }
        }
        instantSignSetting {
          id
          enabled
        }
        uploads {
          id
          url
          type
          title
          metadata {
            primary
            order
          }
        }
        numberOfHomes
        minRent
        maxRent
        minRoomCount
        maxRoomCount
        minSquareMeters
        maxSquareMeters
        averagePricePerNight
        minimumPricePerNight
        maximumPricePerNight
        studentHome
        seniorHome
        rentalType
        tenantCount
        bedCount
        bedroomCount
        corporateHome
        links {
          locale
          url
        }
      }
      homeApplicationsToMyHomes {
        id
        status
        tenant {
          uid
          firstName
          showAge
          age
          companyName
          professional
          landlord
          seenAt
          createdAt
          profilePicture {
            id
            url
          }
          certificate {
            id
            status
            expiresAt
          }
          currentIdentification {
            identityConfirmed
            identityConfirmedAt
          }
        }
        home {
          id
          rent
          roomCount
          squareMeters
          status
          location {
            route
            locality
            latitude
            longitude
            postalCode
            streetNumber
            googleMapsPlaceId
            countryCode
            id
          }
          title
          uploads {
            url
            id
            metadata {
              primary
              order
            }
          }
        }
        origin
        message
        matchInterest
        createdAt
        startOptimal
        endOptimal
        endUfn
        conversation {
          id
        }
      }
      applicationStats {
        uid
        locales
        priceMin
        priceMax
        roomMin
        roomMax
      }
    }
  }
`)
