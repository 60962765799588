import { Stack } from '@qasa/ui'

import { useAppTranslation } from '../../../../../contexts/i18next'
import { useFindHomeFiltersContext } from '../../../contexts'
import { getFilterableHomeTypes } from '../../utils/home-type-mapping'
import { FilterCheckboxGroup } from '../../components/filter-checkbox-group'

export function HomeTypeFilterSection() {
  const { t } = useAppTranslation('filter_fields')
  const { filterValues, updateFilterValues, rentalType } = useFindHomeFiltersContext()
  const filterableHomeTypes = getFilterableHomeTypes({ rentalType })

  const homeTypeOptions = filterableHomeTypes.map((homeType) => ({
    value: homeType,
    label: t(`common_home:home_type.${homeType}`),
  }))

  const sharedHomeOptions = [
    {
      value: 'privateHome',
      label: t('shared_home.private.label'),
      helperText: t('shared_home.private.helper_text'),
    },
    {
      value: 'sharedHome',
      label: t('shared_home.shared.label'),
      helperText: t('shared_home.shared.helper_text'),
    },
  ]

  return (
    <Stack gap="8x">
      <FilterCheckboxGroup
        label={t('shared_home.label')}
        values={filterValues.sharedHome}
        onChange={(newValue) => updateFilterValues({ sharedHome: newValue })}
        options={sharedHomeOptions}
      />
      <FilterCheckboxGroup
        label={t('home_types.label')}
        values={filterValues.homeTypes}
        onChange={(newValue) => updateFilterValues({ homeTypes: newValue })}
        options={homeTypeOptions}
      />
    </Stack>
  )
}
