import type { UseComboboxProps } from 'downshift'
import { useCombobox, useMultipleSelection } from 'downshift'

import { isStringEmpty } from '../../../utils/string'

import type { AutocompleteProps } from './autocomplete'

type UseAutocompleteParams<TOption> = Pick<
  AutocompleteProps<TOption>,
  'options' | 'optionToLabel' | 'inputValue' | 'onInputValueChange' | 'value' | 'onChange'
>

export const useAutocomplete = <TOption>({
  options,
  optionToLabel,
  inputValue,
  onInputValueChange,
  value,
  onChange,
}: UseAutocompleteParams<TOption>) => {
  const {
    addSelectedItem,
    getSelectedItemProps,
    getDropdownProps,
    removeSelectedItem,
    reset,
    selectedItems: selectedOptions,
  } = useMultipleSelection<TOption>({
    selectedItems: value,
    onSelectedItemsChange: ({ selectedItems }) => onChange(selectedItems ?? []),
  })

  const handleStateChange: UseComboboxProps<TOption>['onStateChange'] = ({
    inputValue,
    type,
    selectedItem,
  }) => {
    const newInputValue = inputValue ?? ''
    switch (type) {
      case useCombobox.stateChangeTypes.InputChange:
        onInputValueChange(newInputValue)
        if (isStringEmpty(newInputValue)) {
          closeMenu()
        }
        break
      case useCombobox.stateChangeTypes.InputKeyDownEnter:
      case useCombobox.stateChangeTypes.ItemClick:
      case useCombobox.stateChangeTypes.InputBlur:
        if (selectedItem) {
          onInputValueChange('')
          addSelectedItem(selectedItem)
          selectItem(null!)
        }
        break
      default:
        break
    }
  }

  const {
    isOpen,
    selectItem,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
    openMenu,
  } = useCombobox({
    inputValue,
    items: options,
    itemToString: optionToLabel,
    onStateChange: handleStateChange,
    defaultHighlightedIndex: 0,
    onInputValueChange: ({ inputValue }) => {
      onInputValueChange(inputValue ?? '')
    },
  })

  return {
    selectedOptions,
    isOpen,
    highlightedIndex,
    openMenu,
    closeMenu,
    removeSelectedItem,
    reset,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    getSelectedItemProps,
    getDropdownProps,
  }
}
