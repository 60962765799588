/* eslint-disable complexity */
import camelCase from 'lodash/camelCase'
import type { HomeRentalTypeEnum, HomeTypeEnum, SavedSearchesQuery } from '@qasa/graphql'
import { HomeTraitEnum } from '@qasa/graphql'

import { omit } from '../../../../utils/object'
import type { FindHomeFilterValues } from '../../contexts'
import { getFilterableHomeTypes } from '../../filters/utils/home-type-mapping'
import { getIsFilterActive } from '../../filters/utils/filters.utils'
import { formatNumber } from '../../../../utils/number'
import { useAppTranslation } from '../../../../contexts/i18next'
import { formatDateRange } from '../../../../utils/date'
import { useRegionConfig } from '../../../../configs/use-region-config'

type ParseSavedSearchParams = {
  searchParams: SavedSearchesQuery['savedSearch'][number]['searchParams']
}

// These are using snake case because that's what the backend sends.
// Maybe we should use snake case for this in FE as well?
const POPULAR_AMENITIES = [
  'pool',
  'washing_machine',
  'internet',
  'dish_washer',
  'parking',
  'balcony',
  'kitchen_appliances',
  'inhome_sauna',
]
const LOCATION_PERKS = [
  'near_beach',
  'ski_in_out',
  'near_fishing',
  'near_golf',
  'near_hiking_routes',
  'archipelago',
  'remote',
  'near_restaurants',
]
const RULES_ACCESSIBILITY = ['pets', 'smoker', 'wheelchair_accessible']

type TraitsGroup = {
  locationPerks: string[]
  popularTraits: string[]
  equipmentTraits: string[]
}

const parseTraits = (traits: HomeTraitEnum[]) => {
  const traitsResult: TraitsGroup = {
    locationPerks: [],
    popularTraits: [],
    equipmentTraits: [],
  }
  traits.forEach((trait) => {
    if (LOCATION_PERKS.includes(trait)) {
      traitsResult.locationPerks.push(camelCase(trait))
    } else if (POPULAR_AMENITIES.includes(trait)) {
      traitsResult.popularTraits.push(camelCase(trait))
    } else if (!RULES_ACCESSIBILITY.includes(trait)) {
      traitsResult.equipmentTraits.push(camelCase(trait))
    }
  })
  return traitsResult
}

type ParseSavedSearch = ParseSavedSearchParams & {
  rentalType: HomeRentalTypeEnum
}

export const parseSavedSearch = ({
  searchParams,
  rentalType,
}: ParseSavedSearch): Partial<FindHomeFilterValues> => {
  const contractTypes = []

  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    searchAreas,
    corporateHome,
    studentHome,
    seniorHome,
    firsthand,
    homeType,
    wheelchairAccessible,
    shared,
    furniture,
    pets,
    smoker,
    minMonthlyCost,
    maxMonthlyCost,
    minRoomCount,
    maxRoomCount,
    maxSquareMeters,
    minSquareMeters,
    moveInEarliest,
    minRentalLength,
    traits,
    checkIn,
    checkOut,
    minBedCount,
    minBedroomCount,
    minToiletCount,
    householdSize,
    minPricePerNight,
    maxPricePerNight,
    hasKitchen,
  } = searchParams
  /* eslint-enable @typescript-eslint/naming-convention */

  // Contract types
  if (firsthand) contractTypes.push('firstHand')
  if (studentHome) contractTypes.push('studentHome')
  if (seniorHome) contractTypes.push('seniorHome')
  if (corporateHome) contractTypes.push('corporateHome')

  // Shared
  const sharedHome = shared === null ? [] : shared ? ['sharedHome'] : ['privateHome']

  // Furnitue
  const furnished = furniture === null ? [] : furniture ? ['furnished'] : ['unfurnished']

  // Rules
  const rules = [
    ...(pets || traits?.includes(HomeTraitEnum.pets) ? ['pets'] : []),
    ...(smoker || traits?.includes(HomeTraitEnum.smoker) ? ['smoker'] : []),
  ]

  // Home types
  const homeTypes = parseHomeTypes({
    selectedHomeTypes: homeType,
    rentalType,
  })

  // Traits
  const { locationPerks, popularTraits, equipmentTraits } = parseTraits(traits ?? [])

  return {
    searchAreas: searchAreas || [],
    contractTypes,
    homeTypes,
    wheelchairAccessible:
      wheelchairAccessible || traits?.includes(HomeTraitEnum.wheelchair_accessible)
        ? ['wheelchairAccessible']
        : [],
    sharedHome,
    furnished,
    rules,
    minMonthlyCost,
    maxMonthlyCost,
    minRoomCount,
    maxRoomCount,
    minSquareMeters,
    maxSquareMeters,
    earliestMoveIn: moveInEarliest,
    minRentalLength,
    checkInDate: checkIn,
    checkOutDate: checkOut,
    householdSize,
    bedCount: minBedCount,
    bedroomCount: minBedroomCount,
    toiletCount: minToiletCount,

    includedRooms: hasKitchen ? ['kitchen'] : [],
    locationPerks,
    popularTraits,
    equipmentTraits,
    minNightlyCost: minPricePerNight,
    maxNightlyCost: maxPricePerNight,
  }
}

type ParseHomeTypes = {
  selectedHomeTypes: HomeTypeEnum[] | null
  rentalType: HomeRentalTypeEnum
}
const parseHomeTypes = ({ rentalType, selectedHomeTypes }: ParseHomeTypes) => {
  const acceptableHomeTypes = getFilterableHomeTypes({ rentalType })
  const filteredHomeTypes = selectedHomeTypes
    ? selectedHomeTypes.filter((homeType) => acceptableHomeTypes.includes(homeType))
    : []
  return filteredHomeTypes
}

// Not entirely type safe since technically the object isn't guaranteed
// to not have other values in it
const getObjectKeys = <TObj extends Record<string, unknown>>(obj: TObj) => Object.keys(obj) as (keyof TObj)[]

type UseFormattedSavedSearchParams = {
  savedSearch: SavedSearchesQuery['savedSearch'][number]
  rentalType: HomeRentalTypeEnum
}
export const useFormattedSavedSearch = ({ savedSearch, rentalType }: UseFormattedSavedSearchParams) => {
  const parsedSavedSearch = parseSavedSearch({ searchParams: savedSearch.searchParams, rentalType })
  return useFormattedFilterValues({ filterValues: parsedSavedSearch, rentalType })
}

type UseFormattedFilterValuesParams = {
  filterValues: Partial<FindHomeFilterValues>
  rentalType: HomeRentalTypeEnum
}
export const useFormattedFilterValues = ({ filterValues, rentalType }: UseFormattedFilterValuesParams) => {
  const { t } = useAppTranslation('saved_searches')
  const currency = useRegionConfig().currency

  const {
    searchAreas,
    checkInDate,
    checkOutDate,
    minRoomCount,
    maxRoomCount,
    minSquareMeters,
    maxSquareMeters,
    minMonthlyCost,
    maxMonthlyCost,
  } = filterValues

  let numberOfFilters = 0
  const savedSearchFilters = omit(
    filterValues,
    'searchAreas',
    'page',
    'order',
    'orderBy',
    'uids',
    'rentalType',
    'safeRental',
  )

  getObjectKeys(savedSearchFilters).forEach((filterKey) => {
    if (getIsFilterActive({ filterKey, currentValue: filterValues[filterKey] })) {
      numberOfFilters++
    }
  })

  let formattedAreas: string | null = ''
  formattedAreas = searchAreas?.[0]?.name || null

  if (!formattedAreas) {
    formattedAreas = t('no_areas')
  }

  // Some areas are missing their actual name due to legacy solutions.
  // It should get fixed as time goes and more names are saved, but in the meantime
  // they have a hard coded name so we can gracefully handle it in the frontend
  if (formattedAreas === 'unnamed') {
    t('missing_area_name')
  }

  const searchAreaCount = searchAreas?.length || 0

  if (searchAreaCount === 0) {
    formattedAreas = t('no_areas')
  } else if (searchAreaCount >= 2) {
    formattedAreas = t('areas_with_count', { areas: formattedAreas, count: searchAreaCount - 1 })
  }

  const formattedMinMaxRooms =
    minRoomCount && maxRoomCount
      ? minRoomCount === maxRoomCount
        ? t('commons:roomWithCount', { count: minRoomCount, ns: 'filter_sections' })
        : t('home_size.min_max_rooms', { minRoomCount, maxRoomCount, ns: 'filter_sections' })
      : null

  const formattedMinRooms =
    minRoomCount && t('home_size.min_rooms', { count: minRoomCount, ns: 'filter_sections' })
  const formattedMaxRooms =
    maxRoomCount === 1
      ? t('commons:roomWithCount', { count: 1, ns: 'filter_sections' })
      : maxRoomCount
        ? t('home_size.max_rooms', { count: maxRoomCount, ns: 'filter_sections' })
        : null

  const formattedRooms = formattedMinMaxRooms ?? formattedMinRooms ?? formattedMaxRooms ?? null

  const formattedSquareMeters =
    minSquareMeters && maxSquareMeters
      ? t('home_size.min_max_sqm', { minSquareMeters, maxSquareMeters, ns: 'filter_sections' })
      : minSquareMeters
        ? t('home_size.min_sqm', { size: minSquareMeters, ns: 'filter_sections' })
        : maxSquareMeters
          ? t('home_size.max_sqm', { size: maxSquareMeters, ns: 'filter_sections' })
          : null

  const selectedDateRange = checkInDate && checkOutDate && formatDateRange(checkInDate, checkOutDate)
  const formattedDateRange = selectedDateRange ?? t('dates.no_selected_dates', { ns: 'filter_sections' })

  const formattedRentSpan =
    minMonthlyCost &&
    maxMonthlyCost &&
    t('rent.range', {
      min: formatNumber({ amount: minMonthlyCost, currency }),
      max: formatNumber({ amount: maxMonthlyCost, currency }),
      ns: 'filter_sections',
    })
  const formattedRentMin =
    minMonthlyCost &&
    t('rent.min', {
      min: formatNumber({ amount: minMonthlyCost, currency }),
      ns: 'filter_sections',
    })
  const formattedRentMax =
    maxMonthlyCost &&
    t('rent.max', {
      max: formatNumber({ amount: maxMonthlyCost, currency }),
      ns: 'filter_sections',
    })
  const formattedRent = formattedRentSpan ?? formattedRentMax ?? formattedRentMin ?? null

  const formattedSize = formattedRooms ?? formattedSquareMeters

  const formattedFilterCount = numberOfFilters
    ? t('filters_count', { count: numberOfFilters })
    : t('all_homes')
  // Only show filter count if no other filters are set
  const filterCount = formattedSize || formattedRent ? null : formattedFilterCount

  const searchSummary: Record<HomeRentalTypeEnum, string[]> = {
    vacation: [formattedAreas, formattedDateRange].filter((item) => Boolean(item)) as string[],
    long_term: [formattedAreas, formattedSize, formattedRent, filterCount].filter((item) =>
      Boolean(item),
    ) as string[],
  }

  return searchSummary[rentalType]
}
