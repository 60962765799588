import first from 'lodash/first'
import type { ReactNode, Reducer } from 'react'
import { useEffect, useReducer, createContext, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { reportError, useToastContext } from '@qasa/app'
import { useReferredAdOrigin } from '@qasa/app/src/hooks/use-referred-ad-origin'
import { BooleanParam, useQueryParams } from 'use-query-params'
import type { HomeStatusEnum, ListingQuery } from '@qasa/graphql'
import { HomeRentalTypeEnum } from '@qasa/graphql'

import { useAuthContext } from '../../context/auth-context'
import { currentBrand } from '../../config'
import { BRAND_CONFIG } from '../../config/brand-configurations'

import type {
  ListingStoreErrors,
  ListingStoreValues,
  UpdateFieldValueAction,
  SetErrorsAction,
  ListingStore,
  ListingStoreDirtyFields,
} from './edit-listing.types'
import { FieldEnum } from './edit-listing.types'
import {
  getCreateHomeInput,
  getRelevantCreateListingFields,
  useDispatchPreloadedValueChanges,
  useValidateAndMutationOnChange,
} from './listing.utils'
import { useCreateHome } from './use-create-home'
import { useCreateListingCookie } from './use-create-listing-cookie'

type ListingContextValue = {
  store: ListingStore
  dispatch: ({ action }: { action: UpdateFieldValueAction }) => void
  createHome: () => void
  getIsValid: ({ fields }: { fields: FieldEnum[] }) => boolean
  getErrorMessage: ({ field }: { field: FieldEnum }) => string | null
  isPublishingDisabled: boolean
  isPendingShortcut: boolean
  homeStatus?: HomeStatusEnum
  isInsuranceAndGuaranteeOptional: boolean
  tenantFees?: NonNullable<ListingQuery['home']>['tenantFees']
}
const ListingContext = createContext<ListingContextValue | undefined>(undefined)

const DEFAULT_STORE: ListingStore = {
  values: {
    isProfessional: null,
    isPremium: null,
    companyName: null,
    orgNumber: null,
    type: null,
    tenureType: null,
    rent: 0,
    waterFeePerTenant: 0,
    recommendedRentNew: null,
    firsthand: false,
    studentHome: false,
    seniorHome: false,
    corporateHome: false,
    toiletCount: null,
    bedroomCount: null,
    bedCount: null,
    hasKitchen: true,
    roomCount: null,
    minRoomCount: null,
    maxRoomCount: null,
    floor: null,
    buildingFloors: null,
    rentalType: HomeRentalTypeEnum.long_term,
    responsibleForCleaning: null,
    cleaningFee: 0,
    cancellationPolicyName: null,
    minNights: 1,
    isRentOnlyWeekly: false,
    checkInDays: [],
    availableDates: [],
    minSquareMeters: null,
    maxSquareMeters: null,
    squareMeters: null,
    tenantCount: null,
    shared: false,
    traits: null,
    title: null,
    description: null,
    houseRules: null,
    numberOfHomes: null,
    electricityFee: null,
    qasaGuarantee: true,
    qasaGuaranteeCost: 0,
    insurance: null,
    insuranceCost: 0,
    buildYear: null,
    energyClass: null,
    housingAssociation: null,
    kitchenRenovationYear: null,
    bathroomRenovationYear: null,
    duration: {
      startOptimal: null,
      startAsap: true,
      endOptimal: null,
      endUfn: true,
      // NOTE: BE only accepts true/false value
      possibilityOfExtension: false,
    },
    ownHome: null,
    formattedAddress: null,
    position: {
      latitude: null,
      longitude: null,
    },
    locality: '',
    postalCode: '',
    route: '',
    streetNumber: '',
    apartmentNumber: '',
    // NOTE: For Qasa.com we don't have a default country and countryCode
    countryCode: currentBrand === 'dotcom' ? '' : BRAND_CONFIG.countryCode.toUpperCase(),
    country: currentBrand === 'dotcom' ? '' : BRAND_CONFIG.country,
    uploads: [],
    isExternal: null,
    externalApplicationUrl: '',
    externalEmail: '',
    externalInfoPage: '',
    pricingModel: {
      insuranceCostFactor: 0,
      qasaGuaranteeCostFactor: 0,
    },
    origin: null,
    //NOTE: Market is set on home-creation
    market: null,
  },
  errors: {},
  dirtyFields: {},
}

const getInitialState = (state: ListingStore) => {
  // This sets dirtyFields to false on initialization
  const dirtyFields = Object.keys(state.values).reduce(
    (accumulator, currentKey) => ({
      ...accumulator,
      [currentKey]: false,
    }),
    {},
  )

  return {
    ...state,
    dirtyFields,
  }
}

const reducer: Reducer<ListingStore, UpdateFieldValueAction | SetErrorsAction> = (
  state: ListingStore,
  action: UpdateFieldValueAction | SetErrorsAction,
) => {
  switch (action.type) {
    case FieldEnum.IsProfessional:
    case FieldEnum.CompanyName:
    case FieldEnum.OrganizationNumber:
    case FieldEnum.Type:
    case FieldEnum.TenureType:
    case FieldEnum.Firsthand:
    case FieldEnum.Senior:
    case FieldEnum.Student:
    case FieldEnum.Shared:
    case FieldEnum.Corporate:
    case FieldEnum.Traits:
    case FieldEnum.Guarantee:
    case FieldEnum.Insurance:
    case FieldEnum.Rent:
    case FieldEnum.RoomCount:
    case FieldEnum.MinRoomCount:
    case FieldEnum.MaxRoomCount:
    case FieldEnum.SquareMeters:
    case FieldEnum.MinSquareMeters:
    case FieldEnum.MaxSquareMeters:
    case FieldEnum.TenantCount:
    case FieldEnum.BedCount:
    case FieldEnum.BedroomCount:
    case FieldEnum.ToiletCount:
    case FieldEnum.NumberOfHomes:
    case FieldEnum.CountryCode:
    case FieldEnum.Country:
    case FieldEnum.FormattedAddress:
    case FieldEnum.Locality:
    case FieldEnum.PostalCode:
    case FieldEnum.Route:
    case FieldEnum.StreetNumber:
    case FieldEnum.ApartmentNumber:
    case FieldEnum.Floor:
    case FieldEnum.BuildingFloors:
    case FieldEnum.HousingAssociation:
    case FieldEnum.EnergyClass:
    case FieldEnum.KitchenRenovation:
    case FieldEnum.HasKitchen:
    case FieldEnum.BathroomRenovation:
    case FieldEnum.BuildYear:
    case FieldEnum.Title:
    case FieldEnum.Description:
    case FieldEnum.HouseRules:
    case FieldEnum.RentalType:
    case FieldEnum.ResponsibleForCleaning:
    case FieldEnum.CleaningFee:
    case FieldEnum.CancellationPolicyName:
    case FieldEnum.MinNights:
    case FieldEnum.IsRentOnlyWeekly:
    case FieldEnum.CheckInDays:
    case FieldEnum.AvailableDates:
    case FieldEnum.IsExternal:
    case FieldEnum.ExternalApplicationUrl:
    case FieldEnum.ExternalInfoPage:
    case FieldEnum.ExternalEmail:
    case FieldEnum.WaterFeePerTenant:
    case FieldEnum.OwnHome:
    case FieldEnum.ElectricityFee: {
      return {
        ...state,
        values: {
          ...state.values,
          [action.type]: action.payload,
        },
        dirtyFields: {
          ...state.dirtyFields,
          [action.type]: true,
        },
      }
    }
    case FieldEnum.Duration:
    case FieldEnum.Position: {
      return {
        ...state,
        values: {
          ...state.values,
          [action.type]: {
            ...state.values[action.type],
            ...action.payload,
          },
        },
        dirtyFields: {
          ...state.dirtyFields,
          [action.type]: true,
        },
      }
    }
    case FieldEnum.Uploads: {
      return {
        ...state,
        values: {
          ...state.values,
          [action.type]: action.payload,
        },
      }
    }

    case FieldEnum.InsuranceCost:
    case FieldEnum.GuaranteeCost:
    case FieldEnum.RecommendedRentNew: {
      return {
        ...state,
        values: {
          ...state.values,
          [action.type]: action.payload,
        },
      }
    }
    case 'SET_ERRORS':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.errors,
        },
      }
    default:
      return state
  }
}

type ListingProviderProps = {
  preloadedValues?: Partial<ListingStoreValues>
  isFromFindTenant?: boolean
  isPendingShortcut?: boolean
  homeId?: string
  homeStatus?: HomeStatusEnum
  tenantFees?: NonNullable<ListingQuery['home']>['tenantFees']
  children: ReactNode
}
function ListingProvider({
  preloadedValues,
  isFromFindTenant,
  isPendingShortcut = false,
  homeStatus,
  homeId,
  children,
  tenantFees,
}: ListingProviderProps) {
  const { addToast } = useToastContext()
  const { authBody } = useAuthContext()
  const { t } = useTranslation('listing')
  const isEdit = Boolean(homeId)
  const history = useHistory()
  const { adOrigin } = useReferredAdOrigin()
  // TODO: Let's remember to remove this when tori finally removes the housing section -- Katri 02/24
  const [{ tori: isFromTori }] = useQueryParams({
    tori: BooleanParam,
  })
  const origin = adOrigin ?? (isFromTori ? 'tori' : null)

  const [store, dispatch] = useReducer(
    reducer,
    {
      ...DEFAULT_STORE,
      values: {
        ...DEFAULT_STORE.values,
        ...preloadedValues,
        isProfessional: authBody?.professional ?? preloadedValues?.isProfessional ?? false,
        isPremium: authBody?.premium ?? preloadedValues?.isPremium ?? null,
        companyName: authBody?.companyName ?? preloadedValues?.companyName ?? null,
        orgNumber: authBody?.private.orgNumber ?? preloadedValues?.orgNumber ?? null,
        origin,
      },
    },
    getInitialState,
  )
  const { createHome } = useCreateHome()

  useDispatchPreloadedValueChanges({ preloadedValues, dispatch })
  useCreateListingCookie({ storeValues: store.values, shouldSaveFormValues: !isEdit })
  const { isUpToDate } = useValidateAndMutationOnChange({ store, dispatch, homeId })

  useEffect(() => {
    const callback = (event: BeforeUnloadEvent) => {
      if (!isUpToDate) {
        event.preventDefault()
        event.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', callback)

    return () => window.removeEventListener('beforeunload', callback)
  }, [isUpToDate])

  const {
    values: { isProfessional, companyName, orgNumber, rent, landlordServices, rentalType },
    errors,
  } = store

  const getIsValid = ({ fields }: { fields: FieldEnum[] }) => {
    return fields
      .map((field) => {
        const fieldErrors = errors[field as keyof ListingStoreErrors]
        return !fieldErrors || fieldErrors.length === 0
      })
      .every(Boolean)
  }

  const handleUpdateFieldValue = ({ action }: { action: UpdateFieldValueAction }) => {
    dispatch(action)
  }

  const isFlowValid = getIsValid({ fields: getRelevantCreateListingFields({ values: store.values }) })

  const getErrorMessage = ({ field }: { field: FieldEnum }) => {
    if (!store.dirtyFields[field as keyof ListingStoreDirtyFields]) {
      return null
    }
    return first(store.errors[field as keyof ListingStoreErrors]) ?? null
  }

  const isLongtermHome = rentalType === HomeRentalTypeEnum.long_term

  const listingContextValue = {
    store,
    getIsValid,
    getErrorMessage,
    dispatch: handleUpdateFieldValue,
    createHome: () => {
      if (!isFlowValid) {
        addToast({ message: t('commons:unexpected_error') })
        reportError('unexpected validation error when creating listing', {
          input: getCreateHomeInput({ storeValues: store.values }),
        })
        history.push('/create-listing/')
      } else {
        const input = getCreateHomeInput({ storeValues: store.values })
        createHome({
          input,
          user: { isProfessional: isProfessional || false, companyName, orgNumber },
          isFromFindTenant,
        })
      }
    },
    isPublishingDisabled: isLongtermHome && (!rent || rent === 0),
    homeStatus,
    isPendingShortcut,
    tenantFees,
    isInsuranceAndGuaranteeOptional:
      landlordServices?.insurance.availability === 'optional' &&
      landlordServices.qasaGuarantee.availability === 'optional',
  }

  return <ListingContext.Provider value={listingContextValue}>{children}</ListingContext.Provider>
}

const useListingContext = () => {
  const context = useContext(ListingContext)
  if (context === undefined) {
    throw new Error('useListingContext must be used within a ListingProvider')
  }
  return context
}

export { useListingContext, ListingProvider }
