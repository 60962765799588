import { styled } from '@qasa/ui/web'

type GetOffsetArgs = {
  currentIndex: number
  count: number
}

const getOffset = ({ currentIndex, count }: GetOffsetArgs) => {
  if (currentIndex < 3) {
    return 0
  }
  return Math.min(currentIndex - 2, count - 5) * 16
}

const ProgressWrapper = styled('div')({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const ProgressContainer = styled('div')({
  paddingBottom: 16,
  maxWidth: 76,
  display: 'flex',
  overflow: 'hidden',
})

const ProgressDots = styled('div')<{ horizontalOffset: number }>(({ horizontalOffset }) => ({
  display: 'flex',
  justifyContent: 'center',
  transform: `translateX(-${horizontalOffset}px)`,
  transition: 'transform 0.15s ease-out',
}))

const ProgressDot = styled('span')<{ isActive: boolean }>(({ isActive, theme }) => ({
  background: theme.colors.bg.default,
  width: theme.sizes['2x'],
  height: theme.sizes['2x'],
  borderRadius: theme.radii.full,
  marginInline: theme.spacing['1x'],
  boxShadow: '0 0 4px rgba(0,0,0,0.2)',
  opacity: isActive ? 1 : 0.6,
  transform: `scale(${isActive ? 1 : 0.75})`,
}))

type ProgressIndicatorProps = {
  count: number
  currentIndex: number
}

export function ProgressIndicator({ currentIndex, count }: ProgressIndicatorProps) {
  if (count < 2) {
    return null
  }

  return (
    <ProgressWrapper>
      <ProgressContainer>
        <ProgressDots horizontalOffset={getOffset({ currentIndex, count })}>
          {[...Array(count)].map((_, index) => (
            <ProgressDot key={index} isActive={currentIndex === index} />
          ))}
        </ProgressDots>
      </ProgressContainer>
    </ProgressWrapper>
  )
}
