import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Heading, Spacer, Stack } from '@qasa/qds-ui'

import { Loading } from '../../ui-shared/_core/network-statuses'
import { LinkButton } from '../../ui-shared/_core/link-button'

type HomesListProps = {
  title: string
  totalCount: number
  children: ReactNode
}

export function HomesList({ title, totalCount, children }: HomesListProps) {
  const { t } = useTranslation('listing')

  return (
    <Stack gap="6x">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Heading as="h2" size="sm">
          {title}
        </Heading>
        {totalCount > 2 && (
          <LinkButton variant="tertiary" to="/my-listings/" size="xs">
            {t('templates.show_all_label', { count: totalCount })}
          </LinkButton>
        )}
      </Stack>
      <Stack gap={'4x'} divider={<Divider />}>
        {children}
      </Stack>
    </Stack>
  )
}

type TemplatesListLayoutProps = {
  isLoading: boolean
  children: ReactNode
}

export function TemplatesListLayout({ isLoading, children }: TemplatesListLayoutProps) {
  if (isLoading) {
    return (
      <>
        <Spacer size={'24x'} />
        <Loading />
        <Spacer size={'24x'} />
      </>
    )
  }
  return <Stack gap={'12x'}>{children}</Stack>
}
