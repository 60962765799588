import type { Location } from '@qasa/graphql'

import { getCurrentLocale } from '../translations/language-loader/language-utils'

type LocationType = Partial<Pick<Location, 'route' | 'streetNumber' | 'locality'>>

function getStreetFirstAddress(location: LocationType) {
  const { route, streetNumber, locality } = location
  let address = `${route}`
  if (streetNumber) {
    address += ` ${streetNumber}`
  }
  if (locality) {
    address += `, ${locality}`
  }
  return address
}

function getNumberFirstAddress(location: LocationType) {
  const { route, streetNumber, locality } = location
  let address = `${route}`
  if (streetNumber) {
    address = streetNumber + ` ${address}`
  }
  if (locality) {
    address += `, ${locality}`
  }
  return address
}

export function getFormattedAddress(location?: LocationType | null) {
  if (!location) return ''
  const currentLanguage = getCurrentLocale()
  switch (currentLanguage) {
    case 'fr':
      return getNumberFirstAddress(location)
    default:
      return getStreetFirstAddress(location)
  }
}
