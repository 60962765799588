import type { SVGProps } from 'react'

const VARIANT_COLORS = {
  // Background / foreground
  white: ['#FFFFFF', '2A1E1A'],
  default: ['#202020', '#FFFFFF'],
}

type ColorVariant = keyof typeof VARIANT_COLORS

type OikotieLogoProps = SVGProps<SVGSVGElement> & {
  color?: ColorVariant
}

export function OikotieLogo({ color = 'default', ...restProps }: OikotieLogoProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="Layer_1"
      x={0}
      y={0}
      viewBox="0 0 600 157.7"
      {...restProps}
    >
      <path fill={VARIANT_COLORS[color][0]} d="M521.2.1H0v157.6h521.2L600 78.9z" />
      <path
        d="M145.8 43.8h15.3V114h-15.3zm70.8 70.3-24.7-24.6-.1.2-.2-.2V114h-15.3V43.8h15.3v24.5l24.6-24.6h21.5l-35 35 35.5 35.4zm151.5-55h-20.2V114h-15.3V59.1H312V43.8h56.1zm15.3-15.3h15.3V114h-15.3zm78.8 15.3h-32.9v11.7h32.9V86h-32.9v12.8h32.9V114h-48.3V43.8h48.3zM95.9 114.2c-19.5 0-35.3-15.8-35.3-35.3 0-19.5 15.8-35.3 35.3-35.3 19.5 0 35.3 15.8 35.3 35.3 0 19.5-15.8 35.3-35.3 35.3m0-54.6c-10.7 0-19.3 8.7-19.3 19.3 0 10.7 8.7 19.3 19.3 19.3 10.7 0 19.3-8.7 19.3-19.3.1-10.7-8.6-19.3-19.3-19.3"
        fill={VARIANT_COLORS[color][1]}
      />
      <path
        d="M271.5 114.2c-19.5 0-35.3-15.8-35.3-35.3 0-19.5 15.8-35.3 35.3-35.3 19.5 0 35.3 15.8 35.3 35.3 0 19.5-15.8 35.3-35.3 35.3m0-54.6c-10.7 0-19.3 8.7-19.3 19.3 0 10.7 8.7 19.3 19.3 19.3 10.7 0 19.3-8.7 19.3-19.3 0-10.7-8.6-19.3-19.3-19.3"
        fill={VARIANT_COLORS[color][1]}
      />
    </svg>
  )
}
