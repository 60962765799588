import axios from 'axios'
import { reportError } from '@qasa/app'
import type { Market } from '@qasa/graphql'
import { EXTERNAL_LINKS_BY_MARKET } from '@qasa/app/src/configs/external-links'

import { EXTERNAL_LINKS } from '../config/external-links'
import { currentBrand } from '../config'

import type { LanguageCode } from './i18n'

export type ZendeskArticleSection =
  | 'home'
  | 'applications'
  | 'conversations'
  | 'conversations'
  | 'rental-agreements'
  | 'invoices'
  | 'invoices'
  | 'profile-update'
  | 'settings'
  | 'listings'
  | 'edit-listing'

export type ZendeskArticle = { url: string; title: string }

function overrideLanguage(language?: LanguageCode) {
  return language === 'en' || !language ? 'en-US' : language
}

/**
 * Fetch articles from zendesk
 */
type GetArticlesParams = {
  section: ZendeskArticleSection
  role: 'tenant' | 'landlord' | ''
  language?: LanguageCode
  market?: Pick<Market, 'name'>
}
function getArticles({ section, role, language, market }: GetArticlesParams): Promise<ZendeskArticle[]> {
  const isBlocket = currentBrand === 'blocket'
  // If market is defined and we are not in blocket, use the market specific help center url. Needed since blocket and qasa have different help centers and is located in sweden.
  const { helpCenterUrl: baseUrl } =
    market && !isBlocket ? EXTERNAL_LINKS_BY_MARKET[market.name] : EXTERNAL_LINKS

  return axios
    .get(baseUrl + 'api/v2/help_center/articles/search.json', {
      params: { label_names: `${role}-${section}`, locale: overrideLanguage(language) },
    })
    .then((response) => {
      const articles = response.data.results || []
      if (!articles) {
        reportError('Unexpected zendesk empty response', { section })
      }
      return articles
        .slice(0, 6)
        .map((article: FixThisTypeLater) => ({ title: article.title, url: article.html_url }))
    })
    .catch((err) => {
      reportError('Unexpected zendesk error', { err, section })
      return []
    })
}

export const ZendeskAPI = {
  getArticles,
}
