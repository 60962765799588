import { useEffect, useState } from 'react'
import { useBreakpointValue } from '@qasa/ui'

export const useHasUsedMouseAfterScrolling = () => {
  const [hasUsedMouseAfterScrolling, setHasUsedMouseAfterScrolling] = useState(true)

  // This feature is not relevant on mobile, so we'll just return true if isMobile === true
  const isMobile = useBreakpointValue({ base: true, md: false })

  // Track mouse and scroll positions to determine if the user has scrolled or moved the mouse
  const MINIMUM_SCROLL_DISTANCE = 32
  const MINIMUM_MOUSE_DISTANCE = 32
  const TIME_TO_WAIT_FOR_SCROLL_TO_STOP = 400 // Ignore mouse movements while scrolling (in milliseconds)
  const [previousScrollPosition, setPreviousScrollPosition] = useState(0)
  const [previousMousePosition, setPreviousMousePosition] = useState({ x: 0, y: 0 })
  const [lastScrollTime, setLastScrollTime] = useState(Date.now())

  useEffect(() => {
    // Returning an empty arrow function so the effect keeps the same return type if it stops here
    if (isMobile) return () => {} // eslint-disable-line @typescript-eslint/no-empty-function

    const onScroll = () => {
      const difference = window.scrollY - 0
      const distanceScrolled = Math.abs(difference)
      const hasScrolled = distanceScrolled >= MINIMUM_SCROLL_DISTANCE
      if (hasScrolled) {
        setHasUsedMouseAfterScrolling(false)
        // If we update previousScrollPosition and lastScrollTime on each event
        // we'll end up measuring speed and not distance. That's we only update
        // previousScrollPosition and ...Time here when the user has scrolled far enough.
        setPreviousScrollPosition(window.scrollY)
        setLastScrollTime(Date.now())
      }
    }

    const onMouseMove = (event: MouseEvent) => {
      // Ignore mousemove that happens while (well, just after) scrolling
      if (Date.now() - lastScrollTime < TIME_TO_WAIT_FOR_SCROLL_TO_STOP) return

      const difference = {
        x: event.clientX - previousMousePosition.x,
        y: event.clientY - previousMousePosition.y,
      }
      // We use Pythagoras' theorem to calculate how far the mouse has moved (yes THAT pythagoras' theorem!)
      const mouseDistance = Math.sqrt(difference.x * difference.x + difference.y * difference.y)
      const hasMovedMouse = mouseDistance >= MINIMUM_MOUSE_DISTANCE
      if (hasMovedMouse) {
        setHasUsedMouseAfterScrolling(true)
        // If we update previousMousePosition on each event we'll end up
        // measuring speed and not distance. That's we only update
        // previousScrollPosition here where the mouse has moved far enough.
        setPreviousMousePosition({ x: event.clientX, y: event.clientY })
      }
    }

    // Clicking is a strong interaction, so this counts as using the mouse no matter what
    const onMouseClick = () => setHasUsedMouseAfterScrolling(true)

    document.addEventListener('scroll', onScroll)
    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('click', onMouseClick)

    return () => {
      document.removeEventListener('scroll', onScroll)
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('click', onMouseClick)
    }
  }, [previousMousePosition, previousScrollPosition, lastScrollTime, hasUsedMouseAfterScrolling, isMobile])

  return isMobile || hasUsedMouseAfterScrolling
}
