import { useTranslation } from 'react-i18next'
import { Stack, createIcon } from '@qasa/qds-ui'
import type { ReactNode } from 'react'
import { TraitTypeEnum } from '@qasa/graphql'

import { CheckboxCard, CheckboxGroup } from '../../../ui-shared/_core/controls/checkbox'
import { FieldEnum } from '../edit-listing.types'
import { useListingContext } from '../listing-context'
import { SectionWrapper } from '../section-wrapper'

const BeachIcon = createIcon({
  displayName: 'BeachIcon',
  viewBox: '0 0 576 512',
  d: 'M346.3 271.8l-60.1-21.9L214 448H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H282.1l64.1-176.2zm121.1-.2l-3.3 9.1 67.7 24.6c18.1 6.6 38-4.2 39.6-23.4c6.5-78.5-23.9-155.5-80.8-208.5c2 8 3.2 16.3 3.4 24.8l.2 6c1.8 57-7.3 113.8-26.8 167.4zM462 99.1c-1.1-34.4-22.5-64.8-54.4-77.4c-.9-.4-1.9-.7-2.8-1.1c-33-11.7-69.8-2.4-93.1 23.8l-4 4.5C272.4 88.3 245 134.2 226.8 184l-3.3 9.1L434 269.7l3.3-9.1c18.1-49.8 26.6-102.5 24.9-155.5l-.2-6zM107.2 112.9c-11.1 15.7-2.8 36.8 15.3 43.4l71 25.8 3.3-9.1c19.5-53.6 49.1-103 87.1-145.5l4-4.5c6.2-6.9 13.1-13 20.5-18.2c-79.6 2.5-154.7 42.2-201.2 108z',
})

const SkiingIcon = createIcon({
  displayName: 'SkiingIcon',
  viewBox: '0 0 512 512',
  d: 'M380.7 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM2.7 268.9c6.1-11.8 20.6-16.3 32.4-10.2L232.7 361.3l46.2-69.2-75.1-75.1c-14.6-14.6-20.4-33.9-18.4-52.1l108.8 52 39.3 39.3c16.2 16.2 18.7 41.5 6 60.6L289.8 391l128.7 66.8c13.6 7.1 29.8 7.2 43.6 .3l15.2-7.6c11.9-5.9 26.3-1.1 32.2 10.7s1.1 26.3-10.7 32.2l-15.2 7.6c-27.5 13.7-59.9 13.5-87.2-.7L12.9 301.3C1.2 295.2-3.4 280.7 2.7 268.9zM118.9 65.6L137 74.2l8.7-17.4c4-7.9 13.6-11.1 21.5-7.2s11.1 13.6 7.2 21.5l-8.5 16.9 54.7 26.2c1.5-.7 3.1-1.4 4.7-2.1l83.4-33.4c34.2-13.7 72.8 4.2 84.5 39.2l17.1 51.2 52.1 26.1c15.8 7.9 22.2 27.1 14.3 42.9s-27.1 22.2-42.9 14.3l-58.1-29c-11.4-5.7-20-15.7-24.1-27.8l-5.8-17.3-27.3 12.1-6.8 3-6.7-3.2L151.5 116.7l-9.2 18.4c-4 7.9-13.6 11.1-21.5 7.2s-11.1-13.6-7.2-21.5l9-18-17.6-8.4c-8-3.8-11.3-13.4-7.5-21.3s13.4-11.3 21.3-7.5z',
})

const GolfIcon = createIcon({
  displayName: 'GolfIcon',
  viewBox: '0 0 384 512',
  d: 'M384 192c0 66.8-34.1 125.6-85.8 160H85.8C34.1 317.6 0 258.8 0 192C0 86 86 0 192 0S384 86 384 192zM242.1 256.6c0 18.5-15 33.5-33.5 33.5c-4.9 0-9.6-1.1-13.8-3c5.3 11.6 16.9 19.7 30.5 19.7c18.5 0 33.5-15 33.5-33.5c0-13.6-8.1-25.3-19.7-30.5c1.9 4.2 3 8.9 3 13.8zm-52.3-49.3c-4.9 0-9.6-1.1-13.8-3c5.3 11.6 16.9 19.7 30.5 19.7c18.5 0 33.5-15 33.5-33.5c0-13.6-8.1-25.3-19.7-30.5c1.9 4.2 3 8.9 3 13.8c0 18.5-15 33.5-33.5 33.5zm113.5-17.5c0 18.5-15 33.5-33.5 33.5c-4.9 0-9.6-1.1-13.8-3c5.3 11.6 16.9 19.7 30.5 19.7c18.5 0 33.5-15 33.5-33.5c0-13.6-8.1-25.3-19.7-30.5c1.9 4.2 3 8.9 3 13.8zM96 448V384H288v64H224v64H160V448H96z',
})

const FishingIcon = createIcon({
  displayName: 'FishingIcon',
  viewBox: '0 0 576 512',
  d: 'M180.5 141.5C219.7 108.5 272.6 80 336 80s116.3 28.5 155.5 61.5c39.1 33 66.9 72.4 81 99.8c4.7 9.2 4.7 20.1 0 29.3c-14.1 27.4-41.9 66.8-81 99.8C452.3 403.5 399.4 432 336 432s-116.3-28.5-155.5-61.5c-16.2-13.7-30.5-28.5-42.7-43.1L48.1 379.6c-12.5 7.3-28.4 5.3-38.7-4.9S-3 348.7 4.2 336.1L50 256 4.2 175.9c-7.2-12.6-5-28.4 5.3-38.6s26.1-12.2 38.7-4.9l89.7 52.3c12.2-14.6 26.5-29.4 42.7-43.1zM448 256a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z',
})

const HikingIcon = createIcon({
  displayName: 'HikingIcon',
  viewBox: '0 0 384 512',
  d: 'M192 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm51.3 182.7L224.2 307l49.7 49.7c9 9 14.1 21.2 14.1 33.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V397.3l-73.9-73.9c-15.8-15.8-22.2-38.6-16.9-60.3l20.4-84c8.3-34.1 42.7-54.9 76.7-46.4c19 4.8 35.6 16.4 46.4 32.7L305.1 208H336V184c0-13.3 10.7-24 24-24s24 10.7 24 24v55.8c0 .1 0 .2 0 .2s0 .2 0 .2V488c0 13.3-10.7 24-24 24s-24-10.7-24-24V272H296.6c-16 0-31-8-39.9-21.4l-13.3-20zM81.1 471.9L117.3 334c3 4.2 6.4 8.2 10.1 11.9l41.9 41.9L142.9 488.1c-4.5 17.1-22 27.3-39.1 22.8s-27.3-22-22.8-39.1zm55.5-346L101.4 266.5c-3 12.1-14.9 19.9-27.2 17.9l-47.9-8c-14-2.3-22.9-16.3-19.2-30L31.9 155c9.5-34.8 41.1-59 77.2-59h4.2c15.6 0 27.1 14.7 23.3 29.8z',
})

const ArchipelagoIcon = createIcon({
  displayName: 'ArchipelagoIcon',
  viewBox: '0 0 576 512',
  d: 'M269.5 69.9c11.1-7.9 25.9-7.9 37 0C329 85.4 356.5 96 384 96c26.9 0 55.4-10.8 77.4-26.1l0 0c11.9-8.5 28.1-7.8 39.2 1.7c14.4 11.9 32.5 21 50.6 25.2c17.2 4 27.9 21.2 23.9 38.4s-21.2 27.9-38.4 23.9c-24.5-5.7-44.9-16.5-58.2-25C449.5 149.7 417 160 384 160c-31.9 0-60.6-9.9-80.4-18.9c-5.8-2.7-11.1-5.3-15.6-7.7c-4.5 2.4-9.7 5.1-15.6 7.7c-19.8 9-48.5 18.9-80.4 18.9c-33 0-65.5-10.3-94.5-25.8c-13.4 8.4-33.7 19.3-58.2 25c-17.2 4-34.4-6.7-38.4-23.9s6.7-34.4 23.9-38.4C42.8 92.6 61 83.5 75.3 71.6c11.1-9.5 27.3-10.1 39.2-1.7l0 0C136.7 85.2 165.1 96 192 96c27.5 0 55-10.6 77.5-26.1zm37 288C329 373.4 356.5 384 384 384c26.9 0 55.4-10.8 77.4-26.1l0 0c11.9-8.5 28.1-7.8 39.2 1.7c14.4 11.9 32.5 21 50.6 25.2c17.2 4 27.9 21.2 23.9 38.4s-21.2 27.9-38.4 23.9c-24.5-5.7-44.9-16.5-58.2-25C449.5 437.7 417 448 384 448c-31.9 0-60.6-9.9-80.4-18.9c-5.8-2.7-11.1-5.3-15.6-7.7c-4.5 2.4-9.7 5.1-15.6 7.7c-19.8 9-48.5 18.9-80.4 18.9c-33 0-65.5-10.3-94.5-25.8c-13.4 8.4-33.7 19.3-58.2 25c-17.2 4-34.4-6.7-38.4-23.9s6.7-34.4 23.9-38.4c18.1-4.2 36.2-13.3 50.6-25.2c11.1-9.4 27.3-10.1 39.2-1.7l0 0C136.7 373.2 165.1 384 192 384c27.5 0 55-10.6 77.5-26.1c11.1-7.9 25.9-7.9 37 0zm0-144C329 229.4 356.5 240 384 240c26.9 0 55.4-10.8 77.4-26.1l0 0c11.9-8.5 28.1-7.8 39.2 1.7c14.4 11.9 32.5 21 50.6 25.2c17.2 4 27.9 21.2 23.9 38.4s-21.2 27.9-38.4 23.9c-24.5-5.7-44.9-16.5-58.2-25C449.5 293.7 417 304 384 304c-31.9 0-60.6-9.9-80.4-18.9c-5.8-2.7-11.1-5.3-15.6-7.7c-4.5 2.4-9.7 5.1-15.6 7.7c-19.8 9-48.5 18.9-80.4 18.9c-33 0-65.5-10.3-94.5-25.8c-13.4 8.4-33.7 19.3-58.2 25c-17.2 4-34.4-6.7-38.4-23.9s6.7-34.4 23.9-38.4c18.1-4.2 36.2-13.3 50.6-25.2c11.1-9.5 27.3-10.1 39.2-1.7l0 0C136.7 229.2 165.1 240 192 240c27.5 0 55-10.6 77.5-26.1c11.1-7.9 25.9-7.9 37 0z',
})

const RemoteIcon = createIcon({
  displayName: 'RemoteIcon',
  viewBox: '0 0 640 512',
  d: 'M70.2 121.4L178.5 5.8C182 2.1 186.9 0 192 0s10 2.1 13.5 5.8L313.8 121.4c4 4.3 6.2 9.9 6.2 15.7c0 12.7-10.3 22.9-22.9 22.9H272l74.6 89.5c3.5 4.2 5.4 9.5 5.4 15c0 13-10.5 23.5-23.5 23.5H304l74.6 89.5c3.5 4.2 5.4 9.5 5.4 15c0 13-10.5 23.5-23.5 23.5H224v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H23.5C10.5 416 0 405.5 0 392.5c0-5.5 1.9-10.8 5.4-15L80 288H55.5c-13 0-23.5-10.5-23.5-23.5c0-5.5 1.9-10.8 5.4-15L112 160H86.9C74.3 160 64 149.7 64 137.1c0-5.8 2.2-11.4 6.2-15.7zm271.4-16.4L434.5 5.8C438 2.1 442.9 0 448 0s10 2.1 13.5 5.8L569.8 121.4c4 4.3 6.2 9.9 6.2 15.7c0 12.7-10.3 22.9-22.9 22.9H528l74.6 89.5c3.5 4.2 5.4 9.5 5.4 15c0 13-10.5 23.5-23.5 23.5H560l74.6 89.5c3.5 4.2 5.4 9.5 5.4 15c0 13-10.5 23.5-23.5 23.5H480v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V416l0-23.4 0-.1 0-.1V392h0c-.1-12.8-4.7-25.2-12.9-35l-40.6-48.7c13-10.2 21.4-26 21.4-43.8c0-13-4.6-25.6-12.9-35.5l-40.4-48.5c12.9-10 21.3-25.8 21.3-43.4c0-11.6-3.7-22.8-10.4-32.1z',
})

const RestaurantsIcon = createIcon({
  displayName: 'RestaurantsIcon',
  viewBox: '0 0 512 512',
  d: 'M153.6 51.2C177 20.1 214.1 0 256 0s79 20.1 102.4 51.2c16-12.1 36-19.2 57.6-19.2c7.1 0 14.4 .8 21.6 2.5C482 44.7 512 84.3 512 128c0 7.1-.8 14.4-2.5 21.6C496 224 448 320 448 320H373.6l26.2-157.4c1.5-8.7-4.4-17-13.2-18.4s-17 4.4-18.4 13.2L341.1 320H272V160c0-8.8-7.2-16-16-16s-16 7.2-16 16V320H170.9L143.8 157.4c-1.5-8.7-9.7-14.6-18.4-13.2s-14.6 9.7-13.2 18.4L138.4 320H64s-48-96-61.5-170.4C.8 142.4 0 135.1 0 128c0-43.7 30-83.3 74.4-93.5C81.6 32.8 88.9 32 96 32c21.6 0 41.6 7.1 57.6 19.2zM448 448c0 35.3-28.7 64-64 64H128c-35.3 0-64-28.7-64-64V352H448v96z',
})

export const LOCATION_PERKS: {
  trait: TraitTypeEnum
  icon: ReactNode
}[] = [
  {
    trait: TraitTypeEnum.near_beach,
    icon: <BeachIcon size={20} />,
  },
  {
    trait: TraitTypeEnum.ski_in_out,
    icon: <SkiingIcon size={20} />,
  },
  {
    trait: TraitTypeEnum.near_golf,
    icon: <GolfIcon size={20} />,
  },
  {
    trait: TraitTypeEnum.near_fishing,
    icon: <FishingIcon size={20} />,
  },
  {
    trait: TraitTypeEnum.near_hiking_routes,
    icon: <HikingIcon size={20} />,
  },
  {
    trait: TraitTypeEnum.archipelago,
    icon: <ArchipelagoIcon size={20} />,
  },
  {
    trait: TraitTypeEnum.remote,
    icon: <RemoteIcon size={20} />,
  },
  {
    trait: TraitTypeEnum.near_restaurants,
    icon: <RestaurantsIcon size={20} />,
  },
]

export function LocationPerksSection() {
  const { t } = useTranslation('listing')
  const {
    store: {
      values: { traits: selectedTraits },
    },
    dispatch,
  } = useListingContext()

  return (
    <SectionWrapper title={t(`section_headings.location_perks`)}>
      <CheckboxGroup
        label=""
        value={selectedTraits ?? []}
        onChange={(values) => {
          dispatch({
            action: {
              type: FieldEnum.Traits,
              payload: values as TraitTypeEnum[],
            },
          })
        }}
      >
        <Stack gap="2x">
          {LOCATION_PERKS.map(({ trait }) => {
            return <CheckboxCard key={trait} label={t(`home_amenities:${trait}`)} value={trait} />
          })}
        </Stack>
      </CheckboxGroup>
    </SectionWrapper>
  )
}
