import styled from '@emotion/styled'
import { reportError } from '@qasa/app'
import type { UploadMetadataInput } from '@qasa/graphql'

const FileInputWrapper = styled.div({
  position: 'relative',
})

const FileInputLabel = styled.label<{ isLoading?: boolean; isDisabled?: boolean }>(
  ({ isLoading = false, isDisabled = false }) => ({
    display: 'block',
    position: 'absolute',
    opacity: 0,
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
    cursor: isLoading ? 'progress' : isDisabled ? 'not-allowed' : 'pointer',
  }),
)

const FileInput = styled.input({
  display: 'none',
})

type FileUploadProps = {
  children: React.ReactNode
  className?: string
  accept?: string
  uploadApi: (file: File, metadata?: UploadMetadataInput) => Promise<string>
  onUploadStart?: () => void
  onUploadEnd?: (uploadUrls?: string[]) => void
  disabled?: boolean
  isLoading?: boolean
  shouldAllowMultiple?: boolean
  initialMetadata?: UploadMetadataInput
}

export function FileUpload({
  children,
  className = '',
  accept = '*',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onUploadStart = () => {},
  onUploadEnd,
  uploadApi,
  disabled,
  isLoading,
  shouldAllowMultiple,
  initialMetadata = { order: 0, rotation: null, primary: false },
}: FileUploadProps) {
  const handleOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files?.length) {
      let uploadedFiles: string[] = []

      try {
        onUploadStart()
        let promises: Promise<string>[] = []
        for (let i = 0; i < files.length; i++) {
          promises = [...promises, uploadApi(files[i], { order: (initialMetadata.order ?? 0) + i })]
        }
        uploadedFiles = await Promise.all(promises)
      } catch (e: LegitimateAny) {
        reportError(e.message, { error: e })
      } finally {
        if (onUploadEnd) {
          onUploadEnd(uploadedFiles)
        }
        // Clear the value to enable resubmitting the same photo
        // Hacky solution, but react doesn't support controlled
        // file inputs
        e.target.value = ''
      }
    }
  }

  return (
    <FileInputWrapper className={className}>
      {children}
      <FileInputLabel isLoading={isLoading} isDisabled={disabled}>
        <FileInput
          type="file"
          multiple={shouldAllowMultiple}
          onChange={handleOnChange}
          {...{ disabled, accept }}
        />
      </FileInputLabel>
    </FileInputWrapper>
  )
}
