import { apolloClient, reportError } from '@qasa/app'
import type { SearchPreferenceInput } from '@qasa/graphql'

import { SEARCH_AREA } from '../../../data/graphql/queries/search-area'
import {
  ProfileContractTypeEnum,
  ProfileFurnishedEnum,
  ProfilePreferredAmenitiesEnum,
  ProfileRequiredAmenitiesEnum,
  ProfileSharedHomeEnum,
} from '../profile.types'
import { isNotNull } from '../../../utils/misc'

import type { ParsedProfileSearchPreference } from './parse-profile-search-preference'

export const serializeProfileSearchPreference = async ({
  homeType,
  furniture,
  shared,
  preferredAmenities,
  requiredAmenities,
  householdSize,
  minRoomCount,
  minSquareMeters,
  maxMonthlyCost,
  currency,
  moveIn,
  moveOut,
  contractType,
  selectedAreas,
}: Partial<ParsedProfileSearchPreference>): Promise<SearchPreferenceInput> => {
  const areaParams = selectedAreas
    ?.map(({ city, name, countryCode }) => {
      if (!countryCode) {
        return null
      }
      return { city, name, countryCode }
    })
    .filter(isNotNull)

  const searchAreaResponse = await apolloClient
    .query({
      query: SEARCH_AREA,
      variables: { areaParams },
    })
    .catch((err) => reportError('Unexpected error when fetching profile searchArea cache data', { err }))

  const getSearchAreaData = () => {
    const searchAreas = searchAreaResponse?.data?.searchArea
    const areaIdentifier = searchAreas?.map(({ identifier }) => identifier) ?? []
    const definedAreaIdentifiers = selectedAreas?.map(({ identifier }) => identifier).filter(isNotNull) ?? []
    const mergedAreaIdentifier = [...areaIdentifier, ...definedAreaIdentifiers]
    return { areaIdentifier: mergedAreaIdentifier }
  }
  const getFurniture = () => {
    switch (furniture) {
      case ProfileFurnishedEnum.furnished:
        return true
      case ProfileFurnishedEnum.unfurnished:
        return false
      case ProfileFurnishedEnum.both:
      default:
        return null
    }
  }
  const getSharedHome = () => {
    switch (shared) {
      case ProfileSharedHomeEnum.onlyPrivate:
        return false
      case ProfileSharedHomeEnum.all:
      default:
        return null
    }
  }

  const getContractType = (): Pick<SearchPreferenceInput, 'seniorHome' | 'studentHome'> => {
    return {
      seniorHome: contractType === ProfileContractTypeEnum.senior,
      studentHome: contractType === ProfileContractTypeEnum.student,
    }
  }

  const getRequiredAmenities = () => {
    const parsedRequiredAmenities: Pick<SearchPreferenceInput, ProfileRequiredAmenitiesEnum> = {}

    Object.values(ProfileRequiredAmenitiesEnum).forEach((requirement) => {
      parsedRequiredAmenities[requirement] = requiredAmenities?.includes(requirement)
    })
    return parsedRequiredAmenities
  }
  const getPreferredAmenities = () => {
    const parsedPreferredAmenities: Pick<SearchPreferenceInput, ProfilePreferredAmenitiesEnum> = {}

    Object.values(ProfilePreferredAmenitiesEnum).forEach((preference) => {
      parsedPreferredAmenities[preference] = preferredAmenities?.includes(preference)
    })
    return parsedPreferredAmenities
  }

  return {
    ...(moveIn !== undefined && { startAsap: !moveIn, startOptimal: moveIn }),
    ...(moveOut !== undefined && { endUfn: !moveOut, endOptimal: moveOut }),
    ...(maxMonthlyCost !== undefined && { maxMonthlyCost }),
    ...(currency !== undefined && { currency }),
    ...(homeType !== undefined && { homeType }),
    ...(minSquareMeters !== undefined && { minSquareMeters }),
    ...(minRoomCount !== undefined && { minRoomCount }),
    ...(householdSize !== undefined && { householdSize }),
    ...(preferredAmenities !== undefined && { ...getPreferredAmenities() }),
    ...(requiredAmenities !== undefined && { ...getRequiredAmenities() }),
    ...(contractType !== undefined && { ...getContractType() }),
    ...(contractType !== undefined && { ...getSearchAreaData() }),
    ...(furniture !== undefined && { furniture: getFurniture() }),
    ...(shared !== undefined && { shared: getSharedHome() }),
  }
}
