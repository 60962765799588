import { Button, Heading, Paragraph, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { ampli } from '@qasa/ampli/p2'
import { Dialog } from '@qasa/app/src/components/dialog'

export function AboutQasaDialog() {
  const { t } = useTranslation('about_qasa_dialog')
  const [tCommon] = useTranslation('commons')
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const usps: { heading: string; description: string }[] = t('usps', { returnObjects: true })

  useEffect(() => {
    if (isDialogOpen) {
      ampli.aboutQasaDialogOpened({ '[Amplitude] Page URL': window.location.href })
    }
  }, [isDialogOpen])

  return (
    <Dialog
      isOpen={isDialogOpen}
      onOpenChange={setIsDialogOpen}
      trigger={
        <Button variant="tertiary" size="sm">
          {t('button_text')}
        </Button>
      }
    >
      <Dialog.Header hasSidePadding>
        <Dialog.Title>{t('title')}</Dialog.Title>
      </Dialog.Header>
      <Dialog.Body>
        <Stack gap="6x">
          {usps.map((item, index) => (
            <Stack gap="2x" key={index}>
              <Heading size="xs">{item.heading}</Heading>
              <Paragraph>{item.description}</Paragraph>
            </Stack>
          ))}
        </Stack>
      </Dialog.Body>
      <Dialog.Footer hasBorder={false}>
        <Button onClick={() => setIsDialogOpen(false)} variant="secondary">
          {tCommon('close')}
        </Button>
      </Dialog.Footer>
    </Dialog>
  )
}
