import omit from 'lodash/omit'
import { useHistory, useLocation } from 'react-router-dom'
import type { Stringifiable } from 'query-string'
import { parse, stringify } from 'query-string'

type StringifiableArray<T extends Stringifiable> = T[] | readonly T[]

type UseQueryParamParams<T extends Stringifiable> = {
  /**
   * Name of the query parameter
   */
  paramName: string
  /**
   * Will be used as inital value and
   * as fallback if URL contains a value
   * not in `whiteList`
   */
  defaultValue: T
  /**
   * Allowed values for URL query parameter
   * If not defined , it will return query paramater value.
   * If query value is not defined it will fallback to defaultValue.
   */
  whiteList?: StringifiableArray<T>
}

/**
 * @deprecated Use installed use-query-params npm package instead
 */
export const useQueryParam = <T extends Stringifiable>({
  paramName,
  defaultValue,
  whiteList,
}: UseQueryParamParams<T>) => {
  const { search, state } = useLocation()
  const { replace } = useHistory()
  const params = parse(search, { parseBooleans: true, parseNumbers: true })
  const paramValue = params[paramName] as unknown as T
  let currentValue = defaultValue

  if (whiteList) {
    currentValue = whiteList.includes(paramValue) ? paramValue : defaultValue
  } else {
    currentValue = paramValue ?? defaultValue
  }

  const setParamValue = (value: T) => {
    const newSearchParams = stringify({ ...params, [paramName]: value })
    replace({ search: newSearchParams, state })
  }

  /**
   * Resets the current value to its default and
   * updates the URL.
   */
  const resetValue = () => {
    const newSearchParams = stringify(omit(params, [paramName]))
    replace({ search: newSearchParams, state })
  }

  return [currentValue, setParamValue, resetValue] as const
}
