import type { IconButtonProps } from '@qasa/qds-ui'
import { IconButton as QdsIconButton } from '@qasa/qds-ui'
import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'

type ButtonOverrideProps = Omit<IconButtonProps, 'onClick'> & {
  onPress: IconButtonProps['onClick']
}
export const IconButton = forwardRef(
  ({ onPress, ...rest }: ButtonOverrideProps, forwardedRef?: ForwardedRef<HTMLButtonElement>) => {
    return <QdsIconButton ref={forwardedRef} onClick={onPress} {...rest} />
  },
)
