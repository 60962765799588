import { styled } from '@qasa/ui/web'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import type { ReactNode } from 'react'

type DropdownMenuRootProps = {
  children: ReactNode
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
}
function DropdownRoot({ children, isOpen, ...rest }: DropdownMenuRootProps) {
  return (
    <DropdownMenuPrimitive.Root open={isOpen} {...rest}>
      {children}
    </DropdownMenuPrimitive.Root>
  )
}

type DropdownMenuTriggerProps = {
  children: ReactNode
}
function DropdownMenuTrigger({ children }: DropdownMenuTriggerProps) {
  return <DropdownMenuPrimitive.Trigger asChild>{children}</DropdownMenuPrimitive.Trigger>
}

const StyledDropdownMenuContent = styled(DropdownMenuPrimitive.Content)(({ theme }) => ({
  minWidth: theme.sizes['192'],
  maxWidth: theme.sizes['320'],
  paddingTop: theme.spacing['2x'],
  paddingBottom: theme.spacing['2x'],
  background: 'white',
  boxShadow: theme.shadows.sm,
  borderRadius: theme.radii.md,
  border: '1px solid',
  borderColor: theme.colors.border.default,
  zIndex: theme.zIndices.dropdown,
  overflow: 'hidden',
}))

type DropdownMenuContentProps = DropdownMenuPrimitive.DropdownMenuContentProps
function DropdownMenuContent({ children, align = 'end', sideOffset = 4, ...rest }: DropdownMenuContentProps) {
  return (
    <DropdownMenuPrimitive.Portal>
      <StyledDropdownMenuContent align={align} sideOffset={sideOffset} {...rest}>
        {children}
      </StyledDropdownMenuContent>
    </DropdownMenuPrimitive.Portal>
  )
}

const DropdownMenuItem = styled(DropdownMenuPrimitive.Item)(({ theme }) => ({
  paddingLeft: theme.spacing['4x'],
  paddingRight: theme.spacing['4x'],
  height: 40,
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  ...theme.typography.body.sm,
  cursor: 'pointer',

  ':hover': {
    background: theme.colors.bg.inset,
  },
  ':focus:not(:focus-visible)': {
    outline: 'none',
  },
  ':focus-visible': {
    outline: '2px solid black',
    outlineOffset: -2,
  },
}))

export const DropdownMenu = Object.assign(DropdownRoot, {
  Trigger: DropdownMenuTrigger,
  Content: DropdownMenuContent,
  Item: DropdownMenuItem,
})
