import { Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'

const ListItem = styled.li({})

type ProfileCardUspListParams = {
  translationArray: string[]
}
export function ProfileCardUspList({ translationArray }: ProfileCardUspListParams) {
  if (!Array.isArray(translationArray)) return null
  return (
    <Stack as="ul" gap="2x">
      {translationArray.map((usp, index) => (
        <ListItem key={index}>{usp}</ListItem>
      ))}
    </Stack>
  )
}
