import { Heading, Paragraph, Spacer, Button, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { LanguageSwitch } from '../../../translations/language-loader/language-switch'
import { Wizard } from '../../../ui-shared/wizard'
import type { UspItemProps } from '../components/usp-list'
import { UspList } from '../components/usp-list'
import { useMutateUserToTenant } from '../hooks/use-mutate-user-to-tenant'
import { useWizardRouterContext } from '../wizard-router/wizard-router'

export function IntroStep() {
  const { t } = useTranslation('create_tenant_listing')
  const { goNext } = useWizardRouterContext()
  const { upsertUserToTenant } = useMutateUserToTenant()

  const handleNextClick = async () => {
    await upsertUserToTenant()
    goNext()
  }
  const uspItems: Array<UspItemProps> = t(`usp_sections:intro.items`, {
    returnObjects: true,
  })

  return (
    <>
      <Stack gap={'12x'}>
        <Stack gap={'3x'}>
          <Heading size="lg" as="h1">
            {t('steps.intro.title')}
          </Heading>
          <Paragraph>{t('steps.intro.paragraph')}</Paragraph>
        </Stack>
        <UspList>
          {uspItems.map((item, index) => (
            <UspList.Item key={index} {...item} />
          ))}
        </UspList>
        <LanguageSwitch />
      </Stack>
      <Spacer size="8x" />
      <Wizard.Footer>
        <Button variant="primary" onClick={handleNextClick} isFullWidth>
          {t('commons:next')}
        </Button>
      </Wizard.Footer>
    </>
  )
}
