import { useEffect, useState } from 'react'

type UseDebouncedValueParams<T> = {
  value: T
  delay: number
}
export const useDebouncedValue = <T>({ value, delay }: UseDebouncedValueParams<T>) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  })
  return debouncedValue
}
