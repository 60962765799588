import type { ExternalLinks } from '.'

export const QASA_FINLAND_EXTERNAL_LINKS: ExternalLinks = {
  requestSupportUrl: 'https://support.qasa.fi/hc/fi/requests/new?ticket_form_id=8502804860945',
  helpCenterUrl: 'https://support.qasa.fi/',
  schibstedAccountSettingsUrl: 'https://login.schibsted.fi/account/summary',
  depositFreeSupportUrl: 'https://support.qasa.fi/hc/fi/articles/6803884340753',
  termsAndConditionsUrl: 'https://support.qasa.fi/hc/fi/articles/7253285818897',
  pricing: 'https://support.qasa.fi/hc/fi/articles/360042797611',
  sortingPolicyUrl: 'https://support.qasa.fi/hc/fi/articles/8785511334289',
  landlordContractTerminationSupportUrl: 'https://support.qasa.fi/hc/articles/360020927738',
  tenantContractTerminationSupportUrl: 'https://support.qasa.fi/hc/articles/360020931838',
  landlordOfferingSupportUrl: 'https://support.qasa.fi/hc/fi/articles/360045439531',
  securitySupportUrl:
    'https://support.qasa.fi/hc/fi/articles/360042798031-Kuinka-toimia-jos-vuokranantaja-haluaa-minun-maksavan-suoraan-h%C3%A4nelle-',
  // NOTE: Press link for Finland isn't actually a press link, but a link to another help center article for now - Sahin 2023-06-08
  press: 'https://support.qasa.fi/hc/fi/sections/360008731891',
  oikotiePublishListingUrl: 'https://asunnot.oikotie.fi/vuokraa-tori/sijainti',
  infoEmail: 'info@qasa.fi',
  publishListingsSupportUrl: 'https://support.qasa.fi/hc/fi/articles/360044439412',
  keyExchangeReceiptUrl: 'https://support.qasa.fi/hc/fi/article_attachments/16802431153553',
  inventoryListUrl: 'https://support.qasa.fi/hc/fi/article_attachments/16802253758481',
  rentGuaranteSupportUrl: 'https://support.qasa.fi/hc/fi/articles/360054481871',
  depositHandlingSupportUrl: 'https://support.qasa.fi/hc/fi/articles/15924518652945',
  insuranceClaim: 'https://external.omocom.se/claimsform',
  insuranceSupportUrl: 'https://support.qasa.fi/hc/fi/articles/26601888733585-Vuokranantajan-vakuutus',
}
