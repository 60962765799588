import { graphql } from '@qasa/graphql'

export const CANCEL_CONTRACT_SIGNING = graphql(`
  mutation CancelContractSigning($id: ID!) {
    cancelContractSigning(id: $id) {
      contract {
        id
        status
      }
      errors {
        field
        codes
      }
    }
  }
`)
