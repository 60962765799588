import { Link, Paragraph } from '@qasa/qds-ui'
import { useTranslation, Trans } from 'react-i18next'
import type { Market } from '@qasa/graphql'
import { useExternalLinks } from '@qasa/app/src/configs/external-links'

export function TermsAndConditionsSection({ market }: { market: Pick<Market, 'name'> | undefined }) {
  const { t } = useTranslation('shortcut_opt_in')
  const { privacyPolicyUrl, termsAndConditionsUrl } = useExternalLinks({ marketName: market?.name })

  return (
    <Paragraph size="sm" color="subtle">
      <Trans
        t={t}
        i18nKey="intro.service_fee"
        components={[
          <Link key="uno" isExternal href={termsAndConditionsUrl} style={{ color: 'initial' }} />,
          <Link key="dos" isExternal href={privacyPolicyUrl} style={{ color: 'initial' }} />,
        ]}
      />
    </Paragraph>
  )
}
