import { Spacer, Button, Stack, Heading, Paragraph } from '@qasa/qds-ui'
import type { ProfileQueryQuery } from '@qasa/graphql'

import type { ViewItemType } from '../profile-badges'
import { getBadges } from '../profile-badges'

export function VerificationSection({ profile }: { profile: NonNullable<ProfileQueryQuery['user']> }) {
  const badges = getBadges(profile)
  return (
    <Stack gap="6x">
      {badges?.map((badge: ViewItemType) => {
        const badgeButtonVariant = badge.badgeName === 'id' ? 'primary' : 'secondary'

        if (badge.shouldHideWhenDisabled && !badge.isEnabled) return null

        return (
          <Stack alignItems="flex-start" key={badge.badgeName}>
            <Heading size="sm">{badge.isEnabled ? badge.enabledTitle : badge.disabledTitle}</Heading>
            <Spacer size="1x" />
            <Paragraph>{badge.description}</Paragraph>
            <Spacer size="4x" />
            {!badge.isEnabled && badge.ActionComponent && (
              <badge.ActionComponent profile={profile}>
                <Button type="button" variant={badgeButtonVariant}>
                  {badge.actionTextPrimary}
                </Button>
              </badge.ActionComponent>
            )}
          </Stack>
        )
      })}
    </Stack>
  )
}
