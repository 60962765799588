import { Stack, RadioGroup } from '@qasa/qds-ui'
import { Fragment } from 'react'

import { VisuallyHidden } from '../visually-hidden'

type BooleanString = 'true' | 'false'
type BooleanInputProps = {
  /**
   * The `name` attribute forwarded to the `radio` elements
   */
  name: string
  value: boolean | null
  /**
   * Label for when the current value is false
   */
  negativeLabel: string
  /**
   * Label for when the current value is true
   */
  positiveLabel: string
  onChange: (newValue: boolean) => void
  label: string
  isLabelVisuallyHidden?: boolean
}

export function BooleanInput({
  label,
  isLabelVisuallyHidden,
  name,
  value,
  onChange,
  negativeLabel,
  positiveLabel,
}: BooleanInputProps) {
  const stringifiedValue = value !== null ? (value.toString() as BooleanString) : ''
  const handleChange = (value: string) => {
    onChange(value === 'true')
  }

  const LabelWrapper = isLabelVisuallyHidden ? VisuallyHidden : Fragment
  return (
    <RadioGroup onValueChange={handleChange} name={name} value={stringifiedValue}>
      <LabelWrapper>
        <RadioGroup.Label>{label}</RadioGroup.Label>
      </LabelWrapper>
      <Stack gap="2x">
        <RadioGroup.Card value="true" label={positiveLabel} />
        <RadioGroup.Card value="false" label={negativeLabel} />
      </Stack>
    </RadioGroup>
  )
}
