import styled from '@emotion/styled'
import { Heading } from '@qasa/qds-ui'
import { EditDialog } from '@qasa/app/src/modules/edit-dialog'

const SectionTitle = Heading
const SectionHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

type Props = {
  isOwnProfile: boolean
  title?: string
  onEditButtonClick: () => void
}
export function ProfileSectionHeader({ isOwnProfile, title, onEditButtonClick }: Props) {
  return (
    <SectionHeader>
      {title && <SectionTitle>{title}</SectionTitle>}
      {isOwnProfile && <EditDialog.Trigger onClick={onEditButtonClick} />}
    </SectionHeader>
  )
}
