import { Button, Heading, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { type OccupationFormStepsProps } from '../occupation-form'
import { useOccupationFields } from '../fields/use-occupation-fields'
import { UserDataVisibilityHintbox } from '../../../ui-shared/user-data-visibility-hintbox'
import { DialogAndWizardActionsFooter } from '../../../ui-shared/dialog-and-wizard-actions-footer'

export function OccupationDetailsStep({
  goNext,
  goBack,
  isLoadingNext,
  occupationType,
  occupationToEdit,
  shouldShowDataVisibilityInfo = false,
}: OccupationFormStepsProps & { shouldShowDataVisibilityInfo?: boolean }) {
  const { t } = useTranslation('edit_profile')
  const occupationFields = useOccupationFields({ occupationType })

  return (
    <Stack gap="8x">
      <Heading size="lg">
        {occupationToEdit
          ? t('form.occupation.edit_occupation')
          : t(`form.occupation.titles.${occupationType}`)}
      </Heading>
      <Stack gap="5x">
        {occupationFields.map((OccupationField, index) => (
          <OccupationField key={index} />
        ))}
      </Stack>
      {shouldShowDataVisibilityInfo && <UserDataVisibilityHintbox mode="public" />}
      <DialogAndWizardActionsFooter>
        <Button type="button" variant="tertiary" onClick={goBack} isDisabled={isLoadingNext}>
          {t('commons:back')}
        </Button>
        <Button type="button" onClick={goNext} isLoading={isLoadingNext}>
          {t('commons:next')}
        </Button>
      </DialogAndWizardActionsFooter>
    </Stack>
  )
}
