import { createIcon } from '@qasa/qds-ui'
import { TraitTypeEnum } from '@qasa/graphql'

import {
  BalconyIcon,
  DishWasherIcon,
  PetsIcon,
  SaunaIcon,
  SmokerIcon,
  WashingMachineIcon,
  WheelchairIcon,
} from '../../profile/amenity-icons'

import type { AmenityGroupType } from './types'
import { AmenityGroupTitleEnum } from './types'

const TvIcon = createIcon({
  displayName: 'TvIcon',
  viewBox: '0 0 512 512',
  d: 'M169 7c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l55 55H80C35.8 96 0 131.8 0 176V432c0 44.2 35.8 80 80 80H432c44.2 0 80-35.8 80-80V176c0-44.2-35.8-80-80-80H321.9l55-55c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-87 87L169 7zM424 232a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm24 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48zM64 224c0-35.3 28.7-64 64-64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H128c-35.3 0-64-28.7-64-64V224z',
})

const InternetIcon = createIcon({
  displayName: 'InternetIcon',
  viewBox: '0 0 640 512',
  d: 'M54.2 202.9C123.2 136.7 216.8 96 320 96s196.8 40.7 265.8 106.9c12.8 12.2 33 11.8 45.2-.9s11.8-33-.9-45.2C549.7 79.5 440.4 32 320 32S90.3 79.5 9.8 156.7C-2.9 169-3.3 189.2 8.9 202s32.5 13.2 45.2 .9zM320 256c56.8 0 108.6 21.1 148.2 56c13.3 11.7 33.5 10.4 45.2-2.8s10.4-33.5-2.8-45.2C459.8 219.2 393 192 320 192s-139.8 27.2-190.5 72c-13.3 11.7-14.5 31.9-2.8 45.2s31.9 14.5 45.2 2.8c39.5-34.9 91.3-56 148.2-56zm64 160a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z',
})

const GrillIcon = createIcon({
  displayName: 'GrillIcon',
  viewBox: '0 0 512 512',
  d: 'M61.1 224C45 224 32 211 32 194.9c0-1.9 .2-3.7 .6-5.6C37.9 168.3 78.8 32 256 32s218.1 136.3 223.4 157.3c.5 1.9 .6 3.7 .6 5.6c0 16.1-13 29.1-29.1 29.1H61.1zM144 128a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zm240 16a16 16 0 1 0 0-32 16 16 0 1 0 0 32zM272 96a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zM16 304c0-26.5 21.5-48 48-48H448c26.5 0 48 21.5 48 48s-21.5 48-48 48H64c-26.5 0-48-21.5-48-48zm16 96c0-8.8 7.2-16 16-16H464c8.8 0 16 7.2 16 16v16c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V400z',
})

const GardenIcon = createIcon({
  displayName: 'GardenIcon',
  viewBox: '0 0 448 512',
  d: 'M210.6 5.9L62 169.4c-3.9 4.2-6 9.8-6 15.5C56 197.7 66.3 208 79.1 208H104L30.6 281.4c-4.2 4.2-6.6 10-6.6 16C24 309.9 34.1 320 46.6 320H80L5.4 409.5C1.9 413.7 0 419 0 424.5c0 13 10.5 23.5 23.5 23.5H192v32c0 17.7 14.3 32 32 32s32-14.3 32-32V448H424.5c13 0 23.5-10.5 23.5-23.5c0-5.5-1.9-10.8-5.4-15L368 320h33.4c12.5 0 22.6-10.1 22.6-22.6c0-6-2.4-11.8-6.6-16L344 208h24.9c12.7 0 23.1-10.3 23.1-23.1c0-5.7-2.1-11.3-6-15.5L237.4 5.9C234 2.1 229.1 0 224 0s-10 2.1-13.4 5.9z',
})

const ParkingIcon = createIcon({
  displayName: 'ParkingIcon',
  viewBox: '0 0 640 512',
  d: 'M171.3 96H224v96H111.3l30.4-75.9C146.5 104 158.2 96 171.3 96zM272 192V96h81.2c9.7 0 18.9 4.4 25 12l67.2 84H272zm256.2 1L428.2 68c-18.2-22.8-45.8-36-75-36H171.3c-39.3 0-74.6 23.9-89.1 60.3L40.6 196.4C16.8 205.8 0 228.9 0 256V368c0 17.7 14.3 32 32 32H65.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80H385.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80H608c17.7 0 32-14.3 32-32V320c0-65.2-48.8-119-111.8-127zM434.7 368a48 48 0 1 1 90.5 32 48 48 0 1 1 -90.5-32zM160 336a48 48 0 1 1 0 96 48 48 0 1 1 0-96z',
})

const ElectricCarChargingIcon = createIcon({
  displayName: 'ElectricCarChargingIcon',
  viewBox: '0 0 576 512',
  d: 'M96 0C60.7 0 32 28.7 32 64V448c-17.7 0-32 14.3-32 32s14.3 32 32 32H320c17.7 0 32-14.3 32-32s-14.3-32-32-32V304h16c22.1 0 40 17.9 40 40v32c0 39.8 32.2 72 72 72s72-32.2 72-72V252.3c32.5-10.2 56-40.5 56-76.3V144c0-8.8-7.2-16-16-16H544V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H480V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H432c-8.8 0-16 7.2-16 16v32c0 35.8 23.5 66.1 56 76.3V376c0 13.3-10.7 24-24 24s-24-10.7-24-24V344c0-48.6-39.4-88-88-88H320V64c0-35.3-28.7-64-64-64H96zM216.9 82.7c6 4 8.5 11.5 6.3 18.3l-25 74.9H256c6.7 0 12.7 4.2 15 10.4s.5 13.3-4.6 17.7l-112 96c-5.5 4.7-13.4 5.1-19.3 1.1s-8.5-11.5-6.3-18.3l25-74.9H96c-6.7 0-12.7-4.2-15-10.4s-.5-13.3 4.6-17.7l112-96c5.5-4.7 13.4-5.1 19.3-1.1z',
})

const BikesIcon = createIcon({
  displayName: 'BikesIcon',
  viewBox: '0 0 640 512',
  d: 'M312 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h25.7l34.6 64H222.9l-27.4-38C191 99.7 183.7 96 176 96H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h43.7l22.1 30.7-26.6 53.1c-10-2.5-20.5-3.8-31.2-3.8C57.3 224 0 281.3 0 352s57.3 128 128 128c65.3 0 119.1-48.9 127-112h49c8.5 0 16.3-4.5 20.7-11.8l84.8-143.5 21.7 40.1C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L375.4 48.8C369.8 38.4 359 32 347.2 32H312zM458.6 303.7l32.3 59.7c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-32.3-59.7c3.6-.6 7.4-.9 11.2-.9c39.8 0 72 32.2 72 72s-32.2 72-72 72s-72-32.2-72-72c0-18.6 7-35.5 18.6-48.3zM133.2 368h65c-7.3 32.1-36 56-70.2 56c-39.8 0-72-32.2-72-72s32.2-72 72-72c1.7 0 3.4 .1 5.1 .2l-24.2 48.5c-9 18.1 4.1 39.4 24.3 39.4zm33.7-48l50.7-101.3 72.9 101.2-.1 .1H166.8zm90.6-128H365.9L317 274.8 257.4 192z',
})

export const JacuzziIcon = createIcon({
  displayName: 'JacuzziIcon',
  viewBox: '0 0 512 512',
  d: 'M272 24c0-13.3-10.7-24-24-24s-24 10.7-24 24v5.2c0 34 14.4 66.4 39.7 89.2l16.4 14.8c15.2 13.7 23.8 33.1 23.8 53.5V200c0 13.3 10.7 24 24 24s24-10.7 24-24V186.8c0-34-14.4-66.4-39.7-89.2L295.8 82.8C280.7 69.1 272 49.7 272 29.2V24zM0 320v16V448c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V320c0-35.3-28.7-64-64-64H277.3c-13.8 0-27.3-4.5-38.4-12.8l-85.3-64C137 166.7 116.8 160 96 160c-53 0-96 43-96 96v64zm128 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V336c0-8.8 7.2-16 16-16s16 7.2 16 16zm80-16c8.8 0 16 7.2 16 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V336c0-8.8 7.2-16 16-16zm112 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V336c0-8.8 7.2-16 16-16s16 7.2 16 16zm80-16c8.8 0 16 7.2 16 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V336c0-8.8 7.2-16 16-16zM360 0c-13.3 0-24 10.7-24 24v5.2c0 34 14.4 66.4 39.7 89.2l16.4 14.8c15.2 13.7 23.8 33.1 23.8 53.5V200c0 13.3 10.7 24 24 24s24-10.7 24-24V186.8c0-34-14.4-66.4-39.7-89.2L407.8 82.8C392.7 69.1 384 49.7 384 29.2V24c0-13.3-10.7-24-24-24zM64 128A64 64 0 1 0 64 0a64 64 0 1 0 0 128z',
})

const BoatKayakIcon = createIcon({
  displayName: 'BoatKayakIcon',
  viewBox: '0 0 576 512',
  d: 'M320 96a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zm21.1 80C367 158.8 384 129.4 384 96c0-53-43-96-96-96s-96 43-96 96c0 33.4 17 62.8 42.9 80H224c-17.7 0-32 14.3-32 32s14.3 32 32 32h32V448H208c-53 0-96-43-96-96v-6.1l7 7c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L97 263c-9.4-9.4-24.6-9.4-33.9 0L7 319c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l7-7V352c0 88.4 71.6 160 160 160h80 80c88.4 0 160-71.6 160-160v-6.1l7 7c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-56-56c-9.4-9.4-24.6-9.4-33.9 0l-56 56c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l7-7V352c0 53-43 96-96 96H320V240h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H341.1z',
})

export const FirePlaceIcon = createIcon({
  displayName: 'FirePlaceIcon',
  viewBox: '0 0 640 512',
  d: 'M0 32V64C0 81.7 14.3 96 32 96H608c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H32C14.3 0 0 14.3 0 32zm608 96H32V480c0 17.7 14.3 32 32 32h96V352c0-88.4 71.6-160 160-160s160 71.6 160 160V512h96c17.7 0 32-14.3 32-32V128zM300.6 258.7c-2-1.8-4.6-2.7-7.1-2.7c-2.6 0-5.2 .9-7.3 2.7c-24.2 21.6-44.7 46.1-59.2 69.7c-14.3 23.2-23.6 48.2-23.6 67.4c0 63.8 51.1 116.3 116.4 116.3c64.5 0 116.4-52.4 116.4-116.3c0-14.7-7-37-17.6-57.4c-10.7-20.7-25.9-42.5-43.9-59c-4.1-3.7-10.4-3.8-14.5 0c-7 6.4-13.5 14.3-19.2 21.5c-12.6-15.1-26-29-40.4-42zm55.6 195.8c-10.9 7.3-22.5 10.9-35.6 10.9c-32.7 0-58.9-21.1-58.9-56.7c0-17.5 10.9-32.7 32.7-59.6c2.9 3.6 45.1 57.5 45.1 57.5L365.7 376c2.2 2.9 3.6 5.8 5.1 8.7c13.1 24 7.3 54.5-14.5 69.8z',
})

export const PoolIcon = createIcon({
  displayName: 'PoolIcon',
  viewBox: '0 0 576 512',
  d: 'M128 127.7C128 74.9 170.9 32 223.7 32c48.3 0 89 36 95 83.9l1 8.2c2.2 17.5-10.2 33.5-27.8 35.7s-33.5-10.2-35.7-27.8l-1-8.2c-2-15.9-15.5-27.8-31.5-27.8c-17.5 0-31.7 14.2-31.7 31.7V224H384V127.7C384 74.9 426.9 32 479.7 32c48.3 0 89 36 95 83.9l1 8.2c2.2 17.5-10.2 33.5-27.8 35.7s-33.5-10.2-35.7-27.8l-1-8.2c-2-15.9-15.5-27.8-31.5-27.8c-17.5 0-31.7 14.2-31.7 31.7V361c-1.6 1-3.3 2-4.8 3.1c-18 12.4-40.1 20.3-59.2 20.3h0V288H192v96.5c-19 0-41.2-7.9-59.1-20.3c-1.6-1.1-3.2-2.2-4.9-3.1V127.7zM306.5 389.9C329 405.4 356.5 416 384 416c26.9 0 55.4-10.8 77.4-26.1l0 0c11.9-8.5 28.1-7.8 39.2 1.7c14.4 11.9 32.5 21 50.6 25.2c17.2 4 27.9 21.2 23.9 38.4s-21.2 27.9-38.4 23.9c-24.5-5.7-44.9-16.5-58.2-25C449.5 469.7 417 480 384 480c-31.9 0-60.6-9.9-80.4-18.9c-5.8-2.7-11.1-5.3-15.6-7.7c-4.5 2.4-9.7 5.1-15.6 7.7c-19.8 9-48.5 18.9-80.4 18.9c-33 0-65.5-10.3-94.5-25.8c-13.4 8.4-33.7 19.3-58.2 25c-17.2 4-34.4-6.7-38.4-23.9s6.7-34.4 23.9-38.4c18.1-4.2 36.2-13.3 50.6-25.2c11.1-9.4 27.3-10.1 39.2-1.7l0 0C136.7 405.2 165.1 416 192 416c27.5 0 55-10.6 77.5-26.1c11.1-7.9 25.9-7.9 37 0z',
})

export const VACATION_TRAITS: AmenityGroupType[] = [
  {
    title: AmenityGroupTitleEnum.POPULAR,
    traits: [
      {
        trait: TraitTypeEnum.dish_washer,
        icon: <DishWasherIcon size={20} />,
      },
      {
        trait: TraitTypeEnum.washing_machine,
        icon: <WashingMachineIcon size={20} />,
      },
      {
        trait: TraitTypeEnum.television,
        icon: <TvIcon size={20} />,
      },
      {
        trait: TraitTypeEnum.internet,
        icon: <InternetIcon size={20} />,
      },
      {
        trait: TraitTypeEnum.grill,
        icon: <GrillIcon size={20} />,
      },
      {
        trait: TraitTypeEnum.garden,
        icon: <GardenIcon size={20} />,
      },
      {
        trait: TraitTypeEnum.parking_included,
        icon: <ParkingIcon size={20} />,
      },
      {
        trait: TraitTypeEnum.electric_car_charger,
        icon: <ElectricCarChargingIcon size={20} />,
      },
    ],
  },
  {
    title: AmenityGroupTitleEnum.EXTRA_VACATION,
    traits: [
      {
        trait: TraitTypeEnum.balcony,
        icon: <BalconyIcon size={20} />,
      },
      {
        trait: TraitTypeEnum.inhome_sauna,
        icon: <SaunaIcon size={20} />,
      },
      {
        trait: TraitTypeEnum.jacuzzi,
        icon: <JacuzziIcon size={20} />,
      },
      {
        trait: TraitTypeEnum.boat_kayak,
        icon: <BoatKayakIcon size={20} />,
      },
      {
        trait: TraitTypeEnum.bikes,
        icon: <BikesIcon size={20} />,
      },
      {
        trait: TraitTypeEnum.indoor_fireplace,
        icon: <FirePlaceIcon size={20} />,
      },
      {
        trait: TraitTypeEnum.pool,
        icon: <PoolIcon size={20} />,
      },
    ],
  },
  {
    title: AmenityGroupTitleEnum.RULES,
    traits: [
      {
        trait: TraitTypeEnum.pets,
        icon: <PetsIcon size={20} />,
      },
      { trait: TraitTypeEnum.smoker, icon: <SmokerIcon size={20} /> },
    ],
  },
  {
    title: AmenityGroupTitleEnum.KITCHEN,
    traits: [
      {
        trait: TraitTypeEnum.fridge,
      },
      {
        trait: TraitTypeEnum.freezer,
      },
      {
        trait: TraitTypeEnum.oven,
      },
      {
        trait: TraitTypeEnum.stove,
      },
      {
        trait: TraitTypeEnum.coffee_brewer,
      },
      {
        trait: TraitTypeEnum.microwave_oven,
      },
      {
        trait: TraitTypeEnum.kettle,
      },
      {
        trait: TraitTypeEnum.kitchen_appliances,
      },
      {
        trait: TraitTypeEnum.porcelain_glasses_cutlery,
      },
    ],
  },
  {
    title: AmenityGroupTitleEnum.BATHING,
    traits: [
      {
        trait: TraitTypeEnum.toilet,
      },
      {
        trait: TraitTypeEnum.shower,
      },
      {
        trait: TraitTypeEnum.bathtub,
      },
      {
        trait: TraitTypeEnum.shower_supplies,
      },
      {
        trait: TraitTypeEnum.outhouse,
      },
      {
        trait: TraitTypeEnum.outdoor_shower,
      },
      {
        trait: TraitTypeEnum.towels,
      },
      {
        trait: TraitTypeEnum.hair_dryer,
      },
    ],
  },
  {
    title: AmenityGroupTitleEnum.WASHING,
    traits: [
      {
        trait: TraitTypeEnum.tumble_dryer,
      },
      {
        trait: TraitTypeEnum.iron,
      },
      {
        trait: TraitTypeEnum.shared_washing_area,
      },
    ],
  },
  {
    title: AmenityGroupTitleEnum.BEDROOM,
    traits: [
      {
        trait: TraitTypeEnum.bed_sheets,
      },
      {
        trait: TraitTypeEnum.extra_blankets_and_pillows,
      },
    ],
  },
  {
    title: AmenityGroupTitleEnum.FAMILY,
    traits: [
      {
        trait: TraitTypeEnum.high_chair,
      },
      {
        trait: TraitTypeEnum.cot,
      },
    ],
  },
  {
    title: AmenityGroupTitleEnum.TECHNOLOGY,
    traits: [
      {
        trait: TraitTypeEnum.sound_system,
      },
    ],
  },
  {
    title: AmenityGroupTitleEnum.OUTDOORS,
    traits: [
      {
        trait: TraitTypeEnum.outdoor_furniture,
      },
      {
        trait: TraitTypeEnum.grill_essentials,
      },
    ],
  },
  {
    title: AmenityGroupTitleEnum.PARKING_FACILITIES,
    traits: [
      {
        trait: TraitTypeEnum.bike_room,
      },
      {
        trait: TraitTypeEnum.elevator,
      },
      {
        trait: TraitTypeEnum.recycling,
      },
      { trait: TraitTypeEnum.security_door },
    ],
  },
  {
    title: AmenityGroupTitleEnum.SAFETY,
    traits: [
      {
        trait: TraitTypeEnum.first_aid_kit,
      },
      {
        trait: TraitTypeEnum.fire_extinguisher,
      },
      {
        trait: TraitTypeEnum.smoke_alarm,
      },
    ],
  },
  {
    title: AmenityGroupTitleEnum.ACCESSIBILITY,
    traits: [
      {
        trait: TraitTypeEnum.wheelchair_accessible,
        icon: <WheelchairIcon size={20} />,
      },
    ],
  },
]
