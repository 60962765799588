import { useTranslation } from 'react-i18next'
import { HintBox } from '@qasa/qds-ui'
import { HomeTypeEnum } from '@qasa/graphql'

import { FormRadioGroup } from '../../../ui-shared/forms/form-radio-group'
import {
  ProfileContractTypeEnum,
  ProfileFurnishedEnum,
  ProfileSharedHomeEnum,
} from '../../profile/profile.types'
import { FieldWithHint } from '../components/field-with-hint'
import { Form } from '../components/form'
import { StepNavigation } from '../components/step-navigation'
import { WizardStepWrapper } from '../components/step-wrapper'
import { requiredFormField } from '../create-tenant-listing.utils'
import { FormCheckboxGroup } from '../fields/form-checkbox-group'
import { useWizardRouterContext } from '../wizard-router/wizard-router'
const HOME_TYPES = [
  HomeTypeEnum.apartment,
  HomeTypeEnum.house,
  HomeTypeEnum.terrace_house,
  HomeTypeEnum.duplex,
  HomeTypeEnum.other,
]
export function HomeTypeStep() {
  const { goNext } = useWizardRouterContext()
  const { t } = useTranslation('create_tenant_listing')
  const homeTypeOptions = HOME_TYPES.map((homeType) => {
    return { value: homeType, label: t(`fields.home_type.checkbox_options.${homeType}`) }
  })

  const sharedOptions = Object.values(ProfileSharedHomeEnum).map((value) => ({
    value,
    label: t(`fields.shared_home.${value}`),
  }))

  const furnitureOptions = Object.values(ProfileFurnishedEnum).map((value) => ({
    value,
    label: t(`fields.furniture.${value}`),
  }))

  const contractTypesOptions = Object.values(ProfileContractTypeEnum).map((value) => ({
    value,
    label: t(`fields.contract_types.${value}`),
  }))

  return (
    <WizardStepWrapper>
      <Form dataKey="home-type" onSubmit={goNext}>
        {({ control, formState: { errors } }) => (
          <>
            <FieldWithHint>
              <FormRadioGroup
                label={t('fields.shared_home.label')}
                name={'shared'}
                options={sharedOptions}
                control={control}
              />
              <HintBox>{t('fields.shared_home.hint_box')}</HintBox>
            </FieldWithHint>
            <FormRadioGroup
              label={t('fields.furniture.label')}
              name={'furniture'}
              options={furnitureOptions}
              control={control}
            />
            <FieldWithHint>
              <FormCheckboxGroup
                label={t('fields.home_type.label')}
                control={control}
                options={homeTypeOptions}
                name={'homeType'}
                rules={requiredFormField}
                errorMessage={errors['homeType'] && t('fields.home_type.required_error')}
              />
              <HintBox>{t('fields.home_type.hint_box')}</HintBox>
            </FieldWithHint>
            {/** Contract types are temporary until decided where it should be located */}
            <FieldWithHint>
              <FormRadioGroup
                label={t('fields.contract_types.label')}
                name={'contractType'}
                options={contractTypesOptions}
                control={control}
              />
              <HintBox>{t('fields.contract_types.hint_box')}</HintBox>
            </FieldWithHint>

            <StepNavigation />
          </>
        )}
      </Form>
    </WizardStepWrapper>
  )
}
