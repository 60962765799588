import type { ButtonProps as QdsProps } from '@qasa/qds-ui'
import { Button as QdsButton } from '@qasa/qds-ui'
import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'

type ButtonProps = Omit<QdsProps, 'onClick' | 'disabled'> & {
  onPress?: QdsProps['onClick']
}
export const Button = forwardRef(
  ({ onPress, ...rest }: ButtonProps, forwardedRef?: ForwardedRef<HTMLButtonElement>) => {
    return <QdsButton ref={forwardedRef} onClick={onPress} {...rest} />
  },
)
