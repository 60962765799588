import type { Region } from '../contexts/region'
import { getEnv } from '../env'

export type Toggles = {
  /**
   * Indicates if the brand should use the Schibsted SSO solution to handle auth.
   * Basic login with email & password will be used otherwise
   */
  availableLoginMethods: ('schibsted_se' | 'schibsted_fi' | 'qasa' | 'one_time_password')[]
  /**
   * Indicates if the brand should use the Schibsted Sourcepoint client to handle consent cookies.
   * (https://docs.schibsted.io/cmp/web/scc/)
   */
  usesSchibstedSourcepointClient: boolean
  /**
   * Hides or shows the right side panel with links to articles in the zendesk help center
   */
  faqPanelEnabled: boolean
  /**
   * Hides or shows the statistics section in the profile view.
   * The statistics section show info about the behavior of the user in the platform
   * i.e. applications sent, landlords contacted, days since account was created, etc
   */
  profileStatisticsSectionEnabled: boolean
  /**
   * According to this toggle, we will show the notifications icon and menu in the top-bar and poll the backend for new events.
   */
  notificationsEnabled: boolean
  /**
   * Indicates wether the platform supports first hand rentals
   */
  hasFirstHandRentals: boolean
  /**
   * Indicates whether the platform uses pro-pilot verifications
   * (income/student/employment verification) for tenants
   */
  isProPilotVerificationsEnabled: boolean
  /**
   * Indicates if controllers of data is enabled.
   */
  isPrivacyControllersEnabled: boolean
  /**
   * Indicates if verification block in profile settings is enabled.
   */
  isVerificationBlockEnabled: boolean
  /**
   * Indicates if the audit and insurance pages after home published is enabled.
   */
  hasHomePublishedFlowEnabled: boolean
  /**
   * Indicates if the checkout widget flow is enabled.
   */
  isCheckoutEnabled: boolean
  /**
   * Indicates if account block in profile settings is enabled, allowing the
   * user to edit their role (tenant vs. landlord) and account type (private vs. business).
   */
  isAccountBlockEnabled: boolean
  /**
   * Indicates if the affiliate code block in profile settings is enabled.
   */
  isAffiliateCodeBlockEnabled: boolean
  /**
   * Indicates if the notification block in profile settings is enabled.
   */
  isNotificationBlockEnabled: boolean
  /**
   * If the new financing system is enabled.
   */
  hasNewFinancingApi: boolean
  /**
   * Indicates which bank account provider is available.
   */
  bankAccountProvider: 'tink' | 'stripe'
  /**
   * Indicates if shared documents space in contract dashboard is available.
   */
  isSharedContractDocumentsEnabled: boolean
  /**
   * Indicates OTP is enabled, allowing users to login with email only.
   */
  hasOneTimePasswordEnabled: boolean
  /**
   * Indicates if the user's registration flow included agreeing to our terms. If not we might need to show terms in
   * other parts of flow.
   */
  hasAgreedToQasaTermsOnSignup: boolean
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const isBlocket = getEnv('BRAND_NAME') === 'blocket'

export const TOGGLES: Record<Region, Toggles> = {
  se: {
    availableLoginMethods: isBlocket ? ['schibsted_se'] : ['qasa'],
    usesSchibstedSourcepointClient: isBlocket,
    faqPanelEnabled: true,
    profileStatisticsSectionEnabled: true,
    notificationsEnabled: true,
    hasFirstHandRentals: true,
    isProPilotVerificationsEnabled: true,
    isVerificationBlockEnabled: true,
    isPrivacyControllersEnabled: false,
    hasHomePublishedFlowEnabled: true,
    isCheckoutEnabled: false,
    isAccountBlockEnabled: true,
    isAffiliateCodeBlockEnabled: true,
    isNotificationBlockEnabled: true,
    hasNewFinancingApi: false,
    bankAccountProvider: 'tink',
    isSharedContractDocumentsEnabled: false,
    hasOneTimePasswordEnabled: false,
    hasAgreedToQasaTermsOnSignup: !isBlocket,
  },
  fi: {
    availableLoginMethods: ['schibsted_fi'],
    usesSchibstedSourcepointClient: true,
    faqPanelEnabled: true,
    profileStatisticsSectionEnabled: true,
    notificationsEnabled: true,
    hasFirstHandRentals: false,
    isProPilotVerificationsEnabled: false,
    isVerificationBlockEnabled: false,
    isPrivacyControllersEnabled: true,
    hasHomePublishedFlowEnabled: false,
    isCheckoutEnabled: false,
    isAccountBlockEnabled: true,
    isAffiliateCodeBlockEnabled: true,
    isNotificationBlockEnabled: true,
    hasNewFinancingApi: false,
    bankAccountProvider: 'tink',
    isSharedContractDocumentsEnabled: false,
    hasOneTimePasswordEnabled: false,
    hasAgreedToQasaTermsOnSignup: true,
  },
  fr: {
    availableLoginMethods: ['qasa', 'one_time_password'],
    usesSchibstedSourcepointClient: false,
    faqPanelEnabled: false,
    profileStatisticsSectionEnabled: false,
    notificationsEnabled: true,
    hasFirstHandRentals: false,
    isProPilotVerificationsEnabled: false,
    isVerificationBlockEnabled: false,
    isPrivacyControllersEnabled: false,
    hasHomePublishedFlowEnabled: false,
    isCheckoutEnabled: true,
    isAccountBlockEnabled: false,
    isAffiliateCodeBlockEnabled: false,
    isNotificationBlockEnabled: false,
    hasNewFinancingApi: true,
    bankAccountProvider: 'stripe',
    isSharedContractDocumentsEnabled: true,
    hasOneTimePasswordEnabled: true,
    hasAgreedToQasaTermsOnSignup: true,
  },
  international: {
    availableLoginMethods: ['qasa', 'schibsted_se', 'schibsted_fi'],
    usesSchibstedSourcepointClient: false,
    faqPanelEnabled: true,
    profileStatisticsSectionEnabled: true,
    notificationsEnabled: true,
    hasFirstHandRentals: true,
    isProPilotVerificationsEnabled: false,
    isVerificationBlockEnabled: true,
    isPrivacyControllersEnabled: false,
    hasHomePublishedFlowEnabled: true,
    isCheckoutEnabled: false,
    isAccountBlockEnabled: true,
    isAffiliateCodeBlockEnabled: true,
    isNotificationBlockEnabled: true,
    hasNewFinancingApi: false,
    bankAccountProvider: 'tink',
    isSharedContractDocumentsEnabled: false,
    hasOneTimePasswordEnabled: false,
    hasAgreedToQasaTermsOnSignup: true,
  },
}
