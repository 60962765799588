import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import type { AvatarProps } from '@qasa/qds-ui'
import { TrashIcon, Avatar, IconButton, PlusIcon, LoadingDots } from '@qasa/qds-ui'
import { useToastContext } from '@qasa/app'
import type { MeQueryQuery } from '@qasa/graphql'
import { OwnerTypeEnum, UploadTypeEnum } from '@qasa/graphql'

import { FileUpload } from '../../ui-shared/_core/file-upload'
import { useUploadImage } from '../../hooks/use-upload-image'
import { DESTROY_UPLOAD } from '../../data/graphql/mutations/destroy-upload'
import { ME } from '../../data/graphql/queries'
import { useAuthContext } from '../../context/auth-context'
import { prependSrcWithThumbor } from '../../helpers/image'

const Wrapper = styled.div({ position: 'relative' })
const IconButtonContainer = styled.div({
  position: 'absolute',
  bottom: 0,
  right: 0,
})

const StyledLoader = styled(LoadingDots)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  background: theme.colors.core.black,
  color: theme.colors.core.white,
  padding: theme.spacing['2x'],
  borderRadius: theme.radii.lg,
  fontSize: 6,
}))

type ProfilePictureUploadParams = {
  profilePicture: UNSAFE_DeepNonNullableObject<MeQueryQuery>['me']['profilePicture'] | null
  userId: string
  size?: AvatarProps['size']
  className?: string
}
export function ProfilePictureUpload({
  profilePicture,
  userId,
  size = '2xl',
  className,
}: ProfilePictureUploadParams) {
  const { t } = useTranslation('profile_picture_upload')
  const { addToast } = useToastContext()
  const { refreshAuthBody, authBody } = useAuthContext()
  const handleError = () => {
    addToast({ message: t('unexpected_error', { ns: 'commons' }) })
  }
  const [uploadProfilePicture, { isLoading: isUploading }] = useUploadImage({
    ownerType: OwnerTypeEnum.USER,
    uploadType: UploadTypeEnum.profile_picture,
    ownerId: userId,
    refetchQueries: [{ query: ME }],
  })
  const [deleteUpload, { loading: isDeleting }] = useMutation(DESTROY_UPLOAD, {
    refetchQueries: [{ query: ME }],
    awaitRefetchQueries: true,
    onError: handleError,
    onCompleted: refreshAuthBody,
  })

  const isLoading = isUploading || isDeleting

  const handleUploadEnd = async (urls?: string[]) => {
    if (urls) {
      refreshAuthBody()
    } else {
      handleError()
    }
  }
  const handleDelete = () => {
    if (profilePicture) {
      deleteUpload({ variables: { id: profilePicture.id } })
    }
  }

  const isOwnProfile = authBody?.uid === userId

  const profilePictureUrl = profilePicture?.url
    ? prependSrcWithThumbor({
        src: profilePicture.url,
        // a bit arbitrary but it's a small image, yet we don't want too low quality
        width: 252,
        height: 252,
        fittingMethod: 'fit-in',
      })
    : undefined

  if (!isOwnProfile) {
    return <Avatar className={className} src={profilePictureUrl} size={size} />
  }

  return (
    <Wrapper>
      {/* TODO: fix a proper accessible label for the file input */}
      <FileUpload
        accept="image/*"
        onUploadEnd={handleUploadEnd}
        uploadApi={uploadProfilePicture}
        disabled={isLoading}
        isLoading={isLoading}
      >
        <Avatar className={className} src={profilePictureUrl} size={size} />
        {isLoading && <StyledLoader size="sm" />}

        {!isLoading && !profilePicture && (
          <IconButtonContainer>
            <IconButton
              label={t('label', { context: 'upload' })}
              size="xs"
              variant="secondary"
              icon={PlusIcon}
            />
          </IconButtonContainer>
        )}
      </FileUpload>
      {!isLoading && profilePicture && (
        <IconButtonContainer>
          <IconButton
            label={t('label', { context: 'delete' })}
            size="xs"
            variant="tertiary"
            icon={TrashIcon}
            onClick={handleDelete}
          />
        </IconButtonContainer>
      )}
    </Wrapper>
  )
}
