import { MapPinIcon, Paragraph, Spacer, Stack, styled } from '@qasa/ui'

import { useAppTranslation } from '../../../contexts/i18next'
import { useRegionContext } from '../../../contexts/region'

import { SearchHistoryLink } from './search-history-link'
import { SearchHistoryHeading } from './search-history.parts'
import type { SearchHistoryProps } from './search-history'

const RecommendedSearchLink = styled(SearchHistoryLink)(({ theme }) => ({
  minHeight: theme.sizes['12x'],
}))

export function RecommendedSearches({ onListItemPress }: SearchHistoryProps) {
  const { t } = useAppTranslation('search_history')
  const { region } = useRegionContext()

  const cities = {
    se: [
      'Stockholm',
      t('places.gothenburg'),
      'Malmö',
      'Uppsala',
      'Västerås',
      'Örebro',
      'Linköping',
      'Lund',
      'Borås',
      'Norrköping',
    ],
    fi: [
      'Helsinki',
      'Oulu',
      'Tampere',
      'Turku',
      'Jyväskylä',
      'Vantaa',
      'Joensuu',
      'Kuopio',
      'Espoo',
      'Uusimaa',
    ],
    fr: [],
    international: [],
  }

  if (!cities[region].length) {
    return null
  }
  return (
    <Stack>
      <SearchHistoryHeading>{t('recommended_searches.title')}</SearchHistoryHeading>
      <Spacer size="4x" />
      {cities[region].map((city) => (
        <RecommendedSearchLink
          key={city}
          onPress={onListItemPress}
          filterValues={{ searchAreas: [{ name: city, countryCode: region }] }}
        >
          {/* TODO: Should we have strokeWidth here */}
          <MapPinIcon size={16} style={{ flexShrink: 0 }} />
          <Paragraph asWebElement="span">{city}</Paragraph>
        </RecommendedSearchLink>
      ))}
    </Stack>
  )
}
