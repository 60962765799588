import styled from '@emotion/styled'
import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from '@qasa/app/src/components/dialog'
import { AlertTriangleIcon, Stack } from '@qasa/qds-ui'

import { useAuthContext } from '../../context/auth-context'

import { ReportProfileForm } from './report-profile-form'
import { useProfileContext } from './profile-context'

const ReportButton = styled.button(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing['2x'],
  height: theme.sizes['12x'],
  padding: '0 16px',
  marginLeft: -16,
  color: theme.colors.text.default,
  textDecoration: 'underline',
  ...theme.typography.label.md,
}))

export function ProfileFooterBlock() {
  const { profile } = useProfileContext()
  const { authBody, isAuthenticated } = useAuthContext()
  const [isDialogOpen, setIsDialogIsOpen] = useState(false)
  const closeDialog = useCallback(() => setIsDialogIsOpen(false), [])
  const openDialog = useCallback(() => setIsDialogIsOpen(true), [])
  const { t } = useTranslation('report_profile_block')

  if (!isAuthenticated || authBody?.uid === profile.uid) {
    // Users will not report themselves
    return null
  }
  return (
    <Stack gap="2x">
      <ReportButton onClick={openDialog}>
        <AlertTriangleIcon size={16} color="currentColor" />
        {t('title')}
      </ReportButton>
      <Dialog isOpen={isDialogOpen} onOpenChange={closeDialog}>
        <ReportProfileForm userUid={profile.uid} onProfileReported={closeDialog} />
      </Dialog>
    </Stack>
  )
}
