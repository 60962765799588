import { graphql } from '@qasa/graphql'

graphql(`
  fragment OccupationFragment on Occupation {
    current
    endedAt
    id
    occupationScheduleType
    occupationType
    startedAt
    subtitle
    title
  }
`)

export const UPSERT_OCCUPATION = graphql(`
  mutation UpsertOccupation($input: UpsertOccupationInput!) {
    upsertOccupation(input: $input) {
      occupation {
        ...OccupationFragment
      }
      errors {
        codes
        field
      }
    }
  }
`)

export const DESTROY_OCCUPATION = graphql(`
  mutation DestroyOccupationMutation($destroyOccupationId: ID!) {
    destroyOccupation(id: $destroyOccupationId) {
      success
    }
  }
`)
