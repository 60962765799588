import styled from '@emotion/styled'

export const AmenityGrid = styled.ul(({ theme }) => ({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: 16,
  [theme.mediaQueries.mdUp]: {
    gridTemplateColumns: '1fr 1fr',
  },
}))
