import { useTranslation } from 'react-i18next'
import { Button, Heading, Paragraph, Stack } from '@qasa/qds-ui'

import { type OccupationFormStepsProps } from '../occupation-form'
import { DialogAndWizardActionsFooter } from '../../../ui-shared/dialog-and-wizard-actions-footer'

export function OccupationOtherCheckStep({ goNext, goBack }: OccupationFormStepsProps) {
  const { t } = useTranslation('edit_profile')

  return (
    <Stack gap="8x">
      <Heading size="lg">{t('form.occupation.other_occupation.are_you_sure')}</Heading>
      <Paragraph>{t('form.occupation.other_occupation.description')}</Paragraph>
      <DialogAndWizardActionsFooter>
        <Button type="button" variant="tertiary" onClick={goBack}>
          {t('commons:back')}
        </Button>
        <Button type="button" onClick={goNext}>
          {t('commons:next')}
        </Button>
      </DialogAndWizardActionsFooter>
    </Stack>
  )
}
