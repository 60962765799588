import { useState } from 'react'
import { Checkbox, Select, Stack } from '@qasa/qds-ui'

import { useDebouncedValue } from '../../../../../hooks/use-debounced-value'
import { useEffectOnUpdate } from '../../../../../hooks/use-effect-on-update'
import { useFindHomeFiltersContext } from '../../../contexts'
import { useAppTranslation } from '../../../../../contexts/i18next'
import { NumberStepper } from '../../../../../components/web'

const MAX_GUESTS = 16
const MAX_BEDROOMS = 8
const MAX_BEDS = 25
const MAX_BATHROOMS = 5
const MAX_ROOMS = 8

export function RoomsAndBedsFilterSection() {
  const { t } = useAppTranslation('filter_fields')
  const { filterValues, updateFilterValues } = useFindHomeFiltersContext()

  const [roomsAndBeds, setRoomsAndBeds] = useState({
    bedCount: filterValues.bedCount,
    bedroomCount: filterValues.bedroomCount,
    householdSize: filterValues.householdSize,
    rooms: filterValues.minRoomCount,
    toiletCount: filterValues.toiletCount,
  })
  const { bedCount, bedroomCount, householdSize, rooms, toiletCount } = roomsAndBeds

  const handleUpdateRoomsAndBeds = (payload: Partial<typeof roomsAndBeds>) => {
    setRoomsAndBeds((old) => ({ ...old, ...payload }))
  }

  const debouncedRoomsAndBeds = useDebouncedValue({ value: roomsAndBeds, delay: 300 })

  useEffectOnUpdate(() => {
    const sanitizedValues = Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(debouncedRoomsAndBeds).filter(([_, value]) => value !== 0),
    )
    updateFilterValues(sanitizedValues)
  }, [debouncedRoomsAndBeds, updateFilterValues])

  // If value changes by clicking the clear button we need to manually update
  useEffectOnUpdate(() => {
    setRoomsAndBeds({
      bedCount: filterValues.bedCount,
      bedroomCount: filterValues.bedroomCount,
      householdSize: filterValues.householdSize,
      rooms: filterValues.minRoomCount,
      toiletCount: filterValues.toiletCount,
    })
  }, [
    filterValues.bedCount,
    filterValues.bedroomCount,
    filterValues.householdSize,
    filterValues.minRoomCount,
    filterValues.toiletCount,
  ])

  const minSquareMetersOptions = [
    ...[15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 120].map((item) => {
      return {
        value: item,
        label: t('common_home:home_info.square_meters', { amount: item }),
      }
    }),
  ]

  return (
    <Stack gap="6x">
      <NumberStepper
        label={t('guests.label')}
        value={householdSize || 0}
        max={MAX_GUESTS}
        onChange={(newValue) => handleUpdateRoomsAndBeds({ householdSize: newValue })}
      />
      <NumberStepper
        label={t('bedrooms.label')}
        value={bedroomCount || 0}
        max={MAX_BEDROOMS}
        onChange={(newValue) => handleUpdateRoomsAndBeds({ bedroomCount: newValue })}
      />
      <NumberStepper
        label={t('beds.label')}
        value={bedCount || 0}
        max={MAX_BEDS}
        onChange={(newValue) => handleUpdateRoomsAndBeds({ bedCount: newValue })}
      />
      <NumberStepper
        label={t('bathrooms.label')}
        value={toiletCount || 0}
        max={MAX_BATHROOMS}
        onChange={(newValue) => handleUpdateRoomsAndBeds({ toiletCount: newValue })}
      />
      {/* TODO: Make number of rooms a range slider */}
      <NumberStepper
        label={t('rooms.label')}
        value={rooms || 0}
        max={MAX_ROOMS}
        onChange={(newValue) => handleUpdateRoomsAndBeds({ rooms: newValue })}
      />

      <Select
        label={t('min_square_meters.label')}
        placeholder={t('common:show_all')}
        value={filterValues.minSquareMeters || ''}
        onChange={(e) => updateFilterValues({ minSquareMeters: parseInt(e.target.value, 10) || null })}
      >
        {minSquareMetersOptions.map(({ value, label }) => (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>

      <Checkbox
        label={t('has_kitchen.label')}
        isChecked={filterValues.includedRooms.includes('kitchen')}
        onCheckedChange={(isChecked: boolean) => {
          const includedRoomsWithoutKitchen = filterValues.includedRooms.filter(
            (includedRoom) => includedRoom !== 'kitchen',
          )
          const newIncludedRooms = isChecked
            ? [...filterValues.includedRooms, 'kitchen']
            : includedRoomsWithoutKitchen
          updateFilterValues({ includedRooms: newIncludedRooms })
        }}
      />
    </Stack>
  )
}
