import type { NetworkError } from '@apollo/client/errors'
import { onError } from '@apollo/client/link/error'
import Cookies from 'js-cookie'

type NetworkErrorWithStatusCode = NetworkError & { statusCode: number }

export const errorLink = onError((error) => {
  const networkError = error.networkError as NetworkErrorWithStatusCode | undefined

  if (networkError?.statusCode === 401) {
    Cookies.remove('Access-Token')
    Cookies.remove('AdminToken')
    window.location.reload()
  }
})
