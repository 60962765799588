import type { Position } from 'geojson'
import { Marker } from 'react-map-gl'
import { styled } from '@qasa/ui/web'

type ClusterVariant = 'active' | 'normal'

const ClusterWrapper = styled('button')<{ size: number; variant: ClusterVariant }>(
  ({ theme, size, variant }) => ({
    borderRadius: 999,
    width: size,
    height: size,
    border: '1px solid',
    borderColor: theme.colors.border.default,
    boxShadow: theme.shadows.lg,
    ...theme.typography.label.sm,
    background: variant === 'active' ? theme.colors.bg.brandPrimary : theme.colors.bg.default,
    color: variant === 'active' ? theme.colors.core.white : theme.colors.text.default,
    fontVariantNumeric: 'tabular-nums',
    transition: 'transform 0.1s ease-out',
    ':hover': {
      transform: 'scale(1.05)',
    },
  }),
)

const getSize = ({ pointCount }: { pointCount: number }) => {
  switch (true) {
    case pointCount >= 1000:
      return 56
    case pointCount >= 100:
      return 48
    default:
      return 32
  }
}

type MapClusterProps = {
  coordinates: Position
  pointCount: number
  onClick: () => void
  variant: ClusterVariant
}
export function MapCluster({ coordinates, pointCount, onClick, variant }: MapClusterProps) {
  const [longitude, latitude] = coordinates

  const size = getSize({ pointCount })

  return (
    <Marker anchor={'center'} longitude={longitude} latitude={latitude} draggable={false}>
      <ClusterWrapper
        onClick={(e) => {
          e.stopPropagation()
          onClick()
        }}
        {...{ size, variant }}
      >
        {pointCount}
      </ClusterWrapper>
    </Marker>
  )
}
