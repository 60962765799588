import { useTranslation } from 'react-i18next'
import { RadioGroup, Stack, TextField } from '@qasa/qds-ui'

import { FieldEnum } from '../edit-listing.types'
import { useListingContext } from '../listing-context'
import { SectionWrapper } from '../section-wrapper'

export function UserTypeSection() {
  const { t } = useTranslation('listing')
  const {
    store: {
      values: { isProfessional, companyName, orgNumber },
    },
    getErrorMessage,
    dispatch,
  } = useListingContext()

  const isProfessionalValue = typeof isProfessional === 'boolean' ? isProfessional.toString() : null

  const companyNameErrorMessage = getErrorMessage({ field: FieldEnum.CompanyName }) || undefined
  const organizationNumberErrorMessage = getErrorMessage({ field: FieldEnum.OrganizationNumber }) || undefined

  return (
    <SectionWrapper title={t('section_headings.user_type')} description={t('user_types.subheading')}>
      <RadioGroup
        name="usertype"
        value={isProfessionalValue ?? ''}
        onValueChange={(value) => {
          const isProfessional = value === 'true'
          dispatch({
            action: {
              type: FieldEnum.IsProfessional,
              payload: isProfessional,
            },
          })
        }}
      >
        <Stack gap="2x">
          <RadioGroup.Card value="false" label={t('user_types.private')} />
          <RadioGroup.Card value="true" label={t('user_types.professional')} />
        </Stack>
      </RadioGroup>
      {isProfessional && (
        <Stack direction={{ base: 'column', md: 'row' }} gap={{ base: '6x', md: '2x' }}>
          <TextField
            value={companyName || ''}
            onChange={(event) =>
              dispatch({ action: { type: FieldEnum.CompanyName, payload: event.target.value || null } })
            }
            label={t('user_types.company_name')}
            placeholder={t(['user_types.company_name_placeholder', 'user_types.company_name_placeholder'])}
            isInvalid={Boolean(companyNameErrorMessage)}
            errorMessage={companyNameErrorMessage}
          />
          <TextField
            value={orgNumber || ''}
            onChange={(event) =>
              dispatch({
                action: { type: FieldEnum.OrganizationNumber, payload: event.target.value || null },
              })
            }
            label={t('user_types.organization_number')}
            placeholder={t([
              'user_types.organization_number_placeholder',
              'user_types.organization_number_placeholder',
            ])}
            isInvalid={Boolean(organizationNumberErrorMessage)}
            errorMessage={organizationNumberErrorMessage}
          />
        </Stack>
      )}
    </SectionWrapper>
  )
}
