import type { ReactNode } from 'react'
import { useCallback, Children, useEffect, useRef, useState } from 'react'
import { styled } from '@qasa/ui/web'

import { NavigationControls } from './navigation-controls'
import { ProgressIndicator } from './progress-indicator'
import { useCarousel } from './use-carousel'

type CarouselVariant = 'default' | 'home'

const CarouselRoot = styled('div')<{ variant?: CarouselVariant }>(({ theme, variant }) => ({
  width: '100%',
  height: '100%',
  borderTopLeftRadius: variant === 'home' ? 32 : 24,
  borderBottomLeftRadius: variant === 'home' ? 32 : 24,
  borderTopRightRadius: variant === 'home' ? 0 : 24,
  borderBottomRightRadius: variant === 'home' ? 0 : 24,
  overflow: 'hidden',
  position: 'relative',
  userSelect: 'none',
  border: '1px solid',
  borderColor: theme.colors.border.default,
}))

export const Slider = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,

  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  overflowY: 'hidden',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  scrollSnapStop: 'always',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',

  // This is needed to fix a bug on iOS Safari
  borderRadius: 'inherit',
})

export const SliderItem = styled('li')({
  width: '100%',
  height: '100%',
  flexShrink: 0,
  scrollSnapAlign: 'center',
})

type CarouselProps = {
  children: ReactNode
  onIndexChange?: (value: { newIndex: number }) => void
  variant?: CarouselVariant
}

export function Carousel({ children, onIndexChange = () => null, variant = 'default' }: CarouselProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const sliderRef = useRef<HTMLUListElement>(null)
  const { registerItem, currentIndex, itemCount, isFirstStep, isLastStep, manualScroll } = useCarousel({
    containerRef,
    sliderRef,
  })
  const [isHovered, setIsHovered] = useState(false)

  const onIndexChangeCallback = useCallback(onIndexChange, [onIndexChange])

  useEffect(() => {
    onIndexChangeCallback({ newIndex: currentIndex })
  }, [currentIndex, onIndexChangeCallback])

  return (
    <CarouselRoot
      ref={containerRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      variant={variant}
    >
      <Slider ref={sliderRef}>
        {Children.map(children, (child, index) => {
          if (child)
            return (
              <SliderItem ref={(item: HTMLLIElement) => registerItem({ item, index })}>{child}</SliderItem>
            )
          return null
        })}
      </Slider>
      <ProgressIndicator count={itemCount} currentIndex={currentIndex} />
      {isHovered && (
        <NavigationControls
          hasPrevious={!isFirstStep}
          onPreviousClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            manualScroll(-1)
          }}
          hasNext={!isLastStep}
          onNextClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            manualScroll(1)
          }}
        />
      )}
    </CarouselRoot>
  )
}

type CarouselImgProps = {
  objectFit?: 'cover' | 'contain'
}

// Handles sizing of images in carousel.
// Normal <img />-tags also works but this is a handy utility
export const CarouselImg = styled('img')<CarouselImgProps>(({ objectFit }) => ({
  width: '100%',
  height: '100%',
  objectFit: objectFit ?? 'cover',
}))
