import { CurrencyEnum } from '@qasa/graphql'

const isNotNull = <T>(value: T): value is NonNullable<T> => Boolean(value)

const getCurrencySymbol = ({ currency }: { currency?: CurrencyEnum }) => {
  switch (currency) {
    case CurrencyEnum.EUR:
      return '€'
    case CurrencyEnum.SEK:
      return 'kr'
    default:
      return undefined
  }
}

export { isNotNull, getCurrencySymbol }
