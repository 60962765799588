import { Link as QDSLink } from '@qasa/qds-ui'
import type { Ref } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import type { RouteName } from '../../navigation'
import { getPath } from '../../navigation'

import type { LinkProps } from './link.types'

/**
 * Using forwardRef here messes up the generic type inference for links.
 * Use linkRef instead if a ref is needed on the link
 * https://stackoverflow.com/questions/58578570/value-of-using-react-forwardref-vs-custom-ref-prop/60237948#60237948
 * --magnuslh 01-24
 */
type Props<T extends RouteName> = LinkProps<T> & { linkRef?: Ref<HTMLAnchorElement> }

export function Link<T extends RouteName>(props: Props<T>) {
  if ('to' in props) {
    const { to, params, queryParams, ...rest } = props
    const path = getPath(to, params, queryParams)
    return <QDSLink ref={props.linkRef} as={RouterLink} to={path} {...rest} />
  } else {
    return <QDSLink isExternal ref={props.linkRef} {...props} />
  }
}
