import { Stack } from '@qasa/qds-ui'
import { TenantAdStatusEnum } from '@qasa/graphql'

import { BioField } from '../fields/bio-field'
import { BioPetsField } from '../fields/bio-pets-field'
import { BioTitleField } from '../fields/bio-title-field'
import { ProfileDataForm } from '../forms/profile-data-form'
import { useProfileContext } from '../profile-context'

export function AboutMeSection() {
  const { tenantAdStatus, isProfessional } = useProfileContext()
  const isProfilePublished = tenantAdStatus === TenantAdStatusEnum.published
  return (
    <ProfileDataForm dataKey="aboutMe">
      <Stack gap="4x">
        <BioTitleField isFieldRequired={isProfilePublished} isProfessional={isProfessional} />
        <BioField isProfessional={isProfessional} />
        <BioPetsField isProfessional={isProfessional} />
      </Stack>
    </ProfileDataForm>
  )
}
