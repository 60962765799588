import styled from '@emotion/styled'
import type { StaticImageData } from 'next/image'

import type { ThumborFittingMethods } from '../../helpers/image'
import { prependSrcWithThumbor } from '../../helpers/image'

const ImageDiv = styled.div(({ imgSource }: { imgSource: string }) => ({
  backgroundImage: `url(${imgSource})`,
  backgroundSize: 'cover',
}))

type Merge<A, B> = Omit<A, keyof B> & B

export type ImageProps = Merge<
  React.ImgHTMLAttributes<HTMLElement>,
  {
    asDiv?: boolean
    src: string | StaticImageData
    height?: number
    width?: number
    className?: string
    fittingMethod?: ThumborFittingMethods
  }
>

export function Image({
  asDiv = false,
  src,
  height = 1000,
  width = 1000,
  fittingMethod = 'crop',
  ...props
}: ImageProps) {
  // 1. only optimize images from our storage service
  // 2. hotfix for our local dev environment when running backend locally
  const imageUrl = prependSrcWithThumbor({ src, width, height, fittingMethod })
  return asDiv ? (
    <ImageDiv imgSource={imageUrl} {...props} />
  ) : (
    <img src={imageUrl} alt={props.alt} {...props} />
  )
}
