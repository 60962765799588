import styled from '@emotion/styled'
import { theme } from '@qasa/qds-ui'
import isPropValid from '@emotion/is-prop-valid'
import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'

import { UnstyledButton } from '../unstyled-button'

import type { PillButtonProps, PillButtonStyledProps } from './pill-button.types'

const StyledPillButton = styled(UnstyledButton, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<PillButtonStyledProps>(({ isActive = false, size = 'medium' }) => ({
  all: 'unset',
  boxSizing: 'border-box',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing['2x'],
  height: size === 'small' ? 36 : 40,
  padding: `0 ${theme.spacing['4x']}`,
  borderRadius: theme.radii.full,
  fontSize: theme.typography.body.sm.fontSize,
  color: theme.colors.text.default,
  border: `1px solid`,
  transitionProperty: 'border-color, box-shadow',
  transitionDuration: '120ms',
  flexShrink: 0,
  ...(isActive
    ? {
        borderColor: theme.colors.text.default,
        boxShadow: `inset 0 0 0 1px ${theme.colors.text.default}`,
      }
    : {
        borderColor: theme.colors.border.default,
        ':hover': {
          borderColor: theme.colors.border.defaultHover,
        },
      }),
}))
export const PillButton = forwardRef(
  (props: PillButtonProps, forwardedRef: ForwardedRef<HTMLButtonElement>) => {
    const { children, icon: Icon, iconPlacement = 'left', ...restProps } = props
    const icon = Icon ? <Icon color="default" /> : null

    return (
      <StyledPillButton ref={forwardedRef} {...restProps}>
        {iconPlacement === 'left' && icon}
        {children}
        {iconPlacement === 'right' && icon}
      </StyledPillButton>
    )
  },
)
