import type { FormatValueFunction, Range } from './range-slider.types'

type GetRangeSliderLabels = {
  value: Range
  formatValue?: FormatValueFunction
}
export const getRangeSliderLabels = ({ value, formatValue }: GetRangeSliderLabels) => {
  const formattedMinValue = formatValue ? formatValue(value[0]) : value[0]
  const formattedMaxValue = formatValue ? formatValue(value[1]) : value[1]
  return [formattedMinValue, formattedMaxValue]
}
