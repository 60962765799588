import { Stack, Paragraph, Heading } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

export function TitleAndDescriptionSection({ isDesktop }: { isDesktop?: boolean }) {
  const { t } = useTranslation('shortcut_opt_in')
  return (
    <Stack gap="3x">
      <Heading size={isDesktop ? 'lg' : 'md'}>{t('intro.title')}</Heading>
      <Paragraph size="lg">{t('intro.description')}</Paragraph>
    </Stack>
  )
}
