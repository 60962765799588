import { Link } from '@qasa/qds-ui'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAuthContext } from '../../../../../context/auth-context'
import { useAuthenticatedAction } from '../../../../../helpers/auth-callback'
import { AFTER_LOGIN_ACTIONS } from '../../../../../helpers/auth-callback.types'
import { getPath } from '../../../../../routing/get-path'

export function LogInActionLink() {
  const { t } = useTranslation('listing')
  const { isAuthenticated } = useAuthContext()
  const { pushToLoginWithAfterAction } = useAuthenticatedAction()
  const { state } = useLocation<{ background: Location }>()

  if (isAuthenticated) {
    return null
  }

  return (
    <Link
      as={'button'}
      style={{ width: 'fit-content' }}
      onClick={() =>
        pushToLoginWithAfterAction({
          type: AFTER_LOGIN_ACTIONS.GO_TO_ROUTE,
          route: state?.background.pathname ?? getPath('findTenant'),
          search: state?.background.search ?? undefined,
        })
      }
    >
      {t('find_tenant_intro.log_in_link')}
    </Link>
  )
}
