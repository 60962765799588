import { styled } from '@qasa/ui/web'
import { useBreakpoint, useBreakpointValue } from '@qasa/qds-ui'

import { useFindHomeContext, useFindHomeFiltersContext } from '../contexts'
import { useAppTranslation } from '../../../contexts/i18next'
import { useCurrentUser } from '../../../contexts/current-user'

import { getIsFilterActive, splitArrayAtIndex } from './utils/filters.utils'
import { MoreFiltersDialog } from './more-filters-dialog'
import { QuickFilter } from './quick-filter.web'
import { useVacationFilterSections } from './index.web'

const Wrapper = styled('div')(({ theme }) => ({
  marginInline: `-${theme.spacing['6x']}`,
  position: 'relative',
  [theme.mediaQueries.mdUp]: {
    marginInline: 'initial',
  },
}))
const HorizontalScrollContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing['2x'],
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  paddingLeft: theme.spacing['6x'],
  // Need to use pseudo elements for iOS Safari to respect
  // the right padding -- Hugo 2/5/22
  ':after': {
    content: '""',
    paddingRight: theme.spacing['4x'],
    height: 1,
  },
  [theme.mediaQueries.mdUp]: {
    flexWrap: 'wrap',
    padding: 'initial',
  },
}))

const FadeLeft = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: theme.sizes['6x'],
  left: 0,
  top: 0,
  bottom: 0,
  background: 'linear-gradient(to right, #ffffff 8%, #ffffff00)',
}))

const FadeRight = styled(FadeLeft)({
  left: 'initial',
  right: 0,
  background: 'linear-gradient(to left, #ffffff 8%, #ffffff00)',
})

export function VacationFilters() {
  const { homesResultCount } = useFindHomeContext()

  const filterSections = useVacationFilterSections()
  const { filterValues, clearFilterValues } = useFindHomeFiltersContext()
  const { t } = useAppTranslation('filter_sections')
  const { currentBreakpoint } = useBreakpoint()
  const isMobile = currentBreakpoint === 'sm'
  const { isAuthenticated } = useCurrentUser()
  const numberOfButtons = useBreakpointValue({ base: 4, sm: 3, lg: 4, xl: 3, '2xl': 4 })
  const numberOfQuickFilters = numberOfButtons - Number(isAuthenticated && !isMobile)
  const [quickFilterSections, restFilterSections] = splitArrayAtIndex(filterSections, numberOfQuickFilters)

  return (
    <Wrapper>
      <HorizontalScrollContainer>
        {quickFilterSections.map(({ name, Component, managedValues, valuesFormatter }) => {
          const hasActiveFilters = managedValues.some((filterKey) => {
            return getIsFilterActive({ filterKey, currentValue: filterValues[filterKey] })
          })

          const buttonLabel = t(`${name}.button_label`)
          const formattedValues = valuesFormatter && hasActiveFilters && valuesFormatter(filterValues)
          const sectionTitle = t(`${name}.title`)

          const handleClearFilters = () => clearFilterValues(managedValues)

          return (
            <QuickFilter
              key={name}
              buttonLabel={formattedValues || buttonLabel}
              sectionTitle={sectionTitle}
              isActive={hasActiveFilters}
              onClear={handleClearFilters}
              resultCount={homesResultCount}
            >
              <Component />
            </QuickFilter>
          )
        })}
        <MoreFiltersDialog filterSections={restFilterSections} />
      </HorizontalScrollContainer>
      {isMobile && (
        <>
          <FadeLeft />
          <FadeRight />
        </>
      )}
    </Wrapper>
  )
}
