import { Divider, Stack } from '@qasa/ui'
import { HomeRentalTypeEnum } from '@qasa/graphql'

import { PreviousSearches } from './previous-searches/'
import { SavedSearches } from './saved-searches'
import { useSearchHistoryContext } from './search-history-context'
import { RecommendedSearches } from './recommended-searches'

export type SearchHistoryProps = {
  onListItemPress?: () => void
}
export function SearchHistory({ onListItemPress }: SearchHistoryProps) {
  const { savedSearches, previousSearches, rentalType } = useSearchHistoryContext()

  if (rentalType === HomeRentalTypeEnum.vacation) {
    return <SavedSearches onListItemPress={onListItemPress} />
  }
  return (
    <Stack gap="8x">
      <SavedSearches onListItemPress={onListItemPress} />
      {Boolean(savedSearches?.length) && <Divider />}
      <PreviousSearches onListItemPress={onListItemPress} />
      {Boolean(previousSearches?.length) && <Divider />}
      <RecommendedSearches onListItemPress={onListItemPress} />
    </Stack>
  )
}
