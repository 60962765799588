import { graphql } from '@qasa/graphql'

export const CREATE_CREDIT_REPORT = graphql(`
  mutation CreateCreditReport($authToken: String!) {
    createCreditReport(authToken: $authToken) {
      creditReport {
        id
        fetchStatus
      }
      errors {
        codes
        field
      }
    }
  }
`)

export const GET_CREDIT_REPORT = graphql(`
  query GetCreditReport {
    me {
      uid
      creditReport {
        createdAt
        currentAddress {
          city
          postalCode
          street
        }
        fetchStatus
        id
        moveInfo {
          count
          years
        }
        numberPaymentRemarks
        totalDebt
        yearlyIncomeStatements {
          amount
          year
        }
        guardianship
        seizureAttempt
        debtSanitization
        expiresOn
      }
    }
  }
`)

export const DELETE_CREDIT_REPORT = graphql(`
  mutation DestroyCreditReport($creditReportId: ID!) {
    destroyCreditReport(id: $creditReportId) {
      creditReport {
        id
      }
    }
  }
`)
