import type { Position } from 'geojson'
import clamp from 'lodash/clamp'
import { useMap } from 'react-map-gl'
import { useCallback } from 'react'

import { reportError } from '../../../vendor'

import { POPUP_DESKTOP_WIDTH } from './map.utils.web'

type PanCardIntoViewParams = {
  position: Position
}

export const usePanCardIntoView = () => {
  const { current: map } = useMap()

  const panCardIntoView = useCallback(
    ({ position }: PanCardIntoViewParams) => {
      if (!map) return
      try {
        const cardProjection = map!.project([position[0] ?? 0, position[1] ?? 0])
        const { x: cardX, y: cardY } = cardProjection

        const mapWidth = map!.getCanvas().clientWidth
        const mapHeight = map!.getCanvas().clientHeight
        const MARGIN = 12

        const xMin = POPUP_DESKTOP_WIDTH / 2 + MARGIN
        const xMax = mapWidth - POPUP_DESKTOP_WIDTH / 2 - MARGIN

        const yMin = POPUP_DESKTOP_WIDTH + MARGIN
        const yMax = mapHeight - MARGIN * 2

        /* calculate difference between popups current and desired position and
       limit the difference between the max and min values calculated above */
        const diffX = clamp(cardX, xMin, xMax) - cardX
        const diffY = clamp(cardY, yMin, yMax) - cardY

        if (diffX || diffY) {
          /* apply the diff to the center since we need to move the maps center and not the
        marker and popup themselves for panning the popup into view */
          const center = map!.getCenter()
          const projectedCenter = map!.project(center)
          const centerWithDiff = map!.unproject([projectedCenter.x - diffX, projectedCenter.y - diffY])

          map.flyTo({
            duration: 3 * Math.hypot(diffX, diffY) + 600,
            center: centerWithDiff,
          })
        }
      } catch (error) {
        reportError('Panning map card into view failed', { error })
      }
    },
    [map],
  )

  return { panCardIntoView }
}
