import { HomeSearchOrderEnum, HomeSearchOrderByEnum } from '@qasa/graphql'
import { stringify } from 'query-string'
import fromPairs from 'lodash/fromPairs'

import type { FindHomeFilterValues } from '../features/find-home/'
import type { SelectedArea } from '../types/find-home'

export const stringifySearchArea = (selectedArea: SelectedArea): string => {
  const name = selectedArea.name.replace(/\s/g, '_')
  const city = selectedArea.city ? `~${selectedArea.city.replace(/\s/g, '_')}` : '~'
  const countryCode = selectedArea.countryCode ? `~${selectedArea.countryCode}` : '~'

  return name + city + countryCode
}

const replaceUnderscoreWithWhitespace = (str: string) => str.replace(/_/g, ' ')
export const parseSearchAreaQuery = (searchAreaQuery: string): SelectedArea => {
  // Handle characters that have been encoded with windows-1252 encoding.
  // Should be temporarily until blocket fixes such that all their urls are utf-8 encoded
  const commonWindows1252Chars = {
    '%E4': 'ä',
    '%E5': 'å',
    '%F6': 'ö',
    '%C4': 'Ä',
    '%C5': 'Å',
    '%D6': 'Ö',
  }
  Object.entries(commonWindows1252Chars).forEach(([key, value]) => {
    searchAreaQuery = searchAreaQuery.replaceAll(key, value)
  })

  const [name, city, countryCode] = searchAreaQuery.split('~').map(replaceUnderscoreWithWhitespace)

  return { name: name as string, city, countryCode }
}

// TODO: This should use the same config and library as in `useQueryParamHomeFilters`
export const getSearchQueryString = ({ filterValues }: { filterValues?: Partial<FindHomeFilterValues> }) => {
  const queries = {
    ...filterValues,
  }

  const queriesWithSortedValues = fromPairs(
    Object.entries(queries).map(([key, value]) => {
      if (key === 'searchAreas') {
        const addresses = (value as SelectedArea[]).map(stringifySearchArea)
        return [key, addresses.sort()]
      }

      if (Array.isArray(value) && value.length) {
        return [key, [...value].sort()]
      }
      return [key, value]
    }),
  )

  if (queriesWithSortedValues.page === 1) delete queriesWithSortedValues.page
  if (queriesWithSortedValues.order === HomeSearchOrderEnum.ASCENDING) {
    queriesWithSortedValues.order = 'ASCENDING'
  } else {
    delete queriesWithSortedValues.order
  }
  if (queriesWithSortedValues.orderBy === HomeSearchOrderByEnum.PUBLISHED_AT) {
    delete queriesWithSortedValues.orderBy
  }
  const queryParamsString = stringify(queriesWithSortedValues, {
    skipEmptyString: true,
    skipNull: true,
    arrayFormat: 'separator',
  })
  const queryParamOrEmptyString = queryParamsString.length ? '?' + queryParamsString : ''

  return queryParamOrEmptyString
}
