import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Label, Paragraph, Stack } from '@qasa/qds-ui'

type InputWrapperProps = { title?: string; isOptional?: boolean; children: ReactNode; className?: string }

export function InputWrapper({ title, children, isOptional, className }: InputWrapperProps) {
  const { t } = useTranslation('listing')
  return (
    <Stack gap={'2x'} {...{ className }}>
      {title && (
        <Label>
          {title}
          {isOptional && <Paragraph size="sm" as="span" color="subtle">{` (${t('optional')})`}</Paragraph>}
        </Label>
      )}

      {children}
    </Stack>
  )
}
