import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { useMutation } from '@apollo/client'
import { useToastContext } from '@qasa/app'
import { Dialog } from '@qasa/app/src/components/dialog'

import { useEffectOnMount } from '../../../../hooks/use-effect-on-mount'
import { CREATE_CREDIT_REPORT } from '../credit-report.gql'
import { PageNetworkStatus } from '../../../../ui-shared/_core/network-statuses'
import type { CreditVerificationStepProps } from '../credit-verification-step'

export function CreditVerificationCreating({ goNext, goBack }: CreditVerificationStepProps) {
  const { t } = useTranslation('create_application_with_verifications')
  const { addToast } = useToastContext()

  const [{ transaction_id: scriveToken }, setQueryParams] = useQueryParams({
    transaction_id: withDefault(StringParam, null),
    success: withDefault(StringParam, null),
  })

  const handleErrors = () => {
    addToast({ message: t('commons:unexpected_error') })
    setQueryParams({ transaction_id: null, success: null })
    goBack()
  }
  const [createCreditReport, { loading: isCreateReportLoading }] = useMutation(CREATE_CREDIT_REPORT, {
    onCompleted: ({ createCreditReport }) => {
      if (createCreditReport?.errors) {
        handleErrors()
      } else {
        setQueryParams({ transaction_id: null, success: null })
        goNext()
      }
    },
    onError: () => handleErrors(),
  })

  useEffectOnMount(() => {
    if (scriveToken) {
      createCreditReport({ variables: { authToken: scriveToken } })
    } else {
      handleErrors()
    }
  })

  return (
    <Dialog.Body>
      <PageNetworkStatus {...{ isLoading: isCreateReportLoading }} />
    </Dialog.Body>
  )
}
