import { useEffect, useMemo, useState, useRef, useCallback } from 'react'
import type { MapRef, ViewState } from 'react-map-gl'
import ReactMapGL, { NavigationControl, AttributionControl, MapProvider } from 'react-map-gl'
import { styled } from '@qasa/ui/web'
import type { Polygon } from 'geojson'
import { theme, useBreakpointValue } from '@qasa/qds-ui'
import type { HomeSearchCoords } from '@qasa/graphql'

import { useDebouncedValue } from '../../../hooks/use-debounced-value'
import type { FindHomeNode, SelectedAreaWithSearchAreaData } from '../../../types/find-home'
import { LOCATIONIQ_TILES_URL } from '../../../hooks/use-map.web'
import type { HomeLocation } from '../find-home.utils'

import { MapLocations } from './map-locations'
import { MapMarker } from './map-marker'
import { useInitialViewport } from './map.utils'
import { MapPolygons } from './map-polygons'
import { useSelectedLocation } from './use-selected-location'
import { usePersistedMapState } from './use-persisted-map-state'
import { MapLoader } from './map-loader'
import { useSelectedCluster } from './use-selected-cluster'
import type { ClusterType } from './use-selected-cluster'
import { MapPopup } from './map-popup.web'

import 'maplibre-gl/dist/maplibre-gl.css'

const Wrapper = styled('div')({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  touchAction: 'none',
})

type MapProps = {
  isMapOpen: boolean
  isLoadingLocations: boolean
  hoveredHome: FindHomeNode | null
  isLoadingPolygons: boolean
  areas: SelectedAreaWithSearchAreaData[]
  coordsData?: { homeSearchCoords: Pick<HomeSearchCoords, 'filterHomesRaw'> }
}

// eslint-disable-next-line complexity
export function Map({
  isMapOpen,
  areas,
  coordsData,
  hoveredHome,
  isLoadingLocations,
  isLoadingPolygons,
}: MapProps) {
  const mapRef = useRef<MapRef>(null)
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { persistedMapState, setPersistedMapState } = usePersistedMapState()
  const initialViewport = useInitialViewport({ isMobile, persistedMapState })
  const [viewport, setViewport] = useState<ViewState>(initialViewport)
  const [selectedLocation, setSelectedLocation] = useSelectedLocation()
  const [selectedCluster, setSelectedCluster] = useSelectedCluster()
  const [isClusteringOutOfSync, setIsClusteringOutOfSync] = useState(true)
  const [hasMapLoaded, setHasMapLoaded] = useState(false)

  const locations = useMemo(() => {
    const rawCoords = coordsData?.homeSearchCoords.filterHomesRaw as unknown as string
    return rawCoords ? (JSON.parse(rawCoords) as HomeLocation[]) : []
  }, [coordsData])
  const polygons = useMemo(() => areas.map((x) => x.geojson as unknown as Polygon), [areas])

  useEffect(() => {
    if (mapRef.current && hasMapLoaded) {
      const map = mapRef.current.getMap()
      map.on('styleimagemissing', (event: LegitimateAny) => {
        /**
         * Our map tile provider (LocationIQ) doesn't provide images for all possible icons (ie "coworking_space", "bicycle_parking" etc)
         * and when this happens the map will output a lot of warnings to the console which we don't really care about.
         * To work around this we add a 0x0 image without any content which silences the warning without any actual impact on what's rendered.
         */
        map.addImage(event.id, { width: 0, height: 0, data: new Uint8Array() }, { sdf: true })
      })
    }
  }, [hasMapLoaded])

  const { longitude, latitude, zoom } = useDebouncedValue({ value: viewport, delay: 500 })
  useEffect(() => {
    setPersistedMapState({ longitude, latitude, zoom })
  }, [longitude, latitude, zoom, setPersistedMapState])

  useEffect(() => {
    const locationIsInCluster = selectedCluster?.leaves.find(
      (location) => location?.homeId === selectedLocation?.homeId,
    )
    if (!locationIsInCluster) {
      setSelectedCluster(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation])

  const handleUpdateViewport = useCallback(
    (params: ViewState) => setViewport((oldViewport) => ({ ...oldViewport, ...params })),
    [setViewport],
  )

  return (
    <Wrapper>
      <MapProvider>
        <ReactMapGL
          ref={mapRef}
          mapStyle={LOCATIONIQ_TILES_URL}
          onLoad={() => setHasMapLoaded(true)}
          onMove={(evt) => setViewport(evt.viewState)}
          minZoom={3.5}
          maxZoom={17}
          dragRotate={false}
          touchPitch={false}
          attributionControl={false}
          {...viewport}
          // Dimensions need to be set after viewport props
          // to enable responsive resizing.
          style={{
            width: '100%',
            height: '100%',
            fontFamily: theme.typography.body.md.fontFamily,
          }}
        >
          {hasMapLoaded && isMapOpen && (
            <>
              <MapPolygons polygons={polygons} viewport={viewport} />
              <MapLocations
                locations={locations}
                selectedLocation={selectedLocation}
                selectedCluster={selectedCluster}
                setSelectedLocation={setSelectedLocation}
                viewport={viewport}
                updateViewport={handleUpdateViewport}
                onSyncStateChange={setIsClusteringOutOfSync}
                onClusterClick={(cluster: ClusterType) => {
                  if (cluster) {
                    setSelectedCluster(cluster)
                    setSelectedLocation(cluster.leaves[0]!)
                  }
                }}
              />
              {selectedLocation && (
                <MapPopup
                  selectedLocation={selectedLocation}
                  onClose={() => setSelectedLocation(null)}
                  selectedCluster={selectedCluster}
                />
              )}
              {hoveredHome && (
                <MapMarker
                  coordinates={[hoveredHome.location.longitude ?? 0, hoveredHome.location.latitude ?? 0]}
                  rent={hoveredHome.rent || 0}
                  currency={hoveredHome.currency}
                  tenant_base_fee={hoveredHome.tenantBaseFee || 0}
                  average_price_per_night={hoveredHome.averagePricePerNight || 0}
                  variant="active"
                />
              )}
            </>
          )}

          <NavigationControl
            showCompass={false}
            style={{ marginTop: theme.spacing['4x'], marginRight: theme.spacing['4x'] }}
          />
          <AttributionControl
            compact
            style={{
              visibility: 'inherit',
              backgroundColor: theme.colors.core.white,
              borderRadius: theme.radii.md,
              boxShadow: theme.shadows.lg,
            }}
          />
          <MapLoader isLoading={isClusteringOutOfSync || isLoadingLocations || isLoadingPolygons} />
        </ReactMapGL>
      </MapProvider>
    </Wrapper>
  )
}
