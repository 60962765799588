import { graphql } from '@qasa/graphql'

export const CREATE_FIND_TENANT_LANDLORD_APPLICATION = graphql(`
  mutation CreateFindTenantLandlordApplication($input: CreateLandlordHomeApplicationInput!) {
    createLandlordHomeApplication(input: $input) {
      homeApplication {
        id
        conversation {
          id
        }
      }
      errors {
        codes
        field
      }
    }
  }
`)
