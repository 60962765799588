import { graphql } from '@qasa/graphql'

export const DESTROY_USER_MUTATION = graphql(`
  mutation DestroyUser($uid: ID!) {
    destroyUser(uid: $uid) {
      success
      errors {
        field
        codes
      }
    }
  }
`)
