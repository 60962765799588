import { Heading, Paragraph, Stack, CheckIcon } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

import { IconWithCircle } from './icon-with-circle'

const fadeInAnimation = keyframes`
  0% {
      opacity: 0;
      transform: translateY(10%);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
`

const Content = styled(Stack)`
  animation: ${fadeInAnimation} 0.3s;
`

type Props = {
  title: string
  description?: string
}
export function SuccessHeaderAnimated({ title, description }: Props) {
  return (
    <Content gap={'5x'} alignItems="center">
      <IconWithCircle Icon={CheckIcon} variant="positive" size={32} />
      <Stack gap={'2x'}>
        <Heading size={{ base: 'md', md: 'lg' }} textAlign="center">
          {title}
        </Heading>
        {description && (
          <Paragraph size={{ base: 'md', md: 'lg' }} textAlign="center">
            {description}
          </Paragraph>
        )}
      </Stack>
    </Content>
  )
}
