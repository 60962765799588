import type { TenantPreferencesHomeFragment } from '@qasa/graphql'

import { isNumber, inRange } from '../../utils/number'
import type { ParsedProfileSearchPreference } from '../../utils/parse-profile-search-preference'
import type { SearchPreferenceMatchIndicatorProps } from '../../components/search-preference-match-indicator'

const MAX_MONTHLY_COST_CLOSENESS_FACTOR_RANGE = [0.9, 1] as const
type GetMonthlyCostMatchDataParams = {
  maxMonthlyCostCents: ParsedProfileSearchPreference['maxMonthlyCostCents']
  matchingHome: Omit<TenantPreferencesHomeFragment, '__typename'>
}
export const getMonthlyCostMatchData = ({
  maxMonthlyCostCents,
  matchingHome,
}: GetMonthlyCostMatchDataParams): SearchPreferenceMatchIndicatorProps => {
  const monthlyCostCents = matchingHome.monthlyCostCents ?? 0
  const maxMonthlyCostDifference = monthlyCostCents - (maxMonthlyCostCents ?? 0)
  const isAbleToPayMonthlyCost = maxMonthlyCostDifference <= 0
  const differenceFactor = maxMonthlyCostCents && maxMonthlyCostCents / monthlyCostCents
  const isCloseToMonthlyCost =
    isNumber(differenceFactor) && inRange(differenceFactor, ...MAX_MONTHLY_COST_CLOSENESS_FACTOR_RANGE)

  const getMatchingLevel = () => {
    if (isAbleToPayMonthlyCost) {
      return 'match'
    } else if (isCloseToMonthlyCost) {
      return 'is_close'
    }
    return 'no_match'
  }
  return {
    matchingLevel: getMatchingLevel(),
  }
}
