import { useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Dialog } from '@qasa/app/src/components/dialog'

import { useProfileContext } from '../profile-context'
import { VisuallyHidden } from '../../../ui-shared/_core/visually-hidden'

import { ProfileCardPrivate } from './profile-card-private'
import { PublicFooterActions } from './profile-card-public'
import { PrivateFooterActions } from './private-actions'

const FooterWrapper = styled.footer(({ theme }) => ({
  background: theme.colors.bg.default,
  position: 'sticky',
  boxShadow: theme.shadows.lg,
  clipPath: 'inset(-10px 0px 0px 0px)',
  bottom: 0,
  left: 0,
  right: 0,
}))

const ContentWrapper = styled.div<{ $isOwnProfile: boolean }>(({ $isOwnProfile }) => ({
  height: 72,
  display: 'flex',
  padding: 16,
  justifyContent: $isOwnProfile ? 'space-between' : 'center',
  alignItems: 'center',
}))

export function ProfileCardFooter() {
  const { t } = useTranslation('profile_card')
  const [isOpen, setIsOpen] = useState(false)
  const { isOwnProfile } = useProfileContext()
  const handleOpenModal = () => {
    setIsOpen(true)
  }

  return (
    <FooterWrapper>
      <ContentWrapper $isOwnProfile={isOwnProfile}>
        {isOwnProfile ? <PrivateFooterActions openProfileCard={handleOpenModal} /> : <PublicFooterActions />}
      </ContentWrapper>
      <Dialog {...{ isOpen }} onOpenChange={() => setIsOpen(false)}>
        <Dialog.Header>
          <VisuallyHidden>
            <Dialog.Title>{t('private.missing_info.label')}</Dialog.Title>
          </VisuallyHidden>
        </Dialog.Header>
        <Dialog.Body>
          <ProfileCardPrivate />
        </Dialog.Body>
      </Dialog>
    </FooterWrapper>
  )
}
