import { graphql } from '@qasa/graphql'

export const CREATE_LISTING = graphql(`
  mutation CreateHomeMutation($input: CreateHomeInput!) {
    createHome(input: $input) {
      home {
        id
        status
        rentalType
        pricingModel {
          insuranceCostFactor
          qasaGuaranteeCostFactor
        }
      }
      errors {
        codes
        field
      }
    }
  }
`)
