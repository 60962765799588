import { useTranslation } from 'react-i18next'
import { Stack, useBreakpointValue, Spacer } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { PageWidthContainer } from '../../../../ui-shared/_layout/width-containers'
import { Wizard } from '../../../../ui-shared/wizard'

import { FooterActions, HowItWorksSection, LogInActionLink, TitleAndDescriptionSection } from './components'

const Wrapper = styled(Stack)(({ theme }) => ({ padding: theme.spacing['4x'] }))
const SoftPinkWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['6x'],
  backgroundColor: theme.colors.core.softPink,
  borderRadius: theme.radii.lg,
  justifyContent: 'flex-end',
  [theme.mediaQueries.lgUp]: {
    minWidth: '50%',
    padding: theme.spacing['12x'],
  },
}))
const DisplayHeading = styled.h2(({ theme }) => ({
  ...theme.typography.display.xs,
  [theme.mediaQueries.lgUp]: {
    ...theme.typography.display.sm,
  },
}))
const Content = styled(Stack)(({ theme }) => ({
  paddingInline: theme.spacing['2x'],
  [theme.mediaQueries.lgUp]: {
    paddingInline: 0,
    paddingBlock: theme.spacing['8x'],
  },
}))

type CreateFindTenantListingIntroProps = {
  nextSection: string
}
export function CreateFindTenantListingIntro({ nextSection }: CreateFindTenantListingIntroProps) {
  const Layout = useBreakpointValue({ base: MobileLayout, lg: DesktopLayout })

  return <Layout nextSection={nextSection} />
}

function MobileLayout({ nextSection }: CreateFindTenantListingIntroProps) {
  const { t } = useTranslation('listing')

  return (
    <Wrapper>
      <Stack gap="6x">
        <SoftPinkWrapper>
          <DisplayHeading>{t('find_tenant_intro.display_title')}</DisplayHeading>
        </SoftPinkWrapper>
        <Content gap="6x">
          <TitleAndDescriptionSection />
          <HowItWorksSection />
          <LogInActionLink />
        </Content>
      </Stack>
      <Wizard.Footer>
        <FooterActions nextSection={nextSection} />
      </Wizard.Footer>
    </Wrapper>
  )
}

function DesktopLayout({ nextSection }: CreateFindTenantListingIntroProps) {
  const { t } = useTranslation('listing')

  return (
    <PageWidthContainer maxWidth="wider">
      <Spacer size="4x" />
      <Wrapper gap="20x" direction="row">
        <SoftPinkWrapper>
          <DisplayHeading>{t('find_tenant_intro.display_title')}</DisplayHeading>
        </SoftPinkWrapper>
        <Content gap="6x">
          <TitleAndDescriptionSection />
          <HowItWorksSection />
          <LogInActionLink />
          <FooterActions nextSection={nextSection} />
        </Content>
      </Wrapper>
    </PageWidthContainer>
  )
}
