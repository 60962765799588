import type { Market } from '@qasa/graphql'
import { MarketNameEnum, TraitTypeEnum } from '@qasa/graphql'

import { BalconyIcon, PetsIcon, SaunaIcon, SmokerIcon, WheelchairIcon } from '../../profile/amenity-icons'
import { FurnitureIcon } from '../../profile/view-profile-sections/icons'

import type { AmenityGroupType } from './types'
import { AmenityGroupTitleEnum } from './types'
import { FirePlaceIcon, JacuzziIcon, PoolIcon } from './vacation-traits'

type GetLongTermTraitsParams = {
  marketName?: Market['name']
}
export const getLongTermTraits = ({ marketName }: GetLongTermTraitsParams): AmenityGroupType[] => {
  return [
    {
      title: AmenityGroupTitleEnum.KITCHEN,
      traits: [
        {
          trait: TraitTypeEnum.fridge,
        },
        {
          trait: TraitTypeEnum.freezer,
        },
        {
          trait: TraitTypeEnum.oven,
        },
        {
          trait: TraitTypeEnum.stove,
        },
        {
          trait: TraitTypeEnum.dish_washer,
        },
        {
          trait: TraitTypeEnum.microwave_oven,
        },
        {
          trait: TraitTypeEnum.kitchenette,
        },
      ],
    },
    {
      title: AmenityGroupTitleEnum.BATHING,
      traits: [
        {
          trait: TraitTypeEnum.shower,
        },
        {
          trait: TraitTypeEnum.toilet,
        },
        {
          trait: TraitTypeEnum.bathtub,
        },
      ],
    },
    {
      title: AmenityGroupTitleEnum.WASHING,
      traits: [
        {
          trait: TraitTypeEnum.washing_machine,
        },
        {
          trait: TraitTypeEnum.tumble_dryer,
        },
        {
          trait: TraitTypeEnum.shared_washing_area,
        },
        {
          trait: TraitTypeEnum.drying_room,
        },
      ],
    },
    {
      title: AmenityGroupTitleEnum.EXTRA,
      traits: [
        ...(marketName === MarketNameEnum.finland
          ? [
              {
                trait: TraitTypeEnum.furniture,
                icon: <FurnitureIcon size={20} />,
              },
            ]
          : []),
        {
          trait: TraitTypeEnum.balcony,
          icon: <BalconyIcon size={20} />,
        },
        {
          trait: TraitTypeEnum.french_balcony,
          icon: <BalconyIcon size={20} />,
        },
        {
          trait: TraitTypeEnum.patio,
          icon: <BalconyIcon size={20} />,
        },
        {
          trait: TraitTypeEnum.inhome_sauna,
          icon: <SaunaIcon size={20} />,
        },
        {
          trait: TraitTypeEnum.communal_sauna,
          icon: <SaunaIcon size={20} />,
        },
        {
          trait: TraitTypeEnum.jacuzzi,
          icon: <JacuzziIcon size={20} />,
        },
        {
          trait: TraitTypeEnum.indoor_fireplace,
          icon: <FirePlaceIcon size={20} />,
        },
        {
          trait: TraitTypeEnum.pool,
          icon: <PoolIcon size={20} />,
        },
      ],
    },
    {
      title: AmenityGroupTitleEnum.TECHNOLOGY,
      traits: [
        {
          trait: TraitTypeEnum.internet,
        },
        {
          trait: TraitTypeEnum.television,
        },
      ],
    },
    {
      title: AmenityGroupTitleEnum.PARKING_FACILITIES,
      traits: [
        {
          trait: TraitTypeEnum.bike_room,
        },
        {
          trait: TraitTypeEnum.storage,
        },
        {
          trait: TraitTypeEnum.elevator,
        },
        {
          trait: TraitTypeEnum.electric_car_charger,
        },
        {
          trait: TraitTypeEnum.parking_included,
        },
        {
          trait: TraitTypeEnum.parking,
        },
        {
          trait: TraitTypeEnum.recycling,
        },
        {
          trait: TraitTypeEnum.security_door,
        },
        {
          trait: TraitTypeEnum.stroller_room,
        },
      ],
    },
    {
      title: AmenityGroupTitleEnum.RULES,
      traits: [
        {
          trait: TraitTypeEnum.pets,
          icon: <PetsIcon size={20} />,
        },
        {
          trait: TraitTypeEnum.smoker,
          icon: <SmokerIcon size={20} />,
        },
      ],
    },
    {
      title: AmenityGroupTitleEnum.ACCESSIBILITY,
      traits: [
        {
          trait: TraitTypeEnum.wheelchair_accessible,
          icon: <WheelchairIcon size={20} />,
        },
      ],
    },
  ]
}
