import { theme } from '@qasa/qds-ui'
import { css, keyframes } from '@emotion/react'

const OPEN_EASING = 'cubic-bezier(0.16, 1, 0.3, 1)'
const CLOSE_EASING = 'cubic-bezier(0.11, 0, 0.5, 0)'
const OPEN_DURATION_MOBILE = 400
const OPEN_DURATION_DESKTOP = 250
const CLOSE_DURATION_MOBILE = 200
const CLOSE_DURATION_DESKTOP = 80

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
})

const overlayHide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
})

export const OVERLAY_TRANSITION_STYLES = css`
  animation-fill-mode: forwards;
  &[data-state='open'] {
    animation-duration: ${OPEN_DURATION_MOBILE}ms;
    animation-name: ${overlayShow};
    ${theme.mediaQueries.mdUp} {
      animation-duration: ${OPEN_DURATION_DESKTOP}ms;
    }
  }
  &[data-state='closed'] {
    animation-duration: ${CLOSE_DURATION_MOBILE}ms;
    animation-name: ${overlayHide};
    ${theme.mediaQueries.mdUp} {
      animation-duration: ${CLOSE_DURATION_DESKTOP}ms;
    }
  }
`

const contentShowMobile = keyframes({
  '0%': { opacity: 0, transform: 'translate(0, 100%)' },
  '100%': { opacity: 1, transform: 'translate(0, 0)' },
})
const contentHideMobile = keyframes({
  '0%': { opacity: 1, transform: 'translate(0, 0)' },
  '100%': { opacity: 0, transform: 'translate(0, 100%)' },
})
const contentShowDesktop = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, calc(-50% + 16px))' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%)' },
})
const contentHideDesktop = keyframes({
  '0%': { opacity: 1, transform: 'translate(-50%, -50%)' },
  '100%': { opacity: 0, transform: 'translate(-50%, calc(-50% + 16px))' },
})

export const DIALOG_CONTENT_TRANSITION_STYLES = css`
  &[data-state='open'] {
    animation-timing-function: ${OPEN_EASING};
    animation-duration: ${OPEN_DURATION_MOBILE}ms;
    animation-name: ${contentShowMobile};
    ${theme.mediaQueries.mdUp} {
      animation-duration: ${OPEN_DURATION_DESKTOP}ms;
      animation-name: ${contentShowDesktop};
    }
  }
  &[data-state='closed'] {
    animation-timing-function: ${CLOSE_EASING};
    animation-duration: ${CLOSE_DURATION_MOBILE}ms;
    animation-name: ${contentHideMobile};
    ${theme.mediaQueries.mdUp} {
      animation-duration: ${CLOSE_DURATION_DESKTOP}ms;
      animation-name: ${contentHideDesktop};
    }
  }
`
