import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { RadioGroup, Stack, TextField } from '@qasa/qds-ui'
import { ResponsibleForCleaningEnum } from '@qasa/graphql'

import { FieldEnum } from '../edit-listing.types'
import { useListingContext } from '../listing-context'
import { SectionWrapper } from '../section-wrapper'
import { VisuallyHidden } from '../../../ui-shared/_core/visually-hidden'
import { getCurrencySymbol } from '../../../utils/misc'

export function CleaningSection() {
  const { t } = useTranslation('listing')
  const {
    dispatch,
    store: {
      values: { currency, responsibleForCleaning, cleaningFee },
    },
    getErrorMessage,
  } = useListingContext()

  const cleaningFeeErrorMessage = getErrorMessage({ field: FieldEnum.CleaningFee })
  const [currentCleaningFee, setCurrentCleaningFee] = useState(cleaningFee)

  const handleChangeCleaningOption = (nextValue: ResponsibleForCleaningEnum) => {
    dispatch({
      action: {
        type: FieldEnum.CleaningFee,
        payload: nextValue === ResponsibleForCleaningEnum.landlord_for_fee ? currentCleaningFee : 0,
      },
    })
    dispatch({
      action: {
        type: FieldEnum.ResponsibleForCleaning,
        payload: nextValue,
      },
    })
  }

  const handleSetCleaningFee = (nextValue: number | null) => {
    setCurrentCleaningFee(nextValue)
    dispatch({
      action: {
        type: FieldEnum.CleaningFee,
        payload: nextValue,
      },
    })
  }

  return (
    <SectionWrapper title={t('section_headings.cleaning')}>
      <RadioGroup
        name="cleaning"
        value={responsibleForCleaning ?? ''}
        onValueChange={(nextValue) => handleChangeCleaningOption(nextValue as ResponsibleForCleaningEnum)}
      >
        <VisuallyHidden>
          <RadioGroup.Label>{t('cleaning.label')}</RadioGroup.Label>
        </VisuallyHidden>
        <Stack gap={'2x'}>
          <RadioGroup.Card
            value={ResponsibleForCleaningEnum.landlord}
            label={t('cleaning.landlord_cleaning_free')}
            helperText={t('cleaning.landlord_cleaning_free_helper_text')}
          />
          <RadioGroup.Card
            value={ResponsibleForCleaningEnum.landlord_for_fee}
            label={t('cleaning.landlord_cleaning_fee')}
            helperText={t('cleaning.landlord_cleaning_fee_helper_text')}
          />
          <RadioGroup.Card
            value={ResponsibleForCleaningEnum.tenant}
            label={t('cleaning.guest_cleaning')}
            helperText={t('cleaning.guest_cleaning_helper_text')}
          />
        </Stack>
      </RadioGroup>
      {responsibleForCleaning === ResponsibleForCleaningEnum.landlord_for_fee && (
        <TextField
          label={t('cleaning.cleaning_fee_label')}
          type="number"
          inputMode="numeric"
          pattern="[0-9]*"
          onChange={(event) => {
            if (event.target.value === '') {
              handleSetCleaningFee(null)
            }
            const value = parseFloat(event.target.value)
            if (value) {
              handleSetCleaningFee(value)
            }
          }}
          value={currentCleaningFee ?? ''}
          placeholder={t('cleaning.cleaning_fee_placeholder')}
          suffix={getCurrencySymbol({ currency })}
          errorMessage={cleaningFeeErrorMessage ?? undefined}
        />
      )}
    </SectionWrapper>
  )
}
