import { useCallback, useEffect } from 'react'
import type {
  UpdateUserMutationMutation,
  UpdateUserMutationMutationVariables,
  UpsertTenantAdMutationMutation,
  UpsertTenantAdMutationMutationVariables,
} from '@qasa/graphql'

import { BRAND_CONFIG } from '../../../config/brand-configurations'
import { useProtectedAuthContext } from '../../../context/protected-auth-context'
import { UPDATE_USER } from '../../../data/graphql/mutations'
import { serializeProfileSearchPreference } from '../../profile'
import { UPSERT_TENANT_AD } from '../../profile/hooks/tenant-ad.gql'
import type { CreateTenantListingFormValues } from '../step-declarations'

import { PROFILE_FOR_CREATE_TENANT_LISTING } from './profile-for-create-tenant-listing.gql'
import { useMemoedMutation } from './use-memoed-mutation'

const MAX_MUTATION_INTERVAL = 1_000

type UseMutationOnFormChangeParams = {
  formValues: CreateTenantListingFormValues
}
export function useMutationOnFormChange({ formValues }: UseMutationOnFormChangeParams) {
  const { authBody } = useProtectedAuthContext()
  const { countryCode } = BRAND_CONFIG

  const profileValues = {
    ...formValues['about-me'],
    ...formValues['profile-intro'],
  }
  const [updateUserMutation] = useMemoedMutation<
    UpdateUserMutationMutation,
    UpdateUserMutationMutationVariables
  >(UPDATE_USER, { variables: { input: profileValues, uid: authBody.uid } })

  const [upsertTenantAdMutation] = useMemoedMutation<
    UpsertTenantAdMutationMutation,
    UpsertTenantAdMutationMutationVariables
  >(UPSERT_TENANT_AD, {
    // If no tenant ad already existed we need to manually write it to the cache
    update: (cache, { data }) => {
      const existingProfile = cache.readQuery({
        query: PROFILE_FOR_CREATE_TENANT_LISTING,
        variables: {
          uid: authBody.uid,
        },
      })
      const existingTenantAd = existingProfile?.user?.tenantAd
      const newTenantAd = data?.upsertTenantAd?.tenantAd

      if (existingProfile?.user && !existingTenantAd && newTenantAd) {
        cache.writeQuery({
          query: PROFILE_FOR_CREATE_TENANT_LISTING,
          data: {
            ...existingProfile,
            user: {
              ...existingProfile?.user,
              tenantAd: {
                ...newTenantAd,
                searchPreference: {
                  ...newTenantAd.searchPreference,
                  countryCode: countryCode.toUpperCase(),
                },
              },
            },
          },
        })
      }
    },
  })

  const handleMutateValues = useCallback(async () => {
    await updateUserMutation()

    const searchPreferenceValues = {
      ...formValues['location'],
      ...formValues['home-type'],
      ...formValues['size'],
      ...formValues['dates'],
      ...formValues['requirements'],
      ...formValues['preferences'],
      ...formValues['rent'],
    }
    const serializedSearchPreference = await serializeProfileSearchPreference(searchPreferenceValues)
    upsertTenantAdMutation({ input: { searchPreference: { ...serializedSearchPreference, countryCode } } })
  }, [formValues, updateUserMutation, upsertTenantAdMutation, countryCode])

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleMutateValues()
    }, MAX_MUTATION_INTERVAL)

    return () => clearTimeout(timeout)
  }, [handleMutateValues])

  return { triggerMutation: handleMutateValues }
}

export type UseMutationOnFormChangeReturn = ReturnType<typeof useMutationOnFormChange>
