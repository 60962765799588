import axios from 'axios'

const createFormDataContent = (file: File, fields: Record<string, string | Blob>) => {
  const formData = new FormData()
  Object.entries(fields).forEach(([key, value]) => formData.append(key, value))
  formData.append('file', file)

  return formData
}

export const uploadToS3 = async (file: File, url: string, fields: Record<string, string | Blob>) => {
  const uploadPayload = createFormDataContent(file, { ...fields, acl: 'private' })

  const { data }: { data: LegitimateAny } = await axios.post(url, uploadPayload)
  //TODO: maybe we need to handle error when uploading to S3 endpoint

  return {
    location: data.match(/<Location>(.+)<\/Location>/)[1],
    bucket: data.match(/<Bucket>(.+)<\/Bucket>/)[1],
    etag: data.match(/<ETag>(.+)<\/ETag>/)[1],
    key: data.match(/<Key>(.+)<\/Key>/)[1],
  }
}
