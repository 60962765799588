import { graphql } from '@qasa/graphql'

export const UPSERT_BANK_ACCOUNT = graphql(`
  mutation UpsertBankAccount($id: ID, $input: UpsertBankAccountInput!) {
    upsertBankAccount(input: $input, id: $id) {
      bankAccount {
        ...BankAccountFields
      }
      errors {
        field
        codes
      }
    }
  }
`)
