import styled from '@emotion/styled'

export const PageWidthContainer = styled.div<{ maxWidth?: 'wider' | 'widest' }>(({ theme, maxWidth }) => ({
  maxWidth: theme.sizes[768],
  width: `calc(100% - ${theme.spacing['8x']})`,
  marginLeft: 'auto',
  marginRight: 'auto',
  ...(maxWidth === 'wider' && { maxWidth: theme.sizes[1024] }),
  // NOTE: use theme value when supported sizes that large
  ...(maxWidth === 'widest' && { maxWidth: 1440 }),
}))
