import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import isNull from 'lodash/isNull'
import { Heading, LoadingDots, Stack, ImageIcon, Paragraph } from '@qasa/qds-ui'
import { OwnerTypeEnum, UploadTypeEnum } from '@qasa/graphql'

import { useUploadImage } from '../../../hooks/use-upload-image'
import { FileUpload } from '../../../ui-shared/_core/file-upload'
import { useListingContext } from '../listing-context'
import { ImagesList } from '../upload-images-list'
import { useOptimisticUpdateOfHomeUpload } from '../use-optimistic-image-upload'
import { SectionWrapper } from '../section-wrapper'
import { useEffectOnMount } from '../../../hooks/use-effect-on-mount'

import { NewUploadRefFragment } from './upload-images.gql'

const ImagesInput = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing['6x'],
  paddingBottom: theme.spacing['6x'],
  borderRadius: theme.radii.md,
  border: '2px dashed',
  borderColor: theme.colors.border.default,
}))

const ImageUploadHint = styled(Paragraph)(({ theme }) => ({
  marginTop: `-${theme.spacing['4x']}`,
}))

// NOTE: These limits are derived from the backend; api/app/models/upload.rb
const MAX_IMAGE_SIZE = '30MB'
const SUPPORTED_IMAGE_FORMATS = ['PNG', 'JPG']

export function UploadImages() {
  const { t } = useTranslation('listing')
  const { homeId }: { homeId: string } = useParams()
  const {
    store: {
      values: { uploads },
    },
  } = useListingContext()
  const [uploadHomeImage, { isLoading }] = useUploadImage({
    ownerType: OwnerTypeEnum.HOME,
    uploadType: UploadTypeEnum.home_picture,
    ownerId: homeId,
    cacheUpdateFunction: (cache, { data }) => {
      cache.modify({
        id: cache.identify({ id: homeId, __typename: 'Home' }),
        fields: {
          uploads(existingUploadRefs = []) {
            const newUploadRef = cache.writeFragment({
              data: data?.finalizeUpload?.upload,
              fragment: NewUploadRefFragment,
            })
            return [...existingUploadRefs, newUploadRef]
          },
        },
      })
    },
  })
  const updateUpload = useOptimisticUpdateOfHomeUpload()

  const sortedUploads = [...uploads].sort((a, b) => {
    /* higher value `order` sorts after lower value, null values are last */
    if (isNull(a.metadata?.order)) {
      return 1
    }
    if (isNull(b.metadata?.order)) {
      return -1
    }
    const firstValue = a.metadata?.order ?? 0
    const secondValue = b.metadata?.order ?? 0

    return firstValue - secondValue
  })
  useEffectOnMount(() => {
    /* Set ordered metadata.order from 1 on all uploads that for any reason may not have it  */
    let lastOrderValue = 1
    sortedUploads.forEach((upload) => {
      if (!upload.metadata?.order || upload.metadata.order <= lastOrderValue) {
        updateUpload({ variables: { id: upload.id, input: { metadata: { order: lastOrderValue++ } } } })
      } else if (upload.metadata?.order) {
        lastOrderValue = upload.metadata.order
      }
    })
  })

  const lastImageOrder = sortedUploads[sortedUploads.length - 1]?.metadata?.order ?? 0

  return (
    <SectionWrapper title={t('section_headings.images')} description={t('images.subheading')}>
      <FileUpload
        shouldAllowMultiple
        isLoading={isLoading}
        accept={'image/png, image/jpeg'}
        uploadApi={uploadHomeImage}
        initialMetadata={{ order: lastImageOrder + 1 }}
      >
        <ImagesInput direction="row" gap="3x" justifyContent="center" alignItems="center">
          <ImageIcon />
          <Heading size="2xs" as="span">
            {t('images.input_field_placeholder')}
          </Heading>
        </ImagesInput>
      </FileUpload>
      <ImageUploadHint color="subtle" size="xs">
        {t('images.input_limits_hint', {
          maxFileSize: MAX_IMAGE_SIZE,
          supportedImageFormats: SUPPORTED_IMAGE_FORMATS,
        })}
      </ImageUploadHint>
      <ImagesList uploads={sortedUploads} />
      {isLoading && (
        <Stack alignItems="center">
          <LoadingDots />
        </Stack>
      )}
    </SectionWrapper>
  )
}
