import styled from '@emotion/styled'
import { Paragraph, Heading, Stack, createLucideIcon, Link } from '@qasa/qds-ui'
import { Trans } from 'react-i18next'
import type { PropsWithChildren } from 'react'
import { ShieldCheck } from 'lucide-react'

import { EXTERNAL_LINKS } from '../../../config/external-links'

const ShieldCheckIcon = createLucideIcon(ShieldCheck)

function UspListRoot({ children }: PropsWithChildren) {
  return <Stack gap={'6x'}>{children}</Stack>
}

const IconContainer = styled.div({
  flexShrink: 0,
})

export type UspItemProps = {
  title: string
  description: string
}
function UspItem({ title, description }: UspItemProps) {
  return (
    <Stack direction="row" gap={'2x'}>
      <IconContainer>
        <ShieldCheckIcon color="positive" size={20} />
      </IconContainer>
      <Stack gap={'1x'}>
        <Heading size="2xs">{title}</Heading>
        <Paragraph size="sm" color="subtle">
          <Trans components={[<Link key="0" href={EXTERNAL_LINKS.depositFreeSupportUrl!} isExternal />]}>
            {description}
          </Trans>
        </Paragraph>
      </Stack>
    </Stack>
  )
}
export const UspList = Object.assign(UspListRoot, { Item: UspItem })
