import { useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import type { WizardRouterProps } from './wizard-router'

export const useWizardSteps = <TStepName extends string>({
  basePath,
  steps,
}: Omit<WizardRouterProps<TStepName>, 'onIndexChange'>) => {
  const { pathname } = useLocation()
  const { push, goBack: browserGoBack, replace } = useHistory()

  const currentPath = pathname.split('/').pop()!
  const currentIndex = steps.findIndex((step) => step.name === currentPath)
  const currentStep = steps?.[currentIndex]

  const previousStep = steps?.[currentIndex - 1]
  const hasPreviousStep = Boolean(previousStep)
  const previousPathname = [basePath, previousStep?.name].join('/')

  const nextStep = steps?.[currentIndex + 1]
  const hasNextStep = Boolean(nextStep)
  const nextPathname = [basePath, nextStep?.name].join('/')

  const entryIndex = useRef(currentIndex)

  useEffect(() => {
    // We move the "pointer" of the entry if user goes back
    if (currentIndex < entryIndex.current) {
      entryIndex.current = currentIndex
    }
  }, [currentIndex])

  const goBack = () => {
    if (!hasPreviousStep) return

    if (currentIndex > entryIndex.current) {
      browserGoBack()
    } else {
      replace({ pathname: previousPathname })
    }
  }

  const goNext = () => {
    if (!hasNextStep) {
      push('/profile/')
      return
    }

    push({ pathname: nextPathname })
  }

  return { currentStep, goBack, goNext, currentIndex }
}
