import { useAppTranslation } from '../../../../../contexts/i18next'
import { useFindHomeFiltersContext } from '../../../contexts'
import { FilterCheckboxGroup } from '../../components/filter-checkbox-group.web'

export function LocationPerksFilterSection() {
  const { filterValues, updateFilterValues } = useFindHomeFiltersContext()
  const { t } = useAppTranslation('amenities')

  const handleFilterChange = (newValue: string[]) => updateFilterValues({ locationPerks: newValue })

  const options = [
    { value: 'nearBeach', label: t('traits.near_beach') },
    { value: 'skiInOut', label: t('traits.ski_in_out') },
    { value: 'nearFishing', label: t('traits.near_fishing') },
    { value: 'nearGolf', label: t('traits.near_golf') },
    { value: 'nearHikingRoutes', label: t('traits.near_hiking_routes') },
    { value: 'archipelago', label: t('traits.archipelago') },
    { value: 'remote', label: t('traits.remote') },
    { value: 'nearRestaurants', label: t('traits.near_restaurants') },
  ]

  return (
    <FilterCheckboxGroup
      label={t('location_perks.title', { ns: 'filter_sections' })}
      isLabelVisuallyHidden
      values={filterValues.locationPerks}
      onChange={handleFilterChange}
      options={options}
    />
  )
}
