import { useTranslation } from 'react-i18next'
import { useBreakpointValue } from '@qasa/qds-ui'

import imageDesktop from '../../../assets/images/chapters/image-chapter-2-desktop.png'
import imageMobile from '../../../assets/images/chapters/image-chapter-2-mobile.png'

import { ChapterIntro } from './chapter-intro'
type Props = {
  previousSection: string
  nextSection: string
}

export function ListingDetailsChapterIntro(props: Props) {
  const { t } = useTranslation('listing')
  const chapterImage = useBreakpointValue({ base: imageMobile, md: imageDesktop })

  return (
    <ChapterIntro
      title={t('chapters.listing_details_chapter.title')}
      description={t('chapters.listing_details_chapter.description')}
      overline={t('chapters.listing_details_chapter.overline')}
      image={chapterImage}
      {...props}
    />
  )
}
