import styled from '@emotion/styled'

const Separator = styled.span({
  paddingInline: '0.5ch',
  paddingBlock: 0,
})

export function SlashSeparator() {
  return <Separator>{'/'}</Separator>
}
