import omit from 'lodash/omit'
import type { SelectedAreaWithSearchAreaData } from '@qasa/app/src/types/find-home'
import type { ProfileQueryQuery } from '@qasa/graphql'

import {
  ProfileContractTypeEnum,
  ProfileFurnishedEnum,
  ProfilePreferredAmenitiesEnum,
  ProfileRequiredAmenitiesEnum,
  ProfileSharedHomeEnum,
} from '../profile.types'

type ParseProfileSearchPreferenceParams = {
  searchPreference:
    | Partial<UNSAFE_DeepNonNullableObject<ProfileQueryQuery>['user']['tenantAd']['searchPreference']>
    | null
    | undefined
}
export const parseProfileSearchPreference = ({ searchPreference }: ParseProfileSearchPreferenceParams) => {
  const {
    /* eslint-disable @typescript-eslint/naming-convention */
    furniture: furnitureField,
    shared: sharedField,
    householdSize,
    balcony,
    bathtub,
    bikeRoom,
    dishWasher,
    inhomeSauna,
    parking,
    recycling,
    storage,
    tumbleDryer,
    washingMachine,
    smoker,
    wheelchairAccessible,
    pets,
    minRoomCount,
    minSquareMeters,
    homeType,
    maxMonthlyCost,
    maxMonthlyCostCents,
    currency,
    startOptimal,
    endOptimal,
    seniorHome,
    studentHome,
    searchAreas,
  } = searchPreference || {}

  const parseFurnitureField = () => {
    switch (furnitureField) {
      case true:
        return ProfileFurnishedEnum.furnished
      case false:
        return ProfileFurnishedEnum.unfurnished
      default:
        return ProfileFurnishedEnum.both
    }
  }

  const parseSharedField = () => {
    switch (sharedField) {
      case true:
        return ProfileSharedHomeEnum.all
      case false:
        return ProfileSharedHomeEnum.onlyPrivate
      default:
        return ProfileSharedHomeEnum.all
    }
  }

  const parseContractType = () => {
    if (seniorHome) {
      return ProfileContractTypeEnum.senior
    } else if (studentHome) {
      return ProfileContractTypeEnum.student
    } else {
      return ProfileContractTypeEnum.none
    }
  }

  const preferredAmenities = [
    ...(balcony ? [ProfilePreferredAmenitiesEnum.balcony] : []),
    ...(bathtub ? [ProfilePreferredAmenitiesEnum.bathtub] : []),
    ...(bikeRoom ? [ProfilePreferredAmenitiesEnum.bikeRoom] : []),
    ...(dishWasher ? [ProfilePreferredAmenitiesEnum.dishWasher] : []),
    ...(inhomeSauna ? [ProfilePreferredAmenitiesEnum.inhomeSauna] : []),
    ...(parking ? [ProfilePreferredAmenitiesEnum.parking] : []),
    ...(recycling ? [ProfilePreferredAmenitiesEnum.recycling] : []),
    ...(storage ? [ProfilePreferredAmenitiesEnum.storage] : []),
    ...(tumbleDryer ? [ProfilePreferredAmenitiesEnum.tumbleDryer] : []),
    ...(washingMachine ? [ProfilePreferredAmenitiesEnum.washingMachine] : []),
  ]

  const requiredAmenities = [
    ...(pets ? [ProfileRequiredAmenitiesEnum.pets] : []),
    ...(smoker ? [ProfileRequiredAmenitiesEnum.smoker] : []),
    ...(wheelchairAccessible ? [ProfileRequiredAmenitiesEnum.wheelchairAccessible] : []),
  ]

  const selectedAreas: SelectedAreaWithSearchAreaData[] =
    searchAreas?.map((searchArea) => omit(searchArea, '__typename')) || []

  return {
    householdSize: householdSize || null,
    minRoomCount: minRoomCount || null,
    minSquareMeters: minSquareMeters || null,
    furniture: parseFurnitureField(),
    shared: parseSharedField(),
    preferredAmenities,
    requiredAmenities,
    homeType: homeType || [],
    maxMonthlyCost: maxMonthlyCost || null,
    maxMonthlyCostCents: maxMonthlyCostCents || null,
    currency,
    moveIn: startOptimal || null,
    moveOut: endOptimal || null,
    contractType: parseContractType(),
    selectedAreas,
  }
}

export type ParsedProfileSearchPreference = ReturnType<typeof parseProfileSearchPreference>
