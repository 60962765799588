/**
 * sorting-select consists of a button that looks like a select, and if you press
 * the button you'll be shown a popover or drawer with your options, depending on
 * if you're on desktop or mobile.
 */
import { styled } from '@qasa/ui/src/styled/styled' // TODO: What's x-platform/native version of this?
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, useBreakpointValue } from '@qasa/qds-ui'
import { useState } from 'react'
import { Stack } from '@qasa/ui'

import { Popover } from '../web'

import { SortingDialog } from './sorting-dialog'

const StyledButton = styled('button')<{ isActive: boolean }>(({ theme, isActive }) => ({
  // Offset the button
  marginRight: `-${theme.spacing['2x']}`,
  height: 36,
  paddingInline: theme.spacing['4x'],
  borderRadius: theme.radii.full,
  backgroundColor: theme.colors.bg.default,
  color: theme.colors.text.default,
  ...theme.typography.label.md,
  '&:hover': {
    backgroundColor: theme.colors.bg.brandTertiaryHover,
  },
  ...(isActive && {
    backgroundColor: theme.colors.bg.brandTertiary,
  }),
}))

const OptionButton = styled('button')<{ isActive: boolean }>(({ theme, isActive }) => ({
  display: 'flex',
  width: '100%',
  ...theme.typography.label.md,
  paddingBlock: theme.spacing['2x'],
  paddingInline: theme.spacing['8x'],
  '&:hover': {
    backgroundColor: theme.colors.core.offWhite,
  },
  ...(isActive && {
    fontWeight: theme.typography.title.md.fontWeight,
  }),
}))

const PopoverContent = styled(Popover.Content)(({ theme }) => ({
  borderRadius: theme.radii.lg,
  minWidth: 'max-content',
  width: 150,
}))

const PopoverBody = styled(Popover.Body)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBlock: theme.spacing['4x'],
}))

export type SortingSelectProps = {
  /**
   * Function that is called when the selected option changes. Receives the new value as a string.
   */
  onChange: (value: string) => void
  /**
   * List of options.
   */
  options: { label: string; value: string }[]
  /**
   * Currently selected option.
   */
  value: string
  /**
   * Description of the select for screen readers.
   */
  ariaLabel: string
}

export function SortingSelect({ onChange, options, value, ariaLabel }: SortingSelectProps) {
  const [isOpen, setIsOpen] = useState(false)
  const isMdOrAbove = useBreakpointValue({ base: false, md: true })

  const handleOptionClick = (selectedValue: string) => {
    const previousValue = value
    if (selectedValue !== previousValue) {
      onChange(selectedValue)
    }
    setIsOpen(false)
  }

  const Icon = isOpen
    ? () => <ChevronUpIcon size={16} aria-hidden="true" />
    : () => <ChevronDownIcon size={16} aria-hidden="true" />

  const triggerButton = (
    <StyledButton
      value={value}
      isActive={isOpen}
      aria-haspopup={'true'}
      aria-label={ariaLabel}
      aria-expanded={isOpen}
    >
      <Stack direction="row" gap="2x" alignItems="center">
        {options.find((option) => option.value === value)?.label ?? ariaLabel}
        <Icon />
      </Stack>
    </StyledButton>
  )

  function OptionButtons({ options }: { options: SortingSelectProps['options'] }) {
    return (
      <>
        {options.map((option) => (
          <OptionButton
            key={option.value}
            isActive={option.value === value}
            onClick={() => handleOptionClick(option.value)}
          >
            <Stack gap="2x" direction="row" alignItems="center">
              {option.value === value && <CheckIcon aria-hidden="true" size={16} />}
              {option.label}
            </Stack>
          </OptionButton>
        ))}
      </>
    )
  }

  // Render starts here
  if (isMdOrAbove) {
    return (
      <Popover isOpen={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
        <Popover.Trigger>
          {/* Rendering this as <TriggerButton /> makes it so it doesn't trigger the popover when clicked.
                Wrapping it in a <span> fixes that, but then the alignment of the popover content is wrong. */}
          {triggerButton}
        </Popover.Trigger>
        <PopoverContent align="end">
          <PopoverBody>
            <OptionButtons options={options} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    )
  }
  return (
    <SortingDialog isOpen={isOpen} setIsOpen={setIsOpen} trigger={triggerButton}>
      <OptionButtons options={options} />
    </SortingDialog>
  )
}
