import { Stack, styled } from '@qasa/ui'
import type { SmallHomeCardFragment } from '@qasa/graphql'
import { graphql } from '@qasa/graphql'

import { Link } from '../../components/link'
import { getPrimaryUpload, prependSrcWithThumbor } from '../../utils/image'
import { HOME_PLACEHOLDER_IMAGE_SRC } from '../../assets/home-placeholder'

import { SmallHomeCardInfo } from './small-home-card-info'
import { SmallHomeCardImage } from './small-home-card-image'

graphql(`
  fragment SmallHomeCard on Home {
    id
    ...GetPrimaryUpload
    ...SmallHomeCardInfo
  }
`)

const StyledLink = styled(Link)({
  textDecorationLine: 'none',
})

type SmallHomeCardProps = {
  home: SmallHomeCardFragment
  isLinkToHome?: boolean
}
export function SmallHomeCard({ home, isLinkToHome = false }: SmallHomeCardProps) {
  const { uploads, id } = home

  const primaryUpload = getPrimaryUpload({ uploads })?.url
  const homeImageSrc = primaryUpload
    ? prependSrcWithThumbor({
        src: primaryUpload,
        height: 200,
        width: 200,
      })
    : HOME_PLACEHOLDER_IMAGE_SRC

  const homeCard = (
    <Stack direction="row" gap="4x" alignItems="center" justifyContent="flex-start">
      <SmallHomeCardImage src={homeImageSrc} />
      <SmallHomeCardInfo home={home} />
    </Stack>
  )

  if (isLinkToHome) {
    return (
      <StyledLink to={'Home'} params={{ id }}>
        {homeCard}
      </StyledLink>
    )
  }

  return homeCard
}
