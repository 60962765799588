import { DropdownMenu, IconButton, MoreHorizontalIcon } from '@qasa/qds-ui'

type ActionsDropdownMenuProps = {
  items: { label: string; onClick: () => void }[]
}
export function ActionsDropdownMenu({ items }: ActionsDropdownMenuProps) {
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger
        as={IconButton}
        variant="tertiary"
        size="xs"
        icon={MoreHorizontalIcon}
        label="open-actions-menu"
      />
      <DropdownMenu.Content align="end" style={{ zIndex: 1500 }}>
        {items.map(({ label, onClick }) => (
          <DropdownMenu.Item key={label} onSelect={onClick}>
            {label}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}
