import { Stack, Heading, styled } from '@qasa/ui'
import type { SmallHomeCardInfoFragment } from '@qasa/graphql'
import { graphql } from '@qasa/graphql'

import { SlashSeparatedData } from '../../components/slash-separated-data'
import { useAppTranslation } from '../../contexts/i18next'
import { getFormattedAddress, useHomeBaseInfo } from '../../utils/home'
import { formatNumber } from '../../utils/number'

graphql(`
  fragment SmallHomeCardInfo on Home {
    id
    location {
      id
      route
      streetNumber
      locality
    }
    displayStreetNumber
    rent
    currency
    landlord {
      uid
    }
    ...UseHomeBaseInfo
  }
`)
const Content = styled(Stack)({
  overflow: 'hidden',
})

export function SmallHomeCardInfo({ home }: { home: SmallHomeCardInfoFragment }) {
  const { t } = useAppTranslation('common_home')
  const { rent, currency } = home
  const formattedAddress = getFormattedAddress({
    location: home.location,
    shouldDisplayStreetNumber: home.displayStreetNumber,
  })
  const { mainBullets } = useHomeBaseInfo(home)

  return (
    <Content gap="1x">
      <Heading size="2xs" numberOfLines={1}>
        {formattedAddress}
      </Heading>
      <SlashSeparatedData textSize="sm" data={mainBullets} />
      <Heading size="2xs">
        {rent ? formatNumber({ amount: rent, currency }) : t('other.rent_not_set')}
      </Heading>
    </Content>
  )
}
