import styled from '@emotion/styled'
import { createIcon } from '@qasa/qds-ui'

const ShieldCheckIcon = createIcon({
  displayName: 'ShieldCheckIcon',
  viewBox: '0 0 512 512',
  d: 'M269.4 2.9C265.2 1 260.7 0 256 0s-9.2 1-13.4 2.9L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L269.4 2.9zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z',
})

export const VerificationIconPro = styled(ShieldCheckIcon)<{ size?: number }>(({ size }) => ({
  marginLeft: 8,
  marginRight: 8,

  fontSize: size || 20,
}))
