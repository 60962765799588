import { useTranslation } from 'react-i18next'

import { Form } from '../components/form'
import { StepNavigation } from '../components/step-navigation'
import { WizardStepWrapper } from '../components/step-wrapper'
import { requiredFormField } from '../create-tenant-listing.utils'
import { FormInputWithUnitField } from '../fields/form-input-with-unit-field'
import { FormSelectField } from '../fields/form-select-field'
import { useWizardRouterContext } from '../wizard-router/wizard-router'

export function SizeStep() {
  const { goNext } = useWizardRouterContext()
  const { t } = useTranslation('create_tenant_listing')
  const numberOfTenantsOptions = [1, 2, 3, 4, 5, 6, 7].map((value) => {
    return { value, label: value.toString() }
  })
  const numberOfRoomsOptions = [1, 2, 3, 4, 5, 6, 7].map((value) => {
    return { value, label: value + '+' }
  })
  return (
    <WizardStepWrapper>
      <Form dataKey="size" onSubmit={goNext}>
        {({ control, formState: { errors } }) => (
          <>
            <FormSelectField
              control={control}
              options={numberOfTenantsOptions}
              label={t('fields.number_of_tenants.title')}
              placeholder={t('fields.number_of_tenants.placeholder')}
              name={'householdSize'}
              rules={requiredFormField}
              errorMessage={errors['householdSize'] && t('fields.number_of_tenants.required_error')}
            />
            <FormSelectField
              name={'minRoomCount'}
              control={control}
              label={t('fields.number_of_rooms.label')}
              placeholder={t('fields.number_of_rooms.placeholder')}
              options={numberOfRoomsOptions}
              rules={requiredFormField}
              errorMessage={errors['minRoomCount'] && t('fields.number_of_rooms.required_error')}
            />
            <FormInputWithUnitField
              label={t('fields.square_meters.label')}
              name={'minSquareMeters'}
              control={control}
              unit={t('commons:square_meters')}
              placeholder={t('fields.square_meters.placeholder')}
              rules={requiredFormField}
              errorMessage={errors['minSquareMeters'] && t('fields.square_meters.required_error')}
            />
            <StepNavigation />
          </>
        )}
      </Form>
    </WizardStepWrapper>
  )
}
