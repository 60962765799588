import { useTranslation } from 'react-i18next'
import { Select } from '@qasa/qds-ui'

import { useListingContext } from '../listing-context'
import { SectionWrapper } from '../section-wrapper'
import type { UpdateFieldValueAction } from '../edit-listing.types'
import { BooleanInput } from '../../../ui-shared/_core/controls/boolean-input'
import { numberFromString } from '../listing.utils'

import { InputFieldType, useGetAreasSectionFields } from './use-get-areas-section-fields'

export function AreasSection() {
  const { t } = useTranslation('listing')
  const {
    store: { values },
    dispatch,
  } = useListingContext()

  const areasSectionInputFields = useGetAreasSectionFields()

  return (
    <SectionWrapper title={t(values.shared ? 'section_headings.areas_shared' : 'section_headings.areas')}>
      {areasSectionInputFields
        .filter((i) => i.isShown)
        .map(({ inputType, title, placeholder, value, fieldEnum, error, options }) => {
          switch (inputType) {
            case InputFieldType.SELECT:
              return (
                <Select
                  key={title}
                  label={t(title)}
                  placeholder={placeholder ? t(placeholder) : undefined}
                  value={(value as number) ?? ''}
                  onChange={(e) =>
                    dispatch({
                      action: {
                        type: fieldEnum,
                        payload: numberFromString(e.target.value),
                      } as UpdateFieldValueAction,
                    })
                  }
                  isInvalid={Boolean(error)}
                  errorMessage={error ?? undefined}
                >
                  {options.map(({ value, label }) => (
                    <Select.Option key={value} value={value}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              )
            case InputFieldType.BOOLEAN_RADIO:
              return (
                <BooleanInput
                  key={title}
                  label={t(title)}
                  name={t(title)}
                  value={Boolean(value)}
                  positiveLabel={options.find(({ value }) => value === 1)!.label}
                  negativeLabel={options.find(({ value }) => value === 0)!.label}
                  onChange={(value) => {
                    dispatch({
                      action: {
                        type: fieldEnum,
                        payload: value,
                      } as UpdateFieldValueAction,
                    })
                  }}
                />
              )
            default:
              return null
          }
        })}
    </SectionWrapper>
  )
}
