import { useTranslation } from 'react-i18next'
import { Paragraph } from '@qasa/qds-ui'

type EmptyFieldProps = {
  colorVariant?: 'subtle' | 'negative'
}
export function EmptyField({ colorVariant = 'subtle' }: EmptyFieldProps) {
  const { t } = useTranslation('profile')
  return <Paragraph color={colorVariant}>{t('empty_field')}</Paragraph>
}
