import type { MouseEventHandler } from 'react'
import { ArrowLeftIcon, ArrowRightIcon } from '@qasa/qds-ui'
import { styled } from '@qasa/ui/web'

type ArrowButtonProps = {
  placement: 'left' | 'right'
  isShowing: boolean
}

const ArrowButton = styled('button')<ArrowButtonProps>(({ placement, isShowing, theme }) => ({
  appearance: 'none',
  border: 'none',
  padding: 0,
  borderRadius: 9999,
  background: theme.colors.bg.default,
  width: 40,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  [placement]: theme.spacing['4x'],
  opacity: isShowing ? 1 : 0,
  transform: 'translateY(-50%)',
  '&:hover': {
    cursor: 'pointer',
    background: theme.colors.bg.brandTertiaryActive,
  },
  willChange: 'background-color',
  transition: 'background-color 0.15s ease-in-out',
}))

type NavigationButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement> | undefined
} & ArrowButtonProps

function NavigationButton({ onClick, placement, isShowing }: NavigationButtonProps) {
  const IconDirection = placement === 'left' ? ArrowLeftIcon : ArrowRightIcon
  return (
    <ArrowButton onClick={onClick} isShowing={isShowing} placement={placement}>
      <IconDirection size={16} />
    </ArrowButton>
  )
}

type NavigationControlsProps = {
  onPreviousClick: MouseEventHandler<HTMLButtonElement>
  onNextClick: MouseEventHandler<HTMLButtonElement>
  hasPrevious?: boolean
  hasNext?: boolean
}

export function NavigationControls({
  onPreviousClick,
  onNextClick,
  hasPrevious = false,
  hasNext = false,
}: NavigationControlsProps) {
  return (
    <>
      <NavigationButton onClick={onPreviousClick} isShowing={hasPrevious} placement="left" />
      <NavigationButton onClick={onNextClick} isShowing={hasNext} placement="right" />
    </>
  )
}
