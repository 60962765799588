import { IntroSection } from './edit-profile-sections/intro-section'
import { HousingSituationSection } from './edit-profile-sections/housing-situation-section'
import { ProfileSectionEnum } from './profile.types'
import { AboutMeSection } from './edit-profile-sections/about-me-section'
import { RequiredAmenitiesSection } from './edit-profile-sections/required-amenities-section'
import { PreferredAmenitiesSection } from './edit-profile-sections/preferred-amenities-section'
import { useProfileContext } from './profile-context'
import { SearchPreferenceSection } from './edit-profile-sections/search-preference-section'
import { OccupationSection } from './edit-profile-sections/occupation-section'

export function ProfileEdit() {
  const { profile, activeEditSection } = useProfileContext()

  const renderEditElement = () => {
    switch (activeEditSection) {
      case ProfileSectionEnum.INTRO:
        return <IntroSection profile={profile} />
      case ProfileSectionEnum.ABOUT_ME:
        return <AboutMeSection />
      case ProfileSectionEnum.HOUSING_SITUATION:
        return <HousingSituationSection />
      case ProfileSectionEnum.OCCUPATION:
        return <OccupationSection />
      case ProfileSectionEnum.REQUIRED_AMENITIES:
        return <RequiredAmenitiesSection />
      case ProfileSectionEnum.PREFERRED_AMENITIES:
        return <PreferredAmenitiesSection />
      case ProfileSectionEnum.SEARCH_PREFERENCE:
        return <SearchPreferenceSection />
      default:
        return null
    }
  }
  return renderEditElement()
}
