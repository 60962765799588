import type { FavoriteHomeButtonFragment } from '@qasa/graphql'
import { graphql } from '@qasa/graphql'
import { useState } from 'react'
import { HeartFilledIcon, HeartIcon, IconButton, styled } from '@qasa/ui'
import { useMutation } from '@apollo/client'

import { useAppTranslation } from '../../contexts/i18next'
import { useCurrentUser } from '../../contexts/current-user'

const StyledHeartFilledIcon = styled(HeartFilledIcon)(({ theme }) => ({
  color: theme.colors.bg.brandPrimary,
}))

graphql(`
  fragment FavoriteHomeButton on Home {
    id
    favoriteMarkedByUser
  }
`)

const FAVORITE_MARK_HOME = graphql(`
  mutation FavoriteMarkHomeApp($homeId: ID!) {
    favoriteMarkHome(id: $homeId) {
      home {
        ...FavoriteHomeButton
      }
    }
  }
`)

const UNMARK_FAVORITE_HOME = graphql(`
  mutation UnmarkFavoriteHomeApp($homeId: ID!) {
    unmarkFavoriteHome(id: $homeId) {
      home {
        ...FavoriteHomeButton
      }
    }
  }
`)

function useFavoriteHomeButton({ home }: FavoriteHomeButtonProps) {
  const [isChecked, setIsChecked] = useState(home.favoriteMarkedByUser)

  const [favoriteMarkHome, { loading: isFavoriteLoading }] = useMutation(FAVORITE_MARK_HOME, {
    refetchQueries: ['FavoritesQuery', 'GetUserFavoriteHomes'],
    update: (cache) =>
      cache.modify({
        id: cache.identify({ id: home.id, __typename: 'Home' }),
        fields: {
          favoriteMarkedByUser() {
            return true
          },
        },
      }),
  })

  const [unmarkFavoriteHome, { loading: isUnfavoriteLoading }] = useMutation(UNMARK_FAVORITE_HOME, {
    refetchQueries: ['FavoritesQuery', 'GetUserFavoriteHomes'],
    update: (cache) =>
      cache.modify({
        id: cache.identify({ id: home.id, __typename: 'Home' }),
        fields: {
          favoriteMarkedByUser() {
            return false
          },
        },
      }),
  })

  const handleButtonPress = async () => {
    try {
      if (isChecked) {
        await unmarkFavoriteHome({ variables: { homeId: home.id } })
      } else {
        await favoriteMarkHome({ variables: { homeId: home.id } })
      }
      // TODO: add success toast with error when that is figured out
      setIsChecked(!isChecked)
    } catch (error) {
      // TODO: add toast with error when that is figured out
    }
  }

  return {
    handleButtonPress,
    isLoading: isFavoriteLoading || isUnfavoriteLoading,
  }
}

type FavoriteHomeButtonProps = {
  home: FavoriteHomeButtonFragment
}
export function FavoriteHomeButton({ home }: FavoriteHomeButtonProps) {
  const { handleButtonPress, isLoading } = useFavoriteHomeButton({ home })
  const { isAuthenticated } = useCurrentUser()
  const { t } = useAppTranslation('favorite_home_button')

  if (!isAuthenticated) {
    return null
  }

  const isChecked = home.favoriteMarkedByUser

  const favoriteIcon = isChecked ? StyledHeartFilledIcon : HeartIcon

  return (
    <IconButton
      size="sm"
      label={t('accessibility_label')}
      aria-checked={isChecked}
      role="checkbox"
      icon={favoriteIcon}
      onPress={handleButtonPress}
      isDisabled={isLoading}
    />
  )
}
