import { Stack, styled } from '@qasa/ui'

import { moderateScale } from '../../../utils/scale'

import { Dot, ProgressDots } from './carousel-progress-indicator-styles'
import { getIndicatorOffset } from './carousel-progress-indicator.utils'

type CarouselProgressIndicatorProps = {
  count: number
  currentIndex: number
  dotColor?: string
}
export function CarouselProgressIndicator({ count, currentIndex, dotColor }: CarouselProgressIndicatorProps) {
  if (count < 2) {
    return null
  }

  const horizontalOffset = getIndicatorOffset({ currentIndex, count })

  return (
    <Wrapper direction="row" justifyContent="center">
      <InnerWrapper direction="row">
        <ProgressDots horizontalOffset={horizontalOffset} direction="row">
          {[...Array(count)].map((_, index) => {
            const isActive = index === currentIndex
            return <StyledDot isActive={isActive} key={index} dotColor={dotColor} />
          })}
        </ProgressDots>
      </InnerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing['4x'],
  left: 0,
  width: '100%',
  pointerEvents: 'none',
}))
const InnerWrapper = styled(Stack)({
  maxWidth: moderateScale(84),
  overflow: 'hidden',
})
const StyledDot = styled(Dot)(({ theme }) => ({
  width: theme.spacing['2x'],
  height: theme.spacing['2x'],
  borderRadius: theme.radii.full,
  marginLeft: theme.spacing['1x'],
  marginRight: theme.spacing['1x'],
}))
