import type { StaticImageData } from 'next/image'

import { ENV } from './env'

// Default is 'crop', which fits the image to your exact proportions.
// To keep the original aspect ratio use 'fit-in' instead.
// Docs: (we use an older version than this) https://thumbor.readthedocs.io/en/latest/usage.html
export type ThumborFittingMethods = 'fit-in' | 'adaptive-fit-in' | 'full-fit-in' | 'crop'

type Props = {
  src: string | StaticImageData
  width?: number
  height?: number
  fittingMethod: ThumborFittingMethods
}

export const prependSrcWithThumbor = ({ src: srcProp, width, height, fittingMethod }: Props) => {
  // If it's a static import, we need to extract the src from the object.
  const src = typeof srcProp === 'string' ? srcProp : srcProp.src

  if (src.includes('qasa-static') && !src.includes('localhost')) {
    const urlPrefix = ENV.IMAGES_CDN_URL
    const resolution = `${width ?? 0}x${height ?? 0}/`
    const fit = fittingMethod === 'crop' ? '' : `${fittingMethod}/`
    const smartCrop = fittingMethod === 'crop' ? 'smart/' : ''

    return `${urlPrefix}${fit}${resolution}${smartCrop}${src}`
  } else {
    return src
  }
}
