import { graphql } from '@qasa/graphql'

export const REPORT_USER = graphql(`
  mutation ReportUser($uid: ID!, $reason: String!, $reportType: ReportTypeEnum) {
    reportUser(uid: $uid, reason: $reason, reportType: $reportType) {
      errors {
        field
        codes
      }
      userReport
    }
  }
`)
