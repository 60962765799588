import { useEffect } from 'react'
import {
  FindHomeProvider,
  FindHomeFiltersProvider,
  trackRouteChange,
  useQueryParamHomeFilters,
  PageContainer,
} from '@qasa/app'
import { HomeRentalTypeEnum } from '@qasa/graphql'

import { FindHomeMetaTags } from '../ui-page-modules/find-home/find-home-meta-tags'
import { SeoTestText } from '../ui-page-modules/find-home/seo-test-text'
import { FindHome } from '../ui-page-modules/find-home/find-home'

export const FIND_HOME_PATH = '/find-home'

export function FindHomePage() {
  const homeFiltersState = useQueryParamHomeFilters()

  useEffect(() => {
    trackRouteChange('Listing')
  }, [homeFiltersState.filterValues])

  return (
    <FindHomeFiltersProvider {...homeFiltersState} rentalType={HomeRentalTypeEnum.long_term}>
      <FindHomeProvider rentalType={HomeRentalTypeEnum.long_term}>
        <FindHomeMetaTags />
        <PageContainer>
          <FindHome />
          <SeoTestText />
        </PageContainer>
      </FindHomeProvider>
    </FindHomeFiltersProvider>
  )
}
