import { Heading, Paragraph, Spacer, HintBox } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { useProfileContext } from './profile-context'

const StatisticText = styled(Paragraph)({
  paddingBottom: 8,
  ':last-child': {
    paddingBottom: 0,
  },
})

export function ProfileStatisticsBlock() {
  const { profile } = useProfileContext()
  const { t } = useTranslation('profile_statistics_block')
  const { t: commonsT } = useTranslation('commons')

  const stats = profile.applicationStats!

  return (
    <div>
      <Heading as="h2" size="sm">
        {t('title')}
      </Heading>
      <Spacer size="6x" />
      <HintBox>
        {stats.locales?.length ? (
          <StatisticText>
            {t('places')}
            <Heading as="span" size="2xs">
              {stats.locales.join(', ')}
            </Heading>
          </StatisticText>
        ) : null}
        <StatisticText>
          {t('rentWithCount', { count: stats.priceMin === stats.priceMax ? 1 : 2 })}{' '}
          <Heading as="span" size="2xs">
            {stats.priceMin === stats.priceMax
              ? stats.priceMin
              : `${stats.priceMin} ${commonsT('and')} ${stats.priceMax}`}
          </Heading>
        </StatisticText>
        <StatisticText>
          {t('roomsWithCount', { count: stats.roomMin === stats.roomMax ? 1 : 2 })}{' '}
          <Heading as="span" size="2xs">
            {stats.roomMin === stats.roomMax
              ? stats.roomMin
              : `${stats.roomMin} ${commonsT('and')} ${stats.roomMax}`}
          </Heading>
        </StatisticText>
      </HintBox>
    </div>
  )
}
