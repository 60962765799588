import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Stack, TextField, RadioGroup } from '@qasa/qds-ui'

import { FieldEnum } from '../edit-listing.types'
import { useListingContext } from '../listing-context'
import { getCurrencySymbol } from '../../../utils/misc'

type WaterFeeType = 'included' | 'not_included'

export function WaterFeeField() {
  const { t } = useTranslation('listing')

  const {
    store: {
      values: { currency, waterFeePerTenant },
    },
    dispatch,
    getErrorMessage,
  } = useListingContext()

  const initialValue: WaterFeeType = waterFeePerTenant ? 'not_included' : 'included'
  const [selectedOption, setSelectedOption] = useState<string>(initialValue)

  const shouldShowWaterFeeField = selectedOption === 'not_included'

  const handleChange = (newOption: string) => {
    setSelectedOption(newOption)
    if (newOption === 'included') {
      dispatch({ action: { type: FieldEnum.WaterFeePerTenant, payload: null } })
    }
  }
  return (
    <>
      <RadioGroup name="waterfee" value={selectedOption} onValueChange={handleChange}>
        <RadioGroup.Label>{t('water_fee.label')}</RadioGroup.Label>
        <Stack gap={'2x'}>
          <RadioGroup.Card value={'included'} label={t('water_fee.is_included')} />
          <RadioGroup.Card value={'not_included'} label={t('water_fee.is_not_included')} />
        </Stack>
      </RadioGroup>
      {shouldShowWaterFeeField && (
        <TextField
          label={t('water_fee.label')}
          suffix={getCurrencySymbol({ currency })}
          placeholder={t('water_fee.placeholder')}
          value={waterFeePerTenant ?? ''}
          onChange={(event) => {
            dispatch({
              action: { type: FieldEnum.WaterFeePerTenant, payload: parseFloat(event.target.value) || 0 },
            })
          }}
          errorMessage={getErrorMessage({ field: FieldEnum.WaterFeePerTenant }) ?? undefined}
          isInvalid={Boolean(getErrorMessage({ field: FieldEnum.WaterFeePerTenant }))}
        />
      )}
    </>
  )
}
