import { Paragraph as QdsParagraph } from '@qasa/qds-ui'
import type { ParagraphProps as QdsParagraphProps } from '@qasa/qds-ui'

import { styled } from '../../../web'

import type { ParagraphProps } from './paragraph.types'

const StyledParagraph = styled(QdsParagraph)<ParagraphProps>(({ theme, boldness }) => ({
  ...(boldness === 'bold' && { fontWeight: theme.typography.label.md.fontWeight }),
  ...(boldness === 'bolder' && { fontWeight: theme.typography.caption.md.fontWeight }),
}))

export function Paragraph(props: ParagraphProps) {
  const { boldness, asWebElement, ...rest } = props
  return <StyledParagraph boldness={boldness} as={asWebElement as QdsParagraphProps['as']} {...rest} />
}
