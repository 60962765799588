import { Stack } from '@qasa/qds-ui'

import { useAppTranslation } from '../../../../../contexts/i18next'
import { useFindHomeFiltersContext } from '../../../contexts'
import { FilterCheckboxGroup } from '../../components/filter-checkbox-group.web'

export function EquipmentTraitsFilterSection() {
  const { filterValues, updateFilterValues } = useFindHomeFiltersContext()
  const { t: translateAmenity } = useAppTranslation('amenities')
  const { t: translateFilterSection } = useAppTranslation('filter_sections')

  const kitchenOptions = [
    { value: 'fridge', label: translateAmenity('traits.fridge') },
    { value: 'freezer', label: translateAmenity('traits.freezer') },
    { value: 'oven', label: translateAmenity('traits.oven') },
    { value: 'stove', label: translateAmenity('traits.stove') },
    { value: 'coffeeBrewer', label: translateAmenity('traits.coffee_brewer') },
    {
      value: 'porcelainGlassesCutlery',
      label: translateAmenity('traits.porcelain_glasses_cutlery'),
    },
  ]
  const bathroomOptions = [
    { value: 'toilet', label: translateAmenity('traits.toilet') },
    { value: 'shower', label: translateAmenity('traits.shower') },
    { value: 'bathtub', label: translateAmenity('traits.bathtub') },
  ]
  const cleaningOptions = [{ value: 'tumbleDryer', label: translateAmenity('traits.tumble_dryer') }]
  const familyOptions = [
    { value: 'highChair', label: translateAmenity('traits.high_chair') },
    { value: 'cot', label: translateAmenity('traits.cot') },
  ]
  const extrasOptions = [
    { value: 'jacuzzi', label: translateAmenity('traits.jacuzzi') },
    { value: 'indoorFireplace', label: translateAmenity('traits.indoor_fireplace') },
    { value: 'bikes', label: translateAmenity('traits.bikes') },
    { value: 'boatKayak', label: translateAmenity('traits.boat_kayak') },
  ]
  const technologyOptions = [
    { value: 'television', label: translateAmenity('traits.television') },
    { value: 'soundSystem', label: translateAmenity('traits.sound_system') },
  ]
  const outdoorOptions = [
    { value: 'grill', label: translateAmenity('traits.grill') },
    { value: 'garden', label: translateAmenity('traits.garden') },
  ]
  const parkingOptions = [
    { value: 'parkingIncluded', label: translateAmenity('traits.parking_included') },
    { value: 'electricCarCharger', label: translateAmenity('traits.electric_car_charger') },
  ]

  const checkboxGroups = [
    {
      label: translateFilterSection('equipment_traits.group_labels.kitchen'),
      options: kitchenOptions,
    },
    {
      label: translateFilterSection('equipment_traits.group_labels.bathroom'),
      options: bathroomOptions,
    },
    { label: translateFilterSection('equipment_traits.group_labels.cleaning'), options: cleaningOptions },
    { label: translateFilterSection('equipment_traits.group_labels.family'), options: familyOptions },
    { label: translateFilterSection('equipment_traits.group_labels.extras'), options: extrasOptions },
    { label: translateFilterSection('equipment_traits.group_labels.technology'), options: technologyOptions },
    { label: translateFilterSection('equipment_traits.group_labels.outdoor'), options: outdoorOptions },
    { label: translateFilterSection('equipment_traits.group_labels.parking'), options: parkingOptions },
  ]

  const handleFilterChange = (newValue: string[]) => updateFilterValues({ equipmentTraits: newValue })

  return (
    <Stack gap="8x">
      {checkboxGroups.map(({ label, options }, index) => (
        <FilterCheckboxGroup
          key={index}
          label={label}
          options={options}
          values={filterValues.equipmentTraits}
          onChange={handleFilterChange}
        />
      ))}
    </Stack>
  )
}
