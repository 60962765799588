import type { ReactNode } from 'react'
import { useState, useCallback, createContext, useContext } from 'react'

type ToastProps = {
  message: string
  actionLabel?: string
  onAction?: () => void
}

export type ToastConfig = ToastProps & { timeout?: number; key?: string }

const DEFAULT_TOAST_TIMEOUT = 6000

type ToastContextType = { toasts: ToastConfig[]; addToast: (config: ToastConfig) => void }

// @ts-ignore
const ToastContext = createContext<ToastContextType>()

export function ToastProvider({ children }: { children: ReactNode }) {
  const [toasts, setToasts] = useState<ToastConfig[]>([])

  const addToast = useCallback(({ timeout = DEFAULT_TOAST_TIMEOUT, ...toastProps }: ToastConfig) => {
    const removeToast = () => {
      setToasts((toasts) => [
        ...toasts.slice(0, toasts.indexOf(toastProps)),
        ...toasts.slice(toasts.indexOf(toastProps) + 1),
      ])
    }

    // Close the toast after specified time
    const timeoutId = setTimeout(removeToast, timeout)

    const actionCallback = toastProps.onAction
    if (actionCallback) {
      // Close the toast when clicking the action
      toastProps.onAction = () => {
        clearTimeout(timeoutId)
        removeToast()
        actionCallback()
      }
    }

    toastProps.key = timeoutId.toString()

    // TODO: Set limit to toasts amount?
    setToasts((toasts) => [...toasts, toastProps])
  }, [])

  return <ToastContext.Provider value={{ toasts, addToast }}>{children}</ToastContext.Provider>
}

/** @deprecated Use `toast` function from qds/qds-native instead. */
export const useToastContext = () => useContext<ToastContextType>(ToastContext)
