import { graphql } from '@qasa/graphql'

export const CREATE_HOME_FROM_TEMPLATE = graphql(`
  mutation CreateHomeFromTemplateInCreateListingMutation($homeTemplateId: ID!) {
    createHomeFromTemplate(homeTemplateId: $homeTemplateId) {
      errors {
        field
        codes
      }
      home {
        id
      }
    }
  }
`)
