import { Redirect, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import isNull from 'lodash/isNull'
import { FeeAvailabilityEnum, HomeRentalTypeEnum } from '@qasa/graphql'

import { currentBrand } from '../../config'

import type { ListingSections } from './edit-listing.types'
import { FieldEnum } from './edit-listing.types'
import { ListingLocationAddress } from './sections/listing-location-address'
import { PreLoginSection } from './sections/pre-login-section'
import { SizeSection } from './sections/size-section'
import { UploadImages } from './sections/upload-images'
import { UserTypeSection } from './sections/user-type-section'
import { ListingLocationMap } from './sections/listing-location-map'
import { DescriptionSection } from './sections/description-section'
import { NumberOfHomesSection } from './sections/number-of-homes'
import { useListingContext } from './listing-context'
import { StandardSection } from './sections/standard-section'
import { getIsProbablyFinlandMarket, getIsProfessionalWithSeveralListings } from './listing.utils'
import { ApplicationTypeSection } from './sections/application-type-section'
import { CreateFindTenantListingIntro } from './sections/find-tenant-intro/create-find-tenant-listing-intro'
import { ChooseRentalType } from './sections/choose-rental-type'
import { TemplatesSection } from './sections/templates-section'
import { LocationPerksSection } from './sections/location-perks-section'
import { CleaningSection } from './sections/cleaning-section'
import { AreasSection } from './sections/areas-section'
import { CancellationPolicySection } from './sections/cancellation-policy-section'
import { CalendarSettingsSection } from './sections/calendar-settings-section'
import { RulesAndAccessibilitySection } from './sections/rules-and-accessibility-section'
import { AmenitiesSection } from './sections/amenities-section'
import { RentSection } from './sections/rent-section'
import { HomeDetailsChapterIntro } from './sections/home-details-chapter-intro'
import { ListingDetailsChapterIntro } from './sections/listing-details-chapter-intro'
import { AboutRentalChapterIntro } from './sections/about-rental-chapter-intro'
import { UtilityCosts } from './sections/utility-costs'
import { HomeTypeSection } from './sections/home-type-section'
import { DurationSection } from './sections/duration-section'
import { SharedFurnishedSection } from './sections/shared-furnished-section'

function LandlordCalendarRedirect() {
  const { homeId }: { homeId: string } = useParams()

  return <Redirect to={`/calendar/${homeId}`} />
}

export const useListingSections = () => {
  const { t } = useTranslation('listing')
  const {
    store: {
      values: {
        isProfessional,
        isPremium,
        traits,
        numberOfHomes: numberOfHomesValue,
        isExternal,
        rentalType,
        countryCode,
        market,
      },
    },
    tenantFees,
    getIsValid,
  } = useListingContext()

  const isFinlandMarket = getIsProbablyFinlandMarket({ countryCode, marketName: market?.name })
  const isQasaDotCom = currentBrand === 'dotcom'
  const shouldRenderRanges = getIsProfessionalWithSeveralListings({
    isProfessional,
    numberOfHomes: numberOfHomesValue,
  })
  const isVacationRental = rentalType === HomeRentalTypeEnum.vacation

  const isWaterFeeMandatory = tenantFees?.waterFeePerTenant.availability === FeeAvailabilityEnum.mandatory
  const isElectricityFeeAvailable =
    tenantFees?.monthlyElectricityFee?.availability === FeeAvailabilityEnum.optional

  const sections: ListingSections = {
    homeDetailsIntro: {
      path: 'home-details-intro',
      component: HomeDetailsChapterIntro,
      isComplete: true,
    },
    listingDetailsIntro: {
      path: 'listing-details-intro',
      component: ListingDetailsChapterIntro,
      isComplete: true,
    },
    aboutRentalIntro: {
      path: 'about-rental-intro',
      component: AboutRentalChapterIntro,
      isComplete: true,
    },
    findTenantIntro: {
      path: 'find-tenant-intro',
      component: CreateFindTenantListingIntro,
      isComplete: true,
    },
    chooseRentalType: {
      path: 'choose-rental-type',
      component: ChooseRentalType,
      isComplete: true,
    },
    templates: {
      path: 'templates',
      component: TemplatesSection,
      isComplete: true,
      nextLabelOverride: t('templates.next_label'),
    },
    userType: {
      path: 'user-type',
      component: UserTypeSection,
      isComplete: getIsValid({
        fields: [
          FieldEnum.IsProfessional,
          ...(isProfessional ? [FieldEnum.CompanyName, FieldEnum.OrganizationNumber] : []),
        ],
      }),
    },
    numberOfHomes: {
      path: 'number-of-homes',
      component: NumberOfHomesSection,
      isComplete: getIsValid({ fields: [FieldEnum.NumberOfHomes] }),
    },
    size: {
      path: 'size',
      component: SizeSection,
      isComplete: getIsValid({
        fields: shouldRenderRanges
          ? [
              FieldEnum.MinSquareMeters,
              FieldEnum.MaxSquareMeters,
              FieldEnum.MinRoomCount,
              FieldEnum.MaxRoomCount,
            ]
          : [FieldEnum.SquareMeters, FieldEnum.RoomCount],
      }),
    },
    areas: {
      path: 'areas',
      component: AreasSection,
      isComplete: getIsValid({
        fields: [FieldEnum.BedroomCount, FieldEnum.BedCount, FieldEnum.ToiletCount, FieldEnum.HasKitchen],
      }),
    },
    address: {
      path: 'address',
      component: ListingLocationAddress,
      isComplete: getIsValid({
        fields: [
          FieldEnum.Route,
          FieldEnum.StreetNumber,
          FieldEnum.PostalCode,
          FieldEnum.Locality,
          ...(isQasaDotCom ? [FieldEnum.CountryCode] : []),
        ],
      }),
    },
    map: {
      path: 'map',
      component: ListingLocationMap,
      isComplete: getIsValid({ fields: [FieldEnum.Position] }),
    },
    amenitiesHome: {
      path: 'amenities-home',
      component: AmenitiesSection,
      isComplete: true,
    },
    rulesAndAccessibility: {
      path: 'rules-and-accessibility',
      component: RulesAndAccessibilitySection,
      isComplete: getIsValid({
        fields: [FieldEnum.TenantCount],
      }),
    },
    landlordCalendar: {
      path: 'calendar',
      component: LandlordCalendarRedirect,
      isComplete: true,
    },
    locationPerks: {
      path: 'location-perks',
      component: LocationPerksSection,
      isComplete: true,
    },
    uploadImages: {
      path: 'upload-images',
      component: UploadImages,
      isComplete: true,
    },
    standard: {
      path: 'standard',
      component: StandardSection,
      isComplete: true,
    },
    rent: {
      path: 'rent',
      component: RentSection,
      isComplete: getIsValid({
        fields: [FieldEnum.Rent],
      }),
    },
    utilityCosts: {
      path: 'utility-costs',
      component: UtilityCosts,
      isComplete: getIsValid({
        fields: [
          ...(isWaterFeeMandatory ? [FieldEnum.WaterFeePerTenant] : []),
          ...(isElectricityFeeAvailable ? [FieldEnum.ElectricityFee] : []),
        ],
      }),
    },
    description: {
      path: 'description',
      component: DescriptionSection,
      isComplete: getIsValid({
        fields: isVacationRental
          ? [FieldEnum.Title, FieldEnum.Description, FieldEnum.HouseRules]
          : [FieldEnum.Description],
      }),
    },
    preLogin: {
      path: 'pre-login',
      component: PreLoginSection,
      isComplete: true,
    },
    applicationType: {
      path: 'application-type',
      component: ApplicationTypeSection,
      isComplete: isExternal
        ? getIsValid({
            fields: [FieldEnum.ExternalEmail, FieldEnum.ExternalApplicationUrl, FieldEnum.ExternalInfoPage],
          })
        : true,
    },
    cleaning: {
      path: 'cleaning',
      component: CleaningSection,
      isComplete: getIsValid({ fields: [FieldEnum.ResponsibleForCleaning, FieldEnum.CleaningFee] }),
    },
    cancellationPolicy: {
      path: 'cancellation-policy',
      component: CancellationPolicySection,
      isComplete: getIsValid({ fields: [FieldEnum.CancellationPolicyName] }),
    },
    calendarSettings: {
      path: 'calendar-settings',
      component: CalendarSettingsSection,
      isComplete: getIsValid({ fields: [FieldEnum.MinNights] }),
    },
    sharedFurnished: {
      path: 'shared-furnished',
      component: SharedFurnishedSection,
      isComplete: isFinlandMarket
        ? getIsValid({ fields: [FieldEnum.Shared] })
        : getIsValid({ fields: [FieldEnum.Shared] }) && (!isNull(traits) || isVacationRental),
    },
    duration: {
      path: 'duration',
      component: DurationSection,
      isComplete: getIsValid({
        fields: isFinlandMarket ? [FieldEnum.Duration] : [FieldEnum.Duration, FieldEnum.OwnHome],
      }),
    },
    homeType: {
      path: 'home-type',
      component: HomeTypeSection,
      isComplete: getIsValid({
        fields: [
          FieldEnum.Type,
          FieldEnum.Firsthand,
          FieldEnum.Senior,
          FieldEnum.Student,
          FieldEnum.Corporate,
          ...(isVacationRental || isFinlandMarket ? [] : [FieldEnum.TenureType]),
        ],
      }),
    },
  }

  const shouldShowApplicationType = isProfessional && isPremium
  const shouldShowUtilityCostsSection = isWaterFeeMandatory || isElectricityFeeAvailable
  return { sections, shouldShowApplicationType, shouldShowUtilityCostsSection }
}
