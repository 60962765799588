import { useQuery } from '@apollo/client'
import type { LinkProps } from 'react-router-dom'
import { useMap } from 'react-map-gl'
import { useLocation } from 'react-router-dom'
import { graphql } from '@qasa/graphql'

import { MapHomeCard } from './map-home-card.web'
import type { SelectedLocation } from './use-selected-location'
import type { PersistedMapState } from './use-persisted-map-state.web'
import { usePersistedMapState } from './use-persisted-map-state.web'

const MAP_POPUP_CONTENT_HOME = graphql(`
  query MapPopupContentHome($id: ID!) {
    home(id: $id) {
      id
      ...MapHomeCard
    }
  }
`)

type MapPopupContentProps = {
  selectedLocation: Exclude<SelectedLocation, null>
}

export function MapPopupContent({ selectedLocation }: MapPopupContentProps) {
  const { homeId } = selectedLocation

  const { persistedMapState } = usePersistedMapState()
  const { current: map } = useMap()
  const currentZoom = map?.getZoom()
  const currentCenter = map?.getCenter()
  const { pathname, search } = useLocation()

  const {
    error: homeError,
    data: homeData,
    loading: isLoading,
  } = useQuery(MAP_POPUP_CONTENT_HOME, { variables: { id: homeId } })

  if (isLoading) return null

  const home = homeData?.home

  if (!home || homeError) return null

  const statefulLinkToHome: LinkProps['to'] = ({ ...props }) => {
    const zoom = currentZoom

    const { lng, lat } = currentCenter || {}

    const newState: PersistedMapState = {
      fromLocation: pathname + search,
      longitude: lng,
      latitude: lat,
      zoom,
      selectedLocation,
    }
    return {
      ...props,
      pathname: `/home/${homeId}/`,
      state: {
        ...persistedMapState,
        ...newState,
      },
    }
  }

  return <MapHomeCard {...{ home, to: statefulLinkToHome }} />
}
