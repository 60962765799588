import type { MarketNameEnum } from '@qasa/graphql'
import { CountryCodeEnum } from '@qasa/graphql'

export type AvailableMarketCountryCodes =
  | CountryCodeEnum.SE
  | CountryCodeEnum.FI
  | CountryCodeEnum.FR
  | CountryCodeEnum.NO

export const MARKET_COUNTRY_CODES: Record<MarketNameEnum, AvailableMarketCountryCodes> = {
  sweden: CountryCodeEnum.SE,
  finland: CountryCodeEnum.FI,
  france: CountryCodeEnum.FR,
  norway: CountryCodeEnum.NO,
}
