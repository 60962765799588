import { Textarea as QDSTextarea } from '@qasa/qds-ui'
import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'

import { styled } from '../../styled/styled.web'

import type { TextareaProps, TextareaState } from './textarea.shared'

const TextareaWithWarning = styled(QDSTextarea)<{ state: TextareaState }>(({ state, theme }) => ({
  ...(state === 'warning' && {
    borderColor: theme.colors.border.warning,
    '&:hover': {
      borderColor: theme.colors.border.warning,
    },
    '&:focus': {
      borderColor: theme.colors.border.warning,
      boxShadow: `0 0 0 1px ${theme.colors.border.warning}`,
    },
  }),
}))

type Ref = ForwardedRef<HTMLTextAreaElement>

export const Textarea = forwardRef(
  (
    { label, onChange, placeholder, value, maxLength, inputState = 'default', ...props }: TextareaProps,
    ref: Ref,
  ) => {
    return (
      <TextareaWithWarning
        ref={ref}
        state={inputState}
        maxLength={maxLength}
        value={value}
        label={label}
        placeholder={placeholder}
        onChange={(event) => onChange?.(event.target.value)}
        {...props}
      />
    )
  },
)
