import isEqual from 'lodash/isEqual'
import { useCallback } from 'react'
import type { ViewState } from 'react-map-gl'
import { useHistory, useLocation } from 'react-router-dom'

import type { SelectedLocation } from './use-selected-location'
import type { ClusterType } from './use-selected-cluster'

export type PersistedMapState =
  | ({
      fromLocation?: string | null
      selectedLocation?: SelectedLocation
      selectedCluster?: ClusterType
      isMapOpen?: boolean
    } & Partial<Pick<ViewState, 'latitude' | 'longitude' | 'zoom'>>)
  | undefined

export const usePersistedMapState = () => {
  const { replace } = useHistory()
  const location = useLocation<PersistedMapState>()
  const persistedMapState = location.state

  const setPersistedMapState = useCallback(
    (newState: PersistedMapState) => {
      const newLocation = { ...location, state: { ...location.state, ...newState } }
      if (!isEqual(location, newLocation)) {
        replace(newLocation)
      }
    },

    [replace, location],
  )

  return { persistedMapState, setPersistedMapState }
}
