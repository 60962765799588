import { graphql } from '@qasa/graphql'

export const CONTRACTS = graphql(`
  query ContractsQuery($status: [ContractStatusEnum!], $signingProcess: [SigningProcessEnum!]) {
    contracts(status: $status, signingProcess: $signingProcess) {
      nodes {
        ...BaseContract
      }
    }
  }
`)
