import { Heading, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import type { TraitTypeEnum } from '@qasa/graphql'
import { HomeRentalTypeEnum } from '@qasa/graphql'

import { CheckboxGroup } from '../../../ui-shared/_core/controls/checkbox'
import { CheckboxPill } from '../../../ui-shared/_core/controls/checkbox/checkbox-pill'
import { FieldEnum } from '../edit-listing.types'
import { useListingContext } from '../listing-context'
import { SectionWrapper } from '../section-wrapper'
import { getTraits } from '../trait-declarations/get-traits'
import type { AmenityGroupType } from '../trait-declarations/types'
import { AmenityGroupTitleEnum } from '../trait-declarations/types'

export function AmenitiesSection() {
  const { t } = useTranslation('listing')
  const {
    store: {
      values: { traits: selectedTraits, rentalType, market },
    },
    dispatch,
  } = useListingContext()
  const traits = getTraits({
    isVacationHome: rentalType === HomeRentalTypeEnum.vacation,
    excludedGroups: [AmenityGroupTitleEnum.RULES, AmenityGroupTitleEnum.ACCESSIBILITY],
    marketName: market?.name,
  })

  return (
    <SectionWrapper
      title={t('section_headings.amenities_home')}
      description={t('amenities_home.description')}
    >
      {traits.map((amenityGroup: AmenityGroupType) => {
        return (
          <CheckboxGroup
            key={amenityGroup.title}
            label={t(`subsection_headings.${amenityGroup.title}`)}
            isLabelVisuallyHidden
            value={selectedTraits ?? []}
            onChange={(values) => {
              dispatch({
                action: {
                  type: FieldEnum.Traits,
                  payload: values as TraitTypeEnum[],
                },
              })
            }}
          >
            <Stack gap="2x">
              <Heading size="2xs">{t(`subsection_headings.${amenityGroup.title}`)}</Heading>
              <Stack direction="row" wrap="wrap" gap="2x">
                {amenityGroup.traits.map(({ trait }) => {
                  return <CheckboxPill key={trait} label={t(`home_amenities:${trait}`)} value={trait} />
                })}
              </Stack>
            </Stack>
          </CheckboxGroup>
        )
      })}
    </SectionWrapper>
  )
}
