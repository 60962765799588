/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { FieldPolicy, Reference, StoreObject, StoreValue } from '@apollo/client'
import type { SafeReadonly } from '@apollo/client/cache/core/types/common'
import type { FieldFunctionOptions, KeyArgsFunction } from '@apollo/client/cache/inmemory/policies'

type KeySpecifier = string[]
type KeyArgs = KeySpecifier | KeyArgsFunction | false

type ConversationArgs = { after?: string; before?: string }

type Node<T = Reference> = T

type Edge = {
  node: Node
  cursor: string
}

type PageInfo = {
  hasPreviousPage: boolean
  hasNextPage: boolean
  startCursor: string
  endCursor: string
}

type EdgesAndPageInfo = {
  edges: Edge[]
  pageInfo: PageInfo
}

const EMPTY_EXISTING = {
  edges: [],
  pageInfo: {
    hasPreviousPage: false,
    hasNextPage: true,
    startCursor: '',
    endCursor: '',
  },
}

type ConversationsReadField = (
  fieldName: string,
  from?: StoreObject | Reference,
) => SafeReadonly<StoreValue> | undefined

export function removeExistingNodesWithDuplicates(
  existing: EdgesAndPageInfo,
  incoming: EdgesAndPageInfo,
  readField: ConversationsReadField,
) {
  const incomingIds = incoming.edges.map((e) => readField('id', e.node) as string)
  const duplicatesRemoved = existing.edges.filter((edge) => {
    const existingNodeId = readField('id', edge.node) as string
    return !incomingIds.includes(existingNodeId)
  })
  return duplicatesRemoved
}

type ConcatenatedEdgesType = {
  currentEdges: Edge[]
  incomingEdges: Edge[]
  args: ConversationArgs
}

function concatenateEdges({ currentEdges = [], incomingEdges, args }: ConcatenatedEdgesType) {
  return args && args.before ? [...incomingEdges, ...currentEdges] : [...currentEdges, ...incomingEdges]
}

type MergePageInfoType = {
  existing: EdgesAndPageInfo
  incoming: EdgesAndPageInfo
  args: ConversationArgs
}

function mergePageInfo({ existing = EMPTY_EXISTING, incoming, args }: MergePageInfoType) {
  if (incoming.edges.length === 0) return existing.pageInfo

  if (!incoming.pageInfo) return existing.pageInfo

  if (args && args.before) {
    const endCursor =
      incoming.pageInfo.endCursor && existing.pageInfo.endCursor === ''
        ? incoming.pageInfo.endCursor
        : existing.pageInfo.endCursor
    return {
      ...existing.pageInfo,
      startCursor: incoming.pageInfo.startCursor,
      hasPreviousPage: incoming.pageInfo.hasPreviousPage,
      endCursor,
    }
  } else {
    const startCursor =
      incoming.pageInfo.startCursor && existing.pageInfo.startCursor === ''
        ? incoming.pageInfo.startCursor
        : existing.pageInfo.startCursor
    return {
      ...existing.pageInfo,
      endCursor: incoming.pageInfo.endCursor,
      hasNextPage: incoming.pageInfo.hasNextPage,
      startCursor,
    }
  }
}

type MergeConversationNodesType = {
  existing: EdgesAndPageInfo
  incoming: EdgesAndPageInfo
  args: ConversationArgs
  readField: ConversationsReadField
}

export function mergeConversationNodes({
  existing = EMPTY_EXISTING,
  incoming,
  args,
  readField,
}: MergeConversationNodesType) {
  if (args && args.before && args.after) {
    throw Error('When invoking this merge function, make sure only either after or before is set.')
  }

  const currentEdges = removeExistingNodesWithDuplicates(existing, incoming, readField)
  const concatenatedEdges = concatenateEdges({ currentEdges, incomingEdges: incoming.edges, args })

  const updatedPageInfo = mergePageInfo({ existing, incoming, args })
  return { ...existing, edges: concatenatedEdges, pageInfo: updatedPageInfo }
}

export function conversationsPagination(keyArgs: KeyArgs = false): FieldPolicy {
  return {
    keyArgs,
    merge: (existing: EdgesAndPageInfo, incoming: EdgesAndPageInfo, options: FieldFunctionOptions) => {
      const conversationArgs = options.args as ConversationArgs
      const readField = options.readField
      const result = mergeConversationNodes({
        existing: existing || EMPTY_EXISTING,
        incoming,
        args: conversationArgs,
        readField,
      })
      return result
    },
  }
}
