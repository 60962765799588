import capitalize from 'lodash/capitalize'
import range from 'lodash/range'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Checkbox, Stack } from '@qasa/qds-ui'
import { OccupationTypeEnum } from '@qasa/graphql'

import { isNumber } from '../../../utils/number'
import { FormSelectField } from '../../../ui-page-modules/create-tenant-listing/fields/form-select-field'
import type { OccupationFormValues } from '../use-occupation-form'
import { getFormattedMonths } from '../occupation.utils'

import { ERROR_OCCUPATION_I18N_KEY, OCCUPATION_I18N_KEY } from './title'

const CURRENT_YEAR = new Date().getFullYear()

type ValidateEndYearAfterStartYearParams = {
  start: number | null | string
  end?: number | null | string
}
const validateEndAfterStart = ({ start, end }: ValidateEndYearAfterStartYearParams) => {
  //end can be null, undefined, string, or a number
  //we should only compare if end is a number, otherwise default to 'Ongoing'
  if (!isNumber(end) || !isNumber(start)) {
    return true
  }
  return start <= end
}

export function EndDateField() {
  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<OccupationFormValues>()
  const { endYear, endMonth, startMonth, startYear } = watch()
  const hasEndDates = Boolean(getValues('endMonth') && getValues('endYear'))
  const [isEndDatesDisabled, setIsEndDatesDisabled] = useState(!hasEndDates)
  const shouldAllowFutureEndDate = getValues('occupationType') === OccupationTypeEnum.student
  const { t } = useTranslation('edit_profile')

  const formattedMonths = getFormattedMonths()
  const monthOptions = formattedMonths.map((month, index) => ({
    value: index,
    label: capitalize(month),
  }))

  const endYearOptions = range(CURRENT_YEAR + (shouldAllowFutureEndDate ? 10 : 0), 1940, -1).map((year) => ({
    value: year,
    label: year.toString(),
  }))

  const validateEndMonth = () => {
    const isSameYear = endYear === startYear
    const isEndMonthAfterStartMonth = validateEndAfterStart({
      start: startMonth,
      end: endMonth,
    })

    if (!isEndMonthAfterStartMonth && isSameYear)
      return t(`${ERROR_OCCUPATION_I18N_KEY}.start_month_after_end_month`)

    return true
  }
  const onCheckboxChange = (isChecked: boolean) => {
    setIsEndDatesDisabled(isChecked)
    setValue('endMonth', null)
    setValue('endYear', null)
  }
  return (
    <Stack gap="3x">
      <Checkbox
        label={t(`${OCCUPATION_I18N_KEY}.date.ongoing_occupation`)}
        onCheckedChange={onCheckboxChange}
        isChecked={isEndDatesDisabled}
      />
      <Stack direction={'row'} gap="3x">
        <FormSelectField
          control={control}
          name={'endMonth'}
          label={t(`${OCCUPATION_I18N_KEY}.date.end_month_label`)}
          placeholder={t(`${OCCUPATION_I18N_KEY}.date.month_placeholder`)}
          rules={{
            validate: validateEndMonth,
          }}
          errorMessage={errors['endMonth'] && errors['endMonth'].message}
          isDisabled={isEndDatesDisabled}
          options={monthOptions}
        />
        <FormSelectField
          control={control}
          name={'endYear'}
          label={t(`${OCCUPATION_I18N_KEY}.date.end_year_label`)}
          placeholder={t(`${OCCUPATION_I18N_KEY}.date.year_placeholder`)}
          rules={{
            validate: () =>
              validateEndAfterStart({
                start: startYear,
                end: endYear,
              }),
          }}
          errorMessage={errors['endYear'] && t(`${ERROR_OCCUPATION_I18N_KEY}.start_year_after_end_year`)}
          isDisabled={isEndDatesDisabled}
          options={endYearOptions}
        />
      </Stack>
    </Stack>
  )
}
