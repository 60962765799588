import { Popup } from 'react-map-gl'
import { styled } from '@qasa/ui/web'
import { XIcon, IconButton, useBreakpointValue } from '@qasa/qds-ui'

import { useMapCenter } from '../../../hooks/use-map.web'

import type { SelectedLocation } from './use-selected-location'
import { POPUP_DESKTOP_WIDTH, POPUP_DESKTOP_HEIGHT, POPUP_MOBILE_HEIGHT } from './map.utils.web'
import type { ClusterType } from './use-selected-cluster'
import { MapHomeCardCarousel } from './map-home-card-carousel.web'
import { MapPopupContent } from './map-popup-content.web'

const StyledPopup = styled(Popup)(({ theme }) => ({
  '.mapboxgl-popup-tip': {
    display: 'none',
  },
  '.mapboxgl-popup-content': {
    boxShadow: 'none',
    padding: 0,
    borderRadius: 0,
    height: '100%',
    position: 'relative',
  },
  boxShadow: theme.shadows.lg,
  borderRadius: theme.radii.md,
  overflow: 'hidden',
  backgroundColor: theme.colors.bg.default,
  display: 'flex',
  flexDirection: 'column',
}))

const CardWrapper = styled('div')({
  width: POPUP_DESKTOP_WIDTH,
  height: POPUP_DESKTOP_HEIGHT,
})

const MobileWrapper = styled('div')(({ theme }) => ({
  width: 'calc(100vw - 32px)',
  position: 'fixed',
  bottom: 72,
  left: '50%',
  transform: 'translateX(-50%)',
  background: theme.colors.bg.default,
  overflow: 'hidden',
  borderRadius: theme.radii.md,
  boxShadow: theme.shadows.lg,
}))

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: 8,
  zIndex: 1,
})

const MobileContainer = styled('div')({ position: 'relative', height: POPUP_MOBILE_HEIGHT, width: '100%' })

type MapPopupProps = {
  selectedLocation: Exclude<SelectedLocation, null>
  onClose: () => void
  selectedCluster?: ClusterType
}

export function MapPopup({ selectedLocation, onClose, selectedCluster }: MapPopupProps) {
  const mapCenter = useMapCenter()
  const { position } = selectedLocation
  const longitude = position[0] ?? mapCenter.lng
  const latitude = position[1] ?? mapCenter.lat

  const isMobile = useBreakpointValue({ base: true, md: false })

  if (isMobile && selectedCluster)
    return (
      <MobileWrapper>
        <CloseButton icon={XIcon} label="close" size={'xs'} onClick={onClose} />
        <MapHomeCardCarousel selectedCluster={selectedCluster} key={selectedCluster.clusterId} />
      </MobileWrapper>
    )

  if (selectedCluster) {
    return (
      <StyledPopup
        closeButton={false}
        offset={[0, -24]}
        anchor="bottom"
        longitude={longitude}
        latitude={latitude}
        closeOnClick={true}
        onClose={onClose}
        maxWidth="none"
      >
        <MapHomeCardCarousel selectedCluster={selectedCluster} key={selectedCluster.clusterId} />
      </StyledPopup>
    )
  }

  if (isMobile)
    return (
      <MobileWrapper>
        <MobileContainer>
          <CloseButton icon={XIcon} label="close" size={'xs'} onClick={onClose} />
          <MapPopupContent {...{ selectedLocation }} />
        </MobileContainer>
      </MobileWrapper>
    )

  return (
    <StyledPopup
      closeButton={false}
      offset={[0, -24]}
      anchor="bottom"
      longitude={longitude}
      latitude={latitude}
      closeOnClick={true}
      onClose={onClose}
      maxWidth="none"
    >
      <CardWrapper>
        <MapPopupContent {...{ selectedLocation }} />
      </CardWrapper>
    </StyledPopup>
  )
}
