import { createIcon } from '@qasa/qds-ui'

export {
  AlertCircleIcon,
  AlertTriangleIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  BellIcon,
  BellOffIcon,
  BookmarkIcon,
  CalendarIcon,
  CameraIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  GlobeIcon,
  HeartFilledIcon,
  HeartIcon,
  HelpCircleIcon,
  HistoryIcon,
  HomeIcon,
  ImageIcon,
  ListFilterIcon,
  ListIcon,
  LogOutIcon,
  MapIcon,
  MapPinIcon,
  MenuIcon,
  MessageCircleIcon,
  MinusIcon,
  MoreHorizontalIcon,
  MoreVerticalIcon,
  PenIcon,
  PlusIcon,
  SearchIcon,
  SettingsIcon,
  ShareIcon,
  SlidersIcon,
  StarFilledIcon,
  StarIcon,
  TrashIcon,
  UserIcon,
  XCircleIcon,
  XIcon,
  InfoIcon,
} from '@qasa/qds-ui'

export const LayoutIcon = createIcon({
  viewBox: '0 0 16 16',
  displayName: 'LayoutIcon',
  path: [
    <path
      key={0}
      fill="none"
      d="M15.1961 6.4V2.4C15.1961 1.51634 14.4798 0.799999 13.5961 0.799999H7.9961H2.3961C1.51244 0.799999 0.796097 1.51634 0.796097 2.4V13.6C0.796097 14.4837 1.51244 15.2 2.3961 15.2H13.5961C14.4798 15.2 15.1961 14.4837 15.1961 13.6V10.4M7.1961 0.799999V6.4M7.1961 15.2L7.1961 10.4L10.3963 10.4004"
      stroke="#342620"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
})
