import { OccupationTypeEnum } from '@qasa/graphql'

import { EndDateField } from './end-date-field'
import { OccupationScheduleField } from './occupation-schedule-field'
import { OccupationTypeField } from './occupation-type-field'
import { StartDateField } from './start-date-field'
import { Subtitle } from './subtitle'
import { Title } from './title'

type UseOccupationFieldsProps = {
  occupationType?: OccupationTypeEnum | null
}
export function useOccupationFields({ occupationType }: UseOccupationFieldsProps) {
  if (!occupationType) return [OccupationTypeField]

  const basicFields = [
    OccupationTypeField,
    Title,
    Subtitle,
    OccupationScheduleField,
    StartDateField,
    EndDateField,
  ]

  const OccupationFields = {
    [OccupationTypeEnum.work]: basicFields,
    [OccupationTypeEnum.student]: basicFields,
    [OccupationTypeEnum.retired]: [OccupationTypeField, StartDateField],
    [OccupationTypeEnum.other]: [
      OccupationTypeField,
      Title,
      OccupationScheduleField,
      StartDateField,
      EndDateField,
    ],
  }
  return OccupationFields[occupationType]
}
