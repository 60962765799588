import type { BigSearchVacationCardFragment } from '@qasa/graphql'
import { graphql } from '@qasa/graphql'

import { BigDataCardBase } from '../../base/big-data-card-base'
import { Carousel } from '../../../carousel'
import { FavoriteHomeButton } from '../../../favorite-home-button'
import { SlashSeparatedData } from '../../../../components/slash-separated-data'

import { useBigSearchVacationCard } from './big-search-vacation-card.logic'
BigSearchVacationCard.fragment = graphql(`
  fragment BigSearchVacationCard on Home {
    id
    title
    currency
    location {
      id
      locality
    }
    uploads {
      id
      url
      metadata {
        primary
        order
      }
    }
    ...FavoriteHomeButton
    averagePricePerNight
    tenantCount
    bedCount
    bedroomCount
    minimumPricePerNight
    maximumPricePerNight
  }
`)

type BigSearchVacationCardProps = {
  data: BigSearchVacationCardFragment
  shouldDisplayPriceRange?: boolean
}
export function BigSearchVacationCard({ data, shouldDisplayPriceRange = false }: BigSearchVacationCardProps) {
  const { images, heading, hero, bulletData } = useBigSearchVacationCard({
    data,
    shouldDisplayPriceRange,
  })
  return (
    <BigDataCardBase>
      <Carousel hasBorderRadius>
        {images.map((image, index) => (
          <Carousel.Image src={image} key={index} loading={index === 0 ? 'eager' : 'lazy'} />
        ))}
      </Carousel>
      <BigDataCardBase.FavoriteWrapper>
        <FavoriteHomeButton home={data} />
      </BigDataCardBase.FavoriteWrapper>
      <BigDataCardBase.TextWrapper>
        <BigDataCardBase.Heading>{heading}</BigDataCardBase.Heading>
        <SlashSeparatedData gap="2x" data={bulletData} />
        {hero && <BigDataCardBase.HeroData>{hero}</BigDataCardBase.HeroData>}
      </BigDataCardBase.TextWrapper>
    </BigDataCardBase>
  )
}
