import styled from '@emotion/styled'
import { Heading, Paragraph, Spacer, Stack } from '@qasa/qds-ui'
import type { ProfileQueryQuery } from '@qasa/graphql'

import { LandlordResponseInfo } from '../../../ui-shared/landlord-response-info'
import { LastActive } from '../../../ui-shared/last-active'
import { ProfileAge } from '../../../ui-shared/profile-age'
import { VerificationIconPro } from '../../../ui-shared/verification-icon-pro'
import { ProfileSectionHeader } from '../profile-section-header'
import { getProfileName } from '../profile.utils'

const SectionWrapper = styled(Stack)({
  position: 'relative',
  width: '100%',
})
const FirstName = Heading
const NameAndAgeWrapper = Stack
const ActivityLevel = Paragraph

type ProfileNameSectionParams = {
  profile: NonNullable<ProfileQueryQuery['user']>
  onActivateEditMode: () => void
  isOwnProfile: boolean
  isCentered: boolean
}
export function ProfileIntroBlock({
  profile,
  onActivateEditMode,
  isOwnProfile,
  isCentered,
}: ProfileNameSectionParams) {
  const isProPilot = profile.proPilot
  const ProfileActivity = profile.landlord ? (
    <LandlordResponseInfo
      landlordApplicationResponseRate={profile.landlordApplicationResponseRate}
      landlordApplicationResponseTimeHours={profile.landlordApplicationResponseTimeHours}
      isHidden={profile.professional}
    />
  ) : (
    <LastActive {...{ profile }} />
  )

  return (
    <>
      <SectionWrapper
        gap="6x"
        direction={isCentered ? 'column' : 'row-reverse'}
        justifyContent={isCentered ? 'flex-start' : 'flex-end'}
        alignItems={isCentered ? 'center' : 'baseline'}
      >
        <ProfileSectionHeader isOwnProfile={isOwnProfile} onEditButtonClick={onActivateEditMode} />
        <NameAndAgeWrapper
          direction={isCentered ? 'column' : 'row'}
          alignItems={isCentered ? 'center' : 'baseline'}
          gap="2x"
          wrap="wrap"
        >
          <FirstName
            as="h1"
            size="lg"
            textAlign={isCentered ? 'center' : 'left'}
            style={{ wordBreak: 'break-word' }}
          >
            {getProfileName({ user: profile })}
          </FirstName>
          <ProfileAge {...{ profile }} />
          {isProPilot && <VerificationIconPro />}
        </NameAndAgeWrapper>
      </SectionWrapper>
      <Spacer size="1x" />
      <ActivityLevel size="sm">{ProfileActivity}</ActivityLevel>
    </>
  )
}
