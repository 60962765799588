import { useTranslation } from 'react-i18next'
import { TextField, Select, Stack } from '@qasa/qds-ui'
import type { ChangeEvent } from 'react'

import { useListingContext } from '../../listing-context'
import { SectionWrapper } from '../../section-wrapper'
import { FieldEnum } from '../../edit-listing.types'
import { numberFromString } from '../../listing.utils'

const ROOM_COUNT_VALUES = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 6, 7, 8, 9, 10]

export function MultipleHomesSizeSection() {
  const { t } = useTranslation('listing')
  const {
    store: {
      values: { shared: isShared, minSquareMeters, maxSquareMeters, minRoomCount, maxRoomCount },
    },
    dispatch,
    getErrorMessage,
  } = useListingContext()

  const handleMinSquareMetersChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = numberFromString(ev.target.value)
    dispatch({
      action: {
        type: FieldEnum.MinSquareMeters,
        payload: value,
      },
    })
  }

  const handleMaxSquareMetersChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = numberFromString(ev.target.value)
    dispatch({
      action: {
        type: FieldEnum.MaxSquareMeters,
        payload: value,
      },
    })
  }

  const handleMinRoomCountChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    const value = numberFromString(ev.target.value)
    dispatch({
      action: {
        type: FieldEnum.MinRoomCount,
        payload: value,
      },
    })
  }

  const handleMaxRoomCountChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    const value = numberFromString(ev.target.value)
    dispatch({
      action: {
        type: FieldEnum.MaxRoomCount,
        payload: value,
      },
    })
  }

  const commonSquareMetersProps = {
    type: 'number',
    inputMode: 'numeric',
    step: 1,
    suffix: t('commons:square_meters'),
  } as const

  const roomCountOptions = ROOM_COUNT_VALUES.map((value) => (
    <Select.Option key={value} value={value}>
      {t('commons:roomWithCount', { count: value })}
    </Select.Option>
  ))

  const minSquareMetersErrorMessage = getErrorMessage({ field: FieldEnum.MinSquareMeters }) ?? undefined
  const maxSquareMetersErrorMessage = getErrorMessage({ field: FieldEnum.MaxSquareMeters }) ?? undefined
  const minRoomCountErrorMessage = getErrorMessage({ field: FieldEnum.MinRoomCount }) ?? undefined
  const maxRoomCountErrorMessage = getErrorMessage({ field: FieldEnum.MaxRoomCount }) ?? undefined

  return (
    <SectionWrapper title={t(isShared ? 'section_headings.size_shared' : 'section_headings.size')}>
      <Stack direction={{ base: 'column', md: 'row' }} gap={{ base: '8x', md: '4x' }}>
        <TextField
          label={t('size.square_meters_label_min')}
          value={minSquareMeters ?? ''}
          onChange={handleMinSquareMetersChange}
          {...commonSquareMetersProps}
          isInvalid={Boolean(minSquareMetersErrorMessage)}
          errorMessage={minSquareMetersErrorMessage}
        />
        <TextField
          label={t('size.square_meters_label_max')}
          value={maxSquareMeters ?? ''}
          onChange={handleMaxSquareMetersChange}
          {...commonSquareMetersProps}
          isInvalid={Boolean(maxSquareMetersErrorMessage)}
          errorMessage={maxSquareMetersErrorMessage}
        />
      </Stack>
      <Stack direction={{ base: 'column', md: 'row' }} gap={{ base: '8x', md: '4x' }}>
        <Select
          label={t('size.room_count_label_min')}
          placeholder={t('size.room_count_placeholder')}
          value={minRoomCount ?? undefined}
          isInvalid={Boolean(minRoomCountErrorMessage)}
          errorMessage={minRoomCountErrorMessage}
          onChange={handleMinRoomCountChange}
        >
          {roomCountOptions}
        </Select>
        <Select
          label={t('size.room_count_label_max')}
          placeholder={t('size.room_count_placeholder')}
          value={maxRoomCount ?? undefined}
          isInvalid={Boolean(maxRoomCountErrorMessage)}
          errorMessage={maxRoomCountErrorMessage}
          onChange={handleMaxRoomCountChange}
        >
          {roomCountOptions}
        </Select>
      </Stack>
    </SectionWrapper>
  )
}
