import { useTranslation } from 'react-i18next'
import { Label, Paragraph, Spacer, Stack, HintBox, useBreakpointValue } from '@qasa/qds-ui'

import { useProtectedAuthContext } from '../../../context/protected-auth-context'
import { FirstNameField } from '../../profile/fields/first-name-field'
import { FieldWithHint } from '../components/field-with-hint'
import { Form } from '../components/form'
import { StepNavigation } from '../components/step-navigation'
import { WizardStepWrapper } from '../components/step-wrapper'
import { requiredFormField, requiredPhoneNumber } from '../create-tenant-listing.utils'
import { FormInputField } from '../../../ui-shared/forms/form-input-field'
import { useWizardRouterContext } from '../wizard-router/wizard-router'
import { ProfilePictureUpload } from '../../../ui-shared/_core/profile-picture-upload'

export function ProfileIntroStep() {
  const { goNext } = useWizardRouterContext()
  const { authBody } = useProtectedAuthContext()
  const { t } = useTranslation('create_tenant_listing')
  const isMdOrAbove = useBreakpointValue({ base: false, md: true })

  const isMissingProfilePicture = !authBody.profilePicture

  const handleNextClick = () => {
    if (isMissingProfilePicture) {
      return
    } else {
      goNext()
    }
  }
  /**
   * NOTE: Validation is not up to date for LastName
   */

  return (
    <WizardStepWrapper>
      <Form dataKey="profile-intro" onSubmit={handleNextClick}>
        {({ control, formState: { errors, isSubmitted } }) => (
          <>
            <div>
              <Label>{t('fields.upload_picture.label')}</Label>
              <Stack justifyContent={isMdOrAbove ? 'flex-start' : 'center'} direction="row">
                <ProfilePictureUpload profilePicture={authBody.profilePicture} userId={authBody.uid} />
              </Stack>
              <Spacer size="2x" />
              {isMissingProfilePicture && isSubmitted && (
                <Paragraph textAlign={isMdOrAbove ? 'left' : 'center'} size="sm" color="negative">
                  {t('fields.upload_picture.required_error')}
                </Paragraph>
              )}
            </div>
            <FirstNameField />
            <FieldWithHint>
              <FormInputField
                name={'familyName'}
                control={control}
                autoComplete="family-name"
                rules={requiredFormField}
                label={t('fields.family_name.label')}
                placeholder={t('fields.family_name.placeholder')}
                isInvalid={Boolean(errors['familyName'])}
                errorMessage={t('fields.family_name.required_error')}
              />
              <HintBox>{t('fields.family_name.hint_box')}</HintBox>
            </FieldWithHint>
            <FieldWithHint>
              <FormInputField
                control={control}
                rules={requiredPhoneNumber}
                label={t('fields.phone_number.label')}
                placeholder={t('fields.phone_number.placeholder')}
                isInvalid={Boolean(errors['phoneNumber'])}
                errorMessage={t('fields.phone_number.error')}
                name={'phoneNumber'}
              />
              <HintBox>{t('fields.phone_number.hint_box')}</HintBox>
            </FieldWithHint>
            <StepNavigation />
          </>
        )}
      </Form>
    </WizardStepWrapper>
  )
}
