import type { HTMLAttributes, ChangeEvent } from 'react'
import { useCallback } from 'react'
import { useFocusRing } from '@react-aria/focus'

import { useCheckboxGroupContext } from './checkbox-group'

type GetCheckboxProps = () => HTMLAttributes<HTMLInputElement>

export type UseCheckboxParams = {
  /**
   * If `true`, the checkbox will be checked.
   * You'll need to pass `onChange` to update its value
   */
  isChecked?: boolean
  /**
   * The callback invoked when the checked state of the Checkbox changes.
   */
  onChange?: (isChecked: boolean) => void
  /**
   * The value to be used when in a CheckboxGroup.
   */
  value?: string
}

export function useCheckbox(props: UseCheckboxParams) {
  const { isChecked: isCheckedProp, onChange: onChangeProp, value } = props
  const checkboxGroup = useCheckboxGroupContext()

  const isWithinCheckboxGroup = checkboxGroup !== undefined
  const isCheckedInGroup = value && checkboxGroup?.value?.includes(value)

  const isChecked = Boolean(isWithinCheckboxGroup ? isCheckedInGroup : isCheckedProp)

  const { isFocusVisible, focusProps } = useFocusRing()

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isInputChecked = event.target.checked

      checkboxGroup?.onChange?.(event)
      onChangeProp?.(isInputChecked)
    },
    [checkboxGroup, onChangeProp],
  )

  const getInputProps: GetCheckboxProps = useCallback(() => {
    return {
      type: 'checkbox',
      onChange: handleChange,
      checked: isChecked,
      value,
      ...(focusProps as HTMLAttributes<HTMLInputElement>),
    }
  }, [handleChange, value, isChecked, focusProps])

  return { isChecked, getInputProps, isFocusVisible }
}
