import type { ReactNode } from 'react'
import { useState } from 'react'
import { Button, Label, Stack, useBreakpointValue } from '@qasa/qds-ui'
import { styled } from '@qasa/ui/web'

import { Popover } from '../../../components/web'
import { useAppTranslation } from '../../../contexts/i18next'
import { Dialog } from '../../../components/dialog'

import { PillDropdownButton } from './components/pill-dropdown-button.web'

const StyledContent = styled(Popover.Content)({
  width: 360,
})
const PaddedPopoverBody = styled(Popover.Body)(({ theme }) => ({
  paddingBlock: theme.spacing['5x'],
  paddingInline: theme.spacing['6x'],
}))

type QuickFilterProps = {
  buttonLabel: string
  sectionTitle: string
  isActive: boolean
  onClear: () => void
  children: ReactNode
  resultCount?: number
}

export function QuickFilter({
  buttonLabel,
  sectionTitle,
  isActive,
  onClear,
  children,
  resultCount,
}: QuickFilterProps) {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useAppTranslation('filter_sections')
  const isMobile = useBreakpointValue({ base: true, md: false })

  const handleOpenAutoFocus = (event: Event) => {
    // Preferably we'd like to keep the autoFocus on opening, but
    // it doesn't work well with our date picker so I'll disable it for now -- Hugo 26/4-22
    event.preventDefault()
    // Still focus on the popover/dialog itself though
    ;(event.target as HTMLElement).focus({ preventScroll: true })
  }

  const TriggerButton = (
    <PillDropdownButton isActive={isOpen} isOpen={isOpen}>
      <Label size="sm" as={'span'}>
        {buttonLabel}
      </Label>
    </PillDropdownButton>
  )
  const FooterActions = (
    <Stack direction="row" justifyContent="space-between">
      <Button variant="tertiary" isDisabled={!isActive} onClick={onClear}>
        {t('clear_filter')}
      </Button>
      <Button onClick={() => setIsOpen(false)}>
        {resultCount ? t('show_results_with_count', { count: resultCount }) : t('show_results')}
      </Button>
    </Stack>
  )

  if (isMobile) {
    return (
      <Dialog
        isOpen={isOpen}
        onOpenChange={(isOpen) => setIsOpen(isOpen)}
        onOpenAutoFocus={handleOpenAutoFocus}
        trigger={TriggerButton}
      >
        <Dialog.Header hasSidePadding>
          <Dialog.Title>{sectionTitle}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>{children}</Dialog.Body>
        <Dialog.Footer>{FooterActions}</Dialog.Footer>
      </Dialog>
    )
  }
  return (
    <Popover isOpen={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
      <Popover.Trigger>{TriggerButton}</Popover.Trigger>
      <StyledContent onOpenAutoFocus={handleOpenAutoFocus}>
        <PaddedPopoverBody>{children}</PaddedPopoverBody>
        <Popover.Footer>{FooterActions}</Popover.Footer>
      </StyledContent>
    </Popover>
  )
}
