import { Stack } from '@qasa/qds-ui'
import { styled } from '@qasa/ui/web'
import type { ReactNode } from 'react'

const StyledFavoriteWrapper = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing['4x'],
  right: theme.spacing['4x'],
}))

type FavoriteWrapperProps = {
  children: ReactNode
}

export function FavoriteWrapper({ children }: FavoriteWrapperProps) {
  return <StyledFavoriteWrapper onClick={(e) => e.preventDefault()}>{children}</StyledFavoriteWrapper>
}
