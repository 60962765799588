import type { BaseContractFragment } from '@qasa/graphql'
import { SignatoryRoleEnum } from '@qasa/graphql'

type UploadsType = Pick<
  UNSAFE_DeepNonNullableObject<BaseContractFragment>['home']['uploads'][number],
  'metadata' | 'id' | 'url' | '__typename'
>[]

export const getSortedUploads = (uploads: UploadsType) => {
  const sortedByOrder = uploads.slice().sort((a, b) => {
    if (a.metadata?.order && b.metadata?.order && a.metadata?.order !== b.metadata?.order) {
      return a.metadata.order - b.metadata.order
    }
    return a.id > b.id ? 1 : -1
  })

  return sortedByOrder
}

export const getPrimaryUpload = (
  uploads: UploadsType,
): UNSAFE_DeepNonNullableObject<BaseContractFragment>['home']['uploads'][number] => {
  const primaryUploads = uploads.filter((x) => x.metadata?.primary)

  if (primaryUploads.length) {
    return primaryUploads[primaryUploads.length - 1]
  } else {
    return getSortedUploads(uploads)[0]
  }
}

type Signatories = Omit<
  UNSAFE_DeepNonNullableObject<BaseContractFragment>['signatories'][number],
  '__typename' | 'order' | 'signingDeadline' | 'user'
>[]
export const getHasLandlordSigned = (signatories: Signatories) => {
  return signatories.some((signatory) => {
    return signatory.signedAt && signatory.role === SignatoryRoleEnum.landlord
  })
}
