import { useState } from 'react'
import { Button, RadioGroup, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ShortcutProposalDeclineReasonEnum } from '@qasa/graphql'
import { Dialog } from '@qasa/app/src/components/dialog'

import { useShortcutDecision } from '../use-shortcut-decision'
import type { ShortcutOptInStepProps } from '../../../legacy-pages/shortcut-opt-in'

const REASONS = [
  ShortcutProposalDeclineReasonEnum.want_to_meet_tenant,
  ShortcutProposalDeclineReasonEnum.diy,
  ShortcutProposalDeclineReasonEnum.own_pictures,
  ShortcutProposalDeclineReasonEnum.too_expensive,
  ShortcutProposalDeclineReasonEnum.unclear_offering,
  ShortcutProposalDeclineReasonEnum.missing_features,
  ShortcutProposalDeclineReasonEnum.payment_plan,
  ShortcutProposalDeclineReasonEnum.other,
]

export function DeclineShortcut({ goNext }: ShortcutOptInStepProps) {
  const { t } = useTranslation('shortcut_opt_in', { keyPrefix: 'decline_dialog' })
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false)
  const { homeId } = useParams<{ homeId: string }>()
  const [declineReason, setDeclineReason] = useState<ShortcutProposalDeclineReasonEnum | undefined>(undefined)

  const { optOutShortcut, isOptOutLoading } = useShortcutDecision({
    homeId,
    onOptInCompleted: goNext,
  })

  return (
    <Dialog
      isOpen={isDeclineModalOpen}
      onOpenChange={(isOpen) => setIsDeclineModalOpen(isOpen)}
      size="md"
      trigger={<Button variant="tertiary">{t('not_interested_button')}</Button>}
    >
      <Dialog.Header hasSidePadding>
        <Dialog.Title size="md">{t('title')}</Dialog.Title>
      </Dialog.Header>
      <Dialog.Body hasPadding>
        <RadioGroup
          value={declineReason}
          onValueChange={(value) => setDeclineReason(value as ShortcutProposalDeclineReasonEnum)}
        >
          <Stack gap="2x">
            {REASONS.map((reason) => {
              return <RadioGroup.Card key={reason} label={t(`reasons.${reason}`)} value={reason} />
            })}
          </Stack>
        </RadioGroup>
      </Dialog.Body>
      <Dialog.Footer>
        <Stack gap="2x" direction={'row'} justifyContent={'center'}>
          <Button variant={'tertiary'} onClick={() => setIsDeclineModalOpen(false)}>
            {t('cancel')}
          </Button>
          <Button
            variant={'primary'}
            onClick={() => optOutShortcut({ variables: { declineReason, homeId } })}
            isLoading={isOptOutLoading}
          >
            {t('submit')}
          </Button>
        </Stack>
      </Dialog.Footer>
    </Dialog>
  )
}
