import type { ComponentPropsWithoutRef, ElementType } from 'react'
import { useEffect, useState } from 'react'

type LoadingStatus = 'loading' | 'loaded' | 'error' | 'pending'

type HTMLQdsProps<T extends ElementType> = ComponentPropsWithoutRef<T>

export type UseImageProps = {
  src?: string
  loading?: HTMLQdsProps<'img'>['loading']
}
export const useImage = ({ src, loading }: UseImageProps) => {
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(src ? 'loading' : 'pending')

  useEffect(() => {
    if (!src) {
      setLoadingStatus('pending')
      return
    }

    const updateStatus = (status: LoadingStatus) => () => {
      setLoadingStatus(status)
    }

    setLoadingStatus('loading')

    const image = new Image()
    image.onload = updateStatus('loaded')
    image.onerror = updateStatus('error')
    image.src = src
    if (loading) {
      image.loading = loading
    }
  }, [loading, src])

  return { loadingStatus }
}
