import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrowLeftIcon, Button, Heading, IconButton, LoadingDots, Stack } from '@qasa/qds-ui'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { useAuthContext } from '../../../context/auth-context'
import { useListingContext } from '../listing-context'
import { SectionWrapper } from '../section-wrapper'
import { Wizard } from '../../../ui-shared/wizard'

type PreLoginSectionProps = {
  previousSection?: string
}
export function PreLoginSection({ previousSection }: PreLoginSectionProps) {
  const { isAuthenticated } = useAuthContext()
  const { t } = useTranslation('listing')
  const {
    createHome,
    store: {
      values: { locality },
    },
  } = useListingContext()
  const location = useLocation()

  useEffect(() => {
    if (isAuthenticated) {
      createHome()
    }
    /**
     * TODO: If we added createHome as a dependency here it will run several times, creating more homes, and we don't want that.
     * We probably want to control this in a more readable way, though.
     */
  }, [isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isAuthenticated) {
    return (
      <Stack alignItems="center">
        <LoadingDots />
      </Stack>
    )
  }
  return (
    <SectionWrapper
      title={t('section_headings.pre_login')}
      description={t('pre_login.description', { area: locality })}
    >
      <Heading as="p" size="2xs">
        {t('pre_login.prompt')}
      </Heading>
      <Wizard.Footer>
        <Stack
          // `row-reverse` makes it so the right-most button will have the lowest tabindex
          direction="row-reverse"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button variant="primary" onClick={createHome}>
            {t('pre_login.cta')}
          </Button>
          {previousSection && (
            <IconButton
              as={RouterLink}
              to={{ ...location, pathname: previousSection }}
              variant="tertiary"
              icon={ArrowLeftIcon}
              label={t('commons:back')}
            />
          )}
        </Stack>
      </Wizard.Footer>
    </SectionWrapper>
  )
}
