import type { HousingSituationAgreementTypeEnum } from '@qasa/graphql'
import { ArrowLeftIcon, Button, Heading, IconButton, Paragraph, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { DialogAndWizardActionsFooter } from '../../../ui-shared/dialog-and-wizard-actions-footer'
import type { HousingSituationStepProps } from '../housing-situation'

type ReferenceNotSupportedProps = Omit<HousingSituationStepProps, 'requiredValues'> & {
  agreementType?: HousingSituationAgreementTypeEnum | null
}
export function ReferenceNotSupported({ agreementType, goNext, goBack }: ReferenceNotSupportedProps) {
  const { t } = useTranslation('edit_profile')

  return (
    <Stack gap="8x">
      <Heading size="lg">{t('form.housing_situation.reference_not_supported.title')}</Heading>
      <Paragraph>
        {agreementType
          ? t('form.housing_situation.reference_not_supported.description', {
              agreementType: t(`form.bio.fields.currentAgreementType.${agreementType}`).toLowerCase(),
            })
          : t('form.housing_situation.reference_not_supported.description_no_agreement_type')}
      </Paragraph>
      <DialogAndWizardActionsFooter>
        <IconButton
          type="button"
          icon={ArrowLeftIcon}
          variant="tertiary"
          label={t('commons:back')}
          onClick={() => goBack()}
        />
        <Button onClick={() => goNext({ context: { shouldHideSuccess: true } })}>{t('commons:close')}</Button>
      </DialogAndWizardActionsFooter>
    </Stack>
  )
}
