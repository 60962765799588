import { graphql } from '@qasa/graphql'

export const EXISTING_BOOKINGS = graphql(`
  query ExistingBookings($homeId: ID!, $status: [BookingStatusEnum!]) {
    bookings(homeId: $homeId, status: $status) {
      id
      conversation {
        id
      }
      status
      checkIn
      checkOut
      pricingInfo {
        rentPrice
        tenantFee
        landlordFee
        totalTenantPrice
        cleaningFee
      }
    }
  }
`)
