import { useListingContext } from '../../listing-context'
import { getIsProfessionalWithSeveralListings } from '../../listing.utils'

import { MultipleHomesSizeSection } from './multiple-homes-size-section'
import { SingleHomeSizeSection } from './single-home-size-section'

export function SizeSection() {
  const {
    store: {
      values: { isProfessional, numberOfHomes },
    },
  } = useListingContext()

  const shouldRenderRanges = getIsProfessionalWithSeveralListings({
    isProfessional,
    numberOfHomes,
  })
  return shouldRenderRanges ? <MultipleHomesSizeSection /> : <SingleHomeSizeSection />
}
