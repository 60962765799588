import { useQuery } from '@apollo/client'
import { graphql } from '@qasa/graphql'
import { useEffect, useState } from 'react'

import type { FindHomeFilterValues } from '../contexts/find-home-filter-context'
import type { SelectedAreaWithSearchAreaData } from '../../../types/find-home'

const SEARCH_AREA = graphql(`
  query SearchArea($areaParams: [AreaParamsInput!]) {
    searchArea(areaParams: $areaParams) {
      ...SearchAreaFields
    }
  }
  fragment SearchAreaFields on SearchArea {
    name
    city
    identifier
    geojson
  }
`)

export const useSearchAreaQuery = ({ filterValues }: { filterValues: FindHomeFilterValues }) => {
  const [searchAreasWithAreaData, setSearchAreasWithAreaData] = useState<SelectedAreaWithSearchAreaData[]>([])
  const { data, loading: isLoadingAreas } = useQuery(SEARCH_AREA, {
    variables: {
      areaParams: filterValues.searchAreas.map(({ name, city, countryCode }) => ({
        name,
        city,
        // NOTE: We don't want to send in empty string due to BE seeing it as a value,
        // in that case BE does not fallback to platform default country code
        countryCode: countryCode || null,
      })),
    },
  })

  useEffect(() => {
    if (isLoadingAreas || !data?.searchArea) {
      return
    }
    setSearchAreasWithAreaData(data.searchArea)
  }, [data, isLoadingAreas])

  return { areas: searchAreasWithAreaData, isLoadingAreas }
}
