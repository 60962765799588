import * as SliderPrimitive from '@radix-ui/react-slider'
import { styled } from '@qasa/ui/web'
import { Label, Paragraph, Stack } from '@qasa/ui'
import { useMemo } from 'react'
import { useStableId } from '@qasa/qds-ui'

import type { RangeSliderProps } from './range-slider.types'
import { getRangeSliderLabels } from './range-slider.utils'

const Slider = styled(SliderPrimitive.Root)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: theme.sizes['6x'],
  touchAction: 'none',
}))

const Track = styled(SliderPrimitive.Track)(({ theme }) => ({
  backgroundColor: theme.colors.core.gray30,
  borderRadius: theme.radii.full,
  height: 3,
  position: 'relative',
  flexGrow: 1,
}))

const Range = styled(SliderPrimitive.Range)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: theme.colors.core.black,
  borderRadius: theme.radii.full,
  height: '100%',
}))

const Thumb = styled(SliderPrimitive.Thumb)(({ theme }) => ({
  display: 'block',
  width: theme.sizes['6x'],
  height: theme.sizes['6x'],
  backgroundColor: theme.colors.core.white,
  borderRadius: theme.radii.full,
  cursor: 'pointer',
  border: `1px solid ${theme.colors.border.default}`,
  ':hover': {
    borderColor: theme.colors.border.defaultHover,
  },
  ':focus:not(:focus-visible)': {
    outline: 'none',
  },
  ':focus-visible': {
    outline: '2px solid',
    outlineOffset: 2,
  },
  ':active': {
    outline: 'none',
  },
}))

const TabularParagraph = styled(Paragraph)({
  // Blockets font doesn't support this but Qasa does
  fontVariantNumeric: 'tabular-nums',
})

export function RangeSlider({
  value,
  onValueChange,
  onValueCommit,
  min = 0,
  max = 100,
  step = 1,
  minRange = 1,
  label,
  formatValue,
}: RangeSliderProps) {
  const [formattedMinValue, formattedMaxValue] = useMemo(
    () => getRangeSliderLabels({ value, formatValue }),
    [value, formatValue],
  )
  const labelId = useStableId()

  return (
    <Stack gap={'2x'}>
      <Stack gap={'4x'}>
        <Label id={labelId}>{label}</Label>
        <Slider
          value={value}
          min={min}
          max={max}
          step={step}
          aria-labelledby={labelId}
          minStepsBetweenThumbs={minRange}
          onValueChange={onValueChange}
          onValueCommit={onValueCommit}
        >
          <Track>
            <Range />
          </Track>
          {value.map((_, index) => (
            <Thumb key={index} />
          ))}
        </Slider>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <TabularParagraph>{formattedMinValue}</TabularParagraph>
        <TabularParagraph>{formattedMaxValue}</TabularParagraph>
      </Stack>
    </Stack>
  )
}
