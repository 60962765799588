import { Label, TextField } from '@qasa/qds-ui'
import type { UseComboboxStateChange } from 'downshift'
import { useCombobox } from 'downshift'
import { Fragment, useState } from 'react'
import styled from '@emotion/styled'
import { VisuallyHidden } from '@qasa/app/src/components/visually-hidden'
import { useFormContext } from 'react-hook-form'
import type { UpdateUserInput } from '@qasa/graphql'
import type { ApolloError } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { NetworkStatus } from '../../ui-shared/_core/network-statuses'

const List = styled.ul(({ theme }) => ({
  padding: theme.spacing['2x'],
  display: 'flex',
  flexDirection: 'column',
  minHeight: theme.sizes['144'],
  maxHeight: theme.sizes['144'],
  position: 'absolute',
  top: `calc(100% + ${theme.spacing['2x']})`,
  left: 0,
  right: 0,
  background: theme.colors.bg.default,
  borderRadius: theme.radii.md,
  border: '1px solid',
  borderColor: theme.colors.border.subtle,
  boxShadow: theme.shadows.lg,
  overflowY: 'auto',
  zIndex: theme.zIndices.tooltip,
}))

const Option = styled('li')<{ isHighlighted: boolean; isSelected: boolean }>(
  ({ isHighlighted, isSelected, theme }) => ({
    appearance: 'none',
    paddingInline: theme.spacing['4x'],
    paddingBlock: theme.spacing['2x'],
    flexShrink: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    background: isHighlighted ? theme.colors.bg.brandTertiary : 'inherit',
    borderRadius: theme.radii.xs,
    userSelect: 'none',
    cursor: 'pointer',
    ...(isSelected && { ...theme.typography.body.md, background: theme.colors.bg.brandTertiary }),
  }),
)

type LandlordItem = { name: string; id: string }
type LandlordComboboxProps = {
  landlordList: LandlordItem[]
  isLoading?: boolean
  error?: ApolloError
}
export function LandlordCombobox({ landlordList, isLoading, error }: LandlordComboboxProps) {
  const { t } = useTranslation('edit_profile')
  const [items, setItems] = useState(landlordList)
  const { setValue, getValues } = useFormContext<UpdateUserInput>()
  const landlordName = getValues('housingSituation.landlordName')

  const handleInputValueChange = ({ inputValue, selectedItem }: UseComboboxStateChange<LandlordItem>) => {
    const lowerCaseInput = inputValue?.toLowerCase()
    setItems(
      landlordList.filter(({ name }) =>
        lowerCaseInput ? name.toLowerCase().includes(lowerCaseInput) : true,
      ),
    )
    if (!lowerCaseInput || !selectedItem?.name?.toLowerCase()?.includes(lowerCaseInput)) {
      setValue('housingSituation.externalLandlordReference', null)
      setValue('housingSituation.landlordName', inputValue)
    }
  }
  const handleSelectedItemChange = ({ selectedItem }: UseComboboxStateChange<LandlordItem>) => {
    setValue('housingSituation.landlordName', selectedItem?.name)
    setValue('housingSituation.externalLandlordReference', selectedItem?.id)
  }

  const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps, selectedItem } = useCombobox({
    onInputValueChange: handleInputValueChange,
    onSelectedItemChange: handleSelectedItemChange,
    items,
    itemToString(item) {
      return item ? item.name : ''
    },
    inputValue: landlordName ?? undefined,
  })

  const ListBoxWrapper = isOpen && items.length ? Fragment : VisuallyHidden

  return (
    <div style={{ position: 'relative' }}>
      <TextField
        {...getInputProps()}
        placeholder={t('form.housing_situation.housing_reference.landlord_name.placeholder')}
        label={t('form.housing_situation.housing_reference.landlord_name.label')}
      />
      <ListBoxWrapper>
        <List {...getMenuProps()}>
          {isLoading || error ? (
            <NetworkStatus isLoading={isLoading} error={error} />
          ) : (
            items.map((item, index) => (
              <Option
                key={item.id}
                isHighlighted={highlightedIndex === index}
                isSelected={selectedItem === item}
                {...getItemProps({ item, index })}
              >
                <Label as="span" style={{ cursor: 'inherit' }}>
                  {item.name}
                </Label>
              </Option>
            ))
          )}
        </List>
      </ListBoxWrapper>
    </div>
  )
}
