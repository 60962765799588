import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useToastContext } from '@qasa/app'
import { Dialog } from '@qasa/app/src/components/dialog'
import { Heading, Paragraph, Spacer, Stack } from '@qasa/ui'
import { Button } from '@qasa/qds-ui'

import { DESTROY_OCCUPATION } from './occupation.gql'

type RemoveOccupationDialogProps = {
  occupationId: string
  onDeleteComplete?: () => void
  isOpen: boolean
  onClose: () => void
}
export function DeleteOccupationDialog({
  occupationId,
  onDeleteComplete,
  isOpen,
  onClose,
}: RemoveOccupationDialogProps) {
  const { t } = useTranslation('edit_profile')
  const { addToast } = useToastContext()

  const handleError = () => addToast({ message: t('commons:unexpected_error') })
  const handleComplete = () => {
    onDeleteComplete && onDeleteComplete()
    onClose()
  }

  const [destroyOccupation, { loading: isLoading }] = useMutation(DESTROY_OCCUPATION, {
    variables: { destroyOccupationId: occupationId },
    onCompleted: handleComplete,
    onError: handleError,
  })

  return (
    <Dialog isOpen={isOpen} onOpenChange={onClose} trigger={null}>
      <Dialog.Body>
        <Dialog.Header>
          <Heading size={'sm'}>{t('form.occupation.delete_occupation_dialog.title')}</Heading>
        </Dialog.Header>
        <Spacer size={'4x'} />
        <Paragraph>{t('form.occupation.delete_occupation_dialog.body')}</Paragraph>
      </Dialog.Body>
      <Dialog.Footer>
        <Stack direction={'row'} gap={'2x'} justifyContent={'center'}>
          <Button variant={'tertiary'} onClick={onClose}>
            {t('commons:back')}
          </Button>
          <Button isLoading={isLoading} onClick={() => destroyOccupation()}>
            {t('form.occupation.delete_occupation_dialog.delete')}
          </Button>
        </Stack>
      </Dialog.Footer>
    </Dialog>
  )
}
