import { TraitTypeEnum, graphql } from '@qasa/graphql'
import type { UseHomeBaseInfoFragment, HomeContextFragment, Home, User } from '@qasa/graphql'

import { useAppTranslation } from '../contexts/i18next'
import { useRegionConfig } from '../configs/use-region-config'
import { useRegionContext, type Region } from '../contexts/region'
import { useOrdinalNumber } from '../hooks'

import { formatNumber, formatSpan } from './number'
import { isNotNull } from './misc'

type GetTotalMonthlyCostParams = Pick<Home, 'rent' | 'tenantBaseFee'>
export const getTotalMonthlyCost = ({ rent, tenantBaseFee }: GetTotalMonthlyCostParams) => {
  return (rent ?? 0) + (tenantBaseFee ?? 0)
}

export const getFormattedMonthlyCost = ({
  home,
}:
  | { home: GetTotalMonthlyCostParams & Pick<Home, 'currency'> }
  | {
      home: Pick<Home, 'tenantBaseFee' | 'rent' | 'currency' | 'numberOfHomes' | 'minRent' | 'maxRent'>
    }) => {
  const { tenantBaseFee, rent, currency } = home
  const totalMonthlyCost = getTotalMonthlyCost({ rent, tenantBaseFee })

  if ('numberOfHomes' in home) {
    const { numberOfHomes, minRent, maxRent } = home
    const isBlockListing = getIsHomeBlockListing({ numberOfHomes })
    if (isBlockListing) {
      return formatSpan({ min: minRent, max: maxRent, currency, fallbackNumber: totalMonthlyCost })
    }
  }

  return formatNumber({ amount: totalMonthlyCost, currency })
}

export function useFormattedSquareMeters({
  home,
}:
  | { home: Pick<Home, 'squareMeters'> }
  | {
      home: Pick<Home, 'squareMeters' | 'numberOfHomes' | 'minSquareMeters' | 'maxSquareMeters'>
    }) {
  const { t } = useAppTranslation('common_home')
  const { squareMeters } = home
  const squareMetersKey = 'home_info.square_meters'

  if ('numberOfHomes' in home) {
    const { numberOfHomes, minSquareMeters, maxSquareMeters } = home
    const isBlockListing = getIsHomeBlockListing({ numberOfHomes })
    if (isBlockListing) {
      const isSpan = minSquareMeters !== maxSquareMeters
      return isSpan
        ? t(squareMetersKey, { min: minSquareMeters, max: maxSquareMeters, context: 'span' })
        : t(squareMetersKey, { count: maxSquareMeters ?? 1 })
    }
  }
  return squareMeters ? t(squareMetersKey, { amount: squareMeters }) : ''
}

export function useFormattedRoomCount({
  home,
}:
  | {
      home: Pick<Home, 'roomCount' | 'numberOfHomes' | 'minRoomCount' | 'maxRoomCount'>
    }
  | { home: Pick<Home, 'roomCount'> }) {
  const { t } = useAppTranslation('common_home')
  const roomCountKey = 'home_info.room_count'

  if ('numberOfHomes' in home) {
    const { numberOfHomes, minRoomCount, maxRoomCount } = home
    const isBlockListing = getIsHomeBlockListing({ numberOfHomes })
    if (isBlockListing) {
      const isSpan = minRoomCount !== maxRoomCount
      return isSpan
        ? t(roomCountKey, { min: minRoomCount, max: maxRoomCount, context: 'span' })
        : t(roomCountKey, { count: maxRoomCount ?? 1 })
    }
  }

  const { roomCount } = home
  return roomCount ? t(roomCountKey, { count: roomCount }) : ''
}

export function useHomeStatus({ home }: { home: Pick<Home, 'status'> }) {
  const { t } = useAppTranslation('common_home')
  return t(`home_status.${home.status}`)
}

export function useFormattedRent({ home }: { home: Pick<Home, 'rent'> }) {
  const currency = useRegionConfig().currency
  return formatNumber({ currency, amount: home.rent ?? 0 })
}

export function getIsHomeBlockListing({ numberOfHomes }: { numberOfHomes: number | null }) {
  return Boolean(numberOfHomes && numberOfHomes > 1)
}

export const getIsHomeProPilot = ({ home }: { home: { landlord: Pick<User, 'proPilot'> } }) => {
  return home.landlord.proPilot
}

export const getIsSublet = ({
  corporateHome: isCorporateHome,
  firsthand: isFirsthand,
  seniorHome: isSeniorHome,
  studentHome: isStudentHome,
}: Pick<Home, 'firsthand' | 'corporateHome' | 'seniorHome' | 'studentHome'>) => {
  return !isCorporateHome && !isFirsthand && !isSeniorHome && !isStudentHome
}

export type ContractTypes = 'firsthand' | 'seniorHome' | 'corporateHome' | 'studentHome'

export enum TagVariant {
  Shortcut = 'shortcut',
  InstantSign = 'instant_sign',
  Firsthand = 'firsthand',
}

type GetHomeTagVariantParams = {
  isInstantSign: boolean
  isShortcutHome: boolean
  isFirsthand: boolean
}
export const getHomeTagVariant = ({
  isInstantSign,
  isShortcutHome,
  isFirsthand,
}: GetHomeTagVariantParams): TagVariant | null => {
  switch (true) {
    case isInstantSign:
      return TagVariant.InstantSign
    case isShortcutHome:
      return TagVariant.Shortcut
    case isFirsthand:
      return TagVariant.Firsthand
    default:
      return null
  }
}

type GetFormattedAddressParams = {
  location: Pick<HomeContextFragment['location'], 'locality' | 'route' | 'streetNumber'>
  shouldDisplayStreetNumber: boolean
}
export const getFormattedAddress = ({ location, shouldDisplayStreetNumber }: GetFormattedAddressParams) => {
  const { route, locality, streetNumber } = location

  return shouldDisplayStreetNumber && streetNumber
    ? `${route} ${streetNumber}, ${locality}`
    : `${route}, ${locality}`
}

graphql(`
  fragment UseHomeBaseInfo on Home {
    type
    numberOfHomes
    roomCount
    minRoomCount
    maxRoomCount
    squareMeters
    minSquareMeters
    maxSquareMeters
    studentHome
    seniorHome
    corporateHome
    shared
    traits {
      type
    }
    floor
    buildingFloors
  }
`)

export const useHomeBaseInfo = (home: UseHomeBaseInfoFragment) => {
  const { t } = useAppTranslation('common_home')
  const { region } = useRegionContext()
  const { getOrdinalNumber } = useOrdinalNumber()
  const size = useFormattedSquareMeters({ home })
  const rooms = useFormattedRoomCount({ home })
  const {
    studentHome: isStudentHome,
    seniorHome: isSeniorHome,
    corporateHome: isCorporateHome,
    type,
    numberOfHomes,
    shared: isShared,
    traits,
    floor,
    buildingFloors,
  } = home

  const isBlockListing = getIsHomeBlockListing({ numberOfHomes })

  const getContractTypeTranslation = () => {
    switch (true) {
      case isStudentHome:
        return t('first_hand_type.student_home')
      case isSeniorHome:
        return t('first_hand_type.senior_home')
      case isCorporateHome:
        return t('first_hand_type.corporate_home')
      default:
        return null
    }
  }
  const getHomeTypeTranslation = () => {
    const homeTypeKey = `home_type.${type}`

    if (isBlockListing) {
      return t(homeTypeKey, { count: numberOfHomes ?? 1 })
    }
    if (isShared) {
      return `${t('home_info.room_in')} ${t(homeTypeKey)}`
    }
    return t(homeTypeKey)
  }
  const getFurnishedTranslation = () => {
    const hasFurniture = traits.find((trait) => trait.type === TraitTypeEnum.furniture)
    if (isBlockListing) {
      return null
    }
    return hasFurniture ? t('home_info.furnished') : t('home_info.unfurnished')
  }
  const getHomeFloorInfoTranslation = () => {
    if (floor && buildingFloors) {
      return t('home_info.floor_number_with_total', { floor, buildingFloors })
    } else if (floor) {
      return t('home_info.floor_number_ordinal', { ordinalFloor: getOrdinalNumber(floor) })
    } else if (buildingFloors) {
      return t('home_info.building_floors', { buildingFloors })
    }
    return null
  }

  const contractType = getContractTypeTranslation()
  const homeType = getHomeTypeTranslation()
  const furnished = getFurnishedTranslation()
  const homeFloorInfo = getHomeFloorInfoTranslation()

  const defaultMainBullets = [contractType, homeType, rooms, size]
  const defaultSecondaryBullets = [furnished, homeFloorInfo]
  const bulletsByRegion: Record<Region, { main: (string | null)[]; secondary: (string | null)[] }> = {
    se: {
      main: defaultMainBullets,
      secondary: defaultSecondaryBullets,
    },
    fi: {
      main: defaultMainBullets,
      secondary: [homeFloorInfo],
    },
    fr: {
      main: defaultMainBullets,
      secondary: defaultSecondaryBullets,
    },
    international: {
      main: defaultMainBullets,
      secondary: defaultSecondaryBullets,
    },
  }

  const mainBullets = bulletsByRegion[region].main.filter(isNotNull)
  const secondaryBullets = bulletsByRegion[region].secondary.filter(isNotNull)

  return { mainBullets, secondaryBullets, size, rooms }
}
