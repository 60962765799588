import { useTranslation } from 'react-i18next'
import { FeeAvailabilityEnum } from '@qasa/graphql'

import { useListingContext } from '../listing-context'
import { SectionWrapper } from '../section-wrapper'
import { WaterFeeField } from '../fields/water-fee-field'
import { ElectricityFeeField } from '../fields/electricity-fee-field'

export function UtilityCosts() {
  const { t } = useTranslation('listing')

  const { tenantFees } = useListingContext()

  const shouldShowWaterFee = tenantFees?.waterFeePerTenant.availability === FeeAvailabilityEnum.mandatory
  const shouldShowElectricityFee =
    tenantFees?.monthlyElectricityFee?.availability === FeeAvailabilityEnum.optional

  return (
    <SectionWrapper title={t('section_headings.utility_costs')}>
      {shouldShowElectricityFee && <ElectricityFeeField />}
      {shouldShowWaterFee && <WaterFeeField />}
    </SectionWrapper>
  )
}
