import type { ReactNode } from 'react'
import { useEffect } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { useWatch, useForm, FormProvider } from 'react-hook-form'
import styled from '@emotion/styled'

import { useCreateTenantListingContext } from '../create-tenant-listing-context'
import type { CreateTenantListingFormValues, CreateTenantListingFormValuesKey } from '../step-declarations'

const SectionWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
})

type MaybeRenderProp<TDataKey extends CreateTenantListingFormValuesKey> =
  | ((methods: UseFormReturn<CreateTenantListingFormValues[TDataKey]>) => ReactNode)
  | ReactNode

type FormProps<TDataKey extends CreateTenantListingFormValuesKey> = {
  children: MaybeRenderProp<TDataKey>
  dataKey: TDataKey
  onSubmit: () => void
}

export function Form<TDataKey extends CreateTenantListingFormValuesKey>({
  children,
  dataKey,
  onSubmit,
}: FormProps<TDataKey>) {
  const { formValues, updateFormValues } = useCreateTenantListingContext()
  const initialValues = formValues[dataKey]

  const methods = useForm<CreateTenantListingFormValues[TDataKey]>({
    /**
     * TDataKey is type-safed to always be right identifier, typescript can not
     * know this since it is generic  keyof identfier.
     * In runtime typescript gets it and it works as intended.
     */
    // @ts-ignore
    defaultValues: initialValues,
    mode: 'onChange',
  })

  const {
    control,
    formState: { isValid, isValidating },
  } = methods
  const newFormData = useWatch({ control })

  useEffect(() => {
    if (isValid && !isValidating) {
      updateFormValues({ dataKey, payload: newFormData })
    }
  }, [dataKey, isValid, isValidating, newFormData, updateFormValues])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <SectionWrapper>{typeof children === 'function' ? children(methods) : children}</SectionWrapper>
      </form>
    </FormProvider>
  )
}
