import { Heading, Button, Stack, Paragraph, IconButton, ArrowLeftIcon } from '@qasa/qds-ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { Dialog } from '@qasa/app/src/components/dialog'
import { IdentificationStatusEnum } from '@qasa/graphql'

import { useVerifyIdWithScrive } from '../../../../hooks/use-verify-id-with-scrive'
import { PageNetworkStatus } from '../../../../ui-shared/_core/network-statuses'
import { BRAND_CONFIG } from '../../../../config/brand-configurations'
import type { CreditVerificationStepProps } from '../credit-verification-step'
import { UserDataVisibilityHintbox } from '../../../../ui-shared/user-data-visibility-hintbox'
import { DialogAndWizardActionsFooter } from '../../../../ui-shared/dialog-and-wizard-actions-footer'

import { CreditVerificationSkip } from './credit-verification-skip'

const Wrapper = Stack

export function CreditVerificationInitial({
  isOptional,
  onSkipCreditFlow,
  goNext,
  goBack,
}: CreditVerificationStepProps) {
  const [isSkipping, setIsSkipping] = useState(false)
  const { t } = useTranslation('create_application_with_verifications')
  /**
   * NOTE: Credit report requires transaction_id from scrive which we do not retain if we route to our id-verification route.
   * This works for now since we only support "pro" homes in Sweden.
   */
  const { identificationStatus, error, verifyIdThroughScrive, isLoading } = useVerifyIdWithScrive({
    identificationType: BRAND_CONFIG.availableIdVerifications[0],
  })
  const [{ transaction_id: scriveToken }, setQueryParams] = useQueryParams({
    transaction_id: withDefault(StringParam, null),
    success: withDefault(StringParam, null),
  })

  useEffect(() => {
    if (identificationStatus === IdentificationStatusEnum.accepted) {
      goNext()
    }
    if (error) {
      setQueryParams(
        {
          success: null,
          transaction_id: null,
        },
        'replaceIn',
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificationStatus, error])

  if (scriveToken && isLoading) {
    return <PageNetworkStatus isLoading={true} />
  }

  if (isSkipping) {
    return <CreditVerificationSkip onSkipCreditFlow={onSkipCreditFlow} goNext={goNext} />
  }

  return (
    <Dialog.Body>
      <Wrapper gap="8x">
        <Heading size="lg" as="h3">
          {t('credit_verification_step.title')}
        </Heading>
        <Paragraph>{t('credit_verification_step.initial.description')}</Paragraph>
        <UserDataVisibilityHintbox mode="private" />
        <DialogAndWizardActionsFooter>
          <IconButton
            type="button"
            icon={ArrowLeftIcon}
            variant="tertiary"
            label={t('commons:back')}
            onClick={() => goBack()}
          />
          <Stack gap="2x" direction="row">
            {isOptional && (
              <Button variant="tertiary" onClick={() => setIsSkipping(true)}>
                {t('commons:skip')}
              </Button>
            )}
            <Button onClick={verifyIdThroughScrive} isLoading={isLoading}>
              {t('commons:next')}
            </Button>
          </Stack>
        </DialogAndWizardActionsFooter>
      </Wrapper>
    </Dialog.Body>
  )
}
