import type { ExternalLinks } from '.'

export const BLOCKET_EXTERNAL_LINKS: ExternalLinks = {
  helpCenterUrl: 'https://support.bostad.blocket.se/',
  requestSupportUrl: 'https://support.bostad.blocket.se/hc/requests/new?ticket_form_id=7454891211665',
  insuranceClaim: 'https://external.omocom.se/claimsform',
  termsAndConditionsUrl: 'https://support.bostad.blocket.se/hc/articles/360018409778',
  vacationTermsAndConditionsUrl: 'https://support.bostad.blocket.se/hc/articles/360034334311',
  insuranceSupportUrl: 'https://support.bostad.blocket.se/hc/articles/360018299578',
  HedvigInsuranceAppUrl: 'https://www.hedvig.com/se/hedvig-appen',
  HedvigInsuranceInfoUrl: 'https://www.hedvig.com/se/partner/qasa-kom-igang',
  rentGuaranteSupportUrl: 'https://support.bostad.blocket.se/hc/articles/360033961052',
  tenantBaseFeeSupportUrl: 'https://support.bostad.blocket.se/hc/articles/360052201012',
  vacationTenantBaseFeeSupportUrl: 'https://support.bostad.blocket.se/hc/articles/360034334311',
  depositFreeSupportUrl: 'https://support.bostad.blocket.se/hc/articles/360020910697',
  privacyPolicyUrl: 'https://info.privacy.schibsted.com/se/schibsted-sverige-personuppgiftspolicy/',
  personalDataSupportUrl: 'https://support.bostad.blocket.se/hc/articles/360018409778 ',
  creditCheckSupportUrl: 'https://support.bostad.blocket.se/hc/articles/360033960812',
  incomeVerificationSupportUrl: 'https://support.bostad.blocket.se/hc/articles/360033960812',
  schibstedAccountSettingsUrl: 'https://login.schibsted.com/account/summary',
  vacationMoreInfoUrl: 'https://www.blocket.se/tips-och-guider/hus-och-lagenhet/hyr-sommarstuga',
  sortingPolicyUrl: 'https://support.bostad.blocket.se/hc/articles/17766936900625',
  landlordContractTerminationSupportUrl: 'https://support.bostad.blocket.se/hc/articles/360033961432',
  tenantContractTerminationSupportUrl: 'https://support.bostad.blocket.se/hc/articles/360033960952',
  securitySupportUrl: 'https://support.bostad.blocket.se/hc/articles/360046205671',
  settingRentSupportUrl: 'https://support.bostad.blocket.se/hc/articles/360034335311',
  shortcutMoreInfo: 'https://support.bostad.blocket.se/hc/articles/13159575865745',
  forCompaniesSupportUrl: 'https://support.qasa.se/hc/articles/17495920242065',
  pricing: 'https://support.bostad.blocket.se/hc/articles/22955763303953',
  infoEmail: 'info@qasa.se',
  publishListingsSupportUrl: 'https://support.bostad.blocket.se/hc/articles/17293203208849',
  landlordOfferingSupportUrl: 'https://support.bostad.blocket.se/hc/articles/360034334931',
  landlordRentPaymentIssuesSupportUrl: 'https://support.bostad.blocket.se/hc/articles/360045437711',
  keyExchangeReceiptUrl: 'https://support.qasa.se/hc/article_attachments/17494819815057',
  inventoryListUrl: 'https://support.qasa.se/hc/article_attachments/19851116824849',
  depositHandlingSupportUrl: 'https://support.bostad.blocket.se/hc/articles/360034335091',
  connectedAccounts: 'https://support.bostad.blocket.se/hc/articles/25734994255505',
}
