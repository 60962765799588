import { Dialog } from '@qasa/app/src/components/dialog'

import { useProfileContext } from '../profile-context'
import { TenantOccupation } from '../../../feature-modules/tenant-occupation/tenant-occupation'

export function OccupationSection() {
  const { profile, isEditDrawerOpen, closeEditProfileSection, refetchProfile } = useProfileContext()
  const { occupations } = profile

  const handleOpenChange = () => {
    closeEditProfileSection()
  }

  return (
    <Dialog isOpen={isEditDrawerOpen} onOpenChange={handleOpenChange} trigger={null}>
      <Dialog.Header hasSidePadding />
      <Dialog.Body>
        <TenantOccupation
          occupations={occupations || []}
          onComplete={refetchProfile}
          onCancel={handleOpenChange}
        />
      </Dialog.Body>
    </Dialog>
  )
}
