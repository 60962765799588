import isNumber from 'lodash/isNumber'
import inRange from 'lodash/inRange'
import type { CurrencyEnum } from '@qasa/graphql'

import type { Region } from '../contexts/region'

import { getCurrentLanguage } from './i18n'

export const convertDecimalsToPercentage = (value: number) => {
  return Math.round(value * 100)
}

export const convertDecimalsToPercentageWithTwoDecimals = (value: number) => {
  return Math.round(value * 10000) / 100
}

type FormatNumber = {
  amount: number
  maximumFractionDigits?: number
  minimumFractionDigits?: number
  currency?: CurrencyEnum
}

export const formatNumber = ({
  amount,
  currency,
  maximumFractionDigits = 0,
  minimumFractionDigits = 0,
}: FormatNumber) => {
  if (currency) {
    return new Intl.NumberFormat(getCurrentLanguage(), {
      style: 'currency',
      currency,
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(amount)
  }
  return new Intl.NumberFormat(getCurrentLanguage(), {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(amount)
}

type FormatSpanParams = {
  locale?: Region
  min?: number | null
  max?: number | null
  fallbackNumber?: number | null
  currency?: CurrencyEnum
}

export const formatSpan = ({ min, max, fallbackNumber, currency }: FormatSpanParams) => {
  const shouldShowSpan = min !== max

  const formattedMin = currency
    ? formatNumber({ amount: min ?? fallbackNumber ?? 0, currency })
    : formatNumber({ amount: min ?? fallbackNumber ?? 0 })
  const formattedMax = currency
    ? formatNumber({ amount: max ?? 0, currency })
    : formatNumber({ amount: max ?? 0 })

  return shouldShowSpan ? `${formattedMin}–${formattedMax}` : formattedMin
}

export const roundToFives = (value: number) => {
  return Math.round(value / 5) * 5
}

export { isNumber, inRange }
