import { graphql } from '@qasa/graphql'

export const IDENTIFY = graphql(`
  mutation Identify($input: IdentifyInput!) {
    identify(input: $input) {
      accessUrl
      success
      errors {
        field
        codes
      }
    }
  }
`)
