import { Label, XIcon } from '@qasa/qds-ui'
import type { UseMultipleSelectionReturnValue } from 'downshift'
import { styled } from '@qasa/ui/web'

import type { OptionToLabel } from './autocomplete'

const SelectedItemRow = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing['1x'],
  rowGap: theme.spacing['1x'],
  paddingTop: 3, // Take border into account
  paddingBottom: 3, // Take border into account
}))
const SelectedItemContainer = styled('div')<{ hasLargePills?: boolean }>(({ theme, hasLargePills }) => ({
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing['1x'],
  backgroundColor: 'rgba(163, 163, 150, 0.17)',
  paddingLeft: hasLargePills ? theme.spacing['6x'] : theme.spacing['4x'],
  paddingRight: hasLargePills ? theme.spacing['3x'] : theme.spacing['2x'],
  borderRadius: theme.radii.full,
  whiteSpace: 'nowrap',
  userSelect: 'none',
  [theme.mediaQueries.mdUp]: {
    height: hasLargePills ? 62 : theme.sizes['12x'],
  },
}))
export const RemoveItemButton = styled('button')(({ theme }) => ({
  width: theme.sizes['6x'],
  height: theme.sizes['6x'],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.radii.full,
  ':hover': {
    backgroundColor: theme.colors.core.gray40,
  },
}))
type AutocompleteSelectedItemsProps<TOption> = {
  selectedOptions: TOption[]
  optionToLabel: OptionToLabel<TOption>
  inputRef: React.RefObject<HTMLDivElement>
  hasLargePills: boolean
} & Pick<UseMultipleSelectionReturnValue<TOption>, 'getSelectedItemProps' | 'removeSelectedItem'>

export function AutocompleteSelectedItems<TOption>({
  selectedOptions,
  optionToLabel,
  getSelectedItemProps,
  removeSelectedItem,
  inputRef,
  hasLargePills,
}: AutocompleteSelectedItemsProps<TOption>) {
  if (!selectedOptions.length) {
    return null
  }

  return (
    <SelectedItemRow>
      {selectedOptions.map((selectedOption, index) => (
        <SelectedItemContainer
          key={`selected-option-${index}`}
          hasLargePills={hasLargePills}
          {...getSelectedItemProps({ selectedItem: selectedOption, index })}
        >
          <Label as="span"> {optionToLabel(selectedOption)}</Label>
          <RemoveItemButton
            type="button"
            onClick={(event) => {
              event.stopPropagation()
              removeSelectedItem(selectedOption)
              setTimeout(() => {
                inputRef.current?.querySelector('input')?.focus()
              }, 1)
            }}
          >
            <XIcon size={16} />
          </RemoveItemButton>
        </SelectedItemContainer>
      ))}
    </SelectedItemRow>
  )
}
