import { useTranslation } from 'react-i18next'
import type { HomeTypeEnum } from '@qasa/graphql'
import { createEnumParam, withDefault } from 'use-query-params'
import { TenantAdSortingEnum } from '@qasa/graphql'

type SlashSeparatedDataProps = {
  type: HomeTypeEnum
  roomCount: number | null
  squareMeters: number | null
}
export const useSlashSeparatedData = ({ type, roomCount, squareMeters }: SlashSeparatedDataProps) => {
  const { t } = useTranslation('commons')

  return [
    t(`home_type.${type}`),
    t('roomWithCount', { count: roomCount ?? 0 }),
    t('square_meters', { number: squareMeters }),
  ].join(' / ')
}

export const SortingParam = withDefault(createEnumParam(Object.values(TenantAdSortingEnum)), null)
