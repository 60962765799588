import { ArrowLeftIcon, Button, Heading, IconButton, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import type { UpdateUserInput } from '@qasa/graphql'
import { HousingSituationAgreementTypeEnum } from '@qasa/graphql'
import { useEffect } from 'react'

import { DialogAndWizardActionsFooter } from '../../../ui-shared/dialog-and-wizard-actions-footer'
import type { HousingSituationStepProps } from '../housing-situation'
import { FormRadioGroup } from '../../../ui-shared/forms/form-radio-group'

import { isReferenceSupportedForHousingType } from './reference-step'

const SORTED_AGREEMENT_TYPES = [
  HousingSituationAgreementTypeEnum.first_hand_renting,
  HousingSituationAgreementTypeEnum.second_hand_renting,
  HousingSituationAgreementTypeEnum.living_with_family,
  HousingSituationAgreementTypeEnum.own_condominium,
  HousingSituationAgreementTypeEnum.own_house,
  HousingSituationAgreementTypeEnum.student_housing,
  HousingSituationAgreementTypeEnum.co_living,
  HousingSituationAgreementTypeEnum.other_agreement_type,
]

export function HousingType({ goBack, requiredValues }: HousingSituationStepProps) {
  const { t } = useTranslation('edit_profile')
  const {
    register,
    setValue,
    watch,
    formState: { isValid },
  } = useFormContext<UpdateUserInput>()
  const housingTypeOption = SORTED_AGREEMENT_TYPES.map((value) => ({
    value,
    label: t(`form.bio.fields.currentAgreementType.${value}`),
  }))

  const agreementType = watch('housingSituation.agreementType')
  const isAgreementTypeRequired = requiredValues?.agreementType

  useEffect(() => {
    if (agreementType && !isReferenceSupportedForHousingType(agreementType)) {
      setValue('housingSituation.landlordName', null)
      setValue('housingSituation.landlordPhoneNumber', null)
      setValue('housingSituation.externalLandlordReference', null)
    }
  }, [agreementType, setValue])

  return (
    <Stack gap="8x">
      <Heading size="lg">{t('form.bio.fields.currentAgreementType.title')}</Heading>
      <FormRadioGroup
        {...register('housingSituation.agreementType', { required: isAgreementTypeRequired })}
        options={housingTypeOption}
      />
      <DialogAndWizardActionsFooter>
        <IconButton
          type="button"
          icon={ArrowLeftIcon}
          variant="tertiary"
          label={t('commons:back')}
          onClick={() => goBack()}
        />
        <Button type="submit" isDisabled={!isValid}>
          {t('commons:next')}
        </Button>
      </DialogAndWizardActionsFooter>
    </Stack>
  )
}
