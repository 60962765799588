import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { RadioGroup, Stack } from '@qasa/qds-ui'
import type { CancellationPolicyNameEnum } from '@qasa/graphql'

import { SectionWrapper } from '../section-wrapper'
import { HOME_CANCELLATION_POLICY_OPTIONS } from '../listing.gql'
import { FieldEnum } from '../edit-listing.types'
import { useListingContext } from '../listing-context'

export function CancellationPolicySection() {
  const { t } = useTranslation('listing')
  const { homeId }: { homeId: string } = useParams()
  const { data } = useQuery(HOME_CANCELLATION_POLICY_OPTIONS, {
    variables: { id: homeId },
  })
  const cancellationPolicyOptions = data?.home?.cancellationOptions

  const {
    dispatch,
    store: {
      values: { cancellationPolicyName },
    },
  } = useListingContext()

  return (
    <SectionWrapper title={t('section_headings.cancellation_policy')}>
      <RadioGroup
        name="cancellation"
        value={cancellationPolicyName ?? ''}
        onValueChange={(value) =>
          dispatch({
            action: {
              type: FieldEnum.CancellationPolicyName,
              payload: value as CancellationPolicyNameEnum,
            },
          })
        }
      >
        <RadioGroup.Label>{t('cancellation_policy.label')}</RadioGroup.Label>
        <Stack gap={'2x'}>
          {cancellationPolicyOptions?.map(({ policyName, fullRefundDays, partialRefundDays }) => (
            <RadioGroup.Card
              key={policyName}
              value={policyName as CancellationPolicyNameEnum}
              label={t(`cancellation_policy.${policyName}`)}
              helperText={t('cancellation_policy.description', { fullRefundDays, partialRefundDays })}
            />
          ))}
        </Stack>
      </RadioGroup>
    </SectionWrapper>
  )
}
