import range from 'lodash/range'
import { useTranslation } from 'react-i18next'
import { Stack, Select, HintBox } from '@qasa/qds-ui'

import { FieldEnum } from '../edit-listing.types'
import { useListingContext } from '../listing-context'
import { SectionWrapper } from '../section-wrapper'

export function NumberOfHomesSection() {
  const { t } = useTranslation('listing')

  const {
    store: {
      values: { numberOfHomes },
    },
    dispatch,
  } = useListingContext()

  return (
    <SectionWrapper title={t(`section_headings.number_of_homes`)}>
      <Stack gap="2x">
        <Select
          value={numberOfHomes || ''}
          onChange={(event) =>
            dispatch({
              action: {
                type: FieldEnum.NumberOfHomes,
                payload: parseInt(event.target.value, 10),
              },
            })
          }
          label={t('number_of_homes.label')}
          placeholder={t('number_of_homes.placeholder')}
        >
          {range(1, 31).map((value) => {
            return (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            )
          })}
        </Select>
        <HintBox>{t('number_of_homes.info_box')}</HintBox>
      </Stack>
    </SectionWrapper>
  )
}
