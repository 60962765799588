import { useAppTranslation } from '../../../contexts/i18next'
import { SortingSelect } from '../../../components/sorting-select'

import { useSorting } from './sorting.shared'

export function Sorting() {
  const { availableSortingOptions, currentValue, handleChange } = useSorting()
  const { t } = useAppTranslation('filter_fields', {
    keyPrefix: 'sorting',
  })
  return (
    <SortingSelect
      options={availableSortingOptions}
      value={currentValue}
      onChange={handleChange}
      ariaLabel={t('title')}
    />
  )
}
