import type { ApplicationStep } from '../../utils/application'

export enum ApplicationEventActions {
  NEW = 'NEW',
  SEND_MESSAGE_CTA = 'SEND_MESSAGE_CTA',
  CONTACT_LANDLORD_CTA = 'CONTACT_LANDLORD_CTA',
  POST_APPLICATION_SIMILAR_HOMES_CLICK = 'POST_APPLICATION_SIMILAR_HOMES_CLICK',
}

export enum ApplicationWithVerificationsEventActions {
  REGISTER_INTEREST = 'REGISTER_INTEREST',
  APPLY_WITH_VERIFIED_INCOME = 'APPLY_WITH_VERIFIED_INCOME',
  APPLY_WITHOUT_VERIFIED_INCOME = 'APPLY_WITHOUT_VERIFIED_INCOME',
  REGISTERED_INTEREST = 'REGISTERED_INTEREST',
}

export enum DepositEventActions {
  PAY_DEPOSIT_CTA = 'PAY_DEPOSIT_CTA',
  DEPOSIT_FREE_CTA = 'DEPOSIT_FREE_CTA',
  DEPOSIT_FREE_OPT_IN = 'DEPOSIT_FREE_OPT_IN',
}

export enum HomeEventActions {
  CREATE = 'CREATE',
  CREATE_VACATION = 'CREATE_VACATION',
}

export enum Category {
  APPLICATION_FLOW = 'APPLICATION_FLOW',
  APPLICATION = 'APPLICATION',
  APPLICATION_WITH_VERIFICATIONS = 'APPLICATION_WITH_VERIFICATIONS',
  DEPOSIT = 'DEPOSIT',
  HOME = 'HOME',
}

export type SendCustomGtagEventParams =
  | { category: Category.APPLICATION_FLOW; action: ApplicationStep; label?: string }
  | { category: Category.APPLICATION; action: ApplicationEventActions; label?: string }
  | {
      category: Category.APPLICATION_WITH_VERIFICATIONS
      action: ApplicationWithVerificationsEventActions
      label?: string
    }
  | { category: Category.DEPOSIT; action: DepositEventActions; label?: string }
  | { category: Category.HOME; action: HomeEventActions; label?: string }

type GtmEvent =
  | 'APPLICATION_NEW'
  | 'ROUTE_CHANGE'
  | 'USER_LOGIN'
  | 'USER_SET'
  | 'HOME_PUBLISH'
  | 'VACATION_HOME_PUBLISH'
  | 'VACATION_HOME_PUBLISH_START'
  | 'HOME_PUBLISH_START'
  | 'HOME_CREATE'
  | 'VACATION_HOME_CREATE'
  | 'HOME_ADD'
  | 'view'
export type GtmCallParams = { event: GtmEvent; [key: string]: LegitimateAny }
