import { Label } from '@qasa/ui'

import { useAppTranslation } from '../../contexts/i18next'
import { TagVariant } from '../../utils/home'

import { HomeTagWrapper, StyledQasaLogo } from './home-tag.styles'

type HomeTagProps = {
  variant: TagVariant
}

export function HomeTag({ variant }: HomeTagProps) {
  const { t } = useAppTranslation('common_home', { keyPrefix: 'tags' })
  const rightSideVisual = variant === TagVariant.Shortcut ? <StyledQasaLogo /> : null

  return (
    <HomeTagWrapper variant={variant} direction={'row'} gap="1x" alignItems="center" justifyContent="center">
      <Label size="sm">{t(variant)}</Label>
      {rightSideVisual}
    </HomeTagWrapper>
  )
}
