import { graphql } from '@qasa/graphql'

export const HOMEQ_LANDLORDS = graphql(`
  query HomeqLandlords {
    homeqLandlordCompanies {
      id
      name
    }
  }
`)
