import { Button, Spacer } from '@qasa/ui'
import type { FieldValues } from 'react-hook-form'
import { FormProvider } from 'react-hook-form'

import { Dialog } from '../../components/dialog'
import { useAppTranslation } from '../../contexts/i18next'

import { EditDialogTrigger } from './edit-dialog-trigger'
import type { EditDialogProps } from './edit-dialog.types'

function EditDialogRoot<T extends FieldValues>({
  title,
  isOpen,
  onOpenChange,
  formMethods,
  formId,
  onSubmit,
  children,
  shouldRenderTrigger = false,
  customFooter,
  isLoading = false,
  ...dialogProps
}: EditDialogProps<T>) {
  const { t } = useAppTranslation('common_buttons')
  const { handleSubmit } = formMethods

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      trigger={shouldRenderTrigger ? <EditDialogTrigger onClick={() => onOpenChange(true)} /> : null}
      {...dialogProps}
    >
      <Dialog.Body>
        <Dialog.Header>
          <Dialog.Title>{title}</Dialog.Title>
        </Dialog.Header>
        <Spacer size="8x" />
        <FormProvider {...formMethods}>
          <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            {typeof children === 'function' ? children(formMethods) : children}
          </form>
        </FormProvider>
        <Spacer size="8x" />
      </Dialog.Body>
      <Dialog.Footer>
        {customFooter ?? (
          <Button
            isLoading={isLoading}
            type="submit"
            form={formId}
            variant="tertiary"
            onPress={() => {
              /* NOTE: currently needed for the ui button but not used when submitting a form */
            }}
          >
            {/* NOTE: using disabled since onColor was changed during rebranding to not break other instances by updating the version */}
            {t('save')}
          </Button>
        )}
      </Dialog.Footer>
    </Dialog>
  )
}

/**
 * A reusable dialog edit form with a built in react-hook-form provider
 */
export const EditDialog = Object.assign(EditDialogRoot, {
  /**
   * The trigger button to open the dialog (in case it needs to be rendered away from the actual dialog)
   */
  Trigger: EditDialogTrigger,
})
