import { Stack, Button, BellIcon, IconButton, useBreakpointValue } from '@qasa/qds-ui'
import { HomeRentalTypeEnum } from '@qasa/graphql'
import type { ComponentPropsWithoutRef } from 'react'
import { forwardRef } from 'react'

import { Dialog } from '../../../../components/dialog'
import { useAppTranslation } from '../../../../contexts/i18next'
import { useFindHomeContext } from '../../contexts'

import { CreateSavedSearchDialogContent } from './create-saved-search-dialog-content'
import { useCreateSavedSearch } from './create-saved-search.shared'

const SAVED_SEARCH_FORM_ID = 'saved-search-form'

const Trigger = forwardRef<HTMLButtonElement, ComponentPropsWithoutRef<'button'>>((props, ref) => {
  const { t } = useAppTranslation('saved_searches')
  const { rentalType } = useFindHomeContext()
  const isMdOrAbove = useBreakpointValue({ base: false, md: true })

  if (rentalType === HomeRentalTypeEnum.vacation) {
    return (
      <Button ref={ref} iconLeft={BellIcon} size={isMdOrAbove ? 'md' : 'sm'} {...props}>
        {t('create.trigger_button')}
      </Button>
    )
  }

  if (isMdOrAbove) {
    return (
      <Button ref={ref} variant="tertiary" iconLeft={BellIcon} size="lg" {...props}>
        {t('create.trigger_button')}
      </Button>
    )
  }

  return (
    <IconButton ref={ref} label={t('create.trigger_button')} variant="tertiary" icon={BellIcon} {...props} />
  )
})

export function CreateSavedSearch() {
  const { t } = useAppTranslation('saved_searches')
  const {
    handleSubmit,
    isDialogOpen,
    isLoadingCreateSavedSearch,
    isMonitoring,
    isSearchFilterValuesEmpty,
    setIsDialogOpen,
    setIsMonitoring,
  } = useCreateSavedSearch()

  if (isSearchFilterValuesEmpty) {
    return null
  }
  return (
    <Dialog isOpen={isDialogOpen} onOpenChange={setIsDialogOpen} trigger={<Trigger />}>
      <Dialog.Body>
        <Dialog.Header>
          <Dialog.Title>{t('create.dialog_title')}</Dialog.Title>
        </Dialog.Header>
        <Stack
          as="form"
          id={SAVED_SEARCH_FORM_ID}
          onSubmit={(event) => {
            event.preventDefault()
            handleSubmit()
          }}
          gap="6x"
        >
          <CreateSavedSearchDialogContent
            isMonitoring={isMonitoring}
            onIsMonitoringChanged={setIsMonitoring}
          />
        </Stack>
      </Dialog.Body>
      <Dialog.Footer>
        <Button form={SAVED_SEARCH_FORM_ID} type="submit" isLoading={isLoadingCreateSavedSearch}>
          {t('create.submit_button')}
        </Button>
      </Dialog.Footer>
    </Dialog>
  )
}
