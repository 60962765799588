import { styled } from '@qasa/ui/web'

import type { CarouselImageProps } from './carousel-image.types'

const StyledImage = styled('img')({
  objectFit: 'cover',
  aspectRatio: '1/1',
  height: '100%',
  width: '100%',
})

export function CarouselImage({ src, loading, ...rest }: CarouselImageProps) {
  return <StyledImage src={src} draggable={false} loading={loading} {...rest} />
}
