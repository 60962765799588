import { Stack } from '@qasa/qds-ui'

import { useWizardRouterContext } from '../wizard-router/wizard-router'
import { HousingSituation } from '../../../feature-modules/housing-situation/housing-situation'
import { useCreateTenantListingContext } from '../create-tenant-listing-context'

export function HousingStep() {
  const { goNext, goBack } = useWizardRouterContext()
  const { formValues } = useCreateTenantListingContext()

  return (
    <Stack gap="8x">
      <HousingSituation
        onComplete={goNext}
        onCancel={goBack}
        defaultValues={formValues.housing}
        initialContext={{ shouldHideSuccess: true, shouldHideReferenceIfNotSupportedForHousingType: true }}
      />
    </Stack>
  )
}
