import { useTranslation } from 'react-i18next'

import { Form } from '../components/form'
import { StepNavigation } from '../components/step-navigation'
import { WizardStepWrapper } from '../components/step-wrapper'
import {
  validateMoveInAfterMoveOut,
  isDayBeforeValidMoveOutDate,
  firstValidMoveOutDate,
} from '../create-tenant-listing.utils'
import { FormDurationInputsField } from '../fields/form-duration-inputs-field'
import { useWizardRouterContext } from '../wizard-router/wizard-router'

export function DatesStep() {
  const { goNext } = useWizardRouterContext()
  const { t } = useTranslation('create_tenant_listing')

  return (
    <WizardStepWrapper>
      <Form dataKey={'dates'} onSubmit={goNext}>
        {({ control, getValues, formState: { errors } }) => (
          <>
            <FormDurationInputsField name={'moveIn'} label="move_in" nonDateLabel="asap" control={control} />
            <FormDurationInputsField
              name={'moveOut'}
              label="move_out"
              nonDateLabel="until_further_notice"
              control={control}
              rules={{
                validate: () =>
                  validateMoveInAfterMoveOut({
                    moveIn: getValues('moveIn'),
                    moveOut: getValues('moveOut'),
                  }),
              }}
              errorMessage={errors['moveOut'] && t('fields.dates.move_in_after_move_out_error')}
              isOutsideRange={isDayBeforeValidMoveOutDate}
              initialVisibleMonth={firstValidMoveOutDate}
            />
            <StepNavigation />
          </>
        )}
      </Form>
    </WizardStepWrapper>
  )
}
