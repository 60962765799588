import styled from '@emotion/styled'
import { ArrowLeftIcon, ArrowRightIcon, IconButton, Stack } from '@qasa/ui'
import { theme } from '@qasa/qds-ui'
import type { MouseEvent } from 'react'

const NavigationControlsWrapper = styled(Stack)({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: theme.spacing['4x'],
  right: theme.spacing['4x'],
  pointerEvents: 'none',
})

const StyledIconButton = styled(IconButton)<{ isShown: boolean }>(({ isShown }) => ({
  opacity: isShown ? 1 : 0,
  pointerEvents: 'auto',
}))

type ScrollValue = 1 | -1
type NavigationControlsProps = {
  onClick: (scrollValue: ScrollValue) => void
  hasPrevious: boolean
  hasNext: boolean
  shouldLoop?: boolean
}
export function CarouselNavigationControls({
  onClick,
  hasPrevious,
  hasNext,
  shouldLoop,
}: NavigationControlsProps) {
  const handleControlClick = (evt: MouseEvent<HTMLButtonElement>, scrollValue: ScrollValue) => {
    evt.preventDefault()
    onClick(scrollValue)
  }
  return (
    <NavigationControlsWrapper direction="row" alignItems="center" justifyContent="space-between">
      <StyledIconButton
        isShown={shouldLoop || hasPrevious}
        size="sm"
        variant="tertiary"
        icon={ArrowLeftIcon}
        label="previous_image"
        onPress={(evt) => handleControlClick(evt, -1)}
      />
      <StyledIconButton
        isShown={shouldLoop || hasNext}
        size="sm"
        variant="tertiary"
        icon={ArrowRightIcon}
        label="next_image"
        onPress={(evt) => handleControlClick(evt, 1)}
      />
    </NavigationControlsWrapper>
  )
}
