import { Spacer, Stack } from '@qasa/ui'

import type { AreaSearchProps } from '../types'
// eslint-disable-next-line import/no-restricted-paths, @typescript-eslint/no-restricted-imports
import { SearchHistory } from '../../../features/find-home/search-history'
import type { SelectedArea } from '../../../types/find-home'
import {
  AutocompleteLoader,
  AutocompleteNoResults,
  AutocompleteOption,
} from '../../../components/autocomplete'

import { SelectedItem } from './area-search-dialog.parts'
import { AreaSearchDialogListWrapper } from './area-search-dialog-list-wrapper'
import { selectedAreaToLabel, selectedAreaToSublabel } from './area-search.utils'

type AreaSearchDialogListProps = AreaSearchProps & {
  inputValue: string
  onInputValueChange: (newValue: string) => void
  areaSuggestions: SelectedArea[]
  isLoading: boolean
  onClose?: () => void
  shouldShowSearchHistory?: boolean
}
export function AreaSearchDialogList({
  value,
  onChange,
  inputValue,
  onInputValueChange,
  areaSuggestions,
  isLoading,
  onClose,
  shouldShowSearchHistory,
}: AreaSearchDialogListProps) {
  const handleRemoveItem = (itemIndex: number) => {
    const clonedArray = [...value]
    clonedArray.splice(itemIndex, 1)
    onChange(clonedArray)
  }

  const isSearching = Boolean(inputValue)
  const hasResults = Boolean(areaSuggestions.length)

  if (isLoading) {
    return <AutocompleteLoader />
  }

  if (isSearching && !hasResults) {
    return <AutocompleteNoResults />
  }

  if (isSearching) {
    return (
      <AreaSearchDialogListWrapper>
        {areaSuggestions.map((option, index) => (
          <AutocompleteOption
            key={`${option.name}-${index}`}
            role="option"
            label={selectedAreaToLabel(option)}
            sublabel={selectedAreaToSublabel(option)}
            onPress={() => {
              onInputValueChange('')
              onChange([...value, option])
              onClose && onClose()
            }}
          />
        ))}
      </AreaSearchDialogListWrapper>
    )
  }

  if (!value.length && shouldShowSearchHistory) {
    return (
      <>
        <Spacer size="6x" />
        <SearchHistory onListItemPress={onClose} />
      </>
    )
  }

  return (
    <>
      <Spacer size="4x" />
      <Stack gap={'3x'} direction="row" wrap="wrap">
        {value.map((item, index) => (
          <SelectedItem
            key={item.name}
            label={item.name}
            labelSize="sm"
            onItemRemove={() => handleRemoveItem(index)}
          />
        ))}
      </Stack>
      <Spacer size="12x" />
      {shouldShowSearchHistory && <SearchHistory onListItemPress={onClose} />}
    </>
  )
}
