import { stringify } from 'query-string'
import { Redirect, useLocation } from 'react-router-dom'

import type { ContactTenantStep } from './contact-tenant-dialog'
import { ContactTenantHomePreview } from './contact-tenant-home-preview'
import { ContactTenantSuccess } from './contact-tenant-success'
import { ContactTenantVerification } from './contact-tenant-verification'

export type CurrentStepProps = {
  contactTenantStep: ContactTenantStep | null
  tenantName: string
  homeId: string
  conversationId?: string
  applicationMessage?: string
  setApplicationMessage?: (message: string) => void
}
export function ContactTenantCurrentStep({
  contactTenantStep,
  homeId,
  conversationId,
  tenantName,
  applicationMessage,
  setApplicationMessage,
}: CurrentStepProps) {
  const { pathname } = useLocation()
  switch (contactTenantStep) {
    default:
    case 'preview': {
      return (
        <ContactTenantHomePreview
          applicationMessage={applicationMessage}
          setApplicationMessage={setApplicationMessage}
        />
      )
    }
    case 'verification': {
      return <ContactTenantVerification />
    }
    case 'success': {
      if (conversationId) {
        return <ContactTenantSuccess conversationId={conversationId} tenantName={tenantName} />
      } else {
        //This should only happen if the user navigates to this page from their history or manually edits the step to be success
        return (
          <Redirect
            to={{
              pathname,
              search: stringify({ homeId }),
            }}
          />
        )
      }
    }
  }
}
