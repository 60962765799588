import styled from '@emotion/styled'
import { pxToRem } from '@qasa/qds-ui'

export const AutocompleteDropdown = styled.ul(({ theme }) => ({
  position: 'relative',
  margin: 0,
  listStyle: 'none',
  padding: 0,
  zIndex: 8,
  flexGrow: 1,
  overflowY: 'scroll',
  backgroundColor: theme.colors.bg.default,
}))

export const AutocompleteDropdownItem = styled.li<{ isActive?: boolean }>(({ theme, isActive = false }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing['4x'],
  paddingRight: theme.spacing['4x'],
  paddingTop: theme.spacing['3x'],
  paddingBottom: theme.spacing['3x'],
  minHeight: pxToRem(48),
  cursor: 'pointer',
  backgroundColor: isActive ? theme.colors.core.gray10 : 'transparent',
}))
