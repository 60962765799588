import type { QueryParamConfigMap, UrlUpdateType } from 'use-query-params'
import { useQueryParams, StringParam, withDefault } from 'use-query-params'

import type { QueryParamsOfRoute, RouteNamesWithQueryParams } from '../../navigation'
import { routes } from '../../navigation'

/**
 * @param {RoutesNamesWithQueryParams} routeName - Only available once the route has query params defined in routes.ts
 * @param {string} withDefaults - Optional initial values for query params
 */
export function useRouteQueryParams<T extends RouteNamesWithQueryParams>(
  routeName: T,
  withDefaults?: Partial<QueryParamsOfRoute<T>>,
) {
  const { queryParams: queryParamsOfRoute } = routes[routeName]

  const config: QueryParamConfigMap = Object.assign(
    {},
    ...queryParamsOfRoute.map((param) => {
      const typedParam = param as keyof QueryParamsOfRoute<T>
      const hasDefault = withDefaults && withDefaults[typedParam]
      if (hasDefault) {
        return { [param]: withDefault(StringParam, String(withDefaults[typedParam])) }
      }
      return { [param]: StringParam }
    }),
  )

  const [queryParams, setQueryParams] = useQueryParams(config)

  const routeQueryParams = queryParams as QueryParamsOfRoute<T>

  /**
   * @param {Partial<QueryParamsOfRoute<T>>} queryParamsOfRoute - Query param key and value
   * @param {UrlUpdateType} updateType Only effects web query params
   */
  function setRouteQueryParams(
    queryParamsOfRoute: Partial<QueryParamsOfRoute<T>>,
    updateType: UrlUpdateType = 'pushIn',
  ) {
    setQueryParams(queryParamsOfRoute, updateType)
  }

  return [routeQueryParams, setRouteQueryParams] as const
}
