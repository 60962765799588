import type { FieldValues } from 'react-hook-form'
import { useController } from 'react-hook-form'

import type { DurationInputProps } from '../../../ui-shared/_core/controls/duration-input'
import { DurationInput } from '../../../ui-shared/_core/controls/duration-input'

import type { InheritedUseControllerProps } from './form-fields.types'

type FormDurationInputsFieldProps = Omit<DurationInputProps, 'name' | 'value' | 'onChange'> & {
  label: string
}

export function FormDurationInputsField<TFormValues extends FieldValues>({
  nonDateLabel,
  dateLabel,
  isOutsideRange,
  errorMessage,
  label,
  initialVisibleMonth,
  ...formProps
}: FormDurationInputsFieldProps & InheritedUseControllerProps<TFormValues>) {
  const { field } = useController(formProps)
  const { onChange, value, name } = field

  return (
    <DurationInput
      {...{
        onChange,
        value,
        nonDateLabel,
        dateLabel,
        isOutsideRange,
        errorMessage,
        name,
        label,
        initialVisibleMonth,
      }}
    />
  )
}
