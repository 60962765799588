import type { DependencyList, EffectCallback } from 'react'
import { useEffect } from 'react'

import { useFirstRender } from './use-first-render'

/**
 * Modified version of useEffect that skips running on the first render
 *
 * @deprecated This is an anti-pattern since all components should be resilient to being remounted.
 * For alternative solutions [see this section in the React docs](https://react.dev/learn/you-might-not-need-an-effect).
 */
export const useEffectOnUpdate = (effect: EffectCallback, deps?: DependencyList) => {
  const { isFirstRender } = useFirstRender()

  useEffect(() => {
    if (!isFirstRender) {
      return effect()
    }

    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
