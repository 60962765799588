import type { PropsWithChildren } from 'react'
import { Fragment } from 'react'

import { VisuallyHidden } from '../../../../components/visually-hidden'

export type FilterCheckboxGroupProps = {
  label: string
  isLabelVisuallyHidden?: boolean
  options: { label: string; value: string }[]
  values: string[]
  onChange: (values: string[]) => void
}

export function VisuallyHiddenOrFragment({
  isLabelVisuallyHidden,
  children,
}: PropsWithChildren<Pick<FilterCheckboxGroupProps, 'isLabelVisuallyHidden'>>) {
  return isLabelVisuallyHidden ? <VisuallyHidden>{children}</VisuallyHidden> : <Fragment>{children}</Fragment>
}

export const parseCheckboxGroupValues = ({
  checkedValue,
  values,
}: Pick<FilterCheckboxGroupProps, 'values'> & { checkedValue: string }) => {
  if (values.includes(checkedValue)) {
    return values.filter((filterValue) => filterValue !== checkedValue)
  }
  return [checkedValue, ...values]
}
