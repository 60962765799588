import { Select } from '@qasa/qds-ui'
import type { FieldValues } from 'react-hook-form'
import { useController } from 'react-hook-form'

import type { InheritedUseControllerProps } from './form-fields.types'

type SelectItem = { value: number | string; label: string; isDisabled?: boolean }
type FormSelectFieldProps = {
  options: SelectItem[]
  label: string
  errorMessage?: string
  placeholder?: string
  isDisabled?: boolean
}

export function FormSelectField<TFormValues extends FieldValues>({
  options,
  label,
  errorMessage,
  placeholder,
  isDisabled = false,
  ...formProps
}: FormSelectFieldProps & InheritedUseControllerProps<TFormValues>) {
  const { field } = useController(formProps)
  const { onChange, ref, value, onBlur } = field

  const parseSelectValue = ({ value }: { value: string }) => {
    let currentValue: number | string = value
    const parsedValue = parseInt(currentValue, 10)
    if (!isNaN(parsedValue)) {
      currentValue = parsedValue
    }
    onChange(currentValue)
  }

  const currentValue = value == null ? '' : value
  return (
    <Select
      ref={ref}
      onBlur={onBlur}
      label={label}
      value={currentValue}
      isDisabled={isDisabled}
      onChange={(e) => parseSelectValue({ value: e.target.value })}
      placeholder={placeholder}
      isInvalid={Boolean(errorMessage)}
      errorMessage={errorMessage}
    >
      {options.map(({ value, label, isDisabled }) => (
        <Select.Option key={value} isDisabled={isDisabled} value={value}>
          {label}
        </Select.Option>
      ))}
    </Select>
  )
}
