import { useTranslation } from 'react-i18next'
import type { HomeViewQuery } from '@qasa/graphql'

import { roundUpHoursToDaysOrWeeks } from '../helpers/date'
import { convertDecimalsToPercentage, roundToFives } from '../utils/number'

type Props = Pick<
  UNSAFE_DeepNonNullableObject<HomeViewQuery>['home']['landlord'],
  'landlordApplicationResponseRate' | 'landlordApplicationResponseTimeHours'
> & { isHidden?: boolean; shouldHideResponseRate?: boolean }

const getLandlordResponseInfoI18n = ({
  landlordApplicationResponseRate: responseRate,
  landlordApplicationResponseTimeHours: responseTimeHours,
  shouldHideResponseRate,
}: Props) => {
  const hasRate = responseRate !== null
  const hasTime = responseTimeHours !== null

  if (!hasRate) {
    return null
  }

  let key = 'response_rate'
  const rate = roundToFives(convertDecimalsToPercentage(responseRate!)) + '%'
  if (!hasTime || responseRate === 0) {
    return { key, rate }
  }

  if (shouldHideResponseRate) {
    const key = 'response_time'
    const { value: time, format } = roundUpHoursToDaysOrWeeks({ hours: responseTimeHours! })
    return { key, time, format }
  }

  const { value: time, format } = roundUpHoursToDaysOrWeeks({ hours: responseTimeHours! })
  key += '_and_time'

  return { key, rate, time, format }
}

export function LandlordResponseInfo({
  landlordApplicationResponseRate,
  landlordApplicationResponseTimeHours,
  isHidden,
  shouldHideResponseRate,
}: Props) {
  const { t } = useTranslation('landlord_response_info')

  if (isHidden) {
    return null
  }

  const landlordReponseI18n = getLandlordResponseInfoI18n({
    landlordApplicationResponseRate,
    landlordApplicationResponseTimeHours,
    shouldHideResponseRate,
  })

  if (!landlordReponseI18n) {
    return null
  }

  return <>{t(landlordReponseI18n.key, landlordReponseI18n)}</>
}
