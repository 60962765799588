import { graphql } from '@qasa/graphql'

graphql(`
  fragment SavedSearchFragment on SavedSearch {
    id
    title
    monitoring
    visibility
    searchParams {
      searchAreas {
        name
        city
        countryCode
      }
      corporateHome
      firsthand
      furniture
      homeType
      minMonthlyCost
      maxMonthlyCost
      minRentalLength
      maxRoomCount
      minRoomCount
      minSquareMeters
      maxSquareMeters
      moveInEarliest
      pets
      smoker
      traits
      checkIn
      checkOut
      minBedCount
      minBedroomCount
      minToiletCount
      householdSize
      minPricePerNight
      maxPricePerNight
      seniorHome
      shared
      studentHome
      wheelchairAccessible
      hasKitchen
    }
  }
`)

export const SAVED_SEARCHES = graphql(`
  query SavedSearches($rentalType: HomeRentalTypeEnum) {
    savedSearch(rentalType: $rentalType) {
      ...SavedSearchFragment
    }
  }
`)

export const CREATE_SAVED_SEARCH = graphql(`
  mutation CreateSavedSearch($input: CreateSavedSearchInput!) {
    createSavedSearch(input: $input) {
      errors {
        codes
        field
      }
      savedSearch {
        ...SavedSearchFragment
      }
    }
  }
`)

export const UPDATE_SAVED_SEARCH = graphql(`
  mutation UpdateSavedSearch($savedSearchId: ID!, $input: UpdateSavedSearchInput!) {
    updateSavedSearch(id: $savedSearchId, input: $input) {
      errors {
        codes
        field
      }
      savedSearch {
        ...SavedSearchFragment
      }
    }
  }
`)

export const DELETE_SAVED_SEARCH = graphql(`
  mutation DeleteSavedSearch($savedSearchId: ID!) {
    destroySavedSearch(id: $savedSearchId) {
      id
    }
  }
`)
