import type { RefObject } from 'react'
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

type UseCarouselArgs = {
  containerRef?: RefObject<HTMLDivElement>
  sliderRef: RefObject<HTMLUListElement>
  observationThreshold?: number
}

type RegisterItemArgs = {
  item: HTMLLIElement
  index: number
}

export function useCarousel({ containerRef, sliderRef, observationThreshold = 0.5 }: UseCarouselArgs) {
  const itemRefs = useRef<HTMLLIElement[]>([])
  const itemCount = itemRefs.current.length

  const registerItem = useCallback(({ item, index }: RegisterItemArgs) => {
    itemRefs.current[index] = item
  }, [])

  const [currentIndex, setCurrentIndex] = useState(0)

  useLayoutEffect(() => {
    if (containerRef?.current) {
      containerRef.current.style.overflow = 'hidden'
    }
  }, [containerRef])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const visibleItem = entries.find((item) => item.isIntersecting)?.target
        const indexOfVisibleItem = itemRefs.current.findIndex((item) => item === visibleItem)
        if (visibleItem && indexOfVisibleItem >= 0) {
          setCurrentIndex(indexOfVisibleItem)
        }
      },
      {
        root: containerRef?.current,
        rootMargin: '0px',
        threshold: observationThreshold,
      },
    )

    for (const item of itemRefs.current) {
      observer.observe(item)
    }
    return () => observer.disconnect()
  }, [itemCount, containerRef, observationThreshold])

  function manualScroll(steps: -1 | 1) {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: itemRefs.current[currentIndex]!.offsetWidth * steps,
        behavior: 'smooth',
      })
    }
  }

  const isFirstStep = currentIndex === 0
  const isLastStep = currentIndex === itemCount - 1

  return {
    registerItem,
    currentIndex,
    itemCount,
    isFirstStep,
    isLastStep,
    manualScroll,
  }
}
