import { isBefore } from 'date-fns'
import { useQuery } from '@apollo/client'
import { CheckIcon } from '@qasa/qds-ui'
import type { TFunction } from 'i18next'
import { BookingStatusEnum } from '@qasa/graphql'

import { formatDateToDayMonth, subtractDays } from '../../helpers/date'
import { convertDecimalsToPercentage } from '../../utils/number'
import { EXISTING_BOOKINGS } from '../home/vacation-home/vacation-pricing-summary/existing-bookings.gql'

type GetCancellationPolicyProps = {
  checkIn: Date
  t: TFunction
  fullRefundDays: number | null
  partialRefundDays: number | null
  partialRefundFactor: number | null
}
export const getCancellationPolicy = ({
  checkIn,
  t,
  fullRefundDays,
  partialRefundDays,
  partialRefundFactor,
}: GetCancellationPolicyProps) => {
  const percentageRefundFactor = convertDecimalsToPercentage(partialRefundFactor!)
  if (!fullRefundDays && !partialRefundDays) {
    return {
      title: t('cancellation_policy.title'),
      description: t('cancellation_policy.no_refund'),
      icon: CheckIcon,
    }
  }

  const shouldGetFullRefund = isBefore(new Date(), new Date(subtractDays(checkIn, fullRefundDays!)))
  const shouldGetPartialRefund = isBefore(new Date(), new Date(subtractDays(checkIn, partialRefundDays!)))

  if (shouldGetFullRefund) {
    return {
      title: t('cancellation_policy.full_refund', {
        lastDateFullRefund: formatDateToDayMonth(subtractDays(new Date(checkIn), fullRefundDays!)),
      }),
      description: t('cancellation_policy.full_refund_addition', {
        lastDateFullRefund: formatDateToDayMonth(subtractDays(new Date(checkIn), fullRefundDays!)),
        lastDatePartialRefund: formatDateToDayMonth(subtractDays(new Date(checkIn), partialRefundDays!)),
        percentageRefundFactor,
      }),
      icon: CheckIcon,
    }
  }
  return shouldGetPartialRefund
    ? {
        title: t('cancellation_policy.half_of_refund_title', {
          lastDatePartialFund: formatDateToDayMonth(subtractDays(new Date(checkIn), partialRefundDays!)),
          percentageRefundFactor,
        }),
        description: t('cancellation_policy.half_of_refund', {
          lastDatePartialFund: formatDateToDayMonth(subtractDays(new Date(checkIn), partialRefundDays!)),
          percentageRefundFactor,
        }),
        icon: CheckIcon,
      }
    : {
        title: t('cancellation_policy.no_refund_title'),
        description: t('cancellation_policy.no_refund'),
        icon: CheckIcon,
      }
}

type GetFormattedDurationProps = {
  checkInDate: string
  checkOutDate: string
}
export const getFormattedDuration = ({ checkInDate, checkOutDate }: GetFormattedDurationProps) =>
  `${formatDateToDayMonth(new Date(checkInDate).toISOString())} - ${formatDateToDayMonth(
    new Date(checkOutDate).toISOString(),
  )}`

type UseGetExistingBookingForHome = {
  homeId: string
  isUserLandlord: boolean
  isVacationHome: boolean
}

export const useGetExistingBookingForHome = ({
  homeId,
  isUserLandlord,
  isVacationHome,
}: UseGetExistingBookingForHome) => {
  const { data: existingBookings, refetch: refetchExistingBookings } = useQuery(EXISTING_BOOKINGS, {
    variables: { homeId, status: [BookingStatusEnum.pending] },
    skip: isUserLandlord || !isVacationHome,
  })
  const existingBooking = existingBookings?.bookings[0]
  return { existingBooking, refetchExistingBookings }
}
