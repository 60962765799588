import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Stack, Button } from '@qasa/qds-ui'

import { useProfileContext } from '../profile-context'
import { ProfileSectionHeader } from '../profile-section-header'
import { ProfileSectionEnum } from '../profile.types'

import { EmptyField } from './empty-field'
import { OccupationCard } from './occupation-card'

const MAX_SHOWING_OCCUPATIONS = 2

const StyledButton = styled(Button)({
  alignSelf: 'flex-start',
})

type ProfileOccupationBlockProps = {
  shouldFlagMissingOccupation?: boolean
}
export function ProfileOccupationBlock({ shouldFlagMissingOccupation = false }: ProfileOccupationBlockProps) {
  const { profile, isOwnProfile, openEditProfileSection } = useProfileContext()
  const [isAllOccupationsVisible, setIsAllOccupationsVisible] = useState(false)
  const { t } = useTranslation('commons')
  const { occupations = [] } = profile

  const hasNoOccupation = occupations?.length === 0
  const hasMoreThanTwoOccupations = occupations ? occupations.length > MAX_SHOWING_OCCUPATIONS : false
  const numberOfDisplayingOccupations = isAllOccupationsVisible
    ? occupations?.length
    : MAX_SHOWING_OCCUPATIONS
  const occupationItems = occupations?.slice(0, numberOfDisplayingOccupations)
  return (
    <Stack gap={'3x'}>
      <ProfileSectionHeader
        title={t('profile:sections_title.OCCUPATION')}
        isOwnProfile={isOwnProfile}
        onEditButtonClick={() => openEditProfileSection({ activeEditSection: ProfileSectionEnum.OCCUPATION })}
      />
      <Stack gap={'6x'}>
        {/* skip showing profile section if no occupation - show general empty state text instead */}
        {hasNoOccupation ? (
          <EmptyField colorVariant={shouldFlagMissingOccupation ? 'negative' : 'subtle'} />
        ) : (
          <>
            {occupationItems?.map((occupation, index) => (
              <OccupationCard key={index} occupation={occupation} />
            ))}
            {hasMoreThanTwoOccupations && (
              <StyledButton
                variant="tertiary"
                onClick={() => setIsAllOccupationsVisible(!isAllOccupationsVisible)}
              >
                {t('show', { context: isAllOccupationsVisible ? 'less' : 'all' })}
              </StyledButton>
            )}
          </>
        )}
      </Stack>
    </Stack>
  )
}
