import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Stack } from '@qasa/qds-ui'

import { LinkButton } from '../../../../../ui-shared/_core/link-button'

type FooterActionsProps = {
  nextSection: string
}
export function FooterActions({ nextSection }: FooterActionsProps) {
  const { t } = useTranslation('listing')
  const location = useLocation()

  return (
    <Stack alignItems="flex-end">
      <LinkButton
        variant="primary"
        to={{
          ...location,
          pathname: nextSection,
        }}
      >
        {t('find_tenant_intro.continue_button')}
      </LinkButton>
    </Stack>
  )
}
