import { styled } from '@qasa/ui/web'
import type { PropsWithChildren, ReactNode } from 'react'

import { Dialog } from '../dialog'
import { useAppTranslation } from '../../contexts/i18next'

const StyledHeader = styled(Dialog.Header)({
  justifyContent: 'center',
})

type SortingDialogProps = {
  trigger: ReactNode
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}
export function SortingDialog({
  trigger,
  isOpen,
  setIsOpen,
  children,
}: PropsWithChildren<SortingDialogProps>) {
  const { t } = useAppTranslation('filter_sections')

  return (
    <Dialog trigger={trigger} isOpen={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
      <Dialog.Body hasPadding={false}>
        <StyledHeader>
          <Dialog.Title>{t('sorting.title')}</Dialog.Title>
        </StyledHeader>
        {children}
      </Dialog.Body>
    </Dialog>
  )
}
