import { Stack } from '@qasa/qds-ui'
import type { StackProps } from '@qasa/ui/src/components/stack/stack.types'
import { styled } from '@qasa/ui/web'
import type { PropsWithChildren } from 'react'

import type { SearchPreferenceListItemWrapperProps } from './search-preference-list-item-wrapper.shared'

function ListItemStack({ children, ...props }: PropsWithChildren<StackProps>) {
  return (
    <Stack as={'li'} {...props}>
      {children}
    </Stack>
  )
}
const StyledSearchPreferenceListItem = styled(ListItemStack)<{ isFullWidth: boolean }>(({ isFullWidth }) => ({
  ...(isFullWidth && { gridColumn: '1 / -1' }),
}))

export function SearchPreferenceListItemWrapper({
  children,
  isFullWidth = false,
  ...props
}: SearchPreferenceListItemWrapperProps) {
  return (
    <StyledSearchPreferenceListItem isFullWidth={isFullWidth} {...props}>
      {children}
    </StyledSearchPreferenceListItem>
  )
}
