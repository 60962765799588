import { useMutation } from '@apollo/client'
import { type SavedSearchesQuery } from '@qasa/graphql'
import { useState } from 'react'
import { BellIcon, BellOffIcon, Paragraph, Stack, TrashIcon, styled } from '@qasa/ui'

import { useAppTranslation } from '../../../../contexts/i18next'
import { useSearchHistoryContext } from '../search-history-context'
import { useToastContext } from '../../../../contexts/toasts'

import { UPDATE_SAVED_SEARCH } from './saved-searches.gql'
import { useFormattedSavedSearch, parseSavedSearch } from './saved-searches.utils'

export type SavedSearchesListItemProps = {
  savedSearch: SavedSearchesQuery['savedSearch'][number]
  onListItemPress?: () => void
}

export const useSavedSearchesListItem = ({ savedSearch }: SavedSearchesListItemProps) => {
  const { id, searchParams, monitoring: isMonitoring } = savedSearch
  const { rentalType } = useSearchHistoryContext()
  const { t } = useAppTranslation('saved_searches')
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const searchSummary = useFormattedSavedSearch({
    savedSearch,
    rentalType,
  })
  const savedSearchFilters = parseSavedSearch({ searchParams, rentalType })

  const { addToast } = useToastContext()
  const handleError = () => addToast({ message: t('common_errors:unexpected_error') })

  const [updateSavedSearch] = useMutation(UPDATE_SAVED_SEARCH, {
    onCompleted: (response) => {
      const errors = response.updateSavedSearch?.errors
      if (errors) {
        handleError()
      } else {
        addToast({ message: t('toggle_monitoring.success_toast_message'), timeout: 2000 })
      }
    },
    onError: handleError,
    optimisticResponse: {
      __typename: 'MutationRoot',
      updateSavedSearch: {
        __typename: 'UpdateSavedSearchPayload',
        savedSearch: {
          ...savedSearch,
          monitoring: !isMonitoring,
        },
        errors: null,
      },
    },
  })
  return {
    updateSavedSearch: async () =>
      updateSavedSearch({
        variables: { savedSearchId: id, input: { monitoring: !isMonitoring } },
      }),
    savedSearchFilters,
    setIsDeleteDialogOpen,
    isDeleteDialogOpen,
    searchSummary,
    isMonitoring,
  }
}

const OptionWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing['4x'],
  alignItems: 'center',
}))

export function MonitoringOption({ isMonitoring }: { isMonitoring: boolean }) {
  const { t } = useAppTranslation('saved_searches')
  const Icon = isMonitoring ? BellIcon : BellOffIcon
  return (
    <OptionWrapper>
      <Icon size={16} />
      <Paragraph>
        {isMonitoring
          ? t('toggle_monitoring.turn_off_monitoring')
          : t('toggle_monitoring.turn_on_monitoring')}
      </Paragraph>
    </OptionWrapper>
  )
}

export function DeleteOption() {
  const { t } = useAppTranslation('saved_searches')
  return (
    <OptionWrapper>
      <TrashIcon size={16} />
      <Paragraph>{t('delete.trigger_button')}</Paragraph>
    </OptionWrapper>
  )
}
