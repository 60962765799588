//@ts-nocheck
import { getEnv } from '../../env'

const SITE_ID = getEnv('HOTJAR_SITE_ID')

let HAS_USER_CONSENTED = false
let IS_INITIALIZED = false
function initializeHotjar() {
  if (SITE_ID && HAS_USER_CONSENTED) {
    ;(function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function (args) {
          ;(h.hj.q = h.hj.q || []).push(...args)
        }
      h._hjSettings = { hjid: SITE_ID, hjsv: 6 }
      a = o.getElementsByTagName('head')[0]
      r = o.createElement('script')
      r.async = 1
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
      a.appendChild(r)
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    IS_INITIALIZED = true
  }
}
export function setHotjarConsent({ hasConsented }: { hasConsented: boolean }) {
  HAS_USER_CONSENTED = hasConsented
  if (!IS_INITIALIZED) {
    initializeHotjar()
  }
  if (IS_INITIALIZED && !hasConsented) {
    window.location.reload()
  }
}
