import { useTranslation } from 'react-i18next'
import { TextField, Select } from '@qasa/qds-ui'

import { useListingContext } from '../../listing-context'
import { SectionWrapper } from '../../section-wrapper'
import { FieldEnum } from '../../edit-listing.types'
import { numberFromString } from '../../listing.utils'

const ROOM_COUNT_VALUES = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 6, 7, 8, 9, 10]

export function SingleHomeSizeSection() {
  const { t } = useTranslation('listing')
  const {
    store: {
      values: { shared: isShared, roomCount, squareMeters },
    },
    dispatch,
    getErrorMessage,
  } = useListingContext()

  const handleSquareMetersChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = numberFromString(ev.target.value)
    dispatch({
      action: {
        type: FieldEnum.SquareMeters,
        payload: value,
      },
    })
  }

  const handleRoomCountChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const value = numberFromString(ev.target.value)
    dispatch({
      action: {
        type: FieldEnum.RoomCount,
        payload: value,
      },
    })
  }

  const squareMetersErrorMessage = getErrorMessage({ field: FieldEnum.SquareMeters }) ?? undefined
  const roomCountErrorMessage = getErrorMessage({ field: FieldEnum.RoomCount }) ?? undefined

  return (
    <SectionWrapper title={t(isShared ? 'section_headings.size_shared' : 'section_headings.size')}>
      <TextField
        label={t('size.square_meters_label')}
        value={squareMeters ?? ''}
        suffix={t('commons:square_meters')}
        onChange={handleSquareMetersChange}
        type="number"
        inputMode="numeric"
        step={1}
        isInvalid={Boolean(squareMetersErrorMessage)}
        errorMessage={squareMetersErrorMessage}
      />
      <Select
        label={t('size.room_count_label')}
        placeholder={t('size.room_count_placeholder')}
        value={roomCount ?? undefined}
        isInvalid={Boolean(roomCountErrorMessage)}
        errorMessage={roomCountErrorMessage}
        onChange={handleRoomCountChange}
      >
        {ROOM_COUNT_VALUES.map((value) => (
          <Select.Option key={value} value={value}>
            {t('commons:roomWithCount', { count: value })}
          </Select.Option>
        ))}
      </Select>
    </SectionWrapper>
  )
}
