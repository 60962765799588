import { BioField } from '../../profile/fields/bio-field'
import { BioPetsField } from '../../profile/fields/bio-pets-field'
import { BioTitleField } from '../../profile/fields/bio-title-field'
import { Form } from '../components/form'
import { StepNavigation } from '../components/step-navigation'
import { WizardStepWrapper } from '../components/step-wrapper'
import { useWizardRouterContext } from '../wizard-router/wizard-router'
import { useCreateTenantListingContext } from '../create-tenant-listing-context'

export function AboutMeStep() {
  const { goNext } = useWizardRouterContext()
  const { isProfessional } = useCreateTenantListingContext()
  return (
    <WizardStepWrapper>
      <Form dataKey="about-me" onSubmit={goNext}>
        <BioTitleField isFieldRequired isProfessional={isProfessional} />
        <BioField isProfessional={isProfessional} />
        <BioPetsField isProfessional={isProfessional} />
        <StepNavigation />
      </Form>
    </WizardStepWrapper>
  )
}
