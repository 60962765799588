import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Paragraph, Heading, Stack, createLucideIcon } from '@qasa/qds-ui'
import { FilePen, CalendarDays } from 'lucide-react'
import { MarketNameEnum, HomeRentalTypeEnum } from '@qasa/graphql'

import { LanguageSwitch } from '../../../translations/language-loader/language-switch'
import { SectionWrapper } from '../section-wrapper'
import { useListingContext } from '../listing-context'
import { FieldEnum } from '../edit-listing.types'
import { BRAND_CONFIG } from '../../../config/brand-configurations'
import { getAvailableVacationMarkets } from '../../../config/markets'
import { MARKET_COUNTRY_CODES } from '../../../config/market-country-codes'

const FilePenIcon = createLucideIcon(FilePen)
const CalendarDaysIcon = createLucideIcon(CalendarDays)

const CardWrapper = styled.div<{ $selected: boolean }>(({ theme, $selected }) => ({
  padding: theme.spacing['6x'],
  ...($selected && {
    outlineStyle: 'solid',
    outlineWidth: 1,
  }),
  border: '1px solid',
  borderColor: $selected ? theme.colors.border.defaultSelected : theme.colors.border.strong,
  borderRadius: theme.radii.md,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing['6x'],
  cursor: 'pointer',
}))
const ImageWrapper = styled.div({
  flexShrink: 0,
})

const activeVacationMarkets = getAvailableVacationMarkets()
const singleActiveVacationMarket = activeVacationMarkets.length === 1 ? activeVacationMarkets[0] : null
const isMultipleMarketBrand = Object.keys(BRAND_CONFIG.availableMarkets).length > 1

export function ChooseRentalType() {
  const { t } = useTranslation('listing')
  const {
    dispatch,
    store: {
      values: { rentalType, isProfessional },
    },
  } = useListingContext()

  return (
    <SectionWrapper
      title={
        isProfessional ? t('choose_rental_type.main_heading_paid') : t('choose_rental_type.main_heading')
      }
      description={t('choose_rental_type.sub_heading')}
    >
      <LanguageSwitch iconSize={16} />
      <Stack gap="3x">
        <button
          onClick={() => {
            dispatch({ action: { type: FieldEnum.RentalType, payload: HomeRentalTypeEnum.long_term } })
          }}
        >
          <CardWrapper $selected={rentalType === HomeRentalTypeEnum.long_term}>
            <ImageWrapper>
              <FilePenIcon size={32} />
            </ImageWrapper>
            <Stack gap="1x">
              <Heading size="sm" as="h3">
                {t('choose_rental_type.longterm_heading')}
              </Heading>
              <Paragraph>
                {t('choose_rental_type.longterm_details', { brandName: BRAND_CONFIG.name })}
              </Paragraph>
            </Stack>
          </CardWrapper>
        </button>
        <button
          onClick={() => {
            dispatch({ action: { type: FieldEnum.RentalType, payload: HomeRentalTypeEnum.vacation } })
            if (singleActiveVacationMarket)
              /* If vacation is only available in one market, change country code accordingly */
              dispatch({
                action: {
                  type: FieldEnum.CountryCode,
                  payload: MARKET_COUNTRY_CODES[activeVacationMarkets[0]],
                },
              })
          }}
        >
          <CardWrapper $selected={rentalType === HomeRentalTypeEnum.vacation}>
            <ImageWrapper>
              <CalendarDaysIcon size={32} />
            </ImageWrapper>
            <Stack gap="1x">
              <div>
                <Heading size="sm" as="h3">
                  {t('choose_rental_type.vacation_heading')}
                </Heading>
                {singleActiveVacationMarket === MarketNameEnum.sweden && isMultipleMarketBrand && (
                  <Paragraph size={'xs'}>
                    {t('choose_rental_type.vacation_currently_only_available_in_sweden')}
                  </Paragraph>
                )}
              </div>
              <Paragraph>
                {t('choose_rental_type.vacation_details', { brandName: BRAND_CONFIG.name })}
              </Paragraph>
            </Stack>
          </CardWrapper>
        </button>
      </Stack>
    </SectionWrapper>
  )
}
