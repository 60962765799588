import { LoadingDots, Paragraph, Stack, styled } from '@qasa/ui'

import { useAppTranslation } from '../../contexts/i18next'

export type AutocompleteOptionProps = {
  isHighlighted?: boolean
  label: string
  sublabel?: string | null
  onPress?: () => void
} & Record<string, unknown>

export type OptionWrapperProps = { isHighlighted: boolean }

const StatusWrapper = styled(Stack)(({ theme }) => ({
  height: theme.sizes['24x'],
  width: '100%',
  flexShrink: 0,
  justifyContent: 'center',
  alignItems: 'center',
}))
export function AutocompleteLoader() {
  return (
    <StatusWrapper>
      <LoadingDots size="sm" />
    </StatusWrapper>
  )
}

export function AutocompleteNoResults() {
  const { t } = useAppTranslation('autocomplete')
  return (
    <StatusWrapper>
      <Paragraph>{t('no_results')}</Paragraph>
    </StatusWrapper>
  )
}
