import { styled } from '@qasa/ui/src/web'

const NAV_HEIGHT = 80

export const PageContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
})
export const SearchSectionWrapper = styled('div')({})
export const MapContent = styled('div')<{ isMapOpen: boolean }>(({ isMapOpen, theme }) => ({
  visibility: isMapOpen ? 'visible' : 'hidden',
  width: '100%',
  height: `calc(100% - ${NAV_HEIGHT}px)`,
  position: 'fixed',
  flex: 1,
  top: NAV_HEIGHT,
  left: 0,
  right: 0,
  bottom: 0,
  [theme.mediaQueries.xlUp]: {
    width: 'auto',
    position: isMapOpen ? 'sticky' : 'absolute',
    height: `calc(100vh - ${NAV_HEIGHT}px)`,
  },
}))
export const LeftColumn = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.mediaQueries.xlUp]: {
    flex: 1,
  },
}))
export const MainContent = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing['12x'],
  paddingLeft: theme.spacing['6x'],
  paddingRight: theme.spacing['6x'],
  overflowAnchor: 'none',
}))
export const ListOfHomesSection = styled('div')({})
