import type { SVGProps } from 'react'

export function NotFoundIllustration(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Curious cat" viewBox="0 0 808.81 449.89" {...props}>
      <path
        fill="#342620"
        d="M78.23 431.72c4.68-2.04 10.67-10.56 16-5.37 26.43 29.88 56.24 32.82 80.92.07 20.49-.09 42.66 6.54 60.54-6.5 2.41-1.75 4.42-3.97 6.31-6.27 21.21-25.73 46.14-24.79 78.06-20.1 96.69 9.81 192.8 12.58 290.06 14.05 16.88 33.09 70.97 28.83 81.07-7.57 22.2 24.24 63.79 24.48 75.08-10.33 71.03-11.62 52.72-105.27-52.2-80.09 5.72-26.35 13.49-73.72 6.02-179.73-2.09-29.66 4.5-60.58-5.62-87.13-10.12-37.76-61.03-61.66-85.83-23.21-11.58 18.82-18.76 40.16-26.24 61.25-6.55 18.46-16.77 35.4-30.09 49.76-11.57 12.46-22.36 25.78-30.44 40.79-34.97-5.15-70.02-12.51-105.4-6.88-21.6 3.37-41.66 17.98-63.29 19.26-20.32 1.2-31.52-21.92-46.96-40.44C292.48 102.79 246.64 71.93 209 35.03c-9.66-9.47-29.26-8.88-36.55 4.71-19.12 35.62 6.87 73.15 26.5 106.11 31.56 53 16.33 86.59 5.59 108.57-7.96 16.3-15.81 32.65-18.99 50.64-17.34-5.02-38.9-1.55-50.87 13.01-31.88-16.67-71.23-3.85-99.07 15.52-58.25 41.23-33.18 141.55 42.62 98.13Zm412.45-130.13c3.22-10.77 13.37-20.34 22.81-25.82.16 26.73 12.92 42.97 22.99 46.76 3.17 1.19 7.16 1.02 9.4-1.21 2.54-2.54 2.78-6.3 1.21-9.4-6.83-13.46-9.17-28.83-7.04-43.68 15.24-.37 30.42 5.5 42.2 16.41 9.41 8.72-8.85 66.68-72.85 45.56-12.99-4.29-23.18-13.7-18.72-28.62ZM317.2 289.85c2.63-11.34 11.62-15.65 21.7-16.53-.28 15.51 5.67 31.12 16.57 42.28 2.4 2.46 6.46 2.94 9.4 1.21 2.71-1.59 4.84-5.64 3.57-8.76a97.426 97.426 0 0 1-7.12-32.36c16.16 4.27 30.11 14.09 39.05 28.24 3.34 5.29 1.97 9.07-1.33 12.84-2.25 2.58-5.1 4.61-8.16 6.14-17.37 8.72-30.24 7.65-43.42 4.78-16.84-3.66-34.67-18.84-30.26-37.85Z"
      />
    </svg>
  )
}
