import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useToastContext } from '@qasa/app'
import type { UpsertOccupationInput } from '@qasa/graphql'

import { getParsedDefaultFormValues, getSerializedFormValues } from './occupation.utils'
import { UPSERT_OCCUPATION } from './occupation.gql'
import type { OccupationType } from './tenant-occupation'

export type OccupationFormValues = Pick<
  UpsertOccupationInput,
  'id' | 'occupationScheduleType' | 'occupationType' | 'subtitle' | 'title'
> & {
  startYear: number | null
  startMonth: number | null
  endYear?: number | null
  endMonth?: number | null
}

type UseOccupationFormParams = {
  onUpsertComplete: () => void
  occupation?: OccupationType | null
}
export function useOccupationForm({ onUpsertComplete, occupation }: UseOccupationFormParams) {
  const { t } = useTranslation('edit_profile')
  const { addToast } = useToastContext()
  const handleError = () => addToast({ message: t('commons:unexpected_error') })

  const methods = useForm<OccupationFormValues>({
    defaultValues: getParsedDefaultFormValues({ occupation: occupation ?? null }),
  })

  const [upsertOccupation, { loading: isLoading }] = useMutation(UPSERT_OCCUPATION, {
    onCompleted: () => {
      onUpsertComplete()
      methods.reset(getParsedDefaultFormValues({ occupation: null }))
    },
    onError: handleError,
  })

  const onSubmit = (formValues: OccupationFormValues) => {
    const upsertInputOccupationData = getSerializedFormValues({ formValues })
    upsertOccupation({ variables: { input: upsertInputOccupationData } })
  }

  return {
    methods,
    onSubmit,
    isUpsertOccupationLoading: isLoading,
  }
}
