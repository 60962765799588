import type { DocumentNode, MutationHookOptions, TypedDocumentNode } from '@apollo/client'
import { useMutation } from '@apollo/client'
import isEqual from 'lodash/isEqual'
import { useRef, useCallback } from 'react'

export const useMemoedMutation = <TData, TVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: Pick<MutationHookOptions<TData, TVariables>, 'variables' | 'update'>,
) => {
  const lastMutatedVariables = useRef(options?.variables)
  const update = options?.update

  const [mutate, result] = useMutation(mutation, {
    update,
  })

  const handleMutate = useCallback(
    async (variables?: TVariables) => {
      const resolvedVariables = variables || options?.variables
      if (isEqual(lastMutatedVariables.current, resolvedVariables)) {
        return undefined
      }

      try {
        const response = await mutate({ variables: resolvedVariables })
        lastMutatedVariables.current = resolvedVariables
        return response
      } catch (error) {
        return undefined
      }
    },
    [mutate, options?.variables],
  )

  return [handleMutate, result] as const
}
