import styled from '@emotion/styled'
import { Avatar, Button, Heading, Paragraph, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import SupportAvatarWithLogo from '../../assets/images/support-avatar-with-logo.png'
import type { ShortcutOptInStepProps } from '../../legacy-pages/shortcut-opt-in'
import { Wizard } from '../../ui-shared/wizard'

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

export function ShortcutSuccess({ goNext }: ShortcutOptInStepProps) {
  const { t } = useTranslation('shortcut_opt_in')

  return (
    <Stack gap="8x">
      <Avatar size={{ base: 'lg', md: 'xl' }} src={SupportAvatarWithLogo.src} />
      <Stack gap="4x">
        <Heading size="lg">{t('success.title')}</Heading>
        <Paragraph size="lg">{t('success.description_read_more')}</Paragraph>
        <Paragraph size="lg">{t('success.next_steps')}</Paragraph>
      </Stack>
      <Wizard.Footer>
        <ButtonWrapper>
          <Button variant="primary" onClick={goNext}>
            {t('success.continue_button')}
          </Button>
        </ButtonWrapper>
      </Wizard.Footer>
    </Stack>
  )
}
