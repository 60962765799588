import { OccupationTypeEnum } from '@qasa/graphql'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Heading, Stack } from '@qasa/qds-ui'

import { FormRadioGroup } from '../../../ui-shared/forms/form-radio-group'
import { OCCUPATION_I18N_KEY } from '../fields/title'
import type { OccupationFormValues } from '../use-occupation-form'
import { type OccupationFormStepsProps } from '../occupation-form'
import { DialogAndWizardActionsFooter } from '../../../ui-shared/dialog-and-wizard-actions-footer'

export function OccupationTypeStep({ goNext, goBack }: OccupationFormStepsProps) {
  const { t } = useTranslation('edit_profile')

  const { control } = useFormContext<OccupationFormValues>()

  const occupationTypeOptions = Object.values(OccupationTypeEnum)
    .reverse()
    .map((value) => {
      return { value, label: t(`${OCCUPATION_I18N_KEY}.occupation_type.options.${value}`) }
    })

  return (
    <Stack gap="8x">
      <Heading size="lg">{t(`${OCCUPATION_I18N_KEY}.titles.occupation_type`)}</Heading>
      <FormRadioGroup control={control} name="occupationType" options={occupationTypeOptions} />
      <DialogAndWizardActionsFooter>
        <Button type="button" variant="tertiary" onClick={goBack}>
          {t('commons:back')}
        </Button>
        <Button type="button" onClick={goNext}>
          {t('commons:next')}
        </Button>
      </DialogAndWizardActionsFooter>
    </Stack>
  )
}
