import { useLazyQuery } from '@apollo/client'
import { useCallback, useEffect, useMemo, useState } from 'react'
import type { SearchAreaKeywordQuery, SearchAreaKeywordQueryVariables } from '@qasa/graphql'
import { CountryCodeEnum, graphql } from '@qasa/graphql'
import debounce from 'lodash/debounce'

import { useRegionContext } from '../../../contexts/region'
import { getEnv } from '../../../env'
import { getCurrentLanguage } from '../../../utils/i18n'

import type { LocationIqResponse } from './use-area-suggestions.types'
import { mergeAreaSuggestions, normalizeResult } from './use-area-suggestions.utils'

const ALLOWED_AREAS = [
  'country',
  'state',
  'region',
  'province',
  'district',
  'county',
  'municipality',
  'city',
  'borough',
  'suburb',
  'quarter',
  'city_block',
  'town',
  'village',
  'allotments',
  'archipelago',
  'island',
]
const PLACES_QUERY_PARAM = ALLOWED_AREAS.map((p) => `place:${p}`).join(',')
const LOCATIONIQ_KEY = getEnv('LOCATIONIQ_KEY')
const LOCATIONIQ_BASE_URL = 'https://api.locationiq.com/v1/autocomplete.php'

const SEARCH_AREA_KEYWORD = graphql(`
  query SearchAreaKeyword($searchString: String!, $countryCodes: [String!]) {
    searchAreaKeyword(searchString: $searchString, countryCodes: $countryCodes) {
      name
      city
      countryCode
    }
  }
`)

export type AreaSuggestionsParams = {
  searchQuery: string
  suggestionsLimit?: number
}

export function useAreaSuggestions({ searchQuery, suggestionsLimit = 10 }: AreaSuggestionsParams) {
  const { region } = useRegionContext()
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false)
  const [locationIqData, setLocationIqData] = useState<LocationIqResponse[]>([])
  const [fetchCustomAreas, { data: customAreaData }] = useLazyQuery<
    SearchAreaKeywordQuery,
    SearchAreaKeywordQueryVariables
  >(SEARCH_AREA_KEYWORD)
  const currentLanguage = getCurrentLanguage()

  const debouncedFetch = useMemo(
    () =>
      debounce((searchQuery: string) => {
        if (!searchQuery) {
          setLocationIqData([])
          setIsLoadingSuggestions(false)
          return
        }

        if (!locationIqData.length) {
          setIsLoadingSuggestions(true)
        }
        const countryCodes =
          region === 'international'
            ? [CountryCodeEnum.SE, CountryCodeEnum.FI, CountryCodeEnum.NO]
            : [region.toUpperCase()]
        const url = `${LOCATIONIQ_BASE_URL}?key=${LOCATIONIQ_KEY}&q=${searchQuery}&limit=${suggestionsLimit}&dedupe=1&countrycodes=${countryCodes.join()}&accept-language=${currentLanguage}&tag=${PLACES_QUERY_PARAM},addr:street:*,boundary:administrative`
        fetchCustomAreas({
          variables: {
            countryCodes,
            searchString: searchQuery,
          },
        })

        fetch(url)
          .then(async (response) => response.json())
          .then((result) => {
            if (result.error) {
              throw Error()
            }
            setLocationIqData(result)
          })
          .catch(() => setLocationIqData([]))
          .finally(() => setIsLoadingSuggestions(false))
      }, 300),
    [fetchCustomAreas, region, locationIqData.length, suggestionsLimit, currentLanguage],
  )

  const fetchSuggestions = useCallback(debouncedFetch, [debouncedFetch])

  useEffect(() => {
    const trimmedSearchQuery = searchQuery.trim()
    fetchSuggestions(trimmedSearchQuery)
  }, [searchQuery, fetchSuggestions])

  const suggestedAreas = useMemo(() => {
    const locationIqSuggestions = normalizeResult({ areas: locationIqData })
    const customSuggestions = customAreaData?.searchAreaKeyword
    return mergeAreaSuggestions({ customSuggestions, locationIqSuggestions })
  }, [customAreaData, locationIqData])

  return { isLoadingSuggestions, suggestedAreas }
}
