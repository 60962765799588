import { useTranslation } from 'react-i18next'
import { useBreakpointValue } from '@qasa/qds-ui'

import { currentBrand } from '../../../config'
import type { BrandType } from '../../../config/index.types'
import { QasaLogo } from '../../../assets/logos/qasa/qasa-logo'
import { QasaXBlocketLogo } from '../../../assets/logos/blocket/qasa-x-blocket'
import { QasaXBlocketMobile } from '../../../assets/logos/blocket/qasa-x-blocket-mobile'
import { QasaOikotieLogo } from '../../../assets/logos/qasa-finland/qasa-oikotie'
import { Link } from '../../../vendor/next'

export type NavigationalLogoProps = { colorVariant?: 'light' | 'default' }
export function NavigationalLogo({ colorVariant = 'default' }: NavigationalLogoProps) {
  const ResponsiveBlocketLogo = useBreakpointValue(
    { base: QasaXBlocketMobile, md: QasaXBlocketLogo },
    { ssr: true },
  )
  const { t } = useTranslation('top_nav_bar')

  const BlocketLogo = <ResponsiveBlocketLogo height={28} style={{ transform: 'translateY(1px)' }} />

  const QasaDefaultLogo = (
    <QasaLogo
      color={colorVariant === 'light' ? 'offWhite' : 'brown'}
      height={36}
      style={{ transform: 'translateY(4px)' }}
    />
  )

  // Using the Qasa X Oikotie logo everywhere for a while to raise brand awareness -- Katri Sep 2023
  const QasaFinlandLogo = (
    <QasaOikotieLogo
      height={36}
      colorVariant={colorVariant}
      style={{ height: 36, transform: 'translateY(4px)' }}
    />
  )

  const NAV_LOGO: Record<BrandType, JSX.Element> = {
    blocket: BlocketLogo,
    qasa: QasaDefaultLogo,
    qasa_finland: QasaFinlandLogo,
    qasa_france: QasaDefaultLogo,
    dotcom: QasaDefaultLogo,
  }

  return (
    <Link aria-label={t('icon_aria_labels.home')} style={{ display: 'flex' }} href="/">
      {NAV_LOGO[currentBrand]}
    </Link>
  )
}
