import type { ParagraphProps } from '@qasa/qds-ui'
import type { Home } from '@qasa/graphql'

import { useAppTranslation } from '../../../contexts/i18next'
import { SlashSeparatedData } from '../../../components/slash-separated-data'

type Props = Pick<Home, 'tenantCount' | 'bedroomCount' | 'bedCount'> & ParagraphProps

export function HomeGuestsBedroomInfo({ tenantCount, bedroomCount, bedCount, ...restProps }: Props) {
  const { t } = useAppTranslation('common_home')

  return (
    <SlashSeparatedData
      gap="1x"
      data={[
        t('vacation_home_info.tenant_count', { count: tenantCount ?? 1 }),
        t('vacation_home_info.bedroom_count', { count: bedroomCount ?? 1 }),
        t('vacation_home_info.bed_count', { count: bedCount ?? 1 }),
      ]}
      {...restProps}
    />
  )
}
