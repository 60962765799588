/* eslint-disable security/detect-unsafe-regex */
import type { Region } from '../contexts/region'

export const finnishPhonePattern =
  /^(0|\+358|00358)?(((753[02][3-9]|7575[3-9]|7598[3-9])\d{3,5})|((73[0-4]\d\d)\d{5})|((202[14-9]|209[0-7])\d{4,6})|((45[45789]\d)\d{2,6})|((10[1-9]|20[13-8]|30[1-9]|73[5-9])\d{5,7})|((71\d)\d{6})|((73[5-9])\d{7})|((43\d|45[0-36])\d{5,7})|(49\d)\d{8}|(([235]9)\d{6,8})|((4[0124678]|50)\d{4,8}))$/

export const swedishPhonePattern = /^(([+]46)|(46)|0|)+\s*(7[02369])\s*(\d{3})\s*(\d{2})\s*(\d{2})$/
// Ok to disable this lint rule since it's only run on the client
export const internationalPhonePatternITU = /^\+(?:[0-9] ?){6,14}[0-9]$/
export const anyNumbersWithOptionalPlus = /^[+\d][\d]*$/
export const noNumbersPattern = /^[^0-9]*$/

export const getPhoneRegexByLocale = (countryCode: Region) => {
  /* TODO: Exchange when we have a better brand config */
  switch (countryCode) {
    case 'fi':
      return finnishPhonePattern
    case 'se':
      return swedishPhonePattern
    default:
      return anyNumbersWithOptionalPlus
  }
}

export const possiblePhoneNumberOrEmailOrUrl = /([a-z]{3,}\.)[a-z]{2,}|[@]|(\d|-| ){9,}/i
export const unallowedUrlPattern = /hemavi\s?\.\s?com/gi

// Matches any string with a top level domain and protocol
const URL_PATTERN_STRING =
  '((https|http)\\:\\/\\/)?(?:www\\.)?[\\d\\w]{2,}\\.(com|org|net|edu|gov|mil|int|biz|info|name|pro|coop|museum|aero|jobs|travel|mobi|tel|asia|cat|xxx|tv|io|ly|se|fi|co|uk|de|fr|es|it|nl|eu|co\\.uk)\\/?((\\??[\\dw]+\\/?){1,10}|)?'
// Matches any string with an "@"
const EMAIL_PATTERN_STRING = '[@]'
// Matches any string with 9 or more digits or dashes
const PHONE_NUMBER_PATTERN_STRING = '(\\d){9,}'

const WHITE_LISTED_URL_PATTERN_STRING =
  /(?:[a-zA-Z0-9-]+\.)?(qasa\.(?:se|fi|fr|com)|bostad\.blocket\.se)(?:\/[^\s?#]*)?(?:\?[^\s#]*)?(?:#[^\s]*)?/

export function getContainsProbableUrl(str: string) {
  const stringsWithoutWhitelistedUrls = str
    .replace(new RegExp(WHITE_LISTED_URL_PATTERN_STRING, 'gi'), '')
    .split(' ')

  return stringsWithoutWhitelistedUrls.some(
    (str) => new RegExp(URL_PATTERN_STRING, 'gi').test(str) && !getContainsProbableEmail(str),
  )
}

export function getContainsProbableEmail(str: string) {
  return new RegExp(EMAIL_PATTERN_STRING, 'gi').test(str)
}

export function getContainsProbablePhoneNumber(str: string) {
  return new RegExp(PHONE_NUMBER_PATTERN_STRING, 'gi').test(str)
}
