import { styled, Stack } from '@qasa/ui'

import { BigDataCardParts } from './big-data-card.parts'

const BaseWrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  minHeight: theme.sizes['160'],
  gap: theme.spacing['4x'],
}))

export const BigDataCardBase = Object.assign(BaseWrapper, BigDataCardParts)
