import styled from '@emotion/styled'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Heading } from '@qasa/qds-ui'

import { EMBEDDED_FIND_HOME_PATH } from '../../legacy-pages/embedded-find-home'

import { useProfileContext } from './profile-context'
import { PRO_PILOT_CONNECTED_UIDS } from './pro-pilot-connected-uids.gql'

const Wrapper = styled.div({
  height: 800,
})
const PublishedListingsFrame = styled.iframe({
  border: 'none',
})
export function ProfileProPilotPublishedListings() {
  const { t } = useTranslation('profile_published_listings_block')
  const { profile } = useProfileContext()
  const { data, error } = useQuery(PRO_PILOT_CONNECTED_UIDS, {
    variables: {
      uid: profile.uid,
    },
  })

  if (error || !data) return null

  const uids = data?.proPilotConnectedUids.join(',')
  const url = `${EMBEDDED_FIND_HOME_PATH}?uids=${uids}`

  return (
    <Wrapper>
      <Heading size="sm">{t('title')}</Heading>
      <PublishedListingsFrame title="bbq" width="100%" height="100%" src={url} />
    </Wrapper>
  )
}
