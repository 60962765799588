import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import capitalize from 'lodash/capitalize'
import { Paragraph, Heading, Stack, ArrowRightIcon, IconButton, PenIcon, TrashIcon } from '@qasa/qds-ui'
import type { ProfileQueryQuery } from '@qasa/graphql'

import { getLocale } from '../../../helpers/date'

const CardWrapper = styled.div({ position: 'relative' })
const OngoingText = styled(Paragraph)({
  display: 'inline-flex',
})
const DateText = Paragraph

const formatDateToYearMonth = (date: string | null) => {
  if (!date) return ''
  const formattedDateString = format(new Date(date), 'MMMM yyyy', { locale: getLocale() })
  return capitalize(formattedDateString)
}

type OccupationCardProps = {
  occupation: Pick<
    NonNullable<NonNullable<ProfileQueryQuery['user']>['occupations']>[number],
    'current' | 'endedAt' | 'occupationScheduleType' | 'occupationType' | 'startedAt' | 'subtitle' | 'title'
  >
  handleDeleteOccupation?: () => void
  handleEditOccupation?: () => void
}
export function OccupationCard({
  occupation,
  handleDeleteOccupation,
  handleEditOccupation,
}: OccupationCardProps) {
  const { t } = useTranslation('profile_occupation_block')
  const {
    current: isCurrent,
    endedAt,
    occupationScheduleType,
    startedAt,
    subtitle,
    title,
    occupationType,
  } = occupation

  const formattedStartedAt = formatDateToYearMonth(startedAt)
  const formattedEndedAt = formatDateToYearMonth(endedAt)

  const occupationCardTile = title || t(`fallback_title.${occupationType}`)

  return (
    <CardWrapper>
      <Stack gap="1x" direction="row" justifyContent="space-between">
        <Stack gap="1x">
          <Heading as="h3" size="2xs">
            {occupationCardTile}
          </Heading>
          <div>
            <Paragraph>{subtitle}</Paragraph>
            {occupationScheduleType && <Paragraph>{t(occupationScheduleType)}</Paragraph>}
          </div>
          <Stack direction={'row'} gap={'1x'} alignItems="center">
            <DateText>{formattedStartedAt}</DateText>
            <ArrowRightIcon size={16} strokeWidth={1.5} />
            {formattedEndedAt && <DateText>{formattedEndedAt}</DateText>}
            {isCurrent && <OngoingText>{t('ongoing')}</OngoingText>}
          </Stack>
        </Stack>
        <Stack direction="row" gap="1x">
          {handleDeleteOccupation && (
            <IconButton
              variant="tertiary"
              size="xs"
              label="delete-button"
              icon={TrashIcon}
              onClick={handleDeleteOccupation}
            />
          )}
          {handleEditOccupation && (
            <IconButton
              variant="tertiary"
              size="xs"
              label="edit-button"
              icon={PenIcon}
              onClick={handleEditOccupation}
            />
          )}
        </Stack>
      </Stack>
    </CardWrapper>
  )
}
