import { useTranslation } from 'react-i18next'

import { FormCheckboxGroup } from '../../create-tenant-listing/fields/form-checkbox-group'
import { ProfileSearchPreferenceForm } from '../forms/profile-search-preference-form'
import { ProfilePreferredAmenitiesEnum, ProfileSectionEnum } from '../profile.types'

export function PreferredAmenitiesSection() {
  const { t } = useTranslation('create_tenant_listing')

  const amenitiesOptions = Object.values(ProfilePreferredAmenitiesEnum).map((value) => ({
    value,
    label: t(`fields.preferences.checkbox_options.${value}`),
  }))

  return (
    <ProfileSearchPreferenceForm dataKey="preferredAmenities">
      {({ control }) => (
        <FormCheckboxGroup
          control={control}
          label={t(`profile:sections_title.${ProfileSectionEnum.PREFERRED_AMENITIES}`)}
          isLabelVisuallyHidden
          name="preferredAmenities"
          options={amenitiesOptions}
        />
      )}
    </ProfileSearchPreferenceForm>
  )
}
