import { useToastContext } from '@qasa/app'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { createEnumParam, useQueryParam, withDefault } from 'use-query-params'
import { useEffect } from 'react'
import type { UpdateUserInput } from '@qasa/graphql'

import { useEditProfile } from '../../ui-page-modules/profile/hooks/use-edit-profile'
import type { WizardStep } from '../../hooks/use-wizard'
import { useWizard } from '../../hooks/use-wizard'
import { useAuthContext } from '../../context/auth-context'

import { HousingType } from './form-steps/housing-type'
import { MovingReason } from './form-steps/moving-reason'
import { PreviousHousing } from './form-steps/previous-housing'
import { HousingSuccess } from './form-steps/housing-success'
import { HousingLocation } from './form-steps/housing-location'
import { isReferenceSupportedForHousingType, ReferenceStep } from './form-steps/reference-step'

export const HOUSING_SITUATION_STEP_QUERY_PARAM = 'housingSituationStep'

export enum HousingSituationSteps {
  HousingType = 'housingType',
  HousingLocation = 'housingLocation',
  MovingReason = 'movingReason',
  PreviousHousing = 'previousHousing',
  Reference = 'reference',
  HousingSuccess = 'housingSuccess',
}

type HousingSituationContext = {
  shouldHideHousingType?: boolean
  shouldHideHousingLocation?: boolean
  shouldHidePreviousHousing?: boolean
  shouldHideMovingReason?: boolean
  shouldHideSuccess?: boolean
  shouldHideReferenceIfNotSupportedForHousingType?: boolean
  isReferenceSupportedForHousingType?: boolean
}
export type HousingSituationStepProps = {
  goNext: (params?: { context?: Partial<HousingSituationContext> }) => void
  goBack: (params?: { context?: Partial<HousingSituationContext> }) => void
  requiredValues: {
    agreementType: boolean
    landlordCity: boolean
    landlordName: boolean
    landlordPhoneNumber: boolean
  }
}
type Step = WizardStep<HousingSituationContext>

type HousingSituationProps = {
  defaultValues?: UpdateUserInput
  onComplete: () => void
  onCancel: () => void
  initialContext?: HousingSituationContext
  shouldMainValuesBeRequired?: boolean
}
export function HousingSituation({
  defaultValues,
  onComplete,
  onCancel,
  initialContext = {},
  shouldMainValuesBeRequired = false,
}: HousingSituationProps) {
  const { authBody } = useAuthContext()
  const { t } = useTranslation('commons')
  const { addToast } = useToastContext()
  const [, setHousingStep] = useQueryParam(
    HOUSING_SITUATION_STEP_QUERY_PARAM,
    withDefault(createEnumParam(Object.values(HousingSituationSteps)), null),
  )

  useEffect(() => {
    setHousingStep(HousingSituationSteps.HousingType, 'replaceIn')
    return () => {
      // NOTE: the cleanup of the query param is necessary since the component could be used within a dialog
      // that might be closed from the parent component
      setHousingStep(null, 'replaceIn')
    }
  }, [setHousingStep])

  const housingSituationSteps: Step[] = [
    {
      id: HousingSituationSteps.HousingType,
      Component: HousingType,
      guard: (context) => !context?.shouldHideHousingType,
    },
    {
      id: HousingSituationSteps.HousingLocation,
      Component: HousingLocation,
      guard: (context) => !context?.shouldHideHousingLocation,
    },
    {
      id: HousingSituationSteps.MovingReason,
      Component: MovingReason,
      guard: (context) => !context?.shouldHideMovingReason,
    },
    {
      id: HousingSituationSteps.PreviousHousing,
      Component: PreviousHousing,
      guard: (context) => !context?.shouldHidePreviousHousing,
    },
    {
      id: HousingSituationSteps.Reference,
      Component: ReferenceStep,
      guard: ({ shouldHideReferenceIfNotSupportedForHousingType, isReferenceSupportedForHousingType }) =>
        Boolean(!shouldHideReferenceIfNotSupportedForHousingType || isReferenceSupportedForHousingType),
    },
    {
      id: HousingSituationSteps.HousingSuccess,
      Component: HousingSuccess,
      guard: (context) => !context?.shouldHideSuccess,
    },
  ]
  const { currentStep, goNext, goBack } = useWizard<HousingSituationContext, Step[]>(housingSituationSteps, {
    onCompleted: () => {
      onComplete()
      setHousingStep(null, 'replaceIn')
    },
    onCancelled: onCancel,
    queryParamName: HOUSING_SITUATION_STEP_QUERY_PARAM,
    queryParamUpdateType: 'replaceIn',
    initialContext,
  })
  const { formMethods, onSubmit } = useEditProfile({
    onCompleted: (data) => {
      const agreementType = data?.updateUser?.user?.housingSituation?.agreementType
      goNext({
        context: {
          isReferenceSupportedForHousingType: Boolean(
            agreementType && isReferenceSupportedForHousingType(agreementType),
          ),
        },
      })
    },
    onError: () => addToast({ message: t('commons:unexpected_error') }),
    userUid: authBody?.uid || '',
    defaultValues,
  })
  const { handleSubmit } = formMethods

  if (!currentStep) {
    return null
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <currentStep.Component
          goNext={goNext}
          goBack={goBack}
          requiredValues={{
            agreementType: shouldMainValuesBeRequired,
            landlordCity: shouldMainValuesBeRequired,
            landlordName: shouldMainValuesBeRequired,
            landlordPhoneNumber: shouldMainValuesBeRequired,
          }}
        />
      </form>
    </FormProvider>
  )
}
