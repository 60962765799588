import { Fragment, useState } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { RadioGroup, Stack } from '@qasa/qds-ui'
import type { StackProps } from '@qasa/qds-ui'

import { ErrorMessage } from '../error-message'
import { formatDateToYearMonthDay } from '../../../helpers/date'
import { VisuallyHidden } from '../visually-hidden'

const Wrapper = 'div'
type RadioValue = 'indeterminate' | 'date'

const isRadioValue = (value: string): value is RadioValue => ['indeterminate', 'date'].includes(value)
// Improves intellisense by preventing typescript from narrowing
// to `string` when using union between string and with literal string type
type LiteralStringUnion<LiteralType> = LiteralType | (string & Record<never, never>)
export type DurationInputProps = {
  /**
   * Name forwared to the radio group
   */
  name: string
  /**
   * The current value of the date picker.
   */
  value: QasaDateTime | null
  /**
   * Callback invoked when the value of the date picker changes
   */
  onChange: (newValue: QasaDateTime | null) => void
  /**
   * Label for the radio group. Supports common localized defaults.
   */
  label: LiteralStringUnion<'move_in' | 'move_out'>
  /**
   * Visually hides the label, but keeps it readable for screen readers
   */
  isLabelVisuallyHidden?: boolean
  /**
   * Label for non date radio card. Supports common localized defaults.
   */
  nonDateLabel: LiteralStringUnion<'asap' | 'until_further_notice'>
  /**
   * Helper text for non date radio card. Is optional.
   */
  nonDateHelperText?: string
  /**
   * Label for radio card when picking a specific date. Supports common localized defaults.
   */
  dateLabel?: LiteralStringUnion<'choose_date'>
  /**
   * Error message for picker
   */
  errorMessage?: string | null
  /**
   * Function to determine which days are out of selectable range
   */
  isOutsideRange?: (day: moment.Moment) => boolean
  /**
   * Determine which month should be initially visible when opening the date picker
   */
  initialVisibleMonth?: Date
  /**
   * Determine in which flexbox direction the options should be stacked
   */
  optionsDirection?: StackProps['direction']
}

export function DurationInput({
  label,
  isLabelVisuallyHidden,
  name,
  nonDateLabel,
  nonDateHelperText,
  dateLabel = 'choose_date',
  value,
  onChange,
  isOutsideRange,
  initialVisibleMonth,
  errorMessage,
  optionsDirection = 'column',
}: DurationInputProps) {
  const [radioValue, setRadioValue] = useState<RadioValue>(value ? 'date' : 'indeterminate')
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const { t } = useTranslation('commons')
  const handleRadioChange = (nextValue: string) => {
    if (!isRadioValue(nextValue)) {
      return
    }
    if (nextValue === 'indeterminate') {
      onChange(null)
    }
    setRadioValue(nextValue)
  }
  const handleDateChange = (date: moment.Moment | null) => {
    if (!date) {
      setRadioValue('indeterminate')
    }
    onChange(date?.toISOString() || null)
  }
  const handleOnCloseDatePicker = ({ date }: { date: moment.Moment | null }) => {
    if (!date) {
      handleRadioChange('indeterminate')
    }
  }
  const handleIsOutsideRange = (day: moment.Moment | null) => {
    if (isOutsideRange && day) {
      return isOutsideRange(day)
    }
    return false
  }
  const radioGroupLabel = ['move_in', 'move_out'].includes(label) ? t(label) : label
  const resolvedNonDateLabel = ['asap', 'until_further_notice'].includes(nonDateLabel)
    ? t(nonDateLabel)
    : nonDateLabel
  const resolvedDateLabel = dateLabel === 'choose_date' ? t(dateLabel) : dateLabel
  const formattedValue = value ? formatDateToYearMonthDay(value) : null

  const LabelWrapper = isLabelVisuallyHidden ? VisuallyHidden : Fragment
  return (
    <RadioGroup name={name} value={radioValue} onValueChange={handleRadioChange}>
      <LabelWrapper>
        <RadioGroup.Label>{radioGroupLabel}</RadioGroup.Label>
      </LabelWrapper>
      <Stack direction={optionsDirection} gap="2x">
        <RadioGroup.Card label={resolvedNonDateLabel} value="indeterminate" helperText={nonDateHelperText} />
        <RadioGroup.Card
          label={formattedValue || resolvedDateLabel}
          value="date"
          onClick={() => setIsDatePickerOpen(true)}
        />

        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Stack>
      {radioValue === 'date' && (
        <Wrapper className="SingleDatePicker_Wrapper">
          <SingleDatePicker
            initialVisibleMonth={() => moment(initialVisibleMonth)}
            id={name}
            date={value ? moment(value) : null}
            onDateChange={handleDateChange}
            focused={isDatePickerOpen}
            onFocusChange={({ focused }) => setIsDatePickerOpen(Boolean(focused))}
            onClose={handleOnCloseDatePicker}
            isOutsideRange={isOutsideRange ? handleIsOutsideRange : undefined}
            numberOfMonths={1}
            block
            verticalSpacing={46}
            daySize={40}
            hideKeyboardShortcutsPanel={true}
          />
        </Wrapper>
      )}
    </RadioGroup>
  )
}
