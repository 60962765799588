import type { Consumer } from '@rails/actioncable'

import { reportError } from '../../../vendor/sentry'

let interval: ReturnType<typeof setTimeout> | null = null

// This function is called every 30 seconds to check if the ActionCable connection
// is still needed. If there are no active subscriptions, the connection is closed.
const cableConnectionManager = (consumer: Consumer) => {
  try {
    const isDisconnected = consumer.connection.disconnected

    if (isDisconnected) {
      return
    }

    const hasActiveSubscriptions = consumer.subscriptions.subscriptions.length > 0
    if (!hasActiveSubscriptions) {
      consumer.disconnect()
      return
    }
  } catch (error) {
    reportError('ActionCable: Error while performing connection maintenance', { error })
  } finally {
    runCableConnectionManager(consumer)
  }
}

const THIRTY_SECONDS = 30 * 1000
export const runCableConnectionManager = (consumer: Consumer) => {
  if (interval) {
    clearInterval(interval)
    interval = null
  }
  interval = setTimeout(() => cableConnectionManager(consumer), THIRTY_SECONDS)
}
