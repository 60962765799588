import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormInputField } from '../../../ui-shared/forms/form-input-field'
import type { OccupationFormValues } from '../use-occupation-form'
import { containsPossiblePhoneNumberOrEmailOrUrl } from '../../../helpers/validation'

export const OCCUPATION_I18N_KEY = 'form.occupation'
export const ERROR_OCCUPATION_I18N_KEY = 'errors.occupation'

export function Title() {
  const { t } = useTranslation('edit_profile')

  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<OccupationFormValues>()

  const occupationValue = getValues('occupationType')

  return (
    <FormInputField
      control={control}
      name={'title'}
      label={t(`${OCCUPATION_I18N_KEY}.title.label`, { context: occupationValue })}
      placeholder={t(`${OCCUPATION_I18N_KEY}.title.placeholder`, { context: occupationValue })}
      rules={{
        required: t(`${ERROR_OCCUPATION_I18N_KEY}.required.title`, { context: occupationValue }),
        maxLength: { value: 50, message: t(`${ERROR_OCCUPATION_I18N_KEY}.max_length_fifty`) },
        validate: (value) => {
          if (value) {
            return (
              !containsPossiblePhoneNumberOrEmailOrUrl(String(value)) ||
              t('errors.occupation.forbiden_content_detected')
            )
          }

          return true
        },
      }}
      errorMessage={errors['title'] && errors['title'].message}
    />
  )
}
