import { Paragraph, Stack } from '@qasa/ui'

import { useAppTranslation } from '../../../contexts/i18next'

type Props = {
  totalHomes: number
  page: number
  pageSize: number
}
export function HomeListPageSize({ totalHomes, page, pageSize }: Props) {
  const { t } = useAppTranslation('home_list_page_size')
  const firstOnCurrentPageMinimum = (page - 1) * pageSize + 1
  const lastOnCurrentPage = page * pageSize
  const lastOnCurrentPageMax = lastOnCurrentPage > totalHomes ? totalHomes : lastOnCurrentPage

  if (totalHomes === 0) return null

  return (
    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
      <Paragraph asWebElement={'span'} size={'sm'}>
        {t('showing_results', {
          from: firstOnCurrentPageMinimum,
          to: lastOnCurrentPageMax,
          total: totalHomes,
        })}
      </Paragraph>
    </Stack>
  )
}
