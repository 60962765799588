import type { ReactElement, ReactNode } from 'react'
import { isValidElement } from 'react'

export type CarouselProps = {
  children: ReactNode
  shouldLoop?: boolean
  initialIndex?: number
  hasBorderRadius?: boolean
}

export const getArrayWithLoopCopies = (array: ReactElement[]) => {
  const firstItem = {
    ...array[0],
    key: 'firstItem',
  }
  const lastItem = {
    ...array[array.length - 1],
    key: 'lastItem',
  }
  /* Simple check since getting array items by index may return undefined */
  if (!isValidElement(firstItem) || !isValidElement(lastItem)) {
    return []
  }
  return [lastItem, ...array, firstItem]
}

export const getAdjustedIndicies = (currentIndex: number, initialIndex: number, shouldLoop: boolean) => {
  if (shouldLoop) {
    return { adjustedCurrentIndex: currentIndex - 1, initialScrollIndex: initialIndex + 1 }
  }
  return { adjustedCurrentIndex: currentIndex, initialScrollIndex: initialIndex }
}
