import { Experiment, ExperimentClient, ExperimentConfig, Variant } from '@amplitude/experiment-js-client'
import { ampli } from '../__generated__'

export const ampliExperiment = {
  client: null as ExperimentClient | null,
  /**
   * A boolean indicating whether the experiment client is initialized.
   */
  isInitialized: false,
  /**
   * The error object if there is an error while loading the experiment.
   */
  error: null as Error | null,
  isLoading: false,

  /**
   * Loads the experiment client with the given options.
   * @async
   * @param {string} [deploymentKey] - The deployment key for the experiment.
   * A deployment is a collection of experiments and flags.
   * It will be unique per Amplitude project, so Qasa SE will have one and Qasa FI will have another..
   * @param {ExperimentConfig} [options] - The options for the experiment.
   */
  async load(deploymentKey: string, options?: ExperimentConfig) {
    if (!deploymentKey) {
      this.error = new Error('Could not start Amplitude Experiment, no deployment key')
      return
    }

    if (!ampli.isLoaded) {
      this.error = new Error('Could not start Amplitude Experiment, Ampli is not loaded')
      return
    }

    if (this.isInitialized) {
      this.error = new Error('Could not start Amplitude Experiment, already initialized')
      return
    }

    this.isLoading = true

    this.client = Experiment.initializeWithAmplitudeAnalytics(deploymentKey, {
      debug: process.env.NODE_ENV === 'development',
      serverZone: 'eu',
      fetchTimeoutMillis: 0,
      ...options,
    })

    try {
      await this.client.start()
      this.isInitialized = true
    } catch (error) {
      if (error instanceof Error) {
        this.error = error
      } else {
        this.error = new Error('Could not start Amplitude Experiment')
      }
    } finally {
      this.isLoading = false
    }
  },

  /**
   * Gets the variant for the given flag name.
   * When a user is allocated a variant and exposure is set to automatic, this will count as an exposure.
   * @param {string} flagName The name of the flag, e.g. 'my-flag'.
   * @returns {Variant | undefined} The variant for the given flag name or undefined if the user is not allocated a variant or the experiment client is not created or initialized.
   */
  getVariant(flagName: string): Variant | undefined {
    if (!this.client || !this.isInitialized) {
      return undefined
    }

    return this.client.variant(flagName, undefined)
  },
}
