import { graphql } from '@qasa/graphql'

graphql(`
  fragment contractFragment on Contract {
    qasaGuaranteeCost
    insuranceCost
    qasaGuarantee
    insurance
    rentIncludes
    rentExcludes
    furniture
    inventory
    canUse
    landlordHasKeys
    subletception
    preCleaning
    postCleaning
    renovations
    temporaryInstallations
    smallRepairs
    insuranceResponsibility
    overnightStay
    pets
    smoker
    numberOfTenants
    acceptsCosignatures
    apartmentNumber
    marketDetails {
      ... on FinlandMarketDetails {
        id
        minimumStayMonths
        rentIndexation {
          fixedPercentage
          indexationOption
        }
      }
    }
    duration {
      endOptimal
      endUfn
      startAsap
      startOptimal
      updatedAt
      id
    }
    tenantFees {
      waterFeePerTenant {
        availability
        cost {
          fixed
          isFree
        }
      }
      monthlyElectricityFee {
        availability
        cost {
          fixed
          isFree
        }
      }
    }
    electricityFee {
      paymentPlan
      monthlyFee
    }
    home {
      ...BaseHome
    }
    id
    rent
    status
    paymentDay
    tenant {
      uid
      firstName
    }
    contractDetails {
      tenantFirstName
      tenantFamilyName
      tenantEmail
      tenantPhoneNumber
      legalType
      knownDamages
      keySpecification
      codeOfConduct
      additionalInformation
      cadastralReference
      sectionNumber
      municipality
      titleNumber
      bathroomShared
      kitchenShared
    }
    extraCosts {
      costFor
      plan
    }
  }
`)

export const UPSERT_CONTRACT = graphql(`
  mutation UpsertContractMutation($input: UpsertContractInput!, $id: ID) {
    upsertContract(input: $input, id: $id) {
      contract {
        ...contractFragment
      }
      errors {
        codes
        field
      }
    }
  }
`)

export const UPSERT_FINNISH_CONTRACT = graphql(`
  mutation UpsertFinnishContract($id: ID, $input: FinlandUpsertContractInput!) {
    finlandUpsertContract(id: $id, input: $input) {
      contract {
        ...contractFragment
      }
      errors {
        codes
        field
      }
    }
  }
`)
