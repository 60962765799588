import { Stack } from '@qasa/qds-ui'
import type { PropsWithChildren } from 'react'

export function AreaSearchDialogListWrapper({ children }: PropsWithChildren) {
  return (
    <Stack as="ul" role="listbox">
      {children}
    </Stack>
  )
}
