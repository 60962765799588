import type { ComponentPropsWithoutRef, ElementType } from 'react'
import { keyframes } from '@emotion/react'
import type { Theme } from '@qasa/qds-ui'

import { styled } from '../../web'

import { useImage } from './use-image'

const PULSE_ANIMATION = keyframes({
  '0%': {
    opacity: 0.5,
  },
  '100%': {
    opacity: 1,
  },
})

type ImageWrapperProps = {
  borderRadius: keyof Theme['radii']
  $width: string | number
  $height: string | number
  isLoading: boolean
}
const ImageWrapper = styled('div')<ImageWrapperProps>(
  ({ theme, $width, $height, borderRadius, isLoading }) => ({
    width: $width,
    height: $height,
    borderRadius: theme.radii[borderRadius],
    overflow: 'hidden',
    transition: 'background-color 180ms',
    background: 'transparent',
    flexShrink: 0,
    ...(isLoading && {
      background: theme.colors.core.gray20,
      animationDuration: '0.8s',
      animationDirection: 'alternate',
      animationIterationCount: 'infinite',
    }),
    animationName: isLoading ? PULSE_ANIMATION : undefined,
  }),
)

type StyledImgProps = {
  isVisible: boolean
}
const StyledImg = styled('img')<StyledImgProps>(({ isVisible }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  opacity: isVisible ? 1 : 0,
  transition: 'opacity 180ms',
}))

type ImageOptions = {
  src?: string
  alt?: string
  width: string | number
  height: string | number
  loading?: 'eager' | 'lazy'
  borderRadius?: keyof Theme['radii']
}

type OmittedProps = 'width' | 'height'

type HTMLQdsProps<T extends ElementType> = ComponentPropsWithoutRef<T>

export type ImageProps = Omit<HTMLQdsProps<'img'>, OmittedProps> & ImageOptions
export function Image({
  src,
  loading = 'lazy',
  width,
  height,
  borderRadius = 'md',
  ...restProps
}: ImageProps) {
  const { loadingStatus } = useImage({ src, loading })

  const isLoading = loadingStatus === 'loading'
  return (
    <ImageWrapper isLoading={isLoading} $width={width} $height={height} borderRadius={borderRadius}>
      <StyledImg src={src} isVisible={loadingStatus === 'loaded'} loading={loading} {...restProps} />
    </ImageWrapper>
  )
}
