import { graphql } from '@qasa/graphql'

export const CREATE_UPLOAD = graphql(`
  mutation CreateUploadMutation($input: CreateUploadInput!) {
    __typename
    createUpload(input: $input) {
      upload {
        action
        fields
        id
      }
      errors {
        codes
        field
      }
    }
  }
`)
