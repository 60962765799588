import styled from '@emotion/styled'

const IconWrapper = styled.div({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
const CircleIcon = styled.div(({ theme }) => ({
  width: 20,
  height: 20,
  border: `2px solid ${theme.colors.border.strong}`,
  borderRadius: 999,
}))
const NumberText = styled.div(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  userSelect: 'none',
  ...theme.typography.caption.md,
}))

type NumberedCircleIconProps = {
  number: number
}
export function NumberedCircleIcon({ number }: NumberedCircleIconProps) {
  return (
    <IconWrapper>
      <CircleIcon />
      <NumberText>{number}</NumberText>
    </IconWrapper>
  )
}
