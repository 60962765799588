import { useTranslation } from 'react-i18next'
import { ArrowLeftIcon, Button, Heading, IconButton, Stack, TextField } from '@qasa/qds-ui'
import { useFormContext } from 'react-hook-form'
import type { UpdateUserInput } from '@qasa/graphql'

import type { HousingSituationStepProps } from '../housing-situation'
import { DialogAndWizardActionsFooter } from '../../../ui-shared/dialog-and-wizard-actions-footer'

export function HousingLocation({ goBack, requiredValues }: HousingSituationStepProps) {
  const { t } = useTranslation('edit_profile')
  const {
    register,
    formState: { isValid },
  } = useFormContext<UpdateUserInput>()
  const isLocationRequired = requiredValues.landlordCity

  return (
    <Stack gap="8x">
      <Heading size="lg">{t('form.housing_situation.housing_location.title')}</Heading>
      <TextField
        {...register('housingSituation.landlordCity', { required: isLocationRequired })}
        label={t('form.housing_situation.housing_location.label')}
        placeholder={t('form.housing_situation.housing_location.placeholder')}
      />
      <DialogAndWizardActionsFooter>
        <IconButton
          type="button"
          icon={ArrowLeftIcon}
          variant="tertiary"
          label={t('commons:back')}
          onClick={() => goBack()}
        />
        <Button type="submit" isDisabled={!isValid}>
          {t('commons:next')}
        </Button>
      </DialogAndWizardActionsFooter>
    </Stack>
  )
}
