import { theme } from '@qasa/qds-ui'
import { Image } from '@qasa/ui'
import { styled } from '@qasa/ui/web'

const HomeImage = styled(Image)({
  objectFit: 'cover',
})
export function SmallHomeCardImage({ src }: { src: string }) {
  return <HomeImage src={src} width={theme.sizes['24x']} height={theme.sizes['24x']} borderRadius="md" />
}
