import { HomeRentalTypeEnum } from '@qasa/graphql'

import { useAuthContext } from '../../context/auth-context'
import { useAdminState } from '../../data/admin-state'
import { useQueryParam } from '../../hooks/use-query-param'
import { currentBrand } from '../../config'
import { availableRentalTypes } from '../../config/markets'

import { useListingContext } from './listing-context'
import { useListingSections } from './listing-section-declarations'
import { PresetRentalTypeEnum } from './listing-section-routes'

export enum ListingSectionVariantEnum {
  CREATE_AD = 'create-ad',
  EDIT_AD = 'edit-ad',
}

type UseListingSectionRoutesParams = {
  variant: ListingSectionVariantEnum
  isUserWithPreviouslySignedHomes: boolean
  isUserWithHomeDrafts: boolean
  isHomePublished?: boolean
  isFromFindTenant?: boolean
}

export const useListingSectionRoutes = ({
  variant,
  isFromFindTenant,
  isUserWithPreviouslySignedHomes,
  isUserWithHomeDrafts,
  isHomePublished,
}: UseListingSectionRoutesParams) => {
  const { sections, shouldShowApplicationType, shouldShowUtilityCostsSection } = useListingSections()
  const { isAuthenticated } = useAuthContext()
  const { isAdminLoggedInAsUser } = useAdminState()
  const [presetRentalType] = useQueryParam<PresetRentalTypeEnum | null>({
    paramName: 'rental-type',
    defaultValue: null,
    whiteList: Object.values(PresetRentalTypeEnum),
  })
  const {
    isPendingShortcut,
    store: {
      values: { isProfessional, rentalType },
    },
  } = useListingContext()

  const isQasaFinland = currentBrand === 'qasa_finland'
  const isQasaFinlandProfessional = isQasaFinland && isProfessional
  const hasMultipleRentalTypes = availableRentalTypes.length > 1
  const shouldRenderAddress = !isHomePublished || isAdminLoggedInAsUser || isProfessional

  const {
    findTenantIntro,
    chooseRentalType,
    templates,
    duration,
    userType,
    sharedFurnished,
    homeType,
    size,
    areas,
    address,
    map,
    rent,
    preLogin,
    numberOfHomes,
    amenitiesHome,
    rulesAndAccessibility,
    locationPerks,
    uploadImages,
    description,
    standard,
    applicationType,
    landlordCalendar,
    cleaning,
    cancellationPolicy,
    calendarSettings,
    homeDetailsIntro,
    listingDetailsIntro,
    aboutRentalIntro,
    utilityCosts,
  } = sections

  const locationSections = [address, map]

  const longTermFlow = {
    [ListingSectionVariantEnum.CREATE_AD]: [
      ...(hasMultipleRentalTypes && !isFromFindTenant ? [chooseRentalType] : []),
      ...(isFromFindTenant ? [findTenantIntro] : []),
      ...(isUserWithPreviouslySignedHomes || isUserWithHomeDrafts ? [templates] : []),
      homeDetailsIntro,
      ...(!isAuthenticated && !isQasaFinland ? [userType] : []),
      ...locationSections,
      ...(isProfessional ? [numberOfHomes] : []),
      ...(!isQasaFinlandProfessional ? [sharedFurnished] : []),
      homeType,
      size,
      duration,
      preLogin,
    ],
    [ListingSectionVariantEnum.EDIT_AD]: isPendingShortcut
      ? [
          ...locationSections,
          sharedFurnished,
          homeType,
          size,
          duration,
          amenitiesHome,
          rulesAndAccessibility,
          standard,
        ]
      : [
          homeDetailsIntro,
          ...(shouldRenderAddress ? locationSections : []),
          ...(isProfessional ? [numberOfHomes] : []),
          ...(!isQasaFinlandProfessional ? [sharedFurnished] : []),
          homeType,
          size,
          duration,
          listingDetailsIntro,
          amenitiesHome,
          standard,
          description,
          uploadImages,
          aboutRentalIntro,
          rulesAndAccessibility,
          ...(shouldShowApplicationType ? [applicationType] : []),
          rent,
          ...(shouldShowUtilityCostsSection ? [utilityCosts] : []),
        ],
  }

  const vacationFlow = {
    [ListingSectionVariantEnum.CREATE_AD]: [
      ...(hasMultipleRentalTypes ? [chooseRentalType] : []),
      ...locationSections,
      ...(!isAuthenticated ? [userType] : []),
      sharedFurnished,
      homeType,
      size,
      areas,
      preLogin,
    ],
    [ListingSectionVariantEnum.EDIT_AD]: [
      sharedFurnished,
      homeType,
      size,
      areas,
      ...(shouldRenderAddress ? locationSections : []),
      locationPerks,
      amenitiesHome,
      rulesAndAccessibility,
      calendarSettings,
      landlordCalendar,
      description,
      uploadImages,
      cancellationPolicy,
      cleaning,
    ],
  }

  const createListingFlows = {
    long_term: longTermFlow,
    vacation: vacationFlow,
  }

  const resolvedRentalType =
    presetRentalType === PresetRentalTypeEnum.long_term ? HomeRentalTypeEnum.long_term : rentalType

  return createListingFlows[resolvedRentalType][variant]
}
