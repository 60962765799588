import { useContext, useCallback, useEffect, createContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useWizardSteps } from './use-wizard-steps'
import type { WizardStep } from './wizard-router.types'

type WizardRouterContextValues = {
  currentStep: WizardStep
  goNext: () => void
  goBack: () => void
}

const WizardRouterContext = createContext<WizardRouterContextValues | undefined>(undefined)

export type WizardRouterProps<TStepName extends string> = {
  /**
   * The part of the pathname that always will persist. It should exclude
   * the specific path for each step.
   *
   * @example
   * "/create-listing"
   */
  basePath: string
  /**
   * Each step in the wizard. The name for each step will be used
   * as to generate the subpath of that specific step in the wizard.
   */
  steps: WizardStep<TStepName>[]

  /**
   * Callback invoked every time the current step index changes
   */
  onIndexChange?: (newIndex: number) => void
}

export function WizardRouter<TStepName extends string>({
  basePath,
  steps,
  onIndexChange = () => undefined,
}: WizardRouterProps<TStepName>) {
  const { currentStep, goBack, goNext, currentIndex } = useWizardSteps({
    basePath,
    steps,
  })

  const onIndexChangeCallback = useCallback(onIndexChange, [onIndexChange])

  useEffect(() => {
    onIndexChangeCallback(currentIndex)
  }, [currentIndex, onIndexChangeCallback])

  return (
    <WizardRouterContext.Provider
      value={{
        currentStep,
        goNext,
        goBack,
      }}
    >
      <Switch>
        {steps.map(({ name, Component }) => (
          <Route key={name} path={`${basePath}/${name}`} component={Component} />
        ))}
        <Redirect to={`${basePath}/${steps[0].name}`} />
      </Switch>
    </WizardRouterContext.Provider>
  )
}

export const useWizardRouterContext = () => {
  const context = useContext(WizardRouterContext)

  if (context === undefined) {
    throw Error('`useWizardRouterContext` must be used within a `<WizardRouter>`')
  }
  return context
}
