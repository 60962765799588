import styled from '@emotion/styled'
import { Stack as QdsStack } from '@qasa/qds-ui'
import { forwardRef } from 'react'

import type { StackCrossPlatformStyle, StackProps } from './stack.types'

const StyledStack = styled(QdsStack)<StackCrossPlatformStyle>(({ flex }) => ({
  flex,
}))

export const Stack = forwardRef(
  (
    props: StackProps & { style?: React.CSSProperties },
    forwardedRef?: React.ForwardedRef<HTMLDivElement>,
  ) => {
    return <StyledStack ref={forwardedRef} {...props} />
  },
)
