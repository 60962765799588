import i18next from 'i18next'
import snakeCase from 'lodash/snakeCase'
import type { ProfileQueryQuery } from '@qasa/graphql'

import { notEmptyFilter } from '../../utils/array'

import { IdVerificationAction } from './fields/id-verification-action'

const ALL_BADGES: { badgeName: string; component?: React.ElementType }[] = [
  { badgeName: 'id', component: IdVerificationAction },
  { badgeName: 'housingReference' },
]

export type ViewItemType = {
  badgeName: 'id' | 'housingReference'
  isEnabled: boolean
  enabledTitle: string
  disabledTitle: string
  description: string
  actionTextPrimary: string
  ActionComponent?: React.ElementType
  shouldHideWhenDisabled?: boolean
}

export const getBadges = (
  profile: Pick<
    NonNullable<ProfileQueryQuery['user']>,
    'landlord' | 'professional' | 'currentIdentification' | 'housingSituation'
  >,
): ViewItemType[] => {
  const isLandlord = profile.landlord
  const isProfessional = profile.professional
  if (isProfessional && isLandlord) {
    return []
  }

  return ALL_BADGES.map(({ badgeName, component }) => {
    let isEnabled = false
    let shouldHideWhenDisabled = false

    switch (badgeName) {
      case 'id':
        isEnabled = Boolean(profile.currentIdentification?.identityConfirmed)
        break
      case 'housingReference':
        isEnabled = Boolean(profile.housingSituation?.hasReference)
        shouldHideWhenDisabled = true
        break
      default:
        return null
    }

    const snakeCaseBadgeName = snakeCase(badgeName)

    return {
      badgeName,
      isEnabled,
      enabledTitle: i18next.t(`edit_profile:form.verifications.${snakeCaseBadgeName}.enabled_title`),
      disabledTitle: i18next.t(`edit_profile:form.verifications.${snakeCaseBadgeName}.disabled_title`),
      description: i18next.t(`edit_profile:form.verifications.${snakeCaseBadgeName}.description`),
      actionTextPrimary: i18next.t(
        `edit_profile:form.verifications.${snakeCaseBadgeName}.action_text_primary`,
      ),
      ActionComponent: component,
      shouldHideWhenDisabled,
    }
  }).filter(notEmptyFilter)
}
