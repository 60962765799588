import { useLocalStorage } from 'usehooks-ts'

type UseLocalStorageOptions<T> = Parameters<typeof useLocalStorage<T | undefined>>[2]
export const usePersistentStorage = <T>(
  key: string,
  initialValue?: T,
  /**
   * __Note:__ The `options` parameter is only available in the web version of this hook.
   */
  options?: UseLocalStorageOptions<T>,
) => {
  return useLocalStorage(key, initialValue, options)
}
