import { useMutation } from '@apollo/client'
import { useCallback } from 'react'

import { UNPUBLISH_TENANT_AD } from './tenant-ad.gql'

export function useUnpublishTenantAd() {
  const [unpublishTenantAdMutation, { loading: isLoadingUnpublishTenantAd }] =
    useMutation(UNPUBLISH_TENANT_AD)

  const handlePublishTenantAd = useCallback(async () => {
    return unpublishTenantAdMutation()
  }, [unpublishTenantAdMutation])

  return { unpublishTenantAd: handlePublishTenantAd, isLoadingUnpublishTenantAd }
}
