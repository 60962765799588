import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { currentBrand } from '../../config'
import { useBrandDefaultCanonicalUrl } from '../../config/canonical-url'
import { BRAND_CONFIG } from '../../config/brand-configurations'

const QASA_DEFAULT_OG_IMAGE =
  'https://img.qasa.se/unsafe/600x300/https://qasa.s3.eu-west-1.amazonaws.com/uploads/qasa-og-share-image-wide.png'

type Props = {
  title?: string
  description?: string
  image?: string | null
  noIndex?: boolean
  noFollow?: boolean
  canonicalUrl?: string
}
export function MetaTags({ title, description, image, noIndex, noFollow = false, canonicalUrl }: Props) {
  const { t } = useTranslation('brand_copy')

  const brandCopy = {
    blocket: {
      tagline: t('blocket.tagline'),
      defaultDescription: t('blocket.defaultDescription'),
      defaultImage: 'https://qasa.s3-eu-west-1.amazonaws.com/uploads/bbq-placeholder-share-icon.png',
    },
    qasa: {
      tagline: t('qasa.tagline'),
      defaultDescription: t('qasa.defaultDescription'),
      defaultImage: QASA_DEFAULT_OG_IMAGE,
    },
  }

  const isBlocket = currentBrand === 'blocket'
  const { tagline, defaultDescription, defaultImage } = isBlocket ? brandCopy.blocket : brandCopy.qasa
  const siteName = BRAND_CONFIG.name
  const metaTitle = (title || tagline) + ' | ' + siteName
  const metaImage = image || defaultImage
  const metaDescription = description || defaultDescription
  const defaultCanonicalUrl = useBrandDefaultCanonicalUrl()

  const hasNoIndexOrNoFollow = noIndex || noFollow
  const getRobotsMetaTagContent = () => {
    switch (true) {
      case noIndex && noFollow:
        return 'noindex, nofollow'
      case noIndex:
        return 'noindex'
      case noFollow:
        return 'nofollow'
      default:
        return undefined
    }
  }

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />
      {hasNoIndexOrNoFollow && <meta name="robots" content={getRobotsMetaTagContent()} />}
      <link rel="canonical" href={canonicalUrl || defaultCanonicalUrl} />
    </Helmet>
  )
}
