import styled from '@emotion/styled'

/**
 * Visually hides content while preserving it for assistive technology.
 */
export const VisuallyHidden = styled.span({
  border: 0,
  clip: 'rect(0px, 0px, 0px, 0px)',
  height: 1,
  width: 1,
  margin: -1,
  padding: 0,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  wordWrap: 'normal',
  position: 'absolute',
})
