import { Stack, useBreakpointValue } from '@qasa/qds-ui'
import { styled } from '@qasa/ui/web'

import { useCurrentUser } from '../../contexts/current-user'
import { AreaSearchDialog } from '../../modules/area-search'
import { AreaSearchAutocomplete } from '../../modules/area-search/web'
import { MoreFiltersDialog, useLongTermFilterSections } from '../..'

import { useFindHomeFiltersContext } from './contexts/find-home-filter-context'
import { HideInEmbeddedFindHomeWrapper } from './hide-in-embedded-find-home-wrapper'
import { CreateSavedSearch } from './search-history/saved-searches'
import { useEmbeddedFindHome } from './hooks/use-embedded-find-home.web'

const Wrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  [theme.mediaQueries.mdUp]: {
    maxWidth: 800,
  },
}))

const FlexGrow = styled('div')({
  flex: 1,
  minWidth: 0,
})

export function LongTermSearch() {
  const { isAuthenticated } = useCurrentUser()
  const {
    filterValues: { searchAreas },
    updateFilterValues,
  } = useFindHomeFiltersContext()
  const filterSections = useLongTermFilterSections()
  const isEmbeddedFindHome = useEmbeddedFindHome()
  const isDesktop = useBreakpointValue({ base: false, md: true })

  return (
    <Wrapper direction="row" gap="2x">
      <FlexGrow>
        {isDesktop ? (
          <AreaSearchAutocomplete
            value={searchAreas}
            isFindHome
            onChange={(searchAreas) => updateFilterValues({ searchAreas })}
          />
        ) : (
          <AreaSearchDialog
            value={searchAreas}
            onChange={(searchAreas) => updateFilterValues({ searchAreas })}
            shouldShowSearchHistory
            shouldAutofocus={!isEmbeddedFindHome}
          />
        )}
      </FlexGrow>
      <MoreFiltersDialog filterSections={filterSections} />
      {isAuthenticated && (
        <HideInEmbeddedFindHomeWrapper>
          <CreateSavedSearch />
        </HideInEmbeddedFindHomeWrapper>
      )}
    </Wrapper>
  )
}
