import type { MutableRefObject } from 'react'
import { useEffect, useState } from 'react'
import type { MapboxOptions } from 'mapbox-gl'

import 'maplibre-gl/dist/maplibre-gl.css'
import { useCurrentLocale } from '../translations/use-current-locale'

import { ENV } from './env'

const LOCATIONIQ_TILES_URL = `https://tiles.locationiq.com/v2/streets/vector.json?key=${ENV.LOCATIONIQ_KEY}`

const CENTER_OF_SWEDEN = { lng: 16.536442377517346, lat: 63.06470060306475 }

type MapWithLanguage = mapboxgl.Map & {
  setLanguage: (language: string) => void
  autodetectLanguage: () => void
}

type UseLoadMapProps = {
  initialValues: Pick<
    MapboxOptions,
    'zoom' | 'center' | 'minZoom' | 'maxZoom' | 'dragRotate' | 'dragPan' | 'scrollZoom'
  > & {
    mapAnchor: MutableRefObject<HTMLDivElement>
  }
}
export function useMap({
  initialValues: {
    mapAnchor,
    zoom = 15,
    center = CENTER_OF_SWEDEN,
    minZoom = 4,
    maxZoom = 16,
    dragRotate = false,
    dragPan = true,
    scrollZoom = true,
  },
}: UseLoadMapProps) {
  const currentLanguage = useCurrentLocale()
  const [map, setMap] = useState<MapWithLanguage>()

  useEffect(() => {
    const loadMap = async () => {
      const mapboxgl = (await import('../vendor/locationiq-lang.js')).default
      const glmap = new mapboxgl.Map({
        container: mapAnchor.current,
        center,
        zoom,
        minZoom,
        maxZoom,
        dragRotate,
        dragPan,
        scrollZoom,
        style: LOCATIONIQ_TILES_URL,
      }) as MapWithLanguage

      currentLanguage && glmap.setLanguage(currentLanguage)
      glmap.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
        }),
      )
      /**
       * Make sure touch device users can scroll
       * the page with one finger without accidentally panning the map
       */
      glmap.on('touchstart', (event) => {
        glmap.dragPan.disable()
        glmap.scrollZoom.disable()
        glmap.touchPitch.disable()
        const e = event.originalEvent
        if (e && 'touches' in e) {
          if (e.touches.length > 1) {
            glmap.dragPan.enable()
          } else {
            glmap.dragPan.disable()
          }
        }
      })

      /* Force resize to prevent known size bug that can occur if container size is changed */
      /* https://github.com/mapbox/mapbox-gl-js/issues/3265 */
      glmap.on('load', () => glmap.resize())

      glmap.on('styleimagemissing', (event) => {
        /**
         * Our map tile provider (LocationIQ) doesn't provide images for all possible icons (ie "coworking_space", "bicycle_parking" etc)
         * and when this happens the map will output a lot of warnings to the console which we don't really care about.
         * To work around this we add a 0x0 image without any content which silences the warning without any actual impact on what's rendered.
         */
        glmap.addImage(event.id, { width: 0, height: 0, data: new Uint8Array() })
      })

      setMap(glmap)
    }
    loadMap()
  }, [mapAnchor, currentLanguage, zoom, center, minZoom, maxZoom, dragRotate, dragPan, scrollZoom])
  return { map }
}
