import type { TenantPreferencesHomeFragment } from '@qasa/graphql'

import { isNumber } from '../../utils/number'
import type { ParsedProfileSearchPreference } from '../../utils/parse-profile-search-preference'
import type { SearchPreferenceMatchIndicatorProps } from '../../components/search-preference-match-indicator'

const SIZE_MATCH_DATA_MAX_CLOSENESS_FACTOR = 1.1

type GetSizeMatchDataParams = {
  minRoomCount: ParsedProfileSearchPreference['minRoomCount']
  minSquareMeters: ParsedProfileSearchPreference['minSquareMeters']
  matchingHome: Omit<TenantPreferencesHomeFragment, '__typename'>
}
export const getSizeMatchData = ({
  minRoomCount,
  minSquareMeters,
  matchingHome,
}: GetSizeMatchDataParams): SearchPreferenceMatchIndicatorProps => {
  const isMinRoomCountMatching = Boolean(
    minRoomCount && matchingHome.roomCount && matchingHome.roomCount >= minRoomCount,
  )
  const isMinSquareMetersMatching = Boolean(
    minSquareMeters && matchingHome.squareMeters && matchingHome.squareMeters >= minSquareMeters,
  )
  const squareMetersDifferenceFactor =
    minSquareMeters && matchingHome.squareMeters && minSquareMeters / matchingHome.squareMeters
  const isCloseToPreferredSquareMeters =
    isNumber(squareMetersDifferenceFactor) &&
    squareMetersDifferenceFactor < SIZE_MATCH_DATA_MAX_CLOSENESS_FACTOR

  const getMatchingLevel = () => {
    if (isMinRoomCountMatching && isMinSquareMetersMatching) {
      return 'match'
    } else if (!isMinSquareMetersMatching && isCloseToPreferredSquareMeters) {
      return 'is_close'
    }
    return 'no_match'
  }
  return {
    matchingLevel: getMatchingLevel(),
  }
}
