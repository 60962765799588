import { useCallback } from 'react'
import type React from 'react'
import { useMutation } from '@apollo/client'
import type { MutationUpdaterFn, MutationFunctionOptions } from '@apollo/client'
import type {
  UploadTypeEnum,
  OwnerTypeEnum,
  UploadMetadataInput,
  FinalizeUploadMutationMutation,
} from '@qasa/graphql'

import { CREATE_UPLOAD, FINALIZE_UPLOAD } from '../data/graphql/mutations'
import { uploadToS3 } from '../helpers/upload-api'

type UploadParameters = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setUploadId?: React.Dispatch<React.SetStateAction<string | null>>
  cacheUpdateFunction?: MutationUpdaterFn<FinalizeUploadMutationMutation>
  refetchQueries?: MutationFunctionOptions['refetchQueries']
}

type UploadCallbackParameters = {
  file: File
  options: {
    uploadType: UploadTypeEnum
    ownerType: OwnerTypeEnum
    ownerId: string
    metadata?: UploadMetadataInput
  }
}

export function useUpload({
  setIsLoading,
  setUploadId,
  cacheUpdateFunction,
  refetchQueries,
}: UploadParameters) {
  const [createUpload] = useMutation(CREATE_UPLOAD)
  const [finalizeUpload] = useMutation(FINALIZE_UPLOAD, {
    update: cacheUpdateFunction,
    refetchQueries,
    awaitRefetchQueries: true,
  })

  const upload = useCallback(
    async ({ file, options }: UploadCallbackParameters) => {
      setIsLoading(true)
      const result = await createUpload({
        variables: {
          input: {
            title: file.name,
            contentLength: file.size,
            contentType: file.type,
            uploadType: options.uploadType,
            ownerType: options.ownerType,
            ownerId: options.ownerId,
            metadata: options.metadata,
          },
        },
      })

      if (!result.data?.createUpload?.upload || result.errors) {
        setIsLoading(false)
        throw new Error('Upload failed')
      }

      const { action, fields, id } = result.data?.createUpload?.upload ?? {}

      const s3Params = await uploadToS3(file, action, fields as Record<string, string | Blob>)

      await finalizeUpload({
        variables: {
          id,
          etag: s3Params.etag,
        },
      }).catch(() => {
        setIsLoading(false)
        throw new Error('Upload failed')
      })
      if (setUploadId) {
        setUploadId(id)
      }
      setIsLoading(false)
      return s3Params.location.replace('tmp%2F', 'img/')
    },
    [createUpload, finalizeUpload, setIsLoading, setUploadId],
  )

  return upload
}
