import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { OccupationScheduleTypeEnum } from '@qasa/graphql'

import { FormRadioGroup } from '../../../ui-shared/forms/form-radio-group'
import type { OccupationFormValues } from '../use-occupation-form'

import { OCCUPATION_I18N_KEY } from './title'

export function OccupationScheduleField() {
  const { t } = useTranslation('edit_profile')

  const { control, getValues } = useFormContext<OccupationFormValues>()

  const occupationScheduleTypeOptions = Object.values(OccupationScheduleTypeEnum).map((value) => {
    return { value, label: t(`${OCCUPATION_I18N_KEY}.occupation_schedule_type.options.${value}`) }
  })

  return (
    <FormRadioGroup
      control={control}
      name="occupationScheduleType"
      label={t(`${OCCUPATION_I18N_KEY}.occupation_schedule_type.label`, {
        context: getValues('occupationType'),
      })}
      options={occupationScheduleTypeOptions}
    />
  )
}
