import { useSearchParams } from 'next/navigation'
import { useMutation } from '@apollo/client'

import type { LanguageCode } from '../helpers/i18n'
import { useAuthContext } from '../context/auth-context'
import { UPDATE_USER } from '../data/graphql/mutations'
import { usePathname, useRouter } from '../vendor/next'

export const useChangeLocale = () => {
  const router = useRouter()
  const pathname = usePathname()

  const searchParams = useSearchParams()
  const { authBody } = useAuthContext()
  const [updateUser] = useMutation(UPDATE_USER)

  const changeLocale = (newLocale: LanguageCode) => {
    if (authBody) {
      updateUser({ variables: { uid: authBody.uid, input: { locale: newLocale } } }).catch(() => {
        // Silently fail because it's not critical enough to show an error to the user
      })
    }
    const searchString = searchParams.toString() ? `?${searchParams.toString()}` : ''
    router.replace(`${pathname}${searchString}${window.location.hash}`, {
      locale: newLocale,
    })
  }

  return changeLocale
}
