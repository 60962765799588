import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Paragraph, Heading, Stack } from '@qasa/qds-ui'
import { TenantAdStatusEnum } from '@qasa/graphql'

import { useProfileContext } from '../profile-context'
import { formatDateDistance } from '../../../helpers/date'

const CircleIcon = styled.div({
  width: 8,
  height: 8,
  backgroundColor: 'currentcolor',
  borderRadius: 999,
  // For optical alignment with the text
  marginBottom: 3,
})
const StatusText = styled(Paragraph)({
  color: 'inherit',
})
type WrapperProps = {
  tenantAdStatus: TenantAdStatusEnum | undefined
}
const Wrapper = styled.div<WrapperProps>(({ tenantAdStatus, theme }) => {
  const baseStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  }
  switch (tenantAdStatus) {
    case TenantAdStatusEnum.published:
      return { ...baseStyle, color: theme.colors.text.positive }
    case TenantAdStatusEnum.unpublished:
    case TenantAdStatusEnum.expired:
    default:
      return { ...baseStyle, color: theme.colors.core.gray50 }
  }
})

function Status() {
  const { t } = useTranslation('profile_card')
  const { tenantAdStatus } = useProfileContext()

  const resolvedTenantAdStatus = tenantAdStatus ?? TenantAdStatusEnum.unpublished

  return (
    <Wrapper {...{ tenantAdStatus }}>
      <CircleIcon aria-hidden="true" />
      <StatusText numberOfLines={1}>{t(`private.${resolvedTenantAdStatus}.statusText`)}</StatusText>
    </Wrapper>
  )
}
function ExpireStatus() {
  const { t } = useTranslation('profile_card')
  const { tenantAdPublishedUntil, tenantAdStatus } = useProfileContext()
  const isProfilePublished = tenantAdStatus === TenantAdStatusEnum.published

  if (!tenantAdPublishedUntil || !isProfilePublished) return null

  const formattedDateDistance = formatDateDistance({ date: new Date(tenantAdPublishedUntil) })
  return <Paragraph size="sm">{t('private.expire_status', { date: formattedDateDistance })}</Paragraph>
}
const ProfileCardRoot = styled(Stack)(({ theme }) => ({ gap: theme.spacing['3x'] }))
const Header = styled(Heading)(({ theme }) => ({ ...theme.typography.title['2xs'] }))
const Description = Paragraph

export const ProfileCard = Object.assign(ProfileCardRoot, { Header, Status, ExpireStatus, Description })
