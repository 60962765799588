import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Textarea } from '@qasa/qds-ui'

import type { ProfileDataFormValues } from '../profile.types'
import {
  containsEmailUrlOrPhoneNumberValidator,
  containsEmailOrPhoneNumberValidator,
} from '../../../helpers/validation'

const BIO_TITLE_MAX_LENGTH = 60

type BioTitleFieldParams = {
  isFieldRequired?: boolean
  isProfessional: boolean
}

export function BioTitleField({ isFieldRequired = false, isProfessional }: BioTitleFieldParams) {
  const {
    control,
    formState: { errors },
  } = useFormContext<ProfileDataFormValues['aboutMe']>()
  const { t } = useTranslation('edit_profile')

  const validator = isProfessional
    ? containsEmailOrPhoneNumberValidator
    : containsEmailUrlOrPhoneNumberValidator

  const validateErrorMessage = isProfessional
    ? 'disallow_content_with_email_or_phone'
    : 'disallow_content_with_email_url_or_phone'

  const isBioTitleInvalid = Boolean(errors.bioTitle)

  return (
    <Controller
      name={'bioTitle'}
      control={control}
      rules={{
        required: { value: isFieldRequired, message: t('errors.bioTitle.required') },
        maxLength: { value: BIO_TITLE_MAX_LENGTH, message: t('errors.bioTitle.too_long') },
        validate: (value: string) =>
          validator()(value).valid || t(`validation_utils:error_messages.${validateErrorMessage}`),
      }}
      render={({ field }) => (
        <Textarea
          label={t('form.bio.fields.bioTitle.title')}
          placeholder={t('form.bio.fields.bioTitle.placeholder')}
          maxLength={BIO_TITLE_MAX_LENGTH}
          isInvalid={isBioTitleInvalid}
          errorMessage={errors.bioTitle?.message}
          helperText={t('form.bio.fields.bioTitle.helper_text')}
          {...field}
        />
      )}
    />
  )
}
