import { HomeRentalTypeEnum, HomeTypeEnum } from '@qasa/graphql'

type GetFilterableHomeTypesParams = {
  rentalType: HomeRentalTypeEnum
}
// NOTE: Keep these home types in sync with mapHomeTypes in find-home.shared.ts
export const getFilterableHomeTypes = ({ rentalType }: GetFilterableHomeTypesParams) => {
  if (rentalType === HomeRentalTypeEnum.vacation) {
    return [HomeTypeEnum.apartment, HomeTypeEnum.house, HomeTypeEnum.cottage, HomeTypeEnum.other]
  }

  // NOTE: For Qasa.com we want to avoid separating the filters options.
  // If we want to add a new filter-item we should add it for all "platforms".
  return [
    HomeTypeEnum.apartment,
    HomeTypeEnum.house,
    HomeTypeEnum.cottage,
    HomeTypeEnum.corridor,
    HomeTypeEnum.other,
  ]
}
