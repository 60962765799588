import { Heading, Button, Paragraph, Stack } from '@qasa/qds-ui'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Dialog } from '@qasa/app/src/components/dialog'

import { useVerifyIdWithScrive } from '../../../../hooks/use-verify-id-with-scrive'
import { BRAND_CONFIG } from '../../../../config/brand-configurations'
import type { CreditVerificationStepProps } from '../credit-verification-step'

const Wrapper = Stack

const List = styled.ul({
  margin: 16,
  listStyleType: 'initial',
})

export function CreditVerificationSkip({
  onSkipCreditFlow,
  goNext,
}: Pick<CreditVerificationStepProps, 'goNext' | 'onSkipCreditFlow'>) {
  const { t } = useTranslation('create_application_with_verifications')
  /**
   * NOTE: Credit report requires transaction_id from scrive which we do not retain if we route to our id-verification route.
   * This works for now since we only support "pro" homes in Sweden.
   */
  const { isIdVerified, verifyIdThroughScrive, isLoading } = useVerifyIdWithScrive({
    identificationType: BRAND_CONFIG.availableIdVerifications[0],
  })

  useEffect(() => {
    if (isIdVerified) {
      goNext()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdVerified])

  const uspList = t('credit_verification_step.skip.usps', { returnObjects: true }) as string[]

  return (
    <Dialog.Body>
      <Wrapper gap={'8x'}>
        <Heading size="lg" as="h3">
          {t('credit_verification_step.skip.title')}
        </Heading>
        <Paragraph size="xl">{t('credit_verification_step.skip.description')}</Paragraph>
        <List>
          {uspList.map((usp: string) => (
            <li key={usp}>
              <Paragraph>{usp}</Paragraph>
            </li>
          ))}
        </List>
        <Stack alignItems="center" gap="2x">
          <Button onClick={verifyIdThroughScrive} isLoading={isLoading}>
            {t('credit_verification_step.order_credit_report')}
          </Button>
          <Button variant="tertiary" onClick={onSkipCreditFlow}>
            {t('credit_verification_step.skip_credit_report')}
          </Button>
        </Stack>
      </Wrapper>
    </Dialog.Body>
  )
}
