import { useMutation } from '@apollo/client'
import { useState } from 'react'
import type { FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Stack, TextField } from '@qasa/qds-ui'
import { useToastContext } from '@qasa/app'

import { useAuthContext } from '../../context/auth-context'
import { phoneValidator } from '../../helpers/validation'

import { UPDATE_PHONE_NUMBER } from './phone-number-form.gql'

export function PhoneNumberForm() {
  const { addToast } = useToastContext()
  const { authBody, refreshAuthBody } = useAuthContext()
  const [phoneNumber, setPhoneNumber] = useState(authBody?.private.phoneNumber || '')
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const { t } = useTranslation('publish_home_overlay')

  const [updateUser] = useMutation(UPDATE_PHONE_NUMBER, {
    onError: () => addToast({ message: t('commons:unexpected_error') }),
    onCompleted: (data) => {
      if (data?.updateUser?.errors) {
        addToast({ message: t('missing_phone_number.backend_validation_error') })
      } else {
        refreshAuthBody()
      }
    },
  })

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const phoneValidationResult = phoneValidator(phoneNumber, e.target)

    if (!phoneValidationResult.valid) {
      setErrorMessage(phoneValidationResult.message)
      return
    } else {
      setErrorMessage(undefined)
    }

    updateUser({
      variables: {
        uid: authBody!.uid,
        input: {
          phoneNumber,
        },
      },
    })
  }

  return (
    <Stack gap="2x" direction="row" alignItems="flex-end" as="form" onSubmit={handleSubmit}>
      <TextField
        value={phoneNumber || ''}
        onChange={(e) => setPhoneNumber(e.target.value)}
        isInvalid={Boolean(errorMessage)}
        errorMessage={errorMessage}
        label={''}
        aria-label={t('commons:phone_number')}
        placeholder={t('commons:phone_number')}
      />
      <div>
        <Button size="md" type="submit">
          {t('submit')}
        </Button>
      </div>
    </Stack>
  )
}
