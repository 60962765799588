import styled from '@emotion/styled'
import { Stack, Heading } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

const StyledUl = styled('ul')({ listStyleType: 'disc' })
const StyledLi = styled('li')({ display: 'list-item', listStylePosition: 'inside' })

export function ProcessListSection() {
  const { t } = useTranslation('shortcut_opt_in')
  const processListData: string[] = t('intro.process_list', { returnObjects: true })

  return (
    <Stack gap="3x">
      <Heading size="xs">{t('intro.process_title')}</Heading>
      <StyledUl>
        {processListData.map((process: string, index: number) => (
          <StyledLi key={index}>{process}</StyledLi>
        ))}
      </StyledUl>
    </Stack>
  )
}
