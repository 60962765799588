import type { ChangeEvent } from 'react'
import { useCallback } from 'react'

export type UseCheckboxGroupParams = {
  /**
   * Currently checked checkboxes in the group
   * (controlled mode)
   */
  value: string[]

  /**
   * Callback function once a checkbox is checked.
   * @param nextValue the value of the checked checkbox
   */
  onChange: (nextValue: string[]) => void
}

export function useCheckboxGroup(props: UseCheckboxGroupParams) {
  const { value: valueProp, onChange: onChangeProp } = props

  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const checkboxValue = event.target.value
      const isCheckboxValueInGroup = valueProp.includes(checkboxValue)

      const nextInternalValue = isCheckboxValueInGroup
        ? valueProp.filter((v) => v !== checkboxValue)
        : [...valueProp, checkboxValue]

      onChangeProp?.(nextInternalValue)
    },
    [onChangeProp, valueProp],
  )

  return {
    value: valueProp,
    onChange: handleCheckboxChange,
  }
}

export type UseCheckboxGroupReturn = ReturnType<typeof useCheckboxGroup>
