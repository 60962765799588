import { Spacer } from '@qasa/qds-ui'
import { useQuery } from '@apollo/client'

import { PROFILE } from '../../profile/profile.gql'
import { NetworkStatus } from '../../../ui-shared/_core/network-statuses'
import { VerificationSection } from '../../profile/edit-profile-sections/verification-section'
import { StepNavigation } from '../components/step-navigation'
import { WizardStepWrapper } from '../components/step-wrapper'
import { useWizardRouterContext } from '../wizard-router/wizard-router'
import { useProtectedAuthContext } from '../../../context/protected-auth-context'

export function VerificationsStep() {
  const { goNext } = useWizardRouterContext()
  const { authBody } = useProtectedAuthContext()
  const {
    error,
    data,
    loading: isLoading,
  } = useQuery(PROFILE, {
    variables: { uid: authBody.uid },
  })

  if (isLoading || error) {
    return (
      <WizardStepWrapper>
        <NetworkStatus {...{ isLoading, error }} />
      </WizardStepWrapper>
    )
  }

  return (
    <WizardStepWrapper>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          goNext()
        }}
      >
        <VerificationSection profile={data!.user!} />
        <Spacer size="8x" />
        <StepNavigation />
      </form>
    </WizardStepWrapper>
  )
}
