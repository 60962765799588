import type { CurrencyEnum, HousingSituationAgreementTypeEnum } from '@qasa/graphql'

import type { ParsedProfileSearchPreference } from '../profile/utils/parse-profile-search-preference'

import {
  IntroStep,
  LocationStep,
  DatesStep,
  ProfileIntroStep,
  HousingStep,
  OccupationStep,
  AboutMeStep,
  VerificationsStep,
  RentStep,
  SizeStep,
  HomeTypeStep,
  RequiredAmenitiesStep,
  PreferredAmenitiesStep,
} from './steps'
import type { WizardStep } from './wizard-router/wizard-router.types'

export const createTenantWizardSteps: WizardStep[] = [
  { name: 'intro', Component: IntroStep },
  { name: 'location', Component: LocationStep },
  { name: 'home-type', Component: HomeTypeStep },
  { name: 'size', Component: SizeStep },
  { name: 'dates', Component: DatesStep },
  { name: 'requirements', Component: RequiredAmenitiesStep },
  { name: 'preferences', Component: PreferredAmenitiesStep },
  { name: 'rent', Component: RentStep },
  { name: 'profile-intro', Component: ProfileIntroStep },
  { name: 'housing', Component: HousingStep },
  { name: 'occupation', Component: OccupationStep },
  { name: 'about-me', Component: AboutMeStep },
  { name: 'verifications', Component: VerificationsStep },
]

export type CreateTenantListingFormValues = {
  location: Pick<ParsedProfileSearchPreference, 'selectedAreas'>
  'profile-intro': {
    firstName: string
    familyName: string
    phoneNumber: string
  }
  'about-me': {
    bio: string
    bioPets: string
    bioTitle: string
  }
  housing: {
    housingSituation: {
      agreementType: HousingSituationAgreementTypeEnum | null
      landlordCity: string | null
      landlordName: string | null
      landlordPhoneNumber: string | null
    }
    movingReason: string
    bioLived: string
  }
  'home-type': Pick<ParsedProfileSearchPreference, 'homeType' | 'shared' | 'furniture' | 'contractType'>
  rent: Pick<ParsedProfileSearchPreference, 'maxMonthlyCost'> & { currency: CurrencyEnum }
  dates: Pick<ParsedProfileSearchPreference, 'moveIn' | 'moveOut'>
  size: Pick<ParsedProfileSearchPreference, 'householdSize' | 'minRoomCount' | 'minSquareMeters'>
  requirements: Pick<ParsedProfileSearchPreference, 'requiredAmenities'>
  preferences: Pick<ParsedProfileSearchPreference, 'preferredAmenities'>
}

export type CreateTenantListingFormValuesKey = keyof CreateTenantListingFormValues
