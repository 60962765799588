import { useQuery } from '@apollo/client'
import type { ReactNode } from 'react'
import { useState, useCallback, createContext, useContext } from 'react'
import type { DeepPartial } from 'react-hook-form'
import type { ProfileForCreateTenantListingQuery } from '@qasa/graphql'

import { useProtectedAuthContext } from '../../context/protected-auth-context'

import { PROFILE_FOR_CREATE_TENANT_LISTING } from './hooks/profile-for-create-tenant-listing.gql'
import type { UseMutationOnFormChangeReturn } from './hooks/use-mutation-on-form-change'
import { useMutationOnFormChange } from './hooks/use-mutation-on-form-change'
import type { CreateTenantListingFormValues, CreateTenantListingFormValuesKey } from './step-declarations'

type UpdateFormValuesFunction = <TDataKey extends CreateTenantListingFormValuesKey>(params: {
  dataKey: TDataKey
  payload: DeepPartial<CreateTenantListingFormValues[TDataKey]>
}) => void

type CreateTenantListingContextValues = {
  formValues: CreateTenantListingFormValues
  updateFormValues: UpdateFormValuesFunction
  tenantProfileData?: ProfileForCreateTenantListingQuery['user']
  refetchTenantProfileData: () => void
  isProfessional: boolean
} & UseMutationOnFormChangeReturn

const CreateTenantListingContext = createContext<CreateTenantListingContextValues | undefined>(undefined)

type CreateTenantListingProps = {
  children: ReactNode
  initialFormValues: CreateTenantListingFormValues
}
export function CreateTenantListingProvider({ children, initialFormValues }: CreateTenantListingProps) {
  const { authBody } = useProtectedAuthContext()
  const [formValues, setFormValues] = useState<CreateTenantListingFormValues>(initialFormValues)
  const { triggerMutation } = useMutationOnFormChange({ formValues })
  const { data, refetch: refetchTenantProfileData } = useQuery(PROFILE_FOR_CREATE_TENANT_LISTING, {
    variables: { uid: authBody.uid },
  })

  const tenantProfileData = data?.user

  const updateFormValues = useCallback<UpdateFormValuesFunction>(
    ({ dataKey, payload }) => setFormValues((oldValues) => ({ ...oldValues, [dataKey]: payload })),
    [],
  )

  return (
    <CreateTenantListingContext.Provider
      value={{
        formValues,
        updateFormValues,
        triggerMutation,
        tenantProfileData,
        refetchTenantProfileData,
        isProfessional: Boolean(tenantProfileData?.professional),
      }}
    >
      {children}
    </CreateTenantListingContext.Provider>
  )
}

export const useCreateTenantListingContext = () => {
  const context = useContext(CreateTenantListingContext)

  if (context === undefined) {
    throw Error('`CreateTenantListingContext` is undefined')
  }

  return context
}
