import type { ReactNode } from 'react'
import { createContext, Fragment, useContext, useMemo } from 'react'
import { LoadingDots } from '@qasa/qds-ui'

import { PageChrome } from '../ui-shared/_layout/page-chrome'
import { Wizard } from '../ui-shared/wizard'

import type { AuthContextType } from './auth-context'
import { useAuthContext } from './auth-context'

type ProtectedAuthContextType = {
  authBody: NonNullable<AuthContextType['authBody']>
  refreshAuthBody: () => void
}

// @ts-ignore
const ProtectedAuthContext = createContext<ProtectedAuthContextType>()

function ProtectedAuthProvider({ children, wizard }: { children: ReactNode; wizard?: boolean }) {
  const { isLoading, authBody, refreshAuthBody } = useAuthContext()

  const contextValue = useMemo(() => ({ authBody: authBody!, refreshAuthBody }), [authBody, refreshAuthBody])

  const getWrapper = () => {
    if (wizard) {
      return Wizard
    }
    return Fragment
  }

  const Wrapper = getWrapper()
  const loader = (
    <Wrapper>
      <LoadingDots />
    </Wrapper>
  )

  return (
    <ProtectedAuthContext.Provider value={contextValue}>
      {isLoading ? <PageChrome>{loader}</PageChrome> : children}
    </ProtectedAuthContext.Provider>
  )
}

const useProtectedAuthContext = () => useContext<ProtectedAuthContextType>(ProtectedAuthContext)

export { useProtectedAuthContext, ProtectedAuthProvider }
