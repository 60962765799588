import { useQuery } from '@apollo/client'

import { useProtectedAuthContext } from '../../../context/protected-auth-context'
import { parseTenantListingData } from '../create-tenant-listing.utils'
import type { CreateTenantListingFormValues } from '../step-declarations'
import { BRAND_CONFIG } from '../../../config/brand-configurations'

import { PROFILE_FOR_CREATE_TENANT_LISTING } from './profile-for-create-tenant-listing.gql'

export function usePreloadedFormValues() {
  const { authBody } = useProtectedAuthContext()
  const { data, loading: isLoadingProfileData } = useQuery(PROFILE_FOR_CREATE_TENANT_LISTING, {
    variables: { uid: authBody.uid },
  })

  const isLoadingPreloadedFormValues = isLoadingProfileData

  const {
    firstName,
    familyName,
    phoneNumber,
    bio,
    bioTitle,
    bioPets,
    bioLived,
    movingReason,
    housingSituation,
    preferredAmenities,
    requiredAmenities,
    shared,
    furniture,
    minSquareMeters,
    minRoomCount,
    householdSize,
    homeType,
    maxMonthlyCost,
    currency,
    moveIn,
    moveOut,
    contractType,
    selectedAreas,
  } = parseTenantListingData({ profileResponseData: data?.user })

  const preloadedFormValues: CreateTenantListingFormValues = {
    location: { selectedAreas },
    'profile-intro': {
      firstName: firstName || '',
      familyName: familyName || '',
      phoneNumber: phoneNumber || '',
    },
    'about-me': {
      bio: bio || '',
      bioPets: bioPets || '',
      bioTitle: bioTitle || '',
    },
    housing: {
      bioLived: bioLived || '',
      movingReason: movingReason || '',
      housingSituation: {
        agreementType: housingSituation?.agreementType || null,
        landlordCity: housingSituation?.landlordCity || '',
        landlordName: housingSituation?.landlordName || '',
        landlordPhoneNumber: housingSituation?.landlordPhoneNumber || '',
      },
    },
    rent: {
      maxMonthlyCost,
      currency: currency ?? BRAND_CONFIG.allowedCurrencies[0],
    },
    'home-type': {
      homeType,
      shared,
      furniture,
      contractType,
    },
    size: {
      householdSize,
      minRoomCount,
      minSquareMeters,
    },
    dates: {
      moveIn,
      moveOut,
    },
    requirements: {
      requiredAmenities,
    },
    preferences: {
      preferredAmenities,
    },
  }

  return { preloadedFormValues, isLoadingPreloadedFormValues }
}
