import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormInputField } from '../../../ui-shared/forms/form-input-field'
import type { OccupationFormValues } from '../use-occupation-form'
import { containsPossiblePhoneNumberOrEmailOrUrl } from '../../../helpers/validation'

import { ERROR_OCCUPATION_I18N_KEY, OCCUPATION_I18N_KEY } from './title'

export function Subtitle() {
  const { t } = useTranslation('edit_profile')

  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<OccupationFormValues>()

  return (
    <FormInputField
      control={control}
      name={'subtitle'}
      label={t(`${OCCUPATION_I18N_KEY}.subtitle.label`, { context: getValues('occupationType') })}
      placeholder={t(`${OCCUPATION_I18N_KEY}.subtitle.placeholder`, { context: getValues('occupationType') })}
      rules={{
        maxLength: { value: 50, message: t(`${ERROR_OCCUPATION_I18N_KEY}.max_length_fifty`) },
        validate: (value) => {
          if (value) {
            return (
              !containsPossiblePhoneNumberOrEmailOrUrl(String(value)) ||
              t('errors.occupation.forbiden_content_detected')
            )
          }

          return true
        },
      }}
      errorMessage={errors['subtitle'] && errors['subtitle'].message}
    />
  )
}
