import { Spacer } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import { useToastContext } from '@qasa/app'
import { Link } from 'react-router-dom'

import { getPrimaryUpload } from '../../../helpers/contract'
import { SectionWrapper } from '../section-wrapper'
import { useHomeDraftsAndPreviouslySignedHomes } from '../use-home-drafts-and-previously-rented-out'
import { HomeItem } from '../home-item'
import { HomesList, TemplatesListLayout } from '../home-templates'

export function TemplatesSection({
  setIsLoadingNext,
}: {
  setIsLoadingNext: Dispatch<SetStateAction<boolean>>
}) {
  const { addToast } = useToastContext()
  const { t } = useTranslation('listing')
  const {
    homeDrafts,
    totalDraftCount,
    previouslySignedHomes,
    totalPreviouslySignedHomesCount,
    createHomeFromTemplate,
    isLoadingCreateHome,
    hasHomeDrafts,
    hasPreviouslySignedHomes,
  } = useHomeDraftsAndPreviouslySignedHomes({
    onError: () => addToast({ message: t('commons:unexpected_error') }),
  })

  useEffect(() => {
    setIsLoadingNext(isLoadingCreateHome)
  }, [isLoadingCreateHome, setIsLoadingNext])

  const headingi18nKey =
    hasHomeDrafts && hasPreviouslySignedHomes
      ? 'templates_both'
      : hasPreviouslySignedHomes
        ? 'templates_previous_listings'
        : 'templates_drafts'

  return (
    <SectionWrapper title={t(`section_headings.${headingi18nKey}`)} description={t('templates.description')}>
      <TemplatesListLayout isLoading={isLoadingCreateHome}>
        {hasHomeDrafts && (
          <HomesList title={t('templates.pending')} totalCount={totalDraftCount || 0}>
            {homeDrafts.map(({ id, location, shared, roomCount, squareMeters, uploads, type, createdAt }) => {
              const editListingUrl = `/edit-listing/${id}`
              return (
                <Link key={id} to={{ pathname: editListingUrl }}>
                  <HomeItem
                    imageUrl={getPrimaryUpload(uploads)?.url}
                    datePrefix={t('templates.created_at')}
                    date={createdAt}
                    {...{ location, shared, roomCount, squareMeters, type }}
                  />
                </Link>
              )
            })}
          </HomesList>
        )}
        {hasPreviouslySignedHomes && (
          <HomesList
            title={t('templates.previous_listings')}
            totalCount={totalPreviouslySignedHomesCount || 0}
          >
            {previouslySignedHomes.map(
              ({
                id,
                location,
                shared,
                roomCount,
                squareMeters,
                uploads,
                type,
                publishedAt,
                homeTemplates,
              }) => {
                if (homeTemplates.length === 0) return null
                const editListingUrl = `/edit-listing/${id}/amenities-home`
                const templateId = homeTemplates[0].id

                return (
                  <Link
                    key={id}
                    to={editListingUrl}
                    onClick={(event) => {
                      event.preventDefault()
                      createHomeFromTemplate({ variables: { homeTemplateId: templateId } })
                    }}
                  >
                    <HomeItem
                      imageUrl={getPrimaryUpload(uploads)?.url}
                      datePrefix={t('templates.published_at')}
                      date={publishedAt}
                      {...{ location, shared, roomCount, squareMeters, type }}
                    />
                  </Link>
                )
              },
            )}
          </HomesList>
        )}
      </TemplatesListLayout>
      <Spacer size="4x" />
    </SectionWrapper>
  )
}
