import { useRef, useEffect } from 'react'

/**
 * @deprecated This is an anti-pattern since all components should be resilient to being remounted.
 * For alternative solutions [see this section in the React docs](https://react.dev/learn/you-might-not-need-an-effect).
 */
export function useFirstRender() {
  const isFirstRender = useRef<boolean>(true)

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    }
  }, [])

  return { isFirstRender: isFirstRender.current }
}
