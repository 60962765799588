import styled from '@emotion/styled'
import { Heading, Stack, Paragraph, Spacer } from '@qasa/qds-ui'
import type { StaticImageData } from 'next/image'

import { Image } from '../../../ui-shared/_core/image'
import homePlaceholderImg from '../../../assets/misc/home-placeholder.jpg'
import { SectionNavigation } from '../section-navigation'

const IMAGE_MAX_HEIGHT = 260

const Wrapper = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing['8x'],
  padding: theme.spacing['4x'],
  [theme.mediaQueries.mdUp]: {
    maxWidth: 1024,
    flexDirection: 'row',
    gap: 0,
    padding: `${theme.spacing['8x']} ${theme.spacing['4x']} 0px ${theme.spacing['4x']}`,
    margin: 'auto',
  },
}))

const Overline = styled.div(({ theme }) => ({
  ...theme.typography.title['2xs'],
  color: theme.colors.text.subtle,
}))

const Chapter = styled(Stack)({
  maxWidth: 500,
})
const StyledImage = styled(Image)(({ theme }) => ({
  objectFit: 'cover',
  borderRadius: theme.radii.lg,
  height: 'auto',
  aspectRatio: '1/1',
  width: '100%',
  maxHeight: IMAGE_MAX_HEIGHT,
  [theme.mediaQueries.mdUp]: {
    // NOTE: Sync so it has same height as create listing-landing-tori image
    height: '80vh',
    maxHeight: 'none',
  },
}))
const ImageContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',

  [theme.mediaQueries.mdUp]: {
    paddingRight: theme.spacing['8x'],
    justifyContent: 'flex-end',
  },
}))
const ChapterContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flex: 1,
  [theme.mediaQueries.mdUp]: {
    paddingLeft: theme.spacing['8x'],
  },
}))

type Props = {
  previousSection: string
  nextSection: string
  title: string
  overline: string
  description: string
  image?: string | StaticImageData
}

export function ChapterIntro({ nextSection, previousSection, title, overline, description, image }: Props) {
  return (
    <Wrapper>
      <ImageContainer>
        <StyledImage src={image || homePlaceholderImg.src} />
      </ImageContainer>
      <ChapterContainer>
        <Chapter justifyContent="center" gap="3x">
          <Overline>{overline}</Overline>
          <Heading>{title}</Heading>
          <Paragraph size="lg">{description}</Paragraph>
          <Spacer size="6x" />
          <SectionNavigation {...{ nextSection, previousSection }} />
        </Chapter>
      </ChapterContainer>
    </Wrapper>
  )
}
