import type { ButtonHTMLAttributes, ForwardedRef } from 'react'
import { forwardRef } from 'react'

import type { UnstyledButtonProps } from './unstyled-button.types'

export const UnstyledButton = forwardRef(
  (
    { children, onPress, isDisabled, ...rest }: UnstyledButtonProps & ButtonHTMLAttributes<HTMLButtonElement>,
    forwardedRef?: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <button ref={forwardedRef} disabled={isDisabled} onClick={onPress} {...rest}>
        {children}
      </button>
    )
  },
)
