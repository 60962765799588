import React from 'react'
import { useHistory } from 'react-router-dom'

export function IdVerificationAction({
  children: button,
}: {
  children: React.ReactElement<FixThisTypeLater>
}) {
  const history = useHistory()
  return React.cloneElement(button, {
    onClick: () => history.replace(`/id-verification?redirectUrl=${history.location.pathname}`),
  })
}
