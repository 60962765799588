import type { FieldPathValue, FieldValues, Path, UseControllerProps } from 'react-hook-form'
import { useController } from 'react-hook-form'
import type { RadioCardProps } from '@qasa/qds-ui'
import { RadioGroup, Stack } from '@qasa/qds-ui'

type FormRadioOption<TValue> = Pick<RadioCardProps, 'label' | 'helperText'> & {
  value: TValue
}
type FormRadioGroupProps<
  TValues extends FieldValues,
  TPath extends Path<TValues>,
  TPathValue extends FieldPathValue<TValues, TPath>,
> = UseControllerProps<TValues, TPath> & {
  label?: string
  ['aria-label']?: string
  options: FormRadioOption<TPathValue>[]
  /**
   * The error message to display when the field is invalid
   */
  errorMessage?: string
}

export function FormRadioGroup<
  TValues extends FieldValues,
  TPath extends Path<TValues>,
  TPathValue extends FieldPathValue<TValues, TPath>,
>(props: FormRadioGroupProps<TValues, TPath, TPathValue>) {
  const { label, options, errorMessage, 'aria-label': ariaLabel, ...formProps } = props
  const { field, fieldState } = useController(formProps)
  const { onChange, ...fieldProps } = field

  return (
    <RadioGroup
      {...fieldProps}
      onValueChange={onChange}
      aria-label={ariaLabel}
      isInvalid={fieldState.invalid}
      errorMessage={errorMessage}
    >
      {label && <RadioGroup.Label>{label}</RadioGroup.Label>}
      <Stack gap="2x">
        {options.map((item, index) => (
          <RadioGroup.Card key={index} {...item} />
        ))}
      </Stack>
    </RadioGroup>
  )
}
