import { graphql } from '@qasa/graphql'

export const HOME_SEARCH = graphql(`
  query HomeSearch(
    $offset: Int
    $limit: Int
    $market: MarketNameTypeEnum
    $order: HomeSearchOrderEnum
    $orderBy: HomeSearchOrderByEnum
    $searchParams: HomeSearchParamsInput!
  ) {
    homeSearch(market: $market, searchParams: $searchParams, order: $order, orderBy: $orderBy) {
      filterHomesOffset(offset: $offset, limit: $limit) {
        pagesCount
        totalCount
        hasNextPage
        hasPreviousPage
        nodes {
          id
          firsthand
          rent
          tenantBaseFee
          title
          currency
          minimumPricePerNight
          maximumPricePerNight
          averagePricePerNight
          favoriteMarkedByUser
          floor
          buildingFloors
          landlord {
            uid
            companyName
            premium
            professional
            profilePicture {
              url
            }
            proPilot
          }
          user {
            uid
            firstName
            proAgent
            profilePicture {
              url
            }
          }
          instantSignSetting {
            id
            enabled
          }
          location {
            id
            latitude
            longitude
            route
            locality
            streetNumber
            sublocality
            countryCode
          }
          displayStreetNumber
          links {
            locale
            url
          }
          roomCount
          seniorHome
          shared
          squareMeters
          studentHome
          type
          traits {
            type
          }
          duration {
            createdAt
            endOptimal
            endUfn
            id
            startAsap
            startOptimal
            updatedAt
          }
          corporateHome
          uploads {
            id
            url
            type
            title
            metadata {
              primary
              order
            }
          }
          numberOfHomes
          minRent
          maxRent
          minRoomCount
          maxRoomCount
          minSquareMeters
          maxSquareMeters
          rentalType
          tenantCount
          bedCount
          bedroomCount
        }
      }
    }
  }
`)

export const HOME_INDEX_SEARCH = graphql(`
  query HomeIndexSearchQuery(
    $order: HomeIndexSearchOrderInput
    $offset: Int
    $limit: Int
    $params: HomeSearchParamsInput
  ) {
    homeIndexSearch(order: $order, params: $params) {
      documents(offset: $offset, limit: $limit) {
        hasNextPage
        hasPreviousPage
        nodes {
          bedroomCount
          blockListing
          rentalLengthSeconds
          householdSize
          corporateHome
          description
          endDate
          firstHand
          furnished
          homeType
          id
          instantSign
          market
          lastBumpedAt
          monthlyCost
          petsAllowed
          platform
          publishedAt
          publishedOrBumpedAt
          rent
          currency
          roomCount
          seniorHome
          shared
          shortcutHome
          smokingAllowed
          sortingScore
          squareMeters
          startDate
          studentHome
          tenantBaseFee
          title
          wheelchairAccessible
          location {
            id
            locality
            countryCode
            streetNumber
            point {
              lat
              lon
            }
            route
          }
          displayStreetNumber
          uploads {
            id
            order
            type
            url
          }
        }
        pagesCount
        totalCount
      }
    }
  }
`)

export const GET_USER_FAVORITE_HOMES = graphql(`
  query GetUserFavoriteHomes {
    favoriteHomes(rentalTypes: [long_term], orderBy: PUBLISHED_AT) {
      filterHomesOffset {
        nodes {
          id
        }
      }
    }
  }
`)
