import styled from '@emotion/styled'
import { Spacer, Stack } from '@qasa/qds-ui'

import { useAuthContext } from '../../context/auth-context'
import { ProfileBadges } from '../../ui-shared/profile-badges'
import { ProfilePictureUpload } from '../../ui-shared/_core/profile-picture-upload'

import { ProfileIntroBlock } from './view-profile-sections/profile-intro-block'
import { ProfileSectionEnum } from './profile.types'
import { useProfileContext } from './profile-context'

type ProfileTopSectionProps = {
  isCentered: boolean
}

const TopContent = styled(Stack)({
  display: 'flex',
  alignItems: 'center',
})
const IntroWrapper = styled(Stack)<ProfileTopSectionProps>(({ isCentered }) => ({
  width: '100%',
  justifyContent: 'center',
  ...(isCentered && {
    alignItems: 'center',
  }),
}))
export function ProfileTopSection({ isCentered }: ProfileTopSectionProps) {
  const { profile, isOwnProfile, openEditProfileSection } = useProfileContext()
  const { authBody } = useAuthContext()
  const shouldShowProfileBadges = !profile.professional && Boolean(authBody)
  return (
    <TopContent direction={isCentered ? 'column' : 'row'} gap="5x">
      <div>
        <ProfilePictureUpload profilePicture={profile.profilePicture} userId={profile.uid} />
      </div>
      <IntroWrapper isCentered={isCentered}>
        <ProfileIntroBlock
          {...{ profile, isOwnProfile, isCentered }}
          onActivateEditMode={() => openEditProfileSection({ activeEditSection: ProfileSectionEnum.INTRO })}
        />
        <Spacer size="3x" />
        {shouldShowProfileBadges && <ProfileBadges shouldHideUnconfirmed={!isOwnProfile} profile={profile} />}
      </IntroWrapper>
    </TopContent>
  )
}
