import { Button, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { SuccessHeaderAnimated } from '../../../ui-shared/success-header-animated'
import type { HousingSituationStepProps } from '../housing-situation'

export function HousingSuccess({ goNext }: HousingSituationStepProps) {
  const { t } = useTranslation('edit_profile')
  return (
    <Stack gap="5x" alignItems="center">
      <SuccessHeaderAnimated
        title={t('form.housing_situation.success.title')}
        description={t('form.housing_situation.success.description')}
      />
      <Button type="button" onClick={() => goNext()}>
        {t('commons:close')}
      </Button>
    </Stack>
  )
}
