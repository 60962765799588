import { useState } from 'react'
import { IconButton, Stack, RadioGroup, MoreVerticalIcon } from '@qasa/qds-ui'
import { stringify } from 'query-string'
import { useTranslation } from 'react-i18next'
import { Dialog } from '@qasa/app/src/components/dialog'
import type { HomeSelectQuery } from '@qasa/graphql'

import { getPath } from '../../routing/get-path'
import { PresetRentalTypeEnum } from '../listing/listing-section-routes'
import { LinkButton } from '../../ui-shared/_core/link-button'
import { getFormattedAddress } from '../../helpers/address'

import { useSlashSeparatedData } from './find-tenant.utils'
import { HomeItem } from './home-item'

type HomeSelectDrawerProps = {
  selectedHome: HomeSelectQuery['homes']['edges'][number]['node']
  homes: HomeSelectQuery['homes']['edges']
  onSelect: ({ homeId }: { homeId: string }) => void
}

export function HomeSelectDialog({ homes, selectedHome, onSelect }: HomeSelectDrawerProps) {
  const { t } = useTranslation('find_tenant')
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleSelection = (homeId: string) => {
    onSelect({ homeId })
    setIsDialogOpen(false)
  }
  const formatHelperText = useSlashSeparatedData

  const listOfHomes = homes.filter(({ node }) => node.id !== selectedHome.id)

  const queryParams = stringify({ isFromFindTenant: 1, 'rental-type': PresetRentalTypeEnum.long_term })

  return (
    <Dialog
      isOpen={isDialogOpen}
      onOpenChange={setIsDialogOpen}
      trigger={
        <HomeItem
          home={selectedHome}
          actionElement={
            <IconButton label="View options" icon={MoreVerticalIcon} size="sm" variant="tertiary" />
          }
        />
      }
    >
      <Dialog.Header hasSidePadding>
        <Dialog.Title>{t('home_select_dialog.choose_listing')}</Dialog.Title>
      </Dialog.Header>
      <Dialog.Body>
        <RadioGroup defaultValue={selectedHome.id} onValueChange={handleSelection}>
          <RadioGroup.Label>{t('home_select_dialog.description')}</RadioGroup.Label>
          <Stack gap="2x">
            <RadioGroup.Card
              label={getFormattedAddress({
                route: selectedHome.location.route,
                streetNumber: selectedHome.location.streetNumber,
              })}
              helperText={formatHelperText({
                type: selectedHome.type,
                roomCount: selectedHome.roomCount,
                squareMeters: selectedHome.squareMeters,
              })}
              value={selectedHome.id}
            />
            {listOfHomes.map(({ node: { id, location, type, roomCount, squareMeters } }) => (
              <RadioGroup.Card
                key={id}
                label={getFormattedAddress({
                  route: location.route,
                  streetNumber: location.streetNumber,
                })}
                helperText={formatHelperText({ type, roomCount, squareMeters })}
                value={id}
              />
            ))}
          </Stack>
        </RadioGroup>
      </Dialog.Body>
      <Dialog.Footer>
        <LinkButton to={{ pathname: getPath('createListing'), search: queryParams }}>
          {t('home_select_dialog.create_new_home')}
        </LinkButton>
      </Dialog.Footer>
    </Dialog>
  )
}
