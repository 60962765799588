import { eachMonthOfInterval, format } from 'date-fns'
import type { UpsertOccupationInput, ProfileQueryQuery } from '@qasa/graphql'
import { OccupationTypeEnum, OccupationScheduleTypeEnum } from '@qasa/graphql'

import { getLocale } from '../../helpers/date'

import type { OccupationFormValues } from './use-occupation-form'

export const OCCUPATION_LIMIT = 6
const TEMPLATE_DEFAULT_OCCUPATION_VALUES = {
  id: null,
  title: '',
  subtitle: '',
  occupationScheduleType: null,
  occupationType: null,
  startedAt: null,
  endedAt: null,
}

type FormatYearMonthToDateParams = {
  year?: number | null
  month?: number | null
}
const formatYearMonthToDate = ({ year, month }: FormatYearMonthToDateParams) => {
  // Month can be zero, so we only check against null and undefined
  if (!year || month == null) return null

  const newDate = new Date(year, month, 1)
  const formattedEndedAt = format(newDate, 'yyyy-MM-dd')
  return formattedEndedAt
}

type FormatDateToYearMonthParams = {
  date?: string | null
}
const formatDateToYearMonth = ({ date }: FormatDateToYearMonthParams) => {
  if (!date) return { year: null, month: null }

  const newDate = new Date(date)
  const month = newDate.getMonth()
  const year = newDate.getFullYear()
  return { year, month }
}

export const getFormattedMonths = () => {
  const locale = getLocale()
  const firstMonthDate = new Date(2000, 0, 1)
  const lastMonthDate = new Date(2000, 11, 1)

  const allMonthInterval = eachMonthOfInterval({ start: firstMonthDate, end: lastMonthDate })
  return allMonthInterval.map((date) => {
    return format(date, 'MMMM', { locale })
  })
}

type GetParsedDefaultFormValuesParams = {
  occupation: NonNullable<NonNullable<ProfileQueryQuery['user']>['occupations']>[number] | null
}
export const getParsedDefaultFormValues = ({
  occupation,
}: GetParsedDefaultFormValuesParams): OccupationFormValues => {
  const { endedAt, id, occupationScheduleType, occupationType, startedAt, subtitle, title } = occupation ?? {}
  const startDate = formatDateToYearMonth({ date: startedAt })
  const endDate = formatDateToYearMonth({ date: endedAt })
  const occupationValues = {
    id: id || null,
    title: title || '',
    subtitle: subtitle || '',
    occupationScheduleType: occupationScheduleType || OccupationScheduleTypeEnum.full_time,
    occupationType: occupationType || null,
    startYear: startDate.year,
    startMonth: startDate.month,
    endYear: endDate.year,
    endMonth: endDate.month,
  }
  return occupationValues
}

type GetSerializedFormValuesParams = {
  formValues: OccupationFormValues
}
export const getSerializedFormValues = ({
  formValues,
}: GetSerializedFormValuesParams): UpsertOccupationInput => {
  const {
    endMonth,
    endYear,
    startMonth,
    startYear,
    occupationType,
    id,
    occupationScheduleType,
    subtitle,
    title,
  } = formValues
  const startedAt = formatYearMonthToDate({ year: startYear, month: startMonth })
  const endedAt = formatYearMonthToDate({ year: endYear, month: endMonth })
  /**
   * We reset the fields that is hidden depending on occupationType to default field value due to when a user edits
   * the saved field from one occupationType to another we want to remove the field data which isn't being used.
   */
  switch (occupationType) {
    case OccupationTypeEnum.retired:
      return { ...TEMPLATE_DEFAULT_OCCUPATION_VALUES, startedAt, id, occupationType }
    case OccupationTypeEnum.other:
      return {
        startedAt,
        endedAt,
        occupationType,
        id,
        occupationScheduleType,
        title,
        subtitle: TEMPLATE_DEFAULT_OCCUPATION_VALUES.subtitle,
      }
    case OccupationTypeEnum.work:
    case OccupationTypeEnum.student:
    default:
      return {
        startedAt,
        endedAt,
        occupationType,
        id,
        occupationScheduleType,
        subtitle,
        title,
      }
  }
}
