import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Fragment } from 'react'
import { Button, Stack } from '@qasa/qds-ui'

import { NavigationalLogo } from '../../ui-page-modules/top-nav-bar/components/navigational-logo'
import { AboutQasaDialog } from '../about-qasa-dialog'
import { LinkButtonV2 } from '../_core/link-button-v2'

import { Footer } from './wizard.parts'
import { WIZARD_CONTENT_MAX_WIDTH, TOP_BAR_CONTENT_MAX_WIDTH } from './constants'

const Wrapper = styled.div(({ theme }) => ({
  background: theme.colors.bg.default,
  minHeight: '100%',
}))
export const TopBarContainer = styled.div(({ theme }) => ({
  background: theme.colors.bg.default,
  borderBottom: '1px solid',
  borderColor: theme.colors.border.default,
  height: 64,
  display: 'flex',
  alignItems: 'center',
  padding: `0 ${theme.spacing['6x']}`,
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndices.docked,
}))
export const TopBar = styled.div<{ maxWidth?: number }>(({ maxWidth }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '0 auto',
  maxWidth: maxWidth ?? TOP_BAR_CONTENT_MAX_WIDTH,
  width: '100%',
}))
export const ContentContainer = styled.div(({ theme }) => ({
  maxWidth: WIZARD_CONTENT_MAX_WIDTH,
  minHeight: 500,
  margin: '0 auto',
  paddingTop: theme.spacing['12x'],
  paddingBottom: theme.spacing['12x'],
  paddingLeft: theme.spacing['6x'],
  paddingRight: theme.spacing['6x'],
}))

type WizardProps = {
  children: React.ReactNode
  closeLink?: string
  closeElement?: React.ReactNode
  shouldFillAvailableSpace?: boolean
  showAboutQasaButton?: boolean
  topBarMaxWidth?: number
}

function Wizard({
  children,
  closeLink,
  closeElement,
  shouldFillAvailableSpace,
  showAboutQasaButton,
  topBarMaxWidth,
}: WizardProps) {
  const { t } = useTranslation('commons')

  const isExternalLink = closeLink?.includes('http')

  const defaultCloseElement = isExternalLink ? (
    <Button as="a" href={closeLink} variant="tertiary" size="sm">
      {t('close')}
    </Button>
  ) : (
    <LinkButtonV2 variant="tertiary" size="sm" href={closeLink!}>
      {t('close')}
    </LinkButtonV2>
  )
  const Container = shouldFillAvailableSpace ? Fragment : ContentContainer
  return (
    <Wrapper>
      <TopBarContainer>
        <TopBar maxWidth={topBarMaxWidth}>
          <NavigationalLogo />
          <Stack direction="row" alignItems="center" gap="5x">
            {showAboutQasaButton && <AboutQasaDialog />}
            {closeElement ? closeElement : closeLink && defaultCloseElement}
          </Stack>
        </TopBar>
      </TopBarContainer>
      <Container>{children}</Container>
    </Wrapper>
  )
}

Wizard.Footer = Footer

export { Wizard }
