import { useTranslation } from 'react-i18next'
import { useRef } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Stack } from '@qasa/qds-ui'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { stringify } from 'query-string'
import { useToastContext, usePersistentStorage } from '@qasa/app'
import { Dialog } from '@qasa/app/src/components/dialog'
import { ampli } from '@qasa/ampli/p2'
import type { ProfileQueryQuery } from '@qasa/graphql'

import { useQueryParam } from '../../../hooks/use-query-param'
import { useAuthContext } from '../../../context/auth-context'
import { PROFILE_HOME_ID_PARAM_NAME } from '../profile'
import { getPath } from '../../../routing/get-path'
import { useAdminState } from '../../../data/admin-state'

import { CREATE_FIND_TENANT_LANDLORD_APPLICATION } from './create-find-tenant-landlord-application.gql'
import { ContactTenantCurrentStep } from './contact-tenant-current-step'

export type ContactTenantStep = 'preview' | 'verification' | 'success'
export const CONTACT_TENANT_STEP_PARAM_NAME = 'contactTenantStep'

export function ContactTenantDialog({ tenant }: { tenant: NonNullable<ProfileQueryQuery['user']> }) {
  const { t } = useTranslation('contact_tenant')
  const { firstName, uid } = tenant
  const { authBody } = useAuthContext()
  const { addToast } = useToastContext()
  const { state } = useLocation<{ previousPath?: string } | undefined>()
  const previousPath = state?.previousPath
  const tenantName = firstName || t('the_tenant')
  const { isAdminLoggedInAsUser } = useAdminState()
  const [homeId] = useQueryParam<string>({ paramName: PROFILE_HOME_ID_PARAM_NAME, defaultValue: '' })
  const [applicationMessage, setApplicationMessage] = usePersistentStorage<string | undefined>(
    `cachedLandlordApplication${uid}`,
  )

  const [contactTenantStep, setContactTenantStep, resetContactTenantStep] =
    useQueryParam<ContactTenantStep | null>({
      paramName: CONTACT_TENANT_STEP_PARAM_NAME,
      defaultValue: null,
    })
  const isPreviousPathFromFindTenant = Boolean(previousPath && previousPath.includes(getPath('findTenant')))
  const linkToFindTenant = isPreviousPathFromFindTenant
    ? previousPath
    : { pathname: getPath('findTenant'), search: stringify({ homeId }) }

  const onError = (message?: string) => {
    addToast({ message: message || t('application_error') })
    resetContactTenantStep()
  }

  const [sendApplication, { data: applicationData, loading: isSendApplicationLoading }] = useMutation(
    CREATE_FIND_TENANT_LANDLORD_APPLICATION,
    {
      variables: { input: { homeId, tenantUid: uid, message: applicationMessage } },
      update: (cache) => {
        cache.modify({
          fields: {
            tenantAds(cachedValue) {
              return cachedValue.INVALIDATE
            },
          },
        })
      },
      onCompleted: ({ createLandlordHomeApplication }) => {
        if (createLandlordHomeApplication?.homeApplication?.id) {
          setApplicationMessage(undefined)
          setContactTenantStep('success')
          ampli.landlordApplicationSent()
        } else {
          const error = createLandlordHomeApplication?.errors && createLandlordHomeApplication?.errors[0]
          if (error?.codes.includes('home.user.ongoing_application')) {
            onError(t('application_ongoing'))
          } else {
            onError()
          }
        }
      },
      onError: () => onError(),
      refetchQueries: ['ConversationForHome'],
    },
  )
  const conversationId = applicationData?.createLandlordHomeApplication?.homeApplication?.conversation?.id

  const isOpen = Boolean(contactTenantStep)

  const hasUserConfirmedId = Boolean(authBody?.currentIdentification?.identityConfirmed)
  const hasUserPhoneNumber = Boolean(authBody?.private.phoneNumber)

  const isUserVerified = hasUserConfirmedId && hasUserPhoneNumber
  const isUserAdminOrIdVerified = isAdminLoggedInAsUser || isUserVerified

  const steps = useRef([
    'preview',
    ...(isUserAdminOrIdVerified ? [] : ['verification']),
    'success',
  ] as ContactTenantStep[])
  let nextStep: ContactTenantStep | null = null

  if (contactTenantStep) {
    if (!steps.current.includes(contactTenantStep)) {
      setContactTenantStep('preview')
    }
    const currentIndex = steps.current.indexOf(contactTenantStep)
    nextStep = steps.current[currentIndex + 1]
  }

  const isNextButtonDisabled =
    (contactTenantStep === 'preview' && !homeId) ||
    (contactTenantStep === 'verification' && !isUserAdminOrIdVerified)
  const dialogTitle = contactTenantStep !== 'success' ? t('drawer_title', { tenantName }) : ''

  const handleNextClick = () => {
    if (nextStep === 'success') {
      sendApplication()
    } else {
      setContactTenantStep(nextStep)
    }
  }
  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      resetContactTenantStep()
    }
  }

  return (
    <Dialog isOpen={isOpen} onOpenChange={handleOpenChange}>
      <Dialog.Header hasSidePadding>
        {dialogTitle && <Dialog.Title>{dialogTitle}</Dialog.Title>}
      </Dialog.Header>
      <Dialog.Body>
        <ContactTenantCurrentStep
          contactTenantStep={contactTenantStep}
          tenantName={tenantName}
          homeId={homeId}
          conversationId={conversationId}
          applicationMessage={applicationMessage}
          setApplicationMessage={setApplicationMessage}
        />
      </Dialog.Body>
      <Dialog.Footer hasSidePadding>
        <>
          {nextStep && (
            <Button
              variant="primary"
              isLoading={isSendApplicationLoading}
              disabled={isNextButtonDisabled}
              onClick={handleNextClick}
            >
              {t('next_button')}
            </Button>
          )}
          {contactTenantStep === 'success' && (
            <Stack
              direction={{
                base: 'column-reverse',
                md: 'row',
              }}
              gap="2x"
            >
              <Button variant="tertiary" as={RouterLink} to={getPath('editListing', { homeId })}>
                {t('success.tell_us_more')}
              </Button>
              {linkToFindTenant && (
                <Button variant="primary" as={RouterLink} to={linkToFindTenant}>
                  {t('success.find_more_tenants')}
                </Button>
              )}
            </Stack>
          )}
        </>
      </Dialog.Footer>
    </Dialog>
  )
}
