import { keyframes } from '@emotion/react'
import { styled } from '@qasa/ui/web'

import type { SkeletonProps } from './skeleton.types'

const wave = keyframes`
  0% {
      background-position: -250px;
  }
  100% {
      background-position: calc(100% + 250px);
  }
`

export const Skeleton = styled('div')<SkeletonProps>(
  ({ width = '100%', height = '100%', radius = 'sm', theme }) => ({
    width,
    height,
    borderRadius: theme.radii[radius],
    backgroundColor: '#f0f0f0',
    marginBottom: '1rem',
    '&::before': {
      content: "''",
      display: 'block',
      width: '100%',
      height: '100%',
      borderRadius: '5px',
      background: `linear-gradient(
      90deg,
      #f0f0f0 0px,
      #f9f9f9 calc(50% - 25px),
      #f9f9f9 calc(50% + 25px),
      #f0f0f0 100%
    )`,
      backgroundSize: '35%',
      backgroundPosition: '0%',
      backgroundRepeat: 'no-repeat',
      animation: `${wave} 1.5s infinite linear`,
    },
  }),
)
