import { useTranslation } from 'react-i18next'

import { FieldEnum } from '../edit-listing.types'
import { useListingContext } from '../listing-context'

export const createNumberOptions = (maxNumber: number, shouldIncludeZero = false) => {
  return [
    ...(shouldIncludeZero ? [{ value: 0, label: '0' }] : []),
    ...Array.from({ length: maxNumber - 1 }, (_, index) => ({
      value: index + 1,
      label: (index + 1).toString(),
    })),
    { value: maxNumber, label: `${maxNumber} +` },
  ]
}

export enum InputFieldType {
  SELECT = 'select',
  TEXT = 'text',
  TEXT_RANGE = 'text_range',
  RANGE = 'range',
  BOOLEAN_RADIO = 'boolean_radio',
}

type Options = { value: number; label: string }[]

type SizeSectionInputField = {
  fieldEnum: FieldEnum
  inputType: InputFieldType
  isShown: boolean
  title: string
  value: boolean | number | null
  error: string | null
  range: { max: number | null; min: number | null }
  rangePayload: { max: string | null; min: string | null }
  unit: string | null
  placeholder: string | null
  options: Options
  info?: string
}

export const useGetAreasSectionFields = (): SizeSectionInputField[] => {
  const { t } = useTranslation('listing')
  const {
    store: {
      values: { hasKitchen, bedroomCount, bedCount, toiletCount },
    },
    getErrorMessage,
  } = useListingContext()

  return [
    {
      fieldEnum: FieldEnum.BedroomCount,
      inputType: InputFieldType.SELECT,
      isShown: true,
      title: 'size.bedroom_count_label',
      value: bedroomCount,
      error: getErrorMessage({ field: FieldEnum.BedroomCount }),
      range: { max: null, min: null },
      rangePayload: { max: null, min: null },
      unit: null,
      placeholder: 'size.bedroom_count_placeholder',
      options: createNumberOptions(15, true),
    },
    {
      fieldEnum: FieldEnum.BedCount,
      inputType: InputFieldType.SELECT,
      isShown: true,
      title: 'size.bed_count_label',
      value: bedCount,
      error: getErrorMessage({ field: FieldEnum.BedCount }),
      range: { max: null, min: null },
      rangePayload: { max: null, min: null },
      unit: null,
      placeholder: 'size.bed_count_placeholder',
      options: createNumberOptions(25, true),
    },
    {
      fieldEnum: FieldEnum.ToiletCount,
      inputType: InputFieldType.SELECT,
      isShown: true,
      title: 'size.toilet_count_label',
      value: toiletCount,
      error: getErrorMessage({ field: FieldEnum.ToiletCount }),
      range: { max: null, min: null },
      rangePayload: { max: null, min: null },
      unit: null,
      placeholder: 'size.toilet_count_placeholder',
      options: createNumberOptions(5, true),
    },
    {
      fieldEnum: FieldEnum.HasKitchen,
      inputType: InputFieldType.BOOLEAN_RADIO,
      isShown: true,
      title: 'size.kitchen_exists_label',
      value: hasKitchen,
      error: getErrorMessage({ field: FieldEnum.HasKitchen }),
      range: { max: null, min: null },
      rangePayload: { max: null, min: null },
      unit: null,
      placeholder: null,
      options: [
        { label: t('kitchen_availability.available'), value: 1 },
        { label: t('kitchen_availability.unavailable'), value: 0 },
      ],
    },
  ]
}
