import { feature, featureCollection, multiPolygon } from '@turf/helpers'
import isEqual from 'lodash/isEqual'
import bbox from '@turf/bbox'
import union from '@turf/union'
import type { MultiPolygon, Polygon } from 'geojson'
import { useEffect, useRef } from 'react'

const unionPolygons = (polygons: (Polygon | MultiPolygon)[]) => {
  return polygons.reduce((prev, current) => {
    return union(prev, current)!.geometry
  })
}

type PolygonBounds = [[number, number], [number, number]]

export const getPolygonBounds = ({ polygons }: { polygons: Polygon[] }): PolygonBounds => {
  const features = featureCollection(polygons.map((p) => feature(p)))
  const bounds = bbox(features)

  return [
    [bounds[0], bounds[1]],
    [bounds[2], bounds[3]],
  ]
}

export const invertPolygons = (polygons: Polygon[]) => {
  if (polygons.length === 0) return null

  const union = unionPolygons(polygons)
  const coords = union.type === 'MultiPolygon' ? union.coordinates.flat() : union.coordinates

  return multiPolygon([
    [
      [
        [-180, -90],
        [-180, 90],
        [180, 90],
        [180, -90],
        [-180, -90],
      ],
      ...coords,
    ],
  ])
}

export const useMemoedPolygons = ({ polygons }: { polygons: Polygon[] }) => {
  const previousPolygonsRef = useRef(polygons)
  const previousPolygons = previousPolygonsRef.current

  const isPolygonsEqual = isEqual(polygons, previousPolygons)

  useEffect(() => {
    if (!isPolygonsEqual) {
      previousPolygonsRef.current = polygons
    }
  })

  return isPolygonsEqual ? previousPolygons : polygons
}
