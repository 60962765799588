import isNumber from 'lodash/isNumber'

const roundToFives = (value: number) => {
  return Math.round(value / 5) * 5
}

const toNumber = (value?: number | string | null) => {
  if (!value) {
    return null
  }
  return parseInt(value.toString(), 10)
}

const convertDecimalsToPercentageWithTwoDecimals = (value: number) => {
  return Math.round(value * 10000) / 100
}

const convertDecimalsToPercentage = (value: number) => {
  return Math.round(value * 100)
}

export {
  isNumber,
  roundToFives,
  toNumber,
  convertDecimalsToPercentage,
  convertDecimalsToPercentageWithTwoDecimals,
}
