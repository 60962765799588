import { Paragraph, Stack, XCircleIcon, AlertCircleIcon, CheckCircleIcon } from '@qasa/ui'
import type { PropsWithChildren } from 'react'

import { useAppTranslation } from '../../contexts/i18next'

const IndicatorText = Paragraph

function Wrapper({ children }: PropsWithChildren) {
  return (
    <Stack direction={'row'} gap={'2x'} alignItems={'center'}>
      {children}
    </Stack>
  )
}

export type SearchPreferenceMatchIndicatorProps = {
  matchingLevel: 'match' | 'no_match' | 'is_close'
  differenceString?: string
  importanceLevel?: 'high' | 'low'
}
export function SearchPreferenceMatchIndicator({
  matchingLevel,
  differenceString,
  importanceLevel = 'low',
}: SearchPreferenceMatchIndicatorProps) {
  const { t } = useAppTranslation('search_preference_match_indicator')

  const isMatching = matchingLevel === 'match'
  if (!isMatching && importanceLevel === 'low') {
    return null
  }
  return isMatching ? (
    <Wrapper>
      <CheckCircleIcon color={'positive'} size={16} strokeWidth={1.5} />
      <IndicatorText size={'sm'}>{t('match')}</IndicatorText>
    </Wrapper>
  ) : (
    <Wrapper>
      {matchingLevel === 'is_close' ? (
        <AlertCircleIcon color={'warning'} size={16} strokeWidth={1.5} />
      ) : (
        <XCircleIcon color={'negative'} size={16} strokeWidth={1.5} />
      )}
      <IndicatorText size={'sm'}>{differenceString || t(matchingLevel)}</IndicatorText>
    </Wrapper>
  )
}
