import { IconButton, Paragraph, Stack, MapPinIcon, HomeIcon, createLucideIcon } from '@qasa/qds-ui'
import { useState } from 'react'
import { Dialog } from '@qasa/app/src/components/dialog'
import { Info, WashingMachine } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '@qasa/app/src/utils/number'

import { Amenity } from '../home/amenities'

import { useListingContext } from './listing-context'
import { RECOMMENDED_RENT_ERROR_RATIO } from './listing.constants'

const InfoIcon = createLucideIcon(Info)
const WashingMachineIcon = createLucideIcon(WashingMachine)

export function RecommendedRentDialog() {
  const { t } = useTranslation('listing')
  const [isOpen, setIsOpen] = useState(false)
  const {
    store: {
      values: { recommendedRentNew, currency },
    },
  } = useListingContext()

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={(newValue) => setIsOpen(newValue)}
      trigger={<IconButton size={'xs'} label={t('recommended_rent_dialog.button_label')} icon={InfoIcon} />}
    >
      <Dialog.Header hasSidePadding>
        <Dialog.Title>{t('recommended_rent_dialog.title')}</Dialog.Title>
      </Dialog.Header>
      <Dialog.Body>
        <Stack gap="6x">
          <Paragraph>{t('recommended_rent_dialog.description')}</Paragraph>
          {recommendedRentNew && (
            <Paragraph>
              {t('recommended_rent_dialog.span_information', {
                expectedMinimumRent: formatNumber({
                  amount: recommendedRentNew - RECOMMENDED_RENT_ERROR_RATIO * recommendedRentNew,
                  currency,
                }),
                expectedMaximumRent: formatNumber({
                  amount: recommendedRentNew + RECOMMENDED_RENT_ERROR_RATIO * recommendedRentNew,
                  currency,
                }),
              })}
            </Paragraph>
          )}
          <Paragraph>{t('recommended_rent_dialog.influencers.heading')}</Paragraph>
          <Stack as={'ul'} direction={'row'} wrap={'wrap'} gap={'6x'}>
            <Amenity isAvailable title={t('recommended_rent_dialog.influencers.size_and_room_count')}>
              <HomeIcon size={20} />
            </Amenity>
            <Amenity isAvailable title={t('recommended_rent_dialog.influencers.location')}>
              <MapPinIcon size={20} />
            </Amenity>
            <Amenity isAvailable title={t('recommended_rent_dialog.influencers.other_amenities')}>
              <WashingMachineIcon size={20} />
            </Amenity>
          </Stack>
        </Stack>
      </Dialog.Body>
    </Dialog>
  )
}
