import type { MarketNameEnum } from '@qasa/graphql'
import uniq from 'lodash/uniq'
import { HomeRentalTypeEnum } from '@qasa/graphql'

import { BRAND_CONFIG } from './brand-configurations'
import { MARKET_COUNTRY_CODES } from './market-country-codes'

/**
 * When qasa.com reigns supreme, these utils and their underlying config in BRAND_CONFIG
 * will probably be replaced by config fetched from BE
 */

export const availableMarkets = Object.keys(BRAND_CONFIG.availableMarkets) as MarketNameEnum[]

export const getAvailableVacationMarkets = () => {
  const allVacationMarketsForBrand = Object.entries(BRAND_CONFIG.availableMarkets)
    .filter(([, { availableRentalTypes }]) => {
      return availableRentalTypes.includes(HomeRentalTypeEnum.vacation)
    })
    .map(([marketName]) => marketName as MarketNameEnum)
  return allVacationMarketsForBrand
}

export const getAvailableCountryCodesForRentalType = ({ rentalType }: { rentalType: HomeRentalTypeEnum }) => {
  const countriesThatHaveRentalType = Object.entries(BRAND_CONFIG.availableMarkets)
    .filter(([, { availableRentalTypes }]) => {
      return availableRentalTypes.includes(rentalType)
    })
    .map(([marketName]) => MARKET_COUNTRY_CODES[marketName as MarketNameEnum])
  return countriesThatHaveRentalType
}

/**
 * A list of unique country codes that the available markets are located in
 */
export const availableMarketCountries = uniq(
  Object.entries(BRAND_CONFIG.availableMarkets).map(
    ([marketName]) => MARKET_COUNTRY_CODES[marketName as MarketNameEnum],
  ),
)

/**
 * A list of all rental types available on the available markets
 */
export const availableRentalTypes = Object.values(BRAND_CONFIG.availableMarkets).reduce(
  (availableTypes, marketConfig) => {
    marketConfig.availableRentalTypes.forEach((rentalType) => {
      if (!availableTypes.includes(rentalType)) {
        availableTypes.push(rentalType)
      }
    })
    return availableTypes
  },
  [] as HomeRentalTypeEnum[],
)
