import { graphql } from '@qasa/graphql'

export const SEND_SIGN_IN_CODE = graphql(`
  mutation SendSignInCode($email: String!, $platform: PlatformEnum!) {
    sendSignInCode(email: $email, platform: $platform) {
      success
    }
  }
`)

export const EXCHANGE_SIGN_IN_CODE = graphql(`
  mutation ExchangeSignInCode($code: String!) {
    exchangeSignInCode(code: $code) {
      accessToken
      errors {
        codes
      }
    }
  }
`)
