import { useCombobox } from 'downshift'
import includes from 'lodash/includes'
import { useEffect } from 'react'
import type { CreateLocationInput } from '@qasa/graphql'

import type { ListingPosition } from '../edit-listing.types'
import type { UseGoogleAutocompleteSuggestionsParams } from '../listing.utils'
import { useFetchLocationFromGooglePlaceId, useGoogleAutocompleteSuggestions } from '../listing.utils'

type UseGoogleAutocompleteParams = {
  formattedAddress: string | null
  handleInputValueChange: (value?: string) => void
  handleSelectedItemChange: (detailedLocation: google.maps.places.PlaceResult | null) => void
}
type ParseResponseParams = {
  location: google.maps.places.PlaceResult
  includePlaceId?: boolean
  includeShortName?: boolean
}
function parseGoogleAutocompleteResponse({
  location,
  includePlaceId = false,
  includeShortName = false,
}: ParseResponseParams) {
  const parsedLocation: Partial<CreateLocationInput> & { position: ListingPosition } = {
    formattedAddress: location.formatted_address,
    position: { latitude: location.geometry?.location?.lat(), longitude: location.geometry?.location?.lng() },
    ...(includePlaceId && { placeId: location.place_id }),
  }

  location.address_components?.forEach((ac) => {
    if (includes(ac.types, 'route')) {
      parsedLocation.route = ac.long_name
    } else if (includes(ac.types, 'street_number')) {
      parsedLocation.streetNumber = ac.long_name
    } else if (includes(ac.types, 'postal_code')) {
      parsedLocation.postalCode = ac.long_name
    } else if (includes(ac.types, 'locality') || includes(ac.types, 'postal_town')) {
      parsedLocation.locality = ac.long_name
    } else if (includes(ac.types, 'country')) {
      parsedLocation.country = ac.long_name
      parsedLocation.countryCode = ac.short_name
    }
  })
  if (includeShortName) {
    parsedLocation.shortName = `${parsedLocation.route ?? ''}, ${parsedLocation.locality}`
  }

  return parsedLocation
}

export const useGoogleAutocomplete = ({
  formattedAddress,
  handleInputValueChange,
  handleSelectedItemChange,
  shouldRestrictToSupportedCountries,
  allowedCountries,
}: UseGoogleAutocompleteParams & UseGoogleAutocompleteSuggestionsParams) => {
  const {
    isLoading: isFetchingSuggestions,
    locationSuggestions,
    fetchLocationSuggestions,
  } = useGoogleAutocompleteSuggestions({ shouldRestrictToSupportedCountries, allowedCountries })
  const { fetchLocationFromGooglePlaceId } = useFetchLocationFromGooglePlaceId()

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
    openMenu,
  } = useCombobox<google.maps.places.QueryAutocompletePrediction>({
    items: locationSuggestions,
    onInputValueChange: ({ inputValue }) => handleInputValueChange(inputValue),
    onSelectedItemChange: async ({ selectedItem }) => {
      if (selectedItem) {
        const detailedLocation = await fetchLocationFromGooglePlaceId({ placeId: selectedItem?.place_id })
        handleSelectedItemChange(detailedLocation)
      }
    },
    itemToString: (location: google.maps.places.QueryAutocompletePrediction | null) => {
      if (!location) return ''
      return location.description
    },
  })

  useEffect(() => {
    if (formattedAddress) {
      fetchLocationSuggestions(formattedAddress)
    }
  }, [formattedAddress, fetchLocationSuggestions])

  return {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
    openMenu,
    isFetchingSuggestions,
    locationSuggestions,
    parseGoogleAutocompleteResponse,
  }
}
