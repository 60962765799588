import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { stringify } from 'query-string'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { ArrowRightIcon, Heading, IconButton, LoadingDots, Paragraph, Stack } from '@qasa/qds-ui'
import { Link } from 'react-router-dom'
import { HomeSearchOrderByEnum, HomeSearchOrderEnum, HomeStatusEnum } from '@qasa/graphql'

import { useAuthContext } from '../../context/auth-context'
import { getPath } from '../../routing/get-path'
import { PresetRentalTypeEnum } from '../listing/listing-section-routes'

import { HomeSelectDialog } from './home-select-dialog'
import { HOME_SELECT } from './home-select.gql'

const CreateHomeLink = styled(Link)(({ theme }) => ({
  width: '100%',
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing['4x'],
  flexShrink: 1,
  padding: theme.spacing['4x'],
  border: '1px solid',
  borderColor: theme.colors.border.default,
  borderRadius: theme.radii.md,
  [theme.mediaQueries.mdUp]: {
    width: theme.sizes[448],
  },
}))

type HomeSelectProps = {
  selectedHomeId: string | null
  onSelect: ({ homeId }: { homeId: string }) => void
  resetHomeId: () => void
}

export function HomeSelect({ selectedHomeId, onSelect, resetHomeId }: HomeSelectProps) {
  const { isAuthenticated } = useAuthContext()
  const { t } = useTranslation('find_tenant')
  const [hasRefetched, setHasRefetched] = useState<boolean>(false)

  const {
    data,
    loading: isLoading,
    refetch,
  } = useQuery(HOME_SELECT, {
    variables: {
      orderBy: HomeSearchOrderByEnum.MOVE_IN,
      order: HomeSearchOrderEnum.DESCENDING,
      homeStatuses: [HomeStatusEnum.normal],
    },
    skip: !isAuthenticated,
  })

  const homes = data?.homes.edges.filter(({ node: { rent } }) => Boolean(rent))
  const selectedHome = homes?.find(({ node }) => node.id === selectedHomeId)?.node
  useEffect(() => {
    const fetchAgain = async () => {
      await refetch()
      setHasRefetched(true)
    }
    if (homes) {
      if (!selectedHomeId && homes.length > 0) {
        onSelect({ homeId: homes[0].node.id })
      }
      if (selectedHomeId && !selectedHome) {
        if (!hasRefetched) {
          // To avoid newly created homes triggering a reset, we need to first try a refetch before resetting the id -- @magnuslh
          fetchAgain()
        } else {
          resetHomeId()
        }
      }
    }
    if (!isAuthenticated && selectedHomeId) {
      resetHomeId()
    }
  }, [selectedHomeId, homes, onSelect, resetHomeId, refetch, isAuthenticated, selectedHome, hasRefetched])

  if (isLoading || !homes || !selectedHome) {
    return (
      <CreateHomeLink
        to={{
          pathname: getPath('createListing'),
          search: stringify({ isFromFindTenant: 1, 'rental-type': PresetRentalTypeEnum.long_term }),
        }}
      >
        {isLoading ? (
          <LoadingDots size="sm" />
        ) : (
          <>
            <Stack gap="1x">
              <Heading as="p" size="2xs">
                {t('create_home.heading')}
              </Heading>
              <Paragraph>{t('create_home.description')}</Paragraph>
            </Stack>
            <IconButton label="" icon={ArrowRightIcon} variant="tertiary" />
          </>
        )}
      </CreateHomeLink>
    )
  }

  return <HomeSelectDialog {...{ onSelect, selectedHome, homes }} />
}
