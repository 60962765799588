import type { SearchAreaKeywordQuery } from '@qasa/graphql'
import omit from 'lodash/omit'

import type { LocationIqResponse } from './use-area-suggestions.types'

const getCityFromResponse = ({ area }: { area: LocationIqResponse }) => {
  switch (area.type) {
    case 'city':
      return area.address.state || ''
    case 'suburb':
      return area.address.city
    case 'administrative':
      return area.address.city || area.address.suburb || ''
    default:
      return area.address.suburb || area.address.city || area.address.state
  }
}
export const normalizeResult = ({ areas }: { areas: LocationIqResponse[] }) => {
  const filteredAreas = areas.filter((area) => Boolean(area.address.name))

  return filteredAreas.map((area) => {
    return {
      osmId: area.osm_id,
      name: area.address.name!,
      city: getCityFromResponse({ area }) || null,
      countryCode: area.address.country_code ?? null,
    }
  })
}

type MergeAreaSuggestionsParams = {
  locationIqSuggestions: { osmId: string; name: string; city: string | null; countryCode: string | null }[]
  customSuggestions?: SearchAreaKeywordQuery['searchAreaKeyword']
}
export const mergeAreaSuggestions = ({
  locationIqSuggestions,
  customSuggestions,
}: MergeAreaSuggestionsParams) => {
  if (customSuggestions && customSuggestions.length > 0) {
    const newCustomSuggestionsArray = customSuggestions.map((suggestion) => ({
      ...omit(suggestion, '__typename'),
    }))
    return newCustomSuggestionsArray.concat(locationIqSuggestions)
  } else {
    return locationIqSuggestions
  }
}
