import { Heading, Stack } from '@qasa/qds-ui'
import type { LinkProps } from 'react-router-dom'
import { styled } from '@qasa/ui/web'
import { Link as RouterLink } from 'react-router-dom'
import { HomeRentalTypeEnum, graphql } from '@qasa/graphql'
import type { MapHomeCardFragment } from '@qasa/graphql'
import type { StaticImageData } from 'next/image'

import homePlaceholderImg from '../../../assets/home-placeholder.jpg'
import { getPrimaryUpload, prependSrcWithThumbor } from '../../../utils/image'
import { Duration } from '../../../components/duration'
import { useEmbeddedFindHome } from '../hooks/use-embedded-find-home.web'
import { DotcomHomeLink } from '../dotcom-home-link'
import { useRegionContext } from '../../../contexts/region'
import { getFormattedAddress, getFormattedMonthlyCost } from '../../../utils/home'
import { useFormattedPricePerNight } from '../find-home.utils'
import { FavoriteHomeButton } from '../../../modules/favorite-home-button'

import { HomeGuestsBedroomInfo } from './home-guests-bedroom-info.web'
import { HomeTypeSize } from './home-type-size.web'
import { POPUP_MOBILE_HEIGHT, useIsDraggingOnClick } from './map.utils.web'

const StyledRouterLink = styled(RouterLink)({
  display: 'flex',
  height: '100%',
})
const StyledDotcomHomeLink = styled(DotcomHomeLink)({
  display: 'flex',
  height: '100%',
})
const CardContentWrapper = styled(Stack)({
  userSelect: 'none',
  flex: 1,
})
const Body = styled(Stack)(({ theme }) => ({
  gap: theme.spacing['1x'],
  paddingLeft: theme.spacing['4x'],
  paddingBlock: theme.spacing['2x'],
  overflow: 'hidden',
  [theme.mediaQueries.mdUp]: {
    paddingInline: theme.spacing['4x'],
    paddingTop: theme.spacing['3x'],
  },
}))

const Image = styled('div')<{ image: string; isPlaceholder: boolean }>(({ theme, image, isPlaceholder }) => ({
  width: POPUP_MOBILE_HEIGHT,
  position: 'relative',
  background: theme.colors.core.gray20,
  backgroundImage: `url("${image}")`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  flexShrink: 0,
  borderRight: `1px solid ${theme.colors.border.subtle}`,

  [theme.mediaQueries.mdUp]: {
    width: '100%',
    height: 160,
    borderRight: 'unset',
    ...(isPlaceholder && {
      borderBottom: `1px solid ${theme.colors.border.default}`,
    }),
  },
}))

const FavoriteButtonWrapper = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing['2x'],
  right: theme.spacing['2x'],
}))

graphql(`
  fragment MapHomeCard on Home {
    id
    uploads {
      id
      url
      metadata {
        order
        primary
      }
    }
    duration {
      id
      startOptimal
      endOptimal
      startAsap
      endUfn
    }
    roomCount
    squareMeters
    displayStreetNumber
    location {
      id
      route
      locality
      streetNumber
      countryCode
    }
    minimumPricePerNight
    maximumPricePerNight
    averagePricePerNight
    rent
    tenantBaseFee
    minRent
    maxRent
    numberOfHomes
    currency
    type
    title
    rentalType
    tenantCount
    bedroomCount
    bedCount
    ...FavoriteHomeButton
  }
`)

type HomeCardProps = {
  to: LinkProps['to']
  home: MapHomeCardFragment
}
export function MapHomeCard({ to, home }: HomeCardProps) {
  const { region } = useRegionContext()
  const { dragProps, isDragging } = useIsDraggingOnClick()
  const {
    uploads,
    duration,
    roomCount,
    squareMeters,
    location,
    type,
    title,
    rentalType,
    tenantCount,
    bedroomCount,
    bedCount,
    displayStreetNumber: shouldDisplayStreetNumber,
  } = home
  const isFindHomeEmbedded = useEmbeddedFindHome()
  const isVacationHome = rentalType === HomeRentalTypeEnum.vacation
  const primaryUpload = getPrimaryUpload({ uploads })?.url
  const hasImage = Boolean(uploads.length) && primaryUpload
  const image = hasImage
    ? prependSrcWithThumbor({
        src: primaryUpload,
        width: 280,
        height: 160,
        fittingMethod: 'crop',
      })
    : (homePlaceholderImg as StaticImageData).src

  const formattedAddress = getFormattedAddress({
    location,
    shouldDisplayStreetNumber,
  })

  const pricePerNight = useFormattedPricePerNight({ home })
  const monthlyCost = getFormattedMonthlyCost({ home })

  const formattedPrice = isVacationHome ? pricePerNight : monthlyCost

  const HomeCardContent = (
    <CardContentWrapper direction={{ base: 'row', md: 'column' }}>
      <Image isPlaceholder={!hasImage} image={image} draggable={false} />
      <FavoriteButtonWrapper onClick={(e) => e.preventDefault()}>
        <FavoriteHomeButton home={home} />
      </FavoriteButtonWrapper>
      <Body>
        <Heading size="2xs" numberOfLines={1}>
          {title ?? formattedAddress}
        </Heading>
        {isVacationHome ? (
          <HomeGuestsBedroomInfo tenantCount={tenantCount} bedroomCount={bedroomCount} bedCount={bedCount} />
        ) : (
          <>
            <HomeTypeSize {...{ type, roomCount, squareMeters }} textSize="sm" />
            <Duration duration={duration!} size="sm" />
          </>
        )}
        {formattedPrice && <Heading size="3xs">{formattedPrice}</Heading>}
      </Body>
    </CardContentWrapper>
  )

  if (region === 'international') {
    return (
      <StyledDotcomHomeLink
        {...dragProps}
        homeId={home.id}
        homeLocale={home.location.countryCode}
        onClick={(event) => {
          if (isDragging) event.preventDefault()
        }}
        draggable={false}
        target={isFindHomeEmbedded ? '_blank' : '_self'}
      >
        {HomeCardContent}
      </StyledDotcomHomeLink>
    )
  }
  return (
    <StyledRouterLink
      {...dragProps}
      onClick={(event) => {
        if (isDragging) event.preventDefault()
      }}
      draggable="false"
      to={to}
      target={isFindHomeEmbedded ? '_blank' : '_self'}
    >
      {HomeCardContent}
    </StyledRouterLink>
  )
}
