import styled from '@emotion/styled'
import type { ReactNode } from 'react'
import { useBreakpointValue } from '@qasa/qds-ui'

const WIZARD_FOOTER_HEIGHT = 72

const FloatingFooter = styled.footer(({ theme }) => ({
  background: theme.colors.bg.default,
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  minHeight: WIZARD_FOOTER_HEIGHT,
  paddingBottom: theme.spacing['4x'],
  paddingTop: theme.spacing['4x'],
  paddingLeft: theme.spacing['6x'],
  paddingRight: theme.spacing['6x'],
  borderTop: `1px solid ${theme.colors.border.subtle}`,
  boxShadow: theme.shadows.lg,
}))
/* Make sure footer doesn't cover the content when the page overflows */
const FooterSpacer = styled.div({
  marginTop: WIZARD_FOOTER_HEIGHT,
})

type FooterProps = {
  children: ReactNode
  isFixedToBottom?: boolean
}
export function Footer({ children, isFixedToBottom }: FooterProps) {
  const isMdOrAbove = useBreakpointValue({ base: false, md: true }, { ssr: false })

  const shouldBeFixedToBottom = isFixedToBottom ?? !isMdOrAbove

  if (shouldBeFixedToBottom) {
    return (
      <>
        <FooterSpacer />
        <FloatingFooter>{children}</FloatingFooter>
      </>
    )
  }

  return <footer>{children}</footer>
}
