type GetIndicatorOffsetParams = {
  currentIndex: number
  count: number
}

/**
 * handles cases of > 5 items in the carousel (only 5 dots are shown at a time)
 * in such cases we want to add an offset when user moves beyond the 3rd item to make sure the dot of the
 * currently visible item is visible
 */
export const getIndicatorOffset = ({ currentIndex, count }: GetIndicatorOffsetParams) => {
  if (currentIndex < 3 || count <= 5) {
    return 0
  }
  // NOTE: 16 is the amount needed to offset the progress indicator by one dot (dot + spacing around it)
  return Math.min(currentIndex - 2, count - 5) * 16
}
