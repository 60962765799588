import { useTranslation } from 'react-i18next'
import { CheckIcon, Stack } from '@qasa/qds-ui'
import { SearchPreferenceMatchIndicator } from '@qasa/app/src/components/search-preference-match-indicator'
import { SearchPreferenceListItem } from '@qasa/app/src/components/search-preference-list-item'

import { AmenityGrid } from '../components/amenity-grid'
import { useProfileContext } from '../profile-context'
import { ProfileSectionHeader } from '../profile-section-header'
import { ProfileSectionEnum } from '../profile.types'

import { EmptyField } from './empty-field'

export function ProfileRequiredAmenitiesBlock() {
  const { isOwnProfile, openEditProfileSection, parsedSearchPreference, searchPreferenceMatchData } =
    useProfileContext()
  const { t } = useTranslation('profile')

  const { requiredAmenities } = parsedSearchPreference
  const hasNoSelectedAmenities = requiredAmenities.length === 0

  return (
    <Stack gap="3x">
      <ProfileSectionHeader
        title={t(`sections_title.${ProfileSectionEnum.REQUIRED_AMENITIES}`)}
        isOwnProfile={isOwnProfile}
        onEditButtonClick={() =>
          openEditProfileSection({ activeEditSection: ProfileSectionEnum.REQUIRED_AMENITIES })
        }
      />
      {hasNoSelectedAmenities ? (
        <EmptyField />
      ) : (
        <AmenityGrid>
          {Object.values(requiredAmenities).map((requirement) => (
            <SearchPreferenceListItem
              key={requirement}
              value={t(`create_tenant_listing:fields.requirements.checkbox_options.${requirement}`)}
              matchingIndicator={
                searchPreferenceMatchData && (
                  <SearchPreferenceMatchIndicator
                    matchingLevel={
                      searchPreferenceMatchData.requiredAmenities?.includes(requirement)
                        ? 'match'
                        : 'no_match'
                    }
                    importanceLevel={'high'}
                  />
                )
              }
            >
              <CheckIcon size={20} />
            </SearchPreferenceListItem>
          ))}
        </AmenityGrid>
      )}
    </Stack>
  )
}
