import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const wave = keyframes`
  0% {
      background-position: -250px;
  }
  100% {
      background-position: calc(100% + 250px);
  }
`
export const SkeletonPulse = styled.div`
  width: 100%;
  height: 200px;
  background-color: rgb(240, 240, 240);
  border-radius: 5px;
  margin-bottom: 1rem;
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: linear-gradient(
      90deg,
      rgb(240, 240, 240) 0px,
      #f9f9f9 calc(50% - 25px),
      #f9f9f9 calc(50% + 25px),
      rgb(240, 240, 240) 100%
    );
    background-size: 35%;
    background-position: 0%;
    background-repeat: no-repeat;
    animation: ${wave} 1.5s infinite linear;
  }
`
