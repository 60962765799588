import { useTranslation } from 'react-i18next'
import { ArrowLeftIcon, Button, IconButton, Stack } from '@qasa/qds-ui'

import { Wizard } from '../../../ui-shared/wizard'
import { useWizardRouterContext } from '../wizard-router/wizard-router'

type StepNavigationProps = {
  isStepOptional?: boolean
  isDisabled?: boolean
  shouldGoNextWithoutSubmit?: boolean
}
export function StepNavigation({
  isStepOptional = false,
  isDisabled = false,
  shouldGoNextWithoutSubmit,
}: StepNavigationProps) {
  const { t } = useTranslation('commons')
  const { goNext, goBack } = useWizardRouterContext()

  const handleNextClick = () => {
    shouldGoNextWithoutSubmit && goNext()
  }

  return (
    <Wizard.Footer>
      <Stack
        // `row-reverse` makes it so the right-most button will have the lowest tabindex
        direction="row-reverse"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          type="submit"
          variant={isStepOptional ? 'tertiary' : 'primary'}
          onClick={handleNextClick}
          isDisabled={isDisabled}
        >
          {isStepOptional ? t('skip') : t('next')}
        </Button>
        <IconButton variant="tertiary" icon={ArrowLeftIcon} label={t('back')} onClick={goBack} />
      </Stack>
    </Wizard.Footer>
  )
}
