import styled from '@emotion/styled'
import { Check } from 'lucide-react'

type SizeProp = {
  size?: 'small' | 'medium'
}
type CheckboxIndicatorProps = {
  isChecked: boolean
  isFocusVisible?: boolean
} & SizeProp

const CheckMarkContainer = styled.span<CheckboxIndicatorProps>(
  ({ theme, isChecked, size, isFocusVisible }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    flexShrink: 0,
    border: '1px solid',
    backgroundColor: theme.colors.bg.default,
    borderColor: theme.colors.border.default,
    borderRadius: 4,
    color: 'transparent',

    ...(isChecked && {
      backgroundColor: theme.colors.border.defaultSelected,
      borderColor: theme.colors.border.defaultSelected,
      color: theme.colors.core.white,
    }),
    transitionProperty: 'border, background-color, color, outline-offset',
    transitionDuration: '120ms',
    transitionTimingFunction: 'ease-in-out',
    outlineStyle: isFocusVisible ? 'solid' : 'none',
    outlineWidth: 2,
    outlineOffset: isFocusVisible ? 2 : 0,
    outlineColor: theme.colors.border.defaultSelected,
    ...(size === 'small' && {
      width: 16,
      height: 16,
    }),
    /**
     * Make tap area larget without affecting
     * its actual DOM size
     */
    ':before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 32,
      height: 32,
      transform: 'translate(-50%, -50%)',
    },
  }),
)

export function CheckboxIndicator({
  isChecked,
  size = 'medium',
  isFocusVisible = false,
}: CheckboxIndicatorProps) {
  return (
    <CheckMarkContainer aria-hidden="true" isChecked={isChecked} size={size} isFocusVisible={isFocusVisible}>
      <Check size={size === 'medium' ? 16 : 12} absoluteStrokeWidth color="currentColor" />
    </CheckMarkContainer>
  )
}
