import { styled } from '@qasa/ui/web'
import { LoadingDots } from '@qasa/qds-ui'

const Wrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 12,
  left: '50%',
  transform: 'translateX(-50%)',
  background: 'white',
  boxShadow: theme.shadows.lg,
  borderRadius: theme.radii.lg,

  height: 32,
  paddingLeft: 12,
  paddingRight: 12,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

type MapLoaderProps = { isLoading: boolean }
export function MapLoader({ isLoading }: MapLoaderProps) {
  if (!isLoading) return null

  return (
    <Wrapper>
      <LoadingDots size="sm" />
    </Wrapper>
  )
}
