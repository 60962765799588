import { graphql } from '@qasa/graphql'

export const NewUploadRefFragment = graphql(/* GraphQL */ `
  fragment NewUploadRef on Upload {
    id
    metadata {
      order
      primary
      rotation
    }
  }
`)
