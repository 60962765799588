import { Label, Stack, Paragraph } from '@qasa/qds-ui'
import { Textarea } from '@qasa/ui'
import { useTranslation } from 'react-i18next'
import { possiblePhoneNumberOrEmailOrUrl } from '@qasa/app/src/utils/regex'

import { useQueryParam } from '../../../hooks/use-query-param'
import { HomeSelect } from '../../find-tenant/home-select'

import type { CurrentStepProps } from './contact-tenant-current-step'

type ContactTenantHomePreviewProps = Pick<CurrentStepProps, 'applicationMessage' | 'setApplicationMessage'>
export function ContactTenantHomePreview({
  applicationMessage = '',
  setApplicationMessage,
}: ContactTenantHomePreviewProps) {
  const { t } = useTranslation('contact_tenant')
  const [homeIdAsNumber, setHomeId, resetHomeId] = useQueryParam<string | null>({
    paramName: 'homeId',
    defaultValue: null,
  })

  const homeId = homeIdAsNumber ? homeIdAsNumber.toString() : null

  const hasPhoneNumberOrMailOrUrlInMessage = possiblePhoneNumberOrEmailOrUrl.test(applicationMessage)

  return (
    <Stack gap={'4x'}>
      <Stack gap="2x">
        <Label as={'p'}>{t('home_preview.label')}</Label>
        <HomeSelect
          selectedHomeId={homeId}
          onSelect={({ homeId }) => setHomeId(homeId)}
          resetHomeId={resetHomeId}
        />
      </Stack>
      <Stack gap="2x">
        {homeId && (
          <Textarea
            label={t('introductory_message.label')}
            placeholder={t('introductory_message.example_message')}
            value={applicationMessage}
            onChange={(value) => setApplicationMessage?.(value)}
            inputState={hasPhoneNumberOrMailOrUrlInMessage ? 'warning' : 'default'}
          />
        )}
        {hasPhoneNumberOrMailOrUrlInMessage && (
          <Paragraph size="sm" color="warning">
            {t('email_phone_url_warning')}
          </Paragraph>
        )}
      </Stack>
    </Stack>
  )
}
