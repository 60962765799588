import { Label, createStyleVariants } from '@qasa/qds-ui'
import styled from '@emotion/styled'

export type StatusTagColor = 'gray' | 'green' | 'yellow' | 'red' | 'pink'

const getColorVariant = createStyleVariants((theme) => {
  return {
    gray: { backgroundColor: theme.colors.core.gray30 },
    green: { backgroundColor: theme.colors.core.green20 },
    yellow: { backgroundColor: theme.colors.core.yellow20 },
    red: { backgroundColor: theme.colors.core.red20 },
    pink: { backgroundColor: theme.colors.core.softPink },
  }
})
const Tag = styled(Label)<{ tagColor: StatusTagColor }>(({ theme, tagColor }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingInline: theme.spacing['3x'],
  height: theme.sizes['8x'],
  borderRadius: theme.radii.sm,
  ...getColorVariant(theme)[tagColor],
}))

type StatusTagProps = {
  tagColor: StatusTagColor
  children: React.ReactNode
}

export function StatusTag({ tagColor, children }: StatusTagProps) {
  return (
    <Tag tagColor={tagColor} size="sm">
      {children}
    </Tag>
  )
}
