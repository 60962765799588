import type { ReactNode } from 'react'
import { useContext, createContext } from 'react'

export type Region = 'se' | 'fi' | 'fr' | 'international'

type RegionContextValue = {
  region: Region
}
const RegionContext = createContext<RegionContextValue | undefined>(undefined)

type Props = {
  children: ReactNode
  region: Region
}
function RegionProvider({ children, region }: Props) {
  return <RegionContext.Provider value={{ region }}>{children}</RegionContext.Provider>
}

const useRegionContext = () => {
  const context = useContext(RegionContext)
  if (!context) {
    throw new Error('useRegionContext must be used within a RegionProvider')
  }
  return context
}

export { RegionProvider, useRegionContext }
