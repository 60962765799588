import { graphql } from '@qasa/graphql'

export const UPDATE_UPLOAD = graphql(`
  mutation UpdateUploadMutation($id: ID!, $input: UpdateUploadInput!) {
    __typename
    updateUpload(id: $id, input: $input) {
      upload {
        id
        metadata {
          order
          primary
          rotation
        }
      }
      errors {
        codes
        field
      }
    }
  }
`)
