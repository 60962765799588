import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useToastContext } from '@qasa/app'
import type { IdentificationTypeEnum } from '@qasa/graphql'
import { IdentificationStatusEnum } from '@qasa/graphql'

import { IDENTIFY } from '../data/graphql/mutations'
import { useApiErrorHelpers } from '../helpers/api-error'

import { usePollForIdentificationStatus } from './use-poll-for-identity-status'

type UseVerifyIdWithScriveParams = {
  customRedirectPath?: string
  onVerificationError?: () => void
  onVerificationFailed?: (errorMessage?: string) => void
  identificationType: IdentificationTypeEnum
}
export const useVerifyIdWithScrive = ({
  customRedirectPath,
  onVerificationError,
  onVerificationFailed,
  identificationType,
}: UseVerifyIdWithScriveParams) => {
  const { t } = useTranslation('create_application_with_verifications')
  const { addToast } = useToastContext()
  const { getErrorCopy } = useApiErrorHelpers()

  const {
    identificationStatus,
    isIdVerified,
    isLoading: isIdentificationStatusLoading,
    error,
    hasTransactionError,
    verificationQueryparams,
  } = usePollForIdentificationStatus()

  const errorTryAgainToast = (message?: string) =>
    addToast({
      message: message || t('id_verification_step.error'),
      onAction: () => null,
      actionLabel: t('close'),
    })

  const handleVerificationError = () => {
    onVerificationError ? onVerificationError() : errorTryAgainToast()
  }
  const handleVerificationFailed = (errorMessage?: string) => {
    onVerificationFailed ? onVerificationFailed(errorMessage) : errorTryAgainToast(errorMessage)
  }

  useEffect(() => {
    if (error || hasTransactionError) {
      handleVerificationError()
    } else if (identificationStatus === IdentificationStatusEnum.rejected_minor) {
      const { errorHeader } = getErrorCopy('verify_id_' + identificationStatus)
      handleVerificationFailed(errorHeader)
    } else if (identificationStatus && identificationStatus !== IdentificationStatusEnum.accepted) {
      handleVerificationFailed()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, hasTransactionError, identificationStatus])

  const [startIdentificationProcess, { loading: isIdVerificationLoading, called: isCalled }] =
    useMutation(IDENTIFY)

  const verifyIdThroughScrive = async () => {
    const { pathname, search, origin } = window.location
    try {
      const result = await startIdentificationProcess({
        variables: {
          input: {
            type: identificationType,
            path: customRedirectPath || pathname + search,
            redirectHost: origin,
          },
        },
      })
      const hasIdentificationStarted = result.data?.identify?.success
      if (hasIdentificationStarted) {
        window.location.href = result.data!.identify!.accessUrl!
      }
    } catch (error) {
      handleVerificationError()
    }
  }

  const isLoading = isIdentificationStatusLoading || isIdVerificationLoading
  const hasError = hasTransactionError || error

  return {
    verifyIdThroughScrive,
    isIdVerified,
    isLoading,
    identificationStatus,
    error: hasError,
    isCalled,
    verificationQueryparams,
  }
}
