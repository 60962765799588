import { parse } from 'query-string'
import { useLocation, useParams } from 'react-router-dom'

import { MetaTags } from '../ui-page-modules/meta-tags'
import { Profile, ProfileProvider } from '../ui-page-modules/profile'
import { PROFILE_HOME_ID_PARAM_NAME } from '../ui-page-modules/profile/profile'
import { useProfileContext } from '../ui-page-modules/profile/profile-context'
import { getProfileName } from '../ui-page-modules/profile/profile.utils'

export const PROFILE_PATH = '/profile/:id?/'

function ProfileMetaTags() {
  const { profile } = useProfileContext()
  const isProfessionalProfile = profile.proPilot || profile.professional
  return <MetaTags title={getProfileName({ user: profile })} noIndex={!isProfessionalProfile} />
}

export function ProfilePage() {
  const { id } = useParams<{ id?: string }>()
  const { search } = useLocation()
  const queryParams = parse(search)

  return (
    <ProfileProvider userUid={id} matchingHomeId={queryParams[PROFILE_HOME_ID_PARAM_NAME]?.toString()}>
      <ProfileMetaTags />
      <Profile />
    </ProfileProvider>
  )
}
