import { useState, useEffect } from 'react'
import type { Position } from 'geojson'

import type { HomeLocation } from '../find-home.utils'

import { usePersistedMapState } from './use-persisted-map-state'

export type SelectedLocation =
  | ({ homeId: HomeLocation['id']; position: Position } & Pick<
      HomeLocation,
      'rent' | 'tenant_base_fee' | 'average_price_per_night' | 'professional' | 'currency'
    >)
  | null

export function useSelectedLocation() {
  const { persistedMapState, setPersistedMapState } = usePersistedMapState()
  const [selectedLocation, setSelectedLocation] = useState<SelectedLocation>(
    persistedMapState?.selectedLocation ?? null,
  )

  useEffect(() => {
    setPersistedMapState({ selectedLocation })
  }, [selectedLocation, setPersistedMapState])

  return [selectedLocation, setSelectedLocation] as const
}
