import { useTranslation } from 'react-i18next'
import { Paragraph } from '@qasa/qds-ui'
import type { BaseUserFragment } from '@qasa/graphql'

import { getProfileAge } from '../ui-page-modules/profile/profile.utils'

export function ProfileAge({
  profile,
}: {
  profile: Pick<BaseUserFragment, 'showAge' | 'age' | 'professional'>
}) {
  const { t } = useTranslation('profile_age')
  const age = getProfileAge({ profile })
  return age ? <Paragraph>{t('age_string', { age })}</Paragraph> : null
}
