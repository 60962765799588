import { getEnv } from '../../env'
import {
  Categories,
  getSchibstedCookieConsentValue,
} from '../schibsted-sourcepoint-client/schibsted-sourcepoint-client.web'

import type { GtmCallParams, SendCustomGtagEventParams } from './google-analytics-client.types'

declare const window: {
  dataLayer?: LegitimateAny[]
  gtag?: (...args: LegitimateAny) => void
  ga?: (...args: LegitimateAny) => void
} & Window

const TRACKING_ID = getEnv('GOOGLE_ANALYTICS_TRACKING_ID')
const GTM_ID = getEnv('GOOGLE_TAG_MANAGER_ID')

const IS_GOOGLE_ANALYTICS_ENABLED = Boolean(TRACKING_ID)
const IS_GOOGLE_TAG_MANAGER_ENABLED = Boolean(GTM_ID)

export const pushToDataLayer = (object: Record<string, LegitimateAny>) => {
  if (!IS_GOOGLE_TAG_MANAGER_ENABLED) {
    return
  }

  if (typeof window.dataLayer === 'object') {
    window.dataLayer.push(object)
  } else {
    setTimeout(() => pushToDataLayer({ object }), 100)
  }
}

export function sendCustomGtagEvent({ category, action, label }: SendCustomGtagEventParams) {
  if (!window.gtag) {
    return
  }

  const hasAnalyticsConsent = getSchibstedCookieConsentValue(Categories.analytics)
  if (hasAnalyticsConsent) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
    })
  }
}

export function gtmCall(event: GtmCallParams) {
  const hasAnalyticsConsent = getSchibstedCookieConsentValue(Categories.analytics)
  const hasMarketConsent = getSchibstedCookieConsentValue(Categories.marketing)
  const hasAdvertisingConsent = getSchibstedCookieConsentValue(Categories.advertising)
  const mappedToOldCookie = {
    version: 2,
    google: hasAnalyticsConsent && hasMarketConsent,
    facebook: hasAdvertisingConsent,
    appnexus: hasMarketConsent,
  }
  pushToDataLayer({ ...event, ...mappedToOldCookie })
}

type RouteObjectType = 'Page' | 'Listing' | 'ClassifiedAd' | undefined
export function trackRouteChange(objectType: RouteObjectType) {
  gtmCall({
    event: 'ROUTE_CHANGE',
    eventType: 'View',
    objectType: objectType || 'Page',
  })
}

/**
 * Waits until the global google analytics function is set and calls it asynchronously
 */
function analyticsCall(...args: LegitimateAny) {
  if (typeof window.ga === 'function') {
    window.ga(...args)
  } else {
    setTimeout(() => analyticsCall(...args), 50)
  }
}

export function trackPageView(path: string, objectType: RouteObjectType) {
  if (!window.gtag) {
    return
  }

  if (IS_GOOGLE_TAG_MANAGER_ENABLED) {
    trackRouteChange(objectType)
  }

  if (!IS_GOOGLE_ANALYTICS_ENABLED) {
    return
  }

  window.gtag('config', TRACKING_ID, { page_path: path })
  window.gtag({
    event: 'ROUTE_CHANGE',
    eventType: 'View',
    objectType: objectType || 'Page',
  })
  analyticsCall('set', 'page', path)
  analyticsCall('send', 'pageview')
}

type SetGoogleConsentValuesParams = { hasConsentedToAnalytics: boolean; hasConsentedToMarketing: boolean }
export function setGoogleConsentValues({
  hasConsentedToAnalytics,
  hasConsentedToMarketing,
}: SetGoogleConsentValuesParams) {
  if (!window.gtag) {
    return
  }

  window.gtag('consent', 'update', {
    ad_storage: hasConsentedToMarketing ? 'granted' : 'denied',
    ad_user_data: hasConsentedToMarketing ? 'granted' : 'denied',
    ad_personalization: hasConsentedToMarketing ? 'granted' : 'denied',
    analytics_storage: hasConsentedToAnalytics ? 'granted' : 'denied',
  })
}
