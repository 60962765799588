import { Spacer } from '@qasa/qds-ui'
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { HomeRentalTypeEnum } from '@qasa/graphql'

import { useQueryParam } from '../../hooks/use-query-param'
import { NotFound } from '../../legacy-pages/not-found'
import { Loading } from '../../ui-shared/_core/network-statuses'

import { ListingSectionVariantEnum, useListingSectionRoutes } from './use-listing-section-routes'
import { SectionNavigation } from './section-navigation'
import { SectionTracker } from './section-tracker'
import { useListingContext } from './listing-context'
import { FieldEnum } from './edit-listing.types'
import { useHomeDraftsAndPreviouslySignedHomes } from './use-home-drafts-and-previously-rented-out'

const Wrapper = styled.div(({ theme }) => ({
  paddingBottom: theme.spacing['16x'], // to offset NavigationContainer
}))

type Props = {
  variant: ListingSectionVariantEnum
  isFromFindTenant: boolean
  isHomePublished?: boolean
}

export enum PresetRentalTypeEnum {
  vacation = 'vacation',
  long_term = 'long-term',
}

export function ListingSectionRoutes({ variant, isHomePublished, isFromFindTenant }: Props) {
  const { path: currentPath } = useRouteMatch()
  const location = useLocation()
  const {
    dispatch,
    store: {
      values: { rentalType },
    },
  } = useListingContext()

  const [isLoadingNext, setIsLoadingNext] = useState(false)
  const [presetRentalType] = useQueryParam<PresetRentalTypeEnum | null>({
    paramName: 'rental-type',
    defaultValue: null,
    whiteList: Object.values(PresetRentalTypeEnum),
  })
  const { hasPreviouslySignedHomes, hasHomeDrafts, isLoading } = useHomeDraftsAndPreviouslySignedHomes({})
  const sectionRoutes = useListingSectionRoutes({
    variant,
    isUserWithPreviouslySignedHomes: hasPreviouslySignedHomes,
    isUserWithHomeDrafts: hasHomeDrafts,
    isHomePublished,
    isFromFindTenant,
  })
  const isCreate = variant === ListingSectionVariantEnum.CREATE_AD
  useEffect(() => {
    if (presetRentalType) {
      dispatch({
        action: {
          type: FieldEnum.RentalType,
          payload:
            presetRentalType === 'vacation' ? HomeRentalTypeEnum.vacation : HomeRentalTypeEnum.long_term,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presetRentalType])

  const shouldSkipRentalTypeStep = presetRentalType && sectionRoutes[0].path === 'choose-rental-type'

  if (isLoading) {
    return <Loading />
  }

  return (
    <Switch>
      {isCreate && (
        <Route exact path={currentPath}>
          <Redirect
            to={{
              ...location,
              pathname: `${currentPath}/${sectionRoutes[shouldSkipRentalTypeStep ? 1 : 0].path}`,
            }}
          />
        </Route>
      )}
      {sectionRoutes.map(({ path, component: Component, isComplete, nextLabelOverride }, index) => {
        const previousSection = sectionRoutes[index - 1]?.path
        const nextSection = sectionRoutes[index + 1]?.path
        const isStandaloneStep = [
          'pre-login',
          'listing-details-intro',
          'home-details-intro',
          'about-rental-intro',
          'find-tenant-intro',
        ].includes(path)

        const ComponentWithSectionNavigation = (
          <>
            <Component setIsLoadingNext={setIsLoadingNext} />
            <Spacer size="8x" />
            <SectionNavigation
              {...{ previousSection, nextSection, isLoadingNext }}
              isCurrentSectionComplete={isComplete}
              nextButtonLabelOverride={nextLabelOverride}
              isCreateListing={isCreate}
            />
          </>
        )

        return (
          <Route path={`${currentPath}/${path}`} key={path}>
            <SectionTracker
              sectionNumber={index + 1}
              sectionPath={path}
              shouldGoogleTagManagerTrack={isCreate}
            />
            <Wrapper>
              {isStandaloneStep ? (
                <Component {...{ previousSection, listingVariant: rentalType, nextSection }} />
              ) : (
                ComponentWithSectionNavigation
              )}
            </Wrapper>
          </Route>
        )
      })}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}
