import { useQuery } from '@apollo/client'
import type { ReactNode } from 'react'
import { useContext, createContext } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useSearchPreferenceMatchData } from '@qasa/app/src/hooks'
import type { TenantAdStatusEnum, HomeMatchingProfileQueryQuery, ProfileQueryQuery } from '@qasa/graphql'

import { useAuthContext } from '../../context/auth-context'
import { HOME_MATCHING_PROFILE } from '../../data/graphql/queries/home-matching-profile.gql'
import { PageNetworkStatus } from '../../ui-shared/_core/network-statuses'
import { createAfterLoginActionParam } from '../../helpers/auth-callback'
import { AFTER_LOGIN_ACTIONS } from '../../helpers/auth-callback.types'

import type { UseEditSectionStateReturn } from './hooks/use-edit-profile-state'
import { useEditProfileState } from './hooks/use-edit-profile-state'
import { PROFILE } from './profile.gql'
import type { ParsedProfileSearchPreference } from './utils/parse-profile-search-preference'
import { parseProfileSearchPreference } from './utils/parse-profile-search-preference'

type ProfileContextValue = {
  profile: NonNullable<ProfileQueryQuery['user']>
  refetchProfile: () => void
  isOwnProfile: boolean
  parsedSearchPreference: ParsedProfileSearchPreference
  tenantAdStatus: TenantAdStatusEnum | undefined
  tenantAdPublishedUntil: string
  matchingHome?: HomeMatchingProfileQueryQuery['home']
  searchPreferenceMatchData: ReturnType<typeof useSearchPreferenceMatchData>
  isProfessional: boolean
} & UseEditSectionStateReturn

const ProfileContext = createContext<ProfileContextValue | undefined>(undefined)

type ProfileProviderProps = {
  children: ReactNode
  userUid?: string
  matchingHomeId?: string
}

export function ProfileProvider({ children, userUid, matchingHomeId }: ProfileProviderProps) {
  const { authBody } = useAuthContext()
  const {
    error,
    data,
    loading: isLoading,
    refetch: refetchProfile,
  } = useQuery(PROFILE, {
    variables: { uid: userUid! || authBody?.uid || '' },
    skip: !userUid && !authBody?.uid,
  })
  const { data: matchingHomeData } = useQuery(HOME_MATCHING_PROFILE, {
    variables: { id: matchingHomeId ?? '' },
    skip: !matchingHomeId,
  })
  const editProfileState = useEditProfileState({ onClose: refetchProfile })
  const { search, pathname } = useLocation()

  const parsedSearchPreference = parseProfileSearchPreference({
    searchPreference: data?.user?.tenantAd?.searchPreference,
  })
  const searchPreferenceMatchData = useSearchPreferenceMatchData({
    parsedSearchPreference,
    matchingHome: matchingHomeData?.home,
  })

  if (!userUid && !authBody?.uid) {
    // Redirect user to login if they are not logged in and try to access a profile without passing an UID
    return (
      <Redirect
        to={{
          pathname: '/login',
          search: createAfterLoginActionParam({
            type: AFTER_LOGIN_ACTIONS.GO_TO_ROUTE,
            route: pathname,
            search,
          }),
        }}
      />
    )
  } else if (!isLoading && !data?.user) {
    // Redirect to 404 page if no "id" was provided or if the provided ID does not exist
    return <Redirect to={{ pathname: '/404', state: window.location.href }} />
  }

  if (isLoading || error) {
    return <PageNetworkStatus {...{ isLoading, error }} />
  }

  const profile = data!.user!
  const isOwnProfile = authBody?.uid === profile.uid
  const tenantAdStatus = data?.user?.tenantAd?.status
  const tenantAdPublishedUntil = data?.user?.tenantAd?.publishedUntil || ''
  const matchingHome = matchingHomeData?.home

  if (!userUid && isOwnProfile) {
    return <Redirect to={`/profile/${authBody!.uid}`} />
  }

  return (
    <ProfileContext.Provider
      value={{
        profile,
        refetchProfile,
        isProfessional: profile.professional,
        isOwnProfile,
        parsedSearchPreference,
        tenantAdStatus,
        tenantAdPublishedUntil,
        matchingHome,
        searchPreferenceMatchData,
        ...editProfileState,
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
}

export const useProfileContext = () => {
  const context = useContext(ProfileContext)

  if (context === undefined) {
    throw Error('You forgot to wrap the component in a `ProfileProvider`')
  }

  return context
}
