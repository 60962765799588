import moment from 'moment'
import type { RegisterOptions } from 'react-hook-form'
import { addDays } from 'date-fns'
import { HousingSituationAgreementTypeEnum, type ProfileForCreateTenantListingQuery } from '@qasa/graphql'

import { phoneValidator, required } from '../../helpers/validation'
import { parseProfileSearchPreference } from '../profile'

export const ONE_MILLION = 1000000

type ParseTenantListingDataParams = {
  profileResponseData?: ProfileForCreateTenantListingQuery['user']
}
export const parseTenantListingData = ({ profileResponseData }: ParseTenantListingDataParams) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { bio, housingSituation, firstName, movingReason } = profileResponseData || {}

  const parsedSearchPreference = parseProfileSearchPreference({
    searchPreference: profileResponseData?.tenantAd?.searchPreference,
  })

  const isCurrentAgreementTypeOwning =
    housingSituation?.agreementType === HousingSituationAgreementTypeEnum.owning

  return {
    housingSituation: {
      agreementType: isCurrentAgreementTypeOwning ? null : housingSituation?.agreementType,
      landlordCity: housingSituation?.landlordCity,
      landlordName: housingSituation?.landlordName,
      landlordPhoneNumber: housingSituation?.landlordPhoneNumber,
    },
    firstName,
    movingReason,
    bio: bio?.intro,
    bioTitle: bio?.title,
    bioPets: bio?.pets,
    bioLived: bio?.lived,
    familyName: profileResponseData?.private?.familyName,
    phoneNumber: profileResponseData?.private?.phoneNumber,
    ...parsedSearchPreference,
  }
}

//Omitted values are not supported for controller - Kevin Brandhild 2022-03-01
type ControlledRules = Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>

export const requiredFormField: ControlledRules = {
  required: true,
  min: 1,
}
export const requiredPhoneNumber: ControlledRules = {
  required: true,
  validate: (value) => phoneValidator(value, required()).valid,
}

type ValidateMoveInAfterMoveOutParams = {
  moveIn: string | null
  moveOut: string | null
}
export const validateMoveInAfterMoveOut = ({ moveIn, moveOut }: ValidateMoveInAfterMoveOutParams) => {
  if (moveOut && moveIn) {
    return moment(moveOut).isAfter(moment(moveIn))
  }
  return true
}

// BE validation: first valid move out day is day after 30 days
export const firstValidMoveOutDate = addDays(new Date(), 31)

export const isDayBeforeValidMoveOutDate = (day: moment.Moment) => {
  return day.isBefore(firstValidMoveOutDate, 'day')
}
