import styled from '@emotion/styled'
import { Avatar, Spacer, Stack, useBreakpointValue } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import SupportAvatarWithLogo from '../../assets/images/support-avatar-with-logo.png'
import type { ShortcutOptInStepProps } from '../../legacy-pages/shortcut-opt-in'
import { PageWidthContainer } from '../../ui-shared/_layout/width-containers'
import { Wizard } from '../../ui-shared/wizard'

import { FooterActions } from './shortcut-intro/footer-actions'
import { ProcessListSection } from './shortcut-intro/process-list-section'
import { TermsAndConditionsSection } from './shortcut-intro/terms-and-conditions-section'
import { TitleAndDescriptionSection } from './shortcut-intro/title-and-description-section'

const Wrapper = styled(Stack)(({ theme }) => ({ padding: theme.spacing['4x'] }))
const SoftPinkWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['6x'],
  backgroundColor: theme.colors.core.softPink,
  flexGrow: 1,
  borderRadius: theme.radii.lg,
  [theme.mediaQueries.lgUp]: {
    minWidth: '50%',
    padding: theme.spacing['12x'],
  },
}))
const DisplayHeading = styled.h2(({ theme }) => ({
  ...theme.typography.display.xs,
  [theme.mediaQueries.lgUp]: {
    ...theme.typography.display.sm,
  },
}))
const Content = styled(Stack)(({ theme }) => ({
  paddingInline: theme.spacing['2x'],
  [theme.mediaQueries.lgUp]: {
    paddingInline: 0,
    paddingBlock: theme.spacing['8x'],
  },
}))

function MobileLayout({ goNext, market }: ShortcutOptInStepProps) {
  const { t } = useTranslation('shortcut_opt_in')

  return (
    <Wrapper>
      <Stack gap="6x">
        <SoftPinkWrapper gap="6x">
          <Avatar src={SupportAvatarWithLogo.src} />
          <DisplayHeading>{t('intro.display_title')}</DisplayHeading>
        </SoftPinkWrapper>
        <Content gap="6x">
          <TitleAndDescriptionSection />
          <ProcessListSection />
          <TermsAndConditionsSection market={market} />
        </Content>
      </Stack>
      <Wizard.Footer>
        <Spacer size="4x" />
        <FooterActions goNext={goNext} />
      </Wizard.Footer>
    </Wrapper>
  )
}

function DesktopLayout({ goNext, market }: ShortcutOptInStepProps) {
  const { t } = useTranslation('shortcut_opt_in')
  return (
    <PageWidthContainer maxWidth="wider">
      <Spacer size="4x" />
      <Wrapper gap="20x" direction="row">
        <SoftPinkWrapper justifyContent="space-between">
          <Avatar size="xl" src={SupportAvatarWithLogo.src} />
          <DisplayHeading>{t('intro.display_title')}</DisplayHeading>
        </SoftPinkWrapper>
        <Content gap="6x">
          <TitleAndDescriptionSection isDesktop />
          <ProcessListSection />
          <TermsAndConditionsSection market={market} />
          <FooterActions goNext={goNext} />
        </Content>
      </Wrapper>
    </PageWidthContainer>
  )
}

export function ShortcutIntro({ goNext, market }: ShortcutOptInStepProps) {
  const Layout = useBreakpointValue({ base: MobileLayout, lg: DesktopLayout })

  return <Layout goNext={goNext} market={market} />
}
