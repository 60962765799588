import { useTranslation } from 'react-i18next'
import { Stack } from '@qasa/qds-ui'
import { HousingSituationAgreementTypeEnum } from '@qasa/graphql'

import { ProfileSectionItem } from '../profile-section-item'
import { ProfileSectionHeader } from '../profile-section-header'
import { useProfileContext } from '../profile-context'
import { ProfileSectionEnum } from '../profile.types'
import { ProfileBadge } from '../../../ui-shared/profile-badges'

export function ProfileHousingBlock() {
  const { profile, isOwnProfile, openEditProfileSection } = useProfileContext()
  const { t } = useTranslation('profile_housing_block')
  const { agreementType, landlordCity, hasReference } = profile.housingSituation || {}
  const hasDefinedAgreementType = Boolean(agreementType)
  const isAgreementTypeSectionVisible =
    agreementType !== HousingSituationAgreementTypeEnum.other_agreement_type

  const formattedAgreementType = t(`current_agreement_type.options.${agreementType ?? 'no_agreement_type'}`)
  const formattedHousing = landlordCity
    ? t('current_agreement_type.type_with_location', {
        agreementType: formattedAgreementType,
        location: landlordCity,
      })
    : formattedAgreementType

  return (
    <Stack gap="3x">
      <ProfileSectionHeader
        isOwnProfile={isOwnProfile}
        title={t('profile:sections_title.HOUSING_SITUATION')}
        onEditButtonClick={() =>
          openEditProfileSection({ activeEditSection: ProfileSectionEnum.HOUSING_SITUATION })
        }
      />
      <Stack gap="6x">
        {isAgreementTypeSectionVisible && (
          <Stack gap="2x">
            <ProfileSectionItem
              title={t(`current_agreement_type.title`)}
              text={hasDefinedAgreementType ? formattedHousing : undefined}
            />
            {hasReference && (
              <ProfileBadge confirmed confirmedLabel={t('current_agreement_type.reference')} />
            )}
          </Stack>
        )}
        <ProfileSectionItem title={t(`moving_reason.title`)} text={profile.movingReason} />
        <ProfileSectionItem title={t(`bio_lived.title`)} text={profile.bio.lived} />
      </Stack>
    </Stack>
  )
}
