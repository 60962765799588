import { useProfileContext } from './profile-context'
import { ProfilePublishedListings } from './profile-published-listings'
import { ProfileProPilotPublishedListings } from './profile-pro-pilot-published-listings'

export function ProfilePublishedListingsBlock() {
  const { profile } = useProfileContext()
  const isProPilotProfile = profile.proPilot

  if (isProPilotProfile) return <ProfileProPilotPublishedListings />

  return <ProfilePublishedListings />
}
