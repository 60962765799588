import { graphql } from '@qasa/graphql'

export const UPDATE_USER = graphql(`
  mutation UpdateUserMutation($uid: ID!, $input: UpdateUserInput!) {
    updateUser(uid: $uid, input: $input) {
      user {
        ...BaseUser
        ...UserBio
        ...UserPrivateFields
        smoker
        affiliateCode {
          code
          discountFor
          discountType
          discountValue
          expirationDate
          affiliateCodeType
        }
      }
      errors {
        field
        codes
      }
    }
  }
`)
