import { forwardRef } from 'react'
import styled from '@emotion/styled'

import { VisuallyHidden } from '../../visually-hidden'
import { useCheckbox } from '../checkbox/use-checkbox'
import type { CheckboxCardProps } from '../checkbox'

type PillProps = {
  isChecked: boolean
}

const Pill = styled.label<PillProps>(({ theme, isChecked }) => ({
  ...theme.typography.label.sm,
  height: 40,
  paddingLeft: theme.spacing['5x'],
  paddingRight: theme.spacing['5x'],
  display: 'flex',
  alignItems: 'center',
  border: '1px solid',
  borderColor: theme.colors.border[isChecked ? 'defaultSelected' : 'default'],
  borderRadius: theme.radii.full,
  cursor: 'pointer',
  color: theme.colors.text.default,
  background: theme.colors.bg.default,
  transitionProperty: 'border-color, box-shadow, background-color, transform, outline',
  transitionDuration: '120ms',
  transitionTimingFunction: 'ease-in-out',
  ...(isChecked
    ? {
        boxShadow: `inset 0 0 0 1px ${theme.colors.border.defaultSelected}`,
        backgroundColor: theme.colors.bg.inset,
      }
    : {
        '&:hover': {
          borderColor: theme.colors.border.defaultHover,
          backgroundColor: theme.colors.bg.inset,
        },
      }),

  // Not supported in a lot of browsers, but hey... progressive enhancement!
  '&:has(input:focus-visible)': {
    outlineStyle: 'solid',
    outlineColor: theme.colors.border.defaultSelected,
    outlineWidth: '2px',
    outlineOffset: '2px',
  },

  WebkitTouchCallout: 'none',
  WebkitTapHighlightColor: 'transparent',

  ':active': {
    transform: 'scale(0.98)',
  },
}))

export const CheckboxPill = forwardRef<HTMLInputElement, CheckboxCardProps>(
  ({ label, ...restProps }, ref) => {
    const { getInputProps, isChecked } = useCheckbox({ ...restProps })

    return (
      <Pill isChecked={isChecked}>
        <VisuallyHidden>
          <input ref={ref} {...getInputProps()} />
        </VisuallyHidden>
        {label}
      </Pill>
    )
  },
)
