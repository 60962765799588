import { useEffect, useState } from 'react'

import { usePersistedMapState } from './use-persisted-map-state'
import type { SelectedLocation } from './use-selected-location'

export type ClusterType =
  | {
      leaves: SelectedLocation[]
      clusterId: number
    }
  | undefined

export function useSelectedCluster() {
  const { persistedMapState, setPersistedMapState } = usePersistedMapState()
  const [selectedCluster, setSelectedCluster] = useState<ClusterType>(
    persistedMapState?.selectedCluster ?? undefined,
  )

  useEffect(() => {
    setPersistedMapState({ selectedCluster })
  }, [selectedCluster, setPersistedMapState])
  return [selectedCluster, setSelectedCluster] as const
}
