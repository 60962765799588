import { graphql } from '@qasa/graphql'

export const LISTING = graphql(`
  query Listing($id: ID!) {
    home(id: $id) {
      id
      type
      market {
        id
        name
      }
      tenureType
      location {
        formattedAddress
        latitude
        locality
        longitude
        postalCode
        route
        streetNumber
        countryCode
      }
      apartmentNumber
      floor
      buildingFloors
      landlord {
        uid
      }
      uploads {
        id
        url
        metadata {
          __typename
          primary
          order
          rotation
        }
      }
      traits {
        type
      }
      duration {
        startOptimal
        endOptimal
        startAsap
        endUfn
        possibilityOfExtension
      }
      ownHome
      landlordServices {
        qasaGuarantee {
          isActive
          availability
        }
        insurance {
          isActive
          availability
        }
      }
      landlordFees {
        base {
          availability
          cost {
            factor
            total
            isFree
          }
        }
      }
      insurance
      insuranceCost
      qasaGuarantee
      qasaGuaranteeCost
      shared
      description
      rent
      recommendedRentNew(forceSync: true)
      roomCount
      squareMeters
      status
      firsthand
      seniorHome
      studentHome
      corporateHome
      tenantCount
      tenantFees {
        base {
          cost {
            factor
          }
        }
        waterFeePerTenant {
          availability
          cost {
            fixed
          }
        }
        monthlyElectricityFee {
          availability
          cost {
            isFree
            fixed
          }
        }
      }
      electricityFee {
        paymentPlan
        monthlyFee
      }
      property {
        maxSquareMeters
        maxRoomCount
        minRoomCount
        minSquareMeters
        numberOfHomes
      }
      energyClass
      housingAssociation
      kitchenRenovationYear
      bathroomRenovationYear
      buildYear
      publishedAt
      external
      externalApplicationUrl
      externalEmail
      externalInfoPage
      pricingModel {
        insuranceCostFactor
        qasaGuaranteeCostFactor
      }
      rentalType
      houseRules
      responsibleForCleaning
      cleaningFee
      title
      bedCount
      bedroomCount
      hasKitchen
      toiletCount
      currentCancellationPolicy {
        policyName
        partialRefundFactor
        partialRefundDays
        fullRefundDays
      }
      cancellationOptions {
        policyName
        partialRefundFactor
        partialRefundDays
        fullRefundDays
      }
      origin
      currency
    }
  }
`)

export const HOME_CANCELLATION_POLICY_OPTIONS = graphql(`
  query CancellationPolicyOptions($id: ID!) {
    home(id: $id) {
      cancellationOptions {
        policyName
        partialRefundFactor
        partialRefundDays
        fullRefundDays
      }
    }
  }
`)
