import type { PropsWithChildren } from 'react'
import { useContext, createContext } from 'react'
import { graphql } from '@qasa/graphql'
import type { CurrentUserFragment } from '@qasa/graphql'

const currentUserFragment = graphql(`
  fragment CurrentUser on User {
    currentIdentification {
      id
      identityConfirmed
      identityConfirmedAt
      identificationType
    }
    activeIdentifications {
      id
      identificationType
      identityConfirmedAt
      identityConfirmed
    }
    emailConfirmed
    certificate {
      id
      status
    }
    profilePicture {
      url
    }
    landlord
    tenant
    age
    firstName
    professional
    companyName
    uid
    premium
    private {
      id
      familyName
      phoneNumber
      invoiceAdsFrom
      verifiedAccess
      activeBankAccount {
        id
      }
    }
    ...UserProfileCompleteness
    ...HomeApplicationSortingInfoUser
  }
`)

export type CurrentUserContextType = {
  currentUser?: CurrentUserFragment
  isAuthenticated: boolean
  refetchCurrentUser?: () => void
}
const CurrentUserContext = createContext<CurrentUserContextType | undefined>(undefined)

function CurrentUserProvider({
  children,
  currentUser,
  refetchCurrentUser,
  isAuthenticated,
}: PropsWithChildren<CurrentUserContextType>) {
  return (
    <CurrentUserContext.Provider value={{ currentUser, isAuthenticated, refetchCurrentUser }}>
      {children}
    </CurrentUserContext.Provider>
  )
}

const useCurrentUser = () => {
  const context = useContext(CurrentUserContext)
  if (!context) {
    throw new Error('useCurrentUser must be used within a CurrentUserProvider')
  }
  return context
}

CurrentUserProvider.fragments = { user: currentUserFragment }

export { CurrentUserProvider, useCurrentUser }
