import type {
  ElectricityFeeInput,
  CreateDurationInput,
  CreateLocationInput,
  DayOfTheWeekTypeEnum,
  HomeRentalTypeEnum,
  HomeTypeEnum,
  TenureTypeEnum,
  UpdateHomeInput,
  ListingQuery,
  CreateHomeInput,
} from '@qasa/graphql'

enum ListingSectionEnum {
  homeDetailsIntro = 'homeDetailsIntro',
  listingDetailsIntro = 'listingDetailsIntro',
  aboutRentalIntro = 'aboutRentalIntro',
  findTenantIntro = 'findTenantIntro',
  chooseRentalType = 'chooseRentalType',
  templates = 'templates',
  userType = 'userType',
  homeType = 'homeType',
  size = 'size',
  areas = 'areas',
  address = 'address',
  map = 'map',
  preLogin = 'preLogin',
  duration = 'duration',
  uploadImages = 'uploadImages',
  sharedFurnished = 'sharedFurnished',
  rent = 'rent',
  utilityCosts = 'utilityCosts',
  amenitiesHome = 'amenitiesHome',
  rulesAndAccessibility = 'rulesAndAccessibility',
  locationPerks = 'locationPerks',
  description = 'description',
  numberOfHomes = 'numberOfHomes',
  standard = 'standard',
  applicationType = 'applicationType',
  landlordCalendar = 'landlordCalendar',
  cleaning = 'cleaning',
  cancellationPolicy = 'cancellationPolicy',
  calendarSettings = 'calendarSettings',
}

type ListingSectionType = {
  path: string
  component: FixThisTypeLater
  isComplete: boolean
  nextLabelOverride?: string
}

export type ListingSections = Record<ListingSectionEnum, ListingSectionType>

export enum FieldEnum {
  IsProfessional = 'isProfessional',
  Type = 'type',
  TenureType = 'tenureType',
  Firsthand = 'firsthand',
  Student = 'studentHome',
  Senior = 'seniorHome',
  Corporate = 'corporateHome',
  Position = 'position',
  Locality = 'locality',
  PostalCode = 'postalCode',
  Route = 'route',
  StreetNumber = 'streetNumber',
  ApartmentNumber = 'apartmentNumber',
  CountryCode = 'countryCode',
  Country = 'country',
  FormattedAddress = 'formattedAddress',
  Duration = 'duration',
  EndOptimal = 'endOptimal',
  StartAsap = 'startAsap',
  EndUfn = 'endUfn',
  OwnHome = 'ownHome',
  Shared = 'shared',
  Traits = 'traits',
  RoomCount = 'roomCount',
  MinRoomCount = 'minRoomCount',
  MaxRoomCount = 'maxRoomCount',
  ToiletCount = 'toiletCount',
  BedroomCount = 'bedroomCount',
  BedCount = 'bedCount',
  HasKitchen = 'hasKitchen',
  SquareMeters = 'squareMeters',
  TenantCount = 'tenantCount',
  Description = 'description',
  NumberOfHomes = 'numberOfHomes',
  Guarantee = 'qasaGuarantee',
  GuaranteeCost = 'qasaGuaranteeCost',
  Insurance = 'insurance',
  InsuranceCost = 'insuranceCost',
  Rent = 'rent',
  Floor = 'floor',
  BuildingFloors = 'buildingFloors',
  CompanyName = 'companyName',
  OrganizationNumber = 'orgNumber',
  KitchenRenovation = 'kitchenRenovationYear',
  BathroomRenovation = 'bathroomRenovationYear',
  BuildYear = 'buildYear',
  EnergyClass = 'energyClass',
  HousingAssociation = 'housingAssociation',
  RecommendedRentNew = 'recommendedRentNew',
  MinSquareMeters = 'minSquareMeters',
  MaxSquareMeters = 'maxSquareMeters',
  Uploads = 'uploads',
  IsExternal = 'isExternal',
  ExternalEmail = 'externalEmail',
  ExternalApplicationUrl = 'externalApplicationUrl',
  ExternalInfoPage = 'externalInfoPage',
  Title = 'title',
  HouseRules = 'houseRules',
  RentalType = 'rentalType',
  ResponsibleForCleaning = 'responsibleForCleaning',
  CleaningFee = 'cleaningFee',
  CancellationPolicyName = 'cancellationPolicyName',
  AvailableDates = 'availableDates',
  WaterFeePerTenant = 'waterFeePerTenant',
  MinNights = 'minNights',
  IsRentOnlyWeekly = 'isRentOnlyWeekly',
  CheckInDays = 'checkInDays',
  ElectricityFee = 'electricityFee',
}

export type ListingPosition = {
  longitude: CreateLocationInput['longitude']
  latitude: CreateLocationInput['latitude']
}

/* Actions */
type UserTypeAction = { type: FieldEnum.IsProfessional; payload: boolean }
type HomeTypeAction = { type: FieldEnum.Type; payload: HomeTypeEnum }
type TenureTypeAction = { type: FieldEnum.TenureType; payload: TenureTypeEnum }
type FirstHandAction = { type: FieldEnum.Firsthand; payload: CreateHomeInput['firsthand'] }
type StudentHomeAction = { type: FieldEnum.Student; payload: CreateHomeInput['studentHome'] }
type SeniorHomeAction = { type: FieldEnum.Senior; payload: CreateHomeInput['seniorHome'] }
type CorporateHomeAction = {
  type: FieldEnum.Corporate
  payload: CreateHomeInput['corporateHome']
}
type PositionAction = { type: FieldEnum.Position; payload: ListingPosition }
type LocalityAction = { type: FieldEnum.Locality; payload: CreateLocationInput['locality'] }
type PostalCodeAction = {
  type: FieldEnum.PostalCode
  payload: CreateLocationInput['postalCode']
}
type RouteAction = { type: FieldEnum.Route; payload: CreateLocationInput['route'] }
type StreetNumberAction = {
  type: FieldEnum.StreetNumber
  payload: CreateLocationInput['streetNumber']
}
type ApartmentNumberAction = {
  type: FieldEnum.ApartmentNumber
  payload: CreateHomeInput['apartmentNumber']
}
type CountryCodeAction = {
  type: FieldEnum.CountryCode
  payload: CreateLocationInput['countryCode']
}
type CountryAction = {
  type: FieldEnum.Country
  payload: CreateLocationInput['country']
}
type FormattedAddressAction = {
  type: FieldEnum.FormattedAddress
  payload: CreateLocationInput['formattedAddress']
}
type FloorAction = {
  type: FieldEnum.Floor
  payload: CreateHomeInput['floor']
}
type BuildingFloorsAction = {
  type: FieldEnum.BuildingFloors
  payload: CreateHomeInput['buildingFloors']
}
type DurationAction = {
  type: FieldEnum.Duration
  payload: Partial<CreateDurationInput>
}
type OwnHomeAction = { type: FieldEnum.OwnHome; payload: boolean }
type SquareMetersAction = { type: FieldEnum.SquareMeters; payload: CreateHomeInput['squareMeters'] }
type MinSquareMetersAction = {
  type: FieldEnum.MinSquareMeters
  payload: Required<UpdateHomeInput>['maxSquareMeters']
}
type MaxSquareMetersAction = {
  type: FieldEnum.MaxSquareMeters
  payload: Required<UpdateHomeInput>['maxSquareMeters']
}
type RoomCountAction = { type: FieldEnum.RoomCount; payload: CreateHomeInput['roomCount'] }
type MinRoomCountAction = { type: FieldEnum.MinRoomCount; payload: CreateHomeInput['minRoomCount'] }
type MaxRoomCountAction = { type: FieldEnum.MaxRoomCount; payload: CreateHomeInput['maxRoomCount'] }
type ToiletCountAction = { type: FieldEnum.ToiletCount; payload: CreateHomeInput['toiletCount'] }
type BedCountAction = { type: FieldEnum.BedCount; payload: CreateHomeInput['bedCount'] }
type BedroomCountAction = { type: FieldEnum.BedroomCount; payload: CreateHomeInput['bedroomCount'] }
type HasKitchenAction = { type: FieldEnum.HasKitchen; payload: CreateHomeInput['hasKitchen'] }
type TenantCountAction = { type: FieldEnum.TenantCount; payload: UpdateHomeInput['tenantCount'] }
type SharedAction = { type: FieldEnum.Shared; payload: UpdateHomeInput['shared'] }
type TraitsAction = { type: FieldEnum.Traits; payload: UpdateHomeInput['traits'] }
type TitleAction = { type: FieldEnum.Title; payload: CreateHomeInput['title'] }
type DescriptionAction = { type: FieldEnum.Description; payload: CreateHomeInput['description'] }
type HouseRulesAction = { type: FieldEnum.HouseRules; payload: CreateHomeInput['houseRules'] }
type RentalTypeAction = { type: FieldEnum.RentalType; payload: CreateHomeInput['rentalType'] }
type ResponsibleForCleaningAction = {
  type: FieldEnum.ResponsibleForCleaning
  payload: UpdateHomeInput['responsibleForCleaning']
}
type CleaningFeeAction = { type: FieldEnum.CleaningFee; payload: UpdateHomeInput['cleaningFee'] }
type CancellationPolicyNameAction = {
  type: FieldEnum.CancellationPolicyName
  payload: UpdateHomeInput['cancellationPolicyName']
}
type MinNightsAction = { type: FieldEnum.MinNights; payload: number | null }
type IsRentOnlyWeeklyAction = { type: FieldEnum.IsRentOnlyWeekly; payload: boolean | null }
type CheckInDaysAction = { type: FieldEnum.CheckInDays; payload: DayOfTheWeekTypeEnum[] }
type AvailableDatesAction = { type: FieldEnum.AvailableDates; payload: string[] | null }
type NumberOfHomesAction = { type: FieldEnum.NumberOfHomes; payload: CreateHomeInput['numberOfHomes'] }
type GuaranteeAction = { type: FieldEnum.Guarantee; payload: UpdateHomeInput['qasaGuarantee'] }
type InsuranceAction = { type: FieldEnum.Insurance; payload: UpdateHomeInput['insurance'] }
type RentAction = { type: FieldEnum.Rent; payload: CreateHomeInput['rent'] | null }
type WaterFeeAction = { type: FieldEnum.WaterFeePerTenant; payload: CreateHomeInput['waterFeePerTenant'] }
type CompanyNameAction = { type: FieldEnum.CompanyName; payload: ListingStoreValues['companyName'] }
type OrganizationNumberAction = {
  type: FieldEnum.OrganizationNumber
  payload: ListingStoreValues['orgNumber']
}
type KitchenRenovationAction = {
  type: FieldEnum.KitchenRenovation
  payload: UpdateHomeInput['kitchenRenovationYear']
}
type BathroomRenovationAction = {
  type: FieldEnum.BathroomRenovation
  payload: UpdateHomeInput['bathroomRenovationYear']
}
type EnergyClassAction = { type: FieldEnum.EnergyClass; payload: UpdateHomeInput['energyClass'] }
type BuildYearAction = { type: FieldEnum.BuildYear; payload: UpdateHomeInput['buildYear'] }
type HousingAssociationAction = {
  type: FieldEnum.HousingAssociation
  payload: UpdateHomeInput['housingAssociation']
}
type UploadsAction = {
  type: FieldEnum.Uploads
  payload: NonNullable<ListingQuery['home']>['uploads']
}
type RecommendedRentAction = {
  type: FieldEnum.RecommendedRentNew
  payload: ListingStoreValues['recommendedRentNew']
}
type GuaranteeCostAction = { type: FieldEnum.GuaranteeCost; payload: ListingStoreValues['qasaGuaranteeCost'] }
type InsuranceCostAction = { type: FieldEnum.InsuranceCost; payload: ListingStoreValues['insuranceCost'] }
type IsExternalAction = { type: FieldEnum.IsExternal; payload: boolean }
type ExternalApplicationUrlAction = {
  type: FieldEnum.ExternalApplicationUrl
  payload: ListingStoreValues['externalApplicationUrl']
}
type ExternalEmailAction = {
  type: FieldEnum.ExternalEmail
  payload: ListingStoreValues['externalEmail']
}
type ExternalInfoPageAction = {
  type: FieldEnum.ExternalInfoPage
  payload: ListingStoreValues['externalInfoPage']
}
type FieldsExcludedFromUpdateHomeMutation =
  | FieldEnum.Uploads
  | FieldEnum.RecommendedRentNew
  | FieldEnum.GuaranteeCost
  | FieldEnum.InsuranceCost
  | FieldEnum.AvailableDates
  | FieldEnum.MinNights
  | FieldEnum.IsRentOnlyWeekly
  | FieldEnum.CheckInDays
type ElectricityFeeAction = {
  type: FieldEnum.ElectricityFee
  payload: ElectricityFeeInput
}
export function shouldExcludeField(type: FieldEnum): type is FieldsExcludedFromUpdateHomeMutation {
  return [
    FieldEnum.Uploads,
    FieldEnum.GuaranteeCost,
    FieldEnum.InsuranceCost,
    FieldEnum.RecommendedRentNew,
    FieldEnum.AvailableDates,
    FieldEnum.MinNights,
    FieldEnum.IsRentOnlyWeekly,
    FieldEnum.CheckInDays,
  ].includes(type)
}

export type UpdateFieldValueAction =
  | UserTypeAction
  | HomeTypeAction
  | TenureTypeAction
  | FirstHandAction
  | StudentHomeAction
  | SeniorHomeAction
  | CorporateHomeAction
  | PositionAction
  | LocalityAction
  | PostalCodeAction
  | RouteAction
  | StreetNumberAction
  | ApartmentNumberAction
  | CountryCodeAction
  | CountryAction
  | FormattedAddressAction
  | FloorAction
  | BuildingFloorsAction
  | DurationAction
  | OwnHomeAction
  | SharedAction
  | TraitsAction
  | MinSquareMetersAction
  | MaxSquareMetersAction
  | RoomCountAction
  | MinRoomCountAction
  | MaxRoomCountAction
  | ToiletCountAction
  | BedCountAction
  | BedroomCountAction
  | HasKitchenAction
  | SquareMetersAction
  | TenantCountAction
  | TitleAction
  | DescriptionAction
  | HouseRulesAction
  | RentalTypeAction
  | NumberOfHomesAction
  | GuaranteeAction
  | InsuranceAction
  | RentAction
  | WaterFeeAction
  | CompanyNameAction
  | OrganizationNumberAction
  | KitchenRenovationAction
  | BathroomRenovationAction
  | BuildYearAction
  | HousingAssociationAction
  | EnergyClassAction
  | UploadsAction
  | InsuranceCostAction
  | GuaranteeCostAction
  | RecommendedRentAction
  | IsExternalAction
  | ExternalApplicationUrlAction
  | ExternalInfoPageAction
  | ExternalEmailAction
  | ResponsibleForCleaningAction
  | CleaningFeeAction
  | CancellationPolicyNameAction
  | AvailableDatesAction
  | MinNightsAction
  | IsRentOnlyWeeklyAction
  | CheckInDaysAction
  | ElectricityFeeAction

export type SetErrorsAction = { type: 'SET_ERRORS'; payload: { field: FieldEnum; errors: ValidationErrors } }

type ValidationErrors = string[]

export type ListingStoreValues = (Pick<
  Required<CreateHomeInput>,
  | 'firsthand'
  | 'studentHome'
  | 'seniorHome'
  | 'corporateHome'
  | 'duration'
  | 'ownHome'
  | 'rent'
  | 'waterFeePerTenant'
  | 'roomCount'
  | 'bedCount'
  | 'bedroomCount'
  | 'toiletCount'
  | 'hasKitchen'
  | 'squareMeters'
  | 'numberOfHomes'
  | 'tenureType'
  | 'apartmentNumber'
  | 'floor'
  | 'buildingFloors'
> &
  Pick<
    Required<CreateLocationInput>,
    'locality' | 'route' | 'postalCode' | 'streetNumber' | 'country' | 'countryCode' | 'formattedAddress'
  > &
  Pick<
    Required<UpdateHomeInput>,
    | 'type'
    | 'traits'
    | 'shared'
    | 'tenantCount'
    | 'title'
    | 'description'
    | 'houseRules'
    | 'qasaGuarantee'
    | 'insurance'
    | 'kitchenRenovationYear'
    | 'bathroomRenovationYear'
    | 'buildYear'
    | 'housingAssociation'
    | 'energyClass'
    | 'externalInfoPage'
    | 'externalApplicationUrl'
    | 'externalEmail'
    | 'responsibleForCleaning'
    | 'cleaningFee'
    | 'cancellationPolicyName'
    | 'minSquareMeters'
    | 'maxSquareMeters'
    | 'minRoomCount'
    | 'maxRoomCount'
  >) &
  Pick<
    NonNullable<ListingQuery['home']>,
    'qasaGuaranteeCost' | 'insuranceCost' | 'recommendedRentNew' | 'uploads' | 'electricityFee' | 'origin'
  > & {
    landlordServices?: {
      insurance: Omit<NonNullable<ListingQuery['home']>['landlordServices']['insurance'], '__typename'>
      qasaGuarantee: Omit<
        NonNullable<ListingQuery['home']>['landlordServices']['qasaGuarantee'],
        '__typename'
      >
    }
    landlordFees?: NonNullable<ListingQuery['home']>['landlordFees']
    position: ListingPosition
    isProfessional: boolean | null
    isPremium: boolean | null
    isExternal: boolean | null
    companyName: string | null
    orgNumber: string | null
    rentalType: HomeRentalTypeEnum
    minNights: number | null
    isRentOnlyWeekly: boolean | null
    checkInDays: DayOfTheWeekTypeEnum[]
    availableDates: string[] | null
    market: NonNullable<ListingQuery['home']>['market'] | null
    pricingModel: Pick<
      NonNullable<ListingQuery['home']>['pricingModel'],
      'insuranceCostFactor' | 'qasaGuaranteeCostFactor'
    >
    currency?: NonNullable<ListingQuery['home']>['currency']
  }
export type ListingStoreErrors = { [k in keyof ListingStoreValues]?: ValidationErrors }
export type ListingStoreDirtyFields = { [k in keyof ListingStoreValues]?: boolean }

export type ListingStore = {
  values: ListingStoreValues
  errors: ListingStoreErrors
  dirtyFields: ListingStoreDirtyFields
}
