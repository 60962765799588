/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable complexity */
// This whole file is copied from apollo/clients relayStylePagination
// I have changed the file to a .ts, which is why there are a lot of "any"s and so on.
// I have added some comments to explain what the original code does.
// My modifications are explained in comments and marked with change from original.
// You can read more on pagination here:
// https://www.apollographql.com/docs/react/pagination/core-api/
// https://www.apollographql.com/docs/react/pagination/cursor-based/
// @magnuslh 28-11-2023
import { __assign, __rest as __rest_1, __spreadArray, __rest } from 'tslib'
import { mergeDeep } from '@apollo/client/utilities'

type PageInfo = {
  hasPreviousPage: boolean
  hasNextPage: boolean
  startCursor: string
  endCursor: string
}
export function newInboxPagination(keyArgs: string[] | false | undefined = false) {
  return {
    keyArgs,
    read(
      existing: { edges: any[]; pageInfo: { startCursor: any; endCursor: any } | null } | null,
      _a: { canRead: any; readField: any },
    ) {
      const canRead = _a.canRead,
        readField = _a.readField
      if (!existing) return existing
      const edges: any[] = []
      let firstEdgeCursor = ''
      let lastEdgeCursor = ''
      existing.edges.forEach(function (edge) {
        if (canRead(readField('node', edge))) {
          edges.push(edge)
          if (edge.cursor) {
            firstEdgeCursor = firstEdgeCursor || edge.cursor || ''
            lastEdgeCursor = edge.cursor || lastEdgeCursor
          }
        }
      })
      const _b = existing.pageInfo || { startCursor: null, endCursor: null }
      const startCursor = _b.startCursor
      const endCursor = _b.endCursor
      return __assign(__assign({}, getExtras(existing)), {
        edges,
        pageInfo: __assign(__assign({}, existing.pageInfo), {
          startCursor: startCursor || firstEdgeCursor,
          endCursor: endCursor || lastEdgeCursor,
        }),
      })
    },
    merge(
      existing: { edges: any; pageInfo: any } | null,
      incoming: { edges: any; pageInfo: any } | null,
      _a: { args: any; isReference: any; readField: any },
    ) {
      const args = _a.args,
        isReference = _a.isReference,
        readField = _a.readField

      if (!existing) {
        existing = makeEmptyData()
      }
      if (!incoming) {
        return existing
      }
      let incomingEdges = incoming.edges
        ? incoming.edges.map(function (edge: { cursor: any; node: any }) {
            if (isReference((edge = __assign({}, edge)))) {
              edge.cursor = readField('cursor', edge)
            }
            return edge
          })
        : []

      if (incoming.pageInfo) {
        const pageInfo1 = incoming.pageInfo
        const startCursor = pageInfo1.startCursor
        const endCursor = pageInfo1.endCursor
        const firstEdge = incomingEdges[0]
        const lastEdge = incomingEdges[incomingEdges.length - 1]
        // Making sure that the first and last edges have cursors corresponding to the pageInfo
        if (firstEdge && startCursor) {
          firstEdge.cursor = startCursor
        }
        if (lastEdge && endCursor) {
          lastEdge.cursor = endCursor
        }
        // If the pageInfo did not include start and end cursors we make sure it corresponds to the edges
        const firstCursor = firstEdge && firstEdge.cursor
        if (firstCursor && !startCursor) {
          incoming = mergeDeep(incoming, {
            pageInfo: {
              startCursor: firstCursor,
            },
          })
        }
        const lastCursor = lastEdge && lastEdge.cursor
        if (lastCursor && !endCursor) {
          incoming = mergeDeep(incoming, {
            pageInfo: {
              endCursor: lastCursor,
            },
          })
        }
      }

      let prefix = [...existing.edges] // Change from original
      let suffix = []
      if (args && args.after) {
        const index = prefix.findIndex(function (edge: { cursor: string }) {
          return edge.cursor === args.after
        })
        if (index >= 0) {
          prefix = prefix.slice(0, index + 1)
        }
      } else if (args && args.before) {
        const index = prefix.findIndex(function (edge: { cursor: string }) {
          return edge.cursor === args.before
        })
        suffix = index < 0 ? prefix : prefix.slice(index)
        prefix = []
      }
      // Original Version:
      // else if (incoming.edges) {
      // prefix = []
      //}

      // ----- Begin change from original
      // In this case we are not paginating, so its either an initial fetch, refetch or poll
      else if (incoming.edges) {
        // If the incoming edges are more than the existing ones, we should just use the incoming edges
        // By setting prefix to an empty array we essentially discard existing edges.
        if (existing.edges.length < incoming.edges.length) {
          prefix = []
        } else {
          /**
           * Explanation:
           * The incomingEdges are the result of a poll, but the poll is only polling the first 12 results.
           * We already have existing edges that we dont want to discard.
           * Instead we want to replace the first 12 results of the existing edges with the incoming edges.
           * But those first 12 could contain a conversation that was just updated (we received a new message)
           * In that case we already have that conversation in our existing edges, so we need to remove that from our existing as well.
           *
           * In this solution we first delete any duplicates from the existing edges.
           * We then insert the incoming edges before the existing edges.
           * This should result in a list where the first 12 results are the 12 incoming edges we just polled for.
           * The rest of the list is the list as it were, but any duplicates have been removed.
           */

          // Remove potential duplicates
          for (let i = 0; i < incomingEdges.length; ++i) {
            // We need to use the __ref which contains the ID of the conversation (as the cursor may have changed dure to it being based on last update)
            const index = prefix.findIndex(
              (existingEdge: { node: { __ref: string } }) =>
                incomingEdges[i].node.__ref === existingEdge.node.__ref,
            )
            // If we found a duplicate remove it from the existing edges
            if (index >= 0) prefix.splice(index, 1)
          }
          // Insert the incoming edges at the beginning of the list. They will be the first 12 conversations in the inbox
          prefix = [...incomingEdges, ...prefix]
          // on line 183 we will append the incomingEdges to the prefix, but we have already handled the incoming edges here.
          // Therefore we set incomingEdges to an empty array, so that only the prefix is used.
          incomingEdges = []

          // The incoming pageInfo is used later to determine the final pageInfo.
          // Here we pretend the incoming polled result has the endCursor of the existing pageInfo
          // This is to avoid fetch more being called with the wrong end cursor
          incoming = mergeDeep(incoming, {
            pageInfo: {
              endCursor: existing.pageInfo.endCursor,
              hasNextPage: existing.pageInfo.hasNextPage,
            },
          })
        }
      }
      // ----- End change

      // Insert the incomingEdges at the cursor position.
      // In our case we always append the incoming edges to the existing ones, as we never use the "before" argument
      const edges = __spreadArray(
        __spreadArray(__spreadArray([], prefix, true), incomingEdges, true),
        suffix,
        true,
      )
      const pageInfo = __assign(__assign({}, incoming.pageInfo), existing.pageInfo)
      if (incoming.pageInfo) {
        const _b = incoming.pageInfo,
          hasPreviousPage = _b.hasPreviousPage,
          hasNextPage = _b.hasNextPage,
          startCursor = _b.startCursor,
          endCursor = _b.endCursor,
          extras = __rest_1(_b, ['hasPreviousPage', 'hasNextPage', 'startCursor', 'endCursor'])
        Object.assign(pageInfo, extras)

        if (!prefix.length) {
          if (void 0 !== hasPreviousPage) pageInfo.hasPreviousPage = hasPreviousPage
          if (void 0 !== startCursor) pageInfo.startCursor = startCursor
        }
        if (!suffix.length) {
          if (void 0 !== hasNextPage) pageInfo.hasNextPage = hasNextPage
          if (void 0 !== endCursor) pageInfo.endCursor = endCursor
        }
      }
      const result = __assign(__assign(__assign({}, getExtras(existing)), getExtras(incoming)), {
        edges,
        pageInfo,
      })

      return result
    },
  }
}
const getExtras = function (obj: { edges: any[]; pageInfo: PageInfo } | object) {
  return __rest(obj, NOT_EXTRAS)
}
const NOT_EXTRAS = ['edges', 'pageInfo']
function makeEmptyData() {
  return {
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: '',
      endCursor: '',
    },
  }
}
