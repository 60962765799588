import { FormProvider } from 'react-hook-form'
import { OccupationTypeEnum } from '@qasa/graphql'

import { useWizard } from '../../hooks/use-wizard'

import { useOccupationForm } from './use-occupation-form'
import type { OccupationType } from './tenant-occupation'
import { OccupationTypeStep } from './form-steps/occupation-type-step'
import { OccupationDetailsStep } from './form-steps/occupation-details-step'
import { OccupationOtherCheckStep } from './form-steps/occupation-other-check-step'

export const OCCUPATION_FORM_STEP_QUERY_PARAM = 'occupationStep'

export enum OccupationFormSteps {
  Type = 'Type',
  OtherCheck = 'OtherCheck',
  Details = 'Details',
}
export type OccupationFormStepsProps = {
  goNext: () => void
  goBack: () => void
  isLoadingNext: boolean
  occupationType?: OccupationTypeEnum | null
  occupationToEdit?: OccupationType | null
}

type OccupationFormProps = {
  onCancel: () => void
  onComplete: () => void
  occupation?: OccupationType | null
  shouldShowDataVisibilityInfo?: boolean
}
export function OccupationForm({
  onCancel,
  occupation,
  onComplete,
  shouldShowDataVisibilityInfo = false,
}: OccupationFormProps) {
  const { methods, onSubmit, isUpsertOccupationLoading } = useOccupationForm({
    onUpsertComplete: onComplete,
    occupation,
  })
  const { watch, handleSubmit } = methods

  const occupationTypeValue = watch('occupationType')

  const occupationSteps = [
    {
      id: OccupationFormSteps.Type,
      Component: OccupationTypeStep,
      guard: () => !occupation,
    },
    {
      id: OccupationFormSteps.OtherCheck,
      Component: OccupationOtherCheckStep,
      guard: () => occupationTypeValue === OccupationTypeEnum.other,
    },
    {
      id: OccupationFormSteps.Details,
      Component: OccupationDetailsStep,
      guard: () => Boolean(occupationTypeValue),
    },
  ]
  const { currentStep, goNext, goBack } = useWizard(occupationSteps, {
    onCompleted: () => handleSubmit(onSubmit)(),
    onCancelled: onCancel,
    queryParamName: OCCUPATION_FORM_STEP_QUERY_PARAM,
    queryParamUpdateType: 'replace',
  })

  if (!currentStep) {
    return null
  }

  return (
    <FormProvider {...methods}>
      <form id="occupation-form">
        <currentStep.Component
          goNext={goNext}
          goBack={goBack}
          isLoadingNext={isUpsertOccupationLoading}
          occupationType={occupationTypeValue}
          occupationToEdit={occupation}
          shouldShowDataVisibilityInfo={shouldShowDataVisibilityInfo}
        />
      </form>
    </FormProvider>
  )
}
