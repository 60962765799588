import { BRAND_CONFIG } from '../config/brand-configurations'
import { getCurrentLocale } from '../translations/language-loader/language-utils'

export type LanguageCode = 'sv' | 'en' | 'fi' | 'fr' | 'nb'

type FormatNumber = {
  amount: number
  hasCurrency?: boolean
  currencyOverride?: string | null
  minimumFractionDigits?: number
  maximumFractionDigits?: number
}
/** @deprecated Use `formatNumber` function from @qasa/app/src/utils/number instead. */
export const formatNumber = ({
  amount,
  hasCurrency = true,
  currencyOverride = null,
  maximumFractionDigits = 0,
  minimumFractionDigits = 0,
}: FormatNumber) => {
  return new Intl.NumberFormat(getCurrentLocale(), {
    style: hasCurrency ? 'currency' : 'decimal',
    currency: currencyOverride ?? BRAND_CONFIG.allowedCurrencies[0],
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(amount)
}
