import { Button, Divider, Heading, Paragraph, PlusIcon, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { OccupationCard } from '../../ui-page-modules/profile/view-profile-sections/occupation-card'

import { DeleteOccupationDialog } from './delete-occupation-dialog'
import type { OccupationType } from './tenant-occupation'
import { OCCUPATION_LIMIT } from './occupation.utils'

const OccupationsWrapper = styled(Stack)({
  width: '100%',
})

type OccupationsListProps = {
  occupations: OccupationType[]
  onOpenForm: (occupation?: OccupationType) => void
  onDelete: () => void
}
export function OccupationsList({ occupations, onOpenForm, onDelete }: OccupationsListProps) {
  const { t } = useTranslation('edit_profile')
  const [occupationIdToDelete, setOccupationIdToDelete] = useState<string | null>(null)
  const isDeleteOccupationOpen = Boolean(occupationIdToDelete)

  const hasOneOccupationLeft = occupations?.length === OCCUPATION_LIMIT - 1
  const hasReachedOccupationLimit = occupations?.length === OCCUPATION_LIMIT

  return (
    <Stack gap="8x" alignItems="flex-start">
      <Heading size="lg">{t('profile:sections_title.OCCUPATION')}</Heading>
      <OccupationsWrapper gap="8x" divider={<Divider />}>
        {occupations.length ? (
          occupations.map((occupation) => (
            <OccupationCard
              key={occupation.id}
              occupation={occupation}
              handleDeleteOccupation={() => setOccupationIdToDelete(occupation.id)}
              handleEditOccupation={() => onOpenForm(occupation)}
            />
          ))
        ) : (
          <Paragraph>{t('form.occupation.no_occupations')}</Paragraph>
        )}
      </OccupationsWrapper>
      <Button
        size="sm"
        variant="tertiary"
        disabled={hasReachedOccupationLimit}
        onClick={() => onOpenForm()}
        iconLeft={PlusIcon}
      >
        {hasReachedOccupationLimit
          ? t('form.occupation.max_reached')
          : t('form.occupation.add', { context: hasOneOccupationLeft ? 'last' : undefined })}
      </Button>
      <DeleteOccupationDialog
        isOpen={isDeleteOccupationOpen}
        onClose={() => setOccupationIdToDelete(null)}
        occupationId={occupationIdToDelete!}
        onDeleteComplete={onDelete}
      />
    </Stack>
  )
}
