import type { EffectCallback } from 'react'
import { useEffect } from 'react'

/**
 * @deprecated This is an anti-pattern since all components should be resilient to being remounted.
 * For alternative solutions [see this section in the React docs](https://react.dev/learn/you-might-not-need-an-effect#initializing-the-application).
 */
export const useEffectOnMount = (effect: EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, [])
}
