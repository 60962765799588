import { graphql } from '@qasa/graphql'

export const HOME_DRAFTS_AND_PREVIOUSLY_SIGNED_HOMES_QUERY = graphql(`
  query HomeDraftsAndPreviouslySignedHomesQuery($first: Int!, $rentalTypes: [HomeRentalTypeEnum!]) {
    homeDrafts: homes(homeStatuses: [pending], first: $first, rentalTypes: $rentalTypes) {
      totalCount
      edges {
        node {
          createdAt
          location {
            id
            route
            streetNumber
          }
          id
          uploads {
            id
            url
            metadata {
              order
              primary
            }
          }
          roomCount
          type
          shared
          squareMeters
        }
      }
    }
    previouslySignedHomes: homes(
      homeStatuses: [contract_ended, rented_out]
      first: $first
      rentalTypes: $rentalTypes
    ) {
      totalCount
      edges {
        node {
          publishedAt
          location {
            id
            route
            streetNumber
          }
          id
          type
          shared
          uploads {
            id
            url
            metadata {
              order
              primary
            }
          }
          roomCount
          squareMeters
          homeTemplates {
            id
          }
        }
      }
    }
  }
`)
