import { useEffect } from 'react'
import { ampli, ampliExperiment } from '@qasa/ampli/p2'

import { getEnv } from '../../env'

const API_KEY = getEnv('AMPLITUDE_API_KEY')
const EXPERIMENT_DEPLOYMENT_KEY = getEnv('AMPLITUDE_EXPERIMENT_DEPLOYMENT_ID')

async function initializeAmpli({ isDisabled }: { isDisabled: boolean }) {
  if (!API_KEY) {
    return
  }

  await ampli.load({
    client: {
      configuration: {
        defaultTracking: {
          pageViews: false,
          sessions: true,
        },
        trackingOptions: {
          ipAddress: false,
        },
        optOut: isDisabled,
      },
      apiKey: API_KEY,
    },
  }).promise

  if (EXPERIMENT_DEPLOYMENT_KEY) {
    await ampliExperiment.load(EXPERIMENT_DEPLOYMENT_KEY)
  }
}

export function toggleAmplitudeActive({
  shouldActivate,
  shouldTrackInitialPageView,
}: {
  shouldActivate: boolean
  shouldTrackInitialPageView: boolean
}) {
  if (ampli.isLoaded) {
    ampli.client.setOptOut(!shouldActivate)

    if (shouldTrackInitialPageView) {
      ampli.amplitudePageViewed({ '[Amplitude] Page URL': window.location.href })
    }

    return
  }

  initializeAmpli({ isDisabled: !shouldActivate })
}

export const useTrackClickEvent = () => {
  const handleTrackButtonClick = (target: HTMLElement) => {
    ampli.buttonClicked({
      id: target.id,
      class: target.className,
      text: target.innerText.trim(),
      label: target.getAttribute('aria-label') ?? '',
      buttonType: target.getAttribute('type') ?? '',
      '[Amplitude] Page URL': window.location.href,
    })
  }

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement
      const closestButton = targetElement.closest('button')

      if (closestButton) {
        handleTrackButtonClick(closestButton)
      }
    }

    document.addEventListener('click', handleClick, true)
    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [])
}
