import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'
import { ChevronDownIcon, useBreakpointValue } from '@qasa/qds-ui'
import { styled } from '@qasa/ui/web'

import { PillButton } from '../../../../components/pill-button'
import type { PillButtonProps } from '../../../../components/pill-button'

const IconWrapper = styled('span')<{
  isUpward: boolean
}>(({ isUpward }) => ({
  display: 'inline-flex',
  transform: isUpward ? 'rotate(180deg)' : 'none',
}))

type PillDropdownButtonProps = {
  isOpen?: boolean
} & Omit<PillButtonProps, 'icon' | 'iconPlacement'>

export const PillDropdownButton = forwardRef(
  (props: PillDropdownButtonProps, forwardedRef: ForwardedRef<HTMLButtonElement>) => {
    const { isActive, children, onPress, isOpen = false, ...rest } = props
    const isMobile = useBreakpointValue({ base: true, md: false })
    return (
      <PillButton
        size={isMobile ? 'small' : 'medium'}
        isActive={isActive || isOpen}
        onPress={onPress}
        ref={forwardedRef}
        {...rest}
      >
        {children}
        <IconWrapper isUpward={isOpen}>
          <ChevronDownIcon size={16} />
        </IconWrapper>
      </PillButton>
    )
  },
)
