import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import type { UpdateUserInput, UpdateUserMutationMutation } from '@qasa/graphql'

import { UPDATE_USER } from '../../../data/graphql/mutations'

type EditProfileParams = {
  defaultValues?: UpdateUserInput
  onCompleted: (data?: UpdateUserMutationMutation) => void
  onError: () => void
  userUid: string
  shouldFocusError?: boolean
}
export const useEditProfile = ({
  onCompleted,
  onError,
  userUid,
  defaultValues = {},
  shouldFocusError = true,
}: EditProfileParams) => {
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      if (data?.updateUser?.errors) {
        onError()
      } else {
        onCompleted(data)
      }
    },
    onError,
  })
  const formMethods = useForm<UpdateUserInput>({ defaultValues, shouldFocusError })

  const onSubmit = async (formValues: UpdateUserInput) =>
    updateUser({
      variables: {
        input: formValues,
        uid: userUid,
      },
    })

  return {
    formMethods,
    onSubmit,
  }
}
