import { Heading, Button, Paragraph, Stack, IconButton, ArrowLeftIcon } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { Dialog } from '@qasa/app/src/components/dialog'

import type { CreditVerificationStepProps } from '../credit-verification-step'
import { DialogAndWizardActionsFooter } from '../../../../ui-shared/dialog-and-wizard-actions-footer'

const Wrapper = Stack

export function CreditVerificationIntro({ goNext, goBack }: CreditVerificationStepProps) {
  const { t } = useTranslation('create_application_with_verifications')

  return (
    <Dialog.Body>
      <Wrapper gap="8x">
        <Heading size="lg" as="h3">
          {t('credit_verification_step.intro.title')}
        </Heading>
        <Paragraph size="xl">{t(`credit_verification_step.intro.description`)}</Paragraph>
        <DialogAndWizardActionsFooter>
          <IconButton
            type="button"
            icon={ArrowLeftIcon}
            variant="tertiary"
            label={t('commons:back')}
            onClick={() => goBack()}
          />
          <Button onClick={() => goNext()}>{t('commons:next')}</Button>
        </DialogAndWizardActionsFooter>
      </Wrapper>
    </Dialog.Body>
  )
}
