import type { FieldValues } from 'react-hook-form'
import { useController } from 'react-hook-form'
import { Stack } from '@qasa/qds-ui'

import type { CheckboxCardProps } from '../../../ui-shared/_core/controls/checkbox'
import { CheckboxGroup, CheckboxCard } from '../../../ui-shared/_core/controls/checkbox'
import type { CheckboxGroupProps } from '../../../ui-shared/_core/controls/checkbox/checkbox-group'
import { ErrorMessage } from '../../../ui-shared/_core/error-message'

import type { InheritedUseControllerProps } from './form-fields.types'

type FormCheckboxGroupProps = {
  options: Pick<CheckboxCardProps, 'label' | 'value'>[]
  errorMessage?: string
} & Pick<CheckboxGroupProps, 'label' | 'isLabelVisuallyHidden'>

export function FormCheckboxGroup<TFormValues extends FieldValues>({
  options,
  label,
  isLabelVisuallyHidden = false,
  errorMessage,
  ...formProps
}: FormCheckboxGroupProps & InheritedUseControllerProps<TFormValues>) {
  const { field } = useController(formProps)
  const { onChange, value, ref } = field
  return (
    <CheckboxGroup {...{ isLabelVisuallyHidden, label, onChange, value, ref }}>
      <Stack gap="2x">
        {options.map((item, index) => (
          <CheckboxCard key={index} {...item} />
        ))}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Stack>
    </CheckboxGroup>
  )
}
