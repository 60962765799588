import styled from '@emotion/styled'
import { Button, Heading, Paragraph, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { BadgeFilledIcon, BadgeIcon } from '../../../ui-shared/profile-badges'
import { getPath } from '../../../routing/get-path'
import { useAuthContext } from '../../../context/auth-context'

const GrayBox = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.colors.core.gray10,
  borderRadius: theme.radii.md,
  padding: theme.spacing['6x'],
}))

type IdVerificationBoxProps = {
  title: string
  description?: string
  customRedirectPath?: string
}
export function IdVerificationBox({ title, description, customRedirectPath }: IdVerificationBoxProps) {
  const { t } = useTranslation('publish_home_overlay')
  const { authBody } = useAuthContext()
  const { pathname, search } = useLocation()
  const { push } = useHistory()
  const redirectPath = customRedirectPath ?? pathname + search

  const hasVerifiedAccessToken = authBody?.private.verifiedAccess

  return (
    <GrayBox gap="4x" alignItems="flex-start">
      <Stack gap="2x">
        <Stack direction="row" gap="2x" alignItems="center">
          {hasVerifiedAccessToken ? <BadgeFilledIcon size={16} /> : <BadgeIcon size={16} />}
          <Heading size="2xs">{title}</Heading>
        </Stack>
        {description && <Paragraph size="sm">{description}</Paragraph>}
      </Stack>
      {!hasVerifiedAccessToken && (
        <Button
          variant="secondary"
          onClick={() =>
            push(
              getPath('idVerification', {
                redirectUrl: redirectPath,
              }),
            )
          }
        >
          {t('required_id_verification.verify_id')}
        </Button>
      )}
    </GrayBox>
  )
}
