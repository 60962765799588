import { Select as QdsSelect } from '@qasa/qds-ui'
import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'

import type { SelectProps } from './select.types'

type Ref = ForwardedRef<HTMLSelectElement>

export const Select = forwardRef(
  (
    {
      name,
      label,
      helperText,
      errorMessage,
      options,
      placeholder,
      isInvalid = false,
      isDisabled = false,
      value,
      onChange,
    }: SelectProps,
    ref: Ref,
  ) => {
    return (
      <QdsSelect
        ref={ref}
        name={name}
        label={label}
        helperText={helperText}
        errorMessage={errorMessage}
        placeholder={placeholder}
        value={value ?? undefined}
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        onChange={onChange}
      >
        {options.map(({ value, label, isDisabled }) => (
          <QdsSelect.Option key={value} isDisabled={isDisabled} value={value}>
            {label}
          </QdsSelect.Option>
        ))}
      </QdsSelect>
    )
  },
)
