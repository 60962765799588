'use client'

import { ReactElement, useEffect, useState } from 'react'
import { Variant } from '@amplitude/experiment-js-client'
import { ampliExperiment } from './ampli-experiment-client'
import { LoadingDots } from '@qasa/ui'

type Props = {
  flagName: string
  variants: {
    default: ReactElement
    [key: string]: ReactElement
  }
}

const AmpliExperiment = ({ flagName, variants }: Props) => {
  const [variant, setVariant] = useState<Variant | undefined | null>(null)
  const isLoading = ampliExperiment.isLoading

  useEffect(() => {
    if (isLoading) {
      return
    }

    setVariant(ampliExperiment.getVariant(flagName))
  }, [flagName, isLoading])

  if (isLoading || variant === null) {
    return <LoadingDots />
  }

  if (!variant?.value) {
    return variants.default
  }

  const component = variants[variant.value]

  if (!component) {
    return variants.default
  }

  return component
}

export default AmpliExperiment
