import type { ExtractRouteParams } from 'react-router'

type RouteConfig = {
  path: string
  queryParams?: readonly string[]
}

export const routes = {
  FindHome: { path: '/find-home', queryParams: ['homeTypes', 'searchAreas'] },
  FindVacation: { path: '/find-vacation' },
  FindTenant: { path: '/find-tenant' },
  Favorites: { path: '/favorites' },
  Home: {
    path: '/home/:id',
    queryParams: [
      'showGallery',
      'index',
      'showVirtualTour',
      'viewingOpenedFrom',
      'isPublishHomeDialogOpen',
      'applicationStep',
      'subFlow',
      'claimStep',
      'success',
      'transaction_id',
    ],
  },
  InstantSign: {
    path: '/home/:id/instant-sign/:step?',
  },
  IdVerification: {
    path: '/id-verification/:type?',
    queryParams: ['redirectUrl'],
  },
  CreateListing: { path: '/create-listing' },
  EditListing: { path: '/edit-listing/:homeId' },
  Leases: { path: '/leases' },
  Invoices: { path: '/leases/:contractId/invoices', queryParams: ['prevRoute', 'id'] },
  Inbox: { path: '/messages' },
  Chat: {
    path: '/messages/:conversationId',
    queryParams: ['status', 'initialContractPage', 'lease', 'vacation', 'createContractRequirement'],
  },
  HomeqApplications: {
    path: '/messages/homeq-applications',
  },
  MyListings: { path: '/my-listings' },
  Profile: { path: '/profile/:id', queryParams: ['homeId'] },
  Settings: { path: '/settings', queryParams: ['section', 'field'] },
  Help: { path: '/help' },
} as const satisfies Record<string, RouteConfig>

export type RouteNamesWithQueryParams = {
  [K in keyof Routes]: Routes[K] extends { queryParams: LegitimateAny } ? K : never
}[keyof Routes]

export type QueryParamsOfRoute<T extends RouteNamesWithQueryParams> = Record<
  Routes[T]['queryParams'][number],
  string | undefined | null | number | boolean
>

export type Routes = typeof routes
export type RouteName = keyof Routes
export type QueryParams<T extends RouteName> = T extends RouteNamesWithQueryParams
  ? Partial<QueryParamsOfRoute<T>>
  : undefined

export type RouteParams<T extends RouteName> = ExtractRouteParams<(typeof routes)[T]['path'], string>

type EmptyObject = {
  [K in LegitimateAny]: never
}
export type UrlParams<T extends string> =
  ExtractRouteParams<T> extends EmptyObject ? undefined : ExtractRouteParams<T, string>
