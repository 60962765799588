import { Stack, Paragraph, styled } from '@qasa/ui'

const OuterProgressBar = styled(Stack)<{ isBackgroundColorVisible: boolean }>(
  ({ theme, isBackgroundColorVisible }) => ({
    height: 20,
    borderRadius: theme.radii.sm,
    backgroundColor: isBackgroundColorVisible ? theme.colors.bg.brandTertiary : 'transparent',
  }),
)
const InnerProgressBar = styled(Stack)<{ widthPercentage: number }>(({ widthPercentage, theme }) => ({
  width: `${widthPercentage}%`,
  backgroundColor: theme.colors.bg.brandPrimary,
  borderRadius: theme.radii.sm,
  transition: 'width 1s ease',
  position: 'relative',
}))

/**
 * This component is used because the InnerProgressBar `Stack` requires children and styling the completeness percentage
 * with absolute position was much more complicated than just adding this dummy element.
 */
const InvisibleParagraph = styled(Paragraph)({
  display: 'none',
})

const ONE_HUNDRED = 100

type Props = {
  percentage: number
}
export function ProgressBar({ percentage }: Props) {
  const isUnderOneHundreddPercent = percentage < ONE_HUNDRED
  const completenessWithPercentText = `${percentage}%`
  return (
    <OuterProgressBar direction="row" gap="1x" isBackgroundColorVisible={isUnderOneHundreddPercent}>
      <InnerProgressBar widthPercentage={percentage}>
        <InvisibleParagraph>{completenessWithPercentText}</InvisibleParagraph>
      </InnerProgressBar>
      <Paragraph size="sm">{completenessWithPercentText}</Paragraph>
    </OuterProgressBar>
  )
}
