import styled from '@emotion/styled'
import type {
  GetPropsCommonOptions,
  UseComboboxGetComboboxPropsOptions,
  UseComboboxGetInputPropsOptions,
  UseComboboxGetItemPropsOptions,
  UseComboboxGetMenuPropsOptions,
} from 'downshift'
import { useTranslation } from 'react-i18next'
import { Paragraph, Heading, MapPinIcon, ArrowRightIcon, SearchIcon, InputBase, Stack } from '@qasa/qds-ui'

import { AutocompleteDropdown, AutocompleteDropdownItem } from '../../ui-shared/autocomplete-dropdown'
import { GoogleLogo } from '../../assets/logos/google-logo'

const InputContainer = styled.div({
  position: 'relative',
})
const AddressInput = styled(InputBase)(({ theme }) => ({
  paddingLeft: theme.spacing['12x'],
}))
const SearchIconContainer = styled.div({
  position: 'absolute',
  top: '50%',
  left: 16,
  transform: 'translateY(-50%)',
})
const AddressAutocompleteDropdown = styled(AutocompleteDropdown)(({ theme }) => ({
  position: 'absolute',
  top: `calc(100% + ${theme.spacing['2x']})`,
  width: '100%',
  borderRadius: theme.radii.md,
  boxShadow: theme.shadows.lg,
  maxHeight: 'calc(100% * 6)', // six input fields high
  paddingTop: theme.spacing['2x'],
  paddingBottom: theme.spacing['2x'],
  '&:empty': {
    padding: 0,
  },
}))
const MapPinIconContainer = styled.div(({ theme }) => ({
  marginRight: theme.spacing['3x'],
  flexShrink: 0,
}))

const ArrowRightContainer = styled.div(({ theme }) => ({
  marginRight: theme.spacing['2x'],
}))

const DropdownBottomContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing['4x'],
  paddingRight: theme.spacing['4x'],
  paddingTop: theme.spacing['3x'],
  paddingBottom: theme.spacing['1x'],
  flexShrink: 0,
  gap: theme.spacing['2x'],
  [theme.mediaQueries.smUp]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}))
const TextButton = styled.button({
  display: 'flex',
  alignItems: 'center',
})

type Props = {
  isOpen: boolean
  isFetchingSuggestions: boolean
  getMenuProps: (
    options?: UseComboboxGetMenuPropsOptions | undefined,
    otherOptions?: GetPropsCommonOptions | undefined,
  ) => LegitimateAny
  getInputProps: (
    options?: UseComboboxGetInputPropsOptions | undefined,
    otherOptions?: GetPropsCommonOptions | undefined,
  ) => LegitimateAny
  getItemProps: (
    options: UseComboboxGetItemPropsOptions<google.maps.places.QueryAutocompletePrediction>,
  ) => LegitimateAny
  getComboboxProps: (
    options?: UseComboboxGetComboboxPropsOptions | undefined,
    otherOptions?: GetPropsCommonOptions | undefined,
  ) => LegitimateAny
  highlightedIndex: number
  openMenu: () => void
  onSelectManualInputOption?: () => void
  formattedAddress: string | null
  locationSuggestions: google.maps.places.QueryAutocompletePrediction[]
}
export function AddressAutoCompleteField({
  isOpen,
  isFetchingSuggestions,
  getMenuProps,
  getInputProps,
  getComboboxProps,
  highlightedIndex,
  getItemProps,
  openMenu,
  formattedAddress,
  onSelectManualInputOption,
  locationSuggestions,
}: Props) {
  const { t } = useTranslation('listing')
  const shouldShowDropdownContent = isOpen && formattedAddress
  const shouldShowLoadingMessage = shouldShowDropdownContent && isFetchingSuggestions
  const shouldShowNoHits =
    shouldShowDropdownContent && !isFetchingSuggestions && locationSuggestions.length === 0

  const handleOpenMenu = () => {
    if (!isOpen) {
      openMenu()
    }
  }
  return (
    <InputContainer {...getComboboxProps()}>
      <AddressInput
        {...getInputProps({
          value: formattedAddress || '',
          onClick: handleOpenMenu,
          onFocus: handleOpenMenu,
          autoComplete: 'off',
        })}
        // 1password was causing down arrow key clicks to open 1password.
        // Assuming some users experience the same very annoying behavior,
        // we're adding this attribute to disable 1password from interacting with this input.
        data-1p-ignore
      />
      <SearchIconContainer>
        <SearchIcon size={16} />
      </SearchIconContainer>

      <AddressAutocompleteDropdown {...getMenuProps()}>
        {(shouldShowLoadingMessage || shouldShowNoHits) && (
          <AutocompleteDropdownItem>
            <Paragraph color="subtle">
              {shouldShowLoadingMessage ? t('address.fetching_suggestions') : t('address.no_hits')}
            </Paragraph>
          </AutocompleteDropdownItem>
        )}
        {shouldShowDropdownContent && (
          <>
            {locationSuggestions.map(
              (item: google.maps.places.QueryAutocompletePrediction, index: number) => {
                return (
                  <AutocompleteDropdownItem
                    isActive={highlightedIndex === index}
                    {...getItemProps({ item, index })}
                    key={index}
                  >
                    <MapPinIconContainer>
                      <MapPinIcon size={16} />
                    </MapPinIconContainer>
                    {/* TODO: style matches using item.matched_substrings and some kind of string replacement */}
                    <Paragraph>{item.description}</Paragraph>
                  </AutocompleteDropdownItem>
                )
              },
            )}
            {onSelectManualInputOption && (
              <DropdownBottomContainer>
                <div>
                  <TextButton onClick={onSelectManualInputOption}>
                    <ArrowRightContainer>
                      <ArrowRightIcon size={16} />
                    </ArrowRightContainer>
                    <Heading size={'2xs'} as={'span'}>
                      {t('address.fill_out_manually')}
                    </Heading>
                  </TextButton>
                </div>
                <Stack direction="row" gap="1x" justifyContent="flex-end" alignItems="center">
                  <Paragraph size="xs" color="subtle">
                    {'Powered by'}
                  </Paragraph>
                  <GoogleLogo height={14} />
                </Stack>
              </DropdownBottomContainer>
            )}
          </>
        )}
      </AddressAutocompleteDropdown>
    </InputContainer>
  )
}
