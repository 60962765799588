import styled from '@emotion/styled'
import { Label, Paragraph, Stack } from '@qasa/qds-ui'

const Section = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing['6x'],
  [theme.mediaQueries.mdUp]: {
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing['6x'],
    rowGap: theme.spacing['6x'],
  },
}))

export type InfoItemType = {
  title: string
  content: string | number
}
function InfoItem({ title, content }: InfoItemType) {
  return (
    <Stack gap="1x">
      <Label>{title}</Label>
      <Paragraph>{content}</Paragraph>
    </Stack>
  )
}

type VerificationDataCardProps = {
  infoItems: InfoItemType[]
}
export function VerificationDataCard({ infoItems }: VerificationDataCardProps) {
  return (
    <Section>
      {infoItems.map(({ title, content }) => (
        <InfoItem key={title} title={title} content={content} />
      ))}
    </Section>
  )
}
