import styled from '@emotion/styled'
import { Stack } from '@qasa/ui'
import { theme } from '@qasa/qds-ui'

export const ProgressDots = styled(Stack)<{ horizontalOffset: number }>(({ horizontalOffset }) => ({
  transform: `translateX(-${horizontalOffset}px)`,
  transition: 'transform 0.15s ease-out',
}))

export const Dot = styled.span<{ isActive: boolean; dotColor?: string }>(({ dotColor, isActive }) => ({
  background: dotColor ?? theme.colors.core.white,
  boxShadow: theme.shadows.sm,
  opacity: isActive ? 1 : 0.6,
  transform: `scale(${isActive ? 1 : 0.75})`,
}))
