import styled from '@emotion/styled'
import { Button, CheckIcon, Heading, Label, Paragraph, Stack } from '@qasa/qds-ui'
import type { Location } from 'history'
import { stringify } from 'query-string'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { useAuthContext } from '../../../context/auth-context'
import { getPath } from '../../../routing/get-path'
import { PhoneNumberForm } from '../../../ui-shared/forms/phone-number-form'
import { useAdminState } from '../../../data/admin-state'

const VerificationSection = Stack
const VerificationSuccessWrapper = Stack
const InfoBox = styled.div(({ theme }) => ({
  padding: 16,
  background: theme.colors.core.gray20,
  borderRadius: theme.radii.md,
}))

function VerificationSuccess({ label }: { label: string }) {
  return (
    <VerificationSuccessWrapper direction="row" gap={'2x'} alignItems="center">
      <CheckIcon />
      <Paragraph>{label}</Paragraph>
    </VerificationSuccessWrapper>
  )
}

export function ContactTenantVerification() {
  const { t } = useTranslation('contact_tenant')
  const { authBody } = useAuthContext()
  const hasPhoneNumber = Boolean(authBody?.private.phoneNumber)
  const { isAdminLoggedInAsUser } = useAdminState()

  const hasUserConfirmedId = Boolean(authBody?.currentIdentification?.identityConfirmed)
  const isUserAdminOrIdVerified = isAdminLoggedInAsUser || hasUserConfirmedId

  return (
    <Stack gap="6x">
      <Stack gap="1x">
        <Heading size="xs" as="h2">
          {t('verification.header')}
        </Heading>
        <Paragraph>{t('verification.description')}</Paragraph>
      </Stack>
      <Stack gap="6x">
        <VerificationSection alignItems={'flex-start'} gap={'2x'}>
          <Label as={'h3'}>{t('verification.id_verification_label')}</Label>
          {isUserAdminOrIdVerified ? (
            <VerificationSuccess label={t('verification.id_verification_success')} />
          ) : (
            <Button
              variant="secondary"
              as={RouterLink}
              to={({ pathname, search }: Location) => {
                return {
                  pathname: getPath('idVerification'),
                  search: stringify({ redirectUrl: pathname + search }),
                }
              }}
            >
              {t('verification.verify_id_button')}
            </Button>
          )}
        </VerificationSection>
        <VerificationSection gap={'2x'}>
          <Label as={'h3'}>{t('verification.phone_number_label')}</Label>
          {hasPhoneNumber ? (
            <VerificationSuccess
              label={t('verification.phone_number_success', { phoneNumber: authBody?.private.phoneNumber })}
            />
          ) : (
            <PhoneNumberForm />
          )}
        </VerificationSection>
        <InfoBox>
          <Paragraph size={'sm'}>
            {t('verification.private_info_not_shared')}
            <br />
            {t('verification.change_phone_number_info')}
          </Paragraph>
        </InfoBox>
      </Stack>
    </Stack>
  )
}
