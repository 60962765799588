import { graphql } from '@qasa/graphql'

export const UPDATE_PHONE_NUMBER = graphql(`
  mutation UpdatePhoneNumber($uid: ID!, $input: UpdateUserInput!) {
    updateUser(uid: $uid, input: $input) {
      user {
        uid
        private {
          id
          phoneNumber
        }
      }
      errors {
        field
        codes
      }
    }
  }
`)
