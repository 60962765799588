import { Divider, HintBox, RadioGroup, Spacer, Stack, TextField } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { FieldEnum } from '../edit-listing.types'
import { useListingContext } from '../listing-context'
import { SectionWrapper } from '../section-wrapper'

export function ApplicationTypeSection() {
  const { t } = useTranslation('listing')
  const {
    store: {
      values: { isExternal, externalApplicationUrl, externalInfoPage, externalEmail },
    },
    dispatch,
  } = useListingContext()

  return (
    <SectionWrapper
      title={t('section_headings.application_type')}
      description={t('application_type.description')}
    >
      <RadioGroup
        name="external-application"
        value={String(Boolean(isExternal))}
        onValueChange={(value) =>
          dispatch({ action: { type: FieldEnum.IsExternal, payload: Boolean(value === 'true') } })
        }
      >
        <RadioGroup.Label>{t('application_type.is_external_label')}</RadioGroup.Label>
        <Stack gap="2x">
          <RadioGroup.Card label={t('commons:no')} value={String(false)} />
          <RadioGroup.Card label={t('commons:yes')} value={String(true)} />
        </Stack>
      </RadioGroup>
      {isExternal ? (
        <Stack gap="6x">
          <Divider />
          <TextField
            label={t('application_type.application_url')}
            value={externalApplicationUrl || ''}
            onChange={(event) =>
              dispatch({ action: { type: FieldEnum.ExternalApplicationUrl, payload: event.target.value } })
            }
          />
          <TextField
            label={t('application_type.website_url')}
            value={externalInfoPage || ''}
            onChange={(event) =>
              dispatch({ action: { type: FieldEnum.ExternalInfoPage, payload: event.target.value } })
            }
          />
          <TextField
            label={t('application_type.email')}
            value={externalEmail || ''}
            onChange={(event) =>
              dispatch({ action: { type: FieldEnum.ExternalEmail, payload: event.target.value } })
            }
            type="email"
          />
          <HintBox>{t('application_type.at_least_one')}</HintBox>
        </Stack>
      ) : (
        <Spacer size="8x" />
      )}
    </SectionWrapper>
  )
}
