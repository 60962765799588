import { useMutation } from '@apollo/client'
import { useCallback } from 'react'

import { useAuthContext } from '../../../context/auth-context'
import { UPDATE_USER } from '../../../data/graphql/mutations'

export function useMutateUserToTenant() {
  const { authBody } = useAuthContext()
  const [updateUserMutation] = useMutation(UPDATE_USER)

  const handleMutation = useCallback(async () => {
    if (!authBody?.tenant && authBody?.uid) {
      await updateUserMutation({
        variables: { input: { tenant: true, landlord: false }, uid: authBody.uid },
      })
    }
  }, [authBody?.tenant, authBody?.uid, updateUserMutation])

  return { upsertUserToTenant: handleMutation }
}
