import { Stack, Button } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ampliExperiment } from '@qasa/ampli/p2'

import type { ShortcutOptInStepProps } from '../../../legacy-pages/shortcut-opt-in'
import { useShortcutDecision } from '../use-shortcut-decision'

import { DeclineShortcut } from './decline-shortcut'

export function FooterActions({ goNext }: ShortcutOptInStepProps) {
  const { t } = useTranslation('shortcut_opt_in')
  const { homeId } = useParams<{ homeId: string }>()
  const isInDeclineReasonTestGroup =
    ampliExperiment.getVariant('shortcut-decline-reason')?.value === 'treatment'

  const { optInShortcut, isOptInLoading, optOutShortcut, isOptOutLoading } = useShortcutDecision({
    homeId,
    onOptInCompleted: goNext,
  })

  return (
    <Stack style={{ flexGrow: 1 }} justifyContent="space-between" direction="row" wrap="wrap" gap="4x">
      {isInDeclineReasonTestGroup ? (
        <DeclineShortcut goNext={goNext} />
      ) : (
        <Button
          variant="tertiary"
          isLoading={isOptOutLoading}
          isDisabled={isOptInLoading}
          onClick={() => optOutShortcut()}
        >
          {t('decline_dialog.not_interested_button')}
        </Button>
      )}
      <Button
        variant="primary"
        isLoading={isOptInLoading}
        isDisabled={isOptOutLoading}
        onClick={() => optInShortcut()}
      >
        {t('intro.interested_button')}
      </Button>
    </Stack>
  )
}
