import { IconButton, XIcon, Label, ArrowLeftIcon, styled, Stack } from '@qasa/ui'
import type { GestureResponderEvent } from 'react-native'

import { UnstyledButton } from '../../../components/unstyled-button'
import { moderateScale } from '../../../utils/scale'

const SelectedItemContainer = styled(Stack)(({ theme }) => ({
  height: moderateScale(40),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['1x'],
  backgroundColor: 'rgba(163, 163, 150, 0.17)',
  paddingLeft: theme.spacing['4x'],
  paddingRight: theme.spacing['2x'],
  borderRadius: theme.radii.full,
  whiteSpace: 'nowrap',
  userSelect: 'none',
}))
const RemoveItemButton = styled(UnstyledButton)(({ theme }) => ({
  width: theme.sizes['6x'],
  height: theme.sizes['6x'],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.radii.full,
}))

type SelectedItemProps = {
  label: string
  labelSize?: 'sm' | 'md'
  onItemRemove: (e: GestureResponderEvent | MouseEvent) => void
}

export function SelectedItem({ label, onItemRemove, labelSize = 'md', ...rest }: SelectedItemProps) {
  return (
    <SelectedItemContainer {...rest}>
      <Label size={labelSize}>{label}</Label>
      <RemoveItemButton onPress={onItemRemove}>
        <XIcon size={16} />
      </RemoveItemButton>
    </SelectedItemContainer>
  )
}
export function BackButton({ onPress }: { onPress: () => void }) {
  return <IconButton onPress={onPress} label="Back" icon={ArrowLeftIcon} />
}

export function ClearButton({ onPress }: { onPress: () => void }) {
  return <IconButton size="sm" onPress={onPress} icon={XIcon} label="Clear" />
}
