import { Paragraph, styled } from '@qasa/ui'

import { useAppTranslation } from '../../../contexts/i18next'
import { useExternalLinks } from '../../../configs/external-links'
import { Link } from '../../../components/link'

const StyledParagraph = styled(Paragraph)(({ theme }) => ({
  fontSize: theme.typography.body.sm.fontSize,
}))

export function SortingLink() {
  const { t } = useAppTranslation('filter_fields')
  const { sortingPolicyUrl } = useExternalLinks()

  if (!sortingPolicyUrl) {
    return null
  }
  return (
    <Link href={sortingPolicyUrl}>
      <StyledParagraph asWebElement="span">{t('filter_sections:sorting.read_more')}</StyledParagraph>
    </Link>
  )
}
