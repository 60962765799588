import { Trans, useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Paragraph, Spacer, Stack, Label, TextField, Link } from '@qasa/qds-ui'
import { formatNumber } from '@qasa/app/src/utils/number'
import { HomeStatusEnum, TenureTypeEnum } from '@qasa/graphql'
import { useExternalLinks } from '@qasa/app/src/configs/external-links'

import { FieldEnum } from '../edit-listing.types'
import { useListingContext } from '../listing-context'
import { SectionWrapper } from '../section-wrapper'
import { convertDecimalsToPercentageWithTwoDecimals } from '../../../utils/number'
import { getRentRelationToRecommendedRent } from '../listing.utils'
import { RecommendedRentAlignmentIndicator } from '../recommended-rent-alignment-indicator'
import { RecommendedRentDialog } from '../recommended-rent-dialog'
import { useAuthContext } from '../../../context/auth-context'
import { useAdminState } from '../../../data/admin-state'
import { IdVerificationBox } from '../../home/bottom-bar/id-verification-box'
import { getCurrencySymbol } from '../../../utils/misc'

const VerificationDisableContentWrapper = styled(Stack)<{ isDisabled: boolean }>(({ isDisabled }) => ({
  ...(isDisabled && {
    opacity: 0.4,
    pointerEvents: 'none',
  }),
}))

const RentSuggestion = styled.button(({ theme }) => ({
  ...theme.typography.title.lg,
  fontWeight: 400,
}))

const DEFAULT_SERVICE_FEE_FACTOR = 0.0495

export function RentSection() {
  const { t } = useTranslation('listing')
  const { authBody } = useAuthContext()
  const { isAdminLoggedInAsUser } = useAdminState()

  const {
    store: {
      values: {
        tenureType,
        rent,
        recommendedRentNew,
        isProfessional,
        landlordFees,
        firsthand: isFirstHandRental,
        studentHome: isStudentHome,
        seniorHome: isSeniorHome,
        corporateHome: isCorporateHome,
        currency,
        market,
      },
    },
    getErrorMessage,
    dispatch,
    isInsuranceAndGuaranteeOptional,
    tenantFees,
    homeStatus,
  } = useListingContext()

  const { settingRentSupportUrl, landlordOfferingSupportUrl } = useExternalLinks({ marketName: market?.name })

  const isTenancyHome = tenureType === TenureTypeEnum.tenancy
  const shouldShowRentSuggestion = recommendedRentNew && !isTenancyHome
  const rentRelationToRecommendedRent = getRentRelationToRecommendedRent({
    rent,
    recommendedRent: recommendedRentNew,
  })

  /**
   *  This is used to show info about the offering only for non-company landlords (rental types other than andrahand)
   *  TODO: can we use some other data in the actual listing to determine if the offering is available or not?
   */
  const isCompanyLandlordWithFirstHandRental = Boolean(
    isProfessional && (isFirstHandRental || isStudentHome || isSeniorHome || isCorporateHome),
  )

  const landlordFeeFactor = landlordFees?.base.cost.factor
  const tenantFeeFactor = tenantFees?.base.cost.factor

  const serviceFeeFactor = landlordFeeFactor || tenantFeeFactor || DEFAULT_SERVICE_FEE_FACTOR

  const serviceFeePercentage = convertDecimalsToPercentageWithTwoDecimals(serviceFeeFactor)
  // The tax percentage for rent income is at minimum 30%
  const afterTaxFraction = 0.7
  const netServiceFeePercentage = convertDecimalsToPercentageWithTwoDecimals(
    serviceFeeFactor * afterTaxFraction,
  )

  const isPublished = homeStatus === HomeStatusEnum.normal
  const isPublishedAndNotAdminLoggedInAsUser = isPublished && !isAdminLoggedInAsUser
  const hasVerifiedAccessToken = Boolean(authBody?.private?.verifiedAccess)

  const shouldVerify = !hasVerifiedAccessToken && isPublishedAndNotAdminLoggedInAsUser

  return (
    <SectionWrapper title={t('section_headings.rent')} description={t('rent.description')}>
      {shouldVerify && (
        <IdVerificationBox title={t('rent.verify_id.title')} description={t('rent.verify_id.description')} />
      )}
      <VerificationDisableContentWrapper gap="8x" isDisabled={shouldVerify}>
        {shouldShowRentSuggestion && (
          <div>
            <Stack direction="row" alignItems="center">
              <Label as="span">{t('rent.recommendation_heading')}</Label>
              <RecommendedRentDialog />
            </Stack>
            <RentSuggestion
              onClick={() => dispatch({ action: { type: FieldEnum.Rent, payload: recommendedRentNew } })}
            >
              {formatNumber({ amount: recommendedRentNew, currency })}
            </RentSuggestion>
          </div>
        )}
        {isTenancyHome && (
          <Paragraph>
            {t('rent.subletting_tenancy_home')}
            {settingRentSupportUrl && (
              <>
                {' '}
                <Link href={settingRentSupportUrl} isExternal>
                  {t('read_more_link:read_more')}
                </Link>
              </>
            )}
          </Paragraph>
        )}

        <div>
          <TextField
            label={t('rent.label')}
            suffix={getCurrencySymbol({ currency })}
            placeholder={t('rent.placeholder')}
            value={rent ?? ''}
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            onChange={(event) => {
              if (event.target.value === '') {
                dispatch({ action: { type: FieldEnum.Rent, payload: null } })
              }
              if (event.target.value) {
                dispatch({ action: { type: FieldEnum.Rent, payload: parseFloat(event.target.value) || 0 } })
              }
            }}
            errorMessage={getErrorMessage({ field: FieldEnum.Rent }) ?? undefined}
          />
          {!isInsuranceAndGuaranteeOptional && !isCompanyLandlordWithFirstHandRental && (
            <>
              <Spacer size="1x" />
              <Paragraph size="xs" color="subtle">
                {landlordFeeFactor ? (
                  // @ts-ignore
                  <Trans t={t} i18nKey={'rent.landlord_fee_offering_description'}>
                    {{
                      serviceFeePercentage,
                      netServiceFeePercentage,
                    }}
                    <Link href={landlordOfferingSupportUrl} isExternal>
                      {'Click here to for details about our offer'}
                    </Link>
                  </Trans>
                ) : (
                  <>{t('rent.tenant_fee_offering_description', { serviceFeePercentage })}</>
                )}
              </Paragraph>
            </>
          )}
          <Spacer size={'4x'} />
          {shouldShowRentSuggestion && (
            <RecommendedRentAlignmentIndicator status={rentRelationToRecommendedRent} />
          )}
        </div>
      </VerificationDisableContentWrapper>
    </SectionWrapper>
  )
}
