import type { Home } from '@qasa/graphql'
import type { Theme } from '@qasa/qds-ui'

import { useAppTranslation } from '../../../contexts/i18next'
import { SlashSeparatedData } from '../../../components/slash-separated-data'

type ParagraphSize = keyof Theme['typography']['body']
type Props = Pick<Home, 'type' | 'roomCount' | 'squareMeters'> & { textSize?: ParagraphSize }

export function HomeTypeSize({ type, roomCount, squareMeters, textSize = 'md' }: Props) {
  const { t } = useAppTranslation('common_home')
  return (
    <SlashSeparatedData
      textSize={textSize}
      gap="1x"
      data={[
        t(`home_type.${type}`),
        t('home_info.room_count', { count: roomCount ?? 1 }),
        t('home_info.square_meters', { amount: squareMeters }),
      ]}
    />
  )
}
