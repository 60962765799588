import { graphql } from '@qasa/graphql'

export const EDIT_LISTING = graphql(`
  mutation EditListingMutation($homeId: ID!, $input: UpdateHomeInput!) {
    updateHome(id: $homeId, input: $input) {
      home {
        id
        qasaGuaranteeCost
        insuranceCost
        recommendedRentNew(forceSync: true)
      }
      errors {
        codes
        field
      }
    }
  }
`)
