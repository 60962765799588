import { useMutation } from '@apollo/client'
import { ampli } from '@qasa/ampli/p2'
import { useToastContext } from '@qasa/app'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { BooleanParam, useQueryParams, withDefault } from 'use-query-params'

import { getAfterCreateHomeRedirect } from '../listing/use-create-home'

import { OPT_IN_SHORTCUT, OPT_OUT_SHORTCUT } from './shortcut-opt-in.gql'

type UseShortcutDecisionParams = {
  homeId: string
  onOptInCompleted: () => void
}

export const useShortcutDecision = ({ homeId, onOptInCompleted }: UseShortcutDecisionParams) => {
  const { addToast } = useToastContext()
  const { t } = useTranslation('commons')
  const { push } = useHistory()
  const [{ isFromFindTenant }] = useQueryParams({
    isFromFindTenant: withDefault(BooleanParam, false),
  })

  const handleError = () => addToast({ message: t('commons:unexpected_error') })

  const [optInShortcut, { loading: isOptInLoading }] = useMutation(OPT_IN_SHORTCUT, {
    variables: { homeId },
    onCompleted: () => {
      ampli.shortcutOptIn({ homeId })
      onOptInCompleted()
    },
    onError: handleError,
  })
  const [optOutShortcut, { loading: isOptOutLoading }] = useMutation(OPT_OUT_SHORTCUT, {
    variables: { homeId },
    onCompleted: () => {
      ampli.shortcutOptOut({ homeId })
      const redirect = getAfterCreateHomeRedirect({
        homeId,
        isVacationHome: false,
        isFromFindTenant,
        isPotentialShortcut: false,
      })
      push(redirect)
    },
    onError: handleError,
  })

  return { optInShortcut, optOutShortcut, isOptInLoading, isOptOutLoading }
}
