import styled from '@emotion/styled'
import { Button } from '@qasa/qds-ui'

export const DangerButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.colors.bg.negative,
  color: theme.colors.core.white,
  ':not(:disabled)': {
    '@media(hover: hover)': {
      ':hover': {
        backgroundColor: theme.colors.core.red80,
      },
    },
    ':active': {
      backgroundColor: theme.colors.core.red80,
    },
  },
}))
