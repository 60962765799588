import type { LocationDescriptor } from 'history'
import type { IconButtonProps } from '@qasa/qds-ui'
import { ArrowLeftIcon, IconButton } from '@qasa/qds-ui'
import { Link } from 'react-router-dom'

type BackLinkParams = {
  to?: LocationDescriptor
  onClick?: () => void
  size?: IconButtonProps['size']
  variant?: IconButtonProps['variant']
}
export function BackLink({ to, onClick, size = 'sm', ...rest }: BackLinkParams) {
  if (to)
    return (
      <IconButton
        icon={ArrowLeftIcon}
        label={'back'}
        as={Link}
        to={to}
        size={size}
        variant="ghost"
        {...rest}
      />
    )

  return <IconButton icon={ArrowLeftIcon} label={'back'} size={size} onClick={onClick} {...rest} />
}
