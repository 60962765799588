import { useLabelFormatters } from '../use-label-formatters'
import type { FilterSection } from '../filters.types'

import { HomeTypeFilterSection } from './long-term-sections/home-type-filter-section'
import { AccessibilityFilterSection } from './accessibility-filter-section'
import { RulesFilterSection } from './rules-filter-section'
import { NightlyCostFilterSection } from './vacation-sections/nightly-cost-filter-section.web'
import { RoomsAndBedsFilterSection } from './vacation-sections/rooms-and-beds-filter-section.web'
import { PopularTraitsFilterSection } from './vacation-sections/popular-traits-filter-section.web'
import { LocationPerksFilterSection } from './vacation-sections/location-perks-filter-section.web'
import { EquipmentTraitsFilterSection } from './vacation-sections/equipment-traits-filter-section.web'

export const useVacationFilterSections = (): FilterSection[] => {
  const { formatHomeTypes, formatNightlyCost } = useLabelFormatters()
  return [
    {
      name: 'rooms_and_beds',
      Component: RoomsAndBedsFilterSection,
      managedValues: [
        'householdSize',
        'bedroomCount',
        'bedCount',
        'toiletCount',
        'minRoomCount',
        'maxRoomCount',
        'minSquareMeters',
        'maxSquareMeters',
        'includedRooms',
      ],
    },
    {
      name: 'nightly_cost',
      Component: NightlyCostFilterSection,
      managedValues: ['minNightlyCost', 'maxNightlyCost'],
      valuesFormatter: formatNightlyCost,
    },
    {
      name: 'home_type',
      Component: HomeTypeFilterSection,
      managedValues: ['homeTypes', 'sharedHome'],
      valuesFormatter: formatHomeTypes,
    },
    { name: 'rules', Component: RulesFilterSection, managedValues: ['rules'] },
    { name: 'popular_traits', Component: PopularTraitsFilterSection, managedValues: ['popularTraits'] },
    { name: 'location_perks', Component: LocationPerksFilterSection, managedValues: ['locationPerks'] },
    {
      name: 'equipment_traits',
      Component: EquipmentTraitsFilterSection,
      managedValues: ['equipmentTraits'],
    },
    {
      name: 'accessibility',
      Component: AccessibilityFilterSection,
      managedValues: ['wheelchairAccessible'],
    },
  ]
}
