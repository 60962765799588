import type { ReactNode } from 'react'
import { Label, Stack } from '@qasa/ui'

type FilterFieldWrapperProps = {
  label: string
  children: ReactNode
}
export function FilterFieldWrapper({ label, children }: FilterFieldWrapperProps) {
  return (
    <Stack gap="2x">
      <Label>{label}</Label>
      {children}
    </Stack>
  )
}
