import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Stack, HomeIcon, MapPinIcon, CalendarIcon } from '@qasa/qds-ui'
import { SearchPreferenceListItem } from '@qasa/app/src/components/search-preference-list-item'
import { SearchPreferenceMatchIndicator } from '@qasa/app/src/components/search-preference-match-indicator'
import { formatNumber } from '@qasa/app/src/utils/number'

import { Duration } from '../../../ui-shared/duration'
import { useProfileContext } from '../profile-context'
import { ProfileSectionHeader } from '../profile-section-header'
import { ProfileSectionEnum, ProfileSharedHomeEnum } from '../profile.types'
import { getProfileName } from '../profile.utils'

import { FurnitureIcon, CoinsIcon, UserGroupIcon } from './icons'

const SearchPreferenceGrid = styled.ul(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
  gap: theme.spacing['6x'],
}))
const FullWidthPreferenceListItem = styled(SearchPreferenceListItem)({
  gridColumn: '1 / -1',
})

export function ProfileSearchPreferenceBlock() {
  const { isOwnProfile, openEditProfileSection, parsedSearchPreference, profile, searchPreferenceMatchData } =
    useProfileContext()
  const { t } = useTranslation('profile_search_preference_block')

  const {
    selectedAreas,
    minSquareMeters,
    minRoomCount,
    furniture,
    maxMonthlyCost,
    currency,
    householdSize,
    homeType,
    shared,
  } = parsedSearchPreference

  const formattedAreas = selectedAreas.map((area) => area.name).join(', ')
  const formattedMonthlyCost =
    maxMonthlyCost && currency
      ? t('monthly_cost.max', { maxMonthlyCost: formatNumber({ amount: maxMonthlyCost, currency }) })
      : null
  const formattedSize = [
    ...(minRoomCount ? [t('commons:roomWithCount', { count: minRoomCount })] : []),
    ...(minSquareMeters ? [t('commons:square_meters', { number: minSquareMeters })] : []),
  ].join(', ')
  const formattedHouseHoldSize = householdSize
    ? `${t('accommodates.accomodation_for')} ${t('accommodates.people_count', { count: householdSize })}`
    : null
  const formattedFurnished = t(`furnished.options.${furniture}`)
  const formattedHomeTypesAndShared = [
    homeType.map((homeType) => t(`commons:home_type.${homeType}`)).join(', '),
    ...(shared === ProfileSharedHomeEnum.onlyPrivate ? [t('shared_home.onlyPrivate')] : []),
  ]
    .filter(Boolean)
    .join(' / ')

  return (
    <Stack gap="3x">
      <ProfileSectionHeader
        title={t(`profile:sections_title.${ProfileSectionEnum.SEARCH_PREFERENCE}`, {
          profileName: getProfileName({ user: profile }),
        })}
        isOwnProfile={isOwnProfile}
        onEditButtonClick={() =>
          openEditProfileSection({ activeEditSection: ProfileSectionEnum.SEARCH_PREFERENCE })
        }
      />
      <SearchPreferenceGrid>
        <FullWidthPreferenceListItem value={formattedAreas}>
          <MapPinIcon size={20} />
        </FullWidthPreferenceListItem>
        <SearchPreferenceListItem
          value={formattedSize}
          matchingIndicator={
            searchPreferenceMatchData && (
              <SearchPreferenceMatchIndicator {...searchPreferenceMatchData.size} importanceLevel={'high'} />
            )
          }
        >
          <HomeIcon size={20} />
        </SearchPreferenceListItem>
        <SearchPreferenceListItem
          value={formattedHouseHoldSize}
          matchingIndicator={
            searchPreferenceMatchData && (
              <SearchPreferenceMatchIndicator
                {...searchPreferenceMatchData.householdSize}
                importanceLevel={'high'}
              />
            )
          }
        >
          <UserGroupIcon size={20} />
        </SearchPreferenceListItem>
        <SearchPreferenceListItem
          value={formattedFurnished}
          matchingIndicator={
            searchPreferenceMatchData && (
              <SearchPreferenceMatchIndicator
                {...searchPreferenceMatchData.furniture}
                importanceLevel={'high'}
              />
            )
          }
        >
          <FurnitureIcon size={20} />
        </SearchPreferenceListItem>
        <SearchPreferenceListItem
          value={formattedHomeTypesAndShared}
          matchingIndicator={
            searchPreferenceMatchData && (
              <SearchPreferenceMatchIndicator
                {...searchPreferenceMatchData.homeTypeAndSharing}
                importanceLevel={'high'}
              />
            )
          }
        >
          <HomeIcon size={20} style={{ flexShrink: 0 }} />
        </SearchPreferenceListItem>
        {profile.tenantAd && (
          <SearchPreferenceListItem
            value={
              <Duration
                duration={{
                  startAsap: profile.tenantAd.searchPreference.startAsap,
                  startOptimal: profile.tenantAd.searchPreference.startOptimal,
                  endUfn: profile.tenantAd.searchPreference.endUfn,
                  endOptimal: profile.tenantAd.searchPreference.endOptimal,
                }}
              />
            }
            matchingIndicator={
              searchPreferenceMatchData && (
                <SearchPreferenceMatchIndicator
                  {...searchPreferenceMatchData.duration}
                  importanceLevel={'high'}
                />
              )
            }
          >
            <CalendarIcon size={20} />
          </SearchPreferenceListItem>
        )}
        <SearchPreferenceListItem
          value={formattedMonthlyCost}
          matchingIndicator={
            searchPreferenceMatchData && (
              <SearchPreferenceMatchIndicator
                matchingLevel={searchPreferenceMatchData.maxMonthlyCostCents.matchingLevel}
                importanceLevel={'high'}
              />
            )
          }
        >
          <CoinsIcon size={20} />
        </SearchPreferenceListItem>
      </SearchPreferenceGrid>
    </Stack>
  )
}
