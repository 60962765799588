import { graphql } from '@qasa/graphql'

export const EXTEND_CONTRACT = graphql(`
  mutation ExtendContractMutation($id: ID!, $endOptimal: DateTime, $rent: Int) {
    extendContract(id: $id, endOptimal: $endOptimal, rent: $rent) {
      contract {
        status
        rent
        duration {
          endOptimal
          endUfn
          id
        }
        id
        extensionContract {
          id
        }
      }
      errors {
        codes
        field
      }
    }
  }
`)
