import { Controller } from 'react-hook-form'

import { Form } from '../components/form'
import { LocationContainer } from '../components/location/location-container'
import { StepNavigation } from '../components/step-navigation'
import { WizardStepWrapper } from '../components/step-wrapper'
import { requiredFormField } from '../create-tenant-listing.utils'
import { useWizardRouterContext } from '../wizard-router/wizard-router'

export function LocationStep() {
  const { goNext } = useWizardRouterContext()

  return (
    <WizardStepWrapper>
      <Form onSubmit={goNext} dataKey="location">
        {({ control, formState: { errors } }) => {
          const hasErrors = Object.keys(errors).length !== 0
          return (
            <>
              <Controller
                name="selectedAreas"
                control={control}
                rules={requiredFormField}
                render={({ field: { onChange, value } }) => (
                  <LocationContainer onChange={onChange} value={value} hasErrors={hasErrors} />
                )}
              />

              <StepNavigation isDisabled={hasErrors} />
            </>
          )
        }}
      </Form>
    </WizardStepWrapper>
  )
}
