import { Button, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { useCreateTenantListingContext } from '../create-tenant-listing-context'
import { TenantOccupation } from '../../../feature-modules/tenant-occupation/tenant-occupation'
import { useWizardRouterContext } from '../wizard-router/wizard-router'
import { DialogAndWizardActionsFooter } from '../../../ui-shared/dialog-and-wizard-actions-footer'

export function OccupationStep() {
  const { t } = useTranslation('commons')
  const { tenantProfileData, refetchTenantProfileData } = useCreateTenantListingContext()
  const { occupations = [] } = tenantProfileData || {}
  const { goBack, goNext } = useWizardRouterContext()

  return (
    <Stack gap="8x">
      <TenantOccupation
        occupations={occupations || []}
        onComplete={refetchTenantProfileData}
        onCancel={goBack}
        occupationsListActions={
          <DialogAndWizardActionsFooter>
            <Button type="button" variant="tertiary" onClick={goBack}>
              {t('back')}
            </Button>
            <Button type="button" onClick={goNext}>
              {t('next')}
            </Button>
          </DialogAndWizardActionsFooter>
        }
      />
    </Stack>
  )
}
