import styled from '@emotion/styled'
import { Heading, Link, Paragraph, Spacer, Stack, useBreakpointValue } from '@qasa/qds-ui'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import type { ReactNode } from 'react'
import { ampli } from '@qasa/ampli/p2'
import { QASA_FINLAND_EXTERNAL_LINKS } from '@qasa/app/src/configs/external-links'

import { Image } from '../../ui-shared/_core/image'
import oikotieLanding from '../../assets/images/landing_create_listing_oikotie.jpg'
import { Wizard } from '../../ui-shared/wizard'
import { LinkButton } from '../../ui-shared/_core/link-button'
import { getPath } from '../../routing/get-path'
import { QasaOikotieLogo } from '../../assets/logos/qasa-finland/qasa-oikotie'
import { OikotieLogo } from '../../assets/logos/qasa-finland/oikotie'
import { ToriLogo } from '../../assets/logos/qasa-finland/tori'

const Wrapper = styled.div(({ theme }) => ({
  padding: `${theme.spacing['8x']} ${theme.spacing['4x']} 0px ${theme.spacing['4x']}`,
  maxWidth: 1024,
  margin: 'auto',
}))
const Container = styled(Stack)({
  flex: 1,
})

const StyledImage = styled(Image)(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: theme.radii.lg,
  aspectRatio: '1/1',
}))
const LogosWrapper = styled(Stack)(({ theme }) => ({
  background: theme.colors.core.offWhite,
  borderRadius: theme.radii.md,
  padding: `${theme.spacing['8x']} ${theme.spacing['1x']}`,
}))
const StyledToriLogo = styled(ToriLogo)(({ theme }) => ({
  height: 24,
  transform: 'translateY(-2px)',
  [theme.mediaQueries.mdUp]: {
    height: 28,
  },
}))
// NOTE: Qasa by Oikotie needs to be baseline-aligned to match other logos
const StyledQasaOikotieLogo = styled(QasaOikotieLogo)(({ theme }) => ({
  height: 36,
  transform: 'translateY(6px)',
  [theme.mediaQueries.mdUp]: {
    height: 40,
    transform: 'translateY(8px)',
  },
}))
const Display = styled(Heading)(({ theme }) => ({
  ...theme.typography.display.xs,
  [theme.mediaQueries.mdUp]: {
    ...theme.typography.display.sm,
  },
}))

export function OikotieDisclaimerLinkWithTracking({ children }: { children?: ReactNode }) {
  return (
    <Link
      href={QASA_FINLAND_EXTERNAL_LINKS.oikotiePublishListingUrl}
      isExternal
      onClick={() =>
        ampli.oikotieOtherRentalPropertyClicked({ '[Amplitude] Page URL': window.location.href })
      }
    >
      {children}
    </Link>
  )
}

function OikotieDisclaimer() {
  const { t } = useTranslation('create_listing_landing_tori')

  return (
    <Paragraph size="xs">
      <Trans
        t={t}
        i18nKey={'oikotie_disclaimer'}
        components={[<OikotieDisclaimerLinkWithTracking key="0" />]}
      />
    </Paragraph>
  )
}

export function CreateListingLandingTori() {
  const { t } = useTranslation('create_listing_landing_tori')
  const USPs: Array<{ title: string; description: string }> = t('usps', { returnObjects: true })
  const isMdOrAbove = useBreakpointValue({ base: false, md: true } as const, { ssr: false })
  const { search } = useLocation()
  const oikotieLogoHeight = useBreakpointValue({ base: 24, md: 28 } as const, { ssr: false })

  return (
    <Wrapper>
      <Stack
        direction={isMdOrAbove ? 'row' : 'column'}
        justifyContent={'space-between'}
        gap={isMdOrAbove ? '16x' : '8x'}
      >
        <Container
          gap="4x"
          // NOTE: Sync so it has same height as chapter-intro
          style={{
            height: '80vh',
          }}
        >
          {isMdOrAbove && <StyledImage src={oikotieLanding} />}
          <LogosWrapper gap="4x" direction="row" justifyContent="center" alignItems="center" wrap="wrap">
            <StyledQasaOikotieLogo />
            <OikotieLogo height={oikotieLogoHeight} />
            <StyledToriLogo />
          </LogosWrapper>
        </Container>
        <Container gap={isMdOrAbove ? '12x' : '6x'} justifyContent="center">
          <div>
            <Paragraph color="subtle">{t('overline')}</Paragraph>
            <Display>{t('title')}</Display>
            <Spacer size="3x" />
            <Heading size="sm">{t('sub_title')}</Heading>
          </div>
          <Stack as="ul" gap="3x">
            {USPs.map(({ title, description }) => (
              <li key={title}>
                <Heading as={'h4'} size="xs">
                  {title}
                </Heading>
                <Paragraph>{description}</Paragraph>
              </li>
            ))}
          </Stack>
          {!isMdOrAbove && <OikotieDisclaimer />}
          <Wizard.Footer>
            <Container gap="2x">
              <LinkButton
                isFullWidth
                variant="primary"
                to={{
                  pathname: getPath('createListing'),
                  search,
                }}
              >
                {t('next_action')}
              </LinkButton>
              {isMdOrAbove && <OikotieDisclaimer />}
            </Container>
          </Wizard.Footer>
        </Container>
      </Stack>
    </Wrapper>
  )
}
