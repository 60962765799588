import styled from '@emotion/styled'
import { createStyleVariants } from '@qasa/qds-ui'
import type { IconProps } from '@qasa/qds-ui'
import type { ElementType } from 'react'

const circleColorVariant = createStyleVariants((theme) => ({
  positive: {
    backgroundColor: theme.colors.core.green20,
    color: theme.colors.core.green90,
  },
  default: {
    backgroundColor: theme.colors.core.gray40,
    color: theme.colors.core.white,
  },
}))

type Variant = 'positive' | 'default'
const IconCircle = styled.span<{ variant: Variant }>(({ theme, variant }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing['3x'],
  aspectRatio: '1',
  borderRadius: theme.radii.full,
  ...circleColorVariant(theme)[variant],
}))

type IconWithCircleProps = {
  Icon: ElementType<IconProps>
  variant?: Variant
} & IconProps

export function IconWithCircle({ Icon, variant = 'default', ...rest }: IconWithCircleProps) {
  return (
    <IconCircle variant={variant}>
      <Icon color="currentColor" {...rest} />
    </IconCircle>
  )
}
