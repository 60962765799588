import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'

import { serializeProfileSearchPreference } from '../utils/serialize-profile-search-preference'
import { BRAND_CONFIG } from '../../../config/brand-configurations'
import type {
  ProfileSearchPreferenceFormValues,
  ProfileSearchPreferenceFormValuesKeys,
} from '../profile.types'

import { UPSERT_TENANT_AD } from './tenant-ad.gql'

type EditSearchPreferenceParams<TDataKey extends ProfileSearchPreferenceFormValuesKeys> = {
  defaultValues?: ProfileSearchPreferenceFormValues[TDataKey]
  onCompleted?: () => void
  onError?: () => void
}
export function useEditSearchPreference<TDataKey extends ProfileSearchPreferenceFormValuesKeys>({
  onCompleted,
  onError,
  defaultValues = undefined,
}: EditSearchPreferenceParams<TDataKey>) {
  const [upsertTenantAd] = useMutation(UPSERT_TENANT_AD, {
    onCompleted: () => onCompleted?.(),
    onError: () => onError?.(),
  })
  const formMethods = useForm<ProfileSearchPreferenceFormValues[TDataKey]>({
    //@ts-ignore
    defaultValues,
  })

  const { countryCode } = BRAND_CONFIG
  const onSubmitSearchPreference = async (formValues: ProfileSearchPreferenceFormValues[TDataKey]) => {
    const serializedSearchPreference = await serializeProfileSearchPreference(formValues)
    upsertTenantAd({
      variables: {
        input: {
          searchPreference: { ...serializedSearchPreference, countryCode: countryCode.toUpperCase() },
        },
      },
    })
  }

  return {
    formMethods,
    onSubmitSearchPreference,
  }
}
