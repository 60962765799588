import { graphql } from '@qasa/graphql'

export const HOME_MATCHING_PROFILE = graphql(`
  query HomeMatchingProfileQuery($id: ID!) {
    home(id: $id) {
      id
      rent
      tenantBaseFee
      monthlyCostCents
      roomCount
      squareMeters
      tenantCount
      traits {
        type
      }
      type
      shared
      duration {
        startAsap
        startOptimal
        endUfn
        endOptimal
      }
      location {
        locality
      }
    }
  }
`)
