import { Trans, useTranslation } from 'react-i18next'
import { Paragraph, Select, Stack, HintBox, RadioGroup } from '@qasa/qds-ui'
import { BooleanParam, useQueryParams } from 'use-query-params'
import { HomeRentalTypeEnum, HomeTypeEnum, TenureTypeEnum } from '@qasa/graphql'

import { FieldEnum } from '../edit-listing.types'
import { useListingContext } from '../listing-context'
import { SectionWrapper } from '../section-wrapper'
import { BRAND_TOGGLES } from '../../../config/toggles'
import { OikotieDisclaimerLinkWithTracking } from '../../../legacy-pages/create-listing-landing/create-listing-landing-tori'
import { getIsProbablyFinlandMarket } from '../listing.utils'

const LONG_TERM_HOME_TYPES = [
  HomeTypeEnum.apartment,
  HomeTypeEnum.house,
  HomeTypeEnum.terrace_house,
  HomeTypeEnum.cottage,
  HomeTypeEnum.duplex,
  HomeTypeEnum.corridor,
  HomeTypeEnum.loft,
  HomeTypeEnum.other,
]

const VACATION_HOME_TYPES = [
  HomeTypeEnum.apartment,
  HomeTypeEnum.house,
  HomeTypeEnum.cottage,
  HomeTypeEnum.other,
]

const DEFAULT_CONTRACT_TYPE = 'sublet'

export function HomeTypeSection() {
  const { t } = useTranslation('listing')
  const {
    store: {
      values: {
        isProfessional,
        firsthand, // eslint-disable-line @typescript-eslint/naming-convention
        studentHome, // eslint-disable-line @typescript-eslint/naming-convention
        seniorHome, // eslint-disable-line @typescript-eslint/naming-convention
        corporateHome, // eslint-disable-line @typescript-eslint/naming-convention
        type,
        tenureType,
        numberOfHomes,
        rentalType,
        countryCode,
        market,
      },
    },
    dispatch,
  } = useListingContext()

  const contractTypeValues = {
    ...(BRAND_TOGGLES.hasFirstHandRentals && { firsthand }),
    studentHome,
    seniorHome,
    corporateHome,
  }
  const hasOnlyOneHome = numberOfHomes && numberOfHomes === 1
  /* This is different on Qasa Finland mainly since there is no first-hand option there */
  const shouldShowDefaultContractTypeOption = hasOnlyOneHome || !BRAND_TOGGLES.hasFirstHandRentals

  const isLongTermRental = rentalType === HomeRentalTypeEnum.long_term
  const HOME_TYPES = isLongTermRental ? LONG_TERM_HOME_TYPES : VACATION_HOME_TYPES
  const CONTRACT_TYPES = [
    ...Object.keys(contractTypeValues),
    ...(shouldShowDefaultContractTypeOption ? [DEFAULT_CONTRACT_TYPE] : []),
  ]
  const [{ tori: isFromTori }] = useQueryParams({ tori: BooleanParam })
  const isFinlandMarket = getIsProbablyFinlandMarket({ countryCode, marketName: market?.name })
  const isToriUser = isFinlandMarket && isFromTori

  const tenureTypeOptions = Object.values(TenureTypeEnum).map((tenureType) => (
    <Select.Option value={tenureType} key={tenureType}>
      {t(`tenure_type.${tenureType}`)}
    </Select.Option>
  ))

  return (
    <SectionWrapper title={t('section_headings.home_type')}>
      <RadioGroup
        name="type"
        value={type ?? ''}
        onValueChange={(value) => {
          dispatch({
            action: {
              type: FieldEnum.Type,
              payload: HomeTypeEnum[value as keyof typeof HomeTypeEnum],
            },
          })
        }}
      >
        <Stack gap={'2x'}>
          {HOME_TYPES.map((homeType) => (
            <RadioGroup.Card value={homeType} label={t(`commons:home_type.${homeType}`)} key={homeType} />
          ))}
          <HintBox>{t('home_types.info_box')}</HintBox>
        </Stack>
      </RadioGroup>
      {isLongTermRental && (
        <>
          {isProfessional && (
            <RadioGroup
              name="contract"
              value={
                CONTRACT_TYPES.find((key) =>
                  Boolean(contractTypeValues[key as keyof typeof contractTypeValues]),
                ) || DEFAULT_CONTRACT_TYPE
              }
              onValueChange={(value) => {
                /* TODO: this feels weird, mainly because the contract types are not options for a single value but rather their own separate booleans */
                Object.keys(contractTypeValues).forEach((key) => {
                  const field = key as
                    | FieldEnum.Firsthand
                    | FieldEnum.Senior
                    | FieldEnum.Student
                    | FieldEnum.Corporate
                  dispatch({ action: { type: field, payload: field === value } })
                })
              }}
            >
              <RadioGroup.Label>{t('contract_types.heading')}</RadioGroup.Label>
              <Stack gap={'2x'}>
                {CONTRACT_TYPES.map((contractType) => (
                  <RadioGroup.Card
                    value={contractType}
                    label={t(`contract_types.${contractType}`)}
                    key={contractType}
                  />
                ))}
                <HintBox>{t('contract_types.info_box')}</HintBox>
              </Stack>
            </RadioGroup>
          )}
          {!isFinlandMarket && (
            <Stack gap="2x">
              <Select
                label={t('tenure_type.heading')}
                value={tenureType ? TenureTypeEnum[tenureType] : undefined}
                placeholder={t('tenure_type.placeholder')}
                onChange={(e) =>
                  dispatch({
                    action: {
                      type: FieldEnum.TenureType,
                      payload: TenureTypeEnum[e.target.value as keyof typeof TenureTypeEnum],
                    },
                  })
                }
              >
                {tenureTypeOptions}
              </Select>
              <HintBox>{t('tenure_type.info_box')}</HintBox>
            </Stack>
          )}
          {isToriUser && (
            <Paragraph size="sm">
              <Trans
                t={t}
                i18nKey={'home_types.other_rental_disclaimer'}
                components={[<OikotieDisclaimerLinkWithTracking key="0" />]}
              />
            </Paragraph>
          )}
        </>
      )}
    </SectionWrapper>
  )
}
