import { ArrowRightIcon, Paragraph, Stack, styled } from '@qasa/ui'
import type { DurationComponentFragment } from '@qasa/graphql'
import { graphql } from '@qasa/graphql'

import { useAppTranslation } from '../../contexts/i18next'
import { formatDateToYearMonthDay, formatToLongLocalizedDate } from '../../utils/date'

graphql(`
  fragment DurationComponent on Duration {
    id
    endOptimal
    endUfn
    startAsap
    startOptimal
  }
`)

/* TODO: Make this render as something else as <div> to avoid DOM nesting errors when it's rendered in a Paragraph */
const Wrapper = styled(Stack)(({ theme }) => ({
  columnGap: theme.spacing['2x'],
}))

type DurationProps = {
  duration: Omit<DurationComponentFragment, 'id' | '__typename'>
  isBold?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  variant?: 'default' | 'short' | 'long'
}

const useDuration = ({ duration, variant = 'default' }: Pick<DurationProps, 'duration' | 'variant'>) => {
  const { t } = useAppTranslation('duration')

  const { startOptimal, endOptimal, startAsap: isStartAsap, endUfn: isEndUfn } = duration

  const dateFormatFn = (date: Date) => {
    return variant === 'long' ? formatToLongLocalizedDate({ date }) : formatDateToYearMonthDay({ date })
  }

  const getStartDate = () => {
    switch (true) {
      case isStartAsap:
        return t('now')
      case Boolean(startOptimal):
        return dateFormatFn(new Date(startOptimal!))
      default:
        return null
    }
  }
  const getEndDate = () => {
    switch (true) {
      case isEndUfn && variant === 'short':
        return t('until_further_notice_short')
      case isEndUfn:
        return t('until_further_notice')
      case Boolean(endOptimal):
        return dateFormatFn(new Date(endOptimal!))
      default:
        return null
    }
  }

  return { startDate: getStartDate(), endDate: getEndDate() }
}

export function Duration({ duration, isBold, variant = 'default', size = 'md' }: DurationProps) {
  const { startDate, endDate } = useDuration({ duration, variant })

  if (!startDate && !endDate) {
    // TODO: add sentry error handling here to catch issues with duration not showing properly
    return null
  }

  return (
    <Wrapper direction="row" alignItems="center" wrap="wrap">
      <Paragraph asWebElement={'span'} boldness={isBold ? 'bolder' : 'normal'} numberOfLines={1} size={size}>
        {startDate}
      </Paragraph>
      <ArrowRightIcon size={16} strokeWidth={1.5} />
      <Paragraph asWebElement={'span'} boldness={isBold ? 'bolder' : 'normal'} numberOfLines={1} size={size}>
        {endDate}
      </Paragraph>
    </Wrapper>
  )
}
