import { graphql } from '@qasa/graphql'

export const UPSERT_TENANT_AD = graphql(`
  mutation UpsertTenantAdMutation($input: TenantAdInput!) {
    upsertTenantAd(input: $input) {
      tenantAd {
        id
        status
        publishedUntil
        searchPreference {
          id
          searchAreas {
            city
            name
            geojson
            identifier
          }
          balcony
          bathtub
          bikeRoom
          corporateHome
          countryCode
          currency
          dishWasher
          furniture
          householdSize
          inhomeSauna
          storage
          studentHome
          pets
          recycling
          seniorHome
          shared
          smoker
          washingMachine
          tumbleDryer
          wheelchairAccessible
          parking
          minRoomCount
          minSquareMeters
          homeType
          maxMonthlyCost
          startAsap
          startOptimal
          endUfn
          endOptimal
        }
      }
      errors {
        codes
        field
      }
    }
  }
`)
export const PUBLISH_TENANT_AD = graphql(`
  mutation PublishTenantAdMutation {
    publishTenantAd {
      tenantAd {
        id
        status
      }
      errors {
        codes
        field
      }
    }
  }
`)

export const UNPUBLISH_TENANT_AD = graphql(`
  mutation UnpublishTenantAdMutation {
    unpublishTenantAd {
      tenantAd {
        id
        status
        publishedUntil
      }
      errors {
        codes
        field
      }
    }
  }
`)

export const CONVERSATION_FOR_HOME = graphql(`
  query ConversationForHome($homeId: ID!, $uid: ID!) {
    user(uid: $uid) {
      uid
      conversationForHome(homeId: $homeId) {
        homeApplication {
          id
          conversation {
            id
          }
        }
      }
    }
  }
`)
