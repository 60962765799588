import { graphql } from '@qasa/graphql'

export const HOUSING_SITUATION_EXTRA_DATA = graphql(`
  query HousingSituationExtraData {
    me {
      uid
      housingSituation {
        id
        landlordPhoneNumber
        landlordName
      }
    }
  }
`)
