import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'
import type { HomeRentalTypeEnum, HomeSearchOrderByEnum, HomeSearchOrderEnum } from '@qasa/graphql'

import type { SelectedArea } from '../../../types/find-home'

export type FindHomeFilterValues = {
  minMonthlyCost?: number | null
  maxMonthlyCost?: number | null
  minSquareMeters?: number | null
  maxSquareMeters?: number | null
  minRoomCount?: number | null
  maxRoomCount?: number | null
  earliestMoveIn?: string | null
  minRentalLength?: number | null
  homeTypes: string[]
  contractTypes: string[]
  sharedHome: string[]
  furnished: string[]
  wheelchairAccessible: string[]
  householdSize?: number | null
  searchAreas: SelectedArea[]
  page: number
  checkInDate?: string | null
  checkOutDate?: string | null
  bedCount?: number | null
  bedroomCount?: number | null
  toiletCount?: number | null
  includedRooms: string[]
  minNightlyCost?: number | null
  maxNightlyCost?: number | null
  popularTraits: string[]
  locationPerks: string[]
  equipmentTraits: string[]
  rentalType?: HomeRentalTypeEnum | null
  rules: string[]
  order: HomeSearchOrderEnum
  orderBy: HomeSearchOrderByEnum
  uids: string[] | undefined
}

export type FindHomeFiltersContextValue = {
  rentalType: HomeRentalTypeEnum
  filterValues: FindHomeFilterValues
  clearFilterValues: (clearedFilterKeys: (keyof FindHomeFilterValues)[]) => void
  updateFilterValues: (updatedFilterValues: Partial<FindHomeFilterValues>, shouldResetPage?: boolean) => void
}
const FindHomeFiltersContext = createContext<FindHomeFiltersContextValue | undefined>(undefined)

type FindHomeFiltersProviderProps = {
  children: ReactNode
} & FindHomeFiltersContextValue
export function FindHomeFiltersProvider({ children, ...rest }: FindHomeFiltersProviderProps) {
  return <FindHomeFiltersContext.Provider value={rest}>{children}</FindHomeFiltersContext.Provider>
}

export const useFindHomeFiltersContext = () => {
  const context = useContext(FindHomeFiltersContext)

  if (context === undefined) {
    throw Error('`useFindHomeFiltersContext` must be used within a <FindHomeFiltersProvider>')
  }
  return context
}
