import { Paragraph, Stack, styled } from '@qasa/ui'
import { Fragment } from 'react'
import type { Theme } from '@qasa/qds-ui'

import { SlashSeparator } from '../slash-separator/slash-separator'

type ParagraphColor = keyof Theme['colors']['text']
type ParagraphSize = keyof Theme['typography']['body']
type Gap = keyof Theme['spacing']

const Wrapper = styled(Stack)<{ columnGap: Gap }>(({ theme, columnGap }) => ({
  columnGap: theme.spacing[columnGap],
}))

export type SlashSeparatedDataProps = {
  data: string[]
  gap?: Gap
  textSize?: ParagraphSize
  textColor?: ParagraphColor
}

export function SlashSeparatedData({ data, textSize, gap = '2x', textColor }: SlashSeparatedDataProps) {
  if (!data.length) return null
  return (
    <Wrapper direction="row" alignItems="center" wrap={'wrap'} columnGap={gap}>
      {data.map((dataPoint, i) => {
        const isFirstElement = i === 0
        const key = dataPoint + i

        if (isFirstElement) {
          return (
            <Paragraph key={key} size={textSize} color={textColor} numberOfLines={1}>
              {dataPoint}
            </Paragraph>
          )
        }

        return (
          <Fragment key={key}>
            <SlashSeparator color={textColor} size={textSize} />
            <Paragraph size={textSize} color={textColor} numberOfLines={1}>
              {dataPoint}
            </Paragraph>
          </Fragment>
        )
      })}
    </Wrapper>
  )
}
