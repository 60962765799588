import type { ReactNode } from 'react'
import type { TraitTypeEnum } from '@qasa/graphql'

export enum AmenityGroupTitleEnum {
  POPULAR = 'popular',
  EXTRA = 'extra',
  EXTRA_VACATION = 'extra_vacation',
  KITCHEN = 'kitchen',
  RULES = 'rules',
  BATHING = 'bathing',
  WASHING = 'washing',
  BEDROOM = 'bedroom',
  FAMILY = 'family',
  TECHNOLOGY = 'technology',
  OUTDOORS = 'outdoors',
  PARKING_FACILITIES = 'parkingAndFacilities',
  SAFETY = 'homeSafety',
  ACCESSIBILITY = 'accessibility',
}
export type AmenityGroupType = {
  title: AmenityGroupTitleEnum
  traits: {
    trait: TraitTypeEnum
    icon?: ReactNode
  }[]
}
