import { useLazyQuery } from '@apollo/client'
import { parse } from 'query-string'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useAuthContext } from '../context/auth-context'
import { IDENTIFICATION_STATUS } from '../data/graphql/queries'

export const usePollForIdentificationStatus = () => {
  const { location } = useHistory()
  const { refreshAuthBody } = useAuthContext()
  const [isLoading, setIsLoading] = useState(false)
  const [isIdVerified, setIsIdVerified] = useState(false)

  const { transaction_id, success } = parse(location.search) as Record<string, string | undefined>
  const hasTransactionError = Boolean(success !== undefined && success !== 'true')

  const [getStatus, { called: isCalled, data, error, startPolling, stopPolling }] =
    useLazyQuery(IDENTIFICATION_STATUS)

  useEffect(() => {
    if (!isCalled && typeof transaction_id === 'string' && !hasTransactionError) {
      getStatus({ variables: { externalId: transaction_id } })
      setIsLoading(true)
    }
  }, [transaction_id, isCalled, hasTransactionError, getStatus])

  const identificationStatus = data?.identificationStatus

  useEffect(() => {
    const refetchIdInfo = async () => {
      await refreshAuthBody()
      setIsIdVerified(true)
    }
    if (identificationStatus === 'pending') {
      startPolling?.(1000)
    } else if (identificationStatus) {
      stopPolling()
      setIsLoading(false)
      if (identificationStatus === 'accepted') {
        refetchIdInfo()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificationStatus, startPolling, stopPolling])

  return {
    identificationStatus,
    isIdVerified,
    isLoading,
    error,
    hasTransactionError,
    verificationQueryparams: {
      transaction_id,
      success,
    },
  }
}
