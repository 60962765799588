import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { CheckCircleIcon, Heading, Paragraph, Stack } from '@qasa/qds-ui'
import { ProgressBar } from '@qasa/app/src/components/progress-bar'
import { motion } from 'framer-motion'

import { ProfileSectionEnum } from './profile.types'
import { NumberedCircleIcon } from './numbered-circle-icon'
import { useProfileContext } from './profile-context'
import { getProfileName } from './profile.utils'

const AnimatedDiv = styled(motion.div)(({ theme }) => ({
  boxShadow: theme.shadows.lg,
  overflow: 'hidden',
  borderRadius: theme.radii.lg,
  marginBottom: theme.spacing['12x'],
}))
const Card = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing['6x'],
  padding: theme.spacing['6x'],
  overflow: 'hidden',
}))
const CardHeader = Heading
const CardDescription = Paragraph

const ProfileCompletenessSections = Stack
const SectionRow = Stack
const SectionCompleteText = styled(Paragraph)({
  textDecoration: 'line-through',
})
const SectionIncompleteText = styled(Paragraph)({
  textDecoration: 'underline',
})

export function ProfileCompletenessSection() {
  const { profile, openEditProfileSection } = useProfileContext()
  const { t } = useTranslation('profile_completeness_section')
  const profileName = getProfileName({ user: profile })

  const profileCompletenessScore = profile.tenantProfileCompleteness.completenessScore
  const profileFields = profile.tenantProfileCompleteness.tenantProfileFields
  const profileSections = {
    [ProfileSectionEnum.INTRO]: profileFields.idNumberConfirmed.completed,
    [ProfileSectionEnum.ABOUT_ME]: profileFields.bioTitle.completed && profileFields.bio.completed,
    [ProfileSectionEnum.SEARCH_PREFERENCE]: profileFields.matchingProfile.completed,
    [ProfileSectionEnum.HOUSING_SITUATION]:
      profileFields.currentAgreementType.completed &&
      profileFields.movingReason.completed &&
      profileFields.bioLived.completed,
    [ProfileSectionEnum.OCCUPATION]: profileFields.occupations.completed,
  }

  return (
    <AnimatedDiv exit={{ height: 0, marginBottom: 0, opacity: 0 }}>
      <Card>
        <CardHeader>{t('title')}</CardHeader>
        <CardDescription>{t('description')}</CardDescription>
        <ProfileCompletenessSections gap="3x">
          {Object.entries(profileSections).map(([key, isSectionComplete], index) => {
            const listIndex = index + 1
            return isSectionComplete ? (
              <SectionRow key={listIndex} direction="row" alignItems="center" gap="3x">
                <CheckCircleIcon color="positive" size={20} />
                <SectionCompleteText>
                  {t(`profile:sections_title.${key}`, { profileName })}
                </SectionCompleteText>
              </SectionRow>
            ) : (
              <SectionRow key={listIndex} direction="row" alignItems="center" gap="3x">
                <NumberedCircleIcon number={listIndex} />
                <button
                  onClick={() =>
                    openEditProfileSection({
                      activeEditSection: key as ProfileSectionEnum,
                    })
                  }
                >
                  <SectionIncompleteText>
                    {t(`profile:sections_title.${key}`, { profileName })}
                  </SectionIncompleteText>
                </button>
              </SectionRow>
            )
          })}
        </ProfileCompletenessSections>
        <ProgressBar percentage={profileCompletenessScore} />
      </Card>
    </AnimatedDiv>
  )
}
