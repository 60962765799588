import { InputBase as QdsInputBase } from '@qasa/qds-ui'
import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'

import type { InputBaseProps } from './input-base.types'

export const InputBase = forwardRef(
  ({ onChangeText, ...rest }: InputBaseProps, forwardedRef?: ForwardedRef<HTMLInputElement>) => {
    return <QdsInputBase ref={forwardedRef} onChange={(e) => onChangeText(e.target.value)} {...rest} />
  },
)
