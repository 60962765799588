import { useTranslation } from 'react-i18next'
import type { Polygon } from 'geojson'
import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { AreaSearchDialog } from '@qasa/app/src/modules/area-search'
import { AreaSearchAutocomplete } from '@qasa/app/src/modules/area-search/web'
import type { SelectedAreaWithSearchAreaData } from '@qasa/app/src/types/find-home'
import { useBreakpointValue } from '@qasa/qds-ui'

import { ErrorMessage } from '../../../../ui-shared/_core/error-message'
import { isNotNull } from '../../../../utils/misc'
import { SEARCH_AREA } from '../../../../data/graphql/queries/search-area'

import { CreateTenantListingMap } from './create-tenant-listing-map'

type LocationContainerProps = {
  onChange: (areas: SelectedAreaWithSearchAreaData[]) => void
  value: SelectedAreaWithSearchAreaData[]
  hasErrors: boolean
}

export function LocationContainer({ onChange, value, hasErrors }: LocationContainerProps) {
  const { t } = useTranslation('create_tenant_listing')
  const AreaSearchComponent = useBreakpointValue({ base: AreaSearchDialog, md: AreaSearchAutocomplete })
  const areaParams = value
    .map(({ city, name, countryCode }) => {
      if (!countryCode) {
        return null
      }
      return { city, name, countryCode }
    })
    .filter(isNotNull)

  const { data, loading: isLoadingAreaPolygon } = useQuery(SEARCH_AREA, {
    skip: !areaParams.length,
    variables: {
      areaParams,
    },
  })

  const mergedPolygons = useMemo(() => {
    const existingPolygon = value.map(({ geojson }) => geojson as unknown as Polygon).filter(isNotNull)
    // @ts-expect-error
    const searchAreaPolygon = data?.searchArea.map((area) => area.geojson as Polygon) ?? []
    return [...searchAreaPolygon, ...existingPolygon]
  }, [value, data])

  return (
    <>
      <AreaSearchComponent value={value} onChange={onChange} />
      <div>
        {hasErrors && <ErrorMessage>{t('fields.location.required_error')}</ErrorMessage>}
        <CreateTenantListingMap polygons={mergedPolygons} isLoading={isLoadingAreaPolygon} />
      </div>
    </>
  )
}
