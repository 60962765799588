import { Stack } from '@qasa/qds-ui'
import type { ProfileQueryQuery } from '@qasa/graphql'

import { FirstNameField } from '../fields/first-name-field'
import { ShowAgeField } from '../fields/show-age-field'
import { ProfileDataForm } from '../forms/profile-data-form'

import { VerificationSection } from './verification-section'

type IntroSectionParams = {
  profile: NonNullable<ProfileQueryQuery['user']>
}
export function IntroSection({ profile }: IntroSectionParams) {
  return (
    <ProfileDataForm dataKey="intro">
      <Stack gap="8x">
        <FirstNameField />
        {profile.age && <ShowAgeField />}
        <VerificationSection profile={profile} />
      </Stack>
    </ProfileDataForm>
  )
}
