import type { SVGProps } from 'react'

const VARIANT_COLORS = {
  light: '#fff',
  default: '#000',
}

type ColorVariant = keyof typeof VARIANT_COLORS

type QasaOikotieLogoProps = SVGProps<SVGSVGElement> & {
  colorVariant?: ColorVariant
}

export function QasaOikotieLogo({ colorVariant = 'default', ...restProps }: QasaOikotieLogoProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 683.54 253.64"
      fill={VARIANT_COLORS[colorVariant]}
      {...restProps}
    >
      <defs>
        <clipPath id="clippath">
          <path
            d="M404.77 182.64h271v71h-271z"
            style={{
              strokeWidth: 0,
              fill: 'none',
            }}
          />
        </clipPath>
      </defs>
      <g
        style={{
          clipPath: 'url(#clippath)',
        }}
      >
        <path d="M527.41 209.43c-4.82 0-8.74 3.9-8.74 8.71s3.91 8.71 8.74 8.71 8.73-3.9 8.73-8.71-3.91-8.71-8.73-8.71ZM448.11 209.43c-4.82 0-8.73 3.9-8.73 8.71s3.91 8.71 8.73 8.71 8.74-3.9 8.74-8.71-3.91-8.71-8.74-8.71Z" />
        <path d="M640.19 182.64H404.77v71h235.42l35.58-35.5-35.58-35.5Zm-192.08 51.39c-8.8 0-15.93-7.11-15.93-15.89s7.13-15.89 15.93-15.89 15.93 7.11 15.93 15.89-7.13 15.89-15.93 15.89Zm29.42-.08h-6.9v-31.63h6.9v31.63Zm25.07.05-11.12-11.1-.08.08-.07-.07v11.05h-6.89v-31.63h6.89v10.99l11.09-11.06h9.75l-15.82 15.77 16 15.96h-9.75Zm24.81.02c-8.8 0-15.93-7.11-15.93-15.89s7.13-15.89 15.93-15.89 15.93 7.11 15.93 15.89-7.13 15.89-15.93 15.89Zm43.63-24.83h-9.14v24.75H555v-24.75h-9.28v-6.88h25.32v6.88Zm13.79 24.75h-6.89v-31.63h6.89v31.63Zm28.69-24.75H598.7v5.25h14.82v6.88H598.7v5.74h14.82v6.88h-21.79v-31.62h21.79v6.88Z" />
      </g>
      <path
        d="M477.97 201.91h-6.91v31.81h6.91v-31.81ZM503.08 233.78l-11.15-11.16-.08.08-.07-.07v11.11h-6.9v-31.81h6.9v11.06l11.11-11.13h9.77l-15.84 15.87 16.03 16.06h-9.77ZM571.63 208.83h-9.16v24.89h-6.91v-24.89h-9.3v-6.92h25.36v6.92ZM585.44 201.91h-6.9v31.81h6.9v-31.81ZM614.17 208.83h-14.84v5.28h14.84v6.92h-14.84v5.77h14.84v6.92h-21.83v-31.81h21.83v6.92ZM448.5 233.8c-8.81 0-15.96-7.16-15.96-15.98s7.14-15.98 15.96-15.98 15.96 7.16 15.96 15.98-7.14 15.98-15.96 15.98Zm0-24.74c-4.83 0-8.75 3.92-8.75 8.77a8.749 8.749 0 1 0 17.5 0c0-4.84-3.92-8.77-8.75-8.77Z"
        fill="none"
      />
      <path
        d="M527.93 233.8c-8.81 0-15.96-7.16-15.96-15.98s7.14-15.98 15.96-15.98 15.96 7.16 15.96 15.98-7.14 15.98-15.96 15.98Zm0-24.74c-4.83 0-8.75 3.92-8.75 8.77a8.749 8.749 0 1 0 17.5 0c0-4.84-3.92-8.77-8.75-8.77Z"
        fill="none"
      />
      <path d="M505.18 30.3c0 5.85-2.53 16.94-4.12 25.12-1.37 7.01-3.24 16.49-15.2 16.49-24.61 0-21.23-31.35-41.25-31.35-8.26 0-13.16 6.19-13.16 13.89 0 5.78 3.53 11.17 8.95 15.16l19.91 14.99c16.63 11.48 23.38 26.73 23.38 38.52 0 22.19-19.33 38.75-42.65 38.75h-49.43c-18.65 0-28.06-5.66-33.46-18.98-1.42-4-9.07-23.57-9.07-30.44 0-10.39 7.17-18.41 18.11-18.41 21.05 0 30.25 31.33 47.57 31.33 6.8 0 11.86-5.89 11.86-11.99 0-4.55-2.94-9.46-8.36-13.45l-17.31-13.66c-14.48-10.99-22.72-23.63-22.72-37.11 0-24.71 19.51-47.11 49.75-47.11h49.7c15.98 0 27.5 14.18 27.5 28.26Zm-172.44 74.27c0 10.72 5.04 22.96 5.04 33.95 0 11.99-9.54 23.34-23.6 23.34-17.6 0-17.56-16.72-27.25-16.72s-10.38 18.76-41.03 18.76-64.49-32.57-64.49-76.53 28.98-83.31 73.94-83.31c25.71 0 35.07 15.67 43.93 15.67 11.53 0 11.68-19.74 32.51-19.74 13.05 0 23.34 10.83 23.34 23.67 0 20.47-22.38 51.26-22.38 80.9Zm-42.38-20.59c0-17.01-13.79-30.79-30.79-30.79s-30.79 13.79-30.79 30.79 13.79 30.79 30.79 30.79 30.79-13.79 30.79-30.79Zm370.8 20.59c0 10.72 5.04 22.96 5.04 33.95 0 11.99-9.54 23.34-23.6 23.34-17.6 0-17.56-16.72-27.25-16.72s-10.38 18.76-41.03 18.76-64.49-32.57-64.49-76.53 28.98-83.3 73.94-83.3c25.71 0 35.07 15.67 43.93 15.67C639.23 19.74 639.38 0 660.21 0c13.05 0 23.34 10.83 23.34 23.67 0 20.47-22.38 51.26-22.38 80.9Zm-42.38-20.59c0-17.01-13.79-30.79-30.79-30.79S557.2 66.98 557.2 83.98s13.79 30.79 30.79 30.79 30.79-13.79 30.79-30.79ZM177.5 23.99c0 10.44-6.41 26.4-14.89 60.27-8.48 33.87-13.37 52.11-13.37 68.88s7.81 27.25 7.81 41.87-11.91 26.58-23.59 26.58h-20.47c-13.25 0-23.25-14.21-23.25-26.58 0-21.37 23.03-27.8 23.03-41.32 0-5.66-3.97-10.58-9.72-10.58-10.93 0-13.42 14.69-38.12 14.69C34.15 157.8 0 128.19 0 83.27s36.3-79.2 74.6-79.2c27.66 0 34.57 15.67 45.76 15.67C132.29 19.74 133.82 0 153.52 0c12.08 0 23.99 9.74 23.99 23.99Zm-64.7 60c0-17.01-13.79-30.79-30.79-30.79S51.22 66.99 51.22 83.99s13.79 30.79 30.79 30.79 30.79-13.79 30.79-30.79Z" />
    </svg>
  )
}
