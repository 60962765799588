export function detectCollisions(
  translations: Record<string, LegitimateAny>,
  filename: string,
  filesMap: Record<string, string>,
) {
  Object.keys(translations).forEach((i18nKey) => {
    if (filesMap[i18nKey]) {
      throw new Error(
        `The key "${i18nKey}" is repeated in different translation files (${filename} and ${filesMap[i18nKey]})"`,
      )
    }
    filesMap[i18nKey] = filename
  })
}
