import { useTranslation } from 'react-i18next'
import type { BaseUserFragment } from '@qasa/graphql'

import { formatDateDistance } from '../helpers/date'

type LastSeenType = Pick<BaseUserFragment, 'createdAt' | 'seenAt'>

function getLastActiveDate(profile: LastSeenType) {
  // sometimes server and client times are not in sync, resulting in references to the future
  const lastActive = new Date(profile.seenAt || profile.createdAt)
  const now = new Date()
  return lastActive > now ? now : lastActive
}

export function LastActive({ profile }: { profile: LastSeenType }) {
  const { t } = useTranslation('last_active')

  return (
    <>
      {t('active')}{' '}
      {formatDateDistance({
        date: getLastActiveDate(profile),
        addSuffix: true,
      })}
    </>
  )
}
