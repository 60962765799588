import { useTranslation } from 'react-i18next'
import { useHistory, Link as RouterLink } from 'react-router-dom'
import styled from '@emotion/styled'
import { Button, Paragraph } from '@qasa/qds-ui'
import { useMutation } from '@apollo/client'
import { useToastContext } from '@qasa/app'
import { ampli } from '@qasa/ampli/p2'
import type { PublishTenantAdMutationMutation } from '@qasa/graphql'
import { TenantAdStatusEnum } from '@qasa/graphql'

import { useProfileContext } from '../profile-context'
import { getIsSearchPreferencesFilled, getIsTenantAdRenewable } from '../profile.utils'
import { useAuthContext } from '../../../context/auth-context'
import { getPath } from '../../../routing/get-path'
import { PUBLISH_TENANT_AD } from '../hooks/tenant-ad.gql'

import { ProfileCard } from './profile-card'

const MoreInformation = styled(Paragraph)({
  textDecoration: 'underline',
})

const CenteredButton = styled(Button)({
  alignSelf: 'center',
})

function PublishButton({ buttonText }: { buttonText: string }) {
  const { t } = useTranslation('profile_card')
  const { push } = useHistory()
  const { addToast } = useToastContext()
  const [publishTenantAd, { loading: isLoadingPublishTenantAd }] = useMutation(PUBLISH_TENANT_AD, {
    onCompleted: (data) => {
      if (data.publishTenantAd?.tenantAd) {
        ampli.publishTenantAd()
        push(getPath('profilePublished'))
      } else {
        const errors = data.publishTenantAd?.errors
        if (errors) {
          handleApiErrors({ errors })
        }
      }
    },
    onError: () => {
      addToast({ message: t('commons:unexpected_error') })
    },
  })

  const handleApiErrors = ({
    errors,
  }: {
    errors: NonNullable<NonNullable<PublishTenantAdMutationMutation['publishTenantAd']>['errors']>
  }) => {
    errors.forEach(({ codes }) =>
      addToast({ message: t([`private.errors.${codes[0]}`, 'commons:unexpected_error']) }),
    )
  }
  return (
    <CenteredButton isLoading={isLoadingPublishTenantAd} variant="primary" onClick={() => publishTenantAd()}>
      {buttonText}
    </CenteredButton>
  )
}

export function PrivateCardActions() {
  const { tenantAdStatus, tenantAdPublishedUntil } = useProfileContext()
  const { t } = useTranslation('profile_card')

  const isProfileRenewable = getIsTenantAdRenewable({ tenantAdPublishedUntil })

  switch (tenantAdStatus) {
    case TenantAdStatusEnum.published:
      if (isProfileRenewable) {
        return <PublishButton buttonText={t('private.renew')} />
      }
      return null
    case TenantAdStatusEnum.unpublished:
    case TenantAdStatusEnum.expired:
    default: {
      const resolvedTenantAdStatus = tenantAdStatus ?? TenantAdStatusEnum.unpublished
      return <PublishButton buttonText={t(`private.${resolvedTenantAdStatus}.cta_text`)} />
    }
  }
}

type PrivateFooterActionsParams = {
  openProfileCard: () => void
}
export function PrivateFooterActions({ openProfileCard }: PrivateFooterActionsParams) {
  const { tenantAdStatus, tenantAdPublishedUntil, profile } = useProfileContext()
  const { t } = useTranslation('profile_card')
  const { authBody } = useAuthContext()

  const isProfileRenewable = getIsTenantAdRenewable({ tenantAdPublishedUntil })
  const isSearchPreferencesFilled = getIsSearchPreferencesFilled({ profile })
  const hasPhoneNumberAndFamilyName = authBody?.private?.phoneNumber && authBody.private.familyName
  const isPublishProfileReady = isSearchPreferencesFilled && hasPhoneNumberAndFamilyName

  switch (tenantAdStatus) {
    case TenantAdStatusEnum.published:
      return (
        <>
          <div>
            <ProfileCard.Status />
            <ProfileCard.ExpireStatus />
          </div>
          {isProfileRenewable ? (
            <PublishButton buttonText={t(`private.renew`)} />
          ) : (
            <Button variant="tertiary" onClick={openProfileCard}>
              {t(`private.published.footer_cta`)}
            </Button>
          )}
        </>
      )

    case TenantAdStatusEnum.unpublished:
    case TenantAdStatusEnum.expired:
    default: {
      const resolvedTenantAdStatus = tenantAdStatus ?? TenantAdStatusEnum.unpublished
      return (
        <>
          <button onClick={openProfileCard}>
            <ProfileCard.Status />
            <MoreInformation size="sm" color="subtle">
              {t('more_info')}
            </MoreInformation>
          </button>
          {isPublishProfileReady ? (
            <PublishButton buttonText={t(`private.${resolvedTenantAdStatus}.cta_text`)} />
          ) : (
            <Button as={RouterLink} variant="primary" to={getPath('createTenantListing')}>
              {t('private.unpublished.cta_text')}
            </Button>
          )}
        </>
      )
    }
  }
}
