import { useTranslation } from 'react-i18next'
import { ArrowRightIcon, Paragraph, Stack, theme } from '@qasa/qds-ui'
import type { Theme } from '@qasa/qds-ui'
import type { BaseHomeFragment } from '@qasa/graphql'

import { formatDateToDayMonthYear } from '../helpers/date'

type Props = {
  duration: Pick<
    UNSAFE_DeepNonNullableObject<BaseHomeFragment>['duration'],
    'startAsap' | 'startOptimal' | 'endUfn' | 'endOptimal'
  >
  size?: keyof Theme['typography']['body']
}
export function Duration({ duration, size = 'md' }: Props) {
  const { t } = useTranslation('commons')
  const { startAsap: isStartAsap, startOptimal, endUfn: isEndUfn, endOptimal } = duration
  return (
    <Stack
      direction="row"
      alignItems="center"
      wrap="wrap"
      as="span"
      style={{ columnGap: theme.spacing['1x'] }}
    >
      <Paragraph size={size} as="span">
        {isStartAsap || !startOptimal ? t('asap') : formatDateToDayMonthYear(startOptimal)}
      </Paragraph>
      <ArrowRightIcon size={16} strokeWidth={1.5} />
      <Paragraph size={size} as="span">
        {isEndUfn || !endOptimal ? t('until_further_notice') : formatDateToDayMonthYear(endOptimal)}
      </Paragraph>
    </Stack>
  )
}
