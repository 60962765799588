import { Link, useLocation } from 'react-router-dom'
import { stringify } from 'query-string'
import { BigSearchVacationCard, BigSearchLongtermCard } from '@qasa/app/src/modules/big-data-card/variants'
import { HomeRentalTypeEnum } from '@qasa/graphql'
import type { FindHomeNode } from '@qasa/app/src/types/find-home'

import { useEmbeddedFindHome } from '../../hooks/use-embedded-find-home'
import { getPath } from '../../routing/get-path'

type HomeItemProps = {
  home: FindHomeNode
  onHover?: (hovering: boolean) => void
  checkInDate?: string | null
  checkOutDate?: string | null
  shouldOpenInNewTab?: boolean
  hasFilteredOnPricePerNight?: boolean
  isQasaShortcutHome?: boolean
  onClick?: () => void
}

export function HomeItem({
  home,
  onHover,
  checkInDate,
  checkOutDate,
  shouldOpenInNewTab = false,
  hasFilteredOnPricePerNight = false,
  onClick,
}: HomeItemProps) {
  const isFindHomeEmbedded = useEmbeddedFindHome()

  const { pathname, search } = useLocation()

  const searchQueryString =
    stringify({ checkInDate, checkOutDate }, { skipNull: true, skipEmptyString: true }) || undefined

  const isVacationHome = home.rentalType === HomeRentalTypeEnum.vacation
  const HomeCard = isVacationHome ? (
    <BigSearchVacationCard data={home} shouldDisplayPriceRange={hasFilteredOnPricePerNight} />
  ) : (
    <BigSearchLongtermCard data={home} />
  )

  return (
    <Link
      to={{
        pathname: getPath('home', { homeId: home.id }),
        state: {
          fromLocation: pathname + search,
        },
        search: searchQueryString,
      }}
      onMouseEnter={() => {
        if (onHover) onHover(true)
      }}
      onMouseLeave={() => {
        if (onHover) onHover(false)
      }}
      target={shouldOpenInNewTab || isFindHomeEmbedded ? '_blank' : '_self'}
      onClick={onClick}
    >
      {HomeCard}
    </Link>
  )
}
