import { useAppTranslation } from '../../../../../contexts/i18next'
import { useFindHomeFiltersContext } from '../../../contexts'
import { FilterCheckboxGroup } from '../../components/filter-checkbox-group.web'

export function PopularTraitsFilterSection() {
  const { filterValues, updateFilterValues } = useFindHomeFiltersContext()
  const { t } = useAppTranslation('amenities')

  const handleFilterChange = (newValue: string[]) => updateFilterValues({ popularTraits: newValue })

  const options = [
    { value: 'pool', label: t('traits.pool') },
    { value: 'washingMachine', label: t('traits.washing_machine') },
    { value: 'internet', label: t('traits.internet') },
    { value: 'dishWasher', label: t('traits.dish_washer') },
    { value: 'parking', label: t('traits.parking') },
    { value: 'balcony', label: t('traits.balcony') },
    { value: 'kitchenAppliances', label: t('traits.kitchen_appliances') },
    { value: 'inhomeSauna', label: t('traits.inhome_sauna') },
  ]

  return (
    <FilterCheckboxGroup
      label={t('popular_traits.title', { ns: 'filter_sections' })}
      isLabelVisuallyHidden
      values={filterValues.popularTraits}
      onChange={handleFilterChange}
      options={options}
    />
  )
}
