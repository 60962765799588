import { forwardRef, type ReactNode } from 'react'
import styled from '@emotion/styled'
import { SmallHomeCard } from '@qasa/app/src/modules/small-home-card'
import type { HomeSelectQuery } from '@qasa/graphql'

const Wrapper = styled.button(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.colors.border.default,
  padding: theme.spacing['4x'],
  borderRadius: theme.radii.lg,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing['2x'],
  width: '100%',
  [theme.mediaQueries.mdUp]: {
    width: theme.sizes[448],
  },
}))

type HomeItemProps = {
  home: HomeSelectQuery['homes']['edges'][number]['node']
  actionElement?: ReactNode
}

export const HomeItem = forwardRef<HTMLButtonElement, HomeItemProps>(
  ({ home, actionElement, ...props }, forwardedRef) => {
    return (
      <Wrapper ref={forwardedRef} {...props}>
        <SmallHomeCard home={home} />
        {actionElement}
      </Wrapper>
    )
  },
)
