import { graphql } from '@qasa/graphql'

export const HOME_SELECT = graphql(`
  query HomeSelect(
    $orderBy: HomeSearchOrderByEnum
    $order: HomeSearchOrderEnum
    $homeStatuses: [HomeStatusEnum!]
  ) {
    homes(orderBy: $orderBy, order: $order, homeStatuses: $homeStatuses) {
      totalCount
      edges {
        node {
          id
          location {
            id
            streetNumber
            route
            locality
          }
          displayStreetNumber
          status
          archiveReason
          rent
          currency
          landlord {
            uid
          }
          type
          numberOfHomes
          roomCount
          minRoomCount
          maxRoomCount
          squareMeters
          minSquareMeters
          maxSquareMeters
          studentHome
          seniorHome
          corporateHome
          shared
          traits {
            type
          }
          floor
          buildingFloors

          uploads {
            id
            url
            metadata {
              order
              primary
            }
          }
        }
      }
    }
  }
`)

export const HOME_STATUS = graphql(`
  query HomeStatus($homeId: ID!) {
    home(id: $homeId) {
      id
      status
    }
  }
`)
