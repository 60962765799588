import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@qasa/qds-ui'

export function ShowAgeField() {
  const { control } = useFormContext()
  const { t } = useTranslation('edit_profile')
  return (
    <Controller
      name={'showAge'}
      control={control}
      render={({ field }) => {
        const { onChange, ...fieldProps } = field
        return (
          <Checkbox
            label={t('form.show_age.label')}
            isChecked={Boolean(field.value)}
            onCheckedChange={onChange}
            {...fieldProps}
          />
        )
      }}
    />
  )
}
