import { styled } from '@qasa/ui/web'
import type { ChangeEvent, HTMLProps } from 'react'
import { useRef } from 'react'

import { UnstyledButton } from '../../unstyled-button'

const HiddenInput = styled('input')({
  display: 'none',
})
const Button = styled(UnstyledButton)<Pick<FileUploadProps, 'isDisabled' | 'isLoading'>>(
  ({ isDisabled, isLoading }) => ({
    ...(isDisabled && { cursor: 'disabled' }),
    ...(isLoading && { cursor: 'progress' }),
  }),
)

type FileUploadProps = Omit<HTMLProps<HTMLInputElement>, 'disabled' | 'as' | 'onChange' | 'value'> & {
  isDisabled?: boolean
  isLoading?: boolean
  onFilesAdded: (files: File[]) => void
}

export function FileInput({ isDisabled, isLoading, children, onFilesAdded, ...rest }: FileUploadProps) {
  const inputRef = useRef<null | HTMLInputElement>(null)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return
    }
    onFilesAdded(Array.from(e.target.files))
  }
  return (
    <>
      <Button
        type="button"
        isLoading={isLoading}
        isDisabled={isDisabled}
        onPress={() => inputRef.current?.click()}
      >
        {children}
      </Button>
      <HiddenInput
        /* Keeps the value cleared on change to enable resubmitting the same photo */
        value=""
        ref={inputRef}
        onChange={handleChange}
        disabled={isDisabled}
        type="file"
        {...rest}
      />
    </>
  )
}
