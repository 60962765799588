import { enGB, sv, fi, fr, nb } from 'date-fns/locale'
import {
  formatDistance,
  differenceInCalendarDays,
  subDays,
  addDays as addDaysFromDateFns,
  differenceInMinutes,
  getWeek,
} from 'date-fns'
import { format, toZonedTime } from 'date-fns-tz'
import i18next from 'i18next'

import { getCurrentLocale } from '../translations/language-loader/language-utils'

type FormatDateDistance = {
  date: Date
  referenceDate?: Date
  addSuffix?: boolean
}

export const getLocale = () => {
  const currentLanguage = getCurrentLocale()
  if (!currentLanguage) return enGB
  const locale = {
    sv,
    en: enGB,
    fi,
    fr,
    nb,
  }[currentLanguage]
  return locale
}

export function formatDateDistance({
  date,
  referenceDate = new Date(),
  addSuffix = false,
}: FormatDateDistance) {
  const locale = getLocale()
  if (!locale) return ''
  return formatDistance(date, referenceDate, { addSuffix, locale })
}

export function daysSince(date: Date, referenceDate = new Date()) {
  return differenceInCalendarDays(referenceDate, date)
}

export function subtractDays(date: Date, numOfDays: number) {
  return subDays(date, numOfDays).toISOString()
}

export function addDays(date: Date, numOfDays: number) {
  return addDaysFromDateFns(date, numOfDays).toISOString()
}

export function formatListingEnd({ endOptimal, endUfn }: { endOptimal?: string | null; endUfn?: boolean }) {
  if (endUfn) {
    return i18next.t('until_further_notice', { ns: 'commons' })
  }
  return endOptimal ? formatDateToYearMonthDay(endOptimal) : ''
}

export function formatDateRange(startDate: string, endDate: string) {
  const currentLanguage = getCurrentLocale()
  return new Intl.DateTimeFormat(currentLanguage, {
    month: 'long',
    day: 'numeric',
  }).formatRange(new Date(startDate), new Date(endDate))
}

export function formatDateToDayMonth(date: string) {
  const locale = getLocale()
  if (!locale) return ''
  return format(toZonedTime(date, 'UTC'), 'd MMMM', { locale })
}

export function formatDateToOrdinalDayOfMonth(date: string) {
  const locale = getLocale()
  if (!locale) return ''
  return format(toZonedTime(date, 'UTC'), 'do MMMM', { locale })
}

export function formatDateToDayMonthYear(date: string) {
  const locale = getLocale()
  if (!locale) return ''

  return format(new Date(date), 'd MMMM yyyy', { locale })
}

export function formatToLongLocalizedDate(date: Date) {
  const locale = getLocale()
  if (!locale) return ''

  return format(date, 'PPP', { locale })
}

export function formatDateToYearMonthDay(date: string) {
  const currentLanguage = getCurrentLocale()
  /* Override english locale because we want to use YYYY-MM-DD instead of MM/DD/YYYY */
  const dateLocale = currentLanguage === 'en' ? 'sv' : currentLanguage
  return new Intl.DateTimeFormat(dateLocale).format(new Date(date))
}
export const formatDayToDayOfMonth = (day: number) => {
  const locale = getLocale()
  if (!locale) return ''
  return format(new Date(1970, 0, day), 'do', { locale })
}

export function formatDayAndMonthSimplified(date: Date | string) {
  const locale = getLocale()
  if (!locale) return ''
  return format(date instanceof Date ? date : new Date(date), 'd MMM', { locale })
}

export function formatDateToHourAndMinutes({ date }: { date: Date }) {
  const locale = getLocale()
  if (!locale) return ''
  return format(date, 'HH:mm', { locale })
}

type DaysOrWeeksFormat = 'day' | 'days' | 'week' | 'weeks'

export const roundUpHoursToDaysOrWeeks = ({ hours }: { hours: number }) => {
  const hoursToDays = Math.ceil(hours / 24) || 1
  let value = hoursToDays
  let format: DaysOrWeeksFormat = hoursToDays > 1 ? 'days' : 'day'

  if (hoursToDays >= 7) {
    const daysToWeeks = Math.ceil(hoursToDays / 7) || 1
    value = daysToWeeks
    format = daysToWeeks > 1 ? 'weeks' : 'week'
  }

  return { value, format }
}

export const getHourlyCountdown = ({ countDownDate }: { countDownDate?: Date }) => {
  return countDownDate
    ? Math.max(0, Math.ceil(differenceInMinutes(countDownDate, new Date()) / 60)).toString()
    : ''
}

export const getWeekNumber = (date: Date) => getWeek(date, { weekStartsOn: 1, firstWeekContainsDate: 4 })
