import { parse, stringify } from 'query-string'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { getPath } from '@qasa/app'
import { StringParam, useQueryParams } from 'use-query-params'
import { ampli } from '@qasa/ampli/p2'

import { CONTACT_TENANT_STEP_PARAM_NAME } from '../contact-tenant/contact-tenant-dialog'
import { getProfileName } from '../profile.utils'
import { useProfileContext } from '../profile-context'
import { LinkButton } from '../../../ui-shared/_core/link-button'
import { CONVERSATION_FOR_HOME } from '../hooks/tenant-ad.gql'
import { useAuthContext } from '../../../context/auth-context'

import { ProfileCard } from './profile-card'

const useGetConversationId = ({ homeId }: { homeId: string }) => {
  const { profile } = useProfileContext()

  const { data } = useQuery(CONVERSATION_FOR_HOME, {
    variables: {
      homeId,
      uid: profile.uid,
    },
    fetchPolicy: 'cache-and-network',
    skip: !homeId,
  })

  return {
    conversationId: data?.user?.conversationForHome?.homeApplication?.conversation?.id,
  }
}

function ContactLinkButton() {
  const { t } = useTranslation('profile_card')
  const { authBody } = useAuthContext()

  return (
    <LinkButton
      variant="primary"
      isFullWidth
      to={({ search, ...rest }) => {
        const query = parse(search)
        const newQuery = stringify({ ...query, [CONTACT_TENANT_STEP_PARAM_NAME]: 'preview' })
        return { ...rest, search: newQuery }
      }}
      onClick={() =>
        ampli.contactTenantModalOpened({
          landlordUid: authBody?.uid,
          '[Amplitude] Page URL': window.location.href,
        })
      }
    >
      {t('public.contact.contact_cta')}
    </LinkButton>
  )
}

function ChatOrContactLink() {
  const { t } = useTranslation('profile_card')
  const [{ homeId }] = useQueryParams({ homeId: StringParam })
  const { conversationId } = useGetConversationId({ homeId: homeId ?? '' })

  if (conversationId) {
    return <LinkButton to={getPath('Chat', { conversationId })}> {t('public.contact.chat_cta')}</LinkButton>
  }

  return <ContactLinkButton />
}

export function ProfileCardPublic() {
  const { t } = useTranslation('profile_card')
  const { profile } = useProfileContext()

  return (
    <ProfileCard>
      <ProfileCard.Header>{getProfileName({ user: profile })}</ProfileCard.Header>
      <ChatOrContactLink />
      <ProfileCard.Description>{t('public.contact.description')}</ProfileCard.Description>
    </ProfileCard>
  )
}

export function PublicFooterActions() {
  return (
    <div>
      <ChatOrContactLink />
    </div>
  )
}
