import { graphql } from '@qasa/graphql'

export const BLOCK_LISTING_DATA_FOR_HOME = graphql(`
  query BlockListingDataForHome($id: ID!) {
    home(id: $id) {
      id
      numberOfHomes
      minSquareMeters
      maxSquareMeters
      minRoomCount
      maxRoomCount
      minRent
      maxRent
    }
  }
`)
