import type { ReactNode } from 'react'
import styled from '@emotion/styled'
import { Heading } from '@qasa/qds-ui'

const Section = 'section'
const Header = styled.header({
  marginBottom: 16,
})

type Props = {
  heading?: string
  children: ReactNode
}

export function HomeSection({ heading, children }: Props) {
  return (
    <Section>
      {heading && (
        <Header>
          <Heading size="xs" as="h4">
            {heading}
          </Heading>
        </Header>
      )}
      {children}
    </Section>
  )
}
