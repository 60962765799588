import { Link, Stack } from '@qasa/qds-ui'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { SuccessHeaderAnimated } from '../../../ui-shared/success-header-animated'
import { getPath } from '../../../routing/get-path'

type ContactTenantSuccessProp = {
  tenantName: string
  conversationId: string
}
export function ContactTenantSuccess({ conversationId, tenantName }: ContactTenantSuccessProp) {
  const { t } = useTranslation('contact_tenant')

  return (
    <Stack gap="4x" alignItems="center">
      <SuccessHeaderAnimated
        title={t('success.header')}
        description={t('success.description', { tenantName })}
      />
      <Link as={RouterLink} to={getPath('messages', { conversationId })}>
        {t('success.show_application', { tenantName })}
      </Link>
    </Stack>
  )
}
