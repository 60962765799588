import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Stack, Select, Paragraph } from '@qasa/qds-ui'
import { DayOfTheWeekTypeEnum } from '@qasa/graphql'

import { SectionWrapper } from '../section-wrapper'
import { FieldEnum } from '../edit-listing.types'
import { useListingContext } from '../listing-context'
import { UPSERT_LISTING_AVAILABILITY_SETTINGS } from '../edit-listing-overview/listing-availability.gql'
import { CheckboxCard, CheckboxGroup } from '../../../ui-shared/_core/controls/checkbox'

export const CHECK_IN_DAYS = [
  { value: DayOfTheWeekTypeEnum.monday, label: 'calendar_settings.monday' },
  { value: DayOfTheWeekTypeEnum.tuesday, label: 'calendar_settings.tuesday' },
  { value: DayOfTheWeekTypeEnum.wednesday, label: 'calendar_settings.wednesday' },
  { value: DayOfTheWeekTypeEnum.thursday, label: 'calendar_settings.thursday' },
  { value: DayOfTheWeekTypeEnum.friday, label: 'calendar_settings.friday' },
  { value: DayOfTheWeekTypeEnum.saturday, label: 'calendar_settings.saturday' },
  { value: DayOfTheWeekTypeEnum.sunday, label: 'calendar_settings.sunday' },
]

export function CalendarSettingsSection() {
  const { t } = useTranslation('listing')
  const { homeId }: { homeId: string } = useParams()

  const [upsertHomeAvailabilitySettings] = useMutation(UPSERT_LISTING_AVAILABILITY_SETTINGS, {
    onCompleted: (data) => {
      dispatch({
        action: {
          type: FieldEnum.MinNights,
          payload: data.upsertHomeAvailabilitySettings?.homeAvailabilitySettings?.minNightsStay ?? null,
        },
      })
      dispatch({
        action: {
          type: FieldEnum.CheckInDays,
          payload: CHECK_IN_DAYS.filter(
            (checkinDay) =>
              !data.upsertHomeAvailabilitySettings?.homeAvailabilitySettings?.noCheckinDays?.includes(
                checkinDay.value,
              ),
          ).map((checkinDay) => checkinDay.value),
        },
      })
      dispatch({
        action: {
          type: FieldEnum.IsRentOnlyWeekly,
          payload: data.upsertHomeAvailabilitySettings?.homeAvailabilitySettings?.rentOnlyWeekly ?? null,
        },
      })
    },
  })

  const handleUpsertMinNights = async (value: number) => {
    dispatch({
      action: {
        type: FieldEnum.MinNights,
        payload: value,
      },
    })
    upsertHomeAvailabilitySettings({ variables: { input: { homeId, minNightsStay: value } } })
  }

  const handleUpsertNoCheckInDays = async (value: string[]) => {
    dispatch({
      action: {
        type: FieldEnum.CheckInDays,
        payload: value as DayOfTheWeekTypeEnum[],
      },
    })
    upsertHomeAvailabilitySettings({
      variables: {
        input: {
          homeId,
          noCheckinDays: CHECK_IN_DAYS.filter((checkinDay) => !value.includes(checkinDay.value)).map(
            (checkinDay) => checkinDay.value,
          ) as DayOfTheWeekTypeEnum[],
        },
      },
    })
  }

  const handleUpsertFixedNights = async (value: boolean) => {
    dispatch({
      action: {
        type: FieldEnum.IsRentOnlyWeekly,
        payload: value,
      },
    })
    upsertHomeAvailabilitySettings({ variables: { input: { homeId, rentOnlyWeekly: value } } })
  }

  const minimumNightsOptions = [
    ...[1, 2, 3, 4, 5, 6, 7, 10, 13, 14, 29].map((value) => {
      const optionLabel =
        value % 7 === 0
          ? t('calendar_settings.week', { count: value / 7, days: value + 1 })
          : t('calendar_settings.night', { count: value, days: value + 1 })
      return (
        <Select.Option key={value} value={value}>
          {optionLabel}
        </Select.Option>
      )
    }),
  ]

  const {
    store: {
      values: { minNights, isRentOnlyWeekly, checkInDays },
    },
    dispatch,
  } = useListingContext()
  const isWeeklyMinNights = minNights && minNights % 7 === 0

  return (
    <SectionWrapper title={t('section_headings.calendar_settings')}>
      <Select
        label={t('calendar_settings.minimum_night_label')}
        helperText={t('calendar_settings.minimum_night_description')}
        value={minNights ?? undefined}
        onChange={(e) => handleUpsertMinNights(parseInt(e.target.value, 10))}
      >
        {minimumNightsOptions}
      </Select>

      {(isWeeklyMinNights || isRentOnlyWeekly) && (
        <Stack gap={'2x'}>
          <CheckboxCard
            label={t('calendar_settings.fixed_nights_label')}
            isChecked={Boolean(isRentOnlyWeekly)}
            onChange={handleUpsertFixedNights}
          />
          <Paragraph size="sm" color="subtle">
            {t('calendar_settings.fixed_nights_description')}
          </Paragraph>
        </Stack>
      )}

      <CheckboxGroup
        label={t('calendar_settings.check_in_days_label')}
        value={checkInDays}
        onChange={handleUpsertNoCheckInDays}
      >
        <Stack gap={'2x'}>
          {CHECK_IN_DAYS.map((checkinDay, index) => {
            return <CheckboxCard key={index} label={t(checkinDay.label)} value={checkinDay.value} />
          })}
          <Paragraph size="sm" color="subtle">
            {t('calendar_settings.check_in_days_description')}
          </Paragraph>
        </Stack>
      </CheckboxGroup>
    </SectionWrapper>
  )
}
