import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { ElectricityFeePaymentOptionEnum } from '@qasa/graphql'
import { Stack, TextField, RadioGroup } from '@qasa/qds-ui'

import { FieldEnum } from '../edit-listing.types'
import { InputWrapper } from '../input-wrapper'
import { useListingContext } from '../listing-context'
import { getCurrencySymbol } from '../../../utils/misc'

const ElectricityFeeInput = InputWrapper

// Sorting these here to have a reasonable order in the radio component all around the platform
export const electricityFeePaymentOptions = [
  ElectricityFeePaymentOptionEnum.included_in_rent,
  ElectricityFeePaymentOptionEnum.not_included_in_rent,
  ElectricityFeePaymentOptionEnum.tenant_managed,
  ElectricityFeePaymentOptionEnum.fixed_fee,
]

const isElectricityFeePaymentOption = (value: string): value is ElectricityFeePaymentOptionEnum =>
  Object.values(ElectricityFeePaymentOptionEnum).includes(value as ElectricityFeePaymentOptionEnum)

export function ElectricityFeeField() {
  const { t } = useTranslation('listing')

  const {
    store: {
      values: { currency, electricityFee },
    },
    dispatch,
    getErrorMessage,
  } = useListingContext()

  const initialValue: ElectricityFeePaymentOptionEnum =
    electricityFee?.paymentPlan ?? ElectricityFeePaymentOptionEnum.included_in_rent
  const [selectedPaymentPlan, setSelectedPaymentPlan] =
    useState<ElectricityFeePaymentOptionEnum>(initialValue)

  const shouldShowElectricityFeeField = selectedPaymentPlan === 'fixed_fee'

  const setPaymentPlan = (newOption: string) => {
    if (!isElectricityFeePaymentOption(newOption)) {
      return
    }
    setSelectedPaymentPlan(newOption)
    dispatch({
      action: {
        type: FieldEnum.ElectricityFee,
        payload: { paymentPlan: newOption },
      },
    })
  }

  const setMonthlyFee = (monthlyFee: number | null) => {
    dispatch({
      action: {
        type: FieldEnum.ElectricityFee,
        payload: { paymentPlan: selectedPaymentPlan, monthlyFee: monthlyFee ?? 0 },
      },
    })
  }

  return (
    <>
      <RadioGroup name="electricityfee" value={selectedPaymentPlan} onValueChange={setPaymentPlan}>
        <RadioGroup.Label>{t('electricity_fee.label')}</RadioGroup.Label>
        <Stack gap={'2x'}>
          {electricityFeePaymentOptions.map((option) => (
            <RadioGroup.Card key={option} value={option} label={t(`electricity_fee.${option}`)} />
          ))}
        </Stack>
      </RadioGroup>
      {shouldShowElectricityFeeField && (
        <ElectricityFeeInput title={t('electricity_fee.label')}>
          <TextField
            label=""
            suffix={getCurrencySymbol({ currency })}
            placeholder={t('electricity_fee.placeholder')}
            value={electricityFee?.monthlyFee ?? ''}
            min={0}
            onChange={(event) => {
              if (event.target.value === '') {
                setMonthlyFee(null)
              }
              const value = parseFloat(event.target.value)
              if (value) {
                setMonthlyFee(value)
              } else {
                setMonthlyFee(0)
              }
            }}
            isInvalid={Boolean(getErrorMessage({ field: FieldEnum.ElectricityFee }))}
            errorMessage={getErrorMessage({ field: FieldEnum.ElectricityFee }) ?? undefined}
          />
        </ElectricityFeeInput>
      )}
    </>
  )
}
