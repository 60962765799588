import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { reportError } from '@qasa/app'

/**
 * This is a replacement for a global gql error type
 */
type GraphQLFieldErrors = {
  field: string
  codes: string[]
}
export function getErrorsMap(
  errors?: GraphQLFieldErrors[] | null,
  translate = (val: string) => val,
): Record<string, string> {
  return errors
    ? errors.reduce((errorsMap, error) => {
        if (error.codes.length === 0) {
          reportError('Empty array of error codes received from the backend', { error })
          return {
            ...errorsMap,
            [error.field]: i18next.t('unexpected_error', { ns: 'commons' }),
          }
        }
        return {
          ...errorsMap,
          [error.field]: translate(error.codes[0]),
        }
      }, {})
    : {}
}

enum ErrorCodes {
  verify_id_rejected_minor = 'verify_id_rejected_minor',
}

export const useApiErrorHelpers = () => {
  const { t } = useTranslation('api_error')

  const getErrorCopy = (errorCode: string) => {
    const key = ErrorCodes[errorCode as ErrorCodes] ?? 'unexpected_error'
    const errorHeader: string = t([`${key}.header`, key])
    const errorBody: string = t([`${key}.body`, ''])

    return { errorHeader, errorBody }
  }
  return { getErrorCopy }
}
