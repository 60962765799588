import type { ParsedProfileSearchPreference } from './utils/parse-profile-search-preference'

export enum ProfileSectionEnum {
  INTRO = 'INTRO',
  ABOUT_ME = 'ABOUT_ME',
  HOUSING_SITUATION = 'HOUSING_SITUATION',
  OCCUPATION = 'OCCUPATION',
  PREFERRED_AMENITIES = 'PREFERRED_AMENITIES',
  REQUIRED_AMENITIES = 'REQUIRED_AMENITIES',
  SEARCH_PREFERENCE = 'SEARCH_PREFERENCE',
}

export enum ProfileFurnishedEnum {
  both = 'both',
  furnished = 'furnished',
  unfurnished = 'unfurnished',
}
export enum ProfileSharedHomeEnum {
  all = 'all',
  onlyPrivate = 'onlyPrivate',
}
export enum ProfileContractTypeEnum {
  none = 'none',
  student = 'student',
  senior = 'senior',
}
export enum ProfilePreferredAmenitiesEnum {
  balcony = 'balcony',
  storage = 'storage',
  dishWasher = 'dishWasher',
  washingMachine = 'washingMachine',
  tumbleDryer = 'tumbleDryer',
  bathtub = 'bathtub',
  bikeRoom = 'bikeRoom',
  parking = 'parking',
  recycling = 'recycling',
  inhomeSauna = 'inhomeSauna',
}
export enum ProfileRequiredAmenitiesEnum {
  wheelchairAccessible = 'wheelchairAccessible',
  pets = 'pets',
  smoker = 'smoker',
}

export type ProfileSearchPreferenceFormValues = {
  requiredAmenities: Pick<ParsedProfileSearchPreference, 'requiredAmenities'>
  preferredAmenities: Pick<ParsedProfileSearchPreference, 'preferredAmenities'>
  searchPreference: Omit<ParsedProfileSearchPreference, 'preferredAmenities' | 'requiredAmenities'>
}
export type ProfileSearchPreferenceFormValuesKeys = keyof ProfileSearchPreferenceFormValues

export type ProfileDataFormValues = {
  intro: {
    firstName: string
    showAge: boolean
  }
  aboutMe: {
    bioTitle: string
    bio: string
    bioPets: string
  }
}
export type ProfileDataFormValuesKeys = keyof ProfileDataFormValues
