import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextField } from '@qasa/qds-ui'

import { noNumberPattern } from '../../../helpers/validation'
import type { ProfileDataFormValues } from '../profile.types'
const NAME_MAX_LENGTH = 240
const NAME_MIN_LENGTH = 2

export function FirstNameField() {
  const {
    control,
    formState: { errors },
  } = useFormContext<ProfileDataFormValues['intro']>()
  const { t } = useTranslation('edit_profile')

  const firstNameRule = {
    required: t('errors.name.required'),
    minLength: { value: NAME_MIN_LENGTH, message: t('errors.name.too_short') },
    maxLength: { value: NAME_MAX_LENGTH, message: t('errors.name.too_long') },
    pattern: { value: noNumberPattern, message: t('errors.name.no_numbers') },
  }

  return (
    <Controller
      name={'firstName'}
      control={control}
      rules={firstNameRule}
      render={({ field }) => (
        <TextField
          label={t('form.legal_name.first_name')}
          isInvalid={Boolean(errors.firstName)}
          errorMessage={errors.firstName?.message}
          autoComplete="given-name"
          {...field}
        />
      )}
    />
  )
}
