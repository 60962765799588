import { useTranslation } from 'react-i18next'
import { CheckIcon, Stack } from '@qasa/qds-ui'
import { SearchPreferenceListItem } from '@qasa/app/src/components/search-preference-list-item'
import { SearchPreferenceMatchIndicator } from '@qasa/app/src/components/search-preference-match-indicator'

import { ProfileSectionHeader } from '../profile-section-header'
import type { ProfilePreferredAmenitiesEnum } from '../profile.types'
import { ProfileSectionEnum } from '../profile.types'
import { AmenityGrid } from '../components/amenity-grid'
import { useProfileContext } from '../profile-context'

import { EmptyField } from './empty-field'

export function ProfilePreferredAmenitiesBlock() {
  const { isOwnProfile, openEditProfileSection, parsedSearchPreference, searchPreferenceMatchData } =
    useProfileContext()
  const { t } = useTranslation('profile')

  const { preferredAmenities } = parsedSearchPreference

  const hasNoSelectedAmenities = preferredAmenities.length === 0

  const sortMatchingAmenitiesFirst = (a: ProfilePreferredAmenitiesEnum) =>
    searchPreferenceMatchData && searchPreferenceMatchData.preferredAmenities.includes(a) ? -1 : 1

  return (
    <Stack gap="3x">
      <ProfileSectionHeader
        title={t(`sections_title.${ProfileSectionEnum.PREFERRED_AMENITIES}`)}
        isOwnProfile={isOwnProfile}
        onEditButtonClick={() =>
          openEditProfileSection({ activeEditSection: ProfileSectionEnum.PREFERRED_AMENITIES })
        }
      />

      {hasNoSelectedAmenities ? (
        <EmptyField />
      ) : (
        <AmenityGrid>
          {Object.values(preferredAmenities)
            .sort(sortMatchingAmenitiesFirst)
            .map((preference) => (
              <SearchPreferenceListItem
                key={preference}
                value={t(`create_tenant_listing:fields.preferences.checkbox_options.${preference}`)}
                matchingIndicator={
                  searchPreferenceMatchData && (
                    <SearchPreferenceMatchIndicator
                      matchingLevel={
                        searchPreferenceMatchData.preferredAmenities.includes(preference)
                          ? 'match'
                          : 'no_match'
                      }
                    />
                  )
                }
              >
                <CheckIcon size={20} />
              </SearchPreferenceListItem>
            ))}
        </AmenityGrid>
      )}
    </Stack>
  )
}
