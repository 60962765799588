import { useTranslation } from 'react-i18next'
import { Paragraph, HintBox, TextField, SelectBase } from '@qasa/qds-ui'

import { InputWrapper } from '../input-wrapper'
import { useListingContext } from '../listing-context'
import { SectionWrapper } from '../section-wrapper'
import { FieldEnum } from '../edit-listing.types'
import { getIsProbablyFinlandMarket } from '../listing.utils'

const modernityMaxAge = {
  modern: 5,
  fairlyModern: 10,
  fairlyOld: 20,
} as const

export function StandardSection() {
  const { t } = useTranslation('listing')
  const {
    store: {
      values: {
        market,
        countryCode,
        kitchenRenovationYear,
        bathroomRenovationYear,
        buildYear,
        housingAssociation,
        energyClass,
        type,
        isProfessional,
      },
    },
    dispatch,
  } = useListingContext()
  const isApartmentWithPrivateLandlord = !isProfessional && type === 'apartment'
  const currentYear = new Date().getFullYear()
  const isFinlandMarket = getIsProbablyFinlandMarket({ marketName: market?.name, countryCode })

  const isBuildYearInFuture = buildYear ? currentYear < buildYear : false

  const renovationYearOptions = [
    { value: currentYear, label: '0-4', disabled: buildYear ? buildYear > currentYear : false },
    {
      value: currentYear - modernityMaxAge.modern,
      label: '5-9',
      disabled: buildYear ? buildYear > currentYear - modernityMaxAge.modern : false,
    },
    {
      value: currentYear - modernityMaxAge.fairlyModern,
      label: '10-19',
      disabled: buildYear ? buildYear > currentYear - modernityMaxAge.fairlyModern : false,
    },
    {
      value: currentYear - modernityMaxAge.fairlyOld,
      label: '20+',
      disabled: buildYear ? buildYear > currentYear - modernityMaxAge.fairlyOld : false,
    },
  ]

  const energyClassOptions = ['A', 'B', 'C', 'D', 'E', 'F', 'G']

  const handleBuildYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parsedYear = parseInt(event.target.value, 10)

    if (bathroomRenovationYear && bathroomRenovationYear < parsedYear) {
      dispatch({
        action: { type: FieldEnum.BathroomRenovation, payload: null },
      })
    }
    if (kitchenRenovationYear && kitchenRenovationYear < parsedYear) {
      dispatch({
        action: { type: FieldEnum.KitchenRenovation, payload: null },
      })
    }
    dispatch({
      action: { type: FieldEnum.BuildYear, payload: parsedYear },
    })
  }

  return (
    <SectionWrapper title={t('section_headings.standard')} description={t('standard.description')}>
      {isApartmentWithPrivateLandlord && isFinlandMarket && (
        <TextField
          label={t('standard.housing_association_label')}
          isOptional
          value={housingAssociation || ''}
          onChange={(event) =>
            dispatch({
              action: { type: FieldEnum.HousingAssociation, payload: event.target.value },
            })
          }
        />
      )}
      <TextField
        label={t('standard.build_year_label')}
        isOptional
        value={buildYear || ''}
        onChange={handleBuildYearChange}
      />
      <InputWrapper title={t('standard.bathroom_label')} isOptional>
        <SelectBase
          // NOTE: Fallback to undefined for the component and fallback to null for the state
          // is intentional to make sure both the component and our api gets the expected value
          value={bathroomRenovationYear ?? undefined}
          placeholder={t('standard.dont_know')}
          isDisabled={isBuildYearInFuture}
          onChange={(event) => {
            const value = event.target.value
            dispatch({
              action: {
                type: FieldEnum.BathroomRenovation,
                payload: value ? parseInt(value, 10) : null,
              },
            })
          }}
        >
          {renovationYearOptions.map(({ value, label, disabled }) => {
            return (
              <option key={label} value={value ?? undefined} disabled={disabled}>
                {label}
              </option>
            )
          })}
        </SelectBase>
        {isBuildYearInFuture && (
          <Paragraph size="sm" color="subtle">
            {t('standard.disabled_info_text')}
          </Paragraph>
        )}
      </InputWrapper>
      <InputWrapper title={t('standard.kitchen_label')} isOptional>
        <SelectBase
          value={kitchenRenovationYear ?? undefined}
          placeholder={t('standard.dont_know')}
          isDisabled={isBuildYearInFuture}
          onChange={(event) => {
            const value = event.target.value
            dispatch({
              action: {
                type: FieldEnum.KitchenRenovation,
                payload: value ? parseInt(value, 10) : null,
              },
            })
          }}
        >
          {renovationYearOptions.map(({ value, label, disabled }) => {
            return (
              <option key={label} value={value ?? undefined} disabled={disabled}>
                {label}
              </option>
            )
          })}
        </SelectBase>
        {isBuildYearInFuture && (
          <Paragraph size="sm" color="subtle">
            {t('standard.disabled_info_text')}
          </Paragraph>
        )}
      </InputWrapper>
      <InputWrapper title={t('standard.energy_class_label')} isOptional>
        <SelectBase
          value={energyClass ?? undefined}
          placeholder={t('standard.dont_know')}
          onChange={(event) => {
            const value = event.target.value
            dispatch({
              action: { type: FieldEnum.EnergyClass, payload: value ? value : null },
            })
          }}
        >
          {energyClassOptions.map((value) => {
            return (
              <option key={value} value={value}>
                {value}
              </option>
            )
          })}
        </SelectBase>
        <HintBox>{t('standard.info_box')}</HintBox>
      </InputWrapper>
    </SectionWrapper>
  )
}
