import { createLucideIcon } from '@qasa/qds-ui'
import { Coins, Sofa, Users2, ArrowUpRight } from 'lucide-react'

export const CoinsIcon = createLucideIcon(Coins)

export const FurnitureIcon = createLucideIcon(Sofa)

export const UserGroupIcon = createLucideIcon(Users2)

export const ArrowUpRightIcon = createLucideIcon(ArrowUpRight)
