import type { Polygon } from 'geojson'
import { useEffect, useRef, useMemo } from 'react'
import type { ViewState } from 'react-map-gl'
import { Source, Layer, useMap } from 'react-map-gl'
import { theme } from '@qasa/qds-ui'

import { reportError } from '../../../vendor'

import { usePersistedMapState } from './use-persisted-map-state.web'
import { getPolygonBounds, invertPolygons, useMemoedPolygons } from './map-polygons-utils'

const POLYGONS_PADDING = 20

type MapPolygonsProps = {
  polygons: Polygon[]
  viewport: ViewState
}

export function MapPolygons({ polygons, viewport }: MapPolygonsProps) {
  const { current: map } = useMap()
  const invertedPolygons = useMemo(() => invertPolygons(polygons), [polygons])
  const memoedPolygons = useMemoedPolygons({ polygons })
  const { persistedMapState } = usePersistedMapState()
  const hasPersistedLocation = Boolean(persistedMapState?.selectedLocation)
  const shouldPreventFlyTo = useRef(hasPersistedLocation)
  const viewportZoom = useRef(viewport.zoom)
  const viewportLat = useRef(viewport.latitude)
  const viewportLong = useRef(viewport.longitude)

  useEffect(() => {
    if (!memoedPolygons.length || !viewportZoom.current || !viewportLong.current || !viewportLat.current)
      return

    if (shouldPreventFlyTo.current) {
      shouldPreventFlyTo.current = false
      return
    }

    try {
      const geoBounds = getPolygonBounds({ polygons: memoedPolygons })

      map?.fitBounds(geoBounds, { padding: POLYGONS_PADDING })
    } catch (error) {
      reportError('Map fly to polygon failed', { error })
    }
  }, [map, memoedPolygons])

  if (!invertedPolygons) return null

  return (
    <Source type="geojson" data={invertedPolygons} tolerance={1}>
      <Layer
        id="data"
        type="fill"
        paint={{
          'fill-color': '#000',
          'fill-opacity': 0.12,
        }}
      />
      <Layer
        type="line"
        paint={{
          'line-color': `${theme.colors.core.gray90}`,
          'line-width': 2,
        }}
      />
    </Source>
  )
}
