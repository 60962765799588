import { useMutation } from '@apollo/client'
import type { ExchangeSignInCodeMutationVariables } from '@qasa/graphql'

import { EXCHANGE_SIGN_IN_CODE } from './otp-login.gql'

export function useExchangeSignInCode() {
  const [exchangeSignInCodeMutation, { loading: isLoading, called: hasBeenCalled }] =
    useMutation(EXCHANGE_SIGN_IN_CODE)

  const exchangeSignInCode = async ({ code }: ExchangeSignInCodeMutationVariables) =>
    await exchangeSignInCodeMutation({
      variables: {
        code,
      },
    })

  return {
    exchangeSignInCode,
    isLoading,
    hasBeenCalled,
  }
}
