import { useTranslation } from 'react-i18next'

import { ProfileRequiredAmenitiesEnum } from '../../profile/profile.types'
import { Form } from '../components/form'
import { StepNavigation } from '../components/step-navigation'
import { WizardStepWrapper } from '../components/step-wrapper'
import { FormCheckboxGroup } from '../fields/form-checkbox-group'
import { useWizardRouterContext } from '../wizard-router/wizard-router'

export function RequiredAmenitiesStep() {
  const { goNext } = useWizardRouterContext()
  const { t } = useTranslation('create_tenant_listing')

  const requiredAmenitiesOptions = Object.values(ProfileRequiredAmenitiesEnum).map((value) => {
    return { value, label: t(`fields.requirements.checkbox_options.${value}`) }
  })
  return (
    <WizardStepWrapper>
      <Form dataKey="requirements" onSubmit={goNext}>
        {({ control }) => (
          <>
            <FormCheckboxGroup
              control={control}
              options={requiredAmenitiesOptions}
              label={t('fields.requirements.label')}
              name={'requiredAmenities'}
              isLabelVisuallyHidden
            />
            <StepNavigation />
          </>
        )}
      </Form>
    </WizardStepWrapper>
  )
}
