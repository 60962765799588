import { useEffect } from 'react'

import { trackSectionView } from './listing-tracking.utils'

type Props = { sectionNumber: number; sectionPath: string; shouldGoogleTagManagerTrack: boolean }
export function SectionTracker({ sectionNumber, sectionPath, shouldGoogleTagManagerTrack }: Props) {
  useEffect(() => {
    if (shouldGoogleTagManagerTrack) {
      trackSectionView({ trackingInfo: { sectionPath, sectionNumber } })
    }
  }, [sectionNumber, sectionPath, shouldGoogleTagManagerTrack])

  return null
}
